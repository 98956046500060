import React, { useState, useEffect, useRef } from "react";
import Links from "./../../data/link";
import DatePickerRangeAtm from "./../component/DatePickerRangeAtm";
import MapCatastroPublic from "./../../components/component/MapCatastroPublic";   //MapCatastroPublic
import MapCatastroPublicModal from "./../component/MapCatastroPublicModal";
import HeaderTitleCard from "./_common/HeaderTitleCard";
import DescriptionZonaComunaMapAdv from "./_common/DescriptionZonaComunaMapAdv";
import Constant from "../../data/constant";
import Consulta from "./Consulta";


const Step2EventualFormValideDatosTecnicos = (props) => {
    const { fetch, toast, nextClick, backClick, auth, history, dataForm, calculoSuperficieM2, stepNumber, eventCategory, Texto } = props;

    const idMapAdvertising = "idMapCatastroPublicAdvertising";
    const idMapActEco = "idMapCatastroPublicAdvertising";
    const TYPE_MAP_ACT_ECO = "ACT_ECO";
    const TYPE_MAP_ADV = "ADV";

    const [optionsCategory, setOptionsCategory] = useState([]);
    const [showMapAdvertising, setShowMapAdvertising] = useState(false);

    const [dataInmuebleAdvertising, setDataInmuebleAdvertising] = useState({});
    const [dataActividadEconomicaRuat, setDataActividadEconomicaRuat] = useState(undefined);
    const [superficieM2, setSuperficieM2] = useState();
    const [totalDias, setTotalDias] = useState({ mes: 0, dias: 0 });
    const [dataInmuebleCatastroInfo, setDataInmuebleCatastroInfo] = useState(null);
    const [zonaInfo, setZonaInfo] = useState(null);

    const arrayZonaProhibidaPasacalle = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

    // message
    const [messageError, setMessageError] = useState(null);
    const refSelectCategory = useRef(null);
    const refInputNroActividadEco = useRef(null);
    const refInputAncho = useRef(0);
    const refInputAlto = useRef(0);
    const refInputDireccion = useRef(null);
    const refInputDireccionY = useRef(null);
    const refInputDireccionEntre = useRef(null);
    const refInputRazonSocial = useRef(null);

    const consultaSQL = new Consulta();

    const maxDateDatePicker = new Date();
    maxDateDatePicker.setDate(maxDateDatePicker.getDate() + 29);

    const resultadoDivPasacalle = document.getElementById('resultado_pasacalle');
    const resultadoDivGeoreferencial = document.getElementById('resultado_georeferencial');

    useEffect(() => {

        if (!auth.loggedIn()) history.replace(Links[4].url);

        if (dataForm) {
            optionsSelectCategory()
        }
        setTotalDias(0);

    }, [dataForm.admision]);

    const configureData = (data, typeMap) => {
        debugger
        if (typeMap === TYPE_MAP_ACT_ECO) {
            const nodeImage = document.getElementById("imgActEco01")
            nodeImage.src = "data:image/png;base64, " + data.image64;
            nodeImage.parentElement.classList.remove("d-none");
        }

        if (typeMap === TYPE_MAP_ADV) {
            setDataInmuebleAdvertising(data);
        }
    }

    const optionsSelectCategory = () => {
        debugger
        let idClasification = (dataForm ? dataForm.admision.clasification.id : null);
        const query = consultaSQL.queryCategoryPublicity(idClasification);
        fetch.fetchPostGraphQl(query, "/graphql", null).then((data) => {
            if (data.categoryPublicities && data.categoryPublicities.result) {
                const category = data.categoryPublicities.data.clasificacionCategoryPublicity;
                let listItems = category.map((item, index) => {
                    const categoryPublicity = item.categoryPublicity;

                    const children = category.map((itemC, indexC) => {
                        const categoryPublicityint = itemC.categoryPublicity;
                        if (categoryPublicityint.idFatherCategoryPublicity === categoryPublicity.id) {
                            return (<option
                                key={indexC}
                                value={categoryPublicityint.id}
                                name={categoryPublicityint.name}
                            >
                                {categoryPublicityint.name}
                            </option>)
                        }
                    });

                    if (categoryPublicity.hasChildren && !categoryPublicity.idFatherCategoryPublicity)
                        return (<optgroup
                            key={index}
                            value={categoryPublicity.id}
                            label={categoryPublicity.name}
                        >
                            {children}
                        </optgroup>);

                });

                let listItemSingle = category.map((item, index) => {
                    const categoryPublicity = item.categoryPublicity;

                    if (!categoryPublicity.hasChildren && !categoryPublicity.idFatherCategoryPublicity)
                        return (<option
                            key={index}
                            value={categoryPublicity.id}
                            name={categoryPublicity.name}
                        >
                            {categoryPublicity.name}
                        </option>);
                });
                debugger
                listItems = listItems.filter((item) => item);
                listItemSingle = listItemSingle.filter((item) => item);
                setOptionsCategory([...listItems, ...listItemSingle]);
                //setPaintMapCatastro(true);
            }
        });
    }

    const handleEventMapAdvertising = async (dataInmuebleCatastro) => {
        debugger

        const imgIconButton = document.getElementById("imgAdvertising01");
        imgIconButton.src = dataInmuebleCatastro.fullBase6image4;
        imgIconButton.classList.remove("d-none");

        setShowMapAdvertising(false);
        setDataInmuebleCatastroInfo(null);
        setZonaInfo(null);

        if (dataInmuebleCatastro.nroInmueble && parseInt(dataInmuebleCatastro.nroInmueble) > 0) {
            const inmueble = await findInmueble(dataInmuebleCatastro.nroInmueble, "NIM");
            debugger

            if (inmueble) {
                const data = {
                    inmueble: inmueble.data.resInmueble,
                    datosTecnicos: inmueble.data.resTecnico,
                    datosCatastro: dataInmuebleCatastro
                };
                setDataInmuebleCatastroInfo(dataInmuebleCatastro);
                setZonaInfo(dataInmuebleCatastro.zonaTributaria)
                configureData(data, TYPE_MAP_ADV);
            } else {
                configureData({}, TYPE_MAP_ADV);
            }
        }
    }

    const handleOpenMapGeneric = async (event, type) => {
        debugger

        handleHiddenDivMessage();
        setZonaInfo(null)

        if (type === TYPE_MAP_ACT_ECO) {
            setShowMapAdvertising(false);
            //setShowMapActividadEconomica(true);
        }

        if (type === TYPE_MAP_ADV) {
            //setShowMapActividadEconomica(false);
            setShowMapAdvertising(true);
        }
        const dataInmueble = {
            codigoCatastral: undefined
        }
        loadInmuebleRuat(dataInmueble, type);
    }

    const loadInmuebleRuat = async (dataInmueble, type) => {
        debugger
        if (
            !dataInmueble.codigoCatastral
        ) {
            setTimeout(() => {
                const idMap = type === TYPE_MAP_ADV ? idMapAdvertising : idMapActEco;
                const modalCatastro = new window.bootstrap.Modal(
                    document.getElementById(idMap)
                );
                modalCatastro.show();
            }, 100);
        } else {
            await loadInmuebleCatastro(dataInmueble.codigoCatastral);
        }
    };

    const handleOnSearchPorNroActEconomica = async (event) => {
        event.preventDefault();
        refInputRazonSocial.current.value = "";
        setMessageError(null);
        await eventInputSearchActEconomica();
    }

    const eventInputSearchActEconomica = async () => {
        debugger
        let input = refInputNroActividadEco.current;
        let numeroActividad = input.value;
        setDataActividadEconomicaRuat(undefined);
        if (numeroActividad && numeroActividad.length >= 3) {
            debugger
            const actividadEconomicaRuat = await findActEcoByNumero(numeroActividad);

            if (actividadEconomicaRuat) {
                const propietariosActEco = actividadEconomicaRuat.propiedades;   //solo tomar el primero
                if (actividadEconomicaRuat.razonSocial !== undefined) {                   
                    refInputRazonSocial.current.value = actividadEconomicaRuat.tipoActividad + "(" + actividadEconomicaRuat.razonSocial + ")";
                }

                if (propietariosActEco.length > 0 && propietariosActEco[0].codigoContribuyente) {
                    const contribuyente = await findContribuyenteByCodContribuyente(propietariosActEco[0].codigoContribuyente);
                    let ci = 0;
                    if (contribuyente && contribuyente.natural)
                        ci = contribuyente.natural.numeroDocumento;
                
                    actividadEconomicaRuat.tipoContribuyente = contribuyente.tipoContribuyente;
                    setDataActividadEconomicaRuat(actividadEconomicaRuat);
                }
            } else {
                debugger
                setMessageError('No existe el Número de Actividad Económica');
                toast.error('No existe el Número de Actividad Económica');
            }
        }
    }

    const handleOpenImageAdvertisingBase64 = (event) => {
        const link = event.target;
        const image = new Image();
        image.src = link.src;
        const w = window.open("");
        w.document.write(image.outerHTML);
    }

    const loadInmuebleCatastro = async (codigo_catrastral) => {
        debugger
        const resInmuebleCatastro = await fetch.fetchGet(
            `api-ruat/inmuebles-catastro/codigo-catastral/${codigo_catrastral}`
        );
        if (resInmuebleCatastro && resInmuebleCatastro.status) {
            if (resInmuebleCatastro.data.inmueble.length > 0) {
                return resInmuebleCatastro.data.inmueble[0];
            }
        }
        return undefined;
    };

    const findInmueble = async (nroInmueble, identificador) => {
        let url = undefined;
        debugger
        if (identificador === "CAT")
            url = `api-ruat/inmuebles/codigo-catastral/${nroInmueble}`;
        if (identificador === "NIM")
            url = `api-ruat/inmuebles/nro-inmueble/${nroInmueble}`;

        if (url) {
            const respInmueble = await fetch.axiosAsyncGet(url);
            if (Boolean(respInmueble) && respInmueble.status)
                return respInmueble;
        }

        return null;
    }

    const findActEcoByNumero = async (numeroAct) => {
        const resActEco = await fetch.fetchGet(
            `api/actividad-economica/get-by-nro-act-eco-ruat/${numeroAct}`
        );
        if (resActEco && resActEco.status) {
            if (resActEco.ActividadEconomica)
                return resActEco.ActividadEconomica;
        }
        return undefined;
    }

    const findContribuyenteByCodContribuyente = async (codigoContribuyente) => {

        const resActEco = await fetch.fetchGet(
            `api-ruat/contribuyentes/cc/${codigoContribuyente}`
        );
        if (resActEco && resActEco.status) {
            debugger
            if (resActEco.data.contribuyente.length > 0) {
                return resActEco.data.contribuyente[0];
            }
        }
        return undefined;
    }

    const nextForm = (e) => {
        debugger;
        handleHiddenDivMessage();
        const inputRangeDate = document.getElementsByName("datosTecnicos-tiempo");
        const selectCategory = refSelectCategory.current;
        const inputAncho = refInputAncho.current;
        const inputAlto = refInputAlto.current;
        const inputDireccion = refInputDireccion.current;
        const inputDireccionEntre = refInputDireccionEntre.current;
        const inputDireccionY = refInputDireccionY.current;

        let next = true;
        const data = {
            advertising: {
                inmueble: dataInmuebleAdvertising,
                category: selectCategory.selectedOptions[0].value,
                categoryNombre: selectCategory.options[selectCategory.selectedIndex].text,
                ancho: inputAncho.value,
                alto: inputAlto.value,
                direccion: inputDireccion.value,
                direccionEntre: inputDireccionEntre.value,
                directionY: inputDireccionY.value,
                rangeDate: inputRangeDate[0].value
            },
            actividadEconomica: {
                actividadEconomicaRuat: dataActividadEconomicaRuat,   //esto ver de como fusionar

            }
        };

        if (zonaInfo != null && data.advertising.categoryNombre.toUpperCase().trim() === "PASACALLE") {
            debugger
            const resultado = arrayZonaProhibidaPasacalle.find((item) => parseInt(item) === parseInt(zonaInfo));
            if (resultado) {
                resultadoDivPasacalle.classList.remove("d-none");
                next = false;
            }
        }

        if (zonaInfo === null) {
            resultadoDivGeoreferencial.classList.remove("d-none");
            next = false;
        }

        // validar datos de la ubicacion geoespacial
        if (dataInmuebleAdvertising && Object.keys(dataInmuebleAdvertising).length === 0 && dataInmuebleAdvertising.constructor === Object) {
            debugger
            next = false;
            toast.error("Ubicación Geoespacial de la Publicidad, es obligatorio");
        }

        if (dataActividadEconomicaRuat && Object.keys(dataActividadEconomicaRuat).length === 0 && dataActividadEconomicaRuat.constructor === Object) {
            next = false;
            toast.error("Actividad Económica no localizada, debe proceder con el trámite de la licencia.");
        }

        if (next) {
            window.scrollTo(0, 0);
            nextClick(e, stepNumber, false, data)
        }
    }

    // calculo de superficie en M2
    const onChangeSuperficie = () => {
        if(refInputAlto.current.value && refInputAncho.current.value){
            let area = calculoSuperficieM2(refInputAlto.current.value, refInputAncho.current.value);
            setSuperficieM2(area);
        }
    }

    const handleChangeCategory = (event) => {
        const selectCategory = event.target;
        const category = selectCategory.selectedOptions[0];

        eventCategory({
            id: category.value,
            name: category.getAttribute("name")
        });
    }

    // hiden div message
    const handleHiddenDivMessage = () => {
        if (!resultadoDivPasacalle.classList.contains("d-none") || !resultadoDivGeoreferencial.classList.contains("d-none")) {
            resultadoDivPasacalle.classList.add("d-none");
            resultadoDivGeoreferencial.classList.add("d-none");
        }
    }

    return (
        <>
            <div className="main-wizard ">
                <HeaderTitleCard titulo={'Datos Técnicos'} descripcion={Constant[0].publicidad_step_new[1]} visibleRequired={true} />
                <form
                    className="needs-validation"
                    id={"formValideDatosTecnicos"}
                    noValidate
                >
                    <div className="mt-2">
                        <label htmlFor="datosTecnicos-category" className="form-label">(*) Tipo Publicidad</label>
                        <select className="form-select" ref={refSelectCategory}
                            data-parsley-required="true"
                            name="datosTecnicos-category"
                            onChange={handleChangeCategory}>
                            <option
                                key={0}
                                defaultValue
                                value=""
                            >
                                Seleccione Tipo Publicidad
                            </option>
                            {optionsCategory}
                        </select>
                    </div>

                    {/* fechas */}
                    <div className="row mt-2 mb-3">
                        <div className="col-sm-12 col-md-6 ">
                            <label htmlFor="datosTecnicos-tiempo" className="form-label">(*) Fechas</label>
                            <DatePickerRangeAtm
                                placeHolder={"Rango de Fechas (días)"}
                                name={"datosTecnicos-tiempo"}
                                id={"datosTecnicos-tiempo"}
                                maxDate={maxDateDatePicker}
                                minDate={new Date()}
                                setTotalDias={setTotalDias}
                                mandatory={true}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6" >
                            <label htmlFor="total_dias_info" className="form-label">(*) Días Autorización</label>
                            <input type="text" id="total_dias_info" value={totalDias.dias} data-parsley-required="true" readOnly={true} />
                        </div>
                    </div>

                    <fieldset className="">
                        <legend className="h6 pl-2 pr-2 pt-1 pb-1 mb-2 border-b-dark"><label>
                            {Texto.dimension_publicidad}
                        </label>
                        </legend>
                        <div className="row mt-2 mb-2">
                            <div className="col-sm-12 col-md-4 ">
                                <label htmlFor="datosTecnicos-ancho" className="form-label">(*) Ancho(metros)</label>
                                <input type="text"
                                    id="datosTecnicos-ancho"
                                    name="datosTecnicos-ancho"
                                    ref={refInputAncho}
                                    min={0.2}
                                    max={999}
                                    data-parsley-required="true"
                                    data-parsley-pattern="^\d+(.\d{1,2})?$"
                                    data-parsley-integer-message="Ingrese solo ńumeros enteros"
                                    data-parsley-trigger="keyup"
                                    onChange={(e) => onChangeSuperficie()}
                                />
                            </div>

                            <div className="col-sm-12 col-md-4">
                                <label htmlFor="datosTecnicos-alto" className="form-label">(*) Alto (metros)</label>
                                <input type="text"
                                    id="datosTecnicos-alto"
                                    name="datosTecnicos-alto"
                                    ref={refInputAlto}
                                    min={0.2}
                                    max={999}
                                    data-parsley-required="true"
                                    data-parsley-pattern="^\d+(.\d{1,2})?$"
                                    data-parsley-integer-message="Ingrese solo ńumeros enteros"
                                    onChange={(e) => onChangeSuperficie()}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <label htmlFor="datosTecnicos-largo" className="form-label">(*) Superficie M2</label>
                                <input type="text"
                                    id="datosTecnicos-superficie-m2"
                                    name="datosTecnicos-superficie-m2"
                                    min={0.01}
                                    max={999}
                                    readOnly={true}
                                    value={superficieM2 && superficieM2}
                                    data-parsley-required="true"
                                    data-parsley-pattern="^\d+(.\d{1,2})?$"
                                    data-parsley-integer-message="Ingrese solo ńumeros enteros"

                                />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset className="mb-2">
                        <legend className="h6 pl-2 pr-2 pt-1 pb-1 mb-2 border-b-dark"><label>
                            {Texto.ubicacion_publicidad}
                        </label>
                        </legend>

                        <div className="form-group text-center mt-1 mb-1">
                            <button type="button" className="next_button btn btn-violeta"
                                onClick={(e) => handleOpenMapGeneric(e, TYPE_MAP_ADV)}>
                                {Texto.ubicacion_geoespacial_publicidad}
                                <i className="fas fa-map-marker-alt ps-2"></i>
                            </button>
                        </div>

                        <div className="form-group">
                            <img
                                id={"imgAdvertising01"}
                                className="img-fluid mx-auto d-block pointer rounded border border-secondary d-none mb-2"
                                style={{ height: '70%' }}
                                onClick={(e) => handleOpenImageAdvertisingBase64(e)}
                                alt="img-domicilio-advertising"
                                src={""}
                            />

                            {
                                dataInmuebleCatastroInfo
                                &&
                                <DescriptionZonaComunaMapAdv dataInmuebleCatastroInfo={dataInmuebleCatastroInfo} />
                            }
                            <div id="resultado_pasacalle" style={{ fontSize: 11.5 }} className="alert alert-danger p-2 mb-2 d-none" role="alert">
                                <b>Atención: </b> Zona no autorizada para la categoria seleccionada.
                            </div>

                            <div id="resultado_georeferencial" style={{ fontSize: 11.5 }} className="alert alert-danger p-2 mb-2  d-none" role="alert">
                                <b>Atención: </b> Inmueble no actualizado, consigne el inmueble maś cercano.
                            </div>

                        </div>

                        <div className="">
                            <label htmlFor="datosTecnicos-direccion" className="form-label">(*) Dirección</label>
                            <input
                                type="text"
                                id="datosTecnicos-direccion"
                                name="datosTecnicos-direccion"
                                ref={refInputDireccion}
                                className={"form-control text-uppercase"}
                                required
                                data-parsley-required-message="Ingrese la dirección donde se emplazará la publicidad"
                                data-parsley-minlength="5"
                                data-parsley-maxlength="200"
                                autoComplete="one-time-code"
                                placeholder="Calle/Avenida/Pasaje/Parque/Plazuela/etc."
                            />
                        </div>

                        <div className=" mt-2">
                            <label htmlFor="adyacentes" className="form-label">(*) Adyacentes</label>
                        </div>

                        <div className="input-group">
                            <input
                                type="text"
                                id="ubicacion-adyacente_entre"
                                name="ubicacion-adyacente_entre"
                                ref={refInputDireccionEntre}
                                className={"form-control text-uppercase"}
                                required
                                data-parsley-required-message="Ingrese la dirección adyancente"
                                data-parsley-minlength="5"
                                data-parsley-maxlength="200"
                                placeholder="Calle/Avenida/Pasaje/etc."
                                autoComplete="one-time-code"
                            />

                            <div className="input-group-prepend">
                                <span className="input-group-text" id="">Y</span>
                            </div>
                            <input
                                type="text"
                                id="ubicacion-adyacente_y"
                                name="ubicacion-adyacente_y"
                                ref={refInputDireccionY}
                                className={"form-control text-uppercase"}
                                required
                                data-parsley-required-message="Ingrese la dirección adyancente"
                                data-parsley-minlength="5"
                                data-parsley-maxlength="200"
                                placeholder="Calle/Avenida/Pasaje/etc."
                                autoComplete="one-time-code"
                            />
                        </div>

                        <div className="row">
                            <div className="col-6" id="error_adyacente_entre"></div>
                            <div className="col-6" id="error_adyacente_y"></div>
                        </div>
                    </fieldset>

                    {/* datos domicilio publicidad */}
                    <fieldset className="">
                        <legend className="h6 pl-2 pr-2 pt-1 pb-1 mb-2 border-b-dark"><label>
                            Datos de la Actividad Económica
                        </label>
                        </legend>

                        <div className="row mb-3 mt-2">
                            <div className="col-md-6 col-lg-6 col-sm-12">
                                <label htmlFor="datosTecnicos-nroActividad" className="form-label">Licencia Funcionamiento (Opcional)</label>
                                <div className="input-group ">
                                    <input
                                        type="text"
                                        className="form-control text-uppercase"
                                        id={"input-nroactividad"}
                                        name="datosTecnicos-nroActividad"
                                        aria-label="Nro. Actividad Económica"
                                        aria-describedby="basic-addon2"
                                        data-parsley-validate-if-empty={false}
                                        data-parsley-type="number"
                                        data-parsley-number-message="Ingrese solo ńumeros"
                                        ref={refInputNroActividadEco}
                                    />
                                    <div className="input-group-append">
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            onClick={handleOnSearchPorNroActEconomica}
                                        >
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                                {messageError && <div className="col-12 parsley-errors-list filled"> {messageError}</div>}
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12">
                                <label htmlFor="DatosTecnicos-actividad" className="form-label">Nombre o Razón Social</label>
                                <input
                                    type={"text"}
                                    name="DatosTecnicos-actividad"
                                    readOnly
                                    ref={refInputRazonSocial}
                                    className="form-control"
                                    data-parsley-required-if="#input-nroactividad" />
                            </div>
                        </div>
                    </fieldset>

                    <div className="buttons button_space justify-content-between mt-2">
                        <a className="back_button btn btn-fucsia" onClick={backClick}>Volver</a>
                        <button type="button" className="next_button btn btn-violeta" onClick={nextForm}>Continuar</button>
                    </div>
                </form>
            </div>

            {showMapAdvertising ? <MapCatastroPublicModal
                module={"antenas"}
                //id={"idMapCatastroPublicAdvertising"}
                id={idMapAdvertising}
                nameForm={""}
                fillPaint={true}
                width="724"
                height="261"
                //mode="basic"
                title="Ubicación Publicidad"
                handleEventMap={handleEventMapAdvertising}
            /> : ""}

        </>
    );
};

export default Step2EventualFormValideDatosTecnicos;
