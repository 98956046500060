import Config from './config';
const carroucelsImages = [
    {
        title: "Actividades Económicas",
        src: Config[2].url + '/carrousel/IMPUESTOS-ACTECO-1600x500.jpg',
        href: "/",
        alt: "Actividades Económicas",
        class_active: '',
        target: "_self",
        key: 0
    },
    {
        title: "Inmuebles",
        src: Config[2].url + '/carrousel/IMPUESTOS-INM-1600x500.jpg',
        href: "/",
        alt: "Inmuebles",
        class_active: '',
        target: "_self",
        key: 1
    },
    {
        title: "Sitios y Mercados",
        src: Config[2].url + '/carrousel/IMPUESTOS-MERC-1600x500.jpg',
        href: "/",
        alt: "Sitios y Mercados",
        class_active: 'active',
        target: "_self",
        key: 2
    },
    /*{
        title: "Publicidad Exterior",
        src: Config[2].url + '/carrousel/IMPUESTOS-PUBLI-1600x500.jpg',
        href: "/",
        alt: "Publicidad Exterior",
        class_active: '',
        target: "_self",
        key: 3
    },*/
    {
        title: "Vehículos",
        src: Config[2].url + '/carrousel/IMPUESTOS-VEH-1600x500.jpg',
        href: "/",
        alt: "Vehículos",
        class_active: '',
        target: "_self",
        key: 3
    },
];

export default  carroucelsImages;
