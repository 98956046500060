import React from "react";
import Texto from "../../../data/es";

const ViewDataJuridico = (props) => {
  const {
    dataJuridico,
    dataPoder,
    dataApoderado,
    handleClickAddCopropietario,
    showButtonAdd,
    isVisacion,
    showTitle
  } = props;

  return (
    <>
      <div className="card widget widget-simple">
        <div className="card-body ">
          {
            showTitle ? <div className="row ">
              <div className="col-12 col-md- widget-header mb-3">
                <h4 className="text-maroon-flush">
                  <i className="fas fa-bookmark me-2"></i>{" "}
                  {isVisacion
                    ? Texto.datos_sujeto_pasivo_vendedor
                    : Texto.operador_redes_telecomunicaciones}
                </h4>
              </div>
              <div className="col-4 col-md-6 widget-header mb-3"></div>
            </div> : ""
          }

          <div className="widget-content">
            <div className="widget-body">
              <div className="row ">
                <div className="col-sm-12 col-md-5 col-lg-5 ">
                  <p className="text-left">
                    <strong>{Texto.pmc.toUpperCase()}: </strong>
                    <span>{dataJuridico.pmc}</span>
                  </p>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-4 ">
                  <p className="text-left">
                    <strong>{Texto.tipo_contribuyente}: </strong>
                    <span>{dataJuridico.tipoContribuyente}</span>
                  </p>
                </div>
              </div>

              <div className="row ">
                <div className="col-sm-12 col-md-12 ">
                  <p className="text-left">
                    <strong>{Texto.razon_social}: </strong>
                    <span>{dataJuridico.nombreContribuyente ?dataJuridico.nombreContribuyente :dataJuridico.razonSocial}</span>
                  </p>
                </div>
              </div>
              <div className="row ">
                <div className="col-sm-12 col-md-12 col-lg-12 ">
                  <p className="text-left">
                    <strong>{Texto.direccion}: </strong>
                    <span>{dataJuridico.domicilioLegal}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card widget widget-simple">
        <div className="card-body ">
          {
            showTitle ? <div className="row ">
              <div className="col-12 col-md-6 widget-header mb-3">
                <h4 className="text-maroon-flush">
                  <i className="fas fa-bookmark me-2"></i>{" "}
                  {Texto.informacion_complementaria}
                </h4>
              </div>
              <div className="col-4 col-md-6 widget-header mb-3"></div>
            </div> : ""
          }

          <div className="widget-content">
            <div className="widget-body">
              <div className="row ">
                <div className="col-sm-12 col-md-5 col-lg-5 ">
                  <p className="text-left">
                    <strong>{Texto.tipo_doc}: </strong>
                    <span>
                      {dataJuridico.tipoDocumento
                        ? dataJuridico.tipoDocumento
                        : "--"}
                    </span>
                  </p>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-4 ">
                  <p className="text-left">
                    <strong>{Texto.numero}: </strong>
                    <span>
                      {dataJuridico.numeroDocumento
                        ? dataJuridico.numeroDocumento
                        : "--"}
                    </span>
                  </p>
                </div>

                <div className="col-sm-12 col-md-3 col-lg-3 ">
                  <p className="text-left">
                    <strong>{Texto.tipo_sociedad}: </strong>
                    <span>{dataJuridico.tipoSociedad}</span>
                  </p>
                </div>
              </div>

              <div className="row ">
                <div className="col-sm-12 col-md-5 col-lg-5 ">
                  <p className="text-left">
                    <strong>{Texto.fecha_constitucion}: </strong>
                    <span>{dataJuridico.fechaConstitucion}</span>
                  </p>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-4 ">
                  <p className="text-left">
                    <strong>{Texto.tipo_contribuyente_juridico}: </strong>
                    <span>{dataJuridico.tipoContribuyenteJuridico}</span>
                  </p>
                </div>

                <div className="col-sm-12 col-md-3 col-lg-3 ">
                  <p className="text-left">
                    <strong>{Texto.sigla}: </strong>
                    <span>{dataJuridico.sigla}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {dataApoderado ? (
        <div className="card widget widget-simple">
          <div className="card-body ">
            <div className="row ">
              <div className="col-12 col-md-6 widget-header mb-3">
                <h4 className="text-maroon-flush">
                  <i className="fas fa-bookmark me-2"></i>{" "}
                  {Texto.representante_legal}
                </h4>
              </div>
              <div className="col-4 col-md-6 widget-header mb-3"></div>
            </div>

            <div className="widget-content">
              <div className="widget-body">
                <div className="row ">
                  <div className="col-sm-12 col-md-5 col-lg-5 ">
                    <p className="text-left">
                      <strong>{Texto.tipo_documento}: </strong>
                      <span>
                        {dataApoderado.tipoDocumento
                          ? dataApoderado.tipoDocumento
                          : "--"}
                      </span>
                    </p>
                  </div>

                  <div className="col-sm-12 col-md-4 col-lg-4 ">
                    <p className="text-left">
                      <strong>{Texto.documento_identidad}: </strong>
                      <span>
                        {(dataApoderado.numeroDocumento
                          ? dataApoderado.numeroDocumento
                          : "--") +
                          " " +
                          (dataApoderado.expedidoEn
                            ? dataApoderado.expedidoEn
                            : "--")}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-sm-12 col-md-12 ">
                    <p className="text-left">
                      <strong>{Texto.nombre_completo}: </strong>
                      <span>
                        {dataApoderado.nombre
                          ? dataApoderado.nombre +
                          " " +
                          dataApoderado.apellidoPaterno +
                          " " +
                          (dataApoderado.apellidoMaterno
                            ? dataApoderado.apellidoMaterno
                            : "") +
                          " " +
                          (dataApoderado.apellidoCasada
                            ? " de " + dataApoderado.apellidoCasada
                            : "")
                          : "--"}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-sm-12 col-md-5 col-lg-5 ">
                    <p className="text-left">
                      <strong>{Texto.genero}: </strong>
                      <span>
                        {dataApoderado.genero ? dataApoderado.genero : "--"}
                      </span>
                    </p>
                  </div>

                  <div className="col-sm-12 col-md-4 col-lg-4 ">
                    <p className="text-left">
                      <strong>{Texto.estado_civil}: </strong>
                      <span>
                        {dataApoderado.estadoCivil
                          ? dataApoderado.estadoCivil
                          : "--"}
                      </span>
                    </p>
                  </div>
                </div>

                {dataPoder ? (
                  <>
                    <div className="row ">
                      <div className="col-sm-12 col-md-5 col-lg-5 ">
                        <p className="text-left">
                          <strong>{Texto.numero_poder}: </strong>
                          <span>
                            {dataPoder.numeroPoder
                              ? dataPoder.numeroPoder
                              : "--"}
                          </span>
                        </p>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-4 ">
                        <p className="text-left">
                          <strong>{Texto.fecha_poder}: </strong>
                          <span>{dataPoder.fecha}</span>
                        </p>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-sm-12 col-md-12 ">
                        <p className="text-left">
                          <strong>{Texto.observacion}: </strong>
                          <span>
                            {dataPoder.observacion
                              ? dataPoder.observacion
                              : "--"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {showButtonAdd ? (
        <div className="row">
          <div className="col-12 text-end">
            <div className=" search-btn button mb-3">
              <input
                type="button"
                className="btn bg-green-haze btn-full-screen"
                name={"Agregar"}
                value="Agregar"
                onClick={handleClickAddCopropietario}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ViewDataJuridico;
