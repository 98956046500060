import React from 'react';
import PropTypes from 'prop-types';
import '../style/breadcrumb.css';

const PasosNavigationAutorizacion = ({ nro_inmueble, paso1_active, paso2_active,
    paso3_active, paso4_active, paso5_active }) => {

    var detector = new window.MobileDetect(window.navigator.userAgent)
    let breakLine = true;
    if( Boolean(detector.mobile()) ){
        if(paso1_active && !paso2_active && !paso3_active && !paso4_active && !paso5_active)
            paso1_active = true
        
        if(paso1_active && paso2_active && !paso3_active && !paso4_active && !paso5_active){
            paso1_active = false
            paso2_active = true
        }

        if(paso1_active && paso2_active && paso3_active && !paso4_active && !paso5_active){
            paso1_active = false
            paso2_active = false
            paso3_active = true
        }

        if(paso1_active && paso2_active && paso3_active && paso4_active && !paso5_active){
            paso1_active = false
            paso2_active = false
            paso3_active = false
            paso4_active = true
        }

        if(paso1_active && paso2_active && paso3_active && paso4_active && paso5_active ){
            paso1_active = false
            paso2_active = false
            paso3_active = false
            paso4_active = false
            paso5_active = true
        }
        breakLine = false;
    }
    return (
        <ol className="col-12 breakcrumb-solo mb-3" >
        <li className={paso1_active ? 'active' : "display-active"}>
            <span className={paso1_active ? 'paso1-active' : ""}></span>
            <span className={paso1_active ? 'texto' : "texto_free"}>Inmueble { breakLine ? <br /> : ""}<small>Nro: {nro_inmueble}</small></span>
        </li>

        
        <li className={paso2_active ? ' active' : "display-active"}>
            <span className={paso2_active ? 'paso2-active' : "paso2"}></span>
            <span className={paso2_active ? 'texto' : "texto_free"}>Operador <br/> Telecomunicaciones</span>
        </li>

        <li className={paso3_active ? 'active' : "display-active"}>
            <span className={paso3_active ? 'paso2-active' : "paso2"}></span>
            <span className={paso3_active ? 'texto' : "texto_free"}>Requisitos </span>
        </li>

        <li className={paso4_active ? 'active' : "display-active"}>
            <span className={paso4_active ? 'paso4-active icon-minuta-active' : "paso4 icon-minuta"}></span>
            <span className={paso4_active ? 'texto' : "texto_free"}>Restriccion <br/> Obligaciones</span>
        </li>

        <li className={paso5_active ? 'active' : "display-active"}>
            <span className={paso5_active ? 'paso5-active' : "paso5"}></span>
            <span className={paso5_active ? 'texto' : "texto_free"}>Vista Previa</span>
        </li>
    </ol>)
};

PasosNavigationAutorizacion.prototype = {
    titulo_paso1: PropTypes.string.isRequired,
    paso1_active: PropTypes.bool.isRequired,
    paso2_active: PropTypes.bool.isRequired,
    paso3_active: PropTypes.bool.isRequired,
    paso4_active: PropTypes.bool.isRequired,
    paso5_active: PropTypes.bool.isRequired
}

export default PasosNavigationAutorizacion;