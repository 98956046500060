import React from 'react';

const DescriptionZonaComunaMapAdv = ({ dataInmuebleCatastroInfo }) => {
  return (
    <div className="text-center flex mt-2 mb-2">
      <p>
        <strong className="pe-1">Zona:</strong> {dataInmuebleCatastroInfo.zonaTributaria}
        <strong className="ms-3 pe-1">Comuna:</strong> {dataInmuebleCatastroInfo.comuna}
        <strong className="ms-3 pe-1">Catastro:</strong> {dataInmuebleCatastroInfo.codCatastral}
      </p>
    </div>
  );
};

export default DescriptionZonaComunaMapAdv;
