import React from "react";
import Links from "../../data/link";
import Config from "../../data/config";
import { Link } from "react-router-dom";

const Services = (props) => {
  const handleActiveLink = (event) => {
    sessionStorage.setItem("header_active", "");
    let mainLink = document.getElementsByClassName("main");
    Array.from(mainLink).forEach((element) =>
      element.classList.remove("active")
    );
    if (event) {
      const targetHref = event.target.closest("a").getAttribute("href");
      Array.from(mainLink).forEach((element) => {
        if (element.getAttribute("href") === targetHref) {
          element.classList.add("active");
          sessionStorage.setItem("header_active", targetHref.replace("/", ""));
        }
      });
    }
  };
  return (
    <section className="why-choose section bg-teklet-deep pb-5 pt-5">
      <div className="container pt-3">
        <div className="d-flex align-content-around justify-content-center flex-wrap">
          <div className="p-2 bd-highlight">
            <Link
              to={{ pathname: Links[111].url, state: { fromDashboard: true } }}
              className="text-decoration-none w-100"
              title={Links[111].title}
              onClick={handleActiveLink}
            >
              <div className="circule">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image max-width-108"
                    alt="img-inmuebles"
                    width="100%"
                    height="100%"
                    src={Config[2].url + "/static/img/inmueble.png"}
                  />
                  <div className="text">INMUEBLES</div>
                </div>
                <div className="bottom pt-3">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
          </div>
          <div className="p-2 bd-highlight">
            <Link
              to={{ pathname: Links[112].url }}
              className="text-decoration-none w-100"
              title={"Bienes Automotores"}
              onClick={handleActiveLink}
            >
              <div className="circule">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image max-width-108"
                    alt="img-vehiculos"
                    width="100%"
                    height="100%"
                    src={Config[2].url + "/static/img/car.png"}
                  />
                  <div className="text">VEHICULOS</div>
                </div>
                <div className="bottom pt-3">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
          </div>
          <div className="p-2 bd-highlight">
            <Link
              to={{ pathname: Links[128].url }}
              className="text-decoration-none w-100"
              title={Links[128].title}
              onClick={handleActiveLink}
            >
              <div className="circule">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image max-width-108"
                    alt="img-patentes"
                    width="100%"
                    height="100%"
                    src={
                      Config[2].url +
                      "/static/img/offcanvas/actividades_econominas.png"
                    }
                  />
                  <div className="text" style={{fontSize: "1em"}}>ACTIVIDADES ECONOMICAS</div>
                </div>
                <div className="bottom pt-3">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
          </div>
          <div className="p-2 bd-highlight">
            <Link
              to="#"
              className="text-decoration-none w-100"
              title={"IMPUESTOS MUNIPAL AL CONSUMO ESPECIFCO"}
            >
              <div className="circule">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image max-width-108"
                    alt="img-imce"
                    width="100%"
                    height="100%"
                    src={Config[2].url + "/static/img/offcanvas/chicha.png"}
                  />
                  <div className="text">IMCE</div>
                </div>
                <div className="bottom pt-3">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
          </div>
          <div className="p-2 bd-highlight">
            <Link
              to={{ pathname: Links[108].url }}
              className="text-decoration-none w-100"
              title={"Operador de Telecomunicaciones"}
              onClick={handleActiveLink}
            >
              <div className="circule">
                <div className="top bg-aero">
                  <img
                    className="mx-auto d-block img-noborder image max-width-108"
                    alt="img-saat"
                    width="100%"
                    height="100%"
                    src={Config[2].url + "/static/img/tower.png"}
                  />
                  <div className="text">SAAT</div>
                </div>
                <div className="bottom pt-3">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
          </div>
          <div className="p-2 bd-highlight">
            <Link
              to={{ pathname: Links[115].url }}
              className="text-decoration-none w-100"
              title={Links[115].title}
              onClick={handleActiveLink}
            >
              <div className="circule">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image max-width-108"
                    alt="img-gaceta-tributaria"
                    width="100%"
                    height="100%"
                    src={Config[2].url + "/static/img/book.png"}
                  />
                  <div className="text">GACETA TRIBUTARIA</div>
                </div>
                <div className="bottom pt-3">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
