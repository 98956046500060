import React, { Component } from "react";
import Constant from "../../data/constant";
import MapOpenLayer from "../component/MapOpenLayer";
import MapCatastroPublicModal from "../component/MapCatastroPublicModal";
import Config from "../../data/config";
import Texto from "../../data/es";
import Languaje from "../../data/es";
import { Link } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import PasosNavigationLicencia from "../../components/utils/PasosNavigationLicencia";
import es from "date-fns/locale/es"; // the locale you want
import ModalSearchDireccion from "../../components/component/ModalSearchDireccion";

registerLocale("es", es); // register it with the name you want
var url_map = "";
var derecho_admision = 0;
class FormDomicilioActEco extends Component {
  constructor(props, context) {
    super(props, context);

    this.domicilioActividadEconomicaDb = null;

    this.constant = Constant[0];
    this.handleMapOnClick = this.handleMapOnClick.bind(this);
    this.handleDateFechaInicioChange =
      this.handleDateFechaInicioChange.bind(this);
    this.handleNumeroOnchange = this.handleNumeroOnchange.bind(this);
    this.handleEdificioOnchange = this.handleEdificioOnchange.bind(this);

    this.state = {
      startDate: null,
      showMapa: false,
      showFormEdificio: false,
      showModalSearch: false,
    };
  }

  componentDidMount() {
    window.inputTextUpperCase();
    window.scrollTo(0, 0);

    if (
      this.props.domicilioActividadEconomicaDb &&
      Object.keys(this.props.domicilioActividadEconomicaDb).length > 0
    ) {
      this.domicilioActividadEconomicaDb =
        this.props.domicilioActividadEconomicaDb;

      let domicilioActEco =
        this.domicilioActividadEconomicaDb.domicilio_actividad_economica;
      let actividad_economica =
        this.domicilioActividadEconomicaDb.actividad_economica;
      let direccion = this.domicilioActividadEconomicaDb.direccion;

      document.getElementsByName(
        "actividad_economica[rotulo_comercial]"
      )[0].value = actividad_economica.rotulo_comercial;
      document.getElementsByName("actividad_economica[superficie]")[0].value =
        actividad_economica.superficie;

      this.setState({
        startDate: new Date(actividad_economica.fecha_inicio),
        showModalSearch: false,
      });

      document.getElementsByName("actividad_economica[comuna]")[0].value =
        actividad_economica.comuna;
      document.getElementsByName("actividad_economica[distrito]")[0].value =
        actividad_economica.distrito;
      document.getElementsByName("actividad_economica[sub_distrito]")[0].value =
        actividad_economica.sub_distrito;
      document.getElementsByName("actividad_economica[num_inmueble]")[0].value =
        actividad_economica.num_inmueble;
      document.getElementsByName("actividad_economica[catastro]")[0].value =
        actividad_economica.catastro;

      document.getElementById("spanDistrito").innerHTML =
        actividad_economica.distrito;
      document.getElementById("spanSubDistrito").innerHTML =
        actividad_economica.sub_distrito;
      document.getElementById("spanComuna").innerHTML =
        actividad_economica.comuna;

      if (direccion) {
        document.getElementsByName(
          "domicilio_actividad_economica[direccion]"
        )[0].value = direccion.TipoDireccion.name + " " + direccion.name;
        document.getElementsByName(
          "domicilio_actividad_economica[id_direccion]"
        )[0].value = direccion.id;
      }

      if (domicilioActEco) {
        url_map = domicilioActEco.image;
        document.getElementsByName(
          "domicilio_actividad_economica[adyacente_entre]"
        )[0].value = domicilioActEco.adyacente_entre
            ? domicilioActEco.adyacente_entre
            : "";
        document.getElementsByName(
          "domicilio_actividad_economica[adyacente_y]"
        )[0].value = domicilioActEco.adyacente_y
            ? domicilioActEco.adyacente_y
            : "";
        document.getElementsByName(
          "domicilio_actividad_economica[zona]"
        )[0].value = domicilioActEco.zona;
        document.getElementById("spanZonaTributaria").innerHTML =
          domicilioActEco.zona;

        if (
          Boolean(domicilioActEco.numero) &&
          domicilioActEco.numero !== ""
        ) {
          document.getElementById("checkNumero").checked = false;
          var event = new Event("onchange", {
            bubbles: true,
            cancelable: true,
          });

          document.getElementById("checkNumero").dispatchEvent(event);
          this.handleNumeroOnchange(event);

          document.getElementsByName(
            "domicilio_actividad_economica[numero]"
          )[0].value = domicilioActEco.numero;
          document.getElementsByName(
            "domicilio_actividad_economica[numero]"
          )[0].readOnly = false;
          document.getElementById("checkNumero").checked = true;
        }

        document.getElementsByName(
          "domicilio_actividad_economica[telefono]"
        )[0].value = domicilioActEco.telefono;
        document.getElementsByName(
          "domicilio_actividad_economica[celular]"
        )[0].value = domicilioActEco.celular;

        document.getElementsByName(
          "domicilio_actividad_economica[latitud]"
        )[0].value = domicilioActEco.latitud;
        document.getElementsByName(
          "domicilio_actividad_economica[longitud]"
        )[0].value = domicilioActEco.longitud;
        document.getElementsByName(
          "domicilio_actividad_economica[coordinate]"
        )[0].value = domicilioActEco.coordinate;

        if (
          Boolean(domicilioActEco.edificio) &&
          domicilioActEco.edificio !== ""
        ) {
          document.getElementById("checkEdificio").checked = false;
          const event = new Event("onchange", {
            bubbles: true,
            cancelable: true,
          });
          document.getElementById("checkEdificio").dispatchEvent(event);
          this.handleEdificioOnchange(event);
          document.getElementsByName(
            "domicilio_actividad_economica[edificio]"
          )[0].value = domicilioActEco.edificio;
          this.setState({
            showMapa: true,
            showFormEdificio: true,
            showModalSearch: false,
          });
        } else {
          this.setState({
            showMapa: true,
            showFormEdificio: false,
            showModalSearch: false,
          });
        }
      }
    }

    var self = this;
    window.jQuery("#modalMapCatastro").on("hidden.bs.modal", function () {
      if (
        document.getElementsByName(self.props.nameForm + "[latitud]")[0].value
          .length === 0 &&
        document.getElementsByName(self.props.nameForm + "[latitud]")[0].value
          .length === 0
      ) {
        if (self.state.showMapa === true)
          self.setState({ showMapa: false, showModalSearch: false });
      }
    });

    window.jQuery(".linkHelpFDAE").popover({
      title:
        '<h3 class="custom-title"><i class="fa fa-map-marker"></i> Dirección</h3>',
      content:
        "<p>Debe consignar obligatoriamente la direccion descriptiva de la Actividad Económica (Avenida/Calle/Pasaje) y sus colindantes (Avenida/Calle/Pasaje).</p>",
      html: true,
    });

    window.jQuery(".linkHelpFDAdyaAE").popover({
      title:
        '<h3 class="custom-title"><i class="fa fa-map-marker"></i> Adyacentes</h3>',
      content:
        "<p>Debe consignar obligatoriamente las calles o avenidas Adyacentes a su domicilio.</p>",
      html: true,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.showMapa === true && url_map !== "") {
      document.getElementById(this.props.nameForm + "[image]").src =
        "data:image/png;base64, " + url_map;
    }

    if (
      this.state.showFormEdificio &&
      Boolean(this.domicilioActividadEconomicaDb)
    ) {
      let domicilioActEco =
        this.domicilioActividadEconomicaDb.domicilio_actividad_economica;
      if (Boolean(domicilioActEco)) {
        document.getElementsByName(
          "domicilio_actividad_economica[bloque]"
        )[0].value = domicilioActEco.bloque;
        document.getElementsByName(
          "domicilio_actividad_economica[piso]"
        )[0].value = domicilioActEco.piso;
        document.getElementsByName(
          "domicilio_actividad_economica[dpto_of_local]"
        )[0].value = domicilioActEco.dpto_of_local;
        document.getElementsByName(
          this.props.nameForm + "[edificio]"
        )[0].readOnly = false;
        document
          .getElementsByName(this.props.nameForm + "[edificio]")[0]
          .setAttribute("data-parsley-required", true);
        document.getElementById("checkEdificio").checked = true;
      }
    }
  }

  handleMapOnClick(event) {
    event.preventDefault();
    window.jQuery("#modalMapCatastro").modal("show");

    if (this.state.showMapa === false)
      this.setState({ showMapa: true, showModalSearch: false });
  }

  handleDateFechaInicioChange(date) {
    this.setState({
      startDate: date,
      showModalSearch: false,
    });
  }

  handleNumeroOnchange(event) {
    if (event.target.checked) {
      document.getElementsByName(
        this.props.nameForm + "[numero]"
      )[0].readOnly = false;
      document.getElementsByName(
        this.props.nameForm + "[numero]"
      )[0].placeholder = Texto.numero;
      document
        .getElementsByName(this.props.nameForm + "[numero]")[0]
        .setAttribute("data-parsley-required", true);
    } else {
      document.getElementsByName(
        this.props.nameForm + "[numero]"
      )[0].readOnly = true;
      document.getElementsByName(this.props.nameForm + "[numero]")[0].value =
        "";
      document.getElementsByName(
        this.props.nameForm + "[numero]"
      )[0].placeholder = "S/N";
      document
        .getElementsByName(this.props.nameForm + "[numero]")[0]
        .setAttribute("data-parsley-required", false);
    }
  }

  handleEdificioOnchange(event) {
    if (event.target.checked) {
      document.getElementsByName(
        this.props.nameForm + "[edificio]"
      )[0].readOnly = false;
      document
        .getElementsByName(this.props.nameForm + "[edificio]")[0]
        .setAttribute("data-parsley-required", true);
      this.setState({ showFormEdificio: true, showModalSearch: false });
    } else {
      document.getElementsByName(
        this.props.nameForm + "[edificio]"
      )[0].readOnly = true;
      document.getElementsByName(this.props.nameForm + "[edificio]")[0].value =
        "";
      document
        .getElementsByName(this.props.nameForm + "[edificio]")[0]
        .setAttribute("data-parsley-required", false);

      if (
        document.getElementsByName(this.props.nameForm + "[bloque]").length > 0
      )
        document.getElementsByName(this.props.nameForm + "[bloque]")[0].value =
          "";
      if (document.getElementsByName(this.props.nameForm + "[piso]").length > 0)
        document.getElementsByName(this.props.nameForm + "[piso]")[0].value =
          "";
      if (
        document.getElementsByName(this.props.nameForm + "[dpto_of_local]")
          .length > 0
      )
        document.getElementsByName(
          this.props.nameForm + "[dpto_of_local]"
        )[0].value = "";
      this.setState({ showFormEdificio: false, showModalSearch: false });
    }
  }

  openModalSearchDireccion = (event) => {
    event.preventDefault();
    let comuna_input = document.getElementsByName(
      "actividad_economica[comuna]"
    )[0];
    if (comuna_input.value !== "") this.setState({ showModalSearch: true });
    else
      window.alertBootBox(
        "Debe seleccionar la ubicación de la actividad ecónomica en el mapa interactivo "
      );
  };


  handleEventMap(dataInmuebleCatastro) {
    url_map = dataInmuebleCatastro.base64Image
    document.getElementById("domicilio_actividad_economica[image]").src =
      dataInmuebleCatastro.fullBase6image4;
    document.getElementsByName(
      "domicilio_actividad_economica[image]"
    )[0].value = dataInmuebleCatastro.base64Image;
    document.getElementById("mapUbicacionActividadEconomica").innerHTML =
      "";
  };

  render() {
    if (
      this.props.hasOwnProperty("declaracionJurada") &&
      this.props.declaracionJurada
    ) {
      if (this.props.declaracionJurada.hasOwnProperty("derecho_admision"))
        derecho_admision = this.props.declaracionJurada.derecho_admision;
    }
    return (
      <div className="row">
        <PasosNavigationLicencia
          titulo_paso1={
            parseInt(derecho_admision) ===
              parseInt(Constant[0].derecho_admision.permanente)
              ? Languaje.actividad_economica_permanente
              : Languaje.actividad_economica_temporal
          }
          paso1_active={true}
          paso2_active={true}
          paso3_active={true}
          paso4_active={true}
          paso5_active={false}
        />
        <form
          action=""
          className="contact__form needs-validation"
          name="formDomicilioActEco"
          id="formDomicilioActEco"
          method="post"
          noValidate
          onSubmit={this.props.onSubmitForm}
          style={{ width: "100%" }}
        >
          <div className="row">
            <div className="col-12 ">
              <h5 className="color-gris">
                1 {Texto.ubicacion_actividad_economica}
              </h5>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 text-center">
              {this.state.showMapa ? (
                <img
                  id={this.props.nameForm + "[image]"}
                  className="img-thumbnail rounded mx-auto d-block"
                  alt="img-domicilio-actividad-economica"
                  src={""}
                />
              ) : (
                <div className="folded-corner service_tab_1 folded-corner-rounded">
                  <Link
                    to={"#0"}
                    title="Click Aqui Para Ubicar tu Domicilio"
                    onClick={this.handleMapOnClick}
                  >
                    <div className="text">
                      <i className="fa fa-map fa-5x fa-icon-image"></i>
                    </div>
                  </Link>
                  <i
                    className="fa fa-arrow-up"
                    aria-hidden="true"
                    style={{
                      display: "block",
                      fontSize: "3em",
                      color: "#dc3545",
                    }}
                  ></i>
                  <Link
                    to={"#0"}
                    title="Click Aqui"
                    className="item-title"
                    onClick={this.handleMapOnClick}
                  >
                    Click Aqui
                  </Link>
                </div>
              )}
            </div>

            <div className="col-12 col-sm-6 col-md-3 col-lg-3">
              <strong>{Texto.distrito}:</strong> <span id="spanDistrito"></span>
            </div>

            <div className="col-12 col-sm-6 col-md-3 col-lg-3">
              <strong>{Texto.sub_distrito}:</strong>{" "}
              <span id="spanSubDistrito"></span>
            </div>

            <div className="col-12 col-sm-6 col-md-3 col-lg-3">
              <strong>{Texto.comuna}:</strong> <span id="spanComuna"></span>
            </div>

            <div className="col-12 col-sm-6 col-md-3 col-lg-3">
              <strong>{Texto.zona_tributaria}:</strong>{" "}
              <span id="spanZonaTributaria"></span>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-12 ">
              <h5 className="color-gris">
                2 {Texto.informacion_complementaria}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 mb-3">
              <label
                htmlFor={this.props.nameForm + "[direccion]"}
                className="form-label"
              >
                Dirección *
              </label>
              <button
                type="button"
                className="btn link-help linkHelpFDAE"
                id="linkHelpFDAE"
                style={{ float: "right" }}
              >
                <i className="fa fa-question-circle" aria-hidden="true"></i>
              </button>
              <div className="input-group ">
                <input
                  name={this.props.nameForm + "[direccion]"}
                  type="text"
                  className="form-control input-uppercase"
                  placeholder="Dirección"
                  data-parsley-required="true"
                  required
                  pattern="[.,a-zA-Z'0-9 À-ÿ\u00f1\u00d1]+"
                  data-parsley-pattern="[.,a-zA-Z'0-9 À-ÿ\u00f1\u00d1]+"
                  readOnly
                />

                <input
                  name={this.props.nameForm + "[id_direccion]"}
                  type="hidden"
                />
                <div className="input-group-prepend">
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={this.openModalSearchDireccion}
                  >
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-10 col-lg-10">
              <label
                htmlFor={this.props.nameForm + "[adyacente_entre]"}
                className="form-label"
              >
                Calle/Avenida Adyacente *
              </label>
              <button
                type="button"
                className="btn link-help linkHelpFDAdyaAE"
                id="linkHelpFDAdyaAE"
                style={{ float: "right" }}
              >
                <i className="fa fa-question-circle" aria-hidden="true"></i>
              </button>

              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <div className="input-group-text bg-white border-0">
                    <span>Entre</span>
                  </div>
                </div>
                <input
                  name={this.props.nameForm + "[adyacente_entre]"}
                  type="text"
                  className="form-control input-uppercase"
                  placeholder=""
                  data-parsley-required="true"
                  required
                  pattern="[.,a-zA-Z0-9 À-ÿ\u00f1\u00d1]+"
                  data-parsley-pattern="[.,a-zA-Z0-9 À-ÿ\u00f1\u00d1]+"
                />

                <div className="input-group-prepend">
                  <div className="input-group-text bg-white border-0">
                    <span>Y</span>
                  </div>
                </div>
                <input
                  name={this.props.nameForm + "[adyacente_y]"}
                  type="text"
                  className="form-control input-uppercase"
                  placeholder=""
                  data-parsley-required="true"
                  required
                  pattern="[.,a-zA-Z0-9 À-ÿ\u00f1\u00d1]+"
                  data-parsley-pattern="[.,a-zA-Z0-9 À-ÿ\u00f1\u00d1]+"
                />
              </div>
            </div>

            <div className="col-12 col-md-2 col-lg-2 mb-3">
              <label
                htmlFor={this.props.nameForm + "[numero]"}
                className="form-label"
              >
                Número
              </label>
              <div className="input-group mb-3">
                <div className="input-group-text">
                  <input
                    type="checkbox"
                    id="checkNumero"
                    className="form-check-input"
                    onChange={this.handleNumeroOnchange}
                  />
                </div>
                <input
                  type="text"
                  className="form-control input-uppercase"
                  name={this.props.nameForm + "[numero]"}
                  placeholder="S/N"
                  data-parsley-required="false"
                  pattern="[0-9]+"
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-5 col-lg-5 mb-3">
              <label
                htmlFor={this.props.nameForm + "[edificio]"}
                className="form-label"
              >
                Nombre Edificio
              </label>
              <div className="input-group mb-3">
                <div className="input-group-text ">
                  <input
                    type="checkbox"
                    id="checkEdificio"
                    onChange={this.handleEdificioOnchange}
                  />
                </div>
                <input
                  name={this.props.nameForm + "[edificio]"}
                  type="text"
                  className="form-control input-uppercase"
                  placeholder="Nombre Edificio"
                  data-parsley-required="false"
                  pattern="[a-zA-Z0-9 À-ÿ\u00f1\u00d1]+"
                  data-parsley-pattern="[a-zA-Z0-9 À-ÿ\u00f1\u00d1]+"
                  readOnly
                />
              </div>
            </div>
            {this.state.showFormEdificio ? (
              <div className="col-12 col-md-2 col-lg-2 mb-3">
                <label
                  htmlFor={this.props.nameForm + "[bloque]"}
                  className="form-label"
                >
                  Bloque
                </label>
                <input
                  name={this.props.nameForm + "[bloque]"}
                  type="text"
                  className="form-control input-uppercase"
                  placeholder="Bloque"
                  data-parsley-required="false"
                  pattern="[a-zA-Z-0-9]+"
                  data-parsley-pattern="[a-zA-Z-0-9]+"
                />
              </div>
            ) : (
              ""
            )}

            {this.state.showFormEdificio ? (
              <div className="col-12 col-md-2 col-lg-2 mb-3">
                <label
                  htmlFor={this.props.nameForm + "[piso]"}
                  className="form-label"
                >
                  Número de Piso
                </label>
                <input
                  name={this.props.nameForm + "[piso]"}
                  type="text"
                  className="form-control input-uppercase"
                  placeholder="Número de Piso"
                  data-parsley-required="false"
                  pattern="[0-9]+"
                  data-parsley-pattern="[0-9]+"
                />
              </div>
            ) : (
              ""
            )}

            {this.state.showFormEdificio ? (
              <div className="col-12 col-md-3 col-lg-3 mb-3">
                <label
                  htmlFor={this.props.nameForm + "[dpto_of_local]"}
                  className="form-label"
                >
                  Dpto/Of./Local
                </label>
                <input
                  name={this.props.nameForm + "[dpto_of_local]"}
                  className="form-control input-uppercase"
                  maxLength="10"
                  data-parsley-maxlength="10"
                  placeholder="Departamento/Of./Local"
                  data-parsley-required="false"
                  pattern="[a-zA-Z-0-9]+"
                  data-parsley-pattern="[a-zA-Z-0-9]+"
                />
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="row">
            <div className="col-12 col-md-4 col-lg-4 mb-3">
              <label
                htmlFor={this.props.nameForm + "[telefono]"}
                className="form-label"
              >
                Teléfono Fijo
              </label>
              <input
                name={this.props.nameForm + "[telefono]"}
                type="text"
                className="form-control input-uppercase"
                placeholder="Teléfono Fijo"
                data-parsley-required="false"
                data-parsley-minlength="7"
                minLength="7"
                data-parsley-maxlength="7"
                maxLength="7"
                data-parsley-pattern="[0-9]+"
              />
            </div>

            <div className="col-12 col-md-4 col-lg-4 mb-3">
              <label
                htmlFor={this.props.nameForm + "[celular]"}
                className="form-label"
              >
                Teléfono Móvil *
              </label>
              <input
                name={this.props.nameForm + "[celular]"}
                type="text"
                className="form-control input-uppercase"
                placeholder="Teléfono Móvil"
                data-parsley-required="true"
                required
                data-parsley-minlength="8"
                data-parsley-maxlength="8"
                minLength="8"
                maxLength="8"
                data-parsley-pattern="[0-9]+"
              />
            </div>
          </div>

          <br />

          <div className="row">
            <div className="col-12 mb-3">
              <h5 className="color-gris">3 {Texto.descripcion}</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-4 col-lg-4 mb-3">
              <label
                htmlFor="actividad_economica[rotulo_comercial]"
                style={{ display: "block" }}
                className="form-label"
              >
                Rótulo Comercial *{" "}
              </label>
              <input
                name="actividad_economica[rotulo_comercial]"
                type="text"
                className="form-control input-uppercase"
                placeholder="Rotulo Comercial"
                data-parsley-required="true"
                required
                pattern="[.&a-zA-Z0-9 À-ÿ\u00f1\u00d1-]+"
                data-parsley-pattern="[.&a-zA-Z0-9 À-ÿ\u00f1\u00d1-]+"
              />
            </div>

            <div className="col-12 col-12 col-md-4 col-lg-4 mb-3">
              <label
                htmlFor="actividad_economica[superficie]"
                style={{ display: "block" }}
                className="form-label"
              >
                Superficie(m2) *{" "}
              </label>
              <input
                name="actividad_economica[superficie]"
                type="text"
                className="form-control input-uppercase"
                placeholder="Superficie"
                data-parsley-required="true"
                required
                pattern="[0-9]+"
                data-parsley-pattern="[0-9]+"
              />
            </div>
            <div className="col-12 col-md-4 col-lg-4 mb-3">
              <label
                htmlFor="actividad_economica[fecha_inicio]"
                style={{ display: "block" }}
                className="form-label"
              >
                Fecha Inicio (DD/MM/YYYY) *{" "}
              </label>
              <DatePicker
                locale="es"
                dateFormat={Config[4].format}
                selected={this.state.startDate}
                onChange={this.handleDateFechaInicioChange}
                maxDate={new Date()}
                className="form-control"
                name="actividad_economica[fecha_inicio]"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                required
              />
            </div>
          </div>

          <div className="row">
            <input name="actividad_economica[predio]" type="hidden" />
            <input name="actividad_economica[catastro]" type="hidden" />
            <input name={this.props.nameForm + "[latitud]"} type="hidden" />
            <input name={this.props.nameForm + "[longitud]"} type="hidden" />
            <input name={this.props.nameForm + "[coordinate]"} type="hidden" />
            <input name={this.props.nameForm + "[image]"} type="hidden" />
            <input name={this.props.nameForm + "[zona]"} type="hidden" />
            <input name="actividad_economica[num_inmueble]" type="hidden" />
            <input name="actividad_economica[comuna]" type="hidden" />

            <input name="actividad_economica[distrito]" type="hidden" />
            <input name="actividad_economica[sub_distrito]" type="hidden" />
          </div>

          <div className="row">
            <div className="col-12 col-md-6 col-lg-6 mb-4 text-start">
              <div className="search-btn button ">
                <button
                  className="btn bg-casa-blanca btn-full-screen"
                  type="button"
                  id={"btn_domicilio_mapa"}
                  onClick={this.handleMapOnClick}
                >
                  <i className="fas fa-map-marker-alt" aria-hidden="true"></i>{" "}
                  Ubica tú Actividad en el Mapa
                </button>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-6 mb-4 text-end">
              <div className="search-btn button ">
                <input
                  name="Siguiente"
                  type="submit"
                  className="btn btn-full-screen"
                  value={this.props.buttonName}
                />
              </div>
            </div>
          </div>
        </form>
        <MapCatastroPublicModal
          module={"actividad_economica"}
          nameForm={this.props.nameForm}
          fillPaint={true}
          width="724"
          height="261"
          title="Ubicación"
          handleEventMap={this.handleEventMap}
        />
        <MapOpenLayer module={"actividad_economica"} />{" "}
        <ModalSearchDireccion
          open={this.state.showModalSearch}
          module={"actividad_economica"}
        />
      </div>
    );
  }
}

export default FormDomicilioActEco;