export default {
    "locale": "en-us",
    "licencia_actividad_economica": "Licencia de Funcionamiento",
    "licencia_actividad_economica_ruat": "Licencia de Funcionamiento RUAT",
    "actividad_economica": "Actividad Económica",
    "actividad_economica_permanente": "Actividad Económica Permanente",
    "actividad_economica_temporal": "Actividad Económica Temporal",
    "persona_juridica": "Persona Juridica",
    "persona_natural": "Persona Natural",
    "solicitante": "Solicitante",
    "perfil": "Perfil",
    "perfil_information": "Información de Perfil",
    "perfil_edit": "Editar Perfil",
    "perfil_picture": "Imagen Perfil",
    "change_password": "Cambiar Contraseña",
    "login_history": "Historial Sesión",
    "representante_legal": "Representante Legal",
    "domicilio_persona_o_representante_legal": "Domicilio de la Persona Natural y/o Representante Legal",
    "ubicacion_actividad_economica": " Ubicación de la Actividad Económica",
    "resumen_datos_licencia_actividad_economica": "Resumen de Datos de la Actividad Económica",
    "datos_actividad_economica": "Datos de la Actividad Económica",
    "domicilio_actividad_economica": "Domicilio de la Actividad Económica",
    "descripcion_actividad_economica": "Descripción de la Actividad Económica",
    "datos_solicitante": "Datos del Solicitante",
    "datos_contribuyente": "Datos del Contribuyente",
    "contribuyente": "Contribuyente",
    "no_account": "¿No tienes una cuenta?, haz click aqui para abrir una cuenta.",
    "lost_password": "¿Olvidaste tu contraseña?",
    "autentificacion_usuario": "Iniciar Sesión",
    "reenviar_activacion_cuenta": "¿No te llego el mail de Activación?. Haz click aquí, para solicitar una nueva activación de tu cuenta.",
    "reactivacion_cuenta": "Reactivación de Cuenta",
    "login": "Iniciar Sesión",
    "account_user": "Cuenta de Usuario",
    "campos_obligatorios": "El formulario tiene valores obligatorios",
    "mapa_required": "Debe seleccionar la ubicación de su domicilio en el mapa",
    "espere_redireccionamos_pagina": "Espere mientras redireccionamos la página",
    "account_not_active": "Cuenta no activada",
    "confirm_account": "Confirmación de Cuenta de Usuario",
    "now_can_init_session": "Ahora puedes iniciar Sesión haciendo click en el botón de abajo.",
    "aerial": "Aereo",
    "aerial_with_label": "Aereo Con Etiquetas",
    "road_static": "Carretera Estática",
    "road_dinamic": "Carretera Dinámica",
    "distrito": "Distrito",
    "sub_distrito": "Sub Distrito",
    "comuna": "Comuna",
    "zona_tributaria": "Zona Tributaria",
    "informacion_complementaria": "Información Complementaria",
    "descripcion": "Descripción",
    "numero_orden": "Número de Orden",
    "nro_orden": "Nro. de Orden",
    "categoria": "Categoría",
    "numero_fur": "Nro. de FUR",
    "numero": "Número",
    "estado": "Estado",
    "continuar": "Continuar",
    "cancelar": "Cancelar",
    "temporal": "Temporal",
    "enviar_mail": "Enviar un Correo Electrónico a ",
    "permanente": "Permanente",
    "there_are_no_records_to_display": "No se encontrarón registros.",
    "eliminar": "Eliminar",
    "tipo_actividad_economica": "Tipo Actividad Económica",
    "register_new_account": "Registro de Nueva Cuenta",
    "search": "Buscar",
    "forgot_password": "Encuentra tu Cuenta",
    "editar_mis_datos": "Actualizar Datos",
    "editar_datos_prescripcion": "Editar Datos de la Prescripción",
    "estoy_de_acuerdo": "Estoy de Acuerdo",
    "register_account": "Registrar Cuenta",
    "confirmar_mis_datos": "Confirmar Mis Datos",
    "ingresa_tipo_actividad_economica": "Ingresa el tipo de Actividad Económica",
    "description_contactanos": "La ADMINISTRACION TRIBUTARIA MUNICIPAL del GAMC,  valora la comunicación sobresaliente y la atención al contribuyente, por ello es importante para nosotros conocer tu opinión y sugerencia.",
    "criterio_busqueda_no_corresponde": "Ninguna Actividad Económica corresponde al criterio de búsqueda",
    "falsedad_de_datos_documento": "Tengo conocimiento que de existir falsedad en los datos consignados en el presente formulario la misma constituye un delito tipificado en el parágrafo II del artículo 345 BIS del Código Penal Boliviano.",
    "numero_orden_perderan_datos": "El Número de Orden %s. Tenga en cuenta que se perderan todos los datos",
    "numero_orden_sincronizar_datos": "¿Seguró de querer sincronizar el Número de Orden %s?. Tenga en cuenta que dichos datos se enviarán al sistema RUAT.",
    "ha_ocurrido_un_error_pagina_no_encontrada": "Lo Sentimos, Ha Ocurrido un Error, Pagina Solicitada no Encontrada!",
    "page_not_found": "404 Pagina no Encontrada",
    "debe_seleccionar_predio_mapa": "Debe seleccionar un predio valido en el mapa",
    "create_account": "Crear Cuenta",
    "start_sesion": "Inicia Sesión",
    "you_need_usser_and_password": "Necesitas un usuario y una contraseña.",
    "is_fast_easy": "Es rápido y fácil.",
    "enviar": "Enviar",
    "enviando": "Enviando...",
    "usuario": "Usuario",
    "usuarios": "Usuarios",
    "ci": "Cédula Identidad",
    "nombre": "Nombre",
    "apellido": "Apellido",
    "intentos_fur": "Intentos Fur",
    "action": "Acción",
    "anterior": "Anterior",
    "nuevo": "Nuevo",
    "solicitado": "Solicitado",
    "creado": "Creado",
    "actividades": "Actividades",
    "ip": "IP",
    "dispositivo": "Dispositivo",
    "navegador": "Navegador",
    "description": "Descripción",
    "increment_request_licence": "Incrementador de Solicitudes de Licencias",
    "form_have_inputs_required": "'El formulario tiene valores obligatorios",
    "sub_alcaldia": "Sub Alcaldia",
    //"distrito": "Distrito",
    //"sub_distrito": "Sub Distrito",
    "tipo_direccion": "Tipo Dirección",
    "direccion": "Dirección",
    "certificacion": "Certificación",
    "certificaciones": "Certificaciones",
    "prescripcion": "Prescripción",
    "prescripcion_contribuyente_natural": "Prescripción Contr. Natural",
    "prescripcion_contribuyente_juridica": "Prescripción Contr. Juridica",
    "prescripcion_inmuebles": "Prescripción Inmuebles",
    "prescripcion_actividades_economicas": "Prescripción Actividades Económicas",
    "prescripcion_vehiculos": "Prescripción Vehículos",
    "datos_representante_legal": "Datos del Representante Legal",
    "objetoTributario": "Objeto Tributario",
    "gestiones_prescribir": "Gestiones a Prescribir",
    "notificaciones": "Notificaciones",
    "notificaciones_mixtas": "Notificaciones Mixtas",
    "no_resultados": "No se encontraron resultados",
    "ingreso_gestion_valida": "Ingrese una gestion valida 'Ej. 2016'",
    "publicado": "Publicado",
    "el": "el",
    "cumple": "Cumple",
    "cumplimiento_requisito": "Cumplimiento de Requisitos",
    "terceros_responsables_solicitantes": "Terceros Responsables o Solicitantes",
    "administracion_tributaria_municipal": "Administración Tributaria Municipal",
    "gobierno_autonomo_municipal_cbba": "Gobierno Autónomo Municipal de Cochabamba",
    "tramite_vehiculo": "Trámite de Vehículos",
    "contactanos": "Contáctanos",
    "departamentos": "Departamentos",
    "atm": "ATM",
    "herramientas": "Herramientas",
    "plataforma": "Plataforma",
    "fiscalizacion": "Fiscalización",
    "legal_tributario": "Legal Tributario",
    "norma_tributaria": "Normativa Tributaría",
    "gaceta_tributaria": "Gaceta Tributaría",
    "perdonazo2020": "Perdonazo 2020",
    "ddjj": "Declaraciones Juradas",
    "terceraedad": "Tercera Edad",
    "serviciopublico": "Servicio Público",
    "sitiomercado": "Sitios y Mercados",
    "gestion": "Gestión",
    "objeto_tributario": "Objeto Tributario",
    "aprobado": "Aprobado",
    "ningun_registro_encontrado": "No se ha encontrado ningun registro, con la busqueda solicitada.",
    "liquidaciones": "Liquidaciones",
    "liquidaciones_mixtas": "Liquidaciones Mixtas",
    "consulta_tu_deuda": "Consulta tu Deuda",
    "vehiculos": "Vehículos",
    "sitios_mercados": "Sitios y Mercados",
    "publicidad_exterior": "Publicidad Exterior",
    "actividades_economicas": "Actividades Economicas",
    "inmuebles": "Inmuebles",
    "inmueble": "Inmueble",
    "datos_propietario": "Datos Propietario",
    "datos_tecnicos_generales": "Datos Técnicos Generales",
    "historial": "Historial",
    "liquidacion": "Liquidación",
    "gestiones_a_solicitar": "Gestiones a Solicitar",
    "datos_administrativos_legales": "Datos Administrativos y Legales",
    "ingresos_tributarios": "Ingresos Tributarios",
    "datos_certificacion": "Datos Certificación",
    "tramites": "Trámites",
    "busqueda_vehiculo": "Busqueda Vehículo",
    "solicitud_certificado_transporte_servicio_publico": "Solicitud de Certificado de Transporte de Servicio Publico",
    "datos_servicio_transporte": "Datos Servicio de Transporte",
    "datos_identificacion_vehiculo": "Datos Identificación Vehículo",
    "datos_tecnicos": "Datos Técnicos",
    "datos_deuda": "Datos Deuda",
    "estado_vehiculo": "Estado Vehículo",
    "datos_propietario_tercero_responsable_poseedor": "Datos propietario / tercero responsable(apoderado) / poseedor",
    "linea_servicio_publico": "Línea de Servicio Público",
    "busqueda_no_corresponde": "Ningún elemento coincide con el criterio ingresado.",
    "derechos_reservados": "Derechos Reservados",
    "guia": "Guía",
    "nro_inmueble": "Nro. Inmueble",
    "tutorial": "Tutorial",
    "quienes_somos": "Quienes Somos?",
    "fecha_certificacion": "Fecha Certificación",
    "fecha_vencimiento": "Fecha Vencimiento",
    "fecha_rango": "Fecha Rango",
    "dias_autorizacion": "Días Autorización",
    "codigo_propiedad": "Código Propiedad",
    "codigo_inmueble": "Código Inmueble",
    "titular": "Títular",
    "tercero_responsable": "Tercero Responsable",
    "nombre_completo": "Nombre Completo",
    "numero_documento": "Número Documento",
    "nro_documento": "Nro. Documento",
    "numero_poder": "Número Poder",
    "numero_copias": "Número Copias",
    "numero_copias_funcionario_resumida": "Nro. Copias Func. Resumida",
    "numero_copias_funcionario_detallada": "Nro. Copias Func. Detallada",
    "cambio_radicatoria": "Cambio Radicatoria",
    "datos_poseedor": "Datos Poseedor",
    "datos_complementarios_poseedor": "Datos Complementarios del Poseedor",
    "datos_apoderado_representante_legal": "Datos del Apoderado o Representante Legal",
    "documentos_poseedor": "Documentos del Poseedor",
    "placa": "Placa",
    "marca": "Marca",
    "tipo": "Tipo",
    "servicio": "Servicio",
    "datos_vehiculo": "Datos Vehículo",
    "clase": "Clase",
    "color": "Color",
    "modelo": "Modelo",
    "documento": "Documento",
    "numero_puertas": "Número de Puertas",
    "apellido_casada": "Apellido Casada",
    "estado_civil": "Estado Civil",
    "datos_actuales_tipo_actividad": "Datos Actuales del Tipo de Actividad ",
    "datos_actuales_actividad_economica": "Datos Actuales Actividad Económica ",
    "edit_tipo_actividad_economica": "Edición Tipo Actividad Económica",
    "infracciones_sanciones": "Infracciones y Sancciones",
    "piet": "Proceso de Inicio de Ejecución Tributaria",
    "piet_actividades_economicas": "PIET - Actividades Económicas",
    "piet_vehiculos": "PIET - Vehículos",
    "piet_inmuebles": "PIET - Inmuebles",
    "numero_plaza": "Número Plaza",
    "visacion_minutas": "Visación Minutas",
    "matricula_colindantes": "Matricula y Colindantes",
    "imagen_catastral": "Imagen Catrastal",
    "datos_sujeto_pasivo_vendedor": "Datos Vendedor o Sujeto Pasivo",
    "pmc": "PMC",
    "documento_identidad": "Documento Identidad",
    "tipo_contribuyente": "Tipo Contribuyente",
    "tipo_documento": "Tipo Documento",
    "tipo_doc": "Tipo Doc.",
    "expedido": "Expedido En",
    "fecha_nacimiento": "Fecha Nacimiento",
    "genero": "Género",
    "razon_social": "Razón Social",
    "fecha_constitucion": "Fecha Constitución",
    "tipo_contribuyente_juridico": "Tipo Contr. Jurídico",
    "datos_comprador": "Datos Comprador(es)",
    "copropietarios": "Copropietarios",
    "datos_minuta": "Datos Minuta",
    "datos_vendedor": "Datos Vendedor",
    "datos_certificado": "Datos Certificado",
    "propietarios": "Propietarios",
    "vendedor": "Vendedor",
    "colindates": "Colindates",
    "compradores": "Compradores",
    "construcciones": "Construcciones",
    "construcciones_adicionales": "Construcciones Adicionales",
    "catastro": "Catastro",
    "ruat": "Ruat",
    "contribuyente_y_o_representante_legal": "Propietario/Representante Legal",
    "fur_preliquidacion": "Fur Pre-Liquidación",
    "datos_propiedad": "Datos Propiedad",
    "tipo_visacion_minuta": "Tipo Visación Minuta",
    "datos": "Datos",
    "numero_inmueble": "Número Inmueble",
    "ras_vehiculos": "RAS - Vehículos",
    "fur_sin_costo": "FUR Gratuito",
    "account": "Cuenta",
    "tramite": "Trámite",
    "nro_orden_tramite": "Nro. de Orden Trámite",
    "tipo_sociedad": "Tipo Sociedad",
    "sigla": "Sigla",
    "fecha": "Fecha",
    "observacion": "Observación",
    "poder": "Poder",
    "comprador_beneficiario": "Comprador/Beneficiario",
    "contribuyentes": "Contribuyentes",
    "padron_municipal_contribuyente": "Padrón Municipal de Contribuyentes",
    "seleccione_padron_actualizado": "Seleccione Padron Municipal Contribuyente (PMC) Actualizado",
    "seleccione_pmc": "Seleccione el PMC (Padrón Municipal del Contribuyente)",
    "sentencia_judicial": "Sentencia Judicial",
    "nro_pta": "Nro. Placa",
    "vehiculo": "Vehículo",
    "nro_poliza": "Nro. Poliza",
    "codigo_vehiculo": "Código Vehículo",
    "imce_chicha": "IMCE-Chicha",
    "autorizacion_telecomunicaciones": "Autorización Telecomunicaciones",
    "requisitos_whit_document": "Todos los requisitos deben tener asociado su respectivo documento",
    "review_pending": "Pendiente Revisión",
    "requisito": "Requisito",
    "restriction": "Restricción",
    "aplica": "aplica",
    "emplazamiento_restriction": "Emplazamientos y Restricciones",
    "all_are_obligation": "Todos los Emplazamientos y Restricciones son obligatorios",
    "predio": "Predio",
    "tipo_procedimiento": "Tipo Procedimiento",
    "autorizacion": "Autorización",
    "operador": "Operador",
    "fecha_poder": "Fecha Poder",
    "datos_catastro": "Datos Catastrales",
    "operador_redes_telecomunicaciones": "Operador de Redes de Telecomunicaciones",
    "datos_requisitos": "Datos Requisitos",
    "emplazamientos": "Emplazamientos",
    "emplazamientos_restricciones": "Emplazamientos/Restricciones",
    "liquidaciones_pendientes": "Liquidaciones Mixtas",
    "archivo_adjunto": 'Archivo adjunto',
    "acciones": 'Acciones',
    "obj_tributario": 'Obj. Tributario',
    "nro_publicacion": 'Nro. Publicacion',
    'liquidacion_crear': 'Nuevo registro',
    'liquidacion_inicio': 'Liquidaciones Mixtas',
    'inmueble_nt': "Nº Inmueble",
    'vehiculo_nt': "Nº Placa o poliza",
    'actividad_economica_nt': "Nº licencia",
    'publicidad_exterior_nt': "Nº actividad",
    'impbm_inmueble': 'IMPUESTOS MUNICIPAL A LA PROPIEDAD DE BIENES INMUEBLES',
    'imto_inmueble': 'IMPUESTO MUNICIPAL A LA TRANSFERENCIA ONEROSA DE BIENES INMUEBLES',
    'impvat_vehiculo': 'IMPUESTO MUNICIPAL A LA PROPIEDAD DE VEHICULOS AUTOMOTORES TERRESTES ',
    'imto_vehiculo': 'IMPUESTO MUNICIPAL A LA TRANSFERENCIA ONEROSA DE VEHICULOS AUTOMOTORES',
    "patentes_municipales": "Patentes Municipales",
    "actividades_municipales": "Actividades  Económicas",
    "varios": "Varios",
    "titulo": "Título",
    "doc_relacionados": "Doc. Relacionados",
    "entidades_financieras": "Entidades Financieras",
    "ubicacion": "Ubicación",
    "publico": "Público",
    "archivado": "Archivado",
    "en_revision": "En Revisión",
    'reset_password': '¿Olvidaste tú contraseña?',
    'reset_title': 'Recuperar Contraseña',
    'reset_title_method': 'Seleccionar Recuperar Contraseña',
    'reset_descripction_init': 'Para poder restablecer tu contraseña, primero debes ingresar tú correo electrónico.',
    'reset_description_method': 'Selecciona el método que deseas para restablecer la contraseña.',
    'reset_phone_code_description': 'Revisa tus mensajes de whatshap, te enviamos un código de verificación al número de celular ... ',
    'reset_info_email_description': 'Revisa la bandeja de entrada del correo electrónico, te enviamos un enlace para que puedas restablecer tu constraseña',
    'login_description': 'Ingresa el correo electrónico y la contraseña en SIMAT.',
    'register_question': 'Registrate aquí',
    'register_user_title_init': 'Registro Usuario SIMAT',
    'register_user_description_init': 'Para registrarte debes contar con un número de celular, correo electrónico personal y PMC(Padrón Municipal del Contribuyente), todos válidos.',
    'register_phone_code': 'Ingresar Código de Verificación',
    'register_phone_code_description': 'Revisa tus mensaje de WhatsApp, te enviamos un código de verificación al número ',
    'register_email': 'Correo Electrónico',
    'register_email_description': 'Ingresa tú correo electrónico.',
    'register_pmc_title': 'Datos Usuario SIMAT',
    'register_pmc_description': 'Ingresa tu número de  PMC(Padrón Municipal del Contribuyente) del Gobierno Autónomo Municipal de Cochabamba, en caso de no contar debe apersonarse a Ventanilla de Trámites de la Plataforma de la Administración Tributaria Municipal.',
    'register_pmc_error': 'El número de PMC(Padrón Municipal del Contribuyente) ingresado , tiene observaciones en los datos, apersonarse a Ventanilla de Trámites de la Plataforma de la Administración Tributaria Municipal.',
    'register_pmc_notexist': 'El número de PMC(Padrón Municipal del Contribuyente) ingresado , no encuentra registro en el Gobierno Autónomo Municipal de Cochabamba, apersonarse a Ventanilla de Trámites de la Plataforma de la Administración Tributaria Municipal.',
    'register_pmc_exist': 'El número de PMC(Padrón Municipal del Contribuyente) ingresado, ya existe.',
    'register_end_ok': 'A procedido correctamente con el registro en SIMAT. El número de PMC(Padrón Municipal del Contribuyente) , en el Gobierno Autónomo Municipal de Cochabamba ha sido enviado a su correo electrónico.',
    'register_title_end_ok': 'Registro Finalizado',
    'ruat_search_pmc_error': 'PMC no actualizado,apersonarse a Ventanilla de Trámites de la Plataforma de la Administración Tributaria Municipal.',
    'email_link_response': 'Te enviaremos un enlace de verificación al correo electrónico',
    'phone_codigo_repeat': 'Solicitar otro código de verificación',
    'email_link_verify': 'Solicitar otro enlace de verificación',
    'register_info_email_title': 'Verificación de Correo ',
    'register_info_email_description': 'Revisa la bandeja de entrada del correo electrónico y haz click en el enlace',
    'text_important': 'Importante',
    'pmc_detail': 'Padrón Municipal del Contribuyente',
    'publicity_home': 'Publicidad',
    'publicity_ddjj': 'Declaración Jurada',
    'publicity_ddjj_new': 'Registro Nueva Declaración Jurada',
    'publicity_ddjj_process': 'Declaración Jurada',
    'publicity_ddjj_finish': 'Declaración Jurada',
    'publicity_ddjj_list': 'Declaraciones Juradas',
    'publicity_list': 'Autorización de Publicidad',
    publicity_edit: 'Editar Publicidad',
    publicity_create: 'Nuevo Registro Publicidad',
    'reset_form_title': 'Nueva Contraseña',
    'reset_form_description': 'Ingresa la nueva contraseña',
    'ras_admin_inicio': 'Objetos Resolución Administrativa Sancionatoria',
    'ras_contribuyente_inicio': 'Publicaciones',
    'publicidad': "Publicidad",
    'periodo': 'Periodo',    
    'dimension_publicidad': "Dimensiones (Cartel)",
    ubicacion_publicidad: "Ubicación Publicidad",
    ubicacion_geoespacial_publicidad: "Ubicación Geoespacial de la Publicidad",
    "periodo_autorizacion": "Tiempo Autorización",
    "periodo_autorizacion_estructura": "Tiempo Autorización Estructura Publicitaria",
    "periodo_autorizacion_elemento": "Tiempo Autorización Elemento Publicitaria"
}
