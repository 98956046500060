import React, { Component } from 'react';

import Config from '../../data/config';
import Constant from '../../data/constant';
import Links from '../../data/link';
import Fetch from '../../components/utils/Fetch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var _fetch = null
class CollapseReport extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            show: false,
            verifyFur: false
        };

        _fetch = new Fetch();
        _fetch.setToast(toast);
    }

    render() {
        return (
            <>
                <div className="accordion" id="accordionReporteATM" style={{ padding: "10px 5px" }}>
                    <div className="card">
                        <div className="card-header" id="headingReporte">
                            <h5 className="mb-0">
                                <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseLicenciaReporte" aria-expanded="true" aria-controls="collapseLicenciaReporte">
                                    Licencias de Funcionamiento
                                </button>
                            </h5>
                        </div>

                        <div id="collapseLicenciaReporte" className="collapse show" aria-labelledby="headingReporte" data-parent="#accordionReporteATM">
                            <div className="card-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus.
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="headingPrescripcionReporte">
                            <h5 className="mb-0">
                                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapsePrescripcionPrescripcion" aria-expanded="false" aria-controls="collapsePrescripcionPrescripcion">
                                    Prescripciones
                                </button>
                            </h5>
                        </div>
                        <div id="collapsePrescripcionPrescripcion" className="collapse" aria-labelledby="headingPrescripcionReporte" data-parent="#accordionReporteATM">
                            <div className="card-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="headingCramReporte">
                            <h5 className="mb-0">
                                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseCramReporte" aria-expanded="false" aria-controls="collapseCramReporte">
                                    CRAM
                                </button>
                            </h5>
                        </div>
                        <div id="collapseCramReporte" className="collapse" aria-labelledby="headingCramReporte" data-parent="#accordionReporteATM">
                            <div className="card-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CollapseReport;