import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Config from "../../data/config";
import Texto from "../../data/es";
import Links from "../../data/link";
import "./../style/style-simat.css";
// import TitlePageBreadcrumb from "../utils/TitlePageBreadcrumb";
import TitlePage from "../utils/TitlePage";

const ActividadesEconomicas = (props) => {
  const titlePage = Texto.actividades_municipales;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOpenUrlClick = async (ev, url, rubro) => {
    ev.preventDefault();
  };

  const breadcrumbs = [
    {
      title: Links[0].title,
      url: Links[0].url,
    },
    {
      title: Links[128].title,
      url: Links[128].url,
    },
  ];

  return (
    <section className="items-grid section mt-5 mb-5" >
      <TitlePage
        titlePage={titlePage}
        breadcrumbs={breadcrumbs}
        position={"left"}
        leftfull={false}
      />
      <div className="container mt-4">

        <div className="row justify-content-center align-items-center">
          <div className="col-md-4 col-sm-12 p-3 mt-2 ml-2 mr-2">
            <div className="card">
              <div className="card-body bg-cyan text-center">
                <img
                  className="container-img mx-auto d-block rounded-circle border-2"
                  style={{ minWidth: '130px' }}
                  alt="img-licencia-funcionamiento"
                  src={ Config[2].url + "/static/img/offcanvas/actividades_econominas.png"
                  }
                />
              </div>
              <div className="card-footer text-center">
              {/* to={{ pathname: Links[1].url }} */}
                <Link
                  to={{ pathname: Links[139].url }} 
                  className="btn btn-link w-100"
                  title={Links[1].title}
                >
                  Lic. Funcionamiento
                </Link>
              </div>
            </div>
          </div>

          {/* 2 */}

          <div className="col-md-4 col-sm-12 p-3 mt-2 ml-2 mr-2">
            <div className="card">
              <div className="card-body bg-cyan text-center">
                <img
                  className="container-img"
                  style={{ minWidth: '130px' }}
                  alt="img-licencia-funcionamiento"
                  src={Config[2].url + "/static/img/offcanvas/ads.png"}
                />
              </div>
              <div className="card-footer text-center">
                <Link
                  to={{ pathname: Links[129].url }}
                  className="btn btn-link w-100"
                  title={Links[129].title}
                >
                  Publicidad
                </Link>
              </div>
            </div>
          </div>

          {/* 3           */}

          <div className="col-md-4 col-sm-12 p-3 mt-2 ml-2 mr-2">
            <div className="card">
              <div className="card-body bg-cyan text-center">
                <img
                  className="container-img"
                  style={{ minWidth: '130px' }}
                  alt="img-licencia-funcionamiento"
                  src={Config[2].url + "/static/img/offcanvas/market.png"}
                />
              </div>
              <div className="card-footer text-center">
                <Link
                  to={{
                    pathname:
                      "https://tramitesgamc.cochabamba.bo/#/consulta-sitiosymercados-iframe",
                  }}
                  className="btn btn-link w-100"
                  title={"Sitios y Mercados"}
                  target="_blank"
                  rel="noopener noreferrer"
                >Sitios y Mercados
                </Link>
              </div>
            </div>
          </div>


        </div>

      </div>
    </section >
  );
};

export default ActividadesEconomicas;
