import React, { useState, useEffect } from "react";
import Constant from "./../../../data/constant";

const InfoTopAdvertising = (props) => {
    const { Links, auth, history, listCodeEstado, handleSearchAdvertisingByEstado, dataAdvertisingEstados } = props;

    const [nodeInfo, setNodeInfo] = useState([]);

    useEffect(() => {
        if (!auth.loggedIn()) history.replace(Links[4].url);
        loadInfoCountAdvertisingbyEstado();
    }, [dataAdvertisingEstados]);


    const loadInfoCountAdvertisingbyEstado = async () => {
        const filteredAdvertisingByEstadoCount = dataAdvertisingEstados.filter(item =>
            listCodeEstado.includes(item.Estado.code)
        );
        
        // Ordenar los elementos filtrados por Estado.name
        // const sortedAdvertisingByEstadoCount = filteredAdvertisingByEstadoCount.sort((a, b) =>
        //     a.Estado.name.localeCompare(b.Estado.name)
        // );

        let nodeReq = htmlInfo(filteredAdvertisingByEstadoCount);
        setNodeInfo(nodeReq);
    }


    const htmlInfo = (data) => {
        return data.map(
            ({ totalPublicidad, Estado }, index) => {
                return (

                    <div className="col-4 col-md-3 col-lg-3" key={index}>
                        <div
                            className="single-contact-info pointer"
                            onClick={(e) => handleSearchAdvertisingByEstado(e, Estado.code)}
                        >
                            {Estado.code === Constant[0].estado.en_proceso && <i className="fa fa-cog" aria-hidden="true"></i>}
                            {Estado.code === Constant[0].estado.aprobado && <i className="fa fa-check" aria-hidden="true"></i>}
                            {Estado.code === Constant[0].estado.revisado && <i className="fas fa-check-circle" aria-hidden="true"></i>}
                            {Estado.code === Constant[0].estado.certificado && <i className="fas fa-certificate" aria-hidden="true"></i>}
                            <p id={"info-" + Estado.code}>{Estado.name} - <b>{totalPublicidad}</b></p>
                        </div>
                    </div>
                )
            }
        )
    }

    /* ====================================================================== */
    return (
        <>
            {nodeInfo.length > 0 ?
                <section className="panel-menu-info">
                    <div className="panel-menu-info-content align-center">
                        <div className="row justify-content-center">
                            {nodeInfo}
                        </div>
                    </div>
                </section>
                : ""
            }
        </>
    )
}

export default InfoTopAdvertising;
