import React, { Component } from "react";
//import PropTypes from "prop-types";
import Iframe from "react-iframe";
import Fetch from "./Fetch";
//import Constant from "../../data/constant";
import { Link } from "react-router-dom";
import Config from "../../data/config";
import AuthService from "../../components/Usuario/AuthService";

var token_dj = "";
var _toast = undefined;
var _id_modal = "";
var _rotulo_comercial = "";
var _urlDownload = "#";
class ModalPdf extends Component {
  constructor(props, context) {
    super(props, context);

    //this.handleCompleteOnClick = this.handleCompleteOnClick.bind(this);
    this.hanldeDownloadPdf = this.hanldeDownloadPdf.bind(this);
    this.handleOnlad = this.handleOnlad.bind(this);

    this.fetch = new Fetch();
    this.Auth = new AuthService();

    token_dj = "";
    _toast = undefined;
    _rotulo_comercial = "";

    _id_modal = "modalPdfFul";
    this.title = "Datos de la Actividad Económica: ";

    this.state = {
      currentUrl: "",
    };
  }

  setToast(toast) {
    _toast = toast;
    this.fetch.setToast(toast);
  }

  showPdf(url, title, token, auth, urlDownload) {
    document.getElementById("spinner-loading-modal-pdf").classList.remove("d-none");
    window.jQuery("#" + _id_modal).modal("show");
    document.getElementById("iframeLicPdf").src = url; //+this.Auth.getToken()
    window
      .jQuery("#" + _id_modal)
      .find("#titlePdfFullLabel")
      .html(title);
    token_dj = token;
    _urlDownload = urlDownload;
  }

  hanldeDownloadPdf(event) {
      debugger
    event.preventDefault();
    if (_urlDownload) {
      if (_urlDownload !== "#") window.location.href = _urlDownload;
      else
        window.location.href =
          Config[0].url +
          `report/licencia-actividad-economica-download/${token_dj}/?auth=` +
          this.Auth.getToken();
    }else{
        _toast.warning("No es posible descargar el documento.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
    }
  }

  handleOnlad(event) {
    document
      .getElementById("spinner-loading-modal-pdf")
      .classList.add("d-none");
  }

  render() {
    return (
      <div
        className="modal fade"
        id={_id_modal}
        tabIndex="-1"
        aria-labelledby="movalPdfFullLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="titlePdfFullLabel">
                {this.title}
              </h5>
              <button
                type="button"
                className="btn-close h6"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div id="spinner-loading-modal-pdf" className="row d-none mt-3">
                <div className="col-12 mb-1">
                  <img
                    className="rounded mx-auto d-block"
                    alt="pulse-loading"
                    src={"/static/img/pulse_200.gif"}
                    width="70"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 embed-container">
                  <Iframe
                    url={this.state.currentUrl}
                    width="640"
                    height="360"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    id="iframeLicPdf"
                    //className="myClassname"
                    display="initial"
                    onLoad={this.handleOnlad}
                  />
                </div>

                <div className="row mt-3">
                  <div className="col-11 col-md-11 col-lg-11 paddingTop15">
                    <p>
                      En caso no se visualize el pdf, puedes
                      <Link
                        to="#"
                        onClick={this.hanldeDownloadPdf}
                        title="Descargar"
                        style={{ paddingLeft: "5px" }}
                      >
                        hacer click aquí para descargar el archivo PDF.
                      </Link>
                    </p>
                  </div>

                  <div className="col-1 col-md-1 col-lg-1 paddingTop15 pull-right">
                    <Link
                      to="#"
                      onClick={this.hanldeDownloadPdf}
                      title="Descargar"
                      style={{
                        fontSize: "2em",
                        float: "right",
                        marginTop: "-5px",
                      }}
                    >
                      <i
                        className="fa fa-print fa-icon-image"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="col-12 search-btn button text-end">
                <button
                  type="button"
                  className="btn bg-maroon-flush"
                  data-bs-dismiss="modal"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalPdf;