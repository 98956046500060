import React, { useEffect, useState } from "react";
// import Config from "../../data/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Links from "../../data/link";
import { useHistory } from "react-router-dom";
import TitlePage from "../utils/TitlePage";
import Texto from "../../data/es";
import Fetch from "../utils/Fetch";
import AuthService from "../Usuario/AuthService";
import TributarioButton from "./TributarioButton";
import "./css/style.css";

let breadcrumbs = [
	{
		title: Links[0].title,
		url: Links[0].url,
	},
	{
		title: Links[117].title,
		url: Links[117].url,
	},
	{
		title: 'Objetos Tributarios',
		url: '#'
	},
];

// =========================================== //
const TributarioInicio = (props) => {
	const fetch = new Fetch();
	fetch.setToast(toast);
	const titlePage = Texto.liquidacion_inicio;
	// =========================================== //
	const history = useHistory();
	const auth = new AuthService();
	const userAuth = auth.getProfile();
	if (!auth.loggedIn()) history.replace(Links[4].url);
	// =========================================== //
	// useeffect
	useEffect(() => {
		debugger;
		if (auth.loggedIn()) {
			loadData();
		} else {
			window.location.href = Links[0].url;
		}
		window.scrollTo(0, 0);
	}, []);
	// =========================================== //
	const [dataTributario, setDataTributario] = useState([]);

	// function - objeto tributario
	const loadData = async () => {
		const responseTributario = await fetch.axiosAsyncGet(
			`api/nt-tributario/objeto-tributario/all`
		);

		if (responseTributario && responseTributario.status) {
			setDataTributario(responseTributario.ObjetoTributario);
		}
	};
	
	// ================================================ //
	const itemsHtmlUlLi = dataTributario.map((rowObjectTributario) => (
		<div className="col-lg-4 col-xl-4 col-md-4 col-xs-6 ">
			<TributarioButton rowObjectTributario={rowObjectTributario} />
		</div>
	));
	// ================================================ //
	return (
		<div className="section items-grid  mt-5">
			<div className="container mt-4">
				<TitlePage
					titlePage={titlePage}
					breadcrumbs={breadcrumbs}
					position={"left"}
					leftfull={false}
				/>

				<p className="text-justify">
					Seleccione y haga click sobre el objeto tributario que desea
					publicar o ver los registros existentes.
				</p>

				<div className="row mt-3 justify-content-center align-items-center">
					{itemsHtmlUlLi}
				</div>

				{/* <ToastContainer
					enableMultiContainer
					containerId={"Z"}
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange
					draggable
					pauseOnHover
				/> */}
			</div>
		</div>
	);
};
export default TributarioInicio;
