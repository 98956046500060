import React, { Component } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthService from './AuthService';
import Fetch from '../utils/Fetch';
import Links from '../../data/link';
import Texto from '../../data/es';
import TitlePage from '../utils/TitlePage';
import '../style/parsley.css';

var message_register = ""
class SendMailActivationAccount extends Component {

    constructor(props, context) {
        super(props, context);

        this.Auth = new AuthService();
        this.id_form_search = "formRequestSendMail"

        this.fetch = new Fetch();
        this.fetch.setToast(toast)
        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);

        this.state = {
            showMessageAlert: false,
            showFormSearchAccount: true,
        };
    }

    handleSearchSubmit(event) {
        event.preventDefault()

        window.jQuery("#" + this.id_form_search).parsley().validate();

        if (window.jQuery("#" + this.id_form_search).parsley().isValid()) {
            const form = new FormData(event.target);

            this.fetch.fetchPost(form, 'api/usuario/reenviar-mail-activation').then(dataJson => {
                if (dataJson !== undefined && dataJson.status === true) {
                    message_register = dataJson.message
                    this.setState({ showFormSearchAccount: false, showMessageAlert: true })
                }
            })
        } else {
            toast.warn(Texto.campos_obligatorios, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    componentDidUpdate() {
        if (this.state.showMessageAlert === true)
            document.getElementById("divAlertRequestSenMail").innerHTML = message_register
    }

    render() {
        const breadcrumbs = [
            {
                title: Links[0].title,
                url: Links[0].url
            },
            {
                title: Texto.reactivacion_cuenta,
                url: '#'
            }
        ];
        return (
            <section className="items-grid section mt-5">
                <div className="container mt-4">
                    <TitlePage titlePage={Texto.reactivacion_cuenta} breadcrumbs={breadcrumbs} position={'center'} />

                    {this.state.showMessageAlert ?
                        <div className="row justify-content-md-center justify-content-lg-center">
                            <div className="col-12 col-md-9 col-lg-7" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                                <div className="alert alert-success" role="alert" id="divAlertRequestSenMail" style={{ fontSize: '1.3rem', marginLeft: '15px', marginRight: '15px' }}>
                                    This is a success alert—check it out!
                            </div>
                            </div>
                        </div>
                        : ""}

                    {!this.state.showMessageAlert && this.state.showFormSearchAccount ?
                        <form action="" className="contact__form center-login" name={this.id_form_search} id={this.id_form_search}
                            method="post" noValidate onSubmit={this.handleSearchSubmit} >


                            <div className="row justify-content-md-center justify-content-lg-center">
                                <div className="col-12 col-md-9 col-lg-7 ">
                                    <label htmlFor="usuario[username]">Por favor ingresa tu Correo Electrónico para buscar tu cuenta.</label>
                                    <input name="usuario[username]" id="usuario[username]" type="email" className="form-control" placeholder="Correo Electrónico"
                                        data-parsley-required="true" data-toggle="tooltip" data-placement="left" title="Correo Electrónico" />
                                </div>
                            </div>
                            <br />
                            <div className="row justify-content-md-center justify-content-lg-center">
                                <div className="col-12 col-md-9 col-lg-7 text-end">
                                    <div className="col-12 search-btn button ">
                                        <input className="btn btn-full-screen" name="submit" type="submit" value={Texto.enviar} />
                                    </div>
                                </div>
                            </div>
                        </form>
                        : ""}

                    <ToastContainer enableMultiContainer containerId={'Z'}
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                </div>
            </section>
        );
    }
}

export default SendMailActivationAccount;