import React, { useEffect } from "react";
import Config from "../../data/config";
import Texto from "../../data/es";
import Links from "../../data/link";
import "./../style/style-simat.css";
// import TitlePageBreadcrumb from "../utils/TitlePageBreadcrumb";
import TitlePage from "../utils/TitlePage";
import AuthService from "./../../components/Usuario/AuthService";
import { useHistory } from "react-router-dom";

const breadcrumbs = [
    {
        title: Links[0].title,
        url: Links[0].url,
    },
    {
        title: Links[128].title,
        url: Links[128].url,
    },
    {
        title: Links[129].title,
        url: Links[129].url,
    },
    {
        title: Links[130].title,
        url: Links[130].url,
    }
];

const HomeDeclaracionJurada = (props) => {

    const auth = new AuthService();
    const userAuth = auth.getProfile();

    const history = useHistory();
    const titlePage = Texto.publicity_ddjj;

    useEffect(() => {
        if (!auth.loggedIn()) history.replace(Links[4].url);
        
        if(userAuth && userAuth.code ==="FUNCIONARIO"){           
            history.push(Links[132].url);
        }

        window.scrollTo(0, 0);
    }, []);



    const handleRedirectClick = (event, url) => {
        event.preventDefault();
        //debugger
        //console.log("***** Config ", Config[2].url, Config[2].url+url);
        window.location.href = Config[2].url + url;
    }

    return (
        <section className="items-grid section mt-5 mb-5">
            <TitlePage
                titlePage={titlePage}
                breadcrumbs={breadcrumbs}
                position={"left"}
                leftfull={false}
            />

            <div className="container mt-5">
                <div className="row justify-content-center ">
                    <div className="col-md-4 col-lg-4 col-sm-12">
                        <div className="text-center">
                            <a href="" className="btn btn-cyan btn-lg w-100" onClick={(e) => handleRedirectClick(e, Links[131].url)}>
                                <i className="fa fa-file fa-lg pull-left me-2" aria-hidden="true"></i> Registro Nuevo
                            </a>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-4 col-sm-12">
                        <div className="text-center">
                            <a href="" className="btn btn-cyan btn-lg w-100" onClick={(e) => handleRedirectClick(e, Links[132].url)}>
                                <i className="fa fa-tasks fa-lg pull-left me-2" aria-hidden="true"></i> Registro(s) en Proceso
                            </a>
                        </div>
                    </div>

                    {/* <div className="col-md-4 col-lg-4 col-sm-12">
                        <div className="text-center">
                            <a href="" className="btn btn-cyan btn-lg w-100" onClick={(e) => handleRedirectClick(e, Links[132].url)}>
                                <i className="fa fa-tasks fa-lg pull-left me-2" aria-hidden="true"></i> Funcionario
                            </a>
                        </div>
                    </div> */}
                </div>               
            </div>




        </section >
    );
};

export default HomeDeclaracionJurada;
