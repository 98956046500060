import React, { useState, useEffect } from "react";
import Constant from "./../../../data/constant";

const ValidateRadicatoriaPagos = (props) => {
  const {
    fetch,
    dataVehiculo,
    dataRadicatoriaSelected,
    objetoTributario,
    idModal,
  } = props;
  let pagosSelected = [];
  let isSingleSelect = true;
  const tipoCertificacionVehiculo = Constant[0].tipo_certificacion_vehiculo;
  const numeroPta = dataVehiculo.dataVehiculo.numeroPta;
  const numeroPoliza = dataVehiculo.dataVehiculo.numeroPoliza;
  const datosTecnicos = dataVehiculo.dataTecnico.datosTecnicos;

  const [pagos, setPagos] = useState(undefined);
  const [pagosSeleccionados, setPagosSeleccionados] = useState(undefined);
  const [msg, setMsg] = useState("");
  const [searchData, setSearchData] = useState(false);

  useEffect(() => {
    debugger;
    if (dataRadicatoriaSelected)
      rendizarPagos(dataRadicatoriaSelected.codigoVehiculo);
  }, []);

  const handleClickRow = (event, pago) => {
    event.preventDefault();
    verificarDeuda(event, pago);
  };

  const verificarDeuda = (event, pago) => {
    debugger;
    if (isSingleSelect) {
      pagosSelected = [];
      let pagosRow = event.target
        .closest("form")
        .getElementsByClassName("row-table-custom");
      Array.from(pagosRow).forEach((rowPagos) => {
        let nodeItemp = rowPagos.getElementsByTagName("i")[0];
        nodeItemp.classList.add("fa-square");
        nodeItemp.classList.remove("fa-check-square");
      });
    }

    let nodeI = event.target.closest(".row").getElementsByTagName("i")[0];
    if (nodeI.classList.contains("fa-square")) {
      nodeI.classList.remove("fa-square");
      nodeI.classList.add("fa-check-square");
      pagosSelected = [...pagosSelected, pago];
    } else {
      nodeI.classList.add("fa-square");
      nodeI.classList.remove("fa-check-square");
      pagosSelected = pagosSelected.filter(
        (itemPago) => pago.gestion !== itemPago.gestion
      );
    }
    setPagosSeleccionados(pagosSelected);
  };

  const handleContinuar = (event) => {
    debugger;
    event.preventDefault();
    fetch
      .fetchGet(
        "api/temporal-contribuyentes/by-csrkey/" + props.csrfkey.csrfToken
      )
      .then((dataJson) => {
        if (dataJson && dataJson.status === true) {
          if (pagosSeleccionados && pagosSeleccionados.length > 0)
            props.nextPageCurrent(4, pagosSeleccionados);
          else {
            window.jQuery("#" + idModal).animate({ scrollTop: 0 }, "slow");
            setMsg("Debe seleccionar al menos un pago para poder continuar!!");
            fetch.toast.warn(
              "Debe seleccionar al menos un pago para poder continuar!!",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          }
        }
      });
  };

  const rendizarPagos = async (codigoVehiculo) => {
    setSearchData(true);
    const pagosDB = await getPagosVehiculo(codigoVehiculo);
    debugger;
    let pagosDom;
    switch (objetoTributario.code) {
      case tipoCertificacionVehiculo.pip: //Todas las gestiones
        if (pagosDB) {
          pagosDom = pagosToHtml(pagosDB, codigoVehiculo);
          setPagos(pagosDom);
        } else {
          setMsg("Lo sentimos no se ha encontrado ningun pago!!! ");
          fetch.toast.warn("Lo sentimos no se ha encontrado ningun pago!!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        setSearchData(false);
        props.nextPageCurrent(4, undefined);
        break;
      case tipoCertificacionVehiculo.pipg: //seleccionar una sola gestión
        if (pagosDB) {
          isSingleSelect = !isSingleSelect;
          pagosDom = pagosToHtml(pagosDB, codigoVehiculo);
          setPagos(pagosDom);
        } else {
          setMsg("Lo sentimos no se ha encontrado ningun pago!!! ");
          fetch.toast.warn("Lo sentimos no se ha encontrado ningun pago!!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        setSearchData(false);
        break;
      case tipoCertificacionVehiculo.rdtp: //seleccionar varias gestión
        if (pagosDB) {
          isSingleSelect = !isSingleSelect;
          pagosDom = pagosToHtml(pagosDB, codigoVehiculo);
          setPagos(pagosDom);
        } else {
          setMsg("Lo sentimos no se ha encontrado ningun pago!!! ");
          fetch.toast.warn("Lo sentimos no se ha encontrado ningun pago!!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        setSearchData(false);
        break;
      default: //todos los demas casos, debe continuar directo
        setSearchData(false);
        props.nextPageCurrent(4, undefined);
        break;
    }
  };

  const getPagosVehiculo = async (codigoVehiculo) => {
    const resPagos = await fetch.axiosAsyncGet(
      `api-ruat/vehiculos/pagos-by-codigo-vehiculo/${codigoVehiculo}`
    );
    if (Boolean(resPagos) && resPagos.status)
      return resPagos.data.pagos.reverse();
    return undefined;
  };

  const pagosToHtml = (pagos, codigoVehiculo) => {
    let cont = 0;
    return pagos.map(({ montoPagadoTotal, gestion, detallePagos }, key) => {
      return (
        <div
          className={
            "row pointer row-table-custom pt-2 pb-2  " +
            (key % 2 === 0 ? " bg-picton-blue " : " background-white ") +
            " justify-content-center align-items-center objeto-tributario-deuda-" +
            codigoVehiculo +
            " "
          }
          key={cont++}
          onClick={(e) =>
            handleClickRow(e, {
              selected: true,
              montoPagadoTotal,
              gestion,
              detallePagos,
            })
          }
        >
          <div className="col-10 col-md-10">
            <strong>Gestión: </strong>
            <span> {gestion}</span>
            <br />
            <strong>Monto: </strong>
            <span> {montoPagadoTotal} Bs.</span>
          </div>
          <div className="col-2 col-md-2">
            <i
              className="far fa-square fa-2x fa-icon-image"
              aria-hidden="true"
            ></i>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-12 padding-left-0 text-uppercase">
          <strong>
            <p style={{ fontSize: ".9em" }}>{objetoTributario.name}</p>
          </strong>
        </div>
      </div>

      <div className="row">
        <div className="col-12 padding-left-0 text-uppercase mb-3">
          <strong style={{ fontSize: ".9em" }}>Nro. Placa: </strong>
          <span>{numeroPta}</span>
          <br />
          <strong style={{ fontSize: ".9em" }}>Nro. Poliza: </strong>
          <span>{numeroPoliza}</span>
          <br />
          <strong style={{ fontSize: ".9em" }}>Estado: </strong>
          <span>
            {dataRadicatoriaSelected
              ? dataRadicatoriaSelected.estadoRadicatoria
              : ""}
          </span>
          <br />
          <strong style={{ fontSize: ".9em" }}>Clase: </strong>
          <span>{datosTecnicos ? datosTecnicos.clase : ""}</span>
          <br />
        </div>
      </div>

      <div className="row">
        <div className="col-12 padding-right-0 padding-left-0">
          <h4
            className="text-center mb-3 text-butterfly-bush"
            id="h4TitleDocument"
          >
            Seleccione el/los Pagos
          </h4>
        </div>

        {msg ? (
          <div className="col-12 padding-right-0 padding-left-0 mb-3">
            <div className="alert alert-danger alert-dismissible">
              <strong>Atención!</strong> {msg}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {pagos ? pagos : ""}

      {searchData ? (
        <div className="row">
          <div className="col-12 mb-1">
            <img
              className="rounded mx-auto d-block"
              alt="pulse-loading"
              src={"/static/img/pulse_200.gif"}
              width="70"
            />
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="row mt-3">
        <div className="col-12 col-md-6 col-lg-6 mb-3 text-start">
          <div className="col-12 search-btn button ">
            <input
              className="btn bg-maroon-flush btn-full-screen"
              name="submit"
              type="submit"
              value="Cancelar"
              onClick={props.handleCancelClick}
            />
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-6 mb-3 text-end">
          {pagos ? (
            <div className="col-12 search-btn button ">
              <input
                className="btn btn-full-screen"
                name="submit"
                id="btnModalSmallSubmit"
                type="submit"
                value="Continuar"
                onClick={handleContinuar}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
export default ValidateRadicatoriaPagos;
