
import React from "react";
import PropTypes from "prop-types";

const HeaderTitlecard = ({ titulo, descripcion, clasificador, categoria, visibleRequired }) => {
    return (
        <>
        <div className="text-wizard mb-3">
            <h4 className="text-justify">{titulo} {clasificador && "("+clasificador+")"}</h4>            
            <div className="bg-gray-5 text-justify col-lg-12 col-md-12 col-sm-12 p-2 mt-1 mb-2 d-flex align-items-center">
                <span className="m-2"><i className="fa fa-info-circle fa-lg text-dark"></i> </span> <span>{descripcion}</span>                
            </div>  
            { categoria && <div className="col-12"> Categoria: <b>{categoria}</b></div> }  
            {
                visibleRequired && <div className=" col-12">(*) Campo Obligatorio</div>
            }             
        </div>      
        </>
        
    );
}


HeaderTitlecard.prototype = {
	titulo: PropTypes.string.isRequired,
    descripcion: PropTypes.string.isRequired,
    clasificador:PropTypes.string,
    categoria:PropTypes.string
};

export default HeaderTitlecard;
