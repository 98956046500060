import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Config from "../../data/config";
import Texto from "../../data/es";
import Links from "../../data/link";
import { useHistory } from "react-router-dom";
import TitlePage from "../utils/TitlePage";
import { toast } from "react-toastify";
import Fetch from "../../components/utils/Fetch";

const Footer = (props) => {
  const history = useHistory();
  const titlePage = Texto.inmuebles;

  const fetch = new Fetch();
  fetch.setToast(toast);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const generateToken = async () => {
    const form = new FormData();
    return await fetch.fetchPost(
      form,
      "api/int/integracion-contribuyentes-certificados",
      undefined
    );
  };

  const handleOpenUrlClick = async (ev, url, rubro) => {
    ev.preventDefault();
    debugger;
    const csrfToken = await generateToken();
    if (csrfToken && csrfToken.status) {
      history.push(
        url +
          "/" +
          rubro +
          "?csrf-token=" +
          csrfToken.csrfToken +
          "&lo=" +
          csrfToken.lo +
          "&expiraEn=" +
          csrfToken.expiraEn
      );
    }
  };

  const breadcrumbs = [
    {
      title: Links[0].title,
      url: Links[0].url,
    },
    {
      title: Links[111].title,
      url: Links[111].url,
    },
  ];
  return (
    <section className="items-grid section mt-5 mb-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={titlePage}
          breadcrumbs={breadcrumbs}
          position={"left"}
          leftfull={false}
        />

        <div className="d-flex align-content-around justify-content-center flex-wrap">
          <div className="p-2 bd-highlight">
            <Link
              to={{ pathname: Links[39].url }}
              className="text-decoration-none w-100"
              title={Links[39].title}
            >
              <div className="square">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image"
                    alt="img-visacion"
                    width="108"
                    height="108"
                    src={Config[2].url + "/static/img/offcanvas/visacion.png"}
                  />
                  <div className="text">Visacion de Minutas</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
          </div>
          <div className="p-2 bd-highlight">
            <Link
              to={{ pathname: "#" }}
              className="text-decoration-none w-100"
              title={Links[50].title}
              onClick={(e) => handleOpenUrlClick(e, Links[50].url, "inm")}
            >
              <div className="square">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image"
                    alt="img-certificacion"
                    width="108"
                    height="108"
                    src={
                      Config[2].url + "/static/img/offcanvas/prescripcion.png"
                    }
                  />
                  <div className="text">Certificaciones</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
          </div>
          <div className="p-2 bd-highlight">
            <Link
              to={{ pathname: Links[24].url }}
              className="text-decoration-none w-100"
              title={"Descuento 3ra Edad"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="square">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image"
                    alt="img-descuento-3ra-edad"
                    width="108"
                    height="108"
                    src={Config[2].url + "/static/img/grandparents.png"}
                  />
                  <div className="text">Descuento 3ra Edad</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
          </div>
          <div className="p-2 bd-highlight">
            <Link
              to={{ pathname: Links[89].url }}
              className="text-decoration-none w-100 d-block"
              title={Links[89].title}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="square">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image"
                    alt="img-pagos-qr"
                    width="108"
                    height="108"
                    src={
                      Config[2].url + "/static/img/offcanvas/qr_pago_atm.png"
                    }
                  />
                  <div className="text">Pagos QR</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
            <Link
              to={{
                pathname:
                  "https://www.ruat.gob.bo/inmuebles/consultapago/InicioBusquedaInmueble.jsf",
              }}
              className="text-decoration-none w-100 mtr-1em"
              title={"Imprimir Pagos"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="square-footer ">
                <div className="text bg-carrot-orange-light text-white fw-600">
                  Imprimir Pagos
                </div>
              </div>
            </Link>
          </div>

          <div className="p-2 bd-highlight">
            <Link
              to={{
                pathname:
                  "https://www.ruat.gob.bo/inmuebles/consultageneral/InicioBusquedaInmueble.jsf",
              }}
              className="text-decoration-none w-100"
              title={"Consulta tu Deuda"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="square">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image"
                    alt="img-consulta-deuda"
                    width="108"
                    height="108"
                    src={
                      Config[2].url + "/static/img/offcanvas/consulta_deuda.png"
                    }
                  />
                  <div className="text">Consulta tu Deuda</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
          </div>

          {/*<div className="p-2 bd-highlight">
            <Link
              to={`${Links[34].url}?proceso=inmuebles`}
              className="text-decoration-none w-100"
              title={"Liquidaciones Mixtas"}
              rel="noopener noreferrer"
            >
              <div className="square">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image"
                    alt="img-liquidacion-mixta"
                    width="108"
                    height="108"
                    src={Config[2].url + "/static/img/offcanvas/ddjj.png"}
                  />
                  <div className="text">Liquidaciones Mixtas</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
                  </div>*/}

          <div className="p-2 bd-highlight">
            <Link
              to={`${Links[122].url}`}
              className="text-decoration-none w-100"
              title={"Liquidaciones Mixtas"}
              rel="noopener noreferrer"
            >
              <div className="square">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image"
                    alt="img-liquidacion-mixta"
                    width="108"
                    height="108"
                    src={Config[2].url + "/static/img/offcanvas/ddjj.png"}
                  />
                  <div className="text">Liquidaciones Mixtas</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
          </div>

          <div className="p-2 bd-highlight">
            <Link
              to={{
                pathname: Links[57].url,
              }}
              className="text-decoration-none w-100"
              title={"Requisitos"}
            >
              <div className="square">
                <div className="top bg-aero">
                  <img
                    className="mx-auto d-block img-noborder image"
                    alt="img-requirement"
                    width="108"
                    height="108"
                    src={
                      Config[2].url + "/static/img/offcanvas/requirement.png"
                    }
                  />
                  <div className="text">Requisitos</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
          </div>

          <div className="p-2 bd-highlight">
            <Link
              to={{ pathname: Links[90].url }}
              className="text-decoration-none w-100 d-block"
              title={Links[89].title}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="square">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image"
                    alt="img-pagos-qr"
                    width="108"
                    height="108"
                    src={
                      Config[2].url + "/static/img/offcanvas/qr_pago_atm.png"
                    }
                  />
                  <div className="text">Multas y Otros</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
            <Link
              to={{
                pathname:
                  "https://www.ruat.gob.bo/ingresosnotributarios/consultapago/InicioBusquedaIngresosNoTributarios.jsf?cGM=GM-CBBA",
              }}
              className="text-decoration-none w-100 mtr-1em"
              title={"Imprimir Pagos"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="square-footer ">
                <div className="text bg-carrot-orange-light text-white fw-600">
                  Imprimir Pagos
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
