

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Links from '../../data/link';
import '../style/redes-sociales.css';

export default class Ayuda extends Component {

    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <div className="whatsapp-icon bg-white" style={{ borderRadius: "30%" }}>
                <Link to={{ pathname: Links[22].url }} aria-label={Links[22].title}    >
                    <img src="static/img/owl.svg" alt='Guía' height="56px" width="76px" />
                </Link>
            </div>
        )
    }
}
