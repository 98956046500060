export default [
    {
        title: 'INMUEBLES',
        url: '/inmuebles',
        session: 0,
    },
    {
        title: 'VEHICULOS',
        url: '/vehiculos',
        session: 0,
    },
    {
        title: 'ACTIVIDADES ECONOMICAS',
        url: '/actividades-economicas',
        session: 0,
    },
    {
        title: 'SAAT',
        url: '/autorizacion-telecomunicaciones',
        session: 0,
    },
    /*{
        title: 'DECLARACIONES JURADAS',
        url: '/declaraciones-juradas',
        session: 0,
    },*/
    {
        title: 'GACETA TRIBUTARIA',
        url: '/gaceta-tributaria',
        session: 0,
    },
    {
        title: 'USUARIO',
        url: '/usuario',
        session: 1,
    }
    /*{
        title: 'INGRESAR',
        url: '/login'
    }*/
];
