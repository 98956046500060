const formInmueble = (form, data) => {
  //form.append("inmueble[predio]", data.inmueble.predio);
  form.append("inmueble[catastro_ruat]", data.inmueble.codigoCatastral);
  form.append("inmueble[numero_ruat]", data.inmueble.numeroInmueble);
  form.append("inmueble[codigo_inmueble_ruat]", data.inmueble.codigoInmueble);

  if (data.dataGeoEspacial) {
    form.append("inmueble[comuna]", data.dataGeoEspacial.comuna);
    form.append("inmueble[distrito]", data.dataGeoEspacial.distrito);
    form.append("inmueble[predio]", data.dataGeoEspacial.predio);
    form.append("inmueble[sub_distrito]", data.dataGeoEspacial.subDistrito);
    form.append(
      "inmueble[zona]",
      window.getNumbersInString(data.dataGeoEspacial.zonaTributaria.toString())
    );
    form.append("inmueble[nro_manzana]", data.dataGeoEspacial.nroManzana);
    form.append(
      "inmueble[nro_sub_distrito]",
      data.dataGeoEspacial.nroSubDistrito
    );
  }

  form.append(
    "inmueble[catastro_gamc]",
    data.datosTecnicosCatastroCbba.codigoCatastral
  );
  form.append(
    "inmueble[asiento_gamc]",
    data.datosTecnicosCatastroCbba.informesLegal[0].asiento
  );
  form.append(
    "inmueble[matricula_gamc]",
    data.datosTecnicosCatastroCbba.informesLegal[0].matricula
  );
  form.append(
    "inmueble[partida_gamc]",
    data.datosTecnicosCatastroCbba.informesLegal[0].partida
  );
};

const formServiciosBasicos = (form, data) => {
  //form.append("servicios_basicos_terreno[id]", data.dataServiceBasic.id);
  if (data.dataServiceBasic && Array.isArray(data.dataServiceBasic)) {
    const basicServices = data.dataServiceBasic;
    basicServices.forEach((basicService, index) => {
      form.append(
        "servicios_basicos_terreno[" + index + "][id_servicio]",
        basicService.id
      );
    });
  }
};

const formDireccion = (form, data) => {
  if (data.dataGeoEspacial) {
    form.append("inmueble_direccion[latitud]", data.dataGeoEspacial.latitud);
    form.append("inmueble_direccion[longitud]", data.dataGeoEspacial.longitud);
    form.append(
      "inmueble_direccion[coordinate]",
      data.dataGeoEspacial.coordinate
    );
    form.append("inmueble_direccion[latitudwgs84]", data.dataGeoEspacial.latitudWGS84);
    form.append("inmueble_direccion[longitudwgs84]", data.dataGeoEspacial.longitudWGS84);
    form.append("inmueble_direccion[image]", data.dataGeoEspacial.image64);
  }
};

const formPropiedad = (form, data) => {
  form.append(
    "propiedad[id_tipo_propiedad]",
    data.datosTecnicosRuat.datosGenerales.tipoPropiedad
      .replaceAll(" ", "")
      .replace("Y", "_")
  );
  form.append(
    "propiedad[codigo_contribuyente]",
    data.propiedad.codigoContribuyente ? data.propiedad.codigoContribuyente : "" //falta este valor, en la tabla se debe agregar
  );
  form.append("propiedad[codigo_propiedad]", data.propiedad.codigoPropiedad); //falta este valor, en la tabla se debe agregar
  form.append("propiedad[estado_propiedad]", data.propiedad.estadoPropiedad); //falta este valor, en la tabla se debe agregar
  form.append(
    "propiedad[fecha_fin_propiedad]",
    data.propiedad.fechaFinPropiedad ? data.propiedad.fechaFinPropiedad : ""
  );

  form.append(
    "propiedad[fecha_inicio_propiedad]",
    data.propiedad.fechaInicioPropiedad
      ? data.propiedad.fechaInicioPropiedad
      : ""
  );
  form.append(
    "propiedad[porcentaje]",
    data.propiedad.porcentaje ? data.propiedad.porcentaje : 0
  );
};

const formColindantes = (form, data) => {
  if (
    data.datosTecnicosCatastroCbba.informesTecnico &&
    Array.isArray(data.datosTecnicosCatastroCbba.informesTecnico)
  ) {
    const colindantes =
      data.datosTecnicosCatastroCbba.informesTecnico[0]
        .informesTecnicoColindancia;
    colindantes.forEach((colindante, index) => {
      form.append(
        "colindante[" + index + "][descripcion]",
        colindante.descripcionColindancia
      );
      form.append(
        "colindante[" + index + "][orientacion]",
        colindante.orientacion
      );
    });
  }
};

const formContrucciones = (form, data) => {
  // construcciones del ruat
  if (
    data.datosTecnicosRuat.construcciones &&
    Array.isArray(data.datosTecnicosRuat.construcciones)
  ) {
    data.datosTecnicosRuat.construcciones.forEach((construccion, index) => {
      form.append(
        "construccion[" + index + "][anio]",
        construccion.anioConstruccion
      );
      form.append("construccion[" + index + "][bloque]", construccion.bloque);
      form.append("construccion[" + index + "][gestion]", construccion.gestion);
      form.append(
        "construccion[" + index + "][superficie_m2]",
        construccion.superficieM2
      );
      form.append(
        "construccion[" + index + "][tipo_construccion]",
        construccion.tipoConstruccion
      );
      form.append(
        "construccion[" + index + "][uso_construccion]",
        construccion.usoConstruccion
      );
    });
  }
};

const formContruccionesAdicionales = (form, data) => {
  // construcciones adicionales del ruat
  if (
    data.datosTecnicosRuat.construccionesAdicionales &&
    Array.isArray(data.datosTecnicosRuat.construccionesAdicionales)
  ) {
    data.datosTecnicosRuat.construccionesAdicionales.forEach(
      (construccion, index) => {
        form.append(
          "construccion_adicional[" + index + "][anio]",
          construccion.anioConstruccion
        );
        form.append(
          "construccion_adicional[" + index + "][gestion]",
          construccion.gestion
        );
        form.append(
          "construccion_adicional[" + index + "][puntaje]",
          construccion.puntaje
        );
        form.append(
          "construccion_adicional[" + index + "][superficie_m2]",
          construccion.superficieM2
        );
        form.append(
          "construccion_adicional[" + index + "][tipo_construccion]",
          construccion.tipoConstruccion
        );
        form.append(
          "construccion_adicional[" + index + "][tipo_edificacion]",
          construccion.tipoEdificacion
        );
      }
    );
  }
};

const formDatosTecnicos = (form, data) => {
  form.append(
    "datos_tecnicos[anio_obligacion_tributaria_ruat]",
    data.datosTecnicosRuat.anioObligacionTributaria
  );
  form.append(
    "datos_tecnicos[area_ruat]",
    data.datosTecnicosRuat.datosGenerales.area
  );
  form.append(
    "datos_tecnicos[clase_ruat]",
    data.datosTecnicosRuat.datosGenerales.clase
  );
  form.append(
    "datos_tecnicos[ubicacion_ruat]",
    data.datosTecnicosRuat.datosGenerales.ubicacion
  );
  form.append(
    "datos_tecnicos[zona_tributaria_ruat]",
    window.getNumbersInString(
      data.datosTecnicosRuat.datosGenerales.zonaTributaria
    )
  );
  form.append(
    "datos_tecnicos[tipo_propiedad_ruat]",
    data.datosTecnicosRuat.datosGenerales.tipoPropiedad
  );
  form.append(
    "datos_tecnicos[catastro_anterior_gamc]",
    data.datosTecnicosCatastroCbba.informesTecnico[0].codigoCatastroAnterior
  );

  let fechaAprobacionPlano = "";
  if (data.datosTecnicosCatastroCbba.informesTecnico[0].fechaAprobacionPlano) {
    fechaAprobacionPlano = window
      .moment(
        data.datosTecnicosCatastroCbba.informesTecnico[0].fechaAprobacionPlano
      )
      .format("YYYY/MM/DD");
  }
  form.append(
    "datos_tecnicos[fecha_aprobacion_plano_gamc]",
    fechaAprobacionPlano
  );

  if (data.datosTecnicosCatastroCbba.informesTecnico[0].fechaResolucionPlano) {
    const fechaResolucionPlano = window
      .moment(
        data.datosTecnicosCatastroCbba.informesTecnico[0].fechaResolucionPlano
      )
      .format("YYYY/MM/DD");
    form.append(
      "datos_tecnicos[fecha_resolucion_plano_gamc]",
      fechaResolucionPlano
    );
  } else form.append("datos_tecnicos[fecha_resolucion_plano_gamc]", "");
  form.append(
    "datos_tecnicos[nro_resolucion_municipal_gamc]",
    data.datosTecnicosCatastroCbba.informesTecnico[0].nroResolucionMunicipal
      ? data.datosTecnicosCatastroCbba.informesTecnico[0].nroResolucionMunicipal
      : ""
  );
  form.append(
    "datos_tecnicos[nro_inscripcion_gamc]",
    data.datosTecnicosCatastroCbba.nroInscripcion
  );
};

const formTerreno = (form, data) => {
  debugger;
  if (
    data.datosTecnicosRuat.terreno &&
    data.datosTecnicosRuat.terreno.length > 0
  ) {
    form.append(
      "terreno[id_material_via]",
      data.dataMaterialVia.id ? data.dataMaterialVia.id : 0
    );

    form.append(
      "terreno[superficie_gamc]",
      data.datosTecnicosCatastroCbba.informesTecnico[0].superficiePredio
    );
    form.append(
      "terreno[superficie]",
      data.datosTecnicosRuat.terreno[0].superficeM2
        ? data.datosTecnicosRuat.terreno[0].superficeM2
        : 0
    );
    form.append(
      "terreno[inclinacion]",
      data.datosTecnicosRuat.terreno[0].inclinacion
        ? data.datosTecnicosRuat.terreno[0].inclinacion
        : ""
    );
    form.append(
      "terreno[ubicacion]",
      data.datosTecnicosRuat.terreno[0].ubicacion
        ? data.datosTecnicosRuat.terreno[0].ubicacion
        : ""
    );
  }
};

const formTitularPropiedad = (form, data) => {
  const propietario = data.dataPropietario;
  if (data.dataTipoContribuyente === "NATURAL") {
    form.append(
      "titular_propiedad[id_tipo_documento]",
      data.dataPropietario.tipoDocumento
    );
    form.append(
      "titular_propiedad[numero_documento]",
      data.dataPropietario.numeroDocumento
    );
    form.append("titular_propiedad[pmc]", data.dataPmc);
    form.append(
      "titular_propiedad[expedido_en]",
      data.dataPropietario.expedido
    );
    form.append("titular_propiedad[nombre]", data.dataPropietario.nombres);
    form.append(
      "titular_propiedad[apellido_paterno]",
      data.dataPropietario.primerApellido
    );
    form.append(
      "titular_propiedad[apellido_materno]",
      data.dataPropietario.segundoApellido
    );
    if (data.dataPropietario.apellidoEsposo) {
      form.append(
        "titular_propiedad[apellido_casada]",
        data.dataPropietario.apellidoEsposo
      );
    }
    form.append(
      "titular_propiedad[estado_civil]",
      data.dataPropietario.estadoCivil
    );
    form.append("titular_propiedad[genero]", data.dataPropietario.genero);
    form.append("titular_propiedad[natural]", true);

    if (data.dataPropietario.fechaNacimiento !== "--") {
      const arrayFechaNacmiento =
        data.dataPropietario.fechaNacimiento.split("/");
      const fechaNacimiento = window
        .moment(
          arrayFechaNacmiento[2] +
            "/" +
            arrayFechaNacmiento[1] +
            "/" +
            arrayFechaNacmiento[0]
        )
        .format("YYYY/MM/DD HH:mm:ss ");
      form.append("titular_propiedad[fecha_nacimiento]", fechaNacimiento);
    }
  }

  debugger;
  if (data.dataTipoContribuyente === "JURIDICO") {
    const apoderado = data.dataApoderado;
    const poder = data.dataPoder;
    form.append("titular_propiedad_juridico[pmc]", data.dataPmc);
    form.append(
      "titular_propiedad_juridico[razon_social]",
      propietario.razonSocial
    );
    form.append(
      "titular_propiedad_juridico[direccion]",
      data.dataDomicilioLegal
    );

    form.append(
      "titular_propiedad_juridico[id_tipo_documento]",
      propietario.tipoDocumento
    );
    form.append(
      "titular_propiedad_juridico[numero_documento]",
      propietario.numeroDocumento
    );
    form.append(
      "titular_propiedad_juridico[id_tipo_sociedad]",
      propietario.tipoSociedad
    );
    form.append(
      "titular_propiedad_juridico[fecha_constitucion]",
      propietario.fechaConstitucion
    );
    form.append(
      "titular_propiedad_juridico[tipo_contribuyente_juridico]",
      propietario.tipoContribuyenteJuridico
    );
    form.append("titular_propiedad_juridico[sigla]", propietario.sigla);

    form.append(
      "apoderado_juridico[numero_documento]",
      apoderado.numeroDocumento
    );
    form.append(
      "apoderado_juridico[id_tipo_documento]",
      apoderado.tipoDocumento
    );
    form.append("apoderado_juridico[nombre]", apoderado.nombres);
    form.append(
      "apoderado_juridico[apellido_paterno]",
      apoderado.primerApellido
    );
    form.append(
      "apoderado_juridico[apellido_materno]",
      apoderado.segundoApellido
    );
    form.append(
      "apoderado_juridico[apellido_casada]",
      apoderado.apellidoEsposo
    );
    form.append("apoderado_juridico[genero]", apoderado.genero);
    form.append("apoderado_juridico[estado_civil]", apoderado.estadoCivil);
    form.append("apoderado_juridico[expedido_en]", apoderado.expedido);

    form.append(
      "apoderado_juridico[fecha_nacimiento]",
      apoderado.fechaNacimiento
    );

    form.append("poder[numero]", poder.numeroPoder);
    form.append("poder[fecha]", poder.fecha);
    form.append("poder[observacion]", poder.observacion);
  }
};

const formCopropietarios = (form, data) => {
  if (data.dataCopropietarios && Array.isArray(data.dataCopropietarios)) {
    data.dataCopropietarios.forEach((copropietario, index) => {
      debugger;
      form.append(
        "copropietario[" + index + "][id_tipo_documento]",
        copropietario.tipoDocumentoCode
      );
      form.append(
        "copropietario[" + index + "][numero_documento]",
        copropietario.numeroDocumento
      );
      form.append("copropietario[" + index + "][pmc]", copropietario.pmc);
      form.append(
        "copropietario[" + index + "][expedido_en]",
        copropietario.expedido ? copropietario.expedido : ""
      );
      form.append(
        "copropietario[" + index + "][nombre]",
        copropietario.nombres
      );

      form.append(
        "copropietario[" + index + "][apellido_paterno]",
        copropietario.primerApellido
      );
      form.append(
        "copropietario[" + index + "][apellido_materno]",
        copropietario.segundoApellido
      );
      form.append(
        "copropietario[" + index + "][apellido_casada]",
        copropietario.apellidoEsposo
      );
      form.append(
        "copropietario[" + index + "][estado_civil]",
        copropietario.estadoCivil
      );

      form.append("copropietario[" + index + "][genero]", copropietario.genero);

      const fechaNacimiento = window.formatDateDDMMYYYToYYYYMMDD(
        copropietario.fechaNacimiento
      );
      form.append(
        "copropietario[" + index + "][fecha_nacimiento]",
        fechaNacimiento ? fechaNacimiento : ""
      );
    });
  }
};

const formCompradores = (form, data) => {
  if (data.dataCompradores && Array.isArray(data.dataCompradores)) {
    data.dataCompradores.forEach((comprador, index) => {
      let copropietario = undefined;
      debugger;
      if (comprador.contribuyente === "JURIDICO") {
        copropietario = comprador.apoderado;

        const juridico = comprador.juridico;
        form.append(
          "vm_comprador_datos_juridicos[" + index + "][id_tipo_sociedad]",
          juridico.tipoSociedad
        );
        form.append(
          "vm_comprador_datos_juridicos[" + index + "][razon_social]",
          juridico.razonSocial
        );
        form.append(
          "vm_comprador_datos_juridicos[" + index + "][numero_documento]",
          juridico.numeroDocumento
        );

        form.append(
          "vm_compradores[" + index + "][contribuyente_natural]",
          "0"
        );
        form.append(
          "vm_comprador_datos_juridicos[" + index + "][pmc]",
          juridico.pmc
        );

        //aqui ver la fecha constitución
        form.append(
          "vm_comprador_datos_juridicos[" + index + "][fecha_constitucion]",
          juridico.fechaConstitucion
        );
        form.append(
          "vm_comprador_datos_juridicos[" + index + "][sigla]",
          juridico.sigla.replace(/[^\w\s]/gi, "")
        );
        form.append(
          "vm_comprador_datos_juridicos[" +
            index +
            "][tipo_contribuyente_juridico]",
          juridico.tipoContribuyenteJuridico
        );
        form.append(
          "vm_comprador_datos_juridicos[" + index + "][direccion]",
          juridico.domicilioLegal
        );

        form.append(
          "vm_comprador_datos_juridicos[" + index + "][id_tipo_documento]",
          juridico.tipoDocumentoCode
        );

        form.append(
          "vm_compradores[" + index + "][pmc_juridico]",
          juridico.pmc
        );
      } else {
        form.append("vm_comprador_datos_juridicos[" + index + "]", "");
      }

      if (comprador.contribuyente === "NATURAL") {
        copropietario = comprador.propietario;
        form.append(
          "vm_compradores[" + index + "][contribuyente_natural]",
          "1"
        );
      }

      if (comprador.hasOwnProperty("poder") && comprador.poder) {
        const poder = comprador.poder;
        form.append("vm_comprador_poder[" + index + "][fecha]", poder.fecha);
        form.append(
          "vm_comprador_poder[" + index + "][numero]",
          poder.numeroPoder
        );
        form.append(
          "vm_comprador_poder[" + index + "][observacion]",
          poder.observacion
        );
        /*form.append(
          "vm_comprador_poder[" + index + "][pmc]",
          copropietario.pmc
        );*/
      } else {
        form.append("vm_comprador_poder[" + index + "]", "");
      }

      form.append(
        "vm_compradores[" + index + "][id_tipo_documento]",
        copropietario.tipoDocumento
      );
      form.append(
        "vm_compradores[" + index + "][numero_documento]",
        copropietario.numeroDocumento
      );
      form.append(
        "vm_compradores[" + index + "][expedido_en]",
        copropietario.expedidoEn ? copropietario.expedidoEn : ""
      );
      form.append(
        "vm_compradores[" + index + "][nombre]",
        copropietario.nombre
      );
      form.append(
        "vm_compradores[" + index + "][apellido_paterno]",
        copropietario.apellidoPaterno
      );
      form.append(
        "vm_compradores[" + index + "][apellido_materno]",
        copropietario.apellidoMaterno ? copropietario.apellidoMaterno : ""
      );
      form.append(
        "vm_compradores[" + index + "][apellido_casada]",
        copropietario.apellidoCasada ? copropietario.apellidoCasada : ""
      );
      form.append(
        "vm_compradores[" + index + "][estado_civil]",
        copropietario.estadoCivil
      );
      form.append(
        "vm_compradores[" + index + "][genero]",
        copropietario.genero
      );
      form.append("vm_compradores[" + index + "][pmc]", copropietario.pmc);
    });
  }
};

const formProviders = (form, data) => {
  if (data.juridico) {
    const apoderado = data.apoderado;

    if (data.hasOwnProperty("apoderado") && data.apoderado) {
      form.append(
        "representante_legal[id_tipo_documento]",
        apoderado.tipoDocumento
      );
      form.append(
        "representante_legal[numero_documento]",
        apoderado.numeroDocumento
      );
      form.append(
        "representante_legal[expedido_en]",
        apoderado.expedidoEn ? apoderado.expedidoEn : ""
      );
      form.append("representante_legal[nombre]", apoderado.nombre);
      form.append(
        "representante_legal[apellido_paterno]",
        apoderado.apellidoPaterno
      );
      form.append(
        "representante_legal[apellido_materno]",
        apoderado.apellidoMaterno ? apoderado.apellidoMaterno : ""
      );
      form.append(
        "representante_legal[apellido_casada]",
        apoderado.apellidoCasada ? apoderado.apellidoCasada : ""
      );
      form.append("representante_legal[estado_civil]", apoderado.estadoCivil);
      form.append("representante_legal[genero]", apoderado.genero);
      form.append("representante_legal[pmc]", apoderado.pmc);
    }

    const juridico = data.juridico;

    if (data.hasOwnProperty("juridico") && data.juridico) {
      form.append("datos_juridicos[id_tipo_sociedad]", juridico.tipoSociedad);
      form.append("datos_juridicos[razon_social]", juridico.razonSocial);
      form.append("datos_juridicos[nit]", juridico.numeroDocumento);
      form.append("datos_juridicos[pmc]", juridico.pmc);
      form.append(
        "datos_juridicos[fecha_constitucion]",
        juridico.fechaConstitucion
      );
      form.append(
        "datos_juridicos[sigla]",
        juridico.sigla.replace(/[^\w\s]/gi, "")
      );
      form.append(
        "datos_juridicos[tipo_contribuyente_juridico]",
        juridico.tipoContribuyenteJuridico
      );
      form.append("datos_juridicos[direccion]", juridico.domicilioLegal);

      form.append(
        "datos_juridicos[id_tipo_documento]",
        juridico.tipoDocumentoCode
      );
    }

    if (data.hasOwnProperty("poder") && data.poder) {
      const poder = data.poder;
      form.append("poder_juridico[fecha]", poder.fecha);
      form.append("poder_juridico[numero]", poder.numeroPoder);
      form.append("poder_juridico[observacion]", poder.observacion);
    }
  }
};

module.exports = {
  formInmueble,
  formServiciosBasicos,
  formDireccion,
  formPropiedad,
  formColindantes,
  formContrucciones,
  formContruccionesAdicionales,
  formDatosTecnicos,
  formTerreno,
  formTitularPropiedad,
  formCopropietarios,
  formCompradores,
  formProviders,
};
