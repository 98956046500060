import React, { Component } from 'react';
import { Link} from 'react-router-dom'; // , useHistory, withRouter
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Fetch from '../../../components/utils/Fetch';
import Links from '../../../data/link';
import Texto from '../../../data/es';
import TitleHeaderPage from '../Utils/TitleHeaderPage';

class ConfirmEmail extends Component {
    constructor(props) {
        super(props);

        this.fetch = new Fetch();
        this.fetch.setToast(toast);
        
        this.state = {
            loading: true,
            error: null,
            successMessage: null,
            validado: false,            
        };
    }

    componentDidMount() {
        this.confirmEmail();
    }

    confirmEmail() {

        this.setState({ loading: true, error:null,validado:false, successMessage:"" }); 
        const token = window.getParameterByName('token');
        
        // const tokenConf = window.getParameterByName('tokenc');
        // const expires = window.getParameterByName('expires');
        const response = this.fetch.fetchGet(`api/usuario/registro/confirmar-correo/${token}`);

        response.then(res => {
            console.log("----------------- res ---------------------- ", res);
            if (res && res.status === true) {
                toast.success(res.message);
                this.setState({ 
                    loading: false, 
                    successMessage: res.message ,
                    validado: true                 
                });

                // Modificar localStorage
                this.modifyLocalStorage(res.data.email, token);

                setTimeout(() => {
                    this.closeWindow();
                }, 5000);
            } else{
                this.setState({ loading: false, error: "" });  
            }
        }).catch(error => {
            this.setState({ loading: false, error: Texto.network_error });
        });
    }

    modifyLocalStorage(correo, tokenEmail) {
        const dataForm = JSON.parse(localStorage.getItem('dataForm'));

        if (dataForm) {
            // Actualizar un campo o añadir nueva información
            // dataForm.email = correo;
            dataForm.email_valido = correo;
            dataForm.token = tokenEmail;
            // Guardar los cambios en localStorage
            localStorage.setItem('dataForm', JSON.stringify(dataForm));
            localStorage.setItem("currentStep", 5);
            // toast.info('LocalStorage actualizado con el estado de confirmación y el paso actual.');
        }
    }
  

    handleContactanosClick = (event) => {
        event.preventDefault();
        window.jQuery("#modalFormContacts").modal("show");
    };

    closeWindow = () => {
        this.props.history.push('/register');
    };

    render() {
        const { loading, error, successMessage } = this.state;

        return (
            <div className="section mt-5">
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-md-10 col-sm-12 col-lg-5 mx-auto d-block">
                            <div className="card card-minw-25">
                                <div className="card-body">

                                    <TitleHeaderPage
                                        titlePage={'Verificación de Correo Electrónico'}
                                        description={''}
                                        visible={false}
                                    />

                                    <div className="text-center mb-3">
                                        <button className="btn">
                                            {this.state.error === null ?
                                                <i className='fa fa-check-circle fa-4x text-success'></i>
                                                :
                                                <i className='fa fa-exclamation-circle fa-4x text-danger'></i>
                                            }
                                        </button>
                                    </div>

                                    <div id="features" className="text-center features-caption-custom features">
                                        {loading && <p>Cargando...</p>}
                                        {error && <p className='text-center mb-2'>{error}</p>}
                                        {successMessage &&
                                            <>
                                                <p className='text-center mb-2'><b>{successMessage}</b></p>

                                                <p className='text-center'>
                                                    <button className='btn btn-success' onClick={this.closeWindow}>Continuar</button>
                                                </p>
                                            </>
                                        }
                                    </div>

                                    {!this.state.validado &&
                                    <>
                                    <h5 className='text-center text-danger'>Enlace de verificación no válida</h5>
                                        <p className='text-justify mb-2 mt-2'>
                                            En caso de haber expirado el enlace de verificación. Vuelva a solicitar un nuevo o comuníquese con el
                                            <Link to={{ pathname: Links[11].url }} className="ps-1 pe-1">centro de ayuda</Link>
                                            o envíenos una solicitud mediante el
                                            <Link to={{ pathname: '#' }} className="ps-1 pe-1" onClick={this.handleContactanosClick}>formulario de contacto</Link>
                                            para comunicarse con nuestro equipo.
                                        </p>

                                        </>
                                    }
                                </div>

                                <ToastContainer
                                    enableMultiContainer
                                    containerId={'toast-email'}
                                    position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover />
                                <ToastContainer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmEmail;
