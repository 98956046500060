import React, { useEffect } from 'react';
import Fetch from '../utils/Fetch';
import Config from '../../data/config';
import Links from '../../data/link';
import TitlePage from '../utils/TitlePage';
import Texto from '../../data/es';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let breadcrumbs = [
    {
        title: Links[0].title,
        url: Links[0].url
    },
    {
        title: Links[36].title,
        url: Links[36].url
    }
];

const ConsultaTuDeuda = (props) => {

    const fetch = new Fetch();
    fetch.setToast(toast);

    const titlePage = Texto.consulta_tu_deuda

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleRedirectUrlClick = (ev, url) => {
        ev.preventDefault();
        window.open(url, '_blank');
    }

    return (
        <section className="items-grid section mt-5">
            <div className="container mt-4">
                <TitlePage titlePage={titlePage} breadcrumbs={breadcrumbs} position={'left'} leftfull={false} />

                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                        <div className="single-news wow fadeInUp" data-wow-delay=".2s" 
                        onClick={e => handleRedirectUrlClick(e, 'https://www.ruat.gob.bo/vehiculos/consultageneral/InicioBusquedaVehiculo.jsf')}
                        rel="noreferrer noopener"
                        >
                            <div className="image">
                                <img className='thumb' alt='img-consulta-deuda-vehiculo'
                                    src={Config[2].url + '/static/img/bt_vehiculo.png'} />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                        <div className="single-news wow fadeInUp" data-wow-delay=".2s" 
                        onClick={e => handleRedirectUrlClick(e, 'https://www.ruat.gob.bo/inmuebles/consultageneral/InicioBusquedaInmueble.jsf')}
                        rel="noreferrer noopener">
                            <div className="image">
                                <img className='thumb' alt='img-consulta-deuda-inmueble'
                                    src={Config[2].url + '/static/img/bt_inmuebles.png'} />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                        <div className="single-news wow fadeInUp" data-wow-delay=".2s"  
                        onClick={e => handleRedirectUrlClick(e, 'https://tramitesgamc.cochabamba.bo/#/consulta-sitiosymercados-iframe')}
                        rel="noreferrer noopener">
                            <div className="image">
                                <img className='thumb' alt='img-consulta-deuda-inmueble'
                                    src={Config[2].url + '/static/img/bt_mercados.png'} />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                        <div className="single-news wow fadeInUp" data-wow-delay=".2s" 
                        onClick={e => handleRedirectUrlClick(e, 'https://tramitesgamc.cochabamba.bo/#/consulta-sitiosymercados-iframe')}
                        rel="noreferrer noopener">
                            <div className="image">
                                <img className='thumb' alt='img-consulta-tu-publicidad-exterior'
                                    src={Config[2].url + '/static/img/bt_publicidad_exterior.png'} />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                        <div className="single-news wow fadeInUp" data-wow-delay=".2s" 
                        onClick={e => handleRedirectUrlClick(e, 'https://www.ruat.gob.bo/actividadeseconomicas/consultageneral/InicioBusquedaActividadesEconomicas.jsf')}
                        rel="noreferrer noopener">
                            <div className="image">
                                <img className='thumb' alt='img-consulta-tu-actividades-economicas'
                                    src={Config[2].url + '/static/img/bt_actividad_economica.png'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ConsultaTuDeuda;