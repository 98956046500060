import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Texto from "../../../data/es";
import Fetch from "../../../components/utils/Fetch";
import { toast } from "react-toastify";

const ResetPasswordEmailFormulario = ({
	onSubmit,
	url,
	urlLoading,
	correoelectronico,
	setTitlePageInicio,
	setDescriptionInicio,
	handleChangeVisibleOrData
}) => {
	const idForm = "form-email";
	const fetch = new Fetch();
	fetch.setToast(toast);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [email, setEmail] = useState(correoelectronico);

	const isMounted = useRef(false);

	useEffect(() => {
		isMounted.current = true;

		return () => {
			isMounted.current = false;
		};
	}, []);

	// submit
	const handleSubmit = (e) => {
		e.preventDefault();
		setError('');
		window.jQuery("#" + idForm).parsley().validate();
		if (window.jQuery("#" + idForm).parsley().isValid() && isMounted.current) {
			handleVerifyEmailUser();
		}
	};

	// data response
	const handleVerifyEmailUser = async () => {
		setLoading(true);
		setError(null);
		try {
			const dataResponseJson = await fetch.fetchGet(`api/password/reset/verify-email/${email}`);
			if (dataResponseJson && dataResponseJson.status) {
				let celular = dataResponseJson.Usuario === null ? null : dataResponseJson.Usuario.celular;
				toast.success(dataResponseJson.message);
				onSubmit(email, celular, dataResponseJson.Usuario.token);
			}/*else
				setError("Correo Electrónico no tiene una cuenta asociada");*/
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	// click btn volver
	const handleEventClickVolver = (e) => {
		handleChangeVisibleOrData('form_email', true, true);
		handleChangeVisibleOrData('form_method', false, true);

		setTitlePageInicio(Texto.register_user_title_init);
		setDescriptionInicio(Texto.register_user_description_init);
	};

	// onchange value email
	const handleOnchangeInput = (e) => {
		setError('');
		setEmail(e.target.value);
	}

	/* ============================================================ */
	return (
		<>
			{loading && (<div className="col-12 mb-1 mx-auto d-block text-center">
				<img
					className="rounded"
					alt="pulse-loading"
					src={urlLoading}
					width="40"
				/>{" "}
				<span className="ml-5 text-violeta">Procesando</span>
			</div>)}

			<form
				onSubmit={handleSubmit}
				noValidate
				name={idForm}
				id={idForm}
				method="post"
			>
				<div className="form-group mb-2">
					<label htmlFor="email">(*) Correo Electrónico:</label>
					<input
						type="email"
						id="email"
						value={email}
						onChange={(e) => handleOnchangeInput(e)}
						className={"form-control form-control-lg" + (error ? " parsley-error" : "")}
						required
						data-parsley-required-message="Correo electrónico es requerido"
						data-parsley-type="email"
						data-parsley-email-message="Correo electrónico no válido"
					/>

					{error !== null && (
						<div className="parsley-errors-list filled">
							{error}
						</div>
					)}
				</div>

				<div className="d-flex d-sm-flex flex-sm-row justify-content-between mt-4">
					<div>
						{url == null ? (
							<button
								type="button"
								className="btn btn-fucsia"
								onClick={handleEventClickVolver}
							>
								Volver
							</button>
						) : (
							<Link to={url} className="btn btn-fucsia">
								Volver
							</Link>
						)}
					</div>
					<div>
						<button type="submit" className="btn btn-violeta" >
							Continuar
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

ResetPasswordEmailFormulario.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	handleChangeVisibleOrData: PropTypes.func.isRequired
};

export default ResetPasswordEmailFormulario;
