import React, { Component } from 'react';

class ValidateDeuda extends Component {

    constructor(props, context) {
        super(props, context);

        this.handleSearchInmuebleClick = this.handleSearchInmuebleClick.bind(this);
        this.handleNumeroOnChange = this.handleNumeroOnChange.bind(this)

        this.state = {
            optionsObjetoTributario: "",
            searchData: false,
            message: ""
        };

        this.refSlcObjetoTributario = React.createRef();
        this.refInputSearchObjectTributari = React.createRef()
        this.refInputCancelObjectTributari = React.createRef()
        this.refSlcTypeSearchMap = React.createRef()
        this.refInputSearchMap = React.createRef()
    }

    componentDidMount() {
        this.loadObjetoTributario()
    }

    loadObjetoTributario = () => {
        let self = this
        const objet_tributario_res = this.props.fetch.fetchGet(`api/prescripcion/objeto-tributario/all`);
        objet_tributario_res.then(res => {
            if (res !== undefined && res !== undefined && res.status === true) {
                if (res.status === true && res.ObjetoTributario !== null) {
                    const listItems = res.ObjetoTributario.map((item, index) => {
                        return <option key={index} value={item.id} titleinput={item.description} code={item.code} >{item.name}</option>
                    });
                    self.setState({ optionsObjetoTributario: listItems });
                }
            }
        })
    }

    async handleSearchInmuebleClick(event) {

        event.preventDefault()
        this.props.validateForm();
        debugger
        let input = this.refInputSearchMap.current
        let selectSearch = this.refSlcTypeSearchMap.current
        let selectObjectTributary = this.refSlcObjetoTributario.current
        if((input.value !== '' && selectSearch.selectedIndex > 0) || selectObjectTributary.selectedIndex > 0){
            
            let buttonCancel = this.refInputCancelObjectTributari.current
            this.setState({ searchData: true });
    
            console.log("--------------------------");
            debugger
            input.setAttribute("readonly", true);
            selectSearch.setAttribute("disabled", true);
            selectObjectTributary.setAttribute("disabled", true);
    
            buttonCancel.classList.add("btn-disabled")
            buttonCancel.setAttribute("disabled", true)
    
            const respInmueble = await this.props.fetch.axiosAsyncGet(`api-ruat/inmuebles/nro-inmueble/${input.value}`)
            if (Boolean(respInmueble) && respInmueble.status === true) {
                const propietarios = respInmueble.data.resInmueble.propiedades
                if (propietarios.length === 1) {
                    const form = new FormData();
                    form.append('propiedades[codigoPropiedad]', propietarios[0].codigoPropiedad);
    
                    debugger
                    const resDeudas = await this.props.fetch.fetchPost(form, `api-ruat/inmuebles/deudas`, undefined)
                    if (Boolean(resDeudas) && resDeudas.status === true) {
                        if (resDeudas.pagoObligatorio) {
                            input.removeAttribute("readonly")
                            selectSearch.removeAttribute("disabled")
                            selectObjectTributary.removeAttribute("disabeybled")
                            //button.classList.remove("btn-disabled")
                            //button.removeAttribute("disabled")
    
                            buttonCancel.classList.remove("btn-disabled")
                            buttonCancel.removeAttribute("disabled")
    
                            this.setState({ message: resDeudas.message, searchData: false });
    
                            //api-ruat/inmuebles/pagos-by-codigo-propiedad/(:any) tiene que ser mediante la propiedd
    
                            const respPagos = await this.props.fetch.axiosAsyncGet(`api-ruat/inmuebles/pagos-by-codigo-propiedad/${propietarios[0].codigoPropiedad}`)
                            if (Boolean(respPagos) && respPagos.status === true) {
                                debugger
                            }
                        } else {
                            this.setState({ message: resDeudas.message, searchData: false });
                            const data = {
                                showForm: true,
                                inmueble: respInmueble.data.resInmueble,
                                datosTecnicos: respInmueble.data.resTecnico,
                                gestionDeuda: resDeudas.data.gestionesDeuda,
                                idObjetoTributario: selectObjectTributary.selectedOptions[0].value,
                                objetoTributario: selectObjectTributary.selectedOptions[0].getAttribute("code"),
                                typeSearch: selectSearch.selectedOptions[0].value
                            }
                            this.props.nextPageCurrent(2, data)
                        }
                    }
                } else {
                    //saltar a la siguiente pagina
                    const data = {
                        showForm: true,
                        inmueble: respInmueble.data.resInmueble,
                        datosTecnicos: respInmueble.data.resTecnico,
                        idObjetoTributario: selectObjectTributary.selectedOptions[0].value,
                        objetoTributario: selectObjectTributary.selectedOptions[0].getAttribute("code"),
                        typeSearch: selectSearch.selectedOptions[0].value
                    }
                    this.props.nextPageCurrent(2, data)
                }
            } else {
                input.removeAttribute("readonly")
                selectSearch.removeAttribute("disabled")
                selectObjectTributary.removeAttribute("disabeybled")
                //button.classList.remove("btn-disabled")
                //button.removeAttribute("disabled")
    
                buttonCancel.classList.remove("btn-disabled")
                buttonCancel.removeAttribute("disabled")
                this.setState({ searchData: false });
            }
        }else{
            this.props.fetch.toast.warning("El formulario tiene campos obligatorio", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
        
    }

    handleNumeroOnChange(event) {
        event.preventDefault();
        var button = document.getElementById("btnModalSmallSubmit");

        if (event.currentTarget.value.length >= 3) {
            button.classList.remove("btn-disabled");
            this.setState({ verifyFur: true, inputFur: event.currentTarget.value });
        } else {
            button.classList.add("btn-disabled");
            this.setState({ verifyFur: false, inputFur: '' });
        }
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-12 padding-right-0">
                        <img className='rounded mx-auto d-block' alt='extension-prescripcion' src={"/static/img/extincion_prescripcion.png"} width="150" />
                        <h4 className="text-center mt-3 mb-3">Objetos Tributarios</h4>
                    </div>
                </div>

                {
                    this.state.message !== "" ?
                        <div className="row">
                            <div className="col-12">
                                <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                    <strong>Atención!</strong> {this.state.message}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                        : ""
                }

                <div className="row">
                    <div className="col-12 mb-3">
                        <select className="form-select" name={'[id_objeto_tributario]'} required data-parsley-required="true"
                            ref={this.refSlcObjetoTributario} >
                            <option defaultValue value="">Seleccione Objeto Tributario</option>
                            {this.state.optionsObjetoTributario}
                        </select>
                    </div>

                    <div className="col-12">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <select className="form-select" id="slcTypeSearchMap" ref={this.refSlcTypeSearchMap}>
                                    <option defaultValue value="nro_ruat">Nro. RUAT</option>
                                    <option value="cod_cat">Cod. Cat.</option>
                                </select>
                            </div>
                            <input name={'[numero]'} id={'[numero]'} type="text" className="form-control input-uppercase" placeholder="Número"
                                aria-label="Número" aria-describedby="Número" onChange={this.handleNumeroOnChange}  data-parsley-required="true" ref={this.refInputSearchMap} />
                        </div>
                    </div>
                </div>

                {
                    this.state.searchData ?
                        <div className="row">
                            <div className="col-12 mb-1">
                                <img className='rounded mx-auto d-block' alt='pulse-loading' src={"/static/img/pulse_200.gif"} width="70" />
                            </div>
                        </div> : ""}

                <div className="row mt-3">
                    <div className="col-12 col-md-6 col-lg-6 mb-3 text-start">
                        <div className="col-12 search-btn button ">
                            <input className="btn btn-disabled btn-full-screen" name="submit" id="btnModalSmallSubmit" type="submit" value="Buscar" onClick={this.handleSearchInmuebleClick} />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 mb-3 text-end">
                        <div className="col-12 search-btn button ">
                            <input className="btn bg-maroon-flush btn-full-screen" name="submit" type="submit" value="Cancelar" ref={this.refInputCancelObjectTributari} onClick={this.props.handleCancelClick} />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default ValidateDeuda;