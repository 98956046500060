import React, { useState, useEffect } from "react";
import Links from "../../data/link";
import Fetch from "../utils/Fetch";
import { toast } from "react-toastify";
import AuthService from "../Usuario/AuthService";
import "react-toastify/dist/ReactToastify.css";

import ValidateFur from "./ValidateFur";
import ValidateTipoTramite from "./ValidateTipoTramite";

const ModalAutorizacion = (props) => {
  const { closeModal } = props;

  const idModalAutorization = "modalAutorizationFull";
  const formModalAutorization = "formModalAutorization";

  //const token = window.getParameterByName("csrf-token");
  //const local = window.getParameterByName("lo");
  //const expiraEn = window.getParameterByName("expiraEn");
  //const preview = window.getParameterByName("preview");
  //const adm = window.getParameterByName("adm");

  const [currentPage, setCurrentPage] = useState(0);
  const [dataDerechoAdmision, setDataDerechoAdmision] = useState(0);
  //const [dataInmueble, setDataInmueble] = useState(undefined);
  //const [dataCategory, setDataCategory] = useState(undefined);
  //const [dataTipoTramite, setDataTipoTramite] = useState(undefined);
  //const [dataPropiedadSelected, setDataPropiedadSelected] = useState(undefined);

  const fetch = new Fetch();
  fetch.setToast(toast);

  const auth = new AuthService();

  useEffect(() => {
    if (!auth.loggedIn()) 
      window.location.href = Links[4].url;
    window.scrollTo(0, 0);
  }, []);

  const handleVoidEnter = (e) => {
    e.preventDefault();
    validateForm();
  };

  const handleCancelClick = (event) => {
    event.preventDefault();
    window.location.href = Links[108].url;
  };

  const validateForm = () => {
    window
      .jQuery("#" + formModalAutorization)
      .parsley()
      .validate();
    debugger;
    if (document.getElementById("inputModalFur")) {
      const liErrorFur = document.getElementById("inputModalFur").nextSibling;
      if (liErrorFur) {
        document
          .getElementById("inputModalFur")
          .parentElement.parentElement.append(liErrorFur);
      }
    }

    if (document.getElementById("saat_autorizacion[numero]")) {
      const ul_error_ci = document.getElementById(
        "saat_autorizacion[numero]"
      ).nextSibling;
      if (ul_error_ci) {
        document
          .getElementById("saat_autorizacion[numero]")
          .parentElement.parentElement.append(ul_error_ci);
      }
    }
  };

  const nextPageCurrent = (currentPage, data = undefined) => {
    debugger;
    if (currentPage === 1) {
      setCurrentPage(currentPage);
      setDataDerechoAdmision(data);
    }
    if (currentPage === 2) {
      const dataTemp = {
        dataPropiedad: data,
      };
      closeModal(dataTemp);
    }
  };

  return (
    <div
      className="modal fade modal-custom-atm"
      id={idModalAutorization}
      tabIndex="-1"
      aria-labelledby="modalAutorizationFullTitle"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
      style={{ overflowY: "auto" }}
    >
      <div
        className="modal-dialog modal-md"
        style={{ boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.75)" }}
      >
        <div className="modal-content ">
          <div className="modal-header text-butterfly-bush justify-content-center text-center">
            <h4
              className="modal-title "
              id="modalAutorizationLongTitle"
              style={{ fontWeight: 600 }}
            >
              AUTORIZACIÓN DE SOPORTES DE ANTENA
            </h4>
          </div>
          <div className="modal-body">
            <form
              className="contact__form"
              style={{ margin: "0.5rem" }}
              onSubmit={handleVoidEnter}
              id={formModalAutorization}
            >
              {currentPage === 0 ? (
                <ValidateFur
                  handleCancelClick={handleCancelClick}
                  fetch={fetch}
                  validateForm={validateForm}
                  nextPageCurrent={nextPageCurrent}
                  //csrfkey={csrfkey}
                />
              ) : (
                ""
              )}

              {currentPage === 1 ? (
                <ValidateTipoTramite
                  handleCancelClick={handleCancelClick}
                  fetch={fetch}
                  dataDerechoAdmision={dataDerechoAdmision}
                  validateForm={validateForm}
                  nextPageCurrent={nextPageCurrent}
                  //csrfkey={csrfkey}
                />
              ) : (
                ""
              )}
            </form>
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
};

export default ModalAutorizacion;
