import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AuthService from "../../components/Usuario/AuthService";
import Fetch from "../utils/Fetch";
import { useParams, useHistory } from "react-router-dom";
import Links from "../../data/link";

import HeaderTitlecard from "./_common/HeaderTitleCard";
import "react-toastify/dist/ReactToastify.css";
import "../../components/style/form-wizard.css";
import { Link } from "react-router-dom";
import Config from "../../data/config";
import Constant from "../../data/constant";
import Consulta from "./Consulta";


const ReviewRefused = (props) => {
    const { token, numero } = useParams();

    const fetch = new Fetch();
    const auth = new AuthService();
    fetch.setToast(toast);
    const history = useHistory();
    const tokenAdvertising = token;

    const urlPdfRepositorio = `${Config[10].url}/`;  //src/

    // const [showRequisito, setShowRequisito] = useState(false);
    // const [dataRequeriments, setDataRequeriments] = useState([]);
    const [dataAdvertising, setDataAdvertising] = useState(null);
    const [nodeRequisitos, setNodeRequisitos] = useState([]);
    const [totalValidateRequisito, setTotalValidateRequisito] = useState(0);
    const [totalRequisito, setTotalRequisito] = useState(0);

    const consultaSQL = new Consulta();

    useEffect(() => {
        debugger;
        if (!auth.loggedIn()) history.replace(Links[4].url);
        if (auth.loggedIn() && tokenAdvertising) {
            loadRequirements(tokenAdvertising);
            dataPublicidad(tokenAdvertising);
        }
    }, []);


    // abrir un una nueva pestaña
    function openInNewTab(url) {
        var win = window.open(url, '_blank');
        win.focus();
    }

    const formatDateDDMMYYYY = (dateParam) => {
        return window.moment(dateParam, "YYYY-MM-DD").format("DD/MM/YYYY");
    }

    // 
    const loadRequirements = async (tokenAdvertising) => {
        debugger;
        const query = consultaSQL.queryRequirementsSiteAdvertisingRechazado(tokenAdvertising);
        fetch.fetchPostGraphQl(query, "/graphql", null).then((data) => {
            debugger
            if (data && data.requirementsSiteAdvertising && data.requirementsSiteAdvertising.result) {
                const respRequisitos = data.requirementsSiteAdvertising.data.advertisingRequirementSite;
                setTotalRequisito(respRequisitos.length);
                let nodeReq = requisitosHtml(respRequisitos);
                setNodeRequisitos(nodeReq);
            }
        });

    };


    const requisitosHtml = (respRequistos) => {

        return respRequistos.map(
            ({ id, url, Estado, AdRequirementSite, AdAdvertisingRequirementObservations }, index) => {
                return (
                    <div className="row" key={index + "-" + id} index={index}>
                        <div
                            className="col-12 col-md-12"
                            id={"row-first-requisito-" + id}
                        >
                            <div className={"row row-table-custom pt-2 pb-2  me-1 justify-content-center align-items-center requisitosAdv-" + id} >
                                <div className="col-9 col-md-9">
                                    <span className="text-justify"> {AdRequirementSite.name} </span>
                                    <br />
                                    <strong>Estado: </strong> {Estado.name}
                                    <br />
                                    <strong>Archivo: </strong>
                                    <span className={`doc-name-${id}`}> {url && url.split('/').pop()}</span> {" "}
                                    <button type="button" onClick={(e) => openInNewTab(urlPdfRepositorio + url)} className="btn btn-link"><i className="far fa-file-pdf m-2" aria-hidden="true"></i></button>
                                    <br />
                                    <div className="mt-2 border rounded p-3 bg-light">
                                        <p className="text-justify">
                                            <span className="text-danger mr-2"><b>Observaciones: </b></span> {" "}
                                            {AdAdvertisingRequirementObservations[0].description}
                                            <br />
                                            <b>Fecha:</b> {formatDateDDMMYYYY(AdAdvertisingRequirementObservations[0].observationDate)}
                                        </p>
                                    </div>
                                </div>
                                
                                <div className="col-3 col-md-3">
                                    <div className="form-group files text-center">
                                        <label tabIndex={id} className="form-control">
                                            <input
                                                type="file"
                                                className="form-control"
                                                multiple=""
                                                id={`advRequirementSite_${id}`}
                                                name={`advRequirementSite[]`}                                                
                                                onChange={handleOnchangeInputFile}
                                                data-parsley-required={true}
                                                data-parsley-required-message="Por favor, selecciona un archivo"
                                                data-parsley-filemimetypes={AdRequirementSite.requiredImagenInstitucional ? "image/jpeg,image/png" : "application/pdf"}
                                                data-parsley-filemimetypes-message={AdRequirementSite.requiredImagenInstitucional ? "Solo se permiten imágenes (JPEG/PNG)" : "Solo se permiten archivos PDF"}
                                            />
                                        </label>
                                        <span className="fs-small8em text-maroon-flush small-description">
                                            7MB, Limite Máximo
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                );
            }
        );
    };

    const handleOnchangeInputFile = (event) => {

        debugger
        const inputFile = event.target;
        let sizeFileBytes = inputFile.files[0].size;
        let nameFile = inputFile.files[0].name;
        const divContainerFather = inputFile.closest(".row");
        let iconFile = inputFile.parentElement.parentElement.querySelector("i");
        if (iconFile) inputFile.parentElement.parentElement.removeChild(iconFile);
        // console.log("----------------------->>>>>>>>>>>>>>>>>>>>>>   ",event.target, inputFile.files[0]);
        if (sizeFileBytes > 0 && sizeFileBytes<= 7000000) {               
    
            if (inputFile.files[0].name.includes("https") || inputFile.files[0].name.includes("http")) {
              let nameFile = inputFile.files[0].name.split("/");
              nameFile = nameFile[nameFile.length - 1];
              divContainerFather.firstChild.children[3].innerHTML = `${nameFile.substring(
                nameFile.length - 20,
                nameFile.length
              )}`;
            } else {
              divContainerFather.firstChild.children[3].innerHTML = `${inputFile.files[0].name}`;
            }
    
            const format = inputFile.files[0].type.split("/");
            if (/^(gif|jpe?g|png|pdf)$/i.test(format[1])) {
              const icono = document.createElement("i");
              if (format[1] === "pdf") {
                icono.className = "fas fa-file-pdf icon";
              }
      
              if (/^(gif|jpe?g|png)$/i.test(format[1])){
                icono.className = "fas fa-file-image icon";
              }              
              inputFile.parentElement.parentElement.appendChild(icono);
            } 
        } else{
          inputFile.value = null;
          divContainerFather.firstChild.children[3].innerHTML = "";
         
          toast.error("Formato no valido o excede los 7MB el archivo");
        }    
      };

    // actualizar requisitos
    // actualizar requisitos
    const updateFormRequisitos = (event) => {
        debugger
        event.preventDefault();
        let inputsDocuments = document.getElementsByName("advRequirementSite[]");

        const inputFiles = [];
        Array.from(inputsDocuments).filter((input) => {
            debugger
            if (input.files.length > 0) {
                const idArray = input.getAttribute("id").split("_");
                inputFiles.push({
                    id: idArray[idArray.length - 1],
                    file: input.files[0]
                });
            }
        });


        if (inputFiles.length > 0 && inputFiles.length === totalRequisito) {

            console.log("************  inputFiles ************  ", inputFiles);
            const advRequirement = updateAdvertisingRequirement(inputFiles);
            toast.success("Requisitos actualizados.");

            // console.log("*** inputFiles *** ", inputFiles);
            // console.log("*** inputFiles 2 *** ", advRequirement);

            setTimeout(() => {
                history.push("/actividades-economicas/publicidad/lista");
            }, 2000);

        } else
            toast.error("Adjunte el/los documento(s) por requisito.");
    }

    // update file requirements
    const updateAdvertisingRequirement = async (dataInputFile) => {
        debugger
        try {
            console.log("_______ updateAdvertisingRequirement _________________", dataInputFile, dataAdvertising.id);
            const requirement = dataInputFile;
            if (requirement.length > 0 && parseInt(dataAdvertising.id) > 0) {
                debugger

                let queryPromise = requirement.map(async (advRequirement) => {
                    const formData = new FormData();
                    formData.append('idAdvertising', dataAdvertising.id);
                    formData.append('idAdvRequirementSite', advRequirement.id);
                    formData.append('file', advRequirement.file);
                    debugger
                    //console.log("formdata > _________________", formData);
                    debugger
                    const resqRequisitos = await fetch.fetchPostFormDataGraphQl(
                        formData,
                        "/api/advertising/requirement-site-update",
                        undefined
                    );

                    return resqRequisitos;
                });

                toast.success("Se actualizo los requisitos adjuntos");
                queryPromise = await Promise.all(queryPromise);
                return queryPromise.filter((requirement) => requirement);

            } else {

            }
        } catch (err) {
            console.error(err);
            toast.error("Ocurrio un error al momento de subir los archivos pdf.");
        }
    }


    const dataPublicidad = (token) => {
        const query = consultaSQL.queryInfoAdvertisingByToken(token);

        fetch
            .fetchPostGraphQl(query, "/graphql", null)
            .then((data) => {
                if (data && data.advertising && data.advertising.result) {
                    setDataAdvertising(data.advertising.data.advertising);
                }
            });
    }

    const backClick = () => {
        history.push("/actividades-economicas/publicidad/lista");
    };

    // si contribuytente natural o juridico
    const contribuyente = (listaPersona) => {
        if (Array.isArray(listaPersona) && listaPersona.length > 0) {
            let objetoTitular = listaPersona[0];
            let natural = parseInt(objetoTitular.natural);
            if (natural == 1) {
                return objetoTitular.Persona.nombre + " " + objetoTitular.Persona.apellidoPaterno + " " + objetoTitular.Persona.apellidoMaterno;
            } else {
                debugger
                return objetoTitular.AdTitularJuridico.razonSocial;
            }
        }
        return "Sin datos";
    }

    return (
        <section className="items-grid section mt-5 mb-5">
            <div className="container mt-4">
                <div className="card-w">
                    <HeaderTitlecard titulo={'Requisitos Observados de Publicidad '} descripcion={'Proceda a modificar los requisitos observados por el funcioanrio.'} />
                </div>
                {dataAdvertising && <div className="row mb-3">
                    <div className="col-md-2 col-xs-6"><b>Contribuyente: </b></div>
                    <div className="col-3">{contribuyente(dataAdvertising.AdTitulars)}</div>
                    <div className="col-md-2 col-xs-6"><b>Fur:</b></div>
                    <div className="col-md-1">{dataAdvertising.fur}</div>
                    <div className="col-md-2 col-xs-6"><b>Número:</b></div>
                    <div className="col-md-1">{dataAdvertising.numero}</div>
                    <div className="col-md-2 col-xs-6"><b>Clasificación: </b></div>
                    <div className="col-3">{dataAdvertising.clasificationCategoriesAdv[0].clasificationCategoryPublicity.clasification.name}</div>
                    <div className="col-md-2 col-xs-6"><b>Categoria: </b></div>
                    <div className="col-3">{dataAdvertising.clasificationCategoriesAdv[0].clasificationCategoryPublicity.categoryPublicity.name}</div>
                </div>
                }

                <form
                    className="contact__form"
                    id={"formValideRequirement"}
                >
                    {totalRequisito > 0 ?
                        <div className="mt-4 mb-4">
                            {nodeRequisitos}
                        </div>
                        :


                        <div className="row justify-content-center align-items-center minh-100 mt-5">
                            <div className="col-lg-8 col-md-8 col-xs-12">
                                <div className="alert alert-secondary" role="alert">                                    
                                    <p className="text-justify mt-2 mb-2"><b className="mr-2">Mensaje: </b> No tiene observaciones de los requisitos adjuntos de la publicidad.</p>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="buttons button_space justify-content-center mt-2">
                        <a className="back_button btn btn-fucsia" onClick={backClick}>Volver</a>
                        {
                            totalRequisito > 0 && <button type="button" className="next_button btn btn-violeta" onClick={updateFormRequisitos}>Continuar</button>
                        }

                    </div>
                </form >
            </div>
        </section>
    )

}
export default ReviewRefused;