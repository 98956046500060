import React, { Component, useRef } from "react";
import Fetch from "../utils/Fetch";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Texto from "../../data/es";
import AsyncSelect from "react-select/async";

let fetchG = undefined;
class ModalGacetaTributaria extends Component {
  constructor(props, context) {
    super(props, context);
    this.idModalGT = "modalDocRelacionadosFull";
    this.formModalGT = "formModalDocRelacionados";

    this.handleSubmiForm = this.handleSubmiForm.bind(this);
    this.optionsSelectGT = [];

    this.state = {
      optionsTypes: undefined,
      optionsGestion: undefined,
      listGacetasTributariasHtml: undefined,
      showButtonAdd: false,
    };

    this.fetch = new Fetch();
    this.fetch.setToast(toast);
    fetchG = this.fetch;
    this.ListGacetaTributaria = [];
  }

  componentDidMount() {
    const domModal = document.getElementById(this.idModalGT);
    const modalGT = new window.bootstrap.Modal(domModal, {
      keyboard: true,
      backdrop: "static",
      focus: true,
    });
    modalGT.show();
    this.loadTypes();
    this.loadDefaultRelation(this.props.gacetaTributaria.id_gaceta_tributaria);
  }

  loadDefaultRelation(idFather) {
    const self = this;
    const defaultRelation = this.fetch.fetchGet(
      `api/gaceta-tributaria/search-by-id-father/${idFather}`
    );
    defaultRelation.then((res) => {
      if (res && res.status && res.data) {
        debugger;
        self.ListGacetaTributaria = res.data.map((gacetaTributaria, index) => {
          let typeCode = gacetaTributaria.type_code.toLowerCase();
          typeCode = typeCode.charAt(0).toUpperCase() + typeCode.slice(1);
          return {
            label: gacetaTributaria.number + "/" + gacetaTributaria.gestion,
            type_name: typeCode,
            value: gacetaTributaria.id_child,
          };
        });

        const listGacetas = self.repaintListGaceta(self);
        self.setState({ listGacetasTributariasHtml: listGacetas });
      }
    });
  }

  loadTypes() {
    const self = this;
    const types = this.fetch.fetchGet(`api/gaceta-tributaria/types/all`);
    types.then((res) => {
      if (res && res.status && res.GTTypes) {
        const listItems = res.GTTypes.map((item, index) => {
          return (
            <option
              key={index}
              value={item.id}
              name={item.name}
              code={item.code}
            >
              {item.name} ({item.code})
            </option>
          );
        });
        self.setState({ optionsTypes: listItems });
      }
    });
  }

  handleSubmiForm(e) {
    e.preventDefault();
    debugger;
    this.validateForm(e);
  }

  validateForm = async (event) => {
    debugger;

    if (this.ListGacetaTributaria.length >= 0) {
      let formHtml = event.target;
      const form = new FormData(formHtml);

      form.append(
        "gt_relation[id_gaceta_tributaria]",
        this.props.gacetaTributaria.id_gaceta_tributaria
      );
      for (let index = 0; index < this.ListGacetaTributaria.length; index++) {
        const element = this.ListGacetaTributaria[index];
        form.append("gt_relation[id_child][" + index + "]", element.value);
      }
      const saveGacetaTributaria = await this.fetch.fetchPost(
        form,
        "api/gaceta-tributaria/set-anextion-whit-father",
        formHtml
      );
      debugger;
      if (saveGacetaTributaria && saveGacetaTributaria.status) {
        document.getElementById("btnCloseModalDocRela").click();
        toast.success(saveGacetaTributaria.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else
      toast.warning(
        "El formulario tiene valores obligatorios, debe agregar sus relacionados",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
  };

  async loadOptionGacetas(inputValue) {
    const typeOption = document.getElementById("gt_gaceta_tributaria[id_types]").selectedOptions[0];
    if (inputValue.length > 0 && typeOption.value ) {
      const typeHtml = document.getElementById(
        "gt_gaceta_tributaria[id_types]"
      );
      const optionSelected = typeHtml.selectedOptions[0];
      const response = await fetchG.fetchGet(
        `api/gaceta-tributaria/find-by-types/${inputValue}/${optionSelected.getAttribute(
          "code"
        )}`
      );
      if (response.status) return response.data;
      else return [];
    } else {
      toast.warning("No ha seleccionado un clasificador", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return this.optionsSelectGT;
    }
  }

  handleChangeGt = (selectedOption) => {
    debugger;
    this.setState({ showButtonAdd: false });
    if (selectedOption) {
      const existitem = this.ListGacetaTributaria.filter(
        ({ label }) => label === selectedOption.label
      );
      if (existitem.length === 0) {
        this.ListGacetaTributaria.push(selectedOption);
        this.setState({ showButtonAdd: true });
      } else
        toast.warning("Se ha encontrado un registro duplicado", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
    }
  };

  noOptionsMessageGt(props) {
    return Texto.criterio_busqueda_no_corresponde;
  }

  closeModal(e) {
    this.props.openModalDocRelacionado(e, false);
    this.ListGacetaTributaria = undefined;
  }

  deleteItem(e, self, idGacetaTributaria) {
    self.ListGacetaTributaria = self.ListGacetaTributaria.filter(
      ({ value }) => value !== idGacetaTributaria
    );
    const listGacetas = self.repaintListGaceta(self);
    self.setState({ listGacetasTributariasHtml: listGacetas });
  }

  addDocRelation(e, self) {
    e.preventDefault();
    debugger;
    const listGacetas = self.repaintListGaceta(self);    
    self.setState({ listGacetasTributariasHtml: listGacetas, });
    //document.querySelector(".css-tlfecz-indicatorContainer").click();
  }

  repaintListGaceta(self) {
    debugger;
    const listGacetas = self.ListGacetaTributaria.map(
      ({ label, type_name, value }) => {
        return (
          <div
            className="row row-table-custom pt-3 ms-1 me-1"
            key={value + "-"}
          >
            <div className="col-md-4 col-lg-4 mb-3">{type_name}</div>
            <div className="col-md-4 col-lg-4 mb-3">{label}</div>
            <div className="col-md-2 col-lg-2 mb-3">
              {" "}
              <i className="fa fa-check" aria-hidden="true"></i>
            </div>
            <div className="col-md-2 col-lg-2 mb-3">
              <button
                type="button"
                className="btn btn-outline-secondary bg-maroon-flush text-white"
                onClick={(e) => self.deleteItem(e, self, value)}
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
        );
      }
    );
    return listGacetas;
  }

  render() {
    return (
      <div
        className="modal fade "
        id={this.idModalGT}
        tabIndex="-1"
        aria-labelledby="modalDocRelacionadosFullTitle"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        style={{ overflowY: "auto" }}
      >
        <div
          className="modal-dialog modal-lg"
          style={{ boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.75)" }}
        >
          <div className="modal-content ">
            <div className="modal-header text-butterfly-bush justify-content-center">
              <h4
                className="modal-title "
                id="modalDocRelacionadosLongTitle"
                style={{ fontWeight: 600 }}
              >
                Nro{" "}
                {this.props.gacetaTributaria
                  ? this.props.gacetaTributaria.number +
                    "/" +
                    this.props.gacetaTributaria.gestion_name
                  : ""}
              </h4>

              <button
                type="button"
                className="btn-close h6 me-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="btnCloseModalDocRela"
                onClick={(e) => this.closeModal(e)}
              ></button>
            </div>
            <div className="modal-body">
              <form
                action=""
                className="contact__form"
                onSubmit={this.handleSubmiForm}
                id={this.formModalGT}
                name={this.formModalGT}
                noValidate
              >
                {this.props.gacetaTributaria ? (
                  <div className="row">
                    <div className="col-12 mb-3">
                      <p>
                        <strong>Título: </strong>
                        {this.props.gacetaTributaria.title}
                      </p>
                      <p>
                        <strong>Categoría: </strong>
                        {this.props.gacetaTributaria.type_name}
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="row">
                  <div className="col-12 col-md-4 col-lg-4 mb-3">
                    <label
                      htmlFor={"gt_gaceta_tributaria[id_types]"}
                      className="form-label"
                    >
                      Clasificador
                    </label>
                    <select
                      className="form-select"
                      name={"gt_gaceta_tributaria[id_types]"}
                      id={"gt_gaceta_tributaria[id_types]"}
                      required
                      data-parsley-required="true"
                    >
                      <option value="">Seleccione Clasificador</option>
                      {this.state.optionsTypes}
                    </select>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                    <label
                      htmlFor="gt_gaceta_tributaria[id]"
                      className="form-label"
                    >
                      Número
                    </label>
                    <AsyncSelect
                      cacheOptions
                      loadOptions={this.loadOptionGacetas}
                      defaultOptions
                      onChange={this.handleChangeGt}
                      isClearable
                      isSearchable
                      placeholder={"Seleccionada Gaceta Tributaria"}
                      required
                      defaultValue={[this.optionsSelectGT[0]]}
                      //onInputChange={(inputValue, { action }) => this.handleInputChange(inputValue, { action }, this)}
                      //noOptionsMessage={this.noOptionsMessageGt}
                    />
                  </div>

                  <div className="col-12 col-md-2 col-lg-2 mb-3 text-center">
                    {this.state.showButtonAdd ? (
                      <>
                        <label className="form-label"></label>
                        <div className=" search-btn button mb-3 pointer">
                          <button
                            className="btn btn-full-screen bg-green-haze"
                            onClick={(e) => this.addDocRelation(e, this)}
                          >
                            <i className="fas fa-plus"></i>
                          </button>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4 col-lg-4 mb-3">
                    <strong>Categoría</strong>
                  </div>
                  <div className="col-md-4 col-lg-4 mb-3">
                    <strong>Número</strong>
                  </div>
                  <div className="col-md-2 col-lg-2 mb-3">
                    <strong>Estado</strong>
                  </div>
                  <div className="col-md-2 col-lg-2 mb-3"></div>
                </div>
                {this.state.listGacetasTributariasHtml}

                <div className="row mt-4">
                  <div className="col-12 col-ms-6 col-md-6 col-lg-6 text-end"></div>
                  <div className="col-12 col-ms-3 col-md-3 col-lg-3 text-end"></div>

                  <div className="col-12 col-ms-3 col-md-3 col-lg-3 text-end">
                    <div className=" search-btn button mb-3 pointer">
                      <input
                        type="Submit"
                        className="btn btn-full-screen"
                        defaultValue={"Enlazar"}
                        name="Enlazar"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalGacetaTributaria;
