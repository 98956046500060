import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import MapOpenLayer from "./../MapOpenLayer";
import MapCatastroPublicModal from "../MapCatastroPublicModal";
import Languaje from "../../../data/es";
//import PopOverCustom from "./../PopOverCustom";

const CardDataTecnicos = (props) => {
  let baseServicios = [];
  let materialesVia = [];
  const stringMaterialVia = [];
  //const defaultTextEdit = " Editar ";

  const {
    //dataInmueble,
    dataTecnicosInmueble,
    dataInmuebleCatastro,
    onEventDataService,
    onEventDataMaterialVia,
    fetch,
    ruleValidation,
    isVisacionMinuta,
    //handleInputProgramas,
  } = props;

  const refSpanMaterialVia = useRef();
  const refSpaneService = useRef();

  const [checBoxMaterialVia, setChecBoxMaterialVia] = useState([]);
  const [checBoxService, setChecBoxService] = useState([]);
  const [construcciones, setConstrucciones] = useState(false);
  const [isTerreno, setIsTerreno] = useState(false);
  const [serviceContribuyente, setServiceContribuyente] = useState([]);

  useEffect(() => {
    if (dataTecnicosInmueble) {
      if (
        dataTecnicosInmueble.datosGenerales &&
        dataTecnicosInmueble.datosGenerales.clase === "PROPIEDAD HORIZONTAL"
      ) {
        if (dataTecnicosInmueble.construcciones.length > 0) {
          if (
            dataInmuebleCatastro &&
            dataInmuebleCatastro.informesTecnico.length > 0 &&
            dataInmuebleCatastro.informesTecnico[0].informesTecnicosConstruccion
              .length > 0
          )
            ruleValidation(
              dataTecnicosInmueble.construcciones[0].superficieM2,
              dataInmuebleCatastro.informesTecnico[0]
                .informesTecnicosConstruccion[0].superficieConstruida,
              "SUPERFICIE_TERRENO"
            );
        } else {
          if (
            dataInmuebleCatastro.informesTecnico.length > 0 &&
            dataInmuebleCatastro.informesTecnico[0].informesTecnicosConstruccion
              .length > 0
          )
            ruleValidation(
              0,
              dataInmuebleCatastro.informesTecnico[0]
                .informesTecnicosConstruccion[0].superficieConstruida,
              "SUPERFICIE_TERRENO"
            );
          else ruleValidation(0, 0, "SUPERFICIE_TERRENO");
        }
        onEventDataService([]);
        onEventDataMaterialVia([]);
        setIsTerreno(false);
      } else {
        if (dataTecnicosInmueble.terreno.length > 0) {
          configureDataInit();
          if (
            dataInmuebleCatastro &&
            dataInmuebleCatastro.informesLegal.length > 0
          )
            ruleValidation(
              dataTecnicosInmueble.terreno[0].superficeM2,
              dataInmuebleCatastro.informesLegal[0].superficie,
              "SUPERFICIE_TERRENO"
            );
        } else {
          if (
            dataInmuebleCatastro &&
            dataInmuebleCatastro.informesLegal.length > 0
          )
            ruleValidation(
              0,
              dataInmuebleCatastro.informesLegal[0].superficie,
              "SUPERFICIE_TERRENO"
            );
          else ruleValidation(0, 0, "SUPERFICIE_TERRENO");
        }
        setIsTerreno(true);
      }
    }
  }, []);

  const configureDataInit = async () => {
    baseServicios = await loadBasicServices();
    materialesVia = await loadMaterialesVia();
    const arrayServiciosRuat = dataTecnicosInmueble.terreno[0].servicios
      .replaceAll(" ", "")
      .split(","); //agua, luz
    const intersection = baseServicios.filter(
      (x) => !arrayServiciosRuat.includes(x.descripcion.trim())
    );
    const serviceDefineContribuyente = baseServicios.filter((x) =>
      arrayServiciosRuat.includes(x.descripcion)
    );
    const checkBoxService = intersection.map((service, index) => {
      return (
        <div className="d-block mb-1" key={service.id + "-service-basic"}>
          <input
            onChange={toggleChangeService}
            id={index + "-service-" + service.codigo}
            type="checkbox"
            className="form-check-input ms-2 me-2"
            value={service.descripcion}
          />
          <label
            htmlFor={index + "-service-" + service.codigo}
            className="form-label"
          >
            {service.descripcion}
          </label>
        </div>
      );
    });

    let materialViaDefault = "";
    if (dataTecnicosInmueble && dataTecnicosInmueble.terreno) {
      if (Array.isArray(dataTecnicosInmueble.terreno)) {
        const materialViaRuat = dataTecnicosInmueble.terreno[0].materialVia;
        const materialViaSelected = materialesVia.filter(
          (x) => materialViaRuat === x.material
        );
        materialViaDefault = materialViaSelected[0];
      }
    }

    let checkBoxMaterialVia = materialesVia.map((materialVia, index) => {
      stringMaterialVia.push(materialVia.id + "-material-via");
      if (parseInt(materialVia.orden) > parseInt(materialViaDefault.orden)) {
        return (
          <div className="d-block" key={materialVia.id + "-material-via"}>
            <input
              onChange={toggleChangeMaterialVia}
              id={materialVia.id + "-material-via"}
              type="checkbox"
              className="form-check-input ms-2 me-2"
              value={materialVia.material}
            />
            <label
              htmlFor={materialVia.id + "-material-via"}
              className="form-label"
            >
              {materialVia.material}
            </label>
          </div>
        );
      }
      return undefined;
    });

    checkBoxMaterialVia = checkBoxMaterialVia.filter(
      (materialVia) => materialVia
    );

    const serviceArrayView = [];
    serviceDefineContribuyente.forEach((service) => {
      serviceArrayView.push(service.descripcion);
    });
    setServiceContribuyente(serviceArrayView);
    setChecBoxService(checkBoxService);
    setChecBoxMaterialVia(checkBoxMaterialVia);
    onEventDataService(serviceDefineContribuyente);

    onEventDataMaterialVia(materialViaDefault);
  };

  const loadBasicServices = async () => {
    const services = await fetch.fetchGet(
      `api/flot-servicio/all-actives-gestion-cobro`
    );
    if (services && services.status) return services.Servicios;
    return [];
  };

  const loadMaterialesVia = async () => {
    const materialesVia = await fetch.fetchGet(
      `api/flot-material-via/all-actives-gestion-cobro`
    );
    if (materialesVia && materialesVia.status)
      return materialesVia.MaterialesVia;
    return [];
  };

  const toggleChangeService = (e) => {
    debugger;
    let stringService = refSpaneService.current.innerText;
    if (e.currentTarget.checked) {
      stringService = stringService + "-" + e.currentTarget.value;
    } else {
      stringService = stringService.replaceAll("-" + e.currentTarget.value, "");
    }
    const stringValue = stringService.replaceAll(" ", "");
    if (refSpaneService.current.nodeName === "SPAN")
      refSpaneService.current.innerText = stringValue;
    else
      refSpaneService.current.parentElement.nextElementSibling.value =
        stringValue;

    setServiceContribuyente(stringValue.split("-"));
    const basicServicesSelected = baseServicios.filter((x) =>
      stringValue.split("-").includes(x.descripcion.trim())
    );

    onEventDataService(basicServicesSelected);
  };

  const toggleChangeMaterialVia = (e) => {
    debugger;
    stringMaterialVia.forEach((materialCheck) => {
      if (e.currentTarget.id !== materialCheck) {
        const checkBoxMaterial = document.getElementById(materialCheck);
        if (checkBoxMaterial) checkBoxMaterial.checked = false;
      }
    });
    refSpanMaterialVia.current.innerText = e.currentTarget.value;
    const materialViaSelected = materialesVia.filter(
      (x) => e.currentTarget.value === x.material
    );
    onEventDataMaterialVia(materialViaSelected[0]);
  };
  const openModalConstrucciones = (event, typeModal) => {
    event.preventDefault();
    const modal = new window.bootstrap.Modal(
      document.getElementById("idModalContrucciones"),
      {
        keyboard: true,
        backdrop: "static",
        focus: true,
      }
    );

    if (typeModal === "CONSTRUCCIONES") {
      document.getElementById("h5ModalContrucciones").innerText =
        "CONSTRUCCIONES";
      setConstrucciones(dataTecnicosInmueble.construcciones);
    }

    if (typeModal === "CONSTRUCCIONES-ADICIONALES") {
      document.getElementById("h5ModalContrucciones").innerText =
        "CONSTRUCCIONES ADICIONALES";
      setConstrucciones(dataTecnicosInmueble.construccionesAdicionales);
    }
    modal.show();
  };

  let cont_constr = 0;
  return (
    <>
      <div className="card widget widget-simple">
        <div className="card-body ">
          <div className="row mb-3">
            <div className="col-12 widget-header">
              <h4 className="text-maroon-flush">
                <i className="fas fa-bookmark me-2"></i>{" "}
                {Languaje.datos_tecnicos_generales}
              </h4>
            </div>
          </div>

          <div className="widget-content">
            <div className="widget-body">
              {isTerreno ? (
                <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-4 ">
                    <div className="d-inline-block">
                      <p className="text-left">
                        <strong>Sup. Terreno/Catastro m2: </strong>
                        <span>
                          {dataInmuebleCatastro &&
                          dataInmuebleCatastro.informesLegal.length > 0 &&
                          dataInmuebleCatastro.informesLegal[0].superficie
                            ? parseFloat(
                                dataInmuebleCatastro.informesLegal[0].superficie
                              ).toFixed(2)
                            : "0.00"}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-4 col-lg-4 ">
                    <p className="text-left">
                      <strong>Sup. Terreno/Ruat m2: </strong>
                      <span>
                        {dataTecnicosInmueble &&
                        dataTecnicosInmueble.terreno.length > 0 &&
                        dataTecnicosInmueble.terreno[0].superficeM2
                          ? parseFloat(
                              dataTecnicosInmueble.terreno[0].superficeM2
                            ).toFixed(2)
                          : "0.00"}
                      </span>
                    </p>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-4 ">
                    <p className="text-left">
                      <strong>Sup. Constr./Catastro m2: </strong>
                      <span>
                        {dataInmuebleCatastro &&
                        dataInmuebleCatastro.informesTecnico.length > 0 &&
                        dataInmuebleCatastro.informesTecnico[0]
                          .informesTecnicosConstruccion.length > 0 &&
                        dataInmuebleCatastro.informesTecnico[0]
                          .informesTecnicosConstruccion[0].superficieConstruida
                          ? parseFloat(
                              dataInmuebleCatastro.informesTecnico[0]
                                .informesTecnicosConstruccion[0]
                                .superficieConstruida
                            ).toFixed(2)
                          : ""}
                      </span>
                    </p>
                  </div>

                  <div className="col-sm-12 col-md-4 col-lg-4 ">
                    <p className="text-left">
                      <strong>Sup. Constr./Ruat m2: </strong>
                      <span>
                        {dataTecnicosInmueble &&
                        dataTecnicosInmueble.construcciones.length > 0 &&
                        dataTecnicosInmueble.construcciones[0].superficieM2
                          ? parseFloat(
                              dataTecnicosInmueble.construcciones[0]
                                .superficieM2
                            ).toFixed(2)
                          : ""}
                      </span>
                    </p>
                  </div>
                </div>
              )}

              <div className="row">
                {isVisacionMinuta ? (
                  <div className="col-sm-12 col-md-4 col-lg-4 ">
                    <p className="text-left">
                      <strong>Construcciones: </strong>
                      <span>
                        {dataTecnicosInmueble
                          ? window.removeNullOrUndefined(
                              dataTecnicosInmueble.construcciones
                            ).length
                          : 0}{" "}
                      </span>
                      {dataTecnicosInmueble &&
                      window.removeNullOrUndefined(
                        dataTecnicosInmueble.construcciones
                      ).length > 0 ? (
                        <Link
                          to="#"
                          className="h6 ms-2"
                          title={`Total construcciones - ${
                            window.removeNullOrUndefined(
                              dataTecnicosInmueble.construcciones
                            ).length
                          }`}
                          data-toggle="modal"
                          data-target=".modal-contrucciones"
                          onClick={(event) =>
                            openModalConstrucciones(event, "CONSTRUCCIONES")
                          }
                        >
                          <i className="fa fa-building" aria-hidden="true"></i>
                        </Link>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                ) : (
                  ""
                )}

                {isVisacionMinuta ? (
                  <div className="col-sm-12 col-md-4 col-lg-4 ">
                    <p className="text-left">
                      <strong>Construcciones Adicionales: </strong>
                      <span>
                        {dataTecnicosInmueble &&
                        dataTecnicosInmueble.construccionesAdicionales
                          ? window.removeNullOrUndefined(
                              dataTecnicosInmueble.construccionesAdicionales
                            ).length
                          : 0}
                      </span>
                      {dataTecnicosInmueble &&
                      window.removeNullOrUndefined(
                        dataTecnicosInmueble.construccionesAdicionales
                      ).length > 0 ? (
                        <Link
                          to="#"
                          className="h6 ms-2"
                          title={`Total construcciones adicionales - ${
                            window.removeNullOrUndefined(
                              dataTecnicosInmueble.construccionesAdicionales
                            ).length
                          }`}
                          data-toggle="modal"
                          data-target=".modal-contrucciones-adicionales"
                          onClick={(event) =>
                            openModalConstrucciones(
                              event,
                              "CONSTRUCCIONES-ADICIONALES"
                            )
                          }
                        >
                          <i className="fa fa-building" aria-hidden="true"></i>
                        </Link>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                ) : (
                  ""
                )}

                {!isTerreno ? (
                  <div className="col-sm-12 col-md-4 col-lg-4 ">
                    <p className="text-left">
                      <strong>Areas Comunes: </strong>
                      <span>
                        {dataTecnicosInmueble &&
                        dataTecnicosInmueble.areasComunes
                          ? window.removeNullOrUndefined(
                              dataTecnicosInmueble.areasComunes
                            ).length
                          : 0}
                      </span>
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4 ">
                  <p className="text-left">
                    <strong>Clase: </strong>
                    <span>
                      {dataTecnicosInmueble
                        ? dataTecnicosInmueble.datosGenerales.clase
                        : ""}
                    </span>
                  </p>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-4 ">
                  <p className="text-left">
                    <strong>Area: </strong>
                    <span>
                      {dataTecnicosInmueble
                        ? dataTecnicosInmueble.datosGenerales.area
                        : ""}{" "}
                    </span>
                  </p>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-4 ">
                  <p className="text-left">
                    <strong>Zona Tributaria: </strong>{" "}
                    <span>
                      {dataTecnicosInmueble
                        ? window.getNumbersInString(
                            dataTecnicosInmueble.datosGenerales.zonaTributaria
                          )
                        : ""}
                    </span>
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4 ">
                  <p className="text-left">
                    <strong>Tipo Propiedad: </strong>
                    <span>
                      {dataTecnicosInmueble
                        ? dataTecnicosInmueble.datosGenerales.tipoPropiedad
                        : ""}
                    </span>
                  </p>
                </div>

                {isTerreno ? (
                  <>
                    <div className="col-sm-12 col-md-4 col-lg-4 ">
                      <p className="text-left">
                        <strong>Inclinación: </strong>{" "}
                        <span>
                          {dataTecnicosInmueble &&
                          dataTecnicosInmueble.terreno.length > 0 &&
                          dataTecnicosInmueble.terreno[0].inclinacion
                            ? dataTecnicosInmueble.terreno[0].inclinacion
                            : ""}
                        </span>
                      </p>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 ">
                      <p className="text-left">
                        <strong>Ubicación: </strong>{" "}
                        <span>
                          {dataTecnicosInmueble &&
                          dataTecnicosInmueble.terreno.length > 0 &&
                          dataTecnicosInmueble.terreno[0].ubicacion
                            ? dataTecnicosInmueble.terreno[0].ubicacion
                            : ""}
                        </span>
                      </p>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>

              {isTerreno ? (
                <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-4 ">
                    <p className="text-left">
                      <strong>Material Vía: </strong>
                      <span ref={refSpanMaterialVia}>
                        {dataTecnicosInmueble &&
                        dataTecnicosInmueble.terreno.length > 0 &&
                        dataTecnicosInmueble.terreno[0].materialVia
                          ? dataTecnicosInmueble.terreno[0].materialVia
                          : ""}
                      </span>
                    </p>
                  </div>

                  <div className="col-sm-12 col-md-8 col-lg-8 ">
                    <p className="text-left">
                      <strong>Servicios: </strong>{" "}
                      <span ref={refSpaneService}>
                        {serviceContribuyente.join("-")}
                      </span>
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}

              {isVisacionMinuta ? (
                <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-4  ms-4">
                    {checBoxMaterialVia}
                  </div>

                  <div className="col-sm-12 col-md-4 col-lg-4  ms-4">
                    {checBoxService}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade modal-contrucciones"
        tabIndex="-1"
        role="dialog"
        id="idModalContrucciones"
        aria-labelledby="h5ModalContrucciones"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="h5ModalContrucciones">
                Construcciones
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="row content-overflow">
                {construcciones
                  ? construcciones.map((construccion) => {
                      return construccion ? (
                        <div
                          key={`${cont_constr++}`}
                          className="col-12 col-md-6"
                        >
                          <div className="card widget widget-simple w-100">
                            <div className="card-header">
                              {!construccion.tipoEdificacion ? (
                                <div className="row ">
                                  <div className="col-4 card-title">
                                    <h4 className="">{construccion.bloque}</h4>
                                  </div>
                                  <div className="col-8 card-title">
                                    <h6 className="text-end">
                                      {construccion.usoConstruccion}
                                    </h6>
                                  </div>
                                </div>
                              ) : (
                                <div className="row ">
                                  <div className="col-12 card-title">
                                    <h6 className="">
                                      {construccion.tipoEdificacion}
                                    </h6>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="card-body">
                              <div className="row">
                                <div className="col-12 col-md-7 col-lg-7 ">
                                  <p className="text-start">
                                    <strong>Año de Contrucción: </strong>
                                    <span>{construccion.anioConstruccion}</span>
                                  </p>
                                </div>

                                <div className="col-12 col-md-5 col-lg-5 ">
                                  <p className="text-right"></p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12 col-md-7 col-lg-7 ">
                                  <p className="text-left">
                                    <strong>Superficie(m2): </strong>
                                    <span>{construccion.superficieM2}</span>
                                  </p>
                                </div>

                                <div className="col-12 col-md-5 col-lg-5 "></div>
                              </div>

                              <div className="row">
                                <div className="col-12 ">
                                  <p className="text-left">
                                    <strong>Construcción: </strong>
                                    <span>{construccion.tipoConstruccion}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
      <MapCatastroPublicModal
        module={"prescripcion"}
        nameForm={"domicilio_actividad_economica"}
        mode={"basic"}
        fillPaint={false}
      />
      <MapOpenLayer module={"prescripcion"} />{" "}
      {/* mapa global para extraer la imagen*/}
    </>
  );
};
export default CardDataTecnicos;
