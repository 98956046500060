import React, { useState, useEffect } from "react";
import Texto from "../../data/es";
import Config from "../../data/config";
import Links from "../../data/link";
import TitlePage from "../utils/TitlePage";
import { Link } from "react-router-dom";

const HomeMenuInmueble = (props) => {
	const titlePage = Texto.liquidaciones_mixtas;

	const [breadcrumbs, setBreadcrumbs] = useState([
		{
			title: Links[0].title,
			url: Links[0].url,
		},
		{
			title: Links[111].title,
			url: Links[111].url,
		},
		{
			title: Links[33].title,
			url: Links[33].url,
		},
	]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="items-grid section mt-5">
			<div className="container mt-4">
				<TitlePage
					titlePage={titlePage}
					breadcrumbs={breadcrumbs}
					position={"left"}
					leftfull={false}
				/>

				<div className="d-flex align-content-around justify-content-center flex-wrap pt-5 pb-5">
					<div className="p-2 bd-highlight">
						<Link
							to={`${Links[121].url}?liquidacion=inmuebles-impbi`}
							className="text-decoration-none w-100"
							title={"Impuesto Municipal a la Propiedad de Bienes Inmuebles"}
							rel="noopener noreferrer"
						>
							<div className="square">
								<div className="top bg-aero effect">
									<img
										className="mx-auto d-block img-noborder image"
										alt="img-impbi"
										width="108"
										height="108"
										src={Config[2].url + "/static/img/offcanvas/ddjj.png"}
									/>
									<div className="text">IMPBI</div>
								</div>
								<div className="bottom pt-4">
									<div className="text bg-aero">Ver</div>
								</div>
							</div>
						</Link>
					</div>

					<div className="p-2 bd-highlight">
						<Link
							to={`${Links[121].url}?liquidacion=inmuebles-imto`}
							className="text-decoration-none w-100"
							title={"Impuesto Municipal a la Transferencia Onerosa"}
							rel="noopener noreferrer"
						>
							<div className="square">
								<div className="top bg-aero effect">
									<img
										className="mx-auto d-block img-noborder image"
										alt="img-imto"
										width="108"
										height="108"
										src={Config[2].url + "/static/img/offcanvas/ddjj.png"}
									/>
									<div className="text">IMTO</div>
								</div>
								<div className="bottom pt-4">
									<div className="text bg-aero">Ver</div>
								</div>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HomeMenuInmueble;
