import React, { Component } from 'react';

class ItemsRecently extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Declaraciones Juradas Recientes</h3>
                        </div>

                        <div className="card-body p-0">
                            <ul className="products-list product-list-in-card pl-2 pr-2">
                            </ul>
                        </div>

                        <div className="card-footer text-center">
                            <a href="#" className="uppercase"></a>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default ItemsRecently;