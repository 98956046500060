import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../../components/style/form-wizard.css";
import AuthService from "../../components/Usuario/AuthService";
import Fetch from "../utils/Fetch";
import Links from "./../../data/link";
import funcChangeFormatErrorMessageParsley from './../utils/ChangeFormatErrorMessageParsley';
import Step1FormValideFur from "./Step1FormValideFur";
import Step2EventualFormValideDatosTecnicos from "./Step2EventualFormValideDatosTecnicos";
import Step2EmpresaFormValideDatosTecnicos from "./Step2EmpresaFormValideDatosTecnicos";
import Step2ActEconomicaFormValideDatosTecnicos from "./Step2ActEconomicaFormValideDatosTecnicos";
import Step2EmpresaElementoFormValideDatosTecnicos from "./Step2EmpresaElementoFormValideDatosTecnicos";
import StepFormCongratulation from "./StepFormCongratulation";
import StepFormContribuyente from "./StepFormContribuyente";
import StepFormRequisitos from "./StepFormRequisitos";
import StepFormCondictionProhibition from "./StepFormCondictionProhibition";
import Constant from "../../data/constant";
import Texto from "./../../data/es";
import TitlePage from "../utils/TitlePage";
import Config from "./../../data/config";
import Consulta from "./Consulta";

const breadcrumbs = [
    {
        title: Links[0].title,
        url: Links[0].url,
    },
    {
        title: Links[128].title,
        url: Links[128].url,
    },
    {
        title: Links[129].title,
        url: Links[129].url,
    },
    {
        title: Links[130].title,
        url: Links[130].url,
    },
    {
        title: Links[131].title,
        url: Links[131].url,
    }
];

let dataForm = {};

const Create = (props) => {

    const titlePage = Texto.publicity_create;

    let formnumber = 0;
    const [initialState, setInitialState] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showContribuyente, setShowContribuyente] = useState(false);
    const [dataAdvertisingDb, setDataAdvertisingDb] = useState(undefined);

    const [categorySelected, setCategorySelected] = useState({
        id: null,
        tipo: null,
        codeClasification: null
    });

    const fetch = new Fetch();
    fetch.setToast(toast);
    const history = useHistory();
    const auth = new AuthService();
    const consultaSQL = new Consulta();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!auth.loggedIn()) history.replace(Links[4].url)
    }, [initialState]
    );

    const nextClick = async (event, numForm, submit, data) => {
        debugger
        //let valideForm = valideInputsForm(event);
        let sleep = 300;
        if (!valideInputsForm(event))
            return false;

        if (numForm === 0) {
            dataForm.admision = data;
            setShowForm(true);
        }

        if (numForm === 1) {
            dataForm.datosTecnicos = data;
            deleteAdvertising(dataForm);
        }

        if (numForm === 2)
            dataForm.requirement = data;
        if (numForm === 3) {
            dataForm.condition = data.condition;
            dataForm.prohibition = data.prohibition;
        }

        // if (numForm === 4)   // CODE_EMPRESARIAL
        //     dataForm.prohibition = data;
        if (numForm === 4) {
            dataForm.propietario = data;
        }

        if (submit) {
            debugger
            const advertisingDb = await saveAdvertising(dataForm);
            if (advertisingDb) {
                dataForm.advertisingToken = advertisingDb.token;
                setDataAdvertisingDb(advertisingDb)
                const dataTechnical = await saveDataTechnical(dataForm, advertisingDb);
                const requirement = await saveRequirement(dataForm, advertisingDb);
                const conditions = await saveConditions(dataForm, advertisingDb);
                const prohibition = await saveProhibiciones(dataForm, advertisingDb);
                const contribuyente = await saveContribuyente(dataForm, advertisingDb);

                debugger
                formnumber = numForm + 1;
                setTimeout(() => {
                    if (dataTechnical && requirement && (conditions && conditions.length > 0) && (prohibition && prohibition.length > 0) && contribuyente) {
                        debugger
                        updateform();
                        progressForward(formnumber + 1);
                        contentchange();
                    } else {
                        toast.success("No se logrado completar la tarea, vuelva a intentarlo mas tarde...");
                    }
                }, 500);
            }
        } else {
            formnumber = numForm + 1;
            setTimeout(() => {
                debugger
                if (!submit) {
                    updateform();
                    progressForward(formnumber + 1);
                    contentchange();
                }
            }, 300);
        }
    };

    const backClick = (event, numForm) => {
        // console.log("********* nextClick  ", numForm);
        debugger
        if (numForm === 1) {
            dataForm.admision = undefined;
            setShowForm(false);
        }
        debugger
        formnumber = numForm - 1;

        updateform();
        progressBackward(formnumber + 1);
        contentchange();
    };

    const updateform = () => {
        const mainForm = document.querySelectorAll(".main-wizard");
        mainForm.forEach(function (mainformNumber) {
            mainformNumber.classList.remove('active-f-w');
        })
        mainForm[formnumber] && mainForm[formnumber].classList.add('active-f-w');
    }
    // modificado
    const progressForward = (currentStep) => {
        const stepList = document.querySelectorAll(".progress-bar-f-wizard li");
        const num = document.querySelector(".step-number");

        // Actualizar el número del paso actual
        num.innerHTML = currentStep;
        //console.log("nro de paso ", currentStep);
        // Marcar el paso actual como activo
        if (currentStep > 0 && currentStep <= stepList.length) {
            stepList.forEach((step, index) => {
                if (index < currentStep) {
                    step.classList.add('active-f-w');
                } else {
                    step.classList.remove('active-f-w');
                }
            });
        }
    };

    function progressBackward(currentStep) {
        debugger
        const stepList = document.querySelectorAll(".progress-bar-f-wizard li");
        const formNum = formnumber + 1;
        stepList[formNum].classList.remove('active-f-w');
        const num = document.querySelector(".step-number");
        num.innerHTML = formNum;
    }

    const contentchange = () => {
        const stepNumContent = document.querySelectorAll(".step-number-content");
        stepNumContent.forEach(function (content) {
            content.classList.remove('active-f-w');
            content.classList.add('d-none');
        });
        if (stepNumContent[formnumber]) {
            stepNumContent[formnumber].classList.add('active-f-w');
            stepNumContent[formnumber].classList.remove('d-none');
        }
    }

    const valideInputsForm = (event) => {
        event.preventDefault();
        const formHtml = event.target.closest("form")
        window
            .jQuery("#" + formHtml.getAttribute("id"))
            .parsley()
            .validate();

        funcChangeFormatErrorMessageParsley('input', 'datosTecnicos-nroActividad', null);
        funcChangeFormatErrorMessageParsley('input', 'datosTecnicos-nroInmueble', null);
        funcChangeFormatErrorMessageParsley('select', 'datosTecnicos-category', null);
        funcChangeFormatErrorMessageParsley('select', 'datosTecnicos-caracterization', null);
        funcChangeFormatErrorMessageParsley('input', 'ubicacion-adyacente_entre', 'error_adyacente_entre');
        funcChangeFormatErrorMessageParsley('input', 'ubicacion-adyacente_y', 'error_adyacente_y');

        if (window.jQuery("#" + formHtml.getAttribute("id")).parsley().isValid())
            return true;  //return JSON.stringify(jsonObject);
        return false;
    }

    /* REGISTRAR DATOS DEL FORMULARIO */
    const saveAdvertising = async (data) => {
        debugger
        const admision = data.admision;
        const datosTecnicos = data.datosTecnicos;
        if (admision && datosTecnicos) {
            const clasification = admision.clasification;
            const advertising = datosTecnicos.advertising;
            const dateRange = advertising.rangeDate.split("-");
            const dateStart = fechaFormateado(dateRange[0].trim());
            const dateEnd = fechaFormateado(dateRange[1].trim());
            const idCaracterizacion = (advertising.caracterization === undefined ? 0 : advertising.caracterization);
            const idCategorization = (advertising.categorization === undefined ? 0 : advertising.categorization);
            const idComunication = (advertising.comunication === undefined ? 0 : advertising.comunication);
            const tipo = admision.tipoFormulario;
            const fur = (tipo === "FUR") ? admision.fur : 0;
            const nroCrape = (tipo != "FUR") ? admision.fur : 0;

            const query = `mutation{addAdvertising (advertising: {fur: "${fur}", numeroCrape: "${nroCrape}", dateStart: "${dateStart}", dateExpires:"${dateEnd}"}, clasification: { id: ${clasification.id}},  categoryPublicity:{id:${advertising.category}}, caracterization: {id: ${idCaracterizacion}}, categorization: {id: ${idCategorization}}, comunication: {id: ${idComunication}}){message result data{ advertising{ id token numero}}}}`;
            const respAdvertising = await fetch.fetchPostGraphQl(query, "/graphql", null);

            if (respAdvertising && respAdvertising.addAdvertising && respAdvertising.addAdvertising.result) {
                toast.success("Publicidad: " + respAdvertising.addAdvertising.message);
                return respAdvertising.addAdvertising.data.advertising;
            } else
                toast.info(respAdvertising.addAdvertising.message);
        } else {
            toast.info('Publicidad no registrada, se requiere datos, vuelva a intentarlo mas tarde...');
        }
        return undefined;
    }


    const saveDataTechnical = async (data, advertisingDb) => {
        debugger
        const admision = data.admision;
        const datosTecnicos = data.datosTecnicos;
        const tipo = data.admision.tipoFormulario; // fur/crape

        if (admision && datosTecnicos) {
            debugger
            const advertising = datosTecnicos.advertising;
            const advDatosCatastro = tipo === "FUR" ? (datosTecnicos.advertising.inmueble.datosCatastro) : (datosTecnicos.advertising.inmueble.AdUbicacions[0]);
            const actEconomicaRuat = datosTecnicos.actividadEconomica.actividadEconomicaRuat;

            const area = { id: 0, zona: "" }
            if ("idAreaHomogenea" in advDatosCatastro)
                area.id = advDatosCatastro.idAreaHomogenea
            if ("zonaTributaria" in advDatosCatastro)
                area.zona = advDatosCatastro.zonaTributaria

            let urlImage = (tipo === "FUR" ? advDatosCatastro.base64Image : (advDatosCatastro.urlImagen));

            const query = `mutation{addDataTechnical (dataTechnical: {idAdvertising:${advertisingDb.id}, ancho: ${advertising.ancho}, alto:${advertising.alto}},   
                areaHomogenea:{ id: ${area.id}, zona: "${area.zona}"},
                ubication:{ latitud:"${advDatosCatastro.latitud}", longitud:"${advDatosCatastro.longitud}", coordinate:"${advDatosCatastro.coordinate}",  
                urlImagen:"${urlImage}", comuna: "${advDatosCatastro.comuna}", direccion:"${advertising.direccion}",  direccionEntre:"${advertising.direccionEntre}", 
                direccionY:"${advertising.directionY}" },  actividadEconomica: {nroActividad: "${actEconomicaRuat?.numeroActividad}", nroLicencia:"${actEconomicaRuat?.licencia}", 
                codigoClasificador: "${actEconomicaRuat?.codigoClasificador}", 
                codigoActividadEconomica: "${actEconomicaRuat?.codigoActividadEconomica}", tipoLicencia:"${actEconomicaRuat?.tipoLicencia}", 
                tipoActividad:"${actEconomicaRuat?.tipoActividad}"}) 
                {message  result  data {dataTechnical{ancho  alto},  actividadEconomicaRuat{nroLicencia nroActividad }   ubication{latitud } } }}`;

            const respAdvertising = await fetch.fetchPostGraphQl(query, "/graphql", null);

            if (respAdvertising && respAdvertising.addDataTechnical && respAdvertising.addDataTechnical.result) {
                debugger
                toast.success("Datos Técnicos: " + respAdvertising.addDataTechnical.message);
                return {
                    dataTechnical: respAdvertising.addDataTechnical.data.dataTechnical,
                    actividadEconomicaRuat: respAdvertising.addDataTechnical.data.actividadEconomicaRuat,
                    ubication: respAdvertising.addDataTechnical.data.ubication,
                }
            }
        } else
            toast.info('Datos Técnicos: Datos de inicio requeridos');
        return undefined;
    }

    const saveConditions = async (data, advertisingDb) => {
        debugger
        const admision = data.admision;
        const condition = data.condition;
        if (admision && condition) {
            let queryPromise = condition.map(async (condition) => {
                const query = `mutation{addCondition (advertisingRequirementSite: {idAdvertising: ${advertisingDb.id}, idRequirementSite:${condition.id}}) {message    result    data {advertisingRequirementSite{idAdvertising, url, token, createdAt}}}}`;
                const respAdvReqSite = await fetch.fetchPostGraphQl(query, "/graphql", null);
                //console.log("------ query saveConditions----- ", respAdvReqSite, condition);
                if (respAdvReqSite && respAdvReqSite.addCondition && respAdvReqSite.addCondition.result)
                    return respAdvReqSite.addCondition.data.advertisingRequirementSite;
                return undefined;
            });
            queryPromise = await Promise.all(queryPromise);
            queryPromise = queryPromise.filter((condition) => condition);
            toast.success("Condiciones: " + queryPromise.length + " registrados");
            return queryPromise;
        } else
            toast.info('Condiciones: Datos de inicio requeridos');
        return undefined;
    }

    // prohibiciones
    const saveProhibiciones = async (data, advertisingDb) => {
        debugger
        const admision = data.admision;
        const condition = data.prohibition;
        if (admision && condition) {
            let queryPromise = condition.map(async (condition) => {
                const query = `mutation{addCondition (advertisingRequirementSite: {idAdvertising: ${advertisingDb.id}, idRequirementSite:${condition.id}}) {message    result    data {advertisingRequirementSite{idAdvertising, url, token, createdAt}}}}`;
                const respAdvReqSite = await fetch.fetchPostGraphQl(query, "/graphql", null);
                //console.log("------ query saveProhibiciones----- ", respAdvReqSite, condition);
                if (respAdvReqSite && respAdvReqSite.addCondition && respAdvReqSite.addCondition.result)
                    return respAdvReqSite.addCondition.data.advertisingRequirementSite;
                return undefined;
            });
            queryPromise = await Promise.all(queryPromise);
            //return queryPromise.filter((condition) => condition);

            queryPromise = queryPromise.filter((condition) => condition);
            toast.success("Prohibiciones: " + queryPromise.length + " registrados");
            return queryPromise;
        } else
            toast.info('Prohibiciones: Datos de inicio requeridos');
        return undefined;
    }

    const saveRequirement = async (data, advertisingDb) => {

        debugger
        try {
            const requirement = data.requirement;
            if (requirement && advertisingDb) {
                let queryPromise = await data.requirement.map(async (requirement) => {
                    const file = requirement.file;
                    const idArray = requirement.id.split("_");
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('idAdvertising', advertisingDb.id);
                    formData.append('idRequirementSite', idArray[idArray.length - 1]);
                    // console.log("formdata > ", formData );
                    // debugger
                    return await fetch.fetchPostFormDataGraphQl(
                        formData,
                        "/api/advertising/requirement-site",
                        undefined
                    );
                });

                debugger
                queryPromise = await Promise.all(queryPromise);
                queryPromise = queryPromise.filter((requirement) => requirement);
                toast.success("Requerimientos:  Acción realizada con exito");
                return true;
            } else
                toast.info('Requerimientos: Datos de inicio requeridos');
            return false;
        } catch (err) {
            console.error(err);
        }
    }

    const saveContribuyente = async (data, advertisingDb) => {

        debugger
        try {
            const propietario = data.propietario;
            if (propietario && advertisingDb) {
                let codigoContribuyente = undefined;
                if (propietario.isNatural)
                    codigoContribuyente = propietario.natural.dataNatural.codigoContribuyente;
                else
                    codigoContribuyente = propietario.juridico.dataJuridico.codigoContribuyente;

                const query = `mutation{
                    addPersona(persona:{codigoRuat:"${codigoContribuyente}"}, advertising: {id: ${advertisingDb.id}} ){
                      message
                      result
                      data{
                        titular{
                          numeroDocumento
                        }
                        titularJuridico{
                          pmc
                        }
                      }
                    }
                  }`;
                const respAdvertising = await fetch.fetchPostGraphQl(query, "/graphql", null);

                if (respAdvertising && respAdvertising.addPersona && respAdvertising.addPersona.result) {
                    toast.success("Titular: " + respAdvertising.addPersona.message);
                    return respAdvertising.addPersona.data;
                }
            } else
                toast.info('Titular: Datos de inicio requeridos');
            return undefined;
        } catch (err) {
            console.error(err);
        }
    }

    // delete 
    const deleteAdvertising = async (dataForm) => {
        try {
            if (dataForm && dataForm.advertisingToken) {
                const token = dataForm.advertisingToken;
                const response = await fetch.fetchPostGraphQl(consultaSQL.deleteAdvertisingByToken(token), "/graphql", null);
                if (response && response.data.deleteAdvertisingAndRelatedData.result) {
                    console.log("Publicidad eliminado token: " + token)

                }
            }
        } catch (error) {
            console.log(error.message);
            // throw new Error('Network response was not ok');
        }
    };

    /* PASOS DEL DETALLE Y PASO POR CLASIFICADOR */
    const stepDetailInfo = () => {
        return (<>
            {
                Constant[0].publicidad_step_new.map((step, index) => (
                    index == 0 ?
                        <p key={index + 1} className="step-number-content text-justify active-f-w">{step}</p>
                        :
                        <p key={index + 1} className="step-number-content text-justify d-none">{step}</p>

                ))
            }
        </>)
    }

    const calculoSuperficieM2 = (alto, ancho) => {
        let totalM2 = 0;
        if (alto > 0 && ancho > 0) {
            totalM2 = (Math.round((alto * ancho) * 100) / 100);
        }
        return totalM2;
    }

    const fechaFormateado = (dateParam) => {
        let resultado = null;
        const lengthDate = dateParam.trim().length;
        const diaActual = window.moment().format('DD');

        if (lengthDate > 0 && lengthDate === 7) {
            resultado = window.moment(diaActual + "/" + dateParam, "DD/MM/YYYY").format("YYYY-MM-DD");
        }
        if (lengthDate > 0 && lengthDate === 10) {
            resultado = window.moment(dateParam, "DD/MM/YYYY").format("YYYY-MM-DD");
        }
        return resultado;
    }

    const changeEventCategory = (category) => {
        debugger
        let selected = { id: category.id, tipo: category.name, codeClasification: dataForm.admision.clasification.code };
        setCategorySelected(selected)
    }

    /*const eventActividadEconomica = (hasActividadEconomica) => {
        debugger
        setShowContribuyente(hasActividadEconomica)
    }*/

    return (
        <>
            <section className="items-grid section mt-5">
                <TitlePage
                    titlePage={titlePage}
                    breadcrumbs={breadcrumbs}
                    position={"left"}
                    leftfull={false}
                />
                <div className="container-f-wizard">
                    <div className="card-w">
                        <div className="form">
                            <div className="left-side bg-teklet-deep">
                                <div className="left-heading">
                                    <h4 className="text-center">Publicidad <br />
                                        {dataForm.admision ? dataForm.admision.clasification.name : ""}
                                        {(dataForm.admision && dataForm.admision.tipoFormulario == "FUR" && dataForm.admision.clasification.code === Constant[0].publicidad_clasificador.code_empresarial) && <><br /> Estructura </>}
                                    </h4>

                                </div>
                                <div className="steps-content">
                                    <h4>Paso <span className="step-number">1</span></h4>
                                    {stepDetailInfo()}
                                </div>
                                <ul className="progress-bar-f-wizard ">
                                    <li className="active-f-w" key={0}>Derecho de Admisión</li>
                                    <li key={1}>Datos Técnicos</li>
                                    <li key={2}>Requisitos</li>
                                    <li key={3}>Condiciones y Prohibiciones</li>
                                    <li key={4}>Contribuyente</li>
                                    <li key={5}>Vista Prevía</li>
                                </ul>
                            </div>

                            <div className="right-side">
                                <Step1FormValideFur
                                    fetch={fetch}
                                    toast={toast}
                                    nextClick={nextClick}
                                    auth={auth}
                                    history={history}
                                    stepNumber={0}
                                />
                                {showForm ?
                                    <>
                                        {(dataForm.admision ? dataForm.admision.clasification.code : "") === Constant[0].publicidad_clasificador.code_eventual ?
                                            <Step2EventualFormValideDatosTecnicos
                                                fetch={fetch}
                                                toast={toast}
                                                nextClick={nextClick}
                                                backClick={(e) => backClick(e, 1)}
                                                auth={auth}
                                                history={history}
                                                dataForm={dataForm}
                                                calculoSuperficieM2={calculoSuperficieM2}
                                                stepNumber={1}
                                                eventCategory={changeEventCategory}
                                                //eventActividadEconomica={eventActividadEconomica}
                                                Texto={Texto}
                                            /> : ""}

                                        {(dataForm.admision ? dataForm.admision.clasification.code : "") === Constant[0].publicidad_clasificador.code_empresarial && dataForm.admision.tipoFormulario != "CRAPE" ?
                                            <Step2EmpresaFormValideDatosTecnicos
                                                fetch={fetch}
                                                toast={toast}
                                                nextClick={nextClick}
                                                backClick={(e) => backClick(e, 1)}
                                                auth={auth}
                                                history={history}
                                                dataForm={dataForm}
                                                calculoSuperficieM2={calculoSuperficieM2}
                                                stepNumber={1}
                                                eventCategory={changeEventCategory}
                                                //eventActividadEconomica={eventActividadEconomica}
                                                Texto={Texto}
                                            /> : ""}

                                        {(dataForm.admision ? dataForm.admision.clasification.code : "") === Constant[0].publicidad_clasificador.code_empresarial && dataForm.admision.tipoFormulario === "CRAPE" ?
                                            <Step2EmpresaElementoFormValideDatosTecnicos
                                                fetch={fetch}
                                                toast={toast}
                                                nextClick={nextClick}
                                                backClick={(e) => backClick(e, 1)}
                                                auth={auth}
                                                history={history}
                                                dataForm={dataForm}
                                                stepNumber={1}
                                                // eventCategory={changeEventCategory}
                                                //eventActividadEconomica={eventActividadEconomica}
                                                Texto={Texto}
                                                fechaFormateado={fechaFormateado}
                                            /> : ""}

                                        {(dataForm.admision ? dataForm.admision.clasification.code : "") === Constant[0].publicidad_clasificador.code_actividad ?
                                            <Step2ActEconomicaFormValideDatosTecnicos
                                                fetch={fetch}
                                                toast={toast}
                                                nextClick={nextClick}
                                                backClick={(e) => backClick(e, 1)}
                                                auth={auth}
                                                history={history}
                                                dataForm={dataForm}
                                                calculoSuperficieM2={calculoSuperficieM2}
                                                stepNumber={1}
                                                eventCategory={changeEventCategory}
                                                //eventActividadEconomica={eventActividadEconomica}
                                                Texto={Texto}
                                            /> : ""}

                                        <StepFormRequisitos
                                            fetch={fetch}
                                            toast={toast}
                                            nextClick={nextClick}
                                            backClick={(e) => backClick(e, 2)}
                                            auth={auth}
                                            history={history}
                                            dataForm={dataForm}
                                            categorySelected={categorySelected}
                                            stepNumber={2}
                                        />

                                        <StepFormCondictionProhibition
                                            fetch={fetch}
                                            toast={toast}
                                            nextClick={nextClick}
                                            backClick={(e) => backClick(e, 3)}
                                            auth={auth}
                                            history={history}
                                            dataForm={dataForm}
                                            categorySelected={categorySelected}
                                            stepNumber={3}
                                        />

                                        <StepFormContribuyente
                                            fetch={fetch}
                                            toast={toast}
                                            nextClick={nextClick}
                                            backClick={(e) => backClick(e, 4)}
                                            auth={auth}
                                            dataForm={dataForm}
                                            history={history}
                                            stepNumber={4}
                                        />

                                        <StepFormCongratulation
                                            fetch={fetch}
                                            toast={toast}
                                            backClick={(e) => backClick(e, 5)}
                                            auth={auth}
                                            history={history}
                                            dataForm={dataForm}
                                            advertisingDb={dataAdvertisingDb}
                                            stepNumber={5}
                                        />
                                    </>
                                    :
                                    ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Create;
