import React, { useState } from "react";


const ContribuyenteInfo = ({	
	listaData,	
	dataPublication,	
	onClickButtonPdf
}) => {
	let tipo = listaData.tipo_contribuyente;	
	let razonSocial =
		listaData.razon_social_contribuyente === undefined ? "" : listaData.razon_social_contribuyente;

	let tipoDocumentoCode = listaData.tipo_documento;
	let nit = listaData.nit === undefined ? "" : listaData.nit;
	let documentoNro = listaData.numero_documento === undefined ? "" : listaData.numero_documento;	
	let pmc = listaData.pmc === undefined ? "" : listaData.pmc;		
	// let domicilioLegal = sizeList > 0 ? listaData.domicilioLegal : "";

	return (
		<div
			className="row border border-2 rounded-3 mb-1 fs-6 p-3"
			style={{ fontSize: "9.8pt" }}
			key={tipo}		
			>
			<h5 className="text-butterfly-bush fs-6 text-uppercase">Datos del Contribuyente</h5>
			<div className="w-100"></div>
			<div className="col-md-4 col-lg-3 col-xs-12 fw-bold text-md-start">
				Nombre/Razón Social:
			</div>
			<div className="col-md-8 col-lg-9">{razonSocial}</div>
			<div className="w-100"></div>
			<div className="col-4 col-lg-3 col-xs-12 fw-bold">
				Documento Identificación:
			</div>
			<div className="col">
				{tipoDocumentoCode} {documentoNro}
			</div>
			<div className="col-md-1 col-lg-1 col-xs-12 fw-bold">PMC:</div>
			<div className="col">{pmc}</div>
			<div className="w-100"></div>
			<div className="col-md-4 col-lg-3 col-xs-12 fw-bold">Tipo:</div>
			<div className="col">{tipo}</div>
			{/* <div className="clearfix"></div> */}
			<div className="row">
				<div
					className="col-md-12 col-lg-12 col-xs-12"
					style={{ textAlign: "right" }}
				>
				<br/>				
					{dataPublication.map((row) => (						
							<button
							key={row.codigo}
								className="btn btn-outline-success btn-sm" style={{marginRight:'5px'}}
								title="Ver reporte pdf"
								onClick={() => onClickButtonPdf(this, row.codigo)}
							>
								<i className="fas fa-file-pdf"></i> {row.nombre} 
							</button> 
						
					))}
				</div>
			</div>
		</div>
	);
};
export default ContribuyenteInfo;
