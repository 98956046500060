import React, { useState, useEffect } from "react";
//import DatePickerAtm from "../component/DatePickerAtm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Links from "../../data/link";

import Iframe from "react-iframe";
import { Link } from "react-router-dom";
import Texto from "../../data/es";
import PasosNavigationVisacion from "../utils/PasosNavigationVisacionMinutas";
import Config from "../../data/config";
import Fetch from "../utils/Fetch";
import AuthService from "../../components/Usuario/AuthService";

const FormVistaPrevia = (props) => {
  const fetch = new Fetch();
  fetch.setToast(toast);
  const { dataInmueble, visacionMinuta } = props; //data

  const [searchData, setSearchData] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const auth = new AuthService();

  useEffect(() => {
    
    if (Boolean(visacionMinuta)) {
      setSearchData(true);
      fetch
        .fetchGet(
          `api/visacion-minutas/by-token-and-numero/${visacionMinuta.token}/${visacionMinuta.numeroOrden}`
        )
        .then((dataJson) => {
          if (dataJson && dataJson.status) {
            completeVisacionMnuta(dataJson.data.VisacionMinuta.token);
            toast.success(dataJson.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        });
    }
    window.scrollTo(0, 0);
  }, []);

  const completeVisacionMnuta = (token) => {
    const form = new FormData();
    form.append("visacion_minutas[token]", token);
    fetch.fetchPost(form, "api/visacion-minutas/complete").then((dataJson) => {
      if (dataJson && dataJson.status) {
        setShowIframe(true);
        downloadPdf(
          Config[0].url +
            "report/visacion-minutas/generar/" +
            dataInmueble.numeroInmueble +
            "/" +
            dataInmueble.codigoPropiedad +
            "/" +
            visacionMinuta.token +
            "/preview?auth=" +
            auth.getToken(),
          true
        );
        setTimeout(() => {
          setSearchData(false);
        }, 6000);
      }
    });
  };

  const downloadPdf = (url, preview) => {
    setSearchData(true);
    if (preview)
      setTimeout(() => {
        document.getElementById("iframePreviewVisacionMinutaPdf").src = url;
      }, 300);
    else window.location.href = url;
  };

  const handleOnloadIFrame = () => {
    setSearchData(false);
  };

  const hanldeDownloadPdf = (event) => {
    event.preventDefault();
    downloadPdf(
      Config[0].url +
        "report/visacion-minutas/generar/" +
        dataInmueble.numeroInmueble +
        "/" +
        dataInmueble.codigoPropiedad +
        "/" +
        visacionMinuta.token +
        "/download?auth=" +
        auth.getToken(),
      false
    );
    setTimeout(() => {
      setSearchData(false);
    }, 8000);
  };

  const handleConfirmarDatos = (event) => {
    event.preventDefault();
    window.createBootbox("Esta Seguro de Continuar.", function (result) {
      if (result === true) {
        const form = new FormData();
        form.append("visacion_minutas[token]", visacionMinuta.token);
        fetch.fetchPost(form, "api/visacion-minutas/check").then((dataJson) => {
          if (dataJson && dataJson.status) {
            setShowIframe(true);
            setShowButtons(false);
            downloadPdf(
              Config[0].url +
                "report/visacion-minutas/generar/" +
                dataInmueble.numeroInmueble +
                "/" +
                dataInmueble.codigoPropiedad +
                "/" +
                visacionMinuta.token +
                "/preview?auth=" +
                auth.getToken(),
              true
            );
            setTimeout(() => {
              setSearchData(false);
            }, 8000);
          }
        });
      }
    });
  };

  const handleCancelClick = (event) => {
    event.preventDefault();
    window.createBootbox("Esta Seguro de Continuar.", function (result) {
      if (result === true) {
        const form = new FormData();
        form.append("visacion_minutas[token]", visacionMinuta.token);
        fetch
          .fetchPost(form, "api/visacion-minutas/delete")
          .then((dataJson) => {
            if (dataJson && dataJson.status) {
              toast.success(dataJson.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          });
      }
    });
  };

  const handleEditClick = (event) => {
    event.preventDefault();
    window.editVisacionMinutasMiniBootbox(
      Links[91].url,
      "1",
      visacionMinuta.token,
      visacionMinuta.numeroOrden
    );
  };

  return (
    <>
      <PasosNavigationVisacion
        nro_inmueble={dataInmueble.numeroInmueble}
        paso1_active={true}
        paso2_active={true}
        paso3_active={true}
        paso4_active={true}
        paso5_active={true}
      />

      <div className="modal-body">
        {searchData ? (
          <div className="row">
            <div className="col-12 mb-1">
              <img
                className="rounded mx-auto d-block"
                alt="pulse-loading"
                src={"/static/img/pulse_200.gif"}
                width="70"
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row">
          <div className="row mt-3">
            {showIframe ? (
              <div className="col-12 col-md-12 col-lg-12 embed-container">
                <Iframe
                  src=""
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="allow-same-origin allow-scripts allow-popups allow-forms"
                  allowFullScreen
                  id="iframePreviewVisacionMinutaPdf"
                  display="initial"
                  onLoad={handleOnloadIFrame}
                ></Iframe>
              </div>
            ) : (
              ""
            )}
            {showIframe ? (
              <div className="col-11 col-md-11 col-lg-11 paddingTop15">
                <p>
                  En caso no se visualize el pdf, puedes
                  <Link
                    to="#"
                    onClick={hanldeDownloadPdf}
                    title="Descargar"
                    style={{ paddingLeft: "5px" }}
                  >
                    hacer click aquí para descargar el archivo PDF.
                  </Link>
                </p>
              </div>
            ) : (
              ""
            )}

            {showIframe ? (
              <div className="col-1 col-md-1 col-lg-1 paddingTop15 pull-right">
                <Link
                  to="#"
                  onClick={hanldeDownloadPdf}
                  title="Descargar"
                  style={{ fontSize: "2em", float: "right", marginTop: "-5px" }}
                >
                  <i
                    className="fa fa-print fa-icon-image"
                    aria-hidden="true"
                  ></i>
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {showButtons ? (
        <div className="row">
          <div className="col-12 col-md-4 col-lg-4 text-center mb-3">
            <div className="search-btn button">
              <button
                type="button"
                className="btn bg-mandy btn-full-screen"
                onClick={handleCancelClick}
              >
                {Texto.eliminar}
              </button>
            </div>
          </div>
          <div className="col-12 col-md-4 col-lg-4 text-center mb-3">
            <div className="search-btn button">
              <input
                type="submit"
                className="btn bg-fountai-blue btn-full-screen"
                value={Texto.editar_mis_datos}
                onClick={handleEditClick}
              />
            </div>
          </div>

          <div className="col-12 col-md-4 col-lg-4 text-center mb-3">
            <div className=" search-btn button mb-3">
              <input
                type="Submit"
                className="btn bg-butterfl-bush btn-full-screen"
                defaultValue={Texto.confirmar_mis_datos}
                onClick={handleConfirmarDatos}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  );
};

export default FormVistaPrevia;
