import React, { Component } from "react";
import Constant from "../../../data/constant";
import Config from "../../../data/config";

class ValidateFur extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleFurClick = this.handleFurClick.bind(this);
    this.handleFurOnChange = this.handleFurOnChange.bind(this);
    this.state = {
      verifyFur: false,
      inputFur: "",
      message: "",
    };
    this.rubro = this.props.rubro;
  }

  componentDidMount() {
    if (this.props.typeFur === "PRESCRIPCION") {
    }

    if (this.props.typeFur === "CERTIFICACION") {
      this.setState({ messageDeclarcionJurada: "3 Intentos consecutivos. " });
    }

    if (this.props.typeFur === "LICENCIA_FUNCIONAMIENTO") {
      this.setState({
        messageDeclarcionJurada: "2 Declaraciones Juradas por cuenta.",
      });
    }
  }

  handleFurOnChange(event) {
    event.preventDefault();
    var button = document.getElementById("btnModalSmallSubmit");

    if (event.currentTarget.value.length >= 3) {
      button.classList.remove("btn-disabled");
      this.setState({ verifyFur: true, inputFur: event.currentTarget.value });
    } else {
      button.classList.add("btn-disabled");
      this.setState({ verifyFur: false, inputFur: "" });
    }
  }

  handleFurClick(event) {
    event.preventDefault();
    this.props.validateForm();
    if (this.state.verifyFur) {
      if (this.props.typeFur === "PRESCRIPCION") {
        this.props.fetch
          .fetchGet("api/cobros/fur-prescripciones/" + this.state.inputFur)
          .then((fur) => {
            if (fur && fur.status === true && fur.Fur !== null) {
              this.configureFurCookie(fur, Constant[0].modules.prescripcion);
              this.props.nextPageCurrent(1, {
                fur: fur.Fur,
                numero: this.state.inputFur,
                tramite: this.props.typeFur,
              });
            }
          });
      }

      if (this.props.typeFur === "CERTIFICACION") {
        this.props.fetch
          .fetchGet(
            "api/temporal-contribuyentes/by-csrkey/" +
              window.getParameterByName("csrf-token")
          )
          .then((dataJson) => {
            if (dataJson && dataJson.status === true) {
              this.props.fetch
                .fetchGet(
                  "api/cobros/fur-certificaciones/" +
                    this.state.inputFur +
                    "/" +
                    this.rubro
                )
                .then((fur) => {
                  debugger;
                  if (fur && fur.status === true && fur.Fur !== null) {
                    this.configureFurCookie(
                      fur,
                      Constant[0].modules.prescripcion
                    );
                    this.props.nextPageCurrent(1, {
                      fur: fur.Fur,
                      numero: this.state.inputFur,
                      tramite: this.props.typeFur,
                    });
                  } else
                    this.setState({
                      message:
                        "Fur no verificado o número de intentos superado.",
                    });
                });
            }
          });
      }

      if (this.props.typeFur === "LICENCIA_FUNCIONAMIENTO") {
        this.props.fetch
          .fetchGet("api/Cobros/getFur/" + this.state.inputFur)
          .then((fur) => {
            if (fur && fur.status === true && fur.Fur !== null) {
              //is ok
              this.configureFurCookie(
                fur,
                Constant[0].modules.actividad_economica
              );
            }
          });
      }
    }
  }

  configureFurCookie(fur, module) {
    var input_fur = document.getElementById("inputModalFur");
    // modificar con moment
    var current_date = new Date();
    current_date.setHours(current_date.getHours() + 1);
    var today = new Date(current_date).toUTCString();
    document.cookie =
      "fur=" +
      input_fur.value +
      "; expires=" +
      today.toString() +
      "; SameSite=None; Secure";

    if (module === Constant[0].modules.prescripcion) {
      let derecho_admision;
      if (fur.derecho_admision === "PRES_NATURAL")
        derecho_admision = Constant[0].derecho_admision.natural;
      if (fur.derecho_admision === "PRES_JURIDICO")
        derecho_admision = Constant[0].derecho_admision.juridico;

      window.eliminarCookie("da");
      document.cookie =
        "dapres=" +
        derecho_admision +
        "; expires=" +
        today.toString() +
        "; SameSite=None; Secure";
    }

    if (module === Constant[0].modules.actividad_economica) {
      window.jQuery("#modalFurFull").modal("hide");
      window.eliminarCookie("dapres");
      let derecho_admision =
        fur.derecho_admision === "TEMPORAL"
          ? Constant[0].derecho_admision.temporal
          : Constant[0].derecho_admision.permanente;
      document.cookie =
        "da=" +
        derecho_admision +
        "; expires=" +
        today.toString() +
        "; SameSite=None; Secure";
    }
    // modificar con moment
    this.props.fetch.toast.success(fur.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 padding-right-0 text-center mt-4 mb-4">
            <i
              className="far fa-file-alt"
              aria-hidden="true"
              style={{ fontSize: "8.5rem" }}
            ></i>
            <h4 className="text-center mt-4 text-butterfly-bush">
              Derecho de Admisión
            </h4>
          </div>
        </div>

        <div className="row">
          {this.state.message !== "" ? (
            <div className="col-12 padding-left-0 padding-right-0">
              <div
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                <strong>Atención!</strong> {this.state.message}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="row">
          <div className="col-12 padding-right-0">
            {this.state.messageDeclarcionJurada}
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    Nº FUR
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="FUR"
                  aria-label="Fur"
                  id="inputModalFur"
                  aria-describedby="basic-addon1"
                  onChange={this.handleFurOnChange}
                  data-parsley-required="true"
                  data-parsley-maxlength={Config[9].furLongitud}
                  maxLength={Config[9].furLongitud}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12 col-md-6 col-lg-6 mb-3 text-start">
            <div className="col-12 search-btn button ">
              <input
                className="btn bg-maroon-flush btn-full-screen"
                name="submit"
                type="submit"
                value="Cancelar"
                onClick={this.props.handleCancelClick}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mb-3 text-end">
            <div className="col-12 search-btn button ">
              <input
                className="btn btn-disabled btn-full-screen"
                name="submit"
                id="btnModalSmallSubmit"
                type="submit"
                value="Verificar"
                onClick={this.handleFurClick}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ValidateFur;
