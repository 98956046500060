import React, { Component } from 'react';

class Ayuda extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {

    }

    render = () => {
        return <div id="services" className="paddingTop30" >
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="error-template">
                            <h1>Centro de ayuda</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Ayuda;