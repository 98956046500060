import React, { useState } from 'react';
import Constant from '../../../data/constant';

const ValidatePropiedad = (props) => {
    const { dataInmueble, objetoTributario, dataDerechoAdmision } = props
    const claseInmueble = dataInmueble.dataInmueble.claseInmueble
    const numeroInmueble = dataInmueble.dataInmueble.numeroInmueble
    const codigoCatastral = dataInmueble.dataInmueble.codigoCatastral
    const datosGenerales = dataInmueble.dataTecnico.datosGenerales
    const [message, setMessage] = useState("")

    const constant = Constant[0];

    const handleClickRow = async (event, inmueble) => {
        event.preventDefault();
        
        /*props.fetch.fetchGet('api/temporal-contribuyentes/by-csrkey/'+props.csrfkey.csrfToken).then( async dataJson => {
            if (dataJson && dataJson.status === true){
                await verificarDeuda(inmueble);
            }
        })*/

        debugger
        const isValidkey = await props.fetch.fetchGet('api/temporal-contribuyentes/by-csrkey/'+props.csrfkey.csrfToken)
         if (isValidkey && isValidkey.status) {
            await verificarDeuda(inmueble);
         }
    }

    const verificarDeuda = async (inmueble) => {
        const form = new FormData();
        inmueble.codigoCatastral = dataInmueble.dataInmueble.codigoCatastral
        inmueble.codigoInmueble = dataInmueble.dataInmueble.codigoInmueble
        inmueble.numeroInmueble = dataInmueble.dataInmueble.numeroInmueble

        inmueble.objetoTributario = objetoTributario
        inmueble.dataTecnico = dataInmueble.dataTecnico
        
        if (dataDerechoAdmision.tramite === constant.modules.certificacion ) {
            props.nextPageCurrent(3, inmueble)
        }

        if (dataDerechoAdmision.tramite === constant.modules.prescripcion ) {
            form.append('propiedades[codigoPropiedad]', inmueble.codigoPropiedad);
            const resDeudas = await props.fetch.fetchPost(form, `api-ruat/inmuebles/deudas`, undefined)
            if (Boolean(resDeudas) && resDeudas.status) {
                if (resDeudas.pagoObligatorio)
                    setMessage(resDeudas.message)
                else
                    props.nextPageCurrent(3, inmueble)
            }
        }
    }

    const muebles = dataInmueble.dataInmueble.propiedades.map(({ codigoPropiedad, estadoPropiedad, fechaInicioPropiedad, porcentaje, propietario }, key) => {
        return <div className={"row pointer row-table-custom pt-2 pb-2 " + ((key % 2) === 0 ? " bg-picton-blue " : " background-white ") + " justify-content-center align-items-center objeto-tributario-deuda-" + codigoPropiedad + " "}
            key={codigoPropiedad} onClick={(e) => handleClickRow(e, { selected: true, codigoPropiedad, estadoPropiedad, fechaInicioPropiedad, porcentaje, propietario })} >
            <div className="col-10 col-md-10 ">
                <span>{propietario.nombreContribuyente} {porcentaje !== null ? '(AADD ' + porcentaje + ' %)' : ""}</span><br />
                <strong>Estado: </strong><span> {estadoPropiedad}</span> <br />
                <strong>Fecha Inicio: </strong><span>{fechaInicioPropiedad.replace(/\//g, '-')}</span>
            </div>
            <div className="col-2 col-md-2">
                <i className="far fa-hand-pointer fa-2x fa-icon-image" aria-hidden="true"></i>
                <input type="hidden" name={"inmueble[][codigoPropiedad]"} value={{ codigoPropiedad, estadoPropiedad, fechaInicioPropiedad, porcentaje, propietario }} />
            </div>

            <div className="col-12">
                <strong>Propietario: </strong><span>{propietario ? propietario.nombres + " " + propietario.apellidos : ""}</span>
            </div>
        </div>
    })

    return (
        <>
            <div className="row">
                <div className="col-12 padding-left-0 text-uppercase">
                    <strong><p style={{ fontSize: ".9em" }}>{objetoTributario.name}</p></strong>
                </div>
            </div>

            <div className="row">
                <div className="col-12 padding-left-0 text-uppercase mb-3">
                    <strong style={{ fontSize: ".9em" }}>Nro. Inmueble: </strong><span>{numeroInmueble}</span><br />
                    <strong style={{ fontSize: ".9em" }}>Cod. Cat.: </strong><span>{codigoCatastral}</span><br />
                    <strong style={{ fontSize: ".9em" }}>Clase: </strong><span>{claseInmueble}</span><br />
                    <strong style={{ fontSize: ".9em" }}>Tipo Propiedad: </strong><span>{datosGenerales ? datosGenerales.tipoPropiedad : ""}</span>
                </div>
            </div>

            <div className="row">
                <div className="col-12 padding-left-0 ">
                    <h4 className="text-center mb-3 text-butterfly-bush" id="h4TitleDocument" >Seleccione la Propiedad</h4>
                </div>

                {
                    message !== "" ?
                        <div className="col-12 padding-left-0 padding-right-0">
                            <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                <strong>Atención!</strong> {message}
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                        </div>
                        : ""
                }
            </div>

            {muebles !== undefined ? muebles : ""}
        </>
    )
}
export default ValidatePropiedad