const ruleValidation = (textInputRuat, textInputCatastro, typeValidacion) => {
  let message = "";
  switch (typeValidacion) {
    case "SUPERFICIE_TERRENO":
      debugger
      /*if (parseFloat(textInputRuat) !== parseFloat(textInputCatastro)) {
        message =
          "Datos Terreno, RUAT y CATASTRO no son iguales, regularice su Inmueble.";
      }*/
      if(Math.trunc(textInputRuat) !== Math.trunc(textInputCatastro)){
        message =
        "Datos Terreno, RUAT y CATASTRO no son iguales, regularice su Inmueble.";
      }
      break;
    case "FECHA_APROBACION_PLANO":
      const date = window.moment(textInputCatastro);
      if (textInputCatastro && !date.isValid()) {
        message =
          "Datos Terreno, FECHA APROBACIÓN PLANO no es correcto,  regularice su Inmueble.";
      }
      if(!textInputCatastro){
        message =
          "Datos Terreno, FECHA APROBACIÓN PLANO no esta registrado,  regularice su Inmueble.";
      }
      break;
    case "GLOBAL_CATASTRO":
      message =
        "Debe proceder a regularizar su Inmueble, en oficinas de Catastro CBBA.";
      break;
    case "MAPA_CATASTRO":
      message =
        "No fue posible, ubicar al predio, consulte en oficinas de Catastro CBBA.";
      break;
    case "MATRICULA":
      message =
        "Datos Terreno, MATRICULA no esta registrado,  regularice su Inmueble.";
      break;
    case "ASIENTO":
      message =
        "Datos Terreno, ASIENTO no esta registrado,  regularice su Inmueble.";
      break;
    case "NRO_RESOLUCION_MUNICIPAL":
      message =
        "Datos Terreno, NRO RESOLUCION MUNICIPAL/PREGUNTAR no esta registrado,  regularice su Inmueble.";
      break;
    case "COLINDANTE_ESTE":
      message =
        "Datos Terreno, COLINDANTE ESTE no esta registrado,  regularice su Inmueble.";
      break;
      case "COLINDANTE_OESTE":
      message =
        "Datos Terreno, COLINDANTE OESTE no esta registrado,  regularice su Inmueble.";
      break;
      case "COLINDANTE_SUD":
      message =
        "Datos Terreno, COLINDANTE SUD no esta registrado,  regularice su Inmueble.";
      break;
      case "COLINDANTE_NORTE":
      message =
        "Datos Terreno, COLINDANTE NORTE no esta registrado,  regularice su Inmueble.";
      break;
    default:
      message = "Debe proceder a regularizar su Inmueble.";
      break;
  }
  return message;
};
module.exports = {
  ruleValidation,
};
