import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../../components/style/form-wizard.css";
import AuthService from "../../components/Usuario/AuthService";
import Fetch from "../utils/Fetch";
import Links from "./../../data/link";
import funcChangeFormatErrorMessageParsley from './../utils/ChangeFormatErrorMessageParsley';
// import Step1FormValideFur from "./Step1FormValideFur";
import Constant from "../../data/constant";
import Texto from "./../../data/es";
import TitlePage from "../utils/TitlePage";
import Config from "./../../data/config";
import Consulta from "./Consulta";
import Step1EventualFormValideDatosTecnicos from "./_edit/Step1EventualFormValideDatosTecnicos";
import Step2EditFormRequisitos from "./_edit/Step2EditFormRequisitos";
import Step5Finish from "./_edit/Step5Finish";

import Step1EmpresaFormDatosTecnicos from "./_edit/Step1EmpresaFormDatosTecnicos";
import Step1ActEconomicaFormDatosTecnicos from "./_edit/Step1ActEconomicaFormDatosTecnicos";
import constant from "../../data/constant";

const breadcrumbs = [
    {
        title: Links[0].title,
        url: Links[0].url,
    },
    {
        title: Links[128].title,
        url: Links[128].url,
    },
    {
        title: Links[129].title,
        url: Links[129].url,
    },
    {
        title: Links[130].title,
        url: Links[130].url,
    },
    {
        title: "Detalles",
        url: ""
    }
];

let dataForm = {};

const Edit = () => {

    const { token } = useParams();

    const titlePage = Texto.publicity_edit;
    const urlPdfRepositorioMain = `${Config[10].url}`;

    //let formnumber = 0;

    //const [dataAdvertising, setDataAdvertising] = useState(null);
    //const [dataClasification, setDataClasification] = useState(null);
    //const [dataCategoryPublicity, setDataCategoryPublicity] = useState(null);
    // const [dataAdvertisingRequirementSite, setDataAdvertisingRequirementSite] = useState([]);
    // const [dataAdTitular, setDataAdTitular] = useState(null);
    const fetch = new Fetch();
    fetch.setToast(toast);
    const history = useHistory();
    const auth = new AuthService();
    //const consultaSQL = new Consulta();

    useEffect(() => {
        window.scrollTo(0, 0);

        if (!auth.loggedIn()) {
            history.replace(Links[4].url);
            return;
        }
    }, []);

    return (
        <section className="items-grid section mt-5 ">
            <TitlePage
                titlePage={titlePage}
                breadcrumbs={breadcrumbs}
                position={"left"}
                leftfull={false}
            />
            <div className="container-f-wizard">
                <div className="card-w p-4">
                    <div className="row mt-2 ">
                        <div className="text-wizard mb-3">
                            <h4 className="text-justify">CERTIFICACIÓN DE EMPLAZAMIENTO DE ELEMENTO DE PUBLICIDAD - EVENTUAL Del 07-10-2024 al 12-10-2024</h4>
                        </div>
                    </div>

                    <div className="main-wizard active-f-w">
                        <div className="row ">
                            <div className="col-12 col-md-4 ">
                                uno
                            </div>
                            <div className="col-12 col-md-4">
                                dos
                            </div>
                            <div className="col-12 col-md-4">
                                tres
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2 ">
                        <div className="text-wizard mb-3">
                            <h4 className="text-justify">UBICACIÓN </h4>
                        </div>
                    </div>

                    <div className="main-wizard active-f-w">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                uno
                            </div>
                            <div className="col-12 col-md-4">
                                dos
                            </div>
                            <div className="col-12 col-md-4">
                                tres
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2 ">
                        <div className="text-wizard mb-3">
                            <h4 className="text-justify">DATOS TECNICOS </h4>
                        </div>
                    </div>

                    <div className="main-wizard active-f-w">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                uno
                            </div>
                            <div className="col-12 col-md-4">
                                dos
                            </div>
                            <div className="col-12 col-md-4">
                                tres
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Edit;