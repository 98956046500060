import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import "./../css/style.css";

const TributarioButton = (props) => {
            
		const { rowObjectTributario, listUnidades, setSelectData,setCodeDepartament, setCodeObjeto ,setTitlePage } = props;
	
	const nombreObjTributario = rowObjectTributario.name;
	const codeObjTributarioOk = rowObjectTributario.code;
	const codeObjTributario = rowObjectTributario.code.toLowerCase();
	

	useEffect(() => {		
        window.scrollTo(0, 0);
    }, []);
	// =========================================== //
	const icons = {
		inmueble: "fas fa-home fa-3x rounded-circle border p-3",
		vehiculo: "fa fa-car fa-3x rounded-circle border p-3",
		patente_municipal: "fas fa-store fa-3x rounded-circle border p-3",
	};
	// =========================================== //

	const handleRedirectUrlClick = (codeObjeto, codeUnidad, nombreObjTributario) => {
		setTitlePage(nombreObjTributario);
		setCodeDepartament(codeUnidad);
		setCodeObjeto(codeObjeto);
		setSelectData(true);
	};

	const removeTextInParentheses = (text) => {
		return text.replace(/\s*\(.*?\)\s*/g, '').trim();
	  };
	// =========================================== //
	return (
		<div className="card">
			<div className="card-body">
				<div className="text-center mb-2">
					<i className={icons[codeObjTributario]} aria-hidden="true"></i>
					<h5 className="pt-4 pb-1 opacity-25">
						<strong>{nombreObjTributario}</strong>
					</h5>
				</div>
			</div>

			<div className="card-footer text-muted pointer">
				<div className="dropdown">
					<div
						className="text-center text-wrap text-green3278-bold "
						data-bs-toggle="dropdown"
						aria-expanded="false"
						style={{ minHeight: 40 }}
					>
						{removeTextInParentheses(nombreObjTributario)}
					</div>

					{codeObjTributario !== undefined ? (
						<div className="dropdown-menu">
							{listUnidades.map((rowData) => (
								<Link
									to="#"
									// rel="noopener noreferrer"
									className="dropdown-item"
									onClick={(e) =>
										handleRedirectUrlClick(codeObjTributarioOk, rowData.code, rowData.name+" - "+ removeTextInParentheses(nombreObjTributario))
									}
									title={rowData.name}
								>
									{(rowData.name)} {codeObjTributario}
								</Link>
							))}
						</div>
					) : (
						""
					)}
				</div>
			</div>
		</div>
	);
};

export default TributarioButton;
