import React, { } from 'react';
import Texto from '../../../data/es'

const DatosDeudaVehiculo = (props) => {

    const { dataDeudas, addDeuda, removeDeuda, activeEvent } = props

    const handleClickRow = (event, deuda) => {
        event.preventDefault()
        if (activeEvent) {
            let node_i = event.target.closest('.row').getElementsByTagName('i')[0];
            if (node_i.classList.contains('fa-square')) {
                node_i.classList.remove('fa-square');
                node_i.classList.add('fa-check-square');
                addDeuda(event, deuda);
            } else {
                node_i.classList.add('fa-square');
                node_i.classList.remove('fa-check-square');
                removeDeuda(event, deuda);
            }
        }
    }

    const handleClickDeleteRow = (event, deuda) => {
        event.preventDefault();
        debugger

        deuda.solicitado = false
        removeDeuda(deuda)
    }

    const rowTable = (sub_cont, gestion, name, monto, montoAcumulado, baseImponible, solicitado) => {
        return <div className={"row pointer row-table-custom ml-1 pt-3 objeto-tributario-deuda-" + gestion}
            key={sub_cont + '_' + gestion}  >
            <div className="col-3 col-md-1 ">
                <p>{gestion}</p>
            </div>
            <div className="col-4 col-md-6 d-none d-sm-block " >
                {name}
            </div>
            <div className="col-3 col-md-2 d-none d-sm-block " >
                {monto}
            </div>
            <div className="col-3 col-md-2">
                {montoAcumulado}
            </div>
            <div className="col-3 col-md-1 text-center">
                {
                    solicitado ?
                        <i className={`fas fa-times fa-2x text-danger`} aria-hidden="true" onClick={(e) => handleClickDeleteRow(e, {
                            ge: gestion, bi: baseImponible, cp: name, ma: montoAcumulado, mt: monto
                        })}></i>
                        :
                        <i className={`fa fa-square fa-2x `} aria-hidden="true" onClick={(e) => handleClickRow(e, {
                            ge: gestion, bi: baseImponible, cp: name, ma: montoAcumulado, mt: monto
                        })}></i>
                }
            </div>
        </div>
    }

    let sub_cont = 0

    const table = dataDeudas.map(({ ge, bi, cp, ma, mt, solicitado }) => {
        return rowTable(sub_cont++, ge, cp, mt, ma, bi, solicitado)
    })

    return (
        <div className="card widget widget-simple w-100" >
            <div className="card-body ">
                <div className="row ">
                    <div className="col-12 widget-header">
                        <h4 className="">{Texto.datos_deuda}</h4>
                    </div>
                </div>

                <div className="widget-content">
                    <div className="widget-body">

                        <div className="row  " >
                            <div className="col-3 col-md-1 pt-3 text-center">
                                <p className="font-weight-bold fs-small8em"><strong>Gestión</strong></p>
                            </div>
                            <div className="col-4 col-md-6 pt-3 d-none d-sm-block " style={{ justifyContent: 'left', display: 'flex' }}>
                                <p className="font-weight-bold text-center fs-small8em" ><strong>Detalle</strong></p>
                            </div>
                            <div className="col-3 col-md-2 pt-3 d-none d-sm-block " style={{ justifyContent: 'left', display: 'flex' }}>
                                <p className="font-weight-bold text-center fs-small8em" ><strong>Monto</strong></p>
                            </div>
                            <div className="col-3 col-md-2 pt-3" style={{ justifyContent: 'left', display: 'flex' }}>
                                <p className="font-weight-bold text-center fs-small8em" ><strong>Monto Acumulado</strong></p>
                            </div>
                            <div className="col-3 col-md-1 pt-3">
                                <p className="font-weight-bold text-center fs-small8em" ><strong></strong></p>
                            </div>
                        </div>
                        {table !== undefined ? table : ""}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default DatosDeudaVehiculo