import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fetch from "../utils/Fetch";
import VisacionMinuta from "../../components/utils/VisacionMinuta";
import FormAdministrativosLegales from "./FormAdministrativosLegales";
import FormSujetoPasivo from "./FormSujetoPasivo";
import FormComprador from "./FormComprador";
import FormMinuta from "./FormMinuta";
import FormVistaPrevia from "./FormVistaPrevia";
import {
  formInmueble,
  formDireccion,
  formPropiedad,
  formColindantes,
  formContrucciones,
  formContruccionesAdicionales,
  formDatosTecnicos,
  formTerreno,
  formServiciosBasicos,
  formTitularPropiedad,
  formCopropietarios,
  formCompradores,
} from "./FormData";

const Form = (props) => {
  const fetch = new Fetch();
  fetch.setToast(toast);

  const { dataInmueble } = props;
  const visacionMinutaClass = new VisacionMinuta();

  const [step, setStep] = useState(0);
  const [visacionMinuta, setVisacionMinuta] = useState(undefined);

  const onSubmitForm = (event, dataForm) => {
    debugger;
    console.log("propiesdass ", props);
    event.preventDefault();
    window
      .jQuery("#" + event.target.getAttribute("id"))
      .parsley()
      .validate();

    let formHtml = event.target; //event.target.getAttribute('id');
    const form = new FormData(event.target);
    const target = event.target;

    if (
      window
        .jQuery("#" + formHtml.getAttribute("id"))
        .parsley()
        .isValid()
    ) {
      const text = formHtml.getAttribute("id") === "formDataSell" ? "Para continuar con la siguiente sección, el Contribuyente debe tener su PMC actualizado" : "Esta Seguro de Continuar.";
      window.createBootbox(text, function (result) {
        if (result === true) {
          switch (formHtml.getAttribute("id")) {
            case "formDataInmueble":
              submitFormDataInmueble(form, target, dataForm);
              break;
            case "formDataSell": //segundo formulario
              submitFormToSell(form, target, dataForm);
              break;
            case "formDataBuy": //tercer formulario
              submitFormBuy(form, target, dataForm);
              break;
            case "formDataMinuta": //cuarto formulario
              submitFormMinuta(form, target, dataForm);
              break;
            default:
              break;
          }
        }
      });
    } else {
      toast.warn("El formulario tiene valores obligatorios", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const submitFormDataInmueble = async (form, target, data) => {
    form.append(
      "vm_visacion_minutas[id_tipo_visacion_minutas]",
      data.inmueble.tipoVisacionMinuta.id
    );
    form.append("vm_visacion_minutas[fur]", data.inmueble.derechoAdmision.fur);

    formInmueble(form, data);
    formDireccion(form, data);
    formPropiedad(form, data);
    formColindantes(form, data);
    formContrucciones(form, data);
    formContruccionesAdicionales(form, data);
    formDatosTecnicos(form, data);
    formTerreno(form, data);
    formServiciosBasicos(form, data);

    const saveVisacionMinuta = await fetch.fetchPost(
      form,
      "api/visacion-minutas/create",
      target
    );
    if (saveVisacionMinuta && saveVisacionMinuta.status) {
      if (Boolean(saveVisacionMinuta.data)) {
        visacionMinutaClass.setToken(
          saveVisacionMinuta.data.visacionMinuta.token
        );
        visacionMinutaClass.setNumeroOrden(
          saveVisacionMinuta.data.visacionMinuta.numero
        );
        visacionMinutaClass.setFur(data.inmueble.derechoAdmision.fur);
        setStep(1);
        setVisacionMinuta(visacionMinutaClass);
        toast.success(saveVisacionMinuta.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.warn(saveVisacionMinuta.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  const submitFormToSell = async (form, target, data) => {
    debugger;
    form.append("visacion_minuta[token]", visacionMinuta.token);
    formTitularPropiedad(form, data);
    formCopropietarios(form, data);

    const titularPropietario = await fetch.fetchPost(
      form,
      "api/visacion-minutas/titular-propiedad/create",
      target
    );
    if (titularPropietario && titularPropietario.status) {
      setStep(2);
      toast.success(titularPropietario.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const submitFormBuy = async (form, target, data) => {
    debugger;
    form.append("visacion_minuta[token]", visacionMinuta.token);

    formCompradores(form, data);

    const compradoresRes = await fetch.fetchPost(
      form,
      "api/visacion-minutas/compradores/create",
      target
    );
    if (compradoresRes && compradoresRes.status) {
      setStep(3);
      toast.success(compradoresRes.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const submitFormMinuta = async (form, target, data) => {
    form.append("visacion_minuta[token]", visacionMinuta.token);
    form.append(
      "vm_documentos_transferencia[amount]",
      data.dataMinuta.amount ? data.dataMinuta.amount.replace(",", ".") : 0
    );
    form.append("vm_documentos_transferencia[divisa]", data.dataMinuta.divisa);
    form.append("vm_documentos_transferencia[date]", data.dataMinuta.date);
    form.append(
      "vm_documentos_transferencia[id_documentos_minuta]",
      data.dataMinuta.documentoMinuta
    );
    form.append("vm_documentos_transferencia[numero]", data.dataMinuta.numero);
    form.append(
      "vm_documentos_transferencia[notario]",
      data.dataMinuta.notario
    );
    form.append(
      "vm_documentos_transferencia[numero_notario]",
      data.dataMinuta.numeroNotario
    );

    const minutaRes = await fetch.fetchPost(
      form,
      "api/visacion-minutas/documentos-transferencia/create",
      target
    );
    if (minutaRes && minutaRes.status) {
      setStep(4);
      toast.success(minutaRes.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <>
      <div className="row">
        {step === 0 ? (
          <FormAdministrativosLegales
            dataInmueble={dataInmueble}
            fetch={fetch}
            onSubmitForm={onSubmitForm}
          />
        ) : (
          ""
        )}

        {step === 1 ? (
          <FormSujetoPasivo
            dataInmueble={dataInmueble}
            dataPropietario={dataInmueble.propietario}
            fetch={fetch}
            onSubmitForm={onSubmitForm}
          />
        ) : (
          ""
        )}

        {step === 2 ? (
          <FormComprador
            dataInmueble={dataInmueble}
            dataPropietario={dataInmueble.propietario}
            nameForm={"persona"}
            fetch={fetch}
            onSubmitForm={onSubmitForm}
          />
        ) : (
          ""
        )}

        {step === 3 ? (
          <FormMinuta
            dataInmueble={dataInmueble}
            dataPropietario={dataInmueble.propietario}
            //dataInmuebleCatastro={dataInmuebleCatastro}
            nameForm={"persona"}
            fetch={fetch}
            onSubmitForm={onSubmitForm}
          />
        ) : (
          ""
        )}

        {step === 4 ? (
          <FormVistaPrevia
            dataInmueble={dataInmueble}
            dataPropietario={dataInmueble.propietario}
            visacionMinuta={visacionMinuta}
            fetch={fetch}
          />
        ) : (
          ""
        )}
      </div>

      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Form;
