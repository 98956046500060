import React, { useEffect } from 'react';
import Texto from '../../../data/es'

const DatosIdentificacionVehiculo = (props) => {

    const { dataVehiculo } = props

    useEffect(() => {

    }, []);
    //no hay
    //poliza 
    //placa anterior
    //Nro copia valida placa pta

    return (
        <div className="card widget widget-simple w-100" >
            <div className="card-body ">
                <div className="row ">
                    <div className="col-12 widget-header">
                        <h4 className="">{Texto.datos_identificacion_vehiculo}</h4>
                    </div>
                </div>

                <div className="widget-content">
                    <div className="widget-body">

                        <div className="row ">
                            <div className="col-sm-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left" ><strong >Placa PTA: </strong><span>{dataVehiculo.pl ? dataVehiculo.pl : "--"}</span></p>
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left"><strong >Póliza: </strong><span>{}</span></p>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left"><strong >Placa Anterior: </strong> <span>{}</span></p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 padding-left-right-0">
                                <p className="text-left"><strong >Nro. Copia Válida Placa PTA: </strong><span></span></p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DatosIdentificacionVehiculo