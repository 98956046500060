import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Config from "../../../data/config";

const SocialNetworks = ({ visible }) => {
    const redes = (visible)=>{
        if(visible){
            return (<div className="text-center mb-3 ">
			<Link
				to={{ pathname: "https://www.facebook.com/gamcochabamba" }}
				target="_blank"
				rel="noreferrer noopener"
				title="Facebook GAMC"
				className="btn_icon btn-primary btn-floating mx-1"
			>
				<i className="fab fa-facebook-f"></i>
			</Link>

			<Link
				to={{ pathname: "https://twitter.com/gam_cochabamba" }}
				target="_blank"
				rel="noreferrer noopener"
				title="Twitter GAMC"
				className="btn_icon btn-primary btn-floating mx-1"
			>
				<i className="fab fa-twitter"></i>
			</Link>

			<Link
				to={{ pathname: "https://www.instagram.com/cochabambaciudad" }}
				target="_blank"
				rel="noreferrer noopener"
				title="Instagram GAMC"
				className="btn_icon btn-primary btn-floating mx-1"
			>
				<i className="fab fa-instagram"></i>
			</Link>            
		</div>);
        }else{
            return ('')
        }
        
    }
	return (
		redes(visible)
	);
};

SocialNetworks.prototype = {
	visible: PropTypes.bool.isRequired,
};

export default SocialNetworks;
