import React, { Component } from 'react';
import Config from '../../data/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Fetch from '../utils/Fetch';
import Carroucel from '../../data/CarroucelLegalTributario';
import link from '../../data/link';

class HeaderCarroucel extends Component {

    constructor(props) {
        super(props);
        this.fetch = new Fetch();
        this.fetch.setToast(toast);
        this.list_carroucel = []

        this.handleRedirectOpenPage = this.handleRedirectOpenPage.bind(this);
        //this.fechaPrimeraPublicacion = this.fechaPrimeraPublicacion.bind(this);
        //this.fechaSegundaPublicacion = this.fechaSegundaPublicacion.bind(this);
        //this.procedimientoProcLiqDetMixta = this.procedimientoProcLiqDetMixta.bind(this);

        this.state = {
            tipoCambioTazaInteres: 0,
            renderCarrousel: false
        };
        this.currentDate = new Date();
        this.fechaLiteral = window.fechaToLiteral(this.currentDate);

        this.fechaLiteralArray = this.fechaLiteral.split(" ");
        this.fechaLiteralString = this.fechaLiteralArray[0] + " " + this.fechaLiteralArray[1] + " " + this.fechaLiteralArray[2];
        this.dayLiteralString = window.dayToLiteral(this.currentDate);
    }

    async componentDidMount() {
        const cotizaciones = await this.fetch.axiosAsyncGet(`api/cobros/cotizaciones`);
        if (cotizaciones !== null && cotizaciones.status === true) {
            if (Boolean(cotizaciones.Ufv)) {
                this.setState({ tipoCambioTazaInteres: cotizaciones.Ufv.cotizacion })
            }
        }

        if (this.list_carroucel.length <= 0) {
            this.list_carroucel = Carroucel.map((item) => {
                return (
                    <div className={"carousel-item " + item.class_active} key={item.key}>
                        <a href={item.href} title={item.title} className="item-title w-100" target={item.target}>
                            <img className="img-fluid full-image" src={item.src} alt={item.alt} width='1600' height='600' />
                        </a>
                    </div>
                );
            })
            this.setState({ renderCarrousel: true })
        }
    }

    handleRedirectOpenPage(event, url) {
        event.preventDefault();
        window.open(url, "_blank");
      }
/*
    procedimientoProcLiqDetMixta (event){
        event.preventDefault();
        window.open('https://atmapi.cochabamba.bo/restServerAtm/documents/preview/reglamento-procesos-liquidacion-det-mixt-1553', '_blank');
    }

    rtaOficinaVirtual(event) {
        event.preventDefault();
        window.open('https://atmapi.cochabamba.bo/restServerAtm/documents/preview/rta-1370-2020', '_blank');
    }

    fechaPrimeraPublicacion(event) {
        event.preventDefault();
        window.open('https://atmapi.cochabamba.bo/restServerAtm/documents/preview/notificaciones-mixtas-2011-2014', '_blank');
    }

    fechaSegundaPublicacion(event) {
        event.preventDefault();
        window.open('https://atmapi.cochabamba.bo/restServerAtm/documents/preview/notificaciones-mixtas-2011-2014-rect-2015-2018', '_blank');
    }*/

    render = () => {
        return <section className="hero-area overlay">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 col-12 padding-left-0 padding-right-0">
                        <div className="hero-text text-center">

                            {this.state.renderCarrousel ?
                                <div id="carouselSimatTop" className="carousel slide carousel-fade" data-bs-ride="carousel" >
                                    <div className="carousel-inner">
                                        {this.list_carroucel}
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselSimatTop" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselSimatTop" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                                : ""
                            }
                        </div>
                    </div>

                    <div className="col-md-12 col-12 grid-noti">
                        <div className="container-noti">
                            <div className="cn-title">
                                <div className="calendar mt-1">
                                    <i className="far fa-calendar-alt"></i>
                                </div>
                                <div className="ms-1">
                                    <div>
                                        <span className="text-date text-white text-uppercase">{this.dayLiteralString + " " + this.fechaLiteralString}</span>
                                    </div>
                                    <div>
                                        <span className="text-date text-white" style={{ fontSize: "1.3rem" }}>{this.currentDate.getFullYear()}</span>
                                    </div>
                                </div>

                                <div className="ms-3 ps-3 cn-ufv">
                                    <div className="text-center">
                                        <span className="text-date text-white">UFV</span>
                                    </div>
                                    <div className="text-center ms-3">
                                        <span className="text-date text-white">{this.state.tipoCambioTazaInteres}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="cn-body">
                                <div className="accordion accordion-flush" id="accordionFlushExample" >
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingOne">
                                            <button className="accordion-button collapsed accordion-custom-button text-white"
                                                type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                                                aria-expanded="false" aria-controls="flush-collapseOne"
                                            >
                                                <div className="avatar-btn">
                                                    <img src={Config[2].url + '/static/img/offcanvas/atm_white.png'} alt="atm icon blanco"  width='18' height='18' />
                                                </div>
                                                Pagos Online
                                            </button>
                                        </h2>
                                        <div id="flush-collapseOne" className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body accordion-body-custom wow fadeInUp" data-wow-delay=".2s">
                                                Resolución Técnica Administrativa Nro. 2881/2021...{" "}
                                                <a href={"#"} className=""
                                                    onClick={(e) =>
                                                    this.handleRedirectOpenPage(
                                                        e,
                                                        "https://atmapi.cochabamba.bo/restServerAtm/documents/preview/rta-2881-2021"
                                                    )
                                                    }
                                                    rel="noreferrer noopener"
                                                >
                                                    Continuar Leyendo
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingDateNotificacionMU">
                                            <button className="accordion-button collapsed accordion-custom-button text-white"
                                                type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo"
                                                aria-expanded="false" aria-controls="flush-collapseTwo"
                                            >
                                                <div className="avatar-btn">
                                                    <img src={Config[2].url + '/static/img/offcanvas/atm_white.png'} alt="atm icon blanco"  width='18' height='18' />
                                                </div>
                                                Fechas de Notificación
                                            </button>
                                        </h2>
                                        <div id="flush-collapseTwo" className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingDateNotificacionMU" data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body accordion-body-custom wow fadeInUp" data-wow-delay=".2s">
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }
}

export default HeaderCarroucel;