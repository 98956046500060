import React, { useEffect } from 'react';
import CreateCertification from './servicioPublico/CreateCertification'

import Links from '../../data/link';
import TitlePage from './../../components/utils/TitlePage'  
import Texto from '../../data/es'

const CertificacionTransportePublico = (props) => {

    useEffect(() => {
    }, []);

    const breadcrumbs = [
        {
            title: Links[0].title,
            url: Links[0].url
        },
        {
            title: Links[38].title,
            url: Links[38].url
        },
        {
            title: 'CTSP',
            url: '#'
        }
    ];

    return (
        <section className="items-grid section mt-5">
            <div className="container mt-4">
                <TitlePage titlePage={Texto.solicitud_certificado_transporte_servicio_publico} breadcrumbs={breadcrumbs} position={'left'} />

                <CreateCertification />
            </div>
        </section>
    )
}

export default CertificacionTransportePublico