import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TitleHeaderPage from "../Utils/TitleHeaderPage";
import AuthService from '../../../components/Usuario/AuthService';
import Fetch from '../../../components/utils/Fetch';
import Links from '../../../data/link';
import Texto from '../../../data/es';
//import '../../../components/style/parsley.css';
//  import $ from 'jquery';
// import 'parsleyjs';

const Login = () => {
    const Auth = new AuthService();
    const fetch = new Fetch();
    fetch.setToast(toast);
    const history = useHistory();

    const [email, setEmail] = useState("");
    const [contrasena, setContrasena] = useState("");

    const refInputPassword = useRef(null);
    const id_form = "formLogin";
    const urlVolver = "/";

    useEffect(() => {
        window.scrollTo(0, 0);
        if (Auth.loggedIn()) {
            history.replace(Links[0].url);
        }
        window.jQuery("#" + id_form).parsley();
    }, [Auth]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = window.jQuery("#" + id_form);
        form.parsley().validate();
		var ul_error_dir = window.jQuery("input[name='usuario[password]']").parent().parent().find('ul');
        window.jQuery("input[name='usuario[password]']").parent().parent().find('ul').remove();
        window.jQuery("input[name='usuario[password]']").parent('div').parent('div').append(ul_error_dir);

        if (form.parsley().isValid()) {
            const formData = new FormData(event.target);
            // const target = event.target;
            //window.disableButton(target.querySelector("input[type=submit]"));
            Auth.login(formData).then(dataJson => {
				console.log('auth - login');
                if (dataJson.status) {
                    const browserInfo = window.getBrowserInfo();
                    const historyForm = new FormData();
                    historyForm.append('history_login[ip]', window.myIP());
                    historyForm.append('history_login[country]', '');
                    historyForm.append('history_login[navegador]', browserInfo.browser);
                    historyForm.append('history_login[device]', `${browserInfo.device}_${browserInfo.os}`);

                    fetch.fetchPost(historyForm, 'api/history-login/create').then(historyDataJson => {
                        if (historyDataJson && historyDataJson.status) {
                            console.log(historyDataJson.message);
                        }
                    });

                    toast.success(`${dataJson.message}, ${Texto.espere_redireccionamos_pagina}`, { autoClose: 5000 });

                    setTimeout(() => {
                        window.location.href = '/';
                    }, 3000);
                } else {
                    // window.enableButton(target.querySelector("input[type=submit]"));
                    toast.warn(dataJson.message, { autoClose: 5000 });
                }
            }).catch(error => {
                //window.enableButton(target.querySelector("input[type=submit]"));
                // toast.error(getErrorMessage(error), { autoClose: 5000 });
				toast.error(error.message);
            });
        } else {
            toast.warn(Texto.campos_obligatorios, { autoClose: 5000 });
        }
    };

    const handleOpenPassword = (event) => {
        event.preventDefault();
        const input = refInputPassword.current;
        const icon = input.nextSibling.firstElementChild.firstElementChild;
        if (input.type === "text") {
            input.type = "password";
            icon.classList.add("fa-eye");
            icon.classList.remove("fa-eye-slash");
        } else {
            input.type = "text";
            icon.classList.add("fa-eye-slash");
            icon.classList.remove("fa-eye");
        }
    };

    return (
        <div className="section mt-5">
            <div className="container mt-4">
                <div className="row">
                    <div className="col-md-8 col-sm-12 col-lg-5 mx-auto d-block">
                        <div className="card card-minw-25">
                            <div className="card-body">
                                <TitleHeaderPage
                                    titlePage={Texto.login}
                                    description={Texto.login_description}
                                    visible={false}
                                />
                                <form id={id_form} onSubmit={handleSubmit}>
                                    <div className="form-group mb-3">
                                        <label htmlFor="email">(*) Correo Electrónico:</label>
                                        <input
                                            type="email"
                                            name="usuario[username]"
                                            id="usuario[username]"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                            className="form-control  form-control-lg"
                                            data-parsley-required-message="El correo electrónico es requerido"
                                            data-parsley-type="email"
                                            data-parsley-type-message="El correo electrónico no es válido"
											autoComplete="one-time-code"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="contrasena">(*) Contraseña:</label>
                                        <div className="input-group">
                                            <input
                                                type="password"
                                                name="usuario[password]"
                                                id="usuario[password]"
                                                value={contrasena}
                                                onChange={(e) => setContrasena(e.target.value)}
                                                ref={refInputPassword}
                                                required
                                                className="form-control  form-control-lg"
                                                data-parsley-required-message="La contraseña es requerida"
                                                data-parsley-length="[6,16]"
                                                data-parsley-length-message="La contraseña debe tener entre 8 y 16 caracteres"
												autoComplete="new-password"
                                            />
                                            <div className="input-group-append">
                                                <button className="btn-icono btn-secondary btn-lg" type="button" onClick={handleOpenPassword}>
                                                    <i className='fa fa-eye'></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12 mb-2">
                                            <Link to={Links[126].url} className="btn-link-cyan">
                                                {Texto.reset_password}
                                            </Link>
                                        </div>
                                        <div className="col-md-12 mb-2">
                                            ¿Aún no eres usuario de SIMAT? &nbsp;
                                            <Link to={Links[127].url} className="btn-link-cyan">
                                                {Texto.register_question}
                                            </Link>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 mx-auto border-radius-035 bg-gray-5 p-3 text-justify">
                                            Al continuar, te permitirá realizar trámites tributarios (vehículos, inmuebles y actividades económicas) en la Dirección de Recaudaciones, perteneciente al Gobierno Autónomo Municipal de Cochabamba.
                                        </div>
                                    </div>
                                    <br />
                                    <div className="d-flex justify-content-between">
                                        <Link to={urlVolver} className="btn btn-fucsia">Volver</Link>
                                        <button type="submit" className="btn btn-violeta">Continuar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
