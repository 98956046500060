import Texto from "../../data/es";
import Constant from "../../data/constant";
import { Link } from "react-router-dom";

export default class Columns {
  constructor(userAuth) {
    this.userAuth = userAuth;
  }

  columns({
    handleOpenEditClick,
    handleAprobarDatosClick,
    handleReaperturarClick,
    handleChangeTipoVMClick,
    handlePreviewClick,
    handlePreviewCertificateClick,
    handleGeneratePreliquidacionClick,
    handleDeleteClick,
    handleOpenImtoClick,
  }) {
    return [
      {
        name: Texto.contribuyente,
        sortable: true,
        grow: 1,
        maxWidth: "130px",
        cell: (row) => (
          <div>
            <span>
              {row.contribuyente_natural &&
              window.stringToBoolean(row.contribuyente_natural)
                ? "Natural"
                : "Juridico"}
            </span>
          </div>
        ),
      },
      {
        name: Texto.vendedor,
        sortable: true,
        maxWidth: "350px",
        cell: (row) => (
          <div>
            <span>
              {row.contribuyente_natural &&
              window.stringToBoolean(row.contribuyente_natural)
                ? JSON.parse(row.vendedores).nombre
                : row.vendedores && JSON.parse(row.vendedores).juridico
                ? JSON.parse(row.vendedores).juridico.razon_social
                : "".razon_social}
            </span>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        hide: "md",
      },
      {
        name: Texto.numero_orden,
        selector: "numero",
        sortable: true,
        maxWidth: "120px",
      },
      {
        name: Texto.nro_inmueble,
        selector: "numero_ruat",
        sortable: true,
        hide: "md",
        maxWidth: "120px",
      },
      {
        name: Texto.estado,
        center: true,
        sortable: true,
        maxWidth: "80px",
        minWidth: "50px",
        cell: (row) => (
          <div>
            {row.code_estado === Constant[0].estado.en_proceso ? (
              <span title={row.code_estado}>
                <i className="fa fa-cog" aria-hidden="true"></i>
              </span>
            ) : (
              ""
            )}
            {row.code_estado === Constant[0].estado.completado ? (
              <span title={row.code_estado}>
                <i className="far fa-file-alt" aria-hidden="true"></i>
              </span>
            ) : (
              ""
            )}
            {row.code_estado === Constant[0].estado.aprobado ? (
              <span title={row.code_estado}>
                <i className="fa fa-check" aria-hidden="true"></i>
              </span>
            ) : (
              ""
            )}
            {row.code_estado === Constant[0].estado.eliminado ? (
              <span title={row.code_estado}>
                <i className="fa fa-ban" aria-hidden="true"></i>
              </span>
            ) : (
              ""
            )}

            {row.code_estado ===
            Constant[0].estado.certificado_transferencia ? (
              <span title={row.code_estado}>
                <i className="fas fa-certificate" aria-hidden="true"></i>
              </span>
            ) : (
              ""
            )}

            {row.code_estado ===
            Constant[0].estado.registrar_transferencia_imto ? (
              <span title={row.code_estado}>
                <i className="fas fa-exchange-alt" aria-hidden="true"></i>
              </span>
            ) : (
              ""
            )}
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
      },
      {
        name: "Creado",
        selector: "created_at",
        sortable: true,
        hide: "md",
        maxWidth: "140px",
      },
      {
        name: "",
        sortable: true,
        cell: (row) => (
          <div className="text-sm-start text-md-end" style={{ width: "100px" }}>
            <span
              data-bs-toggle="dropdown"
              aria-expanded="false"
              className="pe-2 ps-2"
            >
              <i className="fas fa-ellipsis-v "></i>
            </span>
            <ul className="dropdown-menu">
              <li>
                {row.permissions.includes(Constant[0].permission.update) &&
                row.code_estado !== Constant[0].estado.aprobado &&
                row.code_estado !==
                  Constant[0].estado.certificado_transferencia &&
                row.code_estado !==
                  Constant[0].estado.registrar_transferencia_imto ? (
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => handleOpenEditClick(row)}
                    title={`Edición de la Visación de Minutas - ` + row.numero}
                  >
                    <i className="fas fa-pencil-alt me-2"></i> Editar
                  </Link>
                ) : (
                  ""
                )}
              </li>
              <li>
                {row.code_estado === Constant[0].estado.completado &&
                row.permissions.includes(Constant[0].permission.aprobar) &&
                row.code_estado !==
                  Constant[0].estado.certificado_transferencia ? (
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => handleAprobarDatosClick(row)}
                    title={`Aprobar los datos de la Visación de Minuta: ${row.numero}`}
                  >
                    <i className="fa fa-unlock-alt me-2" aria-hidden="true"></i>{" "}
                    Confirmar Datos
                  </Link>
                ) : (
                  ""
                )}
              </li>
              <li>
                {row.code_estado === Constant[0].estado.aprobado &&
                row.permissions.includes(Constant[0].permission.reaperturar) ? (
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => handleReaperturarClick(row)}
                    title={`Reaperturar la Visación de Minuta: ${row.numero}`}
                  >
                    <i className="fa fa-lock me-2" aria-hidden="true"></i>{" "}
                    Desbloquear
                  </Link>
                ) : (
                  ""
                )}
              </li>
              <li>
                {row.code_estado === Constant[0].estado.completado &&
                row.permissions.includes(Constant[0].permission.update_tipo_visacion_minuta) &&
                row.code_estado !==
                  Constant[0].estado.certificado_transferencia ? (
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => handleChangeTipoVMClick(row)}
                    title={`Cambiar Tipo de la Visación de Minuta: ${row.numero}`}
                  >
                    <i className="far fa-object-group me-2" aria-hidden="true"></i>{" "}
                    Cambiar Tipo Visación
                  </Link>
                ) : (
                  ""
                )}
              </li>
              <li>
                {row.code_estado === Constant[0].estado.aprobado ||
                row.code_estado === Constant[0].estado.completado ||
                row.code_estado ===
                  Constant[0].estado.certificado_transferencia ||
                row.code_estado ===
                  Constant[0].estado.registrar_transferencia_imto ? (
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => handlePreviewClick(row)}
                    title={`Visación de Minuta número: ${row.numero}`}
                  >
                    <i className="far fa-file-pdf me-2" aria-hidden="true"></i>{" "}
                    Ver DDJJ
                  </Link>
                ) : (
                  ""
                )}
              </li>
              <li>
                {row.code_estado === Constant[0].estado.aprobado &&
                row.fur_preliquidacion &&
                row.permissions.includes(
                  Constant[0].permission.certificacion_visacion
                ) ? (
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => handlePreviewCertificateClick(row)}
                    title={`Visación de Minuta número: ${row.numero}`}
                  >
                    <i className="fas fa-certificate me-2"></i> Certificado
                  </Link>
                ) : (
                  ""
                )}
              </li>
              <li>
                {row.code_estado === Constant[0].estado.aprobado &&
                row.permissions.includes(
                  Constant[0].permission.certificacion_visacion
                ) &&
                !row.fur_preliquidacion ? (
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => handleGeneratePreliquidacionClick(row)}
                    title={`Generar Fur de Pre-Liquidación`}
                  >
                    <i className="fas fa-key me-2"></i> Pre-Liquidación
                  </Link>
                ) : (
                  ""
                )}
              </li>
              <li>
                {row.permissions.includes(Constant[0].permission.update) &&
                row.code_estado !== Constant[0].estado.aprobado &&
                row.code_estado !== Constant[0].estado.eliminado &&
                row.code_estado !==
                  Constant[0].estado.certificado_transferencia &&
                row.code_estado !==
                  Constant[0].estado.registrar_transferencia_imto ? (
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => handleDeleteClick(row)}
                    title={`Eliminar Visación Minuta ${row.numero}`}
                  >
                    <i className="far fa-trash-alt me-2" aria-hidden="true"></i>{" "}
                    Eliminar
                  </Link>
                ) : (
                  ""
                )}
              </li>
              <li>
                {row.code_estado ===
                  Constant[0].estado.certificado_transferencia &&
                row.code_estado !==
                  Constant[0].estado.registrar_transferencia_imto &&
                (row.tipo_visacion_minuta_code === "DIVORCIO" ||
                  row.tipo_visacion_minuta_code === "COMPRA_VENTA_NORMAL" ||
                  row.tipo_visacion_minuta_code ===
                    "PROGRAMAS_CASA_SEGURA_MI_PLANO" ||
                  row.tipo_visacion_minuta_code === "ADJUDICACION_JUDICIAL") &&
                row.fur_preliquidacion &&
                row.permissions.includes(
                  Constant[0].permission.transferencia_imto
                ) ? (
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => handleOpenImtoClick(row)}
                    title={`Impuesto Municipal a la Transferencia Onerosa: ${row.numero}`}
                  >
                    <i className="fas fa-exchange-alt"></i> Transf. Imto
                  </Link>
                ) : (
                  ""
                )}
              </li>
            </ul>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];
  }
}
