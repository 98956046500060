import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useHistory } from "react-router-dom";
import FormRequisitos from "./FormRequisitos";
import FormRestriction from "./FormRestriction";
import FormVistaPrevia from "./FormVistaPrevia";
import Fetch from "../../components/utils/Fetch";
import AuthService from "../../components/Usuario/AuthService";
import Links from "../../data/link";
import TitlePage from "../../components/utils/TitlePage";
import Texto from "../../data/es";
import FormOperador from "./FormOperador";
import { formProviders } from "../../components/utils/FormDataInmueble";

const Edit = (props) => {
  const { form, token, numero } = useParams();

  const fetch = new Fetch();
  const auth = new AuthService();
  fetch.setToast(toast);
  const history = useHistory();

  const [dataInmueble, setDataInmueble] = useState(undefined);
  const [dataAuthorization, setDataAuthorization] = useState({});
  const [dataProvider, setDataProvider] = useState(undefined);
  const [dataRequisito, setDataRequisito] = useState(undefined);
  const [dataSiteRestriction, setDataSiteRestriction] = useState(undefined);
  const [showProvider, setShowProvider] = useState(false);
  const [showRequisito, setShowRequisito] = useState(false);
  const [showRestricction, setShowRestricction] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [searchData, setSearchData] = useState(false);

  if (!auth.loggedIn()) history.replace(Links[4].url);

  useEffect(() => {
    debugger;
    if (auth.loggedIn()) {
      configureData(form, token, numero);
    } else window.location.href = Links[4].url;
  }, []);

  const configureData = async (form, token, numero) => {
    const authorization = await loadAuthorization(token);
    debugger;
    loadProviders(form, token, numero, authorization);
    loadRequirements(form, token, numero, authorization);
    loadDataRestriction(form, token, numero, authorization);
    loadPreview(form, token, numero, authorization);
  };

  const loadProviders = async (form, token, numero, authorization) => {
    if (form === "providers") {
      setSearchData(true);
      //const authorization = await loadAuthorization(token);
      const inmueble = await loadDataInmueble(
        authorization.inmueble,
        authorization
      );
      const response = await fetch.fetchGet(
        `api/saat/provider-autorization/by-token-and-number/${token}/${numero}`
      );
      debugger;
      setDataInmueble(inmueble);
      setDataAuthorization(authorization);

      if (response && response.status)
        setDataProvider(response.data.SaatOperador);
      else setDataProvider(undefined);
      setShowProvider(true);
      setShowRestricction(false);
      setShowRequisito(false);
      setShowPreview(false);
      setSearchData(false);
    }
  };

  const loadRequirements = async (form, token, numero, authorization) => {
    debugger;
    if (form === "requisitos") {
      setSearchData(true);
      //const authorization = await loadAuthorization(token);
      const inmueble = await loadDataInmueble(
        authorization.inmueble,
        authorization
      );

      if (inmueble && authorization) {
        setDataInmueble(inmueble);
        setDataAuthorization(authorization);

        const response = await fetch.fetchGet(
          `api/saat/requisito-autorizacion/by-token-and-number/${token}/${numero}`
        );
        debugger;
        if (
          response &&
          response.status &&
          response.data.SaatRequisito &&
          response.data.SaatRequisito.length > 0
        ) {
          setDataRequisito(response.data.SaatRequisito);
          setShowRequisito(true);
        } else {
          setDataRequisito(undefined);
          setShowRequisito(true);
        }

        setShowProvider(false);
        setShowRestricction(false);
        setShowPreview(false);
        setSearchData(false);
      }
    }
  };

  const loadAuthorization = async (token) => {
    debugger;
    const response = await fetch.fetchGet(
      `api/saat-autorizacion/find/${token}`
    );
    if (response && response.status) {
      return response.data.SaatAutorizacion;
    }
    return undefined;
  };

  const loadDataInmueble = async (inmueble, autorization) => {
    const respInmueble = await fetch.axiosAsyncGet(
      `api-ruat/inmuebles/nro-inmueble/${inmueble.numero_ruat}`
    );
    debugger;
    if (Boolean(respInmueble) && respInmueble.status) {
      const propiedades = respInmueble.data.resInmueble.propiedades;
      //const category
      const category = {
        id: autorization.category.id,
        name: autorization.category.name,
      };

      const tipoTramite = {
        id: parseInt(autorization.tipoTramite.id),
        name: autorization.tipoTramite.name,
        codcat: autorization.category.code,
        codtiptramite: autorization.tipoTramite.code,
        public: autorization.publico,
      };
      const form = new FormData();
      form.append(
        "propiedades[codigoPropiedad]",
        propiedades[0].codigoPropiedad
      );
      form.append("module","saat");   //para definir el modulo 
      const resDeudas = await fetch.fetchPost(
        form,
        `api-ruat/inmuebles/deudas`,
        undefined
      );
      //debugger
      //resDeudas.data.gestionesDeuda = []; //para evitar la deuda
      if (resDeudas && resDeudas.status && resDeudas.data.gestionesDeuda <= 0) {
        debugger;

        propiedades[0].codigoCatastral =
          respInmueble.data.resInmueble.codigoCatastral;
        propiedades[0].codigoInmueble =
          respInmueble.data.resInmueble.codigoInmueble;
        propiedades[0].numeroInmueble =
          respInmueble.data.resInmueble.numeroInmueble;
        propiedades[0].claseInmueble =
          respInmueble.data.resInmueble.claseInmueble;
        propiedades[0].category = category;
        propiedades[0].tipoTramite = tipoTramite;
        propiedades[0].dataTecnico = respInmueble.data.resTecnico;
        propiedades[0].gestionesDeuda = resDeudas.data.gestionesDeuda;

        delete propiedades[0].copropietarios;
        return propiedades[0];
      } else {
        debugger;
        let continueOperation = false;
        if (
          autorization.fecha_inicio_tramite &&
          autorization.tipoTramite.code !== "NUEVO"
        ) {
          const yearInitTramite = window
            .moment(autorization.fecha_inicio_tramite)
            .format("YYYY");
          /*
          resDeudas.data.gestionesDeuda[1] = {
            baseImponible: "1",
            cantidadDias: "0",
            codigoContribuyente: "0A025603091B0B031502545A55",
            concepto: "1ES",
            fechaLiquidacion: "31/08/2023",
            gestion: "2020",
            monto: "1",
            montoAcumulado: "3531",
            montoImpuesto: "3531",
            tasaInteres: "0",
          };
          resDeudas.data.gestionesDeuda[2] = {
            baseImponible: "2",
            cantidadDias: "0",
            codigoContribuyente: "0A025603091B0B031502545A55",
            concepto: "1ES",
            fechaLiquidacion: "31/08/2023",
            gestion: "2015",
            monto: "1",
            montoAcumulado: "2",
            montoImpuesto: "2",
            tasaInteres: "0",
          };*/

          let yearMin = Math.min(
            ...resDeudas.data.gestionesDeuda.map((item) => item.gestion)
          );

          console.log(
            "verificando el paso y activacion de archivos",
            parseInt(yearInitTramite, 10),
            parseInt(yearMin, 10)
          );

          /*if (parseInt(yearInitTramite, 10) >= parseInt(yearMin, 10) ) {
            propiedades[0].codigoCatastral =
              respInmueble.data.resInmueble.codigoCatastral;
            propiedades[0].codigoInmueble =
              respInmueble.data.resInmueble.codigoInmueble;
            propiedades[0].numeroInmueble =
              respInmueble.data.resInmueble.numeroInmueble;
            propiedades[0].claseInmueble =
              respInmueble.data.resInmueble.claseInmueble;
            propiedades[0].category = category;
            propiedades[0].tipoTramite = tipoTramite;
            propiedades[0].dataTecnico = respInmueble.data.resTecnico;
            propiedades[0].gestionesDeuda = resDeudas.data.gestionesDeuda;

            delete propiedades[0].copropietarios;
            continueOperation = true;
            return propiedades[0];
          } else {
            continueOperation = false;
          }*/

          propiedades[0].codigoCatastral =
            respInmueble.data.resInmueble.codigoCatastral;
          propiedades[0].codigoInmueble =
            respInmueble.data.resInmueble.codigoInmueble;
          propiedades[0].numeroInmueble =
            respInmueble.data.resInmueble.numeroInmueble;
          propiedades[0].claseInmueble =
            respInmueble.data.resInmueble.claseInmueble;
          propiedades[0].category = category;
          propiedades[0].tipoTramite = tipoTramite;
          propiedades[0].dataTecnico = respInmueble.data.resTecnico;
          propiedades[0].gestionesDeuda = resDeudas.data.gestionesDeuda;

          delete propiedades[0].copropietarios;
          //continueOperation = true;
          return propiedades[0];
        }
        debugger;
        /*if (resDeudas && !continueOperation) {
          let deudasMessage = "";
          const deudasInmueble = resDeudas.data.gestionesDeuda.map(
            (deuda) => {
              return deuda.gestion;
            }
          );
          if (deudasInmueble.length > 9) {
            deudasMessage =
              deudasInmueble[0] +
              "," +
              deudasInmueble[1] +
              "," +
              deudasInmueble[2] +
              ", ... ," +
              deudasInmueble[deudasInmueble.length - 3] +
              "," +
              deudasInmueble[deudasInmueble.length - 2] +
              "," +
              deudasInmueble[deudasInmueble.length - 1];
          } else deudasMessage = deudasInmueble.join(",");


        }
        setSearchData(false);*/
      }
    }
  };

  const loadDataRestriction = async (form, token, numero, authorization) => {
    debugger;
    if (form === "restriction") {
      setSearchData(true);
      const inmueble = await loadDataInmueble(
        authorization.inmueble,
        authorization
      );

      const listSiteRestriction = {};
      const respRestricciones = await fetch.fetchGet(
        `api/saat/site-restriction/by-type-and-token-authorization/RESTRICCIONES/${authorization.token}`
      );

      if (respRestricciones && respRestricciones.status) {
        listSiteRestriction.restriction =
          respRestricciones.data.SaatEmplazamientoRestriccion;
      }

      const respEmplazamientos = await fetch.fetchGet(
        `api/saat/site-restriction/by-type-and-token-authorization/EMPLAZAMIENTO/${authorization.token}`
      );

      if (respEmplazamientos && respEmplazamientos.status) {
        listSiteRestriction.emplazamiento =
          respEmplazamientos.data.SaatEmplazamientoRestriccion;
      }

      if (
        listSiteRestriction &&
        (listSiteRestriction.hasOwnProperty("restriction") ||
          listSiteRestriction.hasOwnProperty("emplazamiento"))
      ) {
        setDataInmueble(inmueble);
        setDataSiteRestriction(listSiteRestriction);
        setDataAuthorization(authorization);
        setShowProvider(false);
        setShowRestricction(true);
        setShowRequisito(false);
        setShowPreview(false);
        setSearchData(false);
      }
    }
  };

  const loadPreview = async (form, token, numero, authorization) => {
    if (form === "preview") {
      setSearchData(true);
      const inmueble = await loadDataInmueble(
        authorization.inmueble,
        authorization
      );
      setDataInmueble(inmueble);
      setDataAuthorization(authorization);
      setShowProvider(false);
      setShowRestricction(false);
      setShowRequisito(false);
      setShowPreview(true);
      setSearchData(false);
    }
  };

  const handleSubmitEditForm = (event, dataForm) => {
    debugger;
    console.log("propiesdass ", props);
    event.preventDefault();
    window
      .jQuery("#" + event.target.getAttribute("id"))
      .parsley()
      .validate();

    let formHtml = event.target; //event.target.getAttribute('id');
    const form = new FormData(event.target);
    const target = event.target;

    if (
      window
        .jQuery("#" + formHtml.getAttribute("id"))
        .parsley()
        .isValid()
    ) {
      const text =
        formHtml.getAttribute("id") === "formDataSell"
          ? "Para continuar con la siguiente sección, el Contribuyente debe tener su PMC actualizado"
          : "Esta Seguro de Continuar.";
      window.createBootbox(text, async function (result) {
        if (result === true) {
          switch (formHtml.getAttribute("id")) {
            case "formDataOperator":
              await submitFormToOperador(form, target, dataForm);
              break;
            case "formDataRequisitos":
              await submitFormRequisitos(form, target, dataForm);
              break;
            case "formDataRestriction":
              await submitFormRestriction(form, target, dataForm);
              break;
            default:
              break;
          }
        }
      });
    } else {
      toast.warn("El formulario tiene valores obligatorios", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const submitFormToOperador = async (formData, target, dataForm) => {
    debugger;
    formData.append("saat_autorization[token]", dataAuthorization.token);
    formData.delete("saat_autorization[pmc_search]");
    formProviders(formData, dataForm);

    formData.delete("datos_juridicos[id_tipo_documento]");
    formData.delete("representante_legal[id_tipo_documento]");
    if (dataForm.apoderado.tipoDocumentoCode === "CI")
      formData.append("representante_legal[id_tipo_documento]", 1);

    if (dataForm.apoderado.tipoDocumentoCode === "CE")
      formData.append("representante_legal[id_tipo_documento]", 2);

    const titularPropietario = await fetch.fetchPost(
      formData,
      "api/saat/operador-crear",
      target
    );
    if (titularPropietario && titularPropietario.status) {
      window.redirect(Links[108].url);
      toast.success(titularPropietario.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const submitFormRequisitos = async (formData, target, dataForm) => {
    debugger;
    formData.append("saat_autorization[token]", dataAuthorization.token);
    //preguntar si esta completo o no?
    let message = undefined;
    if (!dataRequisito) {
      console.log("registro de data requisito.... ");
      const resqRequisitos = await fetch.fetchPost(
        formData,
        "api/saat/requisito-autorizacion",
        target
      );
      if (resqRequisitos && resqRequisitos.status)
        message = resqRequisitos.message;
    } else {
      let contPost = 0;
      let formDataTemp = undefined;
      let resultFail = false;
      for (let form of formData.entries()) {
        debugger;
        const value = form[1];
        if (!form[0].includes("input_force_update")) {
          if (contPost === 0 || contPost === 1) {
            if (contPost % 2 === 0) formDataTemp = new FormData();

            if (formDataTemp) {
              if (contPost === 0) {
                if (
                  value.type &&
                  (value.type.includes("application/pdf") ||
                    /^(gif|jpe?g|png|pdf)$/i.test(value.type))
                )
                  formDataTemp.append("saat_cat_req_autorization_file", value);
                formDataTemp.append(
                  "saat_autorization[token]",
                  dataAuthorization.token
                );
              }

              if (contPost === 1) {
                formDataTemp.append("saat_cat_req_autorization[token]", value);
              }
            }
          }

          contPost++;
          if (contPost > 1) contPost = 0;
        }

        if (
          form[0].includes("input_force_update") &&
          parseInt(value, 10) === 1
        ) {
          const resqRequisitos = await fetch.fetchPost(
            formDataTemp,
            "api/saat/requisito-autorizacion/update",
            target
          );
          if (resqRequisitos && resqRequisitos.status) {
            contPost = 0;
          } else resultFail = true;
        }
      }

      if (!resultFail) message = "Operación realizada con exíto";
    }

    if (message) {
      window.redirect(Links[108].url);
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const submitFormRestriction = async (formData, target, dataForm) => {
    debugger;
    formData.append("saat_autorization[token]", dataAuthorization.token);

    dataForm.map((row, index) => {
      formData.append(`saat_location_restriction[${index}][id]`, row.id);
      formData.append(
        `saat_location_restriction[${index}][response]`,
        row.selected ? 1 : 0
      );
      return 0;
    });

    const resqRestricciones = await fetch.fetchPost(
      formData,
      "api/saat/restriccion-crear",
      target
    );
    if (resqRestricciones && resqRestricciones.status) {
      window.redirect(Links[108].url);
      toast.success(resqRestricciones.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const breadcrumbs = [
    {
      title: Links[0].title,
      url: Links[0].url,
    },
    {
      title: Links[108].title,
      url: Links[108].url,
    },
    {
      title: "Edición",
      url: "#",
    },
  ];

  return (
    <section className="items-grid section mt-5 mb-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={Texto.autorizacion_telecomunicaciones}
          breadcrumbs={breadcrumbs}
          position={"left"}
        />

        {searchData ? (
          <div className="row">
            <div className="col-12 mb-1">
              <img
                className="rounded mx-auto d-block"
                alt="pulse-loading"
                src={"/static/img/pulse_200.gif"}
                width="70"
              />
            </div>
          </div>
        ) : (
          ""
        )}

        {showProvider ? (
          <FormOperador
            dataInmueble={dataInmueble}
            dataPropietario={
              dataInmueble ? dataInmueble.propietario : undefined
            }
            dataOperadorDb={dataProvider}
            fetch={fetch}
            onSubmitForm={handleSubmitEditForm}
          />
        ) : (
          ""
        )}

        {showRequisito ? (
          <FormRequisitos
            dataInmueble={dataInmueble}
            dataPropietario={dataInmueble.propietario}
            dataRequisitoDb={dataRequisito}
            fetch={fetch}
            onSubmitForm={handleSubmitEditForm}
          />
        ) : (
          ""
        )}

        {showRestricction ? (
          <FormRestriction
            dataInmueble={dataInmueble}
            dataPropietario={dataInmueble.propietario}
            fetch={fetch}
            dataSiteRestrictionDb={dataSiteRestriction}
            onSubmitForm={handleSubmitEditForm}
          />
        ) : (
          ""
        )}

        {showPreview ? (
          <FormVistaPrevia
            dataInmueble={dataInmueble}
            dataPropietario={dataInmueble.propietario}
            dataAuthorizationDb={dataAuthorization}
            fetch={fetch}
          />
        ) : (
          ""
        )}
      </div>

      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </section>
  );
};

export default Edit;
