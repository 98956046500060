import React, { Component } from "react";

import Links from "../../../data/link";
import Fetch from "../../utils/Fetch";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ValidatePlaca from "./ValidatePlaca";

var _fetch = null;
class ModalPlaca extends Component {
  constructor(props, context) {
    super(props, context);
    this.idModalPlaca = "modalPlacaFull";
    this.formModalPlaca = "formModalPlaca";

    this.handleVoidEnter = this.handleVoidEnter.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);

    this.typePlaca = this.props.typePlaca;

    _fetch = new Fetch();
    _fetch.setToast(toast);
  }

  handleCancelClick(event) {
    event.preventDefault();
    debugger
    const urlAdmin = window.getParameterByName("call");
    if (urlAdmin !== "") window.location.href = Links[64].url;
    else window.location.href = Links[112].url;
  }

  handleVoidEnter(e) {
    e.preventDefault();
    this.validateForm();
  }

  validateForm = () => {
    window
      .jQuery("#" + this.formModalPlaca)
      .parsley()
      .validate();
    var li_error = window.jQuery("#inputModalPlaca").siblings("ul");
    window.jQuery("#inputModalPlaca").siblings("ul").remove();
    window
      .jQuery("#inputModalPlaca")
      .parent("div")
      .parent("div")
      .append(li_error);
  };

  render() {
    return (
      <div
        className="modal fade modal-custom-atm"
        id={this.idModalPlaca}
        tabIndex="-1"
        aria-labelledby="modalPlacaFullTitle"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
        style={{ overflowY: "auto" }}
      >
        <div
          className="modal-dialog modal-md"
          style={{ boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.75)" }}
        >
          <div className="modal-content">
            <div className="modal-header text-butterfly-bush justify-content-center">
              <h4
                className="modal-title "
                id="exampleModalLongTitle"
                style={{ fontWeight: 600 }}
              >
                VALIDACIÓN
              </h4>
            </div>
            <div className="modal-body">
              <form
                className="contact__form"
                style={{ margin: "0.5rem" }}
                onSubmit={this.handleVoidEnter}
                id={this.formModalPlaca}
              >
                <ValidatePlaca
                  handleCancelClick={this.handleCancelClick}
                  fetch={_fetch}
                  validateForm={this.validateForm}
                  typePlaca={this.typePlaca}
                  closeModal={this.props.closeModal}
                />
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalPlaca;
