import React, { Component } from 'react';

import Config from '../../data/config';
import Constant from '../../data/constant';
import Fetch from '../utils/Fetch';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AsyncSelect from 'react-select/async';
import DatePicker, {  } from 'react-datepicker';
import datepicker from "react-datepicker/dist/react-datepicker.css";

var _fetch = null
var _derecho_admision = "";
var _languaje = undefined
class FormSearchLicencia extends Component {

    constructor(props, context) {
        super(props, context);

        this.constant = Constant[0];
        this.handleInputChange = this.handleInputChange.bind(this);
        this.noOptionsMessage = this.noOptionsMessage.bind(this);
        this.handleDatePickerFinMapa = this.handleDatePickerFinMapa.bind(this);
        this.handleDatePickerInicioMapa = this.handleDatePickerInicioMapa.bind(this);
        this.handleSearchAdvanceOnchange = this.handleSearchAdvanceOnchange.bind(this)
        this.handleAllOnchange = this.handleAllOnchange.bind(this)

        this.state = {
            showAdvanceSearch: false,
            startDateInicioMapa: null,
            startDateInicioMapa: null,
            optionsComunas: undefined,
            optionsDistritos: undefined,
            optionsSubDistritos: undefined,
            optionsEstadosLicencia: undefined,
            derechoAdmision: 1
        };

        _fetch = new Fetch();
        _fetch.setToast(toast);

        this.optionsSelect = [];
        _derecho_admision = 1 //this.props.derecho_admision  //default value
        _languaje = this.props.languaje
    }

    componentDidMount() {
        window.jQuery(".css-2b097c-container").css('fontSize', '13px')
        window.jQuery(".css-2b097c-container").css('border', 'none')
        window.jQuery(".css-2b097c-container").css('padding', '0rem 0rem')

        var self = this

        const comunas = _fetch.fetchGet(`api/actividad-economica/get-all-comunas`);
        comunas.then(res => {
            if (res !== undefined && res.status === true) {
                const listItems = res.Comuna.map((item, index) => {
                    return <option key={index} value={item.comuna} >{item.comuna}</option>
                });
                self.setState({
                    optionsComunas: listItems
                })
            }
        })

        const distritos = _fetch.fetchGet(`api/actividad-economica/get-all-distritos`);
        distritos.then(res => {
            if (res !== undefined && res.status === true) {
                const listItems = res.Distrito.map((item, index) => {
                    return <option key={index} value={item.distrito} >{item.distrito}</option>
                });
                self.setState({
                    optionsDistritos: listItems
                })
            }
        })

        const sub_distritos = _fetch.fetchGet(`api/actividad-economica/get-all-subdistritos`);
        sub_distritos.then(res => {
            if (res !== undefined && res.status === true) {
                const listItems = res.SubDistrito.map((item, index) => {
                    return <option key={index} value={item.sub_distrito} >{item.sub_distrito}</option>
                });
                self.setState({
                    optionsSubDistritos: listItems
                })
            }
        })

        const estados_licencia = _fetch.fetchGet(`api/estado/get-of-licencias`);
        estados_licencia.then(res => {
            if (res !== undefined && res.status === true) {
                const listItems = res.Estado.map((item, index) => {
                    return <option key={index} value={item.id} >{item.name}</option>
                });
                self.setState({
                    optionsEstadosLicencia: listItems
                })
            }
        })
    }

    handleDatePickerInicioMapa(date) {
        this.setState({
            startDateInicioMapa: date
        });
    }

    handleDatePickerFinMapa(date) {
        this.setState({
            startDateFinMapa: date
        });
    }

    async loadOption(inputValue) {
        if (inputValue.length >= 2) {
            var derecho_admision = document.getElementsByName("reporte[derecho_admicion]")[0].selectedOptions[0].value
            const response = await _fetch.axiosAsyncGet(`api/tipo-actividad-economica/search-by-name/${inputValue}/${derecho_admision}`);
            if (response.status === true)
                return response.data;
            else
                return [];
        } else
            return this.optionsSelect
    }

    handleInputChange = selectedOption => {
        if (document.getElementsByName("reporte[tipo_actividad_economica]").length > 0)
            document.getElementsByName("reporte[tipo_actividad_economica]")[0].remove()

        if (selectedOption !== null)
            window.create_input_hidden(selectedOption.value, 'reporte[tipo_actividad_economica]', 'formLicenciaMap');
    };

    noOptionsMessage(props) {
        let search = "";
        var derecho_admision = document.getElementsByName("reporte[derecho_admicion]")[0].selectedOptions[0].value
        if ((parseInt(derecho_admision) === parseInt(Constant[0].derecho_admision.permanente)))
            search = _languaje.ingresa_tipo_actividad_economica + " - " + _languaje.permanente
        else
            search = _languaje.ingresa_tipo_actividad_economica + " - " + _languaje.temporal
        
        if (props.inputValue === '') {
            return (search);
        }
        return (_languaje.criterio_busqueda_no_corresponde);
    }

    handleSearchAdvanceOnchange(event) {
        if (event.target.checked) {

            document.getElementsByName("reporte[checkBoxAll]")[0].checked = false

            this.setState({ showAdvanceSearch: true })
        } else {
            this.setState({ showAdvanceSearch: false })
        }
    }

    handleAllOnchange(event){
        
        if (event.target.checked) {
            window.jQuery("input[type='text']").val('');
            window.jQuery("select[name*=reporte]:not(select[name='reporte[per_page]']):not(select[name='reporte[type_search]']):not(select[name='reporte[type]']):not(select[name='reporte[derecho_admicion]'])").val('')

            document.getElementsByName("reporte[checkBoxAdvance]")[0].checked = false
            var event = new Event('onchange', {
                bubbles: true,
                cancelable: true,
            });

            document.getElementsByName("reporte[checkBoxAdvance]")[0].dispatchEvent(event);
            this.handleSearchAdvanceOnchange(event)
        }
    }

    render() {
        return (
            <form action="" className="contact__form needs-validation" name="formLicenciaMap" id="formLicenciaMap"
                method="post" noValidate onSubmit={this.props.onSubmitForm} style={{ width: '100%' }}>

                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                        <div className="input-group mb-3" style={{ marginBottom: '0rem !important' }}>
                            <div className="input-group-prepend">
                                <select className="form-control" name="reporte[type]" required data-parsley-required="true" >
                                    <option key={0} value="NUM_ORDEN">Nro. Orden</option>
                                    <option key={1} value="CI">CI</option>
                                    <option key={2} value="NRO_ACT_ECO">Nro. Act. Eco.</option>
                                    {/*<option key={3} value="NRO_INMUEBLE">Nro. Inmueble</option>*/}
                                </select>
                            </div>
                            <input type="text" name="reporte[type_search]" id="reporte[type_search]" className="form-control" placeholder="*" aria-describedby="basic-addon1" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                        <label htmlFor="reporte[tipo_actividad_economica]">Actividad Económica</label>
                        <div className="input-group mb-3" style={{ marginBottom: '0rem !important' }}>
                            <div className="input-group-prepend">
                                <select className="form-control" name="reporte[derecho_admicion]" required data-parsley-required="true" >
                                    <option key={1} value="1">Perm.</option>
                                    <option key={0} value="2">Temp.</option>
                                </select>
                            </div>
                            <AsyncSelect
                                cacheOptions
                                loadOptions={this.loadOption}
                                defaultOptions
                                onChange={this.handleInputChange}
                                isClearable={true}
                                isSearchable
                                placeholder={_languaje.tipo_actividad_economica}
                                required
                                defaultValue={[this.optionsSelect[0]]}
                                noOptionsMessage={this.noOptionsMessage}
                                className="form-control"
                                //options={this.optionsSelect}
                            />
                        </div>
                    </div>
                </div>

                {this.state.showAdvanceSearch ?
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                            <div className="form-group">
                                <label htmlFor="reporte[contribuyente]">Contribuyente</label>
                                <select className="form-control" id="reporte[contribuyente]" name="reporte[contribuyente]"
                                    data-parsley-required="true" >
                                    <option defaultValue value='' >Seleccione Contribuyente</option>
                                    <option key={1} value={'1'} >Natural</option>
                                    <option key={2} value={'2'}>Juridica</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    : ""}

                {this.state.showAdvanceSearch ?
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                            <div className="form-group">
                                <label htmlFor="reporte[comuna]">Comuna</label>
                                <select className="form-control" id="reporte[comuna]" name="reporte[comuna]"
                                    data-parsley-required="true" >
                                    <option defaultValue value=''>Seleccione Comuna</option>
                                    {this.state.optionsComunas}
                                </select>
                            </div>
                        </div>
                    </div>
                    : ""}

                {this.state.showAdvanceSearch ?
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                            <div className="form-group">
                                <label htmlFor="reporte[distrito]">Distrito</label>
                                <select className="form-control" id="reporte[distrito]" name="reporte[distrito]"
                                    data-parsley-required="true" >
                                    <option defaultValue value=''>Seleccione Distrito</option>
                                    {this.state.optionsDistritos}
                                </select>
                            </div>
                        </div>
                    </div>
                    : ""}

                {this.state.showAdvanceSearch ?
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                            <div className="form-group">
                                <label htmlFor="reporte[sub_distrito]">Sub Distrito</label>
                                <select className="form-control" id="reporte[sub_distrito]" name="reporte[sub_distrito]"
                                    data-parsley-required="true" >
                                    <option defaultValue value=''>Seleccione Sub Distrito</option>
                                    {this.state.optionsSubDistritos}
                                </select>
                            </div>
                        </div>
                    </div>
                    : ""}

                {this.state.showAdvanceSearch ?
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                            <div className="form-group">
                                <label htmlFor="reporte[estado]">Estado</label>
                                <select className="form-control" id="reporte[estado]" name="reporte[estado]"
                                    data-parsley-required="true" >
                                    <option defaultValue value=''>Seleccione Estado</option>
                                    {this.state.optionsEstadosLicencia}
                                </select>
                            </div>
                        </div>
                    </div>
                    : ""}

                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                        <label htmlFor="reporte[fecha_inicio]">Inicio (DD/MM/YYYY)</label>
                        <DatePicker
                            locale="es"
                            dateFormat={Config[4].format}
                            selected={this.state.startDateInicioMapa}
                            onChange={this.handleDatePickerInicioMapa}
                            maxDate={new Date()}
                            className="form-control"
                            name="reporte[fecha_inicio]"
                            data-parsley-required="true"
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            required />
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                        <label htmlFor="reporte[fecha_fin]">Fin (DD/MM/YYYY)</label>
                        <DatePicker
                            locale="es"
                            dateFormat={Config[4].format}
                            selected={this.state.startDateFinMapa}
                            onChange={this.handleDatePickerFinMapa}
                            maxDate={new Date()}
                            className="form-control"
                            name="reporte[fecha_fin]"
                            data-parsley-required="true"
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            required />
                    </div>
                </div>

                <div className="row">

                    <div className="col-6 col-md-5 col-lg-5 form-group form-check" style={{ paddingLeft: "2.2rem" }}>
                        <div>
                            <input type="checkbox" className="form-check-input" id="checkBoxAll" name="reporte[checkBoxAll]"onChange={this.handleAllOnchange} />
                            <label className="form-check-label" htmlFor="reporte[checkBoxAll]">Todos</label>
                        </div>

                        <div>
                            <input type="checkbox" className="form-check-input" name="reporte[checkBoxAdvance]" id="checkBoxAdvance" onChange={this.handleSearchAdvanceOnchange} />
                            <label className="form-check-label" htmlFor="reporte[checkBoxAdvance]">Avanzado</label>
                        </div>

                        {/*<div>
                            <input type="checkbox" className="form-check-input" name="reporte[checkBoxEdificio]" id="checkBoxEdificio" />
                            <label className="form-check-label" htmlFor="reporte[checkBoxEdificio]">Edificios</label>
                        </div>*/}
                    </div>
                    <div className="col-6 col-md-7 col-lg-7 form-group">
                        <input name="Buscar" type="submit" className="button-style pull-right" value={this.props.buttonName} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-12" >
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <nav aria-label="pagination-licencia">
                                    <ul className="pagination pagination-sm " id="pagination-ul-licencia">
                                    </ul>
                                </nav>
                            </div>
                            <select className="form-control" name="reporte[per_page]" id="reporte[per_page]" >
                                <option defaultValue key={0} value='100' >100</option>
                                <option key={1} value={'200'} >200</option>
                                <option key={2} value={'300'} >300</option>
                                <option key={3} value={'400'} >400</option>
                            </select>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default FormSearchLicencia;