import React, { useState, useRef, useEffect } from "react";
import Config from "../../../data/config";
import Texto from "../../../data/es";
import TitlePage from "../../utils/TitlePage";
import Links from "../../../data/link";
import Fetch from "../../utils/Fetch";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalPdfBasic from "../../utils/ModalPdfBasic";

const Vehiculos = (props) => {
  const fetch = new Fetch();
  fetch.setToast(toast);

  const refModalPdfBasic = useRef();
  const titlePage = Texto.ras_vehiculos + " - Coactivos";
  const textSearch = "Seleccione la gestión a visualizar";

  const [optionsGestion, setOoptionsGestion] = useState(undefined);

  const [notificacionesList, setNotificacionesList] = useState("");
  const [totalList, setTotalList] = useState(0);
  const [resultSearch, setResultSearch] = useState("");
  const [searchData, setSearchData] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    findGestionPiest();
    getDataLiquidacion(
      "api/fiscalizacion/notification-by-code-department-and-type-notificacion/RAS-COACTIVO/MOVILIDAD_URBANA/VEHICULO/0"
    );
  }, []);

  const findGestionPiest = () => {
    const gestiones = fetch.fetchGet(`api/gestion/piets`);
    gestiones.then((res) => {
      debugger;
      if (res && res.status) {
        if (res.Gestion.length > 0) {
          //is ok
          const listItems = res.Gestion.map((item, index) => {
            return (
              <option key={index} value={item.id} code={item.name}>
                {item.name}
              </option>
            );
          });
          setOoptionsGestion(listItems);
        }
      }
    });
  };

  const getDataLiquidacion = (url, callback) => {
    fetch.fetchGet(url).then((dataJson) => {
      if (dataJson && dataJson.status) {
        if (Boolean(dataJson.Notificacion)) {
          fetch.toast.success(dataJson.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          let cont = 0;
          let notificaciones = dataJson.Notificacion.map((item, index) => {
            return (
              <div
                className={
                  "row pt-3 pb-2 " + (cont % 2 === 0 ? "row-purple" : "")
                }
                key={cont++}
                style={{ marginLeft: 0, marginRight: 0 }}
              >
                <div className="col-12 col-md-2 col-lg-2 text-center pr-0 pt-1 pb-2">
                  <i
                    className={
                      "fa fa-file-pdf-o fa-3x fa-icon-image d-block " +
                      (cont % 2 === 0 ? "" : "text-white")
                    }
                    title={item.title}
                  ></i>
                  <small className="feature-box__text text-center d-block pt-1">
                    {window
                      .moment(item.created_at)
                      .format("DD-MM-YYYY HH:mm:ss")}
                  </small>
                  {item.doc_name.indexOf("a parte") > 0 ? (
                    <strong className="feature-box__text text-center text-overflow">
                      {item.doc_name}
                    </strong>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-12 col-md-8 col-lg-8 pt-1 pb-2">
                  <p className="feature-box__text text-justify pb-0 mb-0 text-overflow">
                    <strong>Nombre:</strong> {item.title}
                  </p>
                  <p
                    className="feature-box__text text-justify pb-0 mb-0"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span className="d-inline-block">
                      <strong>Formato:</strong> {item.doc_content_type}{" "}
                    </span>
                    <span className="d-inline-block" style={{ width: "40%" }}>
                      <strong>Tamaño:</strong> {item.doc_size} KB
                    </span>
                  </p>
                  <p className="feature-box__text text-justify pb-0 mb-0">
                    <strong>Descripción:</strong> {item.description}
                  </p>
                </div>

                <div className="col-12 col-md-2 col-lg-2 text-center pt-1 pb-2">
                  <i
                    className={
                      "far fa-file-pdf fa-2x fa-icon-image pr-3 pointer text-fountain-blue"
                    }
                    onClick={(e) => handleOpenFile(e, item.doc_code)}
                    id={item.doc_id}
                  ></i>
                  <i
                    className={
                      "far fa-arrow-alt-circle-down fa-2x fa-icon-image pointer ms-3 text-fountain-blue"
                    }
                    onClick={(e) => handleDownladFile(e, item.doc_code)}
                    id={item.doc_id}
                  ></i>
                </div>
              </div>
            );
          });
          setNotificacionesList(notificaciones);
          if (
            dataJson.total_mixtas !== undefined &&
            dataJson.total_mixtas > 0
          ) {
            setTotalList(dataJson.total_mixtas);
          }
          if (callback !== undefined)
            callback(
              dataJson.Notificacion,
              dataJson.total_coincidencias_econtradas
            );
        } else {
          if (callback !== undefined) callback(undefined, 0);
          fetch.toast.warn(dataJson.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }

      if (!callback) setSearchData(false);
    });
  };

  const handleOnchangeGestion = (event) => {
    event.preventDefault();
    const selectInput = event.target;
    const code = selectInput.selectedOptions[0].getAttribute("code");

    getDataLiquidacion(
      `api/fiscalizacion/notification-by-code-department-and-type-notificacion/RAS-COACTIVO/MOVILIDAD_URBANA/VEHICULO/${code}`
    );
  };

  const handleOpenFile = (event, code) => {
    event.preventDefault();
    refModalPdfBasic.current.openModal(
      true,
      `documents/preview/${code}`,
      `documents/download/${code}`
    );
  };

  const handleDownladFile = (event, code) => {
    event.preventDefault();
    window.location.href = Config[0].url + `documents/download/${code}`;
  };

  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      title: Links[0].title,
      url: Links[0].url,
    },
    {
      title: Links[93].title,
      url: Links[93].url,
    },
    {
      title: Texto.vehiculos + " - Coactivos",
      url: "#",
    },
  ]);

  return (
    <section className="items-grid section mt-5">
      <div className="container mt-1">
        <TitlePage
          titlePage={titlePage}
          breadcrumbs={breadcrumbs}
          position={"left"}
          leftfull={false}
        />

        <div className="row">
          <div className="col-12 col-md-6 col-lg-8"></div>
          <div className="col-12 col-md-6 col-lg-4">
            <label htmlFor="gestion" className="form-label">
              {textSearch}
            </label>
            <select
              className="form-select "
              name="gestion"
              required
              data-parsley-required="true"
              onChange={handleOnchangeGestion}
            >
              <option value={0} code={0}>
                {"Todas las Gestiones"}
              </option>
              {optionsGestion}
            </select>
          </div>
        </div>

        {searchData ? (
          <div className="row mt-3">
            <div className="col-12 mb-1">
              <img
                className="rounded mx-auto d-block"
                alt="pulse-loading"
                src={"/static/img/pulse_200.gif"}
                width="70"
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row mb-2">
          <div className="col-12">{resultSearch}</div>
        </div>

        {notificacionesList}

        <div className="row">
          <ModalPdfBasic ref={refModalPdfBasic} isFullScreen={true} />
        </div>

        <ToastContainer
          enableMultiContainer
          containerId={"Z"}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <ToastContainer />
      </div>
    </section>
  );
};

export default Vehiculos;