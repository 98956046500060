import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AuthService from "../Usuario/AuthService";
import Links from "../../data/link";
import Config from "../../data/config";

const ValidateFur = (props) => {
  const { handleCancelClick, fetch, validateForm, nextPageCurrent } = props;

  const [inputFur, setInputFur] = useState("");
  const [message, setMessage] = useState("");
  const history = useHistory();
  const auth = new AuthService();

  if (!auth.loggedIn()) history.replace(Links[4].url);

  const handleFurOnChange = (event) => {
    event.preventDefault();
    const button = document.getElementById("btnVerifyFurSmallSubmit");

    if (event.currentTarget.value.length >= 3) {
      button.classList.remove("btn-disabled");
      setInputFur(event.currentTarget.value);
    } else {
      button.classList.add("btn-disabled");
      setInputFur("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      debugger
      event.preventDefault();
      eventInputFur();
    }
  }

  const handleFurClick = (event) => {
    event.preventDefault();
    debugger
    eventInputFur();
  };

  const eventInputFur = () => {
    validateForm();

    const button = document.getElementById("btnVerifyFurSmallSubmit");
    if(!button.classList.value.includes('btn-disabled')){
      if (
        window
          .jQuery(
            "#" + document.getElementById("formModalVisacion").getAttribute("id")
          )
          .parsley()
          .isValid()
      ) {
        fetch
          .fetchGet("api/cobros/fur-visacion-minutas/" + inputFur)
          .then((fur) => {
            if (fur && fur.status && fur.Fur) {
              const Fur = {
                fur: inputFur,
                derechoAdmision: fur.derecho_admision,
                estado: fur.Fur.estado,
                fechaPago: fur.Fur.fecha_pago,
                glosa: fur.Fur.glosa2,
              };
              nextPageCurrent(1, Fur);
            }
          });
      }
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12 padding-right-0 text-center mt-4 mb-4">
          <i
            className="far fa-file-alt"
            aria-hidden="true"
            style={{ fontSize: "8.5rem" }}
          ></i>
          <h4 className="text-center mt-4 text-butterfly-bush">
            Derecho de Admisión
          </h4>
        </div>
      </div>

      <div className="row">
        {message !== "" ? (
          <div className="col-12 padding-left-0 padding-right-0">
            <div
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              <strong>Atención!</strong> {message}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="row">
        <div className="col-12 padding-right-0">
          {"3 Intentos consecutivos."}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-12 col-lg-12">
          <div className="form-group">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text bg-butterfl-bush text-white"
                  id="basic-addon1"
                >
                  Nº FUR
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="FUR"
                aria-label="Fur"
                id="inputModalFur"
                aria-describedby="basic-addon1"
                onChange={handleFurOnChange}
                onKeyDown={handleKeyDown}
                data-parsley-required="true"
                data-parsley-maxlength={Config[9].furLongitud}
                maxLength={Config[9].furLongitud}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
      <div className="col-12 col-md-6 col-lg-6 mb-3  text-start ">
          <div className="col-12 search-btn button ">
            <input
              className="btn bg-maroon-flush btn-full-screen"
              name="submit"
              type="submit"
              value="Cancelar"
              onClick={handleCancelClick}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-6 mb-3 text-end">
          <div className="col-12 search-btn button ">
            <input
              className="btn btn-disabled btn-full-screen"
              name="submit"
              id="btnVerifyFurSmallSubmit"
              type="submit"
              value="Verificar"
              onClick={handleFurClick}
            />
          </div>
        </div>
       
      </div>
    </>
  );
};

export default ValidateFur;
