
import React from "react";
import PropTypes from "prop-types";

const DatosTecnico = (props) => {

  const { dataTechnical, actividadEconomicaRuat, Texto, periodo } = props;

  return (
    <>
      <span>
        <strong>{Texto.datos_tecnicos.toUpperCase()} </strong>
      </span>
      <hr className="mt-0 " />
      <p>
                <strong>{Texto.periodo}: </strong>
                {periodo}
            </p>
      <p>
        <strong>Alto: </strong>
        {dataTechnical && dataTechnical.alto}
      </p>
      <p>
        <strong>Ancho: </strong>
        {dataTechnical &&  dataTechnical.ancho}
      </p>
      <p>
        <strong>Total M2: </strong>
        {dataTechnical &&  dataTechnical.dimension}
      </p>

      <p>
        <strong>Dirección: </strong>
        {dataTechnical.AdUbicacions.length > 0 &&  dataTechnical.AdUbicacions[0].direccion}
      </p>  

       <p>
        <strong>Actividad: </strong>
        {actividadEconomicaRuat &&  actividadEconomicaRuat.nroActividad}
      </p>  

       <p>
        <strong>Tipo Actividad: </strong>
        {actividadEconomicaRuat &&  actividadEconomicaRuat.tipoActividad}
      </p> 
      
    </>
  );
}


DatosTecnico.prototype = {
  dataTechnical: PropTypes.object.isRequired,  
  // descripcion: PropTypes.string.isRequired,
  // clasificador:PropTypes.string,
  // categoria:PropTypes.string
};

export default DatosTecnico;
