import React, { useEffect, useState, useRef } from 'react';

const FormPropietario = (props) => {

    const { fetch, dataPropietario } = props

    const refSelectTipoDocumento = useRef()
    const refInputNroDocumento = useRef()
    const refInputNombre = useRef()
    const refInputPrimerApellido = useRef()
    const refInputSegundoApellido = useRef()

    const [optionsTipoDocumento, setOptionsTipoDocumento] = useState([])

    useEffect(() => {
        window.jQuery(function () { window.jQuery('.toogle-input').bootstrapToggle() });  //input[type=checkbox]

        findAllTipoDocumento()

        clearDataInputs()
        if (dataPropietario) {
            setTimeout(() => { fillDataInputs(dataPropietario) }, 100);
        }
    }, []);

    const findAllTipoDocumento = () => {
        const tipo_doc_res = fetch.fetchGet(`api/tipo-documentos`);
        tipo_doc_res.then(res => {
            if (res && res.status) {
                if (res.TipoDocumento !== null) {  //is ok
                    const listItems = res.TipoDocumento.map((item, index) => {
                        return <option key={index} value={item.id} code={item.code}>{item.name}</option>
                    });
                    setOptionsTipoDocumento(listItems)
                }
            }
        })
    }

    const fillDataInputs = (dataPropietario) => {
        refSelectTipoDocumento.current.value = dataPropietario.id_tipo_documento
        refInputNroDocumento.current.value = dataPropietario.numero_documento
        refInputNombre.current.value = dataPropietario.nombre
        refInputPrimerApellido.current.value = dataPropietario.apellido_paterno
        refInputSegundoApellido.current.value = dataPropietario.apellido_materno
    }

    const clearDataInputs = () => {
        refSelectTipoDocumento.current.value = ''
        refInputNroDocumento.current.value = ""
        refInputNombre.current.value = ""
        refInputPrimerApellido.current.value = ""
        refInputSegundoApellido.current.value = ""
    }

    return (
        <>
            <div className="row">
                <div className="col-12 mb-3">
                    <h4>Tercero Responsable</h4>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-4 col-lg-4 mb-3">
                    <label htmlFor="tercero_responsable[id_tipo_documento]"  className="form-label">Tipo Documento:</label>
                    <select className="form-select" name="tercero_responsable[id_tipo_documento]"
                        data-parsley-required="true" ref={refSelectTipoDocumento}>
                        <option defaultValue value='' >Seleccione Tipo Documento</option>
                        {optionsTipoDocumento}
                    </select>
                </div>

                <div className="col-12 col-md-4 col-lg-4 mb-3">
                    <label htmlFor="tercero_responsable[numero_documento]" className="form-label">Nro. Documento:</label>
                    <input name="tercero_responsable[numero_documento]" type="text" className="form-control" placeholder="Nro. de Documento"
                        ref={refInputNroDocumento}
                        data-parsley-required="true" />
                </div>
            </div>

            <div className="row">
                <div className="col-8 mb-3">
                    <label htmlFor="tercero_responsable[nombre]" className="form-label">Nombre:</label>
                    <input name="tercero_responsable[nombre]" type="text" className="form-control" placeholder="Nombre" ref={refInputNombre}
                        data-parsley-required="true" />
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-4 mb-3">
                    <label htmlFor="tercero_responsable[apellido_paterno]" className="form-label">Primer Apellido:</label>
                    <input name="tercero_responsable[apellido_paterno]" type="text" className="form-control" placeholder="Primer Apellido"
                        ref={refInputPrimerApellido}
                        data-parsley-required="true" />
                </div>

                <div className="col-12 col-md-4 mb-3">
                    <label htmlFor="tercero_responsable[apellido_materno]" className="form-label">Segundo  Apellido:</label>
                    <input name="tercero_responsable[apellido_materno]" type="text" className="form-control" placeholder="Segundo Apellido"
                        ref={refInputSegundoApellido}
                        data-parsley-required="true" />
                </div>
            </div>
        </>
    )
}

export default FormPropietario
