import React, { Component } from 'react';

import Links from '../../data/link';
import TitlePage from '../../components/utils/TitlePage';

class Cram extends Component {

    constructor(props) {
        super(props);
        this.titlePage = Links[15].title
    }

    componentDidMount() {

    }

    handleRedirectUrlClick(ev, url) {
        ev.preventDefault();
        window.alertBootBox("Estamos trabajando en ello...");
        //window.location.href = url;
    }

    hanldeSearchCram = async (event, type_search) => {
        event.preventDefault()
    };


    render() {
        const breadcrumbs = [
            {
                title: Links[0].title,
                url: Links[0].url
            },
            {
                title: Links[15].code,
                url: Links[15].url
            }
        ];

        return (
            <div id="services" className="paddingTop30" >

                {/* Breadcrumb Area Start */}
                <TitlePage titlePage={this.titlePage} breadcrumbs={breadcrumbs} position={'left'} leftfull={false} />
                {/* Breadcrumb Area End */}

                <div className="container features">
                    {/* Menu navegacion */}
                    <section className="panel-menu-info">
                        <div className="panel-menu-info-content">
                            <div className="row">
                                <div className="col-4 col-md-2 col-lg-2">
                                    <div className="single-contact-info pointer" onClick={e => this.hanldeSearchCram(e, 1)}>
                                        <i className="fa fa-cog" aria-hidden="true"></i>
                                        <p id="pPendiente">En Proceso - 0</p>
                                    </div>
                                </div>
                                <div className="col-4 col-md-2 col-lg-2">
                                    <div className="single-contact-info pointer" onClick={e => this.hanldeSearchCram(e, 2)}>
                                        <i className="fa fa-file-text-o" aria-hidden="true"></i>
                                        <p id="pActivo">Completado - 0</p>
                                    </div>
                                </div>
                                <div className="col-4 col-md-2 col-lg-2">
                                    <div className="single-contact-info pointer" onClick={e => this.hanldeSearchCram(e, 3)}>
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                        <p id="pBloqueado">Aprobado - 0</p>
                                    </div>
                                </div>
                                <div className="col-4 col-md-2 col-lg-2">

                                </div>
                                <div className="col-4 col-md-2 col-lg-2">
                                    <div className="single-contact-info pointer" onClick={e => this.handleRedirectUrlClick(e, Links[8].url)}>
                                        <i className="fa fa-user-o" aria-hidden="true"></i>
                                        <p>Mi Cuenta</p>
                                    </div>
                                </div>
                                <div className="col-4 col-md-2 col-lg-2">
                                    <div className="single-contact-info pointer" onClick={e => this.handleRedirectUrlClick(e, Links[16].url)}>
                                        <i className="fa fa-file-o" aria-hidden="true"></i>
                                        <p>Nueva CRAM</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Menu navegacion end*/}

                    <section>
                    Actualmente estamos diseñando las nuevas caracteristicas tecnologícas para el registro de CRAM.
                </section>
                </div>
            </div>

        );
    }
}

export default Cram;