import React from 'react';
import Texto from '../../../data/es'

const DetalleServicioPublico = (props) => {

    const { servicioPublico } = props

    let certificado = undefined
    let propietario = undefined
    let tipoServicio = undefined
    let terceroResponsable = undefined
    let clasificacionOrganizacionSindical = undefined
    let organizacionSindical = undefined
    let lineaServicioPublico = undefined
    if (servicioPublico) {
        certificado = servicioPublico.certificado
        propietario = certificado.propietario
        tipoServicio = servicioPublico.TipoServicio
        terceroResponsable = certificado.terceroResponsable
        clasificacionOrganizacionSindical = servicioPublico.clasificacionOrganizacionSindical
        organizacionSindical = servicioPublico.organizacionSindical
        lineaServicioPublico = servicioPublico.lineaServicioPublico
    }

    return (
        <div className="card widget widget-simple w-100" >
            <div className="card-body ">
                <div className="row ">
                    <div className="col-12 widget-header">
                        <h4 className="">{Texto.datos_propietario_tercero_responsable_poseedor}</h4>
                    </div>
                </div>

                <div className="widget-content">
                    <div className="widget-body">
                        <div className="row ">
                            <div className="col-sm-12 col-md-4 col-lg-4  padding-left-right-0 ">
                                <p className="text-left" ><strong >Documento Identidad Propietario: </strong><span>{propietario ? propietario.numero_documento + ' ' + propietario.expedido_en : ""}</span></p>
                            </div>
                        </div>

                        {terceroResponsable ?
                            <div className="row">
                                <div className="col-12 padding-left-right-0">
                                    <h5>Tercero Responsable</h5>
                                </div>
                            </div>
                            : ""
                        }

                        {terceroResponsable ?
                            <div className="row">
                                <div className="col-12 col-md-4 padding-left-right-0">
                                    <p className="text-left" ><strong >Tipo Documento: </strong><span>{terceroResponsable ? terceroResponsable.TipoDocumento.name : ""}</span></p>
                                </div>
                                <div className="col-12 col-md-4 padding-left-right-0">
                                    <p className="text-left" ><strong >Número Documento: </strong><span>{terceroResponsable ? terceroResponsable.numero_documento + ' ' + (terceroResponsable.expedido_en ? terceroResponsable.expedido_en : "") : ""}</span></p>
                                </div>
                                <div className="col-12 col-md-4 padding-left-right-0"></div>
                            </div>
                            : ""
                        }

                        {terceroResponsable ?
                            <div className="row">
                                <div className="col-12 col-md-4 padding-left-right-0">
                                    <p className="text-left" ><strong >Nombre: </strong><span>{terceroResponsable ? terceroResponsable.nombre : ""}</span></p>
                                </div>
                                <div className="col-12 col-md-4 padding-left-right-0">
                                    <p className="text-left" ><strong >Primer Apellido: </strong><span>{terceroResponsable ? terceroResponsable.apellido_paterno : ""}</span></p>
                                </div>
                                <div className="col-12 col-md-4 padding-left-right-0">
                                    <p className="text-left" ><strong >Segundo Apellido: </strong><span>{terceroResponsable ? terceroResponsable.apellido_materno : ""}</span></p>
                                </div>
                            </div>
                            : ""
                        }

                        <br />

                        <div className="row">
                            <div className="col-12  padding-left-right-0">
                                <h5>Servicio Público</h5>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left" ><strong >Tipo Servicio: </strong><span>{tipoServicio ? tipoServicio.name : ""}</span></p>
                            </div>

                            <div className="col-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left" ><strong >Se Encuentra Afiliado: </strong><span>{servicioPublico ? servicioPublico.esta_afiliado === "1" ? "Si" : "No" : ""}</span></p>
                            </div>

                            <div className="col-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left" ><strong >Tipo Organización: </strong><span>{clasificacionOrganizacionSindical ? clasificacionOrganizacionSindical.name : ""}</span></p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-8 col-lg-8 padding-left-right-0">
                                <p className="text-left" ><strong >Afiliado A: </strong><span>{organizacionSindical ? organizacionSindical.name : ""}</span></p>
                            </div>

                            <div className="col-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left" ><strong >Línea de Servicio Público: </strong><span>{lineaServicioPublico ? lineaServicioPublico.name : ""}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetalleServicioPublico