import React, { Component } from 'react';

import Links from '../../data/link';
import Config from '../../data/config';

class LastMembers extends Component {

    constructor(props, context) {
        super(props, context);

        this.handleDetailUser = this.handleDetailUser.bind()

        this.state = {
            liusers: undefined
        };
    }

    componentDidMount() {
        var self = this
        this.props.fetch.fetchGet(`api/administracion/get-last-members-administracion` ).then(dataJson => {
            if (dataJson && dataJson.status) {
                if (Boolean(dataJson.Usuario)) {
                    self.props.fetch.toast.success(dataJson.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });

                    const listItems = dataJson.Usuario.map((item, index) => {
                        const fullname = item.name + ' '+ item.apellido_paterno + ' ' + item.apellido_materno;
                        return <li key={index} >
                                <img src={item.thumbail ? item.thumbail : Config[2].url + '/static/img/avatar_default.avif'} alt={item.nombre} width="60" height="60" />
                                    <a className="users-list-name" href="#" onClick={this.handleDetailUser}>{fullname.substring(0, 20)}</a>
                                    <small className="users-list-date"> Today</small>
                                </li>
                    });
    
                    self.setState({ liusers: listItems })
                } else {
                    self.props.fetch.toast.warn(dataJson.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            }
        })
    }

    handleDetailUser(){
        alert("Estamos Trabajando")
    }

    render() {
        return (
            <>
                <div className="col-md-6">

                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Últimos Miembros</h3>
                        </div>

                        <div className="card-body p-0">
                            <ul className="users-list clearfix">
                                {this.state.liusers}
                            </ul>
                        </div>

                        <div className="card-footer text-center">
                            <a href={Links[12].url} >Ver Todos los Usuarios</a>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default LastMembers;