import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import Texto from "../../data/es";
import styled from "styled-components"; //styled for data table
import Links from "../../data/link";
import Config from "../../data/config";
import Constant from "../../data/constant";
import Fetch from "../utils/Fetch";
import TitlePage from "../utils/TitlePage";
import AuthService from "../Usuario/AuthService";
import ModalPdf from "../utils/ModalPdf";
import FormModalPreLiquidacion from "./FormModalPreLiquidacion";
import CustomLoader from "../utils/SpinnerDataTable";
import Columns from "./Columns";

const VisacionMinutas = (props) => {
  const fetch = new Fetch();
  fetch.setToast(toast);
  const auth = new AuthService();
  const userAuth = auth.getProfile();

  const columnsClass = new Columns(userAuth);
  const modalPdf = new ModalPdf();
  modalPdf.setToast(toast);
  const history = useHistory();

  const titlePage = Texto.visacion_minutas;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [typeSearch, setTypeSearch] = useState(0);
  const [rowSelected, setRowSelected] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilterSearch, setShowFilterSearch] = useState(false);
  const [showModalPreLiquidacion, setShowModalPreLiquidacion] = useState(false);

  if (!auth.loggedIn()) history.replace(Links[4].url);

  useEffect(() => {
    if (auth.loggedIn()) {
      if (
        userAuth.code === Constant[0].grupos.funcionario ||
        userAuth.code === Constant[0].grupos.administrador
      ) {
        setShowFilterSearch(true);
      }

      getDataVisaciones();
      window.jQuery(".sc-kAzzGY").remove(); //pertenece al datatable
      window.jQuery(".rdt_TableHeader").css("padding-left", "0");
      setTimeout(() => {
        window.jQuery(".rdt_Table").css("width", "98%");
        window.jQuery(".hAZHBB").css("width", "30px");
      }, 500);
    } else window.location.href = Links[4].url;
    window.scrollTo(0, 0);
  }, []);

  /////////
  /*const registerImg = async () => {
    const form = new FormData();
    form.append(
      "vm_visacion_minutas[token]",
      "f899991a2dc1dbb645f448c11fdc8e4b"
    );

    const saveVisacionMinuta = await fetch.fetchPost(
      form,
      "report/visacion-minutas/imto",
      undefined
    );

    console.log("******************* ", saveVisacionMinuta);
  };*/
  ///////////

  const getDataVisaciones = async () => {
    setLoading(true);
    const response = await fetch.axiosAsyncGet(
      `api/visacion-minutas/for-page/${currentPage}/${perPage}/${typeSearch}`
    );
    if (response && response.status) {
      setData(response.data);
      setTotalRows(response.total);
      setLoading(false);

      if (document.getElementById("pEnProceso")) {
        document.getElementById("pEnProceso").innerHTML =
          "En Proceso - " + response.enProceso;
        document.getElementById("pCompletado").innerHTML =
          "Completados - " + response.completados;

        document.getElementById("pAprobados").innerHTML =
          "Aprobados - " + response.aprobados;

        if (
          userAuth.code === Constant[0].grupos.funcionario ||
          userAuth.code === Constant[0].grupos.administrador
        ) {
          document.getElementById("pCertificadoTranferencia").innerHTML =
            "Certificados - " + response.certificados;
        }
      }

      toast.success(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const SampleStyle = styled.div`
    padding: 10px;
    display: block;
    width: 90%;
    p {
      font-size: 12px;
      word-break: break-all;
    }
  `;

  const IntentExpandedComponent = ({ data }) => {
    const vendedor = data.vendedores ? JSON.parse(data.vendedores) : {};
    const juridico = vendedor.juridico ? vendedor.juridico : {};
    return (
      <SampleStyle>
        <div className="row">
          <div className="col-12 col-md-4 mb-3">
            <span>
              <strong>{Texto.datos_minuta.toUpperCase()} </strong>
            </span>
            <hr className="mt-0 " />
            <p>
              <strong>{Texto.numero_orden}: </strong>
              {data.numero}
            </p>
            <p>
              <strong>{Texto.numero_fur}: </strong>
              {data.fur}
            </p>
            <p>
              <strong>{Texto.usuario}: </strong>
              {data.username}
            </p>
            <p>
              <strong>{Texto.creado}: </strong>
              {data.created_at}
            </p>
            <p>
              <strong>{Texto.aprobado}: </strong>
              {data.fecha_aprobacion}
            </p>
            <p>
              <strong>{Texto.tipo_visacion_minuta}: </strong>
              {data.tipo_visacion_minuta}
            </p>
          </div>
          <div className="col-12 col-md-4">
            <span>
              <strong>{Texto.datos_vendedor.toUpperCase()} </strong>
            </span>
            <hr className="mt-0 " />
            <p>
              <strong>{Texto.contribuyente}: </strong>
              {vendedor.natural === 1 ? "Natural" : "Juridico"}
            </p>
            <p>
              <strong>{Texto.tipo_documento}: </strong>
              {vendedor.tipo_documento}
            </p>
            <p>
              <strong>{Texto.numero_documento}: </strong>
              {vendedor.numero_documento
                ? vendedor.numero_documento
                : juridico.numero_documento}
            </p>
            <p>
              <strong>{Texto.nombre}: </strong>
              {vendedor.nombre}
            </p>
            {juridico && juridico.pmc ? (
              <>
                <p>
                  <strong>{Texto.pmc}: </strong>
                  {juridico.pmc}
                </p>
                <p>
                  <strong>{Texto.razon_social}: </strong>
                  {juridico.razon_social}
                </p>
              </>
            ) : (
              ""
            )}
          </div>

          <div className="col-12 col-md-4">
            <span>
              <strong>{Texto.datos_certificado.toUpperCase()} </strong>
            </span>
            <hr className="mt-0 " />
            <p>
              <strong>{Texto.fur_preliquidacion}: </strong>
              {data.fur_preliquidacion}
            </p>
            <p>
              <strong>{Texto.fecha_certificacion}: </strong>
              {data.fecha_certificacion}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 mb-3">
            <span>
              <strong>{Texto.datos_tecnicos.toUpperCase()} </strong>
            </span>
            <hr className="mt-0 " />
            <p>
              <strong>{Texto.numero_inmueble}: </strong>
              {data.numero_ruat}
            </p>
            <p>
              <strong>{Texto.catastro}: </strong>
              {data.catastro_ruat}
            </p>
            <p>
              <strong>{Texto.colindates}: </strong>
              {data.total_colindantes}
            </p>
            <p>
              <strong>{Texto.construcciones}: </strong>
              {data.total_construcciones}
            </p>
            <p>
              <strong>{Texto.construcciones_adicionales}: </strong>
              {data.total_construcciones_adicionales}
            </p>
          </div>
          <div className="col-12 col-md-4 mb-3">
            <span>
              <strong>{Texto.datos_comprador.toUpperCase()} </strong>
            </span>
            <hr className="mt-0 " />
            <p>
              <strong>{Texto.compradores}: </strong>
              {data.total_compradores}
            </p>
          </div>

          <div className="col-12 col-md-4 mb-3">
            <span>
              <strong>{Texto.datos_propiedad.toUpperCase()} </strong>
            </span>
            <hr className="mt-0 " />
            <p>
              <strong>{Texto.estado}: </strong>
              {data.propiedad_estado}
            </p>
            <p>
              <strong>{Texto.clase}: </strong>
              {data.clase_ruat}
            </p>
          </div>
        </div>
      </SampleStyle>
    );
  };

  const handleOpenEditClick = (row) => {
    debugger;
    const estadoPasos = fetch.fetchGet(
      `api/visacion-minutas/estado-pasos/${row.token}`
    );
    estadoPasos.then((res) => {
      if (res && res.status) {
        window.editVisacionMinutasBootbox(
          Links[91].url,
          row.contribuyente_natural,
          row.token,
          row.numero,
          res.isCompleteDataInmueble,
          res.isCompleteDataSeller,
          res.isCompleteDataBuyed,
          res.isCompleteDataMinuta
        );
      } else {
        window.editVisacionMinutasBootbox(
          Links[91].url,
          row.contribuyente_natural,
          row.token,
          row.numero,
          false,
          false,
          false,
          false
        );
      }
    });
  };

  const handleAprobarDatosClick = (row) => {
    window.createBootbox("Esta Seguro de Continuar.", function (result) {
      if (result === true) {
        const form = new FormData();
        form.append("visacion_minutas[token]", row.token);
        fetch.fetchPost(form, "api/visacion-minutas/check").then((dataJson) => {
          if (dataJson && dataJson.status) {
            getDataVisaciones();
            toast.success(dataJson.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        });
      }
    });
  };

  const handleReaperturarClick = (row) => {
    window.createBootbox("Esta Seguro de Continuar.", function (result) {
      if (result === true) {
        const form = new FormData();
        form.append("visacion_minutas[token]", row.token);
        fetch
          .fetchPost(form, "api/visacion-minutas/uncheck")
          .then((dataJson) => {
            if (dataJson && dataJson.status) {
              getDataVisaciones();
              toast.success(dataJson.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          });
      }
    });
  };

  const handleChangeTipoVMClick = async (row) => {
    const list = [
      {
        text: "Seleccione Tipo de Visación",
        value: "",
      },
    ];
    const resTiposVisaciones = await fetch.fetchGet(
      `api/visacion-minutas/clasificadores`
    );

    if (resTiposVisaciones && resTiposVisaciones.status) {
      resTiposVisaciones.TipoVisacionMinutas.map((item) => {
        if (row.tipo_visacion_minuta_code !== item.code)
          list.push({ text: item.name, value: item.id });
        return undefined;
      });
    }

    window.bootBoxWithPromtOneInput(
      'Tipo Visación de Minuta para la DDJJ con Nro de Orden: "' +
        row.numero +
        '" y Nro de Inmueble: "' +
        row.numero_ruat+'"',
      "SELECT",
      list,
      async (result) => {
        if (result) {
          const form = new FormData();
          form.append("visacion_minutas[token]", row.token);
          form.append("tipo_visacion_minuta[id]", result);
          fetch
            .fetchPost(
              form,
              "api/visacion-minutas/tipo-visacion-minutas/change"
            )
            .then((dataJson) => {
              if (dataJson && dataJson.status) {
                toast.success(dataJson.message, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
              }
            });
        }
      }
    );
  };

  const handlePreviewClick = (row) => {
    const url = `${Config[0].url}report/visacion-minutas/generar/${row.numero_ruat}/${row.propiedad_codigo}/${row.token}`;
    modalPdf.showPdf(
      `${url}/preview?auth=${auth.getToken()}}`,
      "Datos de la Visación de Minuta, Inmueble Nro.: " + row.numero_ruat,
      row.token,
      undefined,
      `${url}/download?auth=${auth.getToken()}`
    );
  };

  const handleDeleteClick = (row) => {
    window.createBootbox("Esta Seguro de Continuar.", function (result) {
      if (result === true) {
        const form = new FormData();
        form.append("visacion_minutas[token]", row.token);
        fetch
          .fetchPost(form, "api/visacion-minutas/delete")
          .then((dataJson) => {
            if (dataJson && dataJson.status) {
              getDataVisaciones();
              toast.success(dataJson.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          });
      }
    });
  };

  const handleOpenImtoClick = (row) => {
    debugger;
    if (auth.loggedIn()) {
      history.replace(Links[101].url + row.token + "/" + row.numero);
    }
  };

  const handleGeneratePreliquidacionClick = (row) => {
    setShowModalPreLiquidacion(!showModalPreLiquidacion);
    setRowSelected(row);
    getDataVisaciones();
  };

  const handlePageChange = async (page) => {
    setLoading(true);
    const response = await fetch.axiosAsyncGet(
      `api/visacion-minutas/for-page/${page}/${perPage}/${typeSearch}`,
      {}
    );
    const json = await response.data;
    setLoading(false);
    setData(json);
    setCurrentPage(page);
  };

  const handlePreviewCertificateClick = async (row) => {
    window.bootBoxWithPromtOneInput(
      "Ingrese Fur-Preliquidacion a Verificar Pago",
      "TEXT",
      undefined,
      async (result) => {
        if (result) {
          const responsePreLiquidacion = await fetch.fetchGet(
            `api/cobros/preliquidacion/search/${result}/${row.token}/${row.numero}`
          );
          if (responsePreLiquidacion && responsePreLiquidacion.status) {
            const form = new FormData();
            form.append("visacion_minutas[token]", row.token);
            form.append("visacion_minutas[furPreliquidacion]", result);
            fetch
              .fetchPost(form, "api/visacion-minutas/emision")
              .then((dataJson) => {
                if (dataJson && dataJson.status === true) {
                  const url = `${Config[0].url}report/visacion-minutas/certificar/${row.numero_ruat}/${row.propiedad_codigo}/${row.token}`;
                  modalPdf.showPdf(
                    `${url}/preview?fur=${result}&auth=${auth.getToken()}}`,
                    "Certificación de Visación de Transferencia, Inmueble Nro.: " +
                      row.numero_ruat,
                    row.token,
                    undefined,
                    undefined
                  );
                  getDataVisaciones();
                }
              });
          }
        } else {
          toast.warning("No se ha definido el Fur de la Pre-Liquidación", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }
    );
  };

  const handlePerRowsChange = async (perPage, page) => {
    setLoading(true);
    const response = await fetch.axiosAsyncGet(
      `api/visacion-minutas/for-page/${page}/${perPage}/${typeSearch}`,
      {}
    );
    const json = await response.data;
    setLoading(false);
    setData(json);
    setPerPage(perPage);
    setCurrentPage(page);
  };

  const handleRedirectUrlClick = (ev, url) => {
    window.location.href = url;
  };

  const hanldeSearchVisacionMinutas = async (event, type_search) => {
    setLoading(true);
    const response = await fetch.axiosAsyncGet(
      `api/visacion-minutas/for-page/1/${perPage}/${type_search}`
    );
    if (response && response.status) {
      setData(response.data);
      setTotalRows(response.total);
      setLoading(false);
      setTypeSearch(type_search);
      toast.success(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleSubmitSearchForm = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    setLoading(false);
    let input = window.jQuery(event.target).find("input").val();
    if (input.length > 0) {
      fetch.fetchPost(form, `api/visacion-minutas/search`).then((res) => {
        if (res !== undefined && res.status === true) {
          setLoading(false);
          setData(res.data);
          setPerPage(perPage);
        } else {
          setLoading(false);
          setData([]);
          setPerPage(perPage);
        }
      });
    } else window.location.reload();
  };

  const breadcrumbs = [
    {
      title: Links[0].title,
      url: Links[0].url,
    },
    {
      title: Links[111].title,
      url: Links[111].url,
    },
    {
      title: Links[88].title,
      url: Links[88].url,
    },
  ];
  const eventColumns = {
    handleOpenEditClick,
    handleAprobarDatosClick,
    handleReaperturarClick,
    handleChangeTipoVMClick,
    handlePreviewClick,
    handlePreviewCertificateClick,
    handleGeneratePreliquidacionClick,
    handleDeleteClick,
    handleOpenImtoClick,
  };
  return (
    <section className="items-grid section mt-5 mb-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={titlePage}
          breadcrumbs={breadcrumbs}
          position={"left"}
          leftfull={false}
        />

        <section className="panel-menu-info">
          <div className="panel-menu-info-content">
            <div className="row">
              <div className="col-4 col-md-2 col-lg-2">
                <div
                  className="single-contact-info pointer"
                  onClick={(e) => hanldeSearchVisacionMinutas(e, 1)}
                >
                  <i className="fa fa-cog" aria-hidden="true"></i>
                  <p id="pEnProceso">0</p>
                </div>
              </div>
              <div className="col-4 col-md-2 col-lg-2">
                <div
                  className="single-contact-info pointer"
                  onClick={(e) => hanldeSearchVisacionMinutas(e, 2)}
                >
                  <i className="fa fa-file-text-o" aria-hidden="true"></i>
                  <i className="far fa-file-alt" aria-hidden="true"></i>
                  <p id="pCompletado">Completado - 0</p>
                </div>
              </div>

              <div className="col-4 col-md-2 col-lg-2">
                <div
                  className="single-contact-info pointer"
                  onClick={(e) => hanldeSearchVisacionMinutas(e, 3)}
                >
                  <i className="fa fa-check" aria-hidden="true"></i>
                  <p id="pAprobados">0</p>
                </div>
              </div>

              {showFilterSearch ? (
                <div className="col-4 col-md-2 col-lg-2">
                  <div
                    className="single-contact-info pointer"
                    onClick={(e) => hanldeSearchVisacionMinutas(e, 4)}
                  >
                    <i className="fas fa-certificate" aria-hidden="true"></i>
                    <p id="pCertificadoTranferencia">0</p>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="col-4 col-md-2 col-lg-2">
                <div
                  className="single-contact-info pointer"
                  onClick={(e) => handleRedirectUrlClick(e, Links[8].url)}
                >
                  <i className="far fa-user" aria-hidden="true"></i>
                  <p>Mi Cuenta</p>
                </div>
              </div>
              <div className="col-4 col-md-2 col-lg-2">
                <div
                  className="single-contact-info pointer"
                  onClick={(e) => handleRedirectUrlClick(e, Links[87].url)}
                >
                  <i className="far fa-file" aria-hidden="true"></i>
                  <p>Nueva Visación</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <form
          action=""
          className="contact__form center-login"
          name="formSearchDataTable"
          id="formSearchDataTable"
          method="post"
          noValidate
          onSubmit={handleSubmitSearchForm}
        >
          <div className="row">
            <div className="col-12 col-md-4 col-lg-6 "></div>
            <div className="col-12 col-md-8 col-lg-6 ">
              <div className="input-group mb-3">
                <select
                  className="form-select "
                  name="type"
                  required
                  data-parsley-required="true"
                >
                  <option key={0} value="NUMERO">
                    Número de Orden
                  </option>
                  <option key={1} value="FUR">
                    Número de FUR
                  </option>
                </select>
                <input
                  type="text"
                  name="search"
                  id="search"
                  className="form-control"
                  placeholder="*"
                  aria-label="search"
                />

                <button
                  className="btn btn-outline-secondary"
                  type="submit"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Buscar"
                >
                  <i className="fa fa-search" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </form>

        <DataTable
          title={titlePage}
          columns={columnsClass.columns(eventColumns)}
          data={data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover
          noDataComponent={Texto.there_are_no_records_to_display}
          progressComponent={<CustomLoader />}
          expandableRowsComponent={<IntentExpandedComponent />}
          expandableRows
        />
      </div>

      <ModalPdf />
      {showModalPreLiquidacion ? (
        <FormModalPreLiquidacion
          auth={auth}
          toast={toast}
          data={rowSelected}
          fetch={fetch}
          title={"Generación de la Pre-Liquidación"}
          eventOpenCloseModal={handleGeneratePreliquidacionClick}
        />
      ) : (
        ""
      )}

      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </section>
  );
};

export default VisacionMinutas;
