import React, { useEffect, useState, useRef } from "react";
import Links from "../../data/link";
import TitlePage from "../utils/TitlePage";
import Texto from "../../data/es";
import ContribuyenteInfo from "./ContribuyenteInfo";
import ContribuyenteResult from "./ContribuyenteResult";
import { ToastContainer, toast } from "react-toastify";
import Fetch from "../utils/Fetch";
import Config from "../../data/config";
import ModalPdf from "../utils/ModalPdf";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

// to={`${Links[121].url}?liquidacion=inmuebles-imto`}
const Contribuyente = (props) => {
	const fetch = new Fetch();
	fetch.setToast(toast);
	const titlePage = Texto.liquidaciones_pendientes;

	const { objeto } = useParams();

	const [dataInfoContribuyente, setDataInfoContribuyente] = useState([]);
	const [dataResultado, setDataResultado] = useState([]);
	const [dataResultadoImto, setDataResultadoImto] = useState([]);
	const [messageSearch, setMessageSearch] = useState("");
	const [inputSearchText, setInputSearchText] = useState("");
	const [inputSearchType, setInputSearchType] = useState("");
	const [inputSearchGestion, setInputSearchGestion] = useState(0);
	const [dataPublication, setDataPublication] = useState([]);
	const [dataGestiones, setDataGestiones] = useState([]);
	const [totalImto, setTotalImto] = useState([]);
	const [loadingConsulta, setLoadingConsulta] = useState(false);

	const refInputSearchModal = useRef();
	const refInputSearchPmc = useRef("");
	const idFormSearchDeuda = "formSearchDataTable";

	const modalPdf = new ModalPdf();
	modalPdf.setToast(toast);

	let params = new URLSearchParams(window.location.search);
	// 	var paramLiquidacion = params.get("liquidacion");
	// =================================================== //
	useEffect(() => {
		refInputSearchPmc.current.focus();
		refInputSearchModal.current = null;
		window.scrollTo(0, 0);
		loadDataGestion();
	}, []);

	// const handleKeyDown = (event) => {
	// 	if (event.key === "Enter") event.preventDefault();
	// };
	// =================================================== //
	const onSubmitConsulta = async (event) => {
		debugger;
		event.preventDefault();
		setDataInfoContribuyente([]);
		setDataResultado([]);
		setDataResultadoImto([]);
		setMessageSearch("");
		setDataPublication([]);
		setLoadingConsulta(true);

		const thisForm = document.getElementById(`${idFormSearchDeuda}`);
		const formData = new FormData(thisForm);
		const codeType = formData.get("search[tipo]");
		const textSearch = formData.get("search[texto]");
		const gestion = formData.get("gestion");

		if (textSearch.length > 1) {
			setInputSearchText(formData.get("search[texto]"));
			setInputSearchType(codeType);
			setInputSearchGestion(gestion);

			const dataInputResponse = await fetch.fetchGet(
				`api/nt-tributaria/reporte-consulta/${codeType}/${textSearch}/${gestion}`
			);
			if (dataInputResponse && dataInputResponse.status) {
				setDataInfoContribuyente(dataInputResponse.data.resContribuyente);
				setDataResultado(dataInputResponse.data.resDeudaImpuesto);
				setDataResultadoImto(dataInputResponse.data.resDeudaImto);
				setDataPublication(dataInputResponse.data.resPublicacion);
				let totalIMTO =
					dataInputResponse.data.resDeudaImto[0].INMUEBLE.data.length;
				let totalIMTO2 =
					dataInputResponse.data.resDeudaImto[1].VEHICULO.data.length;
				setTotalImto(totalIMTO + totalIMTO2);
			} else {
				setMessageSearch(
					"No existen datos que coincidan con los valores de búsqueda."
				);
			}
		} else {
			setMessageSearch("Ingrese el texto para la búsqueda.");
		}
		setLoadingConsulta(false);
	};

	// =============================================== //
	const onClickButtonPdf = (event, codigoPublicacion) => {
		debugger;
		const url = `${Config[0].url}api/nt-tributaria/reporte-pdf/${inputSearchType}/${inputSearchText}/${inputSearchGestion}/${codigoPublicacion}`;
		let titulo =
			"Reporte de búsqueda: " +
			inputSearchType +
			" " +
			inputSearchText +
			" (" +
			codigoPublicacion +
			")";

		modalPdf.showPdf(
			`${url}/preview`,
			titulo,
			inputSearchText,
			undefined,
			`${url}/download`
		);
	};

	const loadDataGestion = async () => {
		debugger;
		const responseGestion = await fetch.axiosAsyncGet(
			`api/nt-tributaria/reporte-gestiones`
		);

		if (responseGestion && responseGestion.status) {
			let listGestiones = responseGestion.data.gestiones;
			listGestiones = listGestiones.filter((row) => row.gestion_nombre > 2022);
			setDataGestiones(listGestiones);
		}
	};

	let infoResultadoIMTO = dataResultadoImto.map((dataRows) => (
		<ContribuyenteResult listResultado={dataRows} imto={"(I.M.T.O.)"} />
	));

	// ===================================================================================== //
	// migas
	// ===================================================================================== //
	let breadcrumbs = [
		{
			title: Links[0].title,
			url: Links[0].url,
		},

		objeto == "inmueble"
			? {
					title: Links[111].title,
					url: Links[111].url,
			  }
			: objeto == "vehiculo"
			? {
					title: Links[112].title,
					url: Links[112].url,
			  }
			: {
					title: Links[128].title,
					url: Links[128].url,
			  },

		objeto == "inmueble"
			? {
					title: Links[122].title,
					url: Links[122].url,
			  }
			: objeto == "vehiculo"
			? {
					title: Links[123].title,
					url: Links[123].url,
			  }
			: {
					title: Links[124].title,
					url: Links[124].url,
			  },

		// {`${Links[121].url}?liquidacion=inmuebles-imto`}
		{
			title: Links[119].title,
			url: Links[119].url,
		},
	];
	// ===================================================================================== //
	//
	// ===================================================================================== //
	return (
		<div className="items-grid section mt-5">
			<div className="container mt-4">
				<TitlePage
					titlePage={titlePage}
					breadcrumbs={breadcrumbs}
					position={"left"}
					leftfull={false}
				/>
				<br />

				<h6 className="text-center">
					Buscador de Resolución de Liquidación de Determinación Mixta
				</h6>

				<form
					action=""
					className="needs-validation"
					name={idFormSearchDeuda}
					id={idFormSearchDeuda}
					method="post"
					noValidate
					onSubmit={onSubmitConsulta}
					// onSubmitHandleSearchContribuyente
				>
					<div className="row m-3 d-flex justify-content-center">
						<div className="col-lg-8 col-md-9 col-xs-12">
							<div className="input-group mb-2 mt-2">
								<div className="input-group-prepend col-md-6 col-xs-12">
									<select
										className="form-select input-group-prepend"
										name="search[tipo]"
										required
										data-parsley-required="true"
									>
										<option key={0} value="CI">
											Doc. Identidad (CI - CE)
										</option>
										<option key={1} value="NIT">
											Número Identificación Tributaria (NIT)
										</option>
										<option key={2} value="PMC">
											Padrón Municipal Contribuyente (PMC)
										</option>
										<option key={3} value="OTROS">
											Otros (PAS-RUC-RUN-AG)
										</option>
									</select>
								</div>
								<input
									type="text"
									name="search[texto]"
									id="search_contribuyente"
									className="form-control"
									placeholder="Ingrese el Texto"
									pattern="[0-9-A-Za-z]+"
									data-parsley-pattern="[0-9-A-Za-z]+"
									ref={refInputSearchPmc}
									required
								/>
							</div>
						</div>
						<div className="col-lg-8 col-md-9 col-xs-12">
							<div className="input-group mb-2">
								<div className="input-group-prepend col-md-6 col-xs-12">
									<label
										className="input-group-text"
										htmlFor="inputGroupSelect01"
									>
										Gestión de Publicación
									</label>
								</div>
								<select
									className="form-select"
									id="gestion"
									name="gestion"
									required
								>
									{dataGestiones.map((row) => (
										<option key={row.gestion_nombre} value={row.gestion_nombre}>
											{row.gestion_nombre}
										</option>
									))}
								</select>
							</div>
						</div>

						<div
							className="col-lg-8 col-md-9 col-xs-12"
							style={{ textAlign: "right" }}
						>
							<button
								className="btn btn-outline-primary"
								type="submit"
								data-toggle="tooltip"
								data-placement="top"
								title="Buscar"
							>
								<i className="fa fa-search" aria-hidden="true"></i> Buscar
							</button>
						</div>

						{dataResultado.length === 0 && messageSearch.length > 1 ? (
							<div className="col-lg-8 col-md-9 col-xs-12 form-group mt-3">
								<div className="alert alert-danger" role="alert">
									{messageSearch}
								</div>
							</div>
						) : (
							""
						)}
					</div>
				</form>

				<div
					className="row align-content-center justify-content-center m-4"
					style={{ boxSizing: "content-box" }}
				>
					<div
						id="spinner-loading-modal-pdf"
						className={loadingConsulta ? "row mt-3" : "row mt-3 d-none"}
					>
						<div className="col-12 mb-1">
							<img
								className="rounded mx-auto d-block"
								alt="pulse-loading"
								src={"/static/img/pulse_200.gif"}
								width="50"
							/>
						</div>
					</div>

					{dataResultado.length > 0 || dataResultadoImto.length > 0 ? (
						<ContribuyenteInfo
							listaData={dataInfoContribuyente}
							dataPublication={dataPublication}
							onClickButtonPdf={onClickButtonPdf}
						/>
					) : (
						""
					)}

					{/* IMPUESTO			 */}
					{dataResultado.map((dataRows, index) => (
						<ContribuyenteResult
							key={index}
							listResultado={dataRows}
							imto={""}
						/>
					))}

					{/* IMPUESTO A LA Transferencia */}
					{totalImto > 0 ? infoResultadoIMTO : null}
				</div>
			</div>

			<ModalPdf />

			<ToastContainer
				enableMultiContainer
				containerId={"Z"}
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnVisibilityChange
				draggable
				pauseOnHover
			/>
		</div>
	);
};

export default Contribuyente;
