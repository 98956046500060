import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import Texto from '../../../data/es'
import TitlePage from './../../../components/utils/TitlePage'
import Links from '../../../data/link';
import Fetch from '../../../components/utils/Fetch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DatosIdentificacionVehiculo from './DatosIdentificacionVehiculo'
import DatosTecnicosVehiculo from './DatosTecnicosVehiculo'
import DatosDeudaVehiculo from './DatosDeudaVehiculo'
import DetalleServicioPublico from './DetalleServicioPublico'

const Detalle = (props) => {

    const fetch = new Fetch();
    fetch.setToast(toast);
    const history = useHistory();

    const [dataVehiculo, setDataVehiculo] = useState(undefined)
    const [dataDeudas, setDataDeudas] = useState([])
    const [servicioPublico, setServicioPublico] = useState(undefined)
    const [showButtons, setShowButtons] = useState(false)
    const [showMensajeClose, setShowMensajeClose] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
        searchVehiculo()
    }, []);

    const searchVehiculo = () => {
        const token = tokenCertificacionUrl()
        fetch.fetchGet('api/certificacion-servicio-publico/' + token).then(dataJson => {
            if (dataJson && dataJson.status === true && dataJson.data) {
                if (dataJson.data.vehiculo) {
                    debugger
                    setDataVehiculo(dataJson.data.vehiculo.dv)
                    setDataDeudas(dataJson.data.vehiculo.de)
                    setServicioPublico(dataJson.data.vehiculoServicioPublico)
                    //setDataObservaciones(dataJson.data.vehiculo.lo)
                    //setDataObservado(dataJson.data.observado)
                    setShowButtons(true)

                    toast.success(dataJson.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else {
                    setShowButtons(false)
                    toast.warn(dataJson.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            } else {
                setShowButtons(false)
            }
        })
    }

    const tokenCertificacionUrl = () => {
        const query = window.location.pathname.split("/")
        return query[query.length - 1]
    }

    const removeDeuda = (deuda) => {
        let deudas = dataDeudas.filter((dataDeuda) => !(Number.parseInt(dataDeuda.ge) === Number.parseInt(deuda.ge) && dataDeuda.cp === deuda.cp && Number.parseFloat(dataDeuda.mt) === Number.parseFloat(deuda.mt)));
        setDataDeudas(deudas);
    }

    const handleEditData = (event) => {
        event.preventDefault()
        history.push("/certificacion-transporte-publico/" + servicioPublico.certificado.token + "/" + servicioPublico.certificado.numero);
    }

    const handleEndServicioCertificacion = (event) => {
        event.preventDefault()
        history.push("/");
    }


    const handleGenerarSolicitud = (event) => {
        event.preventDefault()
        const token = tokenCertificacionUrl()
        fetch.fetchGet('api/confirmacion-certificado-servicio-publico/' + token).then(dataJson => {
            if (dataJson && dataJson.status === true && dataJson.data) {
                toast.success(dataJson.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });

                debugger
                //setCertificadoPublico(dataJson.data.certificadoServicioPublico)
                setShowMensajeClose(true)
                //mostrar mensaje de tramite finalizado
            }
        })
    }

    const breadcrumbs = [
        {
            title: Links[0].title,
            url: Links[0].url
        },
        {
            title: Links[38].title,
            url: Links[38].url
        },
        {
            title: 'CTSP',
            url: '#'
        }
    ];

    return (
        <section className="items-grid section mt-5">
            <div className="container mt-4">
                <TitlePage titlePage={Texto.solicitud_certificado_transporte_servicio_publico} breadcrumbs={breadcrumbs} position={'left'} />

                {
                    !showMensajeClose ?
                        <div className="container" >
                            <div className="row mt-3 ">
                                {
                                    dataVehiculo ?
                                        <>
                                            <DatosIdentificacionVehiculo dataVehiculo={dataVehiculo} />
                                            <DatosTecnicosVehiculo dataVehiculo={dataVehiculo} />
                                        </>
                                        : ""
                                }
                                <DatosDeudaVehiculo dataDeudas={dataDeudas} nameFormGestionDeuda="tabla" activeEvent={false} removeDeuda={removeDeuda} />

                                <DetalleServicioPublico servicioPublico={servicioPublico} />
                            </div>


                            {
                                showButtons ?
                                    <div className="row mt-3">

                                        <div className="col-12 col-md-6 search-btn button text-end padding-right-0 ">
                                            <input type="submit" className="btn bg-fountai-blue btn-full-screen mb-3" value="Editar Mis Datos" onClick={handleEditData}></input>
                                        </div>

                                        <div className="col-12 col-md-6 search-btn button text-end padding-right-0 ">
                                            <input className="btn btn-full-screen mb-3" type="button" value={'Generar Solicitud CTSP'} onClick={handleGenerarSolicitud} />
                                        </div>
                                    </div>
                                    : ""
                            }

                        </div>
                        :
                        <div className="container" >
                            <div className="row mt-3">
                                <div className="col-12 alert alert-success text-center">
                                    <div className="text-center mb-2 mt-3 mb-3">
                                        <i className="fa fa-check-circle" aria-hidden="true" style={{ fontSize: '4em' }}></i>
                                    </div>

                                    <div className="" role="alert">
                                        <h5>La Solicitud para la obtención del Certificado de Servicio Público, ha sido recepcionado por el G.A.M.C.   </h5>
                                        <p>Debe aproximarse por oficinas de GAMC, para poder recoger su Certificado de Transporte Público</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-12 search-btn button text-end padding-right-0 ">
                                    <input type="submit" className="btn bg-casa-blanca btn-full-screen mb-3" value="Finalizar" onClick={handleEndServicioCertificacion}></input>
                                </div>

                            </div>
                        </div>
                }
            </div>

            <ToastContainer enableMultiContainer containerId={'Z'}
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
            <ToastContainer />
        </section>
    )
}

export default Detalle