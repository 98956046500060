import React, { useEffect } from 'react';
import Config from '../../data/config';
import Links from '../../data/link';
import TitlePage from '../utils/TitlePage';
import Texto from '../../data/es';
import 'react-toastify/dist/ReactToastify.css';

let breadcrumbs = [
    {
        title: Links[0].title,
        url: Links[0].url
    },
    {
        title: Links[33].title,
        url: Links[33].url
    }
];

const Liquidacion = (props) => {

    const titlePage = Texto.liquidaciones_mixtas

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleRedirectUrlClick = (ev, url) => {
        ev.preventDefault();
        window.location.href = url;
    }

    return (
        <section className="items-grid section mt-5">
            <div className="container mt-4">
                <TitlePage titlePage={titlePage} breadcrumbs={breadcrumbs} position={'left'} leftfull={false} />

                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                        <div className="single-news wow fadeInUp" data-wow-delay=".2s" onClick={e => handleRedirectUrlClick(e, Links[34].url + '?proceso=vehiculos')}>
                            <div className="image">
                                <img className='thumb' alt='img-liquidacion-vehiculo' src={Config[2].url + '/static/img/bt_vehiculo.png'} />
                            </div>
                            <div className="content-body">
                                <h4 className="title"><a href="blog-single-sidebar.html">{Texto.vehiculos}</a></h4>
                                <p>IMPUESTO MUNICIPAL A LA PROPIEDAD DE VEHICULOS AUTOMOTORES</p>
                                <p>IMPUESTO MUNICIPAL A LA TRANSFERENCIA</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                        <div className="single-news wow fadeInUp" data-wow-delay=".2s" onClick={e => handleRedirectUrlClick(e, Links[34].url + '?proceso=inmuebles')}>
                            <div className="image">
                                <img className='thumb' alt='img-liquidacion-inmueble' src={Config[2].url + '/static/img/bt_inmuebles.png'} />
                            </div>
                            <div className="content-body">
                                <h4 className="title"><a href="blog-single-sidebar.html">{Texto.inmuebles}</a></h4>
                                <p>IMPUESTO MUNICIPAL A LA PROPIEDAD DE BIENES INMUEBLES e IMPUESTO MUNICIPAL A LA TRANSFERENCIA</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                        <div className="single-news wow fadeInUp" data-wow-delay=".2s" onClick={e => handleRedirectUrlClick(e, Links[34].url + '?proceso=mercados')}>
                            <div className="image">
                                <img className='thumb' alt='img-liquidacion-mercado' src={Config[2].url + '/static/img/bt_mercados.png'} />
                            </div>
                            <div className="content-body">
                                <h4 className="title"><a href="blog-single-sidebar.html">{Texto.sitios_mercados}</a></h4>
                                <p>PATENTE MUNICIPAL DE SITIOS Y MERCADOS (PATENTE UNICA ANUAL - PUM)</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                        <div className="single-news wow fadeInUp" data-wow-delay=".2s" onClick={e => handleRedirectUrlClick(e, Links[34].url + '?proceso=publicidad_exterior')}>
                            <div className="image">
                                <img className='thumb' alt='img-licitacion-publicidad-exterior' src={Config[2].url + '/static/img/bt_publicidad_exterior.png'} />
                            </div>
                            <div className="content-body">
                                <h4 className="title"><a href="blog-single-sidebar.html">{Texto.publicidad_exterior}</a></h4>
                                <p>PATENTE MUNICIPAL DE PUBLICIDAD EXTERIOR(PATENTE UNICA ANUAL - PUM)</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                        <div className="single-news wow fadeInUp" data-wow-delay=".2s" onClick={e => handleRedirectUrlClick(e, Links[34].url + '?proceso=actividades_economicas')}>
                            <div className="image">
                                <img className='thumb' alt='img-licitacion-actividades-economicas'
                                    src={Config[2].url + '/static/img/bt_actividad_economica.png'} />
                            </div>
                            <div className="content-body">
                                <h4 className="title"><a href="blog-single-sidebar.html">{Texto.actividades_economicas}</a></h4>
                                <p>ATENTE MUNICIPAL DE ACTIVIDADES ECONOMICAS (PATENTE UNICA ANUAL - PUM)</p>
                                <a href={Links[34].url + '?proceso=actividades_economicas'} title={Links[34].title} className="read-more">Leer mas</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Liquidacion;