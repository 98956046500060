import React from "react";
import Config from "../../data/config";
import Links from "../../data/link";

const PerfilOptionInmueble = (props) => {
  const handleRedirectUrlClick = (ev, url) => {
    ev.preventDefault();
    if (url.includes("certificacion-admin"))
      window.location.href = url + "?cert=detallado";
    else window.location.href = url;
  };

  return (
    <section>
      <div className="row">
        <div className="col-12 mb-2">
          <h4>Inmuebles</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-3 col-lg-2">
          <div
            className="single-news"
            onClick={(e) => handleRedirectUrlClick(e, Links[56].url)}
          >
            <div className="image">
              <img
                className="thumb"
                alt="img-certificacion"
                src={Config[2].url + "/static/img/bt_certificacion.png"}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 col-lg-2">
          <div
            className="single-news"
            onClick={(e) =>
              handleRedirectUrlClick(e, Links[97].url + "inmuebles")
            }
          >
            <div className="image">
              <img
                className="thumb"
                alt="img-fur-gratuito"
                src={Config[2].url + "/static/img/btn_fur_gratuito.png"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PerfilOptionInmueble;
