import React, { Component } from 'react';
import Links from '../../data/link';
import { Link } from 'react-router-dom';
import Texto from '../../data/es';

class Page404 extends Component {

    constructor(props) {
        super(props);
        this.handleContactanosClick = this.handleContactanosClick.bind()

        this.state = {};
    }

    handleContactanosClick(event) {
        event.preventDefault()
        window.jQuery("#modalFormContacts").modal("show")
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (window.location.pathname === Links[6].url)
            window.location.reload();
    }

    render = () => {
        return <section className="items-grid section mt-5">
            <div className="container mt-4">
                <div className="row">
                    <div className="col-md-12">
                        <div className="error-template">
                            <h2 className="wow fadeInUp" data-wow-delay=".3s"> Oops!</h2>
                            <h3 className="wow fadeInUp" data-wow-delay=".3s">{Texto.page_not_found}</h3>
                            <div className="error-details wow fadeInUp" data-wow-delay=".3s">
                                <small>{Texto.ha_ocurrido_un_error_pagina_no_encontrada}</small>
                            </div>
                            <div className="error-actions mt-3">
                                <div className="col-12 col-md-6 search-btn button ">
                                    <Link to={{ pathname: Links[0].url }} className="btn btn-full-screen wow fadeInUp" data-wow-delay=".3s"><i class="fas fa-home"></i> Inicio </Link>
                                </div>

                                <div className="col-12 col-md-6 search-btn button ">
                                    <Link to={'#'} className="btn btn-full-screen wow fadeInUp" onClick={this.handleContactanosClick} data-wow-delay=".3s"><span className="glyphicon glyphicon-envelope"></span> Contactanos </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }
}

export default Page404;