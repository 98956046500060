import React, { useEffect, useState, useRef } from "react";
import AuthService from "./../../../components/Usuario/AuthService";
import { useHistory, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Config from "./../../../data/config";
import Texto from "./../../../data/es";
import "./../../style/style-simat.css";
import TitlePage from "./../../utils/TitlePage";
import Constant from "./../../../data/constant";
import CustomLoader from "./../../utils/SpinnerDataTable";
import { ToastContainer, toast } from "react-toastify";
import Fetch from "../../utils/Fetch";
import styled from "styled-components";
import ModalPdf from "../../utils/ModalPdf";
import InfoPublicidad from "../_commonAdvertising/InfoPublicidad";
import DatosTecnico from "../_commonAdvertising/DatosTecnico";
import Links from "./../../../data/link";
import Consulta from "../Consulta";
import InfoTopAdvertising from "./InfoTopAdvertising";
import constant from "./../../../data/constant";

const breadcrumbs = [
    {
        title: Links[0].title,
        url: Links[0].url,
    },
    {
        title: Links[128].title,
        url: Links[128].url,
    },
    {
        title: Links[129].title,
        url: Links[129].url,
    },
    {
        title: Links[133].title,
        url: Links[133].url,
    }
];

const ListPublicidadDDJJ = (props) => {
    const fetch = new Fetch();
    fetch.setToast(toast);

    const auth = new AuthService();
    const userAuth = auth ? auth.getProfile() : null;
    const userCode = userAuth ? userAuth.code : null;
    const esContribuyente = userCode === "CONTRIBUYENTE" ? true : false;
    const esFuncionario = userCode === "FUNCIONARIO" ? true : false;

    const history = useHistory();
    const modalPdf = new ModalPdf();
    const consultaSQL = new Consulta();

    const titlePage = Texto.publicity_list;
    const idFormSearch = 'formSearch';
    const [loading, setLoading] = useState(false);

    const [dataAdvertisings, setDataAdvertisings] = useState([]);
    const [dataAdvertisingEstados, setDataAdvertisingEstados] = useState([]);
    const [dataReviews, setDataReviews] = useState([]);

    const [typeSearch, setTypeSearch] = useState();
    const [codeEstadoUse, setCodeEstadoUse] = useState();

    const tipoSearchRef = useRef();
    const textoSearchRef = useRef();
    // pagination 
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    let listEstadoContribuyente = [
        '"' + Constant[0].estado.en_proceso + '"',
        '"' + Constant[0].estado.aprobado + '"',
        '"' + Constant[0].estado.revisado + '"',
        '"' + Constant[0].estado.certificado + '"',
    ];

    let listEstadoContribuyenteByEstado = [
        Constant[0].estado.en_proceso,
        Constant[0].estado.aprobado,
        Constant[0].estado.revisado,
        Constant[0].estado.certificado,
    ];

    // contribuyente
    if (esContribuyente) {
        listEstadoContribuyente = [
            '"' + Constant[0].estado.en_proceso + '"',
            '"' + Constant[0].estado.aprobado + '"',
            '"' + Constant[0].estado.revisado + '"'
        ];

        listEstadoContribuyenteByEstado = [
            Constant[0].estado.en_proceso,
            Constant[0].estado.aprobado,
            Constant[0].estado.revisado
        ];
    }

    if (!auth.loggedIn()) history.replace(Links[4].url);

    useEffect(() => {
        window.scrollTo(0, 0);
        window.jQuery(".sc-kAzzGY").remove(); //pertenece al datatable
        window.jQuery(".cOaGrx").remove();
        window.jQuery(".sc-chPdSV").remove();
        window.jQuery(".rdt_TableHeader").css("padding-left", "0");
        // rdt_TableHeader
        setTimeout(() => {
            window.jQuery(".rdt_Table").css("width", "98%");
            window.jQuery(".hAZHBB").css("width", "30px");
        }, 500);

        getDataAdvertising();
    }, []);


    const formatDateDMY = (fecha) => {
        return window.moment(fecha).format("DD/MM/YYYY");
    };

    const formatDateDMYHIS = (fechahora) => {
        return window.moment(fechahora).format("DD/MM/YYYY H:mm");
    };

    // DDJJ
    const handlePreviewPdfClickDDJJ = (row, event) => {
        const url = `${Config[10].url}/api/advertising/report-ddjj/${row.numero}/${row.token}/ddjj`;
        modalPdf.showPdf(
            `${url}?report=preview&auth=${auth.getToken()}`,
            "Declaración Jurada de la Publicidad Nro. de Orden : " + row.numero,
            row.token,
            undefined,
            `${url}?report=download&auth=${auth.getToken()}`
        );
    };

    const handlePreviewPdfCrapeClick = async (row) => {
        debugger
        try {
            if (row.Estado.code === Constant[0].estado.revisado) {
                await handleUpdateEstadoAvdertising(Constant[0].estado.revisado, Constant[0].estado.certificado, row.token);
            }

            const url = `${Config[10].url}/api/advertising/report-ddjj/${row.numero}/${row.token}/crape`;
            modalPdf.showPdf(
                `${url}?report=preview&auth=${auth.getToken()}`,
                `Certificado de Registro de Autorización (CRAPE) de Publicidad Nro. Fur: ${row.fur}`,
                row.token,
                undefined,
                `${url}?report=download&auth=${auth.getToken()}`
            );

            setTimeout(async () => {
                await handleUploadFileByDigitalizacion(row);
                await getDataAdvertising();
            }, 5000);
        } catch (error) {
            console.error('Error handling CRAPE preview click:', error);
            toast.error("Ocurrió un error, vuelva a intentarlo.");
        }
    };


    // LIQUIDACION
    const handlePreviewPdfLiquidacionClick = async (row, event) => {
        debugger
        if (row.Estado.code === Constant[0].estado.revisado) {
            await handleUpdateEstadoAvdertising(Constant[0].estado.revisado, Constant[0].estado.certificado, row.token);
            await getDataAdvertising();
        }
        const url = `${Config[10].url}/api/advertising/report-ddjj/${row.numero}/${row.token}/liquidacion`;
        console.log("url pdf " + url);
        modalPdf.showPdf(
            `${url}?report=preview&auth=${auth.getToken()}`,
            "Liquidación de la Publicidad Nro. de orden : " + row.numero,
            row.token,
            undefined,
            `${url}?report=download&auth=${auth.getToken()}`
        );
    };

    const handlePreviewPdfCertificacionClick = async (row, event) => {
        debugger
        try {
            const queryCheckPaid = `query {
                checkPaidSinot ( advertising: {numero: "${row.numero}", token: "${row.token}"} ) {
                    message
                    result
                    data{
                        advertising{ numero }
                    }
                }
            }`;
            const checkPaid = await fetch.fetchPostGraphQl(queryCheckPaid, "/graphql", null)
            if (checkPaid && checkPaid.checkPaidSinot.result) {
                if (row.Estado.code === Constant[0].estado.revisado) {
                    await handleUpdateEstadoAvdertising(Constant[0].estado.revisado, Constant[0].estado.certificado, row.token);
                }

                const url = `${Config[10].url}/api/advertising/report-ddjj/${row.numero}/${row.token}/elemento`;
                modalPdf.showPdf(
                    `${url}?report=preview&auth=${auth.getToken()}`,
                    `Licencia de Publicidad/Autorización (Nro. de Orden: ${row.numero})`,
                    row.token,
                    undefined,
                    `${url}?report=download&auth=${auth.getToken()}`
                );
                setTimeout(async () => {
                    await handleUploadFileByDigitalizacion(row);
                    await getDataAdvertising();
                }, 5000);
            } else
                toast.info(checkPaid.checkPaidSinot.message);
        } catch (error) {
            console.error('Error handling preview click:', error);
            toast.error("Ocurrió un error, vuelva a intentarlo.");
        }
    };

    const handlePreviewPdfLicenciaActividad = async (row, event) => {
        debugger
        try {

            /*const queryCheckPaid = `query {
                checkPaidSinot ( advertising: {numero: "${row.numero}", token: "${row.token}"} ) {
                    message
                    result
                    data{
                        advertising{ numero }
                    }
                }
            }`;*/
            /*const checkPaid = await fetch.fetchPostGraphQl(queryCheckPaid, "/graphql", null)
            if (checkPaid && checkPaid.checkPaidSinot.result) {*/
            // Actualizar estado si es necesario
            if (row.Estado.code === Constant[0].estado.revisado) {
                await handleUpdateEstadoAvdertising(Constant[0].estado.revisado, Constant[0].estado.certificado, row.token);
            }

            const url = `${Config[10].url}/api/advertising/report-ddjj/${row.numero}/${row.token}/actEco`;
            modalPdf.showPdf(
                `${url}?report=preview&auth=${auth.getToken()}`,
                `Licencia de Publicidad (Nro. de Orden: ${row.numero})`,
                row.token,
                undefined,
                `${url}?report=download&auth=${auth.getToken()}`
            );

            setTimeout(async () => {
                await handleUploadFileByDigitalizacion(row);
                await getDataAdvertising();
            }, 5000);
            /*} else
                toast.info(checkPaid.checkPaidSinot.message);*/
        } catch (error) {
            console.error('Error handling preview click:', error);
            toast.error("Ocurrió un error, vuelva a intentarlo.");
        }
    };

    const handleUpdateEstadoAvdertising = async (estadoCodeOld, estadoCodeNew, tokenAdvertising) => {

        const query = consultaSQL.queryUpdateEstadoAdvertising(estadoCodeOld, estadoCodeNew, tokenAdvertising);
        try {
            const data = await fetch.fetchPostGraphQl(query, "/graphql", null);

            if (data.updateEstadoAdvertising && data.updateEstadoAdvertising.result)
                console.log(data.updateEstadoAdvertising.message);
            else
                console.log("No se pudo realizar la modificacion de estado." + estadoCodeOld + " a " + estadoCodeNew);
        } catch (error) {
            console.log('Error updating advertising status:' + estadoCodeOld + " a " + estadoCodeNew);
        }
    }

    const getDataAdvertising = async () => {

        setLoading(true);
        const query = consultaSQL.queryAdvertisingsAll(currentPage, perPage, listEstadoContribuyente);

        fetch.fetchPostGraphQl(query, "/graphql", null)
            .then((data) => {
                if (data.advertisings && data.advertisings.result) {

                    setDataAdvertisings(data.advertisings.data.advertising);
                    setTotalRows(data.advertisings.pagination.total);

                    getDataEstadosAdvertising(); // estados
                    toast.success(data.advertisings.message);
                } else {
                    toast.error(data.advertisings.message);
                }
            })
            /*.catch((error) => {
                debugger
                console.error("Error fetching data:", error);
                //toast.error("Error fetching data");
            })*/
            .finally(() => {
                setLoading(false);
            });
    }

    // listado de estados
    const getDataEstadosAdvertising = async () => {
        const query = consultaSQL.totalesByAdvertisingAndEstado("", "");
        const response = await fetch.fetchPostGraphQl(query, "/graphql", null);
        if (response && response.totalAdvertisingByEstado && response.totalAdvertisingByEstado.result) {
            const data = response.totalAdvertisingByEstado.data.advertisingByEstadoCount;
            setDataAdvertisingEstados(data);
        }
    }

    // si contribuytente natural o juridico
    const contribuyente = (listaPersona) => {

        if (Array.isArray(listaPersona) && listaPersona.length > 0) {
            let objetoTitular = listaPersona[0];
            let natural = parseInt(objetoTitular.natural);
            if (natural === 1) {
                return objetoTitular.Persona != null ? objetoTitular.Persona.nombre + " " + objetoTitular.Persona?.apellidoPaterno + " " + objetoTitular.Persona?.apellidoMaterno : " - ";
            } else {
                debugger
                return objetoTitular.AdTitularJuridico ? objetoTitular.AdTitularJuridico.razonSocial : "";
            }
        }
        return "Sin datos";
    }

    // LINK
    const htmlLinkAfter = (row) => {
        const codeEstadoAdvertising = row.Estado.code;
        const clasificationCategoryPublicity = row.clasificationCategoriesAdv.length > 0 ? row.clasificationCategoriesAdv[0].clasificationCategoryPublicity : null;
        const clasificationCode = clasificationCategoryPublicity ? clasificationCategoryPublicity.clasification.code : null;
        const clasificationName = clasificationCategoryPublicity ? clasificationCategoryPublicity.clasification.name : null;
        const furNumero = parseInt(row.fur);
        const digitalizado = parseInt(row.digitalizado);

        // estado revisado
        if (codeEstadoAdvertising === Constant[0].estado.revisado && clasificationCategoryPublicity) {
            // estructura - empresarial
            if (clasificationCode === Constant[0].publicidad_clasificador.code_empresarial && furNumero > 0 && digitalizado === 0) {
                return <li>
                    {
                        <Link
                            to="#"
                            className="dropdown-item"
                            title={`Generar CRAPE (${clasificationName})`}
                            onClick={(event) => handlePreviewPdfCrapeClick(row, event)}
                        >
                            <i className="fas fa-file-pdf me-2" aria-hidden="true"></i>
                            Generar CRAPE
                        </Link>
                    }
                </li>
            } else
                // licencia - actividad economica
                if (clasificationCode === Constant[0].publicidad_clasificador.code_actividad && digitalizado === 0) {
                    return <li>
                        {
                            <Link
                                to="#"
                                className="dropdown-item"
                                title={`Certificación ${clasificationName}`}
                                onClick={() => handlePreviewPdfLicenciaActividad(row)}
                            >
                                <i className="fas fa-certificate me-2 " aria-hidden="true"></i>
                                Generar Licencia
                            </Link>
                        }
                    </li>;
                } else {
                    return <li>
                        {
                            <Link
                                to="#"
                                className="dropdown-item"
                                title={`Generar Liquidación (${clasificationName})`}
                                onClick={() => handlePreviewPdfLiquidacionClick(row)}
                            >
                                <i className="fas fa-money-bill   me-2 " aria-hidden="true"></i>
                                Generar Liquidación
                            </Link>
                        }
                    </li>;
                }
            //// estado certificado
        } else if (codeEstadoAdvertising === Constant[0].estado.certificado && clasificationCategoryPublicity && digitalizado === 0) {
            if ((furNumero === 0 && clasificationCode === Constant[0].publicidad_clasificador.code_empresarial) || (furNumero > 0 && clasificationCode === Constant[0].publicidad_clasificador.code_eventual)) {
                return <li>
                    {
                        <Link
                            to="#"
                            className="dropdown-item"
                            title={`Certificación ${clasificationName}`}
                            onClick={() => handlePreviewPdfCertificacionClick(row)}
                        >
                            <i className="fas fa-certificate me-2 " aria-hidden="true"></i> Generar Autorización
                        </Link>
                    }
                </li>
            }
        }
    }


    const columnAdvertising = [
        {
            name: "Número FUR",
            cell: (row) => <span>{row.fur > 0 ? row.fur : " - "} {isRechazado(row.AdAdvertisingRequirementSite) && <span className="text-danger">(?)</span>}</span>,
            sortable: false,
        },
        {
            name: "Número de Orden",
            sortable: false,
            cell: (row) => <span>{row.numero}</span>,
            hide: "md",
        },

        {
            name: "Tipo",
            sortable: false,
            cell: (row) => <span>{row.clasificationCategoriesAdv.length > 0 ? row.clasificationCategoriesAdv[0].clasificationCategoryPublicity.clasification.name : ""}</span>,
            wrap: true
        },

        {
            name: "Categoria",
            sortable: false,
            cell: (row) => <span>{row.clasificationCategoriesAdv.length > 0 ? row.clasificationCategoriesAdv[0].clasificationCategoryPublicity.categoryPublicity.name : ""}</span>,
            wrap: true,
            ignoreRowClick: true,
            allowOverflow: true,
            hide: "md",
            style: {
                textAlign: 'justify', // Justifica el texto
            }
        },
        {
            name: 'Contribuyente',
            cell: row => <span> {contribuyente(row.AdTitulars)}</span>,
            sortable: false,
            wrap: true,
            style: {
                textAlign: 'justify', // Justifica el texto
            },
            ignoreRowClick: true,
            allowOverflow: true,
            hide: "md",
        },
        {
            name: 'Estado',
            cell: row => (<span>{row.Estado && row.Estado.name}</span>),
            sortable: false,
            hide: "md",
        },
        {
            name: 'Fecha Registro',
            cell: row => (<span>{formatDateDMYHIS(row.createdAt)}</span>),
            sortable: false,
            hide: "md",

        },
        {
            name: "",
            sortable: false,
            cell: (row) => (
                <div className="text-sm-start text-md-end" style={{ width: "100px" }}>
                    <span
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        className="pe-2 ps-2"
                    >
                        <i className="fas fa-ellipsis-v text-primary "></i>
                    </span>
                    <ul className="dropdown-menu dropdown-menu-right">
                        {row.Estado && (row.Estado.code === Constant[0].estado.completado || row.Estado.code === Constant[0].estado.aprobado
                            || row.Estado.code === Constant[0].estado.revisado || row.Estado.code === Constant[0].estado.certificado) && (
                                <li>
                                    <Link
                                        to="#"
                                        className="dropdown-item"
                                        onClick={(event) => handlePreviewPdfClickDDJJ(row, event)}
                                        title={`Autorización número: ${row.numero}`}
                                    >
                                        <i className="fas fa-file-pdf me-2" aria-hidden="true"></i>{" "}
                                        Ver DDJJ
                                    </Link>
                                </li>
                            )}
                        {/* // solo contribuyente */}

                        {row.Estado && (row.Estado.code === Constant[0].estado.en_proceso) && (
                            <li>
                                <Link
                                    to="#"
                                    className="dropdown-item"
                                    onClick={() => handleAprobarDatosClick(row)}
                                    title={`Aprobar los datos de la publicidad: ${row.numero}`}
                                >
                                    <i
                                        className="fa fa-unlock-alt me-2"
                                        aria-hidden="true"
                                    ></i>{" "}
                                    Confirmar Datos
                                </Link>
                            </li>
                        )}

                        {/* requisitos observados */}
                        {row.Estado && isRechazado(row.AdAdvertisingRequirementSite) && (row.Estado.code === Constant[0].estado.aprobado && esContribuyente) && (

                            <li>
                                <Link
                                    to={{
                                        pathname:
                                            Links[138].url + "/" +
                                            row.token +
                                            "/" +
                                            row.numero,
                                    }}
                                    className="dropdown-item"
                                    title={`Requisitos Observados`}
                                    id={`link-update-requirement`}
                                >
                                    <i className="fas fa-list-alt me-2 "></i>Observados

                                </Link>
                            </li>)}



                        {row.Estado && (row.Estado.code === Constant[0].estado.aprobado && esFuncionario) && (
                            <li>
                                <Link
                                    to={{
                                        pathname:
                                            Links[137].url +
                                            "/" +
                                            row.token +
                                            "/" +
                                            row.numero,
                                    }}
                                    className="dropdown-item"
                                    title={`Revisar Requisitos`}
                                >
                                    <i className="fas fa-list-alt me-2"></i> Revisar
                                </Link>
                            </li>
                        )}



                        {row.Estado && isPendienteAprobacion(row.AdAdvertisingRequirementSite) && (row.Estado.code === Constant[0].estado.aprobado && esFuncionario) && (
                            < li >
                                <Link
                                    to="#"
                                    className="dropdown-item"
                                    onClick={(event) => handleDesbloquearDatosPublicidadClick(event, row)}
                                    title={`Desbloquear publicidad`}
                                >
                                    <i className="fas fa-lock-open me-2"></i> Desbloquear
                                </Link>

                            </li>
                        )}

                        {row.Estado && (row.Estado.code === Constant[0].estado.en_proceso && (esContribuyente || esFuncionario)) && (
                            <li>
                                {
                                    <Link
                                        to={`/actividades-economicas/publicidad/editar/${row.token}`}
                                        className="dropdown-item"
                                        title={`Editar : ${row.nombre}`}
                                    // onClick={(event) => handleEliminarDatosPublicidadClick(event, row)}
                                    >
                                        <i className="fas fa-pen-square me-2"></i> Editar DDJJ

                                    </Link>
                                }
                            </li>
                        )}

                        {esFuncionario && htmlLinkAfter(row)}

                    </ul>
                </div >
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const SampleStyle = styled.div`
    padding: 10px;
    display: block;
    width: 90%;
    p {
      font-size: 12px;
      word-break: break-all;
    }
  `;

    const IntentExpandedComponent = ({ data }) => {

        /*let provider = data.provider ? JSON.parse(data.provider) : {};
        provider = provider.length > 0 ? provider[0] : undefined;*/
        //  hanldeEventExpanded(data);
        return (
            <SampleStyle>
                <div className="row">
                    <div className="col-12 col-md-4 mb-3">
                        <InfoPublicidad data={data} Texto={Texto} formatDateDMY={formatDateDMY} contribuyente={contribuyente} />

                    </div>
                    <div className="col-12 col-md-4">
                        <DatosTecnico dataTechnical={data.AdDataTechnical} actividadEconomicaRuat={data.AdActividadEconomicaRuat} Texto={Texto} periodo={formatDateDMY(data.dateStart) + " - " + formatDateDMY(data.dateExpires)} />
                    </div>

                    <div className="col-12 col-md-4 mb-3">
                        <span>
                            <strong>{Texto.autorizacion.toUpperCase()} </strong>
                        </span>
                        <hr className="mt-0" />
                        <p>
                            <strong>Número Licencia: </strong>
                            {data && data.numeroLicencia}
                        </p>
                        <p>
                            <strong>Número Autorización: </strong> {data && data.numeroAutorization}
                        </p>
                        <p>
                            <strong>Digitalizado: </strong> {data.digitalizado === 1 ? "SI" : "NO"}
                        </p>
                    </div>
                </div>
                {/* <div className="row">
                    
                </div> */}

            </SampleStyle>
        );
    };


    const handlePageChange = async (page) => {
        // by codeEStado
        setLoading(true);
        setCurrentPage(page);
        if (typeSearch === "ESTADO") {
            const query = consultaSQL.queryAdvertisingSearch(page, perPage, typeSearch, "", '"' + codeEstadoUse + '"');
            fetch
                .fetchPostGraphQl(query, "/graphql", null)
                .then((data) => {
                    debugger
                    if (data.advertisingsSearch && data.advertisingsSearch.result) {
                        debugger
                        toast.success(data.advertisingsSearch.message);
                        setLoading(false);
                        setDataAdvertisings(data.advertisingsSearch.data.advertising)
                        setTotalRows(data.advertisingsSearch.pagination.total)

                    } else {
                        toast.error(data.advertisingsSearch.message);
                    }
                });

        } else {

            const query = consultaSQL.queryAdvertisingsAll(page, perPage, listEstadoContribuyente);
            fetch
                .fetchPostGraphQl(query, "/graphql", null)
                .then((data) => {
                    debugger
                    if (data.advertisings && data.advertisings.result) {
                        debugger
                        toast.success(data.advertisings.message);
                        setLoading(false);
                        setDataAdvertisings(data.advertisings.data.advertising)
                    } else {
                        toast.error(data.advertisings.message);
                    }
                });

        }
    };


    const handlePerRowsChange = async (perPage, page) => {
        const query = consultaSQL.queryAdvertisingsAll(page, perPage, listEstadoContribuyente);
        fetch
            .fetchPostGraphQl(query, "/graphql", null)
            .then((data) => {
                debugger
                if (data.advertisings && data.advertisings.result) {
                    debugger
                    toast.success(data.advertisings.message);
                    setLoading(false);
                    setDataAdvertisings(data.advertisings.data.advertising)

                    setCurrentPage(page);
                    setPerPage(perPage);
                } else {
                    toast.error(data.advertisings.message);
                }
            });
    };

    const handleSubmitSearchForm = (event) => {
        event.preventDefault();
        setLoading(true);
        const tipoBusqueda = tipoSearchRef.current.value;
        const textoBusqueda = textoSearchRef.current.value;
        if (textoBusqueda.length > 0) {
            const query = consultaSQL.queryAdvertisingSearch(1, perPage, tipoBusqueda, textoBusqueda, listEstadoContribuyente, "", "FUNCIONARIO");
            fetch
                .fetchPostGraphQl(query, "/graphql", null)
                .then((data) => {
                    debugger
                    if (data.advertisingsSearch && data.advertisingsSearch.result) {
                        debugger
                        console.log("--------------------- >>>> handleSubmitSearchForm data.advertisingsSearch >>>> ", data.advertisingsSearch);
                        toast.success(data.advertisingsSearch.message);
                        setLoading(false);
                        setDataAdvertisings(data.advertisingsSearch.data.advertising)

                        setTotalRows(data.advertisingsSearch.pagination.total)
                    } else {
                        toast.error(data.advertisingsSearch.message);
                    }
                });
        } else {
            getDataAdvertising();
        }
    };

    const handleSearchAdvertisingByEstado = async (event, codeEstadoActual) => {
        event.preventDefault();
        setLoading(true);
        setTypeSearch("");
        setCodeEstadoUse("");
        const tipoBusqueda = "ESTADO";
        const textoBusqueda = "";

        if (codeEstadoActual.length > 0) {

            const query = consultaSQL.queryAdvertisingSearch(1, perPage, tipoBusqueda, textoBusqueda, '"' + codeEstadoActual + '"');
            fetch
                .fetchPostGraphQl(query, "/graphql", null)
                .then((data) => {
                    debugger
                    if (data.advertisingsSearch && data.advertisingsSearch.result) {
                        console.log("--------------------- >>>> data.advertisingsSearch >>>> ", data.advertisingsSearch);
                        setTypeSearch(tipoBusqueda);
                        setCodeEstadoUse(codeEstadoActual);
                        toast.success(data.advertisingsSearch.message);
                        setDataAdvertisings(data.advertisingsSearch.data.advertising)
                        setTotalRows(data.advertisingsSearch.pagination.total)
                        setLoading(false);
                    } else {
                        toast.error(data.advertisingsSearch.message);
                    }
                });
        } else {
            getDataAdvertising();
        }
    };

    // desbloquear
    const handleDesbloquearDatosPublicidadClick = (event, row) => {
        debugger
        event.preventDefault();
        window.createBootbox("¿Está seguro de desbloquear la publicidad con número de orden : " + row.numero + "?", function (result) {
            if (result === true) {
                const query = consultaSQL.queryUpdateEstadoAdvertising(row.Estado.code, Constant[0].estado.en_proceso, row.token);
                fetch
                    .fetchPostGraphQl(query, "/graphql", null)
                    .then((data) => {
                        debugger
                        if (data.updateEstadoAdvertising && data.updateEstadoAdvertising.result) {
                            toast.success(data.updateEstadoAdvertising.message);
                            setTimeout(() => {
                                getDataAdvertising();
                            }, 3000);

                        }
                        else {
                            toast.error(data.updateEstadoAdvertising.message);
                        }
                    });
            }
        });
    }

    // eliminar

    /*const handleEliminarDatosPublicidadClick = (event, row) => {
        debugger
        event.preventDefault();
        window.createBootbox("¿Está seguro de eliminar la publicidad con número de orden : " + row.numero + "?", function (result) {
            if (result === true) {
                const query = consultaSQL.deleteAdvertisingByToken(row.token);
                fetch
                    .fetchPostGraphQl(query, "/graphql", null)
                    .then((data) => {
                        debugger
                        if (data.deleteAdvertisingAndRelatedData && data.deleteAdvertisingAndRelatedData.result) {
                            toast.success(data.deleteAdvertisingAndRelatedData.message);
                            setTimeout(() => {
                                getDataAdvertising();
                            }, 5000);
                        }
                        else {
                            toast.error(data.deleteAdvertisingAndRelatedData.message);
                        }
                    });

            }
        });
    }*/

    // digitalizar archivos de la publicidad
    const handleUploadFileByDigitalizacion = async (row) => {
        try {
            debugger;
            const urlPdfRepositorio = `${Config[10].url}/`;  //src/

            // Obtener los datos de publicidad
            const rowAdvertising = await dataPublicidad(row.token);

            if (!rowAdvertising) {
                throw new Error("No se pudo obtener la información de la publicidad.");
            }

            const isCertificado = rowAdvertising.Estado.code === constant[0].estado.certificado;

            if (isCertificado && rowAdvertising.digitalizado === 0) {
                // Consultar los requisitos del sitio
                const query = consultaSQL.queryRequirementsSiteAdvertising(row.token, "REQUIREMENT");
                const data = await fetch.fetchPostGraphQl(query, "/graphql", null);

                if (!data || !data.requirementsSiteAdvertising || !data.requirementsSiteAdvertising.result) {
                    throw new Error("No se encontraron requisitos del sitio.");
                }

                const responseDataRequisitos = data.requirementsSiteAdvertising.data.advertisingRequirementSite;
                const listUrlsRequirements = responseDataRequisitos.map(objeto => ({
                    url: urlPdfRepositorio + objeto.url,
                    tipo: objeto.AdRequirementSite.typeName
                }));

                const infoObjectContribuyente = contribuyenteInfoDigitalizacion(rowAdvertising.AdTitulars);
                const tipoTramite = tipoTramitePublicidad(rowAdvertising.clasificationCategoriesAdv, parseInt(rowAdvertising.fur));

                if (Object.keys(infoObjectContribuyente).length > 0 && tipoTramite) {
                    let formData = new FormData();

                    formData.append("contribuyente[tipo]", infoObjectContribuyente.tipoContribuyente.toUpperCase());
                    if (infoObjectContribuyente.tipoContribuyente.toUpperCase() === "NATURAL") {
                        formData.append("contribuyente[extension]", infoObjectContribuyente.extension);
                        formData.append("contribuyente[nrodocumento]", infoObjectContribuyente.nrodocumento);
                        formData.append("contribuyente[nombre]", infoObjectContribuyente.nombre);
                        formData.append("contribuyente[paterno]", infoObjectContribuyente.paterno);
                        formData.append("contribuyente[materno]", infoObjectContribuyente.materno);
                    } else {
                        formData.append("contribuyente[nrodocumento]", infoObjectContribuyente.nrodocumento);
                        formData.append("contribuyente[razon_social]", infoObjectContribuyente.razon_social);
                    }

                    // info publicidad                               
                    formData.append("publicidad[numero]", rowAdvertising.numeroLicencia);
                    formData.append("publicidad[nro_autorizacion]", rowAdvertising.numeroAutorization);
                    formData.append("publicidad[tipo_tramite]", tipoTramite);
                    formData.append("publicidad[requisitos]", JSON.stringify(listUrlsRequirements)); // listUrlsRequirements

                    const digitalizacionData = await fetch.fetchPost(formData, "api/publicidad/digitalizar-archivo");

                    if (digitalizacionData && digitalizacionData.status) {
                        debugger
                        //console.log("respuesta de datos - digitaliuzacion :   ", digitalizacionData)
                        const queryUpdate = consultaSQL.queryUpdateDigitalizacionAdvertising(rowAdvertising.token);
                        const updateData = await fetch.fetchPostGraphQl(queryUpdate, "/graphql", null);
                        console.log("updateData - ", updateData);
                        if (updateData.updateFilesUploadAdvertising && updateData.updateFilesUploadAdvertising.result) {
                            debugger
                            fetch.toast.success(digitalizacionData.message);
                        } else {
                            throw new Error("Error actualizando la digitalización de archivos.");
                        }
                    } else {
                        throw new Error(digitalizacionData.message || "Error al digitalizar los archivos.");
                    }
                } else {
                    toast.error("No existen datos para realizar la digitalización.");
                }
            } else {
                console.error("No se digitalizaron los archivos de la publicidad, verifique si ya esta digitalizado.");
            }
        } catch (error) {
            console.log("catch - Ocurrió un error, vuelva a intentarlo.");
        }
    };


    const contribuyenteInfoDigitalizacion = (listaPersona) => {
        let resultado = {};

        if (Array.isArray(listaPersona) && listaPersona.length > 0) {
            let objetoTitular = listaPersona[0];
            let natural = parseInt(objetoTitular.natural);
            if (natural === 1) {
                resultado = {
                    tipoContribuyente: "NATURAL",
                    extension: objetoTitular.Persona != null ? objetoTitular.Persona.expedidoEn : "",  // expedidoEn
                    nombre: objetoTitular.Persona != null ? objetoTitular.Persona.nombre : "",
                    paterno: objetoTitular.Persona != null ? objetoTitular.Persona.apellidoPaterno : "",
                    materno: objetoTitular.Persona != null ? objetoTitular.Persona.apellidoMaterno : "",
                    nrodocumento: objetoTitular.Persona != null ? objetoTitular.Persona.numeroDocumento : "",
                }
            } else {
                debugger
                resultado = {
                    tipoContribuyente: "JURIDICA",
                    nrodocumento: objetoTitular.AdTitularJuridico != null ? objetoTitular.AdTitularJuridico.numeroDocumento : "",
                    razon_social: objetoTitular.AdTitularJuridico != null ? objetoTitular.AdTitularJuridico.razonSocial : "",
                }
            }
        }
        return resultado;
    }

    const tipoTramitePublicidad = (clasificationCategoriesAdv, fur) => {

        let resultado = null;
        const codeClasificador = clasificationCategoriesAdv.length > 0 ? clasificationCategoriesAdv[0].clasificationCategoryPublicity.clasification.code : null;

        if (codeClasificador === constant[0].publicidad_clasificador.code_empresarial && fur > 0) {
            resultado = "CRAPE";
        } else if (codeClasificador === constant[0].publicidad_clasificador.code_eventual && fur > 0) {
            resultado = "Pub. Eventual";
        } else if (codeClasificador === constant[0].publicidad_clasificador.code_actividad && fur > 0) {
            resultado = "Pub. Act. Económica";
        } else if (codeClasificador === constant[0].publicidad_clasificador.code_empresarial && (!Number(fur))) {
            resultado = "Pub. Empresarial";
        }

        return resultado;
    }

    const dataPublicidad = async (token) => {
        const query = consultaSQL.queryInfoAdvertisingByToken(token);

        try {
            const data = await fetch.fetchPostGraphQl(query, "/graphql", null);
            if (data && data.advertising && data.advertising.result) {
                // console.log('Returning advertising data:', data.advertising.data.advertising);
                return data.advertising.data.advertising;
            }
            return null;
        } catch (error) {
            console.error('Error fetching data:', error);
            return null;
        }
    }

    const handleAprobarDatosClick = (row) => {
        debugger
        window.createBootbox("¿Está seguro de continuar con la confirmación de datos de la publicidad ? \n FUR: " + row.fur + " Número:" + row.numero, function (result) {
            if (result === true) {
                const query = consultaSQL.queryUpdateEstadoAdvertising(row.Estado.code, "APROBADO", row.token);
                fetch
                    .fetchPostGraphQl(query, "/graphql", null)
                    .then((data) => {
                        debugger
                        if (data.updateEstadoAdvertising && data.updateEstadoAdvertising.result) {
                            toast.success(data.updateEstadoAdvertising.message);
                            getDataAdvertising();
                        }
                        else {
                            toast.error(data.updateEstadoAdvertising.message);
                        }
                    });

            }
        });
    }

    // observaciones de los requisitos
    const objectReviewRequirementAdvertising = (idAdvertising) => {
        const result = dataReviews.filter(item => parseInt(item.idAdvertising) === idAdvertising)
            .reduce((acc, item) => {
                acc.totalAprobado += parseInt(item.totalAprobado);
                acc.totalRechazado += parseInt(item.totalRechazado);
                return acc;
            }, { idAdvertising: idAdvertising, totalAprobado: 0, totalRechazado: 0 });
        console.log(" ------------>>>>>>> result >>> ", dataReviews, result);
        return result;
    }

    // contar total, REVISADO , PENDIENTE_APROBACION, RECHAZADO (AdAdvertisingRequirementSite)
    const requisitosPublicidadTotales = (listAdRequeriments) => {
        const pendienteAprobacion = listAdRequeriments.filter(item => item.Estado.code === "PENDIENTE_APROBACION");
        const revisado = listAdRequeriments.filter(item => item.Estado.code === "REVISADO");
        const rechazado = listAdRequeriments.filter(item => item.Estado.code === "RECHAZADO");

        // Obtener el total
        return {
            total: listAdRequeriments.length,
            pendiente: pendienteAprobacion.length,
            revisado: revisado.length,
            rechazado: rechazado.length
        }
    }
    const isPendienteAprobacion = (listAdRequeriments) => {
        const result = requisitosPublicidadTotales(listAdRequeriments);
        // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$ result isPendienteAprobacion $$$$$$$$$$$$$$$$$$$$$ ", result)
        return result.total > 0 && result.pendiente > 0 && result.total === result.pendiente
    }

    const isRechazado = (listAdRequeriments) => {
        const result = requisitosPublicidadTotales(listAdRequeriments);
        // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$ result isrechazado $$$$$$$$$$$$$$$$$$$$$ ", result)
        return result.total > 0 && result.rechazado > 0
    }
    // end  ----- AdAdvertisingRequirementSite


    /* ============================================================================================== */

    return (
        <section className="items-grid section mt-5 mb-5">
            {/* TitlePageBreadcrumb */}
            <TitlePage
                titlePage={titlePage}
                breadcrumbs={breadcrumbs}
                position={"left"}
                leftfull={false}
            />
            <div className="container mt-4">
                {/* fetch, toast, auth, history, listCodeEstado, handleSearchAdvertisingByEstado */}
                <InfoTopAdvertising
                    Links={Links}
                    fetch={fetch}
                    toast={toast}
                    history={history}
                    auth={auth}
                    listCodeEstado={listEstadoContribuyenteByEstado}
                    handleSearchAdvertisingByEstado={handleSearchAdvertisingByEstado}
                    dataAdvertisingEstados={dataAdvertisingEstados}
                />

                <form
                    action=""
                    className=""
                    name={idFormSearch}
                    id={idFormSearch}
                    method="post"
                    noValidate
                    onSubmit={handleSubmitSearchForm}
                >
                    <div className="d-flex flex-row-reverse">
                        <div className="p-2">
                            <div className="input-group mb-3">
                                <select
                                    className="form-select "
                                    name="type"
                                    ref={tipoSearchRef}
                                >
                                    <option key={1} value="NUMERO">
                                        Número de Orden
                                    </option>
                                    <option key={0} value="FUR">
                                        Número de FUR
                                    </option>
                                </select>
                                <input
                                    type="text"
                                    name="search"
                                    id="search"
                                    className="form-control"
                                    placeholder="FUR/NUMERO"
                                    aria-label="search"
                                    ref={textoSearchRef}
                                />
                                <button
                                    className="btn btn-outline-secondary"
                                    type="submit"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Buscar"
                                >
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

                <DataTable

                    data={dataAdvertisings}
                    columns={columnAdvertising}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    highlightOnHover
                    noDataComponent={Texto.there_are_no_records_to_display}
                    progressComponent={<CustomLoader />}
                    expandableRowsComponent={<IntentExpandedComponent />}
                    expandableRows
                    responsive
                    striped
                    dense
                />
            </div>

            <ModalPdf />

            <ToastContainer
                enableMultiContainer
                containerId={"Z"}
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
            <ToastContainer />
        </section >
    );
};

export default ListPublicidadDDJJ;
