import React, { useState, useEffect } from "react";
import Links from "../../data/link";
import Config from "../../data/config";
import Constant from "../../data/constant";
import { toast } from "react-toastify";
import HeaderTitlecard from "./_common/HeaderTitleCard";
import Consulta from "./Consulta";

const StepFormCongratulation = (props) => {
    const { fetch, backClick, auth, history, stepNumber, advertisingDb, dataForm } = props;

    const [isDataConfirm, setIsDataConfirm] = useState(false);
    const [isCheckDDJJ, setIsCheckDDJJ] = useState(false);

    const consultaSQL = new Consulta();

    useEffect(() => {
        debugger;
        if (!auth.loggedIn()) history.replace(Links[4].url);
        setIsCheckDDJJ(false);

    }, [dataForm.admision, dataForm.datosTecnicos, dataForm.requirement, dataForm.condition, dataForm.prohibition, dataForm.propietario]);

    const getDDJJUrl = () => {
        debugger
        console.log("******************************************");
        return `${Config[10].url}/api/advertising/report-ddjj/${advertisingDb.numero}/${advertisingDb.token}/ddjj?report=preview&auth=${auth.getToken()}`;
    };

    const viewDDJJ = (event) => {
        event.preventDefault();
        window.open(getDDJJUrl(), '_blank').focus();
        setTimeout(() => setIsCheckDDJJ(true), 2000);
    };

    const handleConfirmData = async (event) => {

        event.preventDefault();
        setIsDataConfirm(false);
        window.createBootbox("¿Está seguro de continuar con la operación? Esta acción bloqueará la Declaración Jurada.", async (result) => {
            if (result && advertisingDb) {
                debugger;
                const query = consultaSQL.queryUpdateEstadoAdvertising(Constant[0].estado.en_proceso, Constant[0].estado.aprobado, advertisingDb.token);
                try {
                    const data = await fetch.fetchPostGraphQl(query, "/graphql");
                    if (data.updateEstadoAdvertising?.result) {
                        debugger;
                        toast.success(data.updateEstadoAdvertising.message);
                        setIsDataConfirm(true);
                    } else {
                        toast.error(data.updateEstadoAdvertising.message);
                    }
                } catch (error) {
                    toast.error("Ocurrió un error al intentar completar DDJJ");
                }
            } else {
                toast.error("Se canceló la confirmación de datos de DDJJ.");
            }
        });
    };

    const printDDJJ = (event) => {
        event.preventDefault();
        window.open(getDDJJUrl(), '_blank').focus();
    };


    const handleCloseDDJJ = (event) => {
        event.preventDefault();
        setTimeout(() => history.push('/actividades-economicas/publicidad/lista'), 1000);
    };

    return (
        <div className="main-wizard">
            <HeaderTitlecard titulo="Vista Previa" descripcion={Constant[0].publicidad_step_new[5]} visibleRequired={false} />

            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>

            <div className="text-wizard congrats">
                <h2>¡Felicidades!</h2>
                <h5>Concluyó con éxito el llenado de la Declaración Jurada.</h5>
            </div>
            <br />

            <div className="row mt-5 ml-5 mr-5">
                {!isDataConfirm ? (
                    <>
                        <div className="col-sm-12 col-md-4 mb-2 text-center">
                            <button className="btn btn-fucsia btn-md" onClick={viewDDJJ} title="Revisar DDJJ">
                                <i className="fas fa-book-reader me-2"></i> Revisar DDJJ
                            </button>
                        </div>
                        {isCheckDDJJ && (
                            <>
                                <div className="col-sm-12 col-md-4 mb-2 text-center">
                                    <button className="back_button btn btn-cyan btn-md" onClick={backClick}>
                                        <i className="fas fa-pencil-alt me-2"></i> Editar Datos
                                    </button>
                                </div>
                                <div className="col-sm-12 col-md-4 mb-2 text-center">
                                    <button className="btn btn-violeta btn-md" onClick={handleConfirmData} title="Confirmar Datos de la DDJJ">
                                        <i className="fas fa-check-circle me-2"></i> Confirmar Datos
                                    </button>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <div className="col-sm-12 col-md-6 text-center mb-2">
                            <button className="btn btn-fucsia btn-md" onClick={printDDJJ}>
                                <i className="fas fa-print me-2"></i> Imprimir DDJJ
                            </button>
                        </div>
                        <div className="col-sm-12 col-md-6 text-center">
                            <button className="btn btn-violeta btn-md" onClick={handleCloseDDJJ}>
                                <i className="fas fa-sign-out-alt me-2"></i> Salir
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default StepFormCongratulation;
