import React, { Component, useState } from 'react';
//import PropTypes from 'prop-types';
import Iframe from 'react-iframe'
//import Fetch from './Fetch';
//import Constant from '../../data/constant';
import { Link } from 'react-router-dom';
import Config from '../../data/config';
//import AuthService from '../../components/Usuario/AuthService';
import { withStuffFunction } from '../utils/WithStuff'

var _id_modal = "modalPdfBasic"
class ModalPdfBasic extends Component {

    modalCategoriaRef = React.createRef()
    constructor(props) {
        super(props);

        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.hanldeDownloadPdf = this.hanldeDownloadPdf.bind(this)
        this.state = {
            urlOpenFile: '',
        };

        this.cssFullScreen = "modal-lg";
        if (props.isFullScreen)
            this.cssFullScreen = " modal-fullscreen "
    }

    handleCloseModal(event) {
        this.openModal(false, '')
    }

    hanldeDownloadPdf = (event) => {
        event.preventDefault()
        window.location.href = Config[0].url + `${this.state.urlOpenFile}`;
    }

    openModal = (open, url_open, url_download) => {
        let self = this
        if (open) {
            window.jQuery("#" + _id_modal).modal("show")

            window.jQuery('#' + _id_modal).on('shown.bs.modal', function () {
                document.getElementById('iframeModalPdfBasic').src = Config[0].url + url_open
                self.setState({ urlOpenFile: url_download })
            });
        } else {
            window.jQuery("#modalPdfBasic").modal("hide")
        }
    }
    render() {
        return (
            <div className={`modal fade `} id={_id_modal} tabIndex="-1" aria-labelledby={_id_modal} aria-hidden="true" data-keyboard="false" >
                <div className={`modal-dialog ${this.cssFullScreen}`} style={{marginLeft: -15 }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close me-1" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 embed-container">
                                    <Iframe url={''}
                                        width="640"
                                        height="360"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        id="iframeModalPdfBasic"
                                        display="initial"
                                        onLoad={this.handleOnlad}
                                    />
                                </div>
                            </div>

                            <br /><br />
                            <div className="row">
                                <div className="col-11 col-md-11 col-lg-11 paddingTop15">
                                    <p>En caso no se visualize el pdf, puedes
                                    <Link to="#" onClick={this.hanldeDownloadPdf} title="Descargar" style={{ paddingLeft: '5px' }} >
                                            hacer click aquí para descargar el archivo PDF.
                                    </Link>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withStuffFunction(ModalPdfBasic);