import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom'
//import AsyncSelect from 'react-select/async';
import Texto from '../../data/es';
import Config from '../../data/config';
//import Constant from '../../data/constant';
//import Fetch from '../utils/Fetch';
import Links from '../../data/link';
import TitlePage from '../utils/TitlePage';

const InfraccionesGamc = (props) => {

    const titlePage = Texto.infracciones_sanciones
    const history = useHistory();

    const [showInitPage, setShowInitPage] = useState(true);
    //const [showContainerMercados, setShowContainerMercados] = useState(false);
    //const [showContainerPublicidadExterior, setShowContainerPublicidadExterior] = useState(false);

    const [breadcrumbs, setBreadcrumbs] = useState([
        {
            title: Links[0].title,
            url: Links[0].url,
        },
        {
            title: Links[77].title,
            url: Links[77].url,
        }
    ]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleShowVehiculo = (event) => {
        event.preventDefault()
        //history.push(Links[59].url);
    }

    const handleShowInmueble = (event) => {
        event.preventDefault()
        //history.push(Links[57].url);
    }

    const handleShowActividadEconomica = (event) => {
        event.preventDefault()
        //history.push(Links[60].url);
    }

    const handleShowMedioAmbiente = (event) => {
        event.preventDefault()
        //history.push(Links[60].url);
    }

    return (
        <section className="items-grid section mt-5">
            <div className="container mt-1">
                <TitlePage titlePage={titlePage} breadcrumbs={breadcrumbs} position={'left'} leftfull={false} />

                {showInitPage ?
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                            <div className="single-news wow fadeInUp" data-wow-delay=".2s" onClick={handleShowVehiculo}>
                                <div className="image">
                                    <img className='thumb' alt='img-inf-san-vehiculo' src={Config[2].url + '/static/img/bt_vehiculo.png'} />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                            <div className="single-news wow fadeInUp" data-wow-delay=".2s" onClick={handleShowInmueble}>
                                <div className="image">
                                    <img className='thumb' alt='img-inf-san-inmueble' src={Config[2].url + '/static/img/bt_inmuebles.png'} />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                            <div className="single-news wow fadeInUp" data-wow-delay=".2s" onClick={handleShowActividadEconomica}>
                                <div className="image">
                                    <img className='thumb' alt='img-inf-san-actividades-economicas'
                                        src={Config[2].url + '/static/img/bt_actividad_economica.png'} />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                            <div className="single-news wow fadeInUp" data-wow-delay=".2s" onClick={handleShowMedioAmbiente}>
                                <div className="image">
                                    <img className='thumb' alt='img-img-inf-san-medio-ambiente'  src={Config[2].url + '/static/img/bt_medio_ambiente.png'} />
                                </div>
                            </div>
                        </div>
                    </div> : ""}
            </div>
        </section>
    )
}

export default InfraccionesGamc;