import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fetch from "../utils/Fetch";
import DataTecnicos from "../component/CardInmueble/CardDataTecnicos";
import DataAdministration from "../component/CardInmueble/CardDataAdministration";
import PasosNavigationVisacion from "../utils/PasosNavigationVisacionMinutas";
import ReglasValidacion from "./ReglasValidacion";
import DefaultInmuebleCatastro from "./../VisacionMinutas/DefaultCatatroInmueble";

const FormAdministrativosLegales = (props) => {
  const fetch = new Fetch();
  fetch.setToast(toast);
  const { dataInmueble, visacionMinutaDb, onSubmitForm } = props;

  const [dataPropietario, setDataPropietario] = useState(undefined);
  const [ruleValidation, setRuleValidation] = useState(undefined);
  const [dataGeoEspacial, setDataGeoEspacial] = useState(undefined);
  const [dataServicesBasic, setDataServicesBasic] = useState(undefined);
  const [dataMaterialVia, setDataMaterialVia] = useState(undefined);
  const [dataTecnicosInmueble, setDataTecnicosInmueble] = useState(undefined);
  const [dataInmuebleCatastro, setDataInmuebleCatastro] = useState(undefined);

  useEffect(() => {
    loadInmuebleRuat();
    window.scrollTo(0, 0);
  }, []);

  const loadInmuebleRuat = async () => {
    await loadInmuebleCatastro(
      dataInmueble.codigoCatastral,
      dataInmueble.tipoVisacionMinuta
    );
    if (visacionMinutaDb) {
      setDataPropietario(dataInmueble.propietario);
      setDataTecnicosInmueble(dataInmueble.dataTecnico);
    } else {
      setDataPropietario(dataInmueble.propietario);
      const form = new FormData();
      form.append("propiedades[codigoPropiedad]", dataInmueble.codigoPropiedad);
      form.append("propiedades[codigoInmueble]", dataInmueble.codigoInmueble);
      fetch
        .fetchPost(form, `api-ruat/inmuebles/datos-tecnicos`, undefined)
        .then((dataJson) => {
          if (dataJson && dataJson.status)
            setDataTecnicosInmueble(dataJson.data);
        });
    }
  };

  const loadInmuebleCatastro = async (codigoCatrastral, tipoVisacionMinuta) => {
    debugger;
    const resInmuebleCatastro = await fetch.fetchGet(
      `api-ruat/inmuebles-catastro/codigo-catastral/${codigoCatrastral}`
    );
    if (resInmuebleCatastro && resInmuebleCatastro.status) {
      if (resInmuebleCatastro.data.inmueble.length > 0)
        setDataInmuebleCatastro(resInmuebleCatastro.data.inmueble[0]);
      else {
        debugger;
        if (tipoVisacionMinuta.code === "PROGRAMAS_CASA_SEGURA_MI_PLANO") {
          if (visacionMinutaDb) {
            const resInmuebleCatastro = await fetch.fetchGet(
              `api-ruat/inmuebles-catastro/codigo-catastral-local/${codigoCatrastral}/${visacionMinutaDb.token}`
            );
            if (resInmuebleCatastro && resInmuebleCatastro.status) {
              if (resInmuebleCatastro.data.inmueble.length > 0)
                setDataInmuebleCatastro(resInmuebleCatastro.data.inmueble[0]);
            }
          } else {
            const defaultInmueble = DefaultInmuebleCatastro;
            defaultInmueble.codigoCatastral = codigoCatrastral.replaceAll(
              /-/g,
              ""
            );

            const datoTecnico = dataInmueble.dataTecnico;
            if (datoTecnico.datosGenerales.clase !== "PROPIEDAD HORIZONTAL") {
              defaultInmueble.informesLegal[0].superficie =
                datoTecnico.terreno[0].superficeM2; //para el caso de VU, TE
            }

            //valido solo para php, que tienen registro de matris, no debe aplicar a mi casa segura
            if (datoTecnico.datosGenerales.clase === "PROPIEDAD HORIZONTAL") {
              defaultInmueble.informesTecnico[0]
                                .informesTecnicosConstruccion[0]
                                .superficieConstruida = datoTecnico.construcciones[0].superficieM2;
            }

            const message = ReglasValidacion.ruleValidation(
              undefined,
              undefined,
              "FECHA_APROBACION_PLANO"
            );

            setRuleValidation(message);
            setDataInmuebleCatastro(defaultInmueble);
          }
        } else ruleValidationAdministrativos("", "", "GLOBAL_CATASTRO");
      }
    }
  };

  const submitFormAdministrativos = (event) => {
    debugger
    if (
      dataInmueble.tipoVisacionMinuta.code === "PROGRAMAS_CASA_SEGURA_MI_PLANO"
    ){
      if(dataInmueble.dataTecnico.datosGenerales.clase !== "PROPIEDAD HORIZONTAL"){
        dataInmuebleCatastro.informesTecnico[0].superficiePredio =
        dataInmueble.dataTecnico.terreno[0].superficeM2;
      }
    }
      
    const data = {
      inmueble: dataInmueble,
      propiedad: {
        porcentaje: dataInmueble.porcentaje,
        codigoPropiedad: dataInmueble.codigoPropiedad,
        codigoContribuyente: dataInmueble.codigoContribuyente,
        estadoPropiedad: dataInmueble.estadoPropiedad,
        fechaInicioPropiedad: dataInmueble.fechaInicioPropiedad,
        fechaFinPropiedad: dataInmueble.fechaFinPropiedad,
        propietario: dataInmueble.propietario,
      },
      datosTecnicosRuat: dataTecnicosInmueble,
      datosTecnicosCatastroCbba: dataInmuebleCatastro,
      dataGeoEspacial: dataGeoEspacial,
      dataServiceBasic: dataServicesBasic,
      dataMaterialVia: dataMaterialVia,
    };
    onSubmitForm(event, data);
  };

  const onEventData = (dataGeoEspacial) => {
    setDataGeoEspacial(dataGeoEspacial);
  };

  const onEventDataService = (dataService) => {
    setDataServicesBasic(dataService);
  };

  const onEventDataMaterialVia = (dataMaterialVia) => {
    setDataMaterialVia(dataMaterialVia);
  };

  const ruleValidationAdministrativos = (
    textInputRuat,
    textInputCatastro,
    typeValidacion
  ) => {
    const message = ReglasValidacion.ruleValidation(
      textInputRuat,
      textInputCatastro,
      typeValidacion
    );
    if (message) setRuleValidation(message);
  };

  /*para los programas mi casa segura, mi plano, etc */
  const handleInputProgramas = (e, data) => {
    e.preventDefault();
    let message = "";
    if (data && data.matricula) {
      dataInmuebleCatastro.informesLegal[0].matricula = data.matricula;
      message = ReglasValidacion.ruleValidation(
        undefined,
        data.matricula,
        "MATRICULA"
      );
    }

    if (data && data.asiento) {
      dataInmuebleCatastro.informesLegal[0].asiento =
        data.asiento.toUpperCase();
      message = ReglasValidacion.ruleValidation(
        undefined,
        data.asiento,
        "ASIENTO"
      );
    }

    const informeTecnico = dataInmuebleCatastro.informesTecnico[0];
    if (data && data.fecha_aprobacion_plano) {
      const dateAproPlano = window
        .moment(data.fecha_aprobacion_plano, "DD/MM/YYYY")
        .format("YYYY-MM-DD");
      informeTecnico.fechaAprobacionPlano = dateAproPlano;
      message = ReglasValidacion.ruleValidation(
        undefined,
        dateAproPlano,
        "FECHA_APROBACION_PLANO"
      );
    }
    if (data && data.fecha_resolucion_plano) {
      const dateResolucionPlano = window
        .moment(data.fecha_resolucion_plano, "DD/MM/YYYY")
        .format("YYYY-MM-DD");
      informeTecnico.fechaResolucionPlano = dateResolucionPlano;
      message = ReglasValidacion.ruleValidation(
        undefined,
        dateResolucionPlano,
        "FECHA_RESOLUCION_PLANO"
      );
    }
    if (data && data.rta_nro) {
      informeTecnico.nroResolucionMunicipal = data.rta_nro;
      message = ReglasValidacion.ruleValidation(
        undefined,
        data.rta_nro,
        "NRO_RESOLUCION_MUNICIPAL"
      );
    }

    if (data && data.colindateEste) {
      informeTecnico.informesTecnicoColindancia[0].descripcionColindancia =
        data.colindateEste.toUpperCase(); //este
      message = ReglasValidacion.ruleValidation(
        undefined,
        data.colindateEste,
        "COLINDANTE_ESTE"
      );
    }
    if (data && data.colindateOeste) {
      informeTecnico.informesTecnicoColindancia[1].descripcionColindancia =
        data.colindateOeste.toUpperCase(); //oeste
      message = ReglasValidacion.ruleValidation(
        undefined,
        data.colindateOeste,
        "COLINDANTE_OESTE"
      );
    }
    if (data && data.colindateSud) {
      informeTecnico.informesTecnicoColindancia[2].descripcionColindancia =
        data.colindateSud.toUpperCase(); //sud
      message = ReglasValidacion.ruleValidation(
        undefined,
        data.colindateSud,
        "COLINDANTE_SUD"
      );
    }
    if (data && data.colindateNorte) {
      informeTecnico.informesTecnicoColindancia[3].descripcionColindancia =
        data.colindateNorte.toUpperCase(); //norte
      message = ReglasValidacion.ruleValidation(
        undefined,
        data.colindateNorte,
        "COLINDANTE_NORTE"
      );
    }
    setRuleValidation(message);
    if (isDataCatastroComplete(dataInmuebleCatastro)) {
      setRuleValidation("");
    }
  };

  const isDataCatastroComplete = (dataInmuebleCatastro) => {
    if (!dataInmuebleCatastro.informesLegal[0].matricula) return false;
    if (!dataInmuebleCatastro.informesLegal[0].asiento) return false;
    const informeTecnico = dataInmuebleCatastro.informesTecnico[0];
    if (!informeTecnico.fechaAprobacionPlano) return false;
    if (!informeTecnico.nroResolucionMunicipal) return false;
    const colindantes = informeTecnico.informesTecnicoColindancia;
    if (!colindantes[0].descripcionColindancia) return false;
    if (!colindantes[1].descripcionColindancia) return false;
    if (!colindantes[2].descripcionColindancia) return false;
    if (!colindantes[3].descripcionColindancia) return false;
    return true;
  };

  return (
    <>
      <PasosNavigationVisacion
        nro_inmueble={dataInmueble.numeroInmueble}
        paso1_active={true}
        paso2_active={false}
        paso3_active={false}
        paso4_active={false}
        paso5_active={false}
      />

      <form
        action=""
        className="contact__form needs-validation"
        name="formDataInmueble"
        id="formDataInmueble"
        method="post"
        noValidate
        onSubmit={submitFormAdministrativos}
        style={{ width: "100%" }}
      >
        {dataInmueble && dataInmuebleCatastro ? (
          <DataAdministration
            dataInmueble={dataInmueble}
            dataPropietario={dataPropietario}
            dataInmuebleCatastro={dataInmuebleCatastro}
            fetch={fetch}
            onEventData={onEventData}
            ruleValidation={ruleValidationAdministrativos}
            isVisacionMinuta={true}
            handleInputProgramas={handleInputProgramas}
          />
        ) : (
          ""
        )}

        {dataTecnicosInmueble && dataInmuebleCatastro ? (
          <DataTecnicos
            dataInmueble={dataInmueble}
            dataTecnicosInmueble={dataTecnicosInmueble}
            dataInmuebleCatastro={dataInmuebleCatastro}
            fetch={fetch}
            nameFormInmueble={"inmueble"}
            nameFormConstruccion={"construccion"}
            nameFormConstruccionAdicional={"construccionAdicional"}
            nameFormDireccionInmueble={"inmueble_direccion"}
            onEventDataService={onEventDataService}
            onEventDataMaterialVia={onEventDataMaterialVia}
            ruleValidation={ruleValidationAdministrativos}
            isVisacionMinuta={true}
          />
        ) : (
          ""
        )}

        {ruleValidation ? (
          <div className="row">
            <div className="col-12 text-start">
              <div className="alert alert-danger text-uppercase" role="alert">
                {ruleValidation}
              </div>
            </div>

            {dataInmueble.tipoVisacionMinuta.code !==
            "PROGRAMAS_CASA_SEGURA_MI_PLANO" ? (
              <div className="col-12 text-start">
                <div
                  className="alert alert-warning text-uppercase"
                  role="alert"
                >
                  {
                    "SI EL INMUEBLE OBJETO DE TRANSFERENCIA SE ENCUENTRA EN EL PROGRAMA DE REGULACIÓN SELECCIONE LA OPCIÓN 'Compra Venta - Prog. Regularación (Mi Casa Segura, Arco y Otros)'"
                  }
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-ms-6 col-md-6 col-lg-7 text-end"></div>
            <div className="col-12 col-ms-3 col-md-3 col-lg-3 text-end"></div>

            <div className="col-12 col-ms-3 col-md-3 col-lg-2 text-end">
              <div className=" search-btn button mb-3 pointer">
                <input
                  type="Submit"
                  className="btn btn-full-screen"
                  defaultValue={"Siguiente"}
                  name="Siguiente"
                />
              </div>
            </div>
          </div>
        )}
      </form>

      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  );
};

export default FormAdministrativosLegales;
