import React, { Component } from "react";
import axios from "axios";
import Fetch from "../utils/Fetch";
import Texto from "../../data/es";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Config from "../../data/config";
import Link from "../../data/link";

var bounds = [802222.4138964778, 8075257.386522729];
var datos_distrito = {};
var projection;
var container = undefined;
var content = undefined;
var closer = undefined;
var capa_predios = undefined;
var capa_vias = undefined;
var _cod_cat = undefined;
var _is_ruat_or_cod_cat = false;
var vectorSource = undefined;
var vectorLayer = undefined;
var _map = undefined;
const markerBlue = Config[2].url + "/static/img/marker_blue.png";
const waterMarkImage = Config[2].url + "/static/img/logo_atm_water_mark.png";

class MapCatastroPublic extends Component {
  constructor(props, context) {
    super(props, context);

    this.domicilioActividadEconomicaDb = null;

    this.id_modal = "modalMapCatastro";
    this.url_root = Link[29].url;
    this.url_root_search = Link[30].url;

    this.format = "image/png";

    this.predio = 0;
    this.catastro = 0;
    this.latitud = 0;
    this.longitud = 0;
    this.coordinate = "";
    this.zona = 0;
    this.comuna = "";
    this.distrito = "";
    this.sub_distrito = "";
    this.num_inmueble = 0;

    this.handleCloseOnClick = this.handleCloseOnClick.bind(this);
    this.handleConfirmOnClick = this.handleConfirmOnClick.bind(this);
    this.handleChangePosition = this.handleChangePosition.bind(this);

    this.state = { center: [0, 0], zoom: 18 };
    this.fetch = new Fetch();
    this.fetch.setToast(toast);
  }

  componentDidMount() {
    container = document.getElementById("popup");
    content = document.getElementById("popup-content");
    closer = document.getElementById("popup-closer");
    this.renderMap();

    window.jQuery(".linkHelpMAE").popover({
      title:
        '<h3 class="custom-title"><i class="fa fa-map-marker"></i> Ayuda</h3>',
      content:
        '<p><img src="/static/img/plus_zoom.jpg" class="rounded z-depth-0" alt="zoom plus" width="18px" /> Ampliar la imagen del mapa.<br/>' +
        '<img src="/static/img/minus_zoom.jpg" class="rounded z-depth-0" alt="zoom minus" width="18px" /> Reducir la imagen del mapa.<br/>' +
        '<img src="/static/img/full_screen.jpg" class="rounded z-depth-0" alt="zoom full" width="18px" /> Mapa en pantalla completa.<br/>' +
        '<img src="/static/img/search_map.jpg" class="rounded z-depth-0" alt="zoom search" width="18px" /> Buscar en el mapa por: <br/>' +
        "<strong>Unifamilar(es)</strong><br/>" +
        "&nbsp;&nbsp;<strong>Nro. Inmuble RUAT:</strong> 12XXX <br/>" +
        "&nbsp;&nbsp;<strong>Cod. Cat:</strong> 24-XXX-XXX-X-XX.... <br/>" +
        "&nbsp;&nbsp;<strong>Vías:</strong> Av. Ayacucho</p>" +
        "<strong>Propiedades Horizontales</strong> <br/>" +
        "&nbsp;&nbsp;<strong>Cod. Cat:</strong> 12-XXX-XXX-X <br/>" +
        "&nbsp;&nbsp;<strong>Vías:</strong> Av. Ayacucho</p>",
      html: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    this.map.setTarget("map");
    this.map.getView().setZoom(this.state.zoom);
  }

  handleCloseOnClick(event) {
    event.preventDefault();

    this.predio = 0;
    this.catastro = 0;
    this.latitud = 0;
    this.longitud = 0;
    this.coordinate = "";
    this.zona = 0;
    this.comuna = "";
    this.distrito = "";
    this.sub_distrito = "";
    this.num_inmueble = 0;

    document.getElementById("mapUbicacionActividadEconomica").innerHTML = "";
  }

  handleConfirmOnClick(event) {
    event.preventDefault();
    if (Number(this.latitud) && Number(this.longitud)) {
      if (module) {
        if (this.props.module === "actividad_economica") {
          window
            .jQuery("input[name='actividad_economica[predio]']")
            .val(this.predio);
          window
            .jQuery("input[name='actividad_economica[catastro]']")
            .val(this.catastro);

          window
            .jQuery("input[name='domicilio_actividad_economica[latitud]']")
            .val(this.latitud);
          window
            .jQuery("input[name='domicilio_actividad_economica[longitud]']")
            .val(this.longitud);
          window
            .jQuery("input[name='domicilio_actividad_economica[coordinate]']")
            .val(this.coordinate);
          window
            .jQuery("input[name='domicilio_actividad_economica[zona]']")
            .val(this.zona);

          window
            .jQuery("input[name='actividad_economica[comuna]']")
            .val(this.comuna);
          window
            .jQuery("input[name='actividad_economica[distrito]']")
            .val(this.distrito);
          window
            .jQuery("input[name='actividad_economica[sub_distrito]']")
            .val(this.sub_distrito);
          window
            .jQuery("input[name='actividad_economica[num_inmueble]']")
            .val(this.num_inmueble);

          window.jQuery("#spanDistrito").text(this.distrito);
          window.jQuery("#spanSubDistrito").text(this.sub_distrito);
          window.jQuery("#spanComuna").text(this.comuna);
          window.jQuery("#spanZonaTributaria").text(this.zona);

          var self = this;
          /////
          const canvasTemp = document.getElementById("catastroPublicCanva");
          this.mapToCanvas(this.map, canvasTemp)
            .then(() => {
              canvasTemp.toBlob(
                (blob) => {
                  debugger;
                  if (blob) {
                    const reader = new FileReader();
                    reader.onload = () => {
                      const dataUrl = reader.result;
                      const base64 = dataUrl.split(",")[1];
                      const dataImageURL = dataUrl;
                      document.getElementById(
                        "domicilio_actividad_economica[image]"
                      ).src = dataImageURL; //input
                      document.getElementsByName(
                        "domicilio_actividad_economica[image]"
                      )[0].value = base64; //input
                      document.getElementById(
                        "mapUbicacionActividadEconomica"
                      ).innerHTML = "";
                      //_mapHidden = undefined;
                      window.jQuery("#" + self.id_modal).modal("hide");
                      window.scrollTo(0, 0);
                      debugger;
                    };
                    reader.readAsDataURL(blob);
                  }
                },
                "image/jpeg",
                0.6
              );
            })
            .catch(function (e) {
              console.log(e); // "oh, no!"
            });

          /*this.captureImage((dataUrl) => {
            //const dataUrl = reader.result;
            const base64 = dataUrl.split(",")[1];
            const dataImageURL = dataUrl;
            document.getElementById(
              "domicilio_actividad_economica[image]"
            ).src = dataImageURL; //input
            document.getElementsByName(
              "domicilio_actividad_economica[image]"
            )[0].value = base64; //input
            document.getElementById(
              "mapUbicacionActividadEconomica"
            ).innerHTML = "";
            //_mapHidden = undefined;
            window.jQuery("#" + self.id_modal).modal("hide");
            window.scrollTo(0, 0);
            debugger;
          });*/
        }
      }
    } else {
      toast.warn(Texto.debe_seleccionar_predio_mapa, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }

  /////////////////////////
  mapToCanvas(map, canvaNodeHtml) {
    let ctx;
    ctx = canvaNodeHtml.getContext("2d");
    ctx.clearRect(0, 0, canvaNodeHtml.width, canvaNodeHtml.height);

    debugger;
    const widthViewPort = document.documentElement.clientWidth;

    if (widthViewPort <= 960) {
      //solo si es mobile
      canvaNodeHtml.width = map.getSize()[0]; //    map.width;
      canvaNodeHtml.height = map.getSize()[1]; //map.height;
    } else {
      canvaNodeHtml.width = this.props.width
        ? this.props.width
        : map.getSize()[0]; //    map.width;
      canvaNodeHtml.height = this.props.height
        ? this.props.height
        : map.getSize()[1]; //map.height;
    }

    const arrayImages = [],
      requests = [];

    const sourcePredio = capa_predios.getSource();
    let urlPredio = sourcePredio.M.i;
    //const dimension = sourcePredio.B;
    //const sourceVias = capa_vias.getSource();
    //let urlVias = sourceVias.M.i;
    //const dimensionVias = sourceVias.B;

    map
      .getLayers()
      .getArray()
      .forEach((layer) => {
        const tileLayer = layer instanceof window.ol.layer.Tile;
        if (tileLayer) {
          let arrayTw = layer.getSource().a.a;
          arrayTw = Object.entries(arrayTw);
          arrayTw = arrayTw[0];

          let objectImageRa = undefined;
          if (typeof arrayTw[0] === "object") {
            objectImageRa = arrayTw[0];
          }
          if (typeof arrayTw[1] === "object") {
            objectImageRa = arrayTw[1];
          }
          debugger;

          if (objectImageRa) {
            urlPredio = urlPredio.split("BBOX=");
            let urlImageBase = objectImageRa.Pc.l;

            const positionStartDimension = urlImageBase.indexOf("WIDTH");
            const positionEndDimension = urlImageBase.indexOf("&SRS");

            let dimensionString = urlImageBase.substring(
              positionStartDimension,
              positionEndDimension
            );
            dimensionString = dimensionString.split("&");
            urlImageBase = urlImageBase
              .replace(dimensionString[0], "WIDTH=" + canvaNodeHtml.width)
              .replace(dimensionString[1], "HEIGHT=" + canvaNodeHtml.height);

            /*urlImageBase = urlImageBase
              .replace("WIDTH=256", "WIDTH=" + dimension[0]  )
              .replace("HEIGHT=256", "HEIGHT=" + dimension[1] );*/

            urlImageBase = urlImageBase.split("BBOX=");
            arrayImages.push(urlImageBase[0] + "BBOX=" + urlPredio[1]);
          }
        }
      });

    // Loop through map layers
    arrayImages.forEach(function (imageUrl) {
      const promise = new Promise((resolve, reject) => {
        const image = new Image(map.width, map.height);
        image.crossOrigin = "anonymous";
        image.addEventListener("load", () => {
          resolve(image);
        });
        image.addEventListener("error", function (errorEvent) {
          reject({
            error: errorEvent,
            image: image,
          });
        });
        image.src = imageUrl;
      });
      requests.push(promise);
    });

    const deferredPing = this.addPingImage(markerBlue);
    requests.push(deferredPing);
    const waterMark = this.addPingImage(waterMarkImage);
    requests.push(waterMark);

    debugger;
    return Promise.all(requests).then(
      (images) => {
        images.forEach((image, index) => {
          if (image.src.includes("marker_blue")) {
            const x = ctx.canvas.width / 2 - (image.width - image.width / 5);
            const y = ctx.canvas.height / 2 - (image.height - image.height / 5);
            ctx.drawImage(image, x, y);
          } else {
            if (image.src.includes("logo_atm_water_mark")) {
              const y = ctx.canvas.height - image.height;
              ctx.drawImage(image, 0, y);
            } else ctx.drawImage(image, 0, 0);
          }
        });
        ctx.save();
      },
      (error) => {
        debugger;
        console.error(error);
      }
    );
  }

  addPingImage(urlImagePing) {
    const promise = new Promise((resolve, reject) => {
      const imgPing = new Image();
      imgPing.addEventListener(
        "load",
        function () {
          resolve(imgPing);
        },
        false
      );
      imgPing.addEventListener("error", function (errorEvent) {
        reject({
          error: errorEvent,
          image: imgPing,
        });
      });
      imgPing.src = urlImagePing;
    });
    return promise;
  }

  captureImage(coordinate, callback) {
    _map.getView().setCenter(coordinate);
    _map.updateSize();
    setTimeout(() => {
      debugger;
      const canvasTemp = document.getElementById("catastroPublicCanva");
      this.mapToCanvas(_map, canvasTemp)
        .then(() => {
          canvasTemp.toBlob(
            (blob) => {
              if (blob) {
                const reader = new FileReader();
                reader.onload = () => {
                  callback(reader.result);
                };
                reader.readAsDataURL(blob);
              }
            },
            "image/jpeg",
            0.6
          );
        })
        .catch(function (e) {
          console.log(e); // "oh, no!"
        });
    }, 800);
  }
  ///////////////////////
  renderMap() {
    var capa_base = new window.ol.layer.Tile({
      source: new window.ol.source.TileWMS({
        url: this.url_root + "imagenes/imagen2019_500/MapServer/WMSServer",
        params: {
          LAYERS: "0",
          VERSION: "1.1.1",
          FORMAT: "image/png",
          TILED: true,
        },
        serverType: "mapserver",
      }),
    });

    capa_vias = new window.ol.layer.Image({
      source: new window.ol.source.ImageWMS({
        ratio: 1,
        url: this.url_root + "planificacion/viasWms/MapServer/WMSServer", //ok
        params: {
          FORMAT: this.format,
          VERSION: "1.1.1",
          LAYERS: "0",
          STYLES: "",
        },
      }),
    });

    capa_predios = new window.ol.layer.Image({
      source: new window.ol.source.ImageWMS({
        ratio: 1,
        url: this.url_root + "catastro/predios_cba/MapServer/WMSServer", //ok nuevo
        params: {
          FORMAT: this.format,
          VERSION: "1.1.1",
          LAYERS: "0",
          STYLES: "",
        },
      }),
    });

    /**
     * enu => the default easting, northing
     * neu => northing, easting
     * up => useful for "lat/long
     * wnu - westing, northing
     */
    projection = new window.ol.proj.Projection({
      code: "EPSG:32719", //32719  4326  EPSG:3857
      units: "m",
      axisOrientation: "neu", //neu
    });

    this.overlay = this.createOverlay();

    this.map = new window.ol.Map({
      controls: window.ol.control.defaults().extend([
        new window.ol.control.FullScreen({
          source: "fullscreen",
        }),
      ]),
      layers: [capa_base, capa_predios, capa_vias],
      renderer: "canvas",
      overlays: [this.overlay],
      view: new window.ol.View({
        projection: projection,
      }),
    });

    this.map.getView().setCenter(bounds);
    this.map.getView().setResolution(this.map.getView().getResolution());

    var self = this;
    this.map.getView().on("change:resolution", function (evt) {
      const resolution = evt.target.get("resolution");
      const units = self.map.getView().getProjection().getUnits();
      const dpi = 25.4 / 0.28;
      const mpu = window.ol.proj.METERS_PER_UNIT[units];
      let scale = resolution * mpu * 39.37 * dpi;
      if (scale >= 9500 && scale <= 950000) {
        scale = Math.round(scale / 1000) + "K";
      } else if (scale >= 950000) {
        scale = Math.round(scale / 1000000) + "M";
      } else {
        scale = Math.round(scale);
      }
    });
    vectorSource = new window.ol.source.Vector({});
    vectorLayer = new window.ol.layer.Vector({
      source: vectorSource,
    });

    this.map.on("click", (obj) => {
      debugger;
      var coordinate = obj.coordinate;
      self.getDatosDistritoMap(
        self,
        coordinate,
        undefined,
        vectorSource,
        vectorLayer
      );
      self.map.getView().setZoom(self.state.zoom);
      self.map.updateSize();
      _is_ruat_or_cod_cat = false;
    });

    // para el puntero del raton
    this.map.on("pointerup", function (evt) {
      var pixel = self.map.getEventPixel(evt.originalEvent);

      const feature = self.map.forEachFeatureAtPixel(pixel, function (feature) {
        if (feature.get("uuid") !== 1) {
          return feature;
        }
      });

      if (feature) {
        const coordinate = feature.getGeometry().getCoordinates();
        self.getDatosDistritoMap(
          coordinate,
          undefined,
          vectorSource,
          vectorLayer
        );
      }
    });

    this.searchMapControl(self, vectorSource, vectorLayer);

    _map = this.map;

    /*window.jQuery("#" + this.id_modal).on("show.bs.modal", function () {
      setTimeout(() => {
        self.setState({ zoom: self.state.zoom }); //createOverlay() -7235766.90, -1920825.04
        self.map.updateSize();
      }, 1500);
    });*/
  }

  createOverlay() {
    var overlay = new window.ol.Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    closer.onclick = function () {
      overlay.setPosition(undefined);
      closer.blur();
      return false;
    };
    return overlay;
  }

  searchMapControl(self, vectorSource, vectorLayer) {
    var result_ul = document.createElement("ul");
    result_ul.className = "ul-result-catastro";

    var boton = document.createElement("button");
    boton.innerHTML = "";
    boton.className = "fa fa-search";
    boton.addEventListener("click", function () {
      window.jQuery("#map-input-query").hasClass("d-none")
        ? window.jQuery("#map-input-query").removeClass("d-none")
        : window.jQuery("#map-input-query").addClass("d-none");
      window.jQuery("#map-a-reset").hasClass("d-none")
        ? window.jQuery("#map-a-reset").removeClass("d-none")
        : window.jQuery("#map-a-reset").addClass("d-none");
      window.jQuery("#map-select-option").hasClass("d-none")
        ? window.jQuery("#map-select-option").removeClass("d-none")
        : window.jQuery("#map-select-option").addClass("d-none");
      window.jQuery(".ul-result-catastro").hasClass("d-none")
        ? window.jQuery(".ul-result-catastro").removeClass("d-none")
        : window.jQuery(".ul-result-catastro").addClass("d-none");
    });

    var input = document.createElement("input");
    input.setAttribute("id", "map-input-query");
    input.setAttribute("class", "gcd-gl-input d-none");
    input.setAttribute("autocomplete", "off");
    input.setAttribute("placeholder", "Ingrese nro de inmuble RUAT...");
    input.setAttribute("type", "text");
    input.addEventListener("keyup", function (event) {
      let value = event.target.value;
      let select_option = window.jQuery(
        "#map-select-option option:selected"
      )[0];
      if (select_option.value === "Cod. Cat.") {
        if (value.length >= 3 && value.length <= 17) {
          value = value.replace(/-/g, "");
          const predios_ = self.fetch.fetchGetExternal(
            `${self.url_root_search}catastro/predios_cba/MapServer/0/query?f=json&where=UPPER(CodCat)%20LIKE%20%27%25${value}%25%27&returnGeometry=true&spatialRel=esriSpatialRelIntersects&maxAllowableOffset=0.13229193125052918&outFields=*&outSR=32719&resultRecordCount=12`
          );
          predios_.then((res) => {
            _is_ruat_or_cod_cat = true;
            self.loadFeatures(
              res,
              result_ul,
              vectorSource,
              vectorLayer,
              self,
              false
            );
          });
        }
      }

      if (select_option.value === "RUAT") {
        if (value.length >= 3 && value.length <= 6) {
          const predios_ = self.fetch.fetchGetExternal(
            `${self.url_root_search}catastro/predios_cba/MapServer/0/query?f=json&where=NoInmueble=%27${value}%27&returnGeometry=true&spatialRel=esriSpatialRelIntersects&maxAllowableOffset=0.13229193125052918&outFields=*&outSR=32719&resultRecordCount=12`
          );
          predios_.then((res) => {
            _is_ruat_or_cod_cat = true;
            self.loadFeatures(
              res,
              result_ul,
              vectorSource,
              vectorLayer,
              self,
              false
            );
          });
        }
      }

      if (select_option.value === "Vías") {
        if (value.length >= 3) {
          const inmueble = self.fetch.fetchGetExternal(
            `${
              self.url_root_search
            }planificacion/vias/MapServer/0/query?f=json&where=UPPER(Nombre_V)%20LIKE%20%27%25${value.toUpperCase()}%25%27&returnGeometry=true&spatialRel=esriSpatialRelIntersects&maxAllowableOffset=0.13229193125052918&outFields=*&outSR=32719&resultRecordCount=6`
          );
          inmueble.then((res) => {
            _is_ruat_or_cod_cat = false;
            self.loadFeatures(
              res,
              result_ul,
              vectorSource,
              vectorLayer,
              self,
              true
            );
          });
        }
      }
    });

    var reset = document.createElement("a");
    reset.setAttribute("id", "map-a-reset");
    reset.className = "gcd-gl-reset fa fa-times d-none";
    reset.addEventListener("click", function (event) {
      window.jQuery("#map-input-query").val("");
    });

    var select = document.createElement("select");
    select.setAttribute("id", "map-select-option");
    select.className = "d-none bg-white";
    select.addEventListener("change", function (event) {
      input.value = "";
      if (event.target.value === "RUAT")
        input.setAttribute("placeholder", "Ingrese nro de inmuble RUAT...");
      if (event.target.value === "Vías")
        input.setAttribute("placeholder", "Ingrese nombre de la vía...");
      if (event.target.value === "Cod. Cat.")
        input.setAttribute("placeholder", "Ingrese cádigo catastral...");
    });

    var array = ["RUAT", "Cod. Cat.", "Vías"]; //Nro Inmueble = Ruat

    for (var i = 0; i < array.length; i++) {
      var option = document.createElement("option");
      option.value = array[i];
      option.text = array[i];
      select.appendChild(option);
    }

    var elementoDiv = document.createElement("div");
    elementoDiv.className = "boton-search-catastro ol-unselectable ol-control";
    elementoDiv.appendChild(boton);
    elementoDiv.appendChild(select);
    elementoDiv.appendChild(input);
    elementoDiv.appendChild(reset);
    elementoDiv.appendChild(result_ul);

    var searchControl = new window.ol.control.Control({ element: elementoDiv });
    this.map.addControl(searchControl);
  }

  loadFeatures(res, result_ul, vectorSource, vectorLayer, self, isVias) {
    if (res !== undefined && res.hasOwnProperty("features")) {
      let list_features = res.features; //lista de predios por codigo catastral
      window.jQuery(result_ul).empty();

      const listItems = list_features.map((features) => {
        let coordinate = undefined;
        let polygon = undefined;
        if (isVias) {
          coordinate = features.geometry.paths[0][0];
          polygon = features.geometry.paths[0];
        } else {
          polygon = features.geometry.rings[0];
          coordinate = features.geometry.rings[0][0];
        }

        var li = document.createElement("li");
        var link_a = document.createElement("a");
        link_a.setAttribute("href", "#");
        link_a.setAttribute("coordinate", coordinate);
        link_a.addEventListener("click", function (event) {
          event.preventDefault();
          vectorSource.clear();
          if (isVias) {
            self.longitud = 0; //longitud
            self.latitud = 0; //latitud
            let bounds = JSON.parse(
              "[" + link_a.getAttribute("coordinate") + "]"
            );
            self.map.getView().setCenter(bounds);
            self.map.updateSize();
            self.paintIcon(coordinate, vectorSource, vectorLayer, self.map);
          } else {
            _cod_cat = event.target.innerHTML;
            self.getDatosDistritoMap(
              self,
              coordinate,
              polygon,
              vectorSource,
              vectorLayer
            );
          }
        });

        let _span = document.createElement("span");
        _span.className = "gcd-road";
        if (isVias) _span.innerHTML = features.attributes.Nombre_V;
        else _span.innerHTML = features.attributes.CodCat;

        link_a.appendChild(_span);

        var link_search = document.createElement("i");
        link_search.className = "fas fa-map-marker-alt float-end";
        link_search.style =
          "padding: 0px 5px; cursor: pointer; color: #007bff;";
        link_search.ariaHidden = "true";

        link_search.addEventListener("click", function (event) {
          link_a.click();
        });

        li.appendChild(link_a);
        li.appendChild(link_search);
        result_ul.appendChild(li);
      });

      if (result_ul.getElementsByTagName("li").length > 0) {
        result_ul.style.display = "block";
        result_ul.classList.remove("d-none");
      }
    }
    if (res !== undefined && res.hasOwnProperty("error")) {
      toast.warn(res.error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }

  getDatosDistritoMap(self, coordinate, polygon, vectorSource, vectorLayer) {
    const view = self.map.getView();
    const viewResolution = view.getResolution();
    const source = capa_predios.getSource();
    const url = source.getGetFeatureInfoUrl(
      coordinate,
      viewResolution,
      view.getProjection(),
      { INFO_FORMAT: "application/json", FEATURE_COUNT: 50 }
    );

    self.getDatosDistrito(
      url,
      self.map,
      vectorSource,
      vectorLayer,
      coordinate,
      polygon
    );
  }

  getDatosDistrito(url, map, vectorSource, vectorLayer, coordinate, polygon) {
    var self = this;
    axios
      .get(url)
      .then((response) => {
        vectorSource.clear();
        var content_overlay = "";

        var xml = window.StringToXML(
          response.data.replace(/ó/g, "o").replace(/Nro./g, "Nro_")
        );
        datos_distrito = {};
        debugger;

        if (xml.getElementsByTagName("FIELDS").length > 1) {
          for (let i = 0; i < xml.getElementsByTagName("FIELDS").length; i++) {
            //revisar el buscar y quitar el substring
            if (
              xml
                .getElementsByTagName("FIELDS")
                [i].getAttributeNode("CodigoCatastral").value === _cod_cat
            ) {
              datos_distrito = {
                codCatastral: xml
                  .getElementsByTagName("FIELDS")
                  [i].getAttributeNode("CodigoCatastral").value,
                distrito: xml
                  .getElementsByTagName("FIELDS")
                  [i].getAttributeNode("Distrito").value,
                subDistrito: xml
                  .getElementsByTagName("FIELDS")
                  [i].getAttributeNode("Subdistrito").value,
                nroPredio: xml
                  .getElementsByTagName("FIELDS")
                  [i].getAttributeNode("Nro_predio").value,
                zonaTributaria: xml
                  .getElementsByTagName("FIELDS")
                  [i].getAttributeNode("ZTributari").value,
                nroInmueble: xml
                  .getElementsByTagName("FIELDS")
                  [i].getAttributeNode("Nro_Inmueble").value,

                //nueva version
                comuna: xml
                  .getElementsByTagName("FIELDS")[0]
                  .getAttributeNode("comuna").value, //Comuna anterior
                nroSubDistrito: xml
                  .getElementsByTagName("FIELDS")[0]
                  .getAttributeNode("Nro_Subdistro").value, //Nro_Subdistrito anterior
                superficie: xml
                  .getElementsByTagName("FIELDS")[0]
                  .getAttributeNode("SHAPE.STLength").value, //Shape.STArea Shape_Leng  //preguntar diferencia entre area
                perimetro: xml
                  .getElementsByTagName("FIELDS")[0]
                  .getAttributeNode("SHAPE.STLength").value,
                pendiente: xml
                  .getElementsByTagName("FIELDS")[0]
                  .getAttributeNode("pendiente").value,
                nroManzana: xml
                  .getElementsByTagName("FIELDS")[0]
                  .getAttributeNode("Nro_manzana").value,
              };
            }
          }
        } else {
          if (xml.getElementsByTagName("FIELDS").length === 1) {
            datos_distrito = {
              codCatastral: xml
                .getElementsByTagName("FIELDS")[0]
                .getAttributeNode("CodigoCatastral").value,
              distrito: xml
                .getElementsByTagName("FIELDS")[0]
                .getAttributeNode("Distrito").value,
              subDistrito: xml
                .getElementsByTagName("FIELDS")[0]
                .getAttributeNode("Subdistrito").value,
              nroPredio: xml
                .getElementsByTagName("FIELDS")[0]
                .getAttributeNode("Nro_predio").value,
              zonaTributaria: xml
                .getElementsByTagName("FIELDS")[0]
                .getAttributeNode("ZTributari").value,
              nroInmueble: xml
                .getElementsByTagName("FIELDS")[0]
                .getAttributeNode("Nro_Inmueble").value,

              //nueva version
              comuna: xml
                .getElementsByTagName("FIELDS")[0]
                .getAttributeNode("comuna").value, //Comuna anterior
              nroSubDistrito: xml
                .getElementsByTagName("FIELDS")[0]
                .getAttributeNode("Nro_Subdistro").value, //Nro_Subdistrito anterior
              superficie: xml
                .getElementsByTagName("FIELDS")[0]
                .getAttributeNode("SHAPE.STArea").value, //Shape.STArea Shape_Leng  //preguntar diferencia entre area
              perimetro: xml
                .getElementsByTagName("FIELDS")[0]
                .getAttributeNode("SHAPE.STLength").value,
              pendiente: xml
                .getElementsByTagName("FIELDS")[0]
                .getAttributeNode("pendiente").value,
              nroManzana: xml
                .getElementsByTagName("FIELDS")[0]
                .getAttributeNode("Nro_manzana").value,
            };
          }
        }

        if (Object.entries(datos_distrito).length !== 0) {
          self.predio = datos_distrito.nroPredio;
          self.catastro = datos_distrito.codCatastral;
          self.zona = datos_distrito.zonaTributaria;
          self.comuna = datos_distrito.comuna;
          self.distrito = datos_distrito.distrito;
          self.sub_distrito = datos_distrito.subDistrito;
          self.num_inmueble = datos_distrito.nroInmueble;

          content_overlay =
            "<p style='margin-bottom: 0rem!important; margin-top: 5px;'><strong>Catastro: </strong> <span>" +
            datos_distrito.codCatastral +
            "</span></p>" +
            "<p style='margin-bottom: 0rem!important; '><strong>Predio: </strong> <span>" +
            datos_distrito.nroPredio +
            "</span></p>" +
            "<p style='margin-bottom: 0rem!important; '><strong>Comuna: </strong> <span>" +
            datos_distrito.comuna +
            "</span></p>" +
            "<p style='margin-bottom: 0rem!important; '><strong>Distrito: </strong> <span>" +
            datos_distrito.distrito +
            "</span></p>" +
            "<p style='margin-bottom: 0rem!important; '><strong>Sub Distrito: </strong> <span>" +
            datos_distrito.subDistrito +
            "</span></p>" +
            "<p style='margin-bottom: 0rem!important; '><strong>Nro. Sub Distrito: </strong> <span>" +
            datos_distrito.nroSubDistrito +
            "</span></p>" +
            "<p style='margin-bottom: 0rem!important; '><strong>Zona Tributaria: </strong> <span>" +
            datos_distrito.zonaTributaria +
            "</span></p>" +
            "<p style='margin-bottom: 0rem!important; '><strong>Nro. inmueble: </strong> <span>" +
            datos_distrito.nroInmueble +
            "</span></p>" +
            "<p style='margin-bottom: 0rem!important; '><strong>Superficie: </strong> <span>" +
            datos_distrito.superficie +
            "</span></p>" +
            "<p style='margin-bottom: 0rem!important; '><strong>Perimetro: </strong> <span>" +
            datos_distrito.perimetro +
            "</span></p>" +
            "<p style='margin-bottom: 0rem!important; '><strong>Pendiente: </strong> <span>" +
            datos_distrito.pendiente +
            "</span></p>" +
            "<p style='margin-bottom: 0rem!important; '><strong>Nro. Manzana: </strong> <span>" +
            datos_distrito.nroManzana +
            "</span></p>";
        }

        content.innerHTML =
          "<strong>Actividad Económica:</strong><code> " +
          content_overlay +
          "</code>";

        if (polygon !== undefined) {
          coordinate = this.paintPredio(
            polygon,
            vectorSource,
            self.map,
            self.num_inmueble
          );
        }
        self.overlay.setPosition(coordinate);
        this.paintIcon(coordinate, vectorSource, vectorLayer, self.map);
        self.map.getView().setCenter(coordinate);
        self.longitud = 0;
        self.latitud = 0;

        if (Object.entries(datos_distrito).length !== 0) {
          let lat_lon = window.ol.proj.toLonLat(coordinate); //devuelve longitud, latitud
          self.longitud = lat_lon[0]; //longitud
          self.latitud = lat_lon[1]; //latitud
          self.coordinate = coordinate.toString();
        }
      })
      .catch((error) => {
        console.log(error.config);
      });
  }

  paintIcon(coordinate, vectorSource, vectorLayer, map) {
    //move to icon feature
    if (!_is_ruat_or_cod_cat) {
      var iconFeature = new window.ol.Feature({
        geometry: new window.ol.geom.Point(coordinate),
      });

      debugger;
      var iconStyle = [
        new window.ol.style.Style({
          image: new window.ol.style.Icon({
            anchor: [0.5, 0.5],
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
            src: markerBlue,
            crossOrigin: "anonymous",
          }),
        }),
      ];
      iconFeature.setStyle(iconStyle);
      vectorSource.addFeature(iconFeature);
      vectorLayer = new window.ol.layer.Vector({
        source: vectorSource,
      });
      map.addLayer(vectorLayer);

      var translate1 = new window.ol.interaction.Translate({
        features: new window.ol.Collection([iconFeature]),
      });

      map.addInteraction(translate1);
    } else {
      vectorLayer = new window.ol.layer.Vector({
        source: vectorSource,
      });
      map.addLayer(vectorLayer);
    }
  }

  paintPredio(polygon, vectorSource, map, num_inmueble) {
    var featurePoligono = new window.ol.Feature({
      geometry: new window.ol.geom.Polygon([polygon]),
      name: num_inmueble,
    });
    featurePoligono.setStyle(styleFunctionPoligono);

    function styleFunctionPoligono() {
      return [
        new window.ol.style.Style({
          fill: new window.ol.style.Fill({
            color: "#B3EEFF", //'rgba(255,255,255,0.4)'
          }),
          stroke: new window.ol.style.Stroke({
            color: "#B3EEFF",
            width: 1.25,
          }),
          text: new window.ol.style.Text({
            font: "10px Calibri,sans-serif",
            fill: new window.ol.style.Fill({ color: "#000" }),
            stroke: new window.ol.style.Stroke({
              color: "#fff",
              width: 2,
            }),
            text: map.getView().getZoom() >= 15 ? num_inmueble : "",
          }),
        }),
      ];
    }

    vectorSource.addFeature(featurePoligono);
    var aa = featurePoligono.getGeometry().getExtent();
    return window.ol.extent.getCenter(aa);
  }

  handleChangePosition(event, polygon) {
    /*let self = this;
    window.jQuery("#" + self.id_modal).on("shown.bs.modal", function () {
      setTimeout(() => {
      this.setState({ zoom: 16 });
    let coordinate = this.paintPredio(polygon, vectorSource, _map, "");
    this.paintIcon(coordinate, vectorSource, vectorLayer, _map);
    _map.getView().setCenter(coordinate);
      }, 1000);
    });*/
  }

  hiddenMap() {
    const container = document.getElementById("containerMapCatastro");
    container.classList.add("d-none");
  }

  render() {
    return (
      <>
        <div id="containerMapCatastro">
          <div className={"row "}>
            <div id="fullscreen" className="fullscreen">
              <div id="map" className="map-layer">
                <div id="popup" className="ol-popup">
                  <a href="#" id="popup-closer" className="ol-popup-closer"></a>
                  <div
                    id="popup-content"
                    style={{
                      overflow: "hidden",
                      maxHeight: 140,
                      overflowY: "scroll",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <canvas
            id="catastroPublicCanva"
            className="d-none hidden"
            height={400}
            width={800}
          ></canvas>{" "}
        </div>
      </>
    );
  }
}

export default MapCatastroPublic;