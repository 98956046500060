import React, { useState, useEffect } from "react";

const ValidatePropiedad = (props) => {
  const CODE_ACTIVE = "ACTIVO";
  const CODE_EN_TRANSFERENCIA = "EN TRANSFERENCIA";
  const { dataInmueble, tipoVisacionMinuta, dataDerechoAdmision } = props;
  const claseInmueble = dataInmueble.dataInmueble.claseInmueble;
  const numeroInmueble = dataInmueble.dataInmueble.numeroInmueble;
  const codigoCatastral = dataInmueble.dataInmueble.codigoCatastral;
  const datosGenerales = dataInmueble.dataTecnico.datosGenerales;

  const [propiedadWorking, setPropiedadWorking] = useState(undefined);
  const [message, setMessage] = useState("");
  let messageMultiplesPropiedades = undefined;

  useEffect(() => {
    if (
      dataInmueble.dataInmueble &&
      dataInmueble.dataInmueble.propiedades.length > 0
    ) {
      const propiedad = dataInmueble.dataInmueble.propiedades;
      if (propiedad.length === 1) {
        let fechaFin = null;
        let fechaInicio = null;
        if (dataInmueble.dataInmueble.propiedades[0].fechaFinPropiedad) {
          fechaFin = window
            .moment(
              dataInmueble.dataInmueble.propiedades[0].fechaFinPropiedad,
              "DD/MM/YYYY",
              true
            )
            .isValid()
            ? dataInmueble.dataInmueble.propiedades[0].fechaFinPropiedad
            : null;
        }
        if (dataInmueble.dataInmueble.propiedades[0].fechaInicioPropiedad) {
          fechaInicio = window
            .moment(
              dataInmueble.dataInmueble.propiedades[0].fechaInicioPropiedad,
              "DD/MM/YYYY",
              true
            )
            .isValid()
            ? dataInmueble.dataInmueble.propiedades[0].fechaInicioPropiedad
            : null;
        }
        const selectedInmuebleDefault = {
          selected: true,
          codigoPropiedad:
            dataInmueble.dataInmueble.propiedades[0].codigoPropiedad,
          codigoContribuyente:
            dataInmueble.dataInmueble.propiedades[0].codigoContribuyente,
          estadoPropiedad:
            dataInmueble.dataInmueble.propiedades[0].estadoPropiedad,
          fechaInicioPropiedad: fechaInicio,
          fechaFinPropiedad: fechaFin,
          porcentaje: dataInmueble.dataInmueble.propiedades[0].porcentaje,
          propietario: dataInmueble.dataInmueble.propiedades[0].propietario,
        };

        if (selectedInmuebleDefault.estadoPropiedad === CODE_ACTIVE) {
          verificarDeuda(selectedInmuebleDefault);
          setPropiedadWorking(propiedad);
        } else {
          setMessage("La propiedad no se encuentra ACTIVA");
        }
      }
    } else {
      console.log("no hay inmuebles asociados");
    }
  }, []);

  const handleClickRow = async (event, propiedad) => {
    event.preventDefault();
    debugger;
    if (propiedad.estadoPropiedad === CODE_ACTIVE) {
      await verificarDeuda(propiedad);
      setPropiedadWorking(propiedad);
    } else {
      setMessage("La propiedad no se encuentra " + CODE_ACTIVE);
    }
  };

  const verificarDeuda = async (propiedad) => {
    const form = new FormData();
    propiedad.codigoCatastral = dataInmueble.dataInmueble.codigoCatastral;
    propiedad.codigoInmueble = dataInmueble.dataInmueble.codigoInmueble;
    propiedad.numeroInmueble = dataInmueble.dataInmueble.numeroInmueble;
    propiedad.claseInmueble = dataInmueble.dataInmueble.claseInmueble;

    propiedad.tipoVisacionMinuta = tipoVisacionMinuta;
    propiedad.dataTecnico = dataInmueble.dataTecnico;
    propiedad.derechoAdmision = dataDerechoAdmision;

    form.append("propiedades[codigoPropiedad]", propiedad.codigoPropiedad);
    const resDeudas = await props.fetch.fetchPost(
      form,
      `api-ruat/inmuebles/deudas`,
      undefined
    );
    if (Boolean(resDeudas) && resDeudas.status) {
      resDeudas.data.gestionesDeuda = [];  //PARA EVITAR LA DEUDA
      if (
        resDeudas.data.gestionesDeuda &&
        resDeudas.data.gestionesDeuda.length <= 0
      ) {
        propiedad.gestionesDeuda = resDeudas.data.gestionesDeuda;
        const objetoOfAction = {
          dataInmueble: propiedad,
          csrfkey: props.csrfkey,
        };
        props.closeModal(objetoOfAction);
      } else {
        const deudasArray = [];
        if (
          resDeudas.data.gestionesDeuda &&
          resDeudas.data.gestionesDeuda.length > 0
        ) {
          resDeudas.data.gestionesDeuda.map((deuda) => {
            if (!deudasArray.includes(deuda.gestion)) {
              deudasArray.push(deuda.gestion);
            }
            return deuda;   //esto es lo nuevo que se ha tocado
          });
          setMessage("");
        }
        setMessage(
          "El inmueble indicado tiene deudas de las gestiones " +
            deudasArray.join(",")
        );
      }
    } else {
      setMessage(
        "El propiedad (AADD)" +
          propiedad.porcentaje +
          "%, tiene deudas a la fecha"
      );
    }
  };

  let propiedades = dataInmueble.dataInmueble.propiedades.map(
    (
      {
        codigoPropiedad,
        codigoContribuyente,
        estadoPropiedad,
        fechaInicioPropiedad,
        fechaFinPropiedad,
        porcentaje,
        propietario,
      },
      key
    ) => {
      let propietarioNatural;
      let propietarioJuridico;
      if (propietario) {
        propietarioNatural = propietario.natural
          ? propietario.natural
          : undefined;
        propietarioJuridico = propietario.juridico
          ? propietario.juridico
          : undefined;
      }

      if (
        estadoPropiedad === CODE_ACTIVE ||
        estadoPropiedad === CODE_EN_TRANSFERENCIA
      ) {
        let fechaFin = null;
        let fechaInicio = null;
        if (fechaFinPropiedad) {
          fechaFin = window
            .moment(fechaFinPropiedad, "DD/MM/YYYY", true)
            .isValid()
            ? fechaFinPropiedad
            : null;
        }
        if (fechaInicioPropiedad) {
          fechaInicio = window
            .moment(fechaInicioPropiedad, "DD/MM/YYYY", true)
            .isValid()
            ? fechaInicioPropiedad
            : null;
        }

        return (
          <div
            className={
              "row pointer row-table-custom pt-2 pb-2 " +
              (key % 2 === 0 ? " bg-picton-blue " : " background-white ") +
              " justify-content-center align-items-center objeto-tributario-deuda-" +
              codigoPropiedad +
              " "
            }
            key={codigoPropiedad}
            onClick={(e) =>
              handleClickRow(e, {
                selected: true,
                codigoPropiedad,
                codigoContribuyente,
                estadoPropiedad,
                fechaInicioPropiedad: fechaInicio,
                fechaFinPropiedad: fechaFin,
                porcentaje,
                propietario,
              })
            }
          >
            <div className="col-10 col-md-10 ">
              <span>
                {propietario.nombreContribuyente}{" "}
                {porcentaje !== null ? "(AADD " + porcentaje + " %)" : ""}
              </span>
              <br />
              <strong>Estado: </strong>
              <span> {estadoPropiedad}</span> <br />
              <strong>Fecha Inicio: </strong>
              <span>{fechaInicioPropiedad.replace(/\//g, "-")}</span>
            </div>
            <div className="col-2 col-md-2">
              <i
                className="far fa-hand-pointer fa-2x fa-icon-image"
                aria-hidden="true"
              ></i>
              <input
                type="hidden"
                name={"inmueble[][codigoPropiedad]"}
                value={{
                  codigoPropiedad,
                  estadoPropiedad,
                  fechaInicioPropiedad,
                  porcentaje,
                  propietario,
                }}
              />
            </div>

            {propietarioNatural ? (
              <div className="col-12">
                <strong>Propietario: </strong>
                <span>{propietarioNatural.nombres}</span>
              </div>
            ) : (
              ""
            )}
            {propietarioJuridico ? (
              <div className="col-12">
                <strong>Propietario: </strong>
                <span>{propietarioJuridico.razonSocial}</span>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      }
      return undefined;
    }
  );
  propiedades = propiedades.filter((propiedad) => propiedad);

  return (
    <>
      <div className="row">
        <div className="col-12 padding-left-0 text-uppercase">
          <strong>
            <p style={{ fontSize: ".9em" }}>{tipoVisacionMinuta.name}</p>
          </strong>
        </div>
      </div>

      <div className="row">
        <div className="col-12 padding-left-0 text-uppercase mb-3">
          <strong style={{ fontSize: ".9em" }}>Nro. Inmueble: </strong>
          <span>{numeroInmueble}</span>
          <br />
          <strong style={{ fontSize: ".9em" }}>Cod. Cat.: </strong>
          <span>{codigoCatastral}</span>
          <br />
          <strong style={{ fontSize: ".9em" }}>Clase: </strong>
          <span>{claseInmueble}</span>
          <br />
          <strong style={{ fontSize: ".9em" }}>Tipo Propiedad: </strong>
          <span>{datosGenerales ? datosGenerales.tipoPropiedad : ""}</span>
        </div>
      </div>

      <div className="row">
        <div className="col-12 padding-left-0 ">
          <h4
            className="text-center mb-3 text-butterfly-bush"
            id="h4TitleDocument"
          >
            Seleccione la Propiedad
          </h4>
        </div>

        {message !== "" ? (
          <>
            <div className="col-12 padding-left-right-0">
              <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
              >
                <strong>Atención!</strong> {message}
              </div>
            </div>

            {propiedadWorking ? (
              propiedadWorking.estadoPropiedad &&
              propiedadWorking.estadoPropiedad !== "ACTIVO" ? (
                <div className="col-12 mt-2 padding-left-right-0">
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    Estado actual{" "}
                    <strong> {propiedadWorking.estadoPropiedad}</strong>
                  </div>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {propiedadWorking ? (
              propiedadWorking.estadoPropiedad === CODE_EN_TRANSFERENCIA ? (
                <div className="col-12 mt-2 padding-left-right-0">
                  <div
                    className="alert alert-warning alert-dismissible fade show"
                    role="alert"
                  >
                    Proceda a consolidar su Derecho Propietario en la
                    SubAlcaldia, que le corresponde: <br />
                  </div>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        {/* CASO MULTIPLES PROPIEDADES, ACCIONES Y DERECHOS */}
        {messageMultiplesPropiedades && !message
          ? messageMultiplesPropiedades
          : ""}
      </div>

      {propiedades ? propiedades : ""}

      <div className="row mt-3">
        <div className="col-12 col-md-6 col-lg-6 text-start"></div>
        <div className="col-12 col-md-6 col-lg-6 search-btn button text-end padding-left-0 padding-right-0">
          <input
            className="btn bg-maroon-flush btn-full-screen"
            name="submit"
            type="submit"
            value="Cancelar"
            onClick={props.handleCancelClick}
          />
        </div>
      </div>
    </>
  );
};
export default ValidatePropiedad;
