import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Fetch from "../../utils/Fetch"

const RegisterPhoneFormulario = ({
	onSubmit,
	dataFormPhone,
	urlImageLoading
}) => {
	const idForm = "form-phone";
	const fetch = new Fetch();
	fetch.setToast(toast);	
	const history = useHistory();

	// state	
	const [celular, setCelular] = useState(dataFormPhone.phone);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const isMounted = useRef(false);

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []
	);

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		setError('');
		window.jQuery("#" + idForm).parsley().validate();
		if (window.jQuery("#" + idForm).parsley().isValid()) {
			// handleGetPhoneVerify();
			handleVerificarCelularYenviarCodigo();
		}
	};


	const handleVerificarCelularYenviarCodigo = async () => {
		debugger
		try {
			setLoading(true);
			const form = new FormData();
			form.append("registro[celular]", celular);
			const dataJson = await fetch.fetchPost(form, "api/usuario/registro/verificar-celular", undefined);
			if (dataJson && dataJson.status) {
				debugger
				const dataJsonCodigo = await fetch.fetchGet(`api/usuario/registro/enviar-codigo/${celular}`);
				if (dataJsonCodigo && dataJsonCodigo.status) {
					debugger
					fetch.toast.success(dataJson.message);
					setTimeout(() => {
						fetch.toast.success(dataJsonCodigo.message);
						onSubmit(celular);
					}, 1000);
				}
			}
		} catch (error) {
			toast.error("Error al realizar la verificación del número de celular. Vuelva a intentar.");
		} finally {
			setLoading(false);
		}
	}

	const changeCelular = (e) => {
		setError('');
		setCelular(e.target.value)
	}

	const handleEventClickCancelar = (e) => {

		const storedData = localStorage.getItem("dataForm");
		if (storedData) {
			localStorage.removeItem("dataForm");
		}

		const stepData = localStorage.getItem("currentStep");
		if (stepData) {
			localStorage.removeItem("currentStep");
		}

		history.push('/');

	};

	/* ============================================================ */
	return (
		<form
			onSubmit={handleFormSubmit}
			name={idForm}
			id={idForm}
			method="post"
			noValidate
			autoComplete='off'
		>

			{loading && (<div className="col-12 mb-1 mx-auto d-block text-center">
				<img
					className="rounded"
					alt="pulse-loading"
					src={urlImageLoading}
					width="40"
				/>{" "}
				<span className="ml-5 text-violeta">Procesando</span>
			</div>)}

			<div className="form-group">
				<label htmlFor="numcelular">(*) Número Celular:</label>
				<input
					type="number"
					id="numcelular"
					name="numcelular"
					className={error ? 'form-control form-control-lg parsley-error' : 'form-control form-control-lg'}
					value={celular}
					onChange={(e) => changeCelular(e)}
					required
					data-parsley-required-message="El número de celular es requerido"
					data-parsley-pattern="^[6-7]\d{7}$"
					data-parsley-pattern-message="El número debe empezar con 6 o 7 y tener 8 dígitos."
					placeholder="Ej.: 61234578"
				/>

			</div>

			{error !== '' && (<div className="parsley-errors-list filled"> {error} </div>)}

			<div className="text-justify col-lg-12 col-md-12 col-sm-12 mx-auto border-radius-035 bg-gray-5 mt-3 p-3">
				Al continuar, te enviaremos un código de verificación por WhatsApp.
			</div>

			<div className="d-flex d-sm-flex flex-sm-row justify-content-between mt-3">
				<div>


					<button type="button" className="btn btn-fucsia" onClick={handleEventClickCancelar}>
						Volver
					</button>
				</div>
				<div>
					<button type="submit" className={loading ? "btn btn-violeta disabled " : "btn btn-violeta "}>
						Continuar
					</button>
				</div>
			</div>
		</form>
	);
};

RegisterPhoneFormulario.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	urlImageLoading: PropTypes.string.isRequired
	// url: PropTypes.string.isRequired,
};

export default RegisterPhoneFormulario;