import React, { Component } from "react";
import AuthService from "../../components/Usuario/AuthService";
import Links from "../../data/link";
import TitlePage from "../../components/utils/TitlePage";
import CollapseMapa from "./CollapseMapa";
import CollapseReport from "./CollapseReport";
import Languaje from "../../data/es";
import HeaderTotales from "./HeaderTotales";
import GraficaLineal from "./GraficaLineal";
import GraficaTorta from "./GraficaTorta";
import ItemsRecently from "./ItemsRecently";
import LastMembers from "./LastMembers";
import MapCatastro from "./MapCatastro";
import Texto from "../../data/es";
import Fetch from "../../components/utils/Fetch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var _mapCatastro = null;
class Gerencia extends Component {
  constructor(props) {
    super(props);
    this.titlePage = "Soporte Gerencial";

    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.handleVerifiedCodeSubmit = this.handleVerifiedCodeSubmit.bind(this);
    this.handleOpenMenu = this.handleOpenMenu.bind(this);

    this.fetch = new Fetch();
    this.fetch.setToast(toast);
    this.Auth = new AuthService();
    this.id_form_verified_code = "formVerifiedCode";

    _mapCatastro = new MapCatastro();

    this.state = {
      showMap: false,
      requireAuthenticacion: true,
      showMessageAlert: false,
    };
  }

  componentDidMount() {
    if (this.Auth.loggedIn()) {
      if (Boolean(this.Auth.getCodigoSoporteGerencial())) {
        this.setState({ requireAuthenticacion: false });
        //this.addEventDismissButton();
      } else {
        this.sendMailCode();
        this.setState({ requireAuthenticacion: true });
      }
    } else this.props.history.replace(Links[4].url);
  }

  handleSubmitForm(event) {
    event.preventDefault();

    const form = new FormData(event.target);
    var form_javascript = event.target;
    var self = this;
    let per_page = form.get("reporte[per_page]");

    //window.jQuery.preloader.start();

    this.fetch
      .fetchPost(
        form,
        `api/administracion/get-licencia-actividad-economica/1/${per_page}`,
        form_javascript
      )
      .then((dataJson) => {
        if (dataJson !== undefined && dataJson.status === true) {
          if (Boolean(dataJson.Reporte)) {
            toast.success(dataJson.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });

            self.setState({
              showMap: true,
            });
            setTimeout(() => {
              _mapCatastro.renderMarketInMap(dataJson.Reporte, dataJson.total);
              let li_paginas = "";
              for (let i = 1; i <= dataJson.total_pages; i++) {
                if (i === 1)
                  li_paginas +=
                    '<li class="page-item disabled"><a class="page-link" href="#" >' +
                    i +
                    "</a></li>";
                else
                  li_paginas +=
                    '<li class="page-item"><a class="page-link" href="#" >' +
                    i +
                    "</a></li>";
              }

              var ul_pagination_licencia = window.jQuery(
                "#pagination-ul-licencia"
              );
              ul_pagination_licencia.empty();
              if (dataJson.Reporte.length > 0) {
                ul_pagination_licencia.append(li_paginas);

                window.event_drop_down(
                  ul_pagination_licencia,
                  function (code_ci_exp, element) {
                    ul_pagination_licencia.find("li").removeClass("disabled");
                    element.parentElement.classList.add("disabled");
                    self.pageLoad(
                      form,
                      form_javascript,
                      element.innerHTML,
                      per_page,
                      self
                    );
                  }
                );
              }
            }, 3000);
          } else {
            toast.warn(dataJson.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        }
        //window.jQuery.preloader.stop();
      });
  }

  pageLoad(form, target, page, per_page, self) {
    this.fetch
      .fetchPost(
        form,
        `api/administracion/get-licencia-actividad-economica/${page}/${per_page}`,
        target
      )
      .then((dataJson) => {
        if (dataJson !== undefined && dataJson.status === true) {
          if (Boolean(dataJson.Reporte)) {
            toast.success(dataJson.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });

            self.setState({
              showMap: true,
            });
            setTimeout(() => {
              _mapCatastro.renderMarketInMap(dataJson.Reporte);
            }, 3000);
          } else {
            toast.warn(dataJson.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        }
      });
  }

  handleVerifiedCodeSubmit(event) {
    event.preventDefault();
    window
      .jQuery("#" + this.id_form_verified_code)
      .parsley()
      .validate();
    var form_javascript = event.target;
    var self = this;

    if (
      window
        .jQuery("#" + this.id_form_verified_code)
        .parsley()
        .isValid()
    ) {
      const form = new FormData(event.target);

      this.fetch
        .fetchPost(
          form,
          `api/administracion/verificar-codigo-sg/SSG`,
          form_javascript
        )
        .then((dataJson) => {
          if (dataJson !== undefined && dataJson.status === true) {
            if (Boolean(dataJson.code)) {
              self.setState({ requireAuthenticacion: false });

              self.Auth.setCodigoSoporteGerencial(dataJson.code);
              //self.addEventDismissButton();
              toast.success(dataJson.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            } else {
              toast.warn(dataJson.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          }
        });
    } else {
      toast.warn(Texto.campos_obligatorios, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }

  addEventDismissButton() {
    /*setTimeout(() => {
            debugger
            window.jQuery('.dismiss, .overlay').on('click', function () {
                debugger
                window.jQuery('.sidebar').removeClass('active');
                window.jQuery('.overlay').removeClass('active');
            });
        }, 1000);*/
  }

  sendMailCode() {
    const send_mail = this.fetch.fetchGet(
      `api/administracion/enviar-codido-to-mail/SSG`
    );
    send_mail.then((res) => {
      if (res !== undefined && res.status === true) {
        document
          .getElementById("divAlertRegisterUser")
          .classList.remove("alert-warning");
        document
          .getElementById("divAlertRegisterUser")
          .classList.add("alert-success");
        document.getElementById("divAlertRegisterUser").innerHTML = res.message;
      } else {
        document
          .getElementById("divAlertRegisterUser")
          .classList.remove("alert-success");
        document
          .getElementById("divAlertRegisterUser")
          .classList.add("alert-warning");
        document.getElementById("divAlertRegisterUser").innerHTML =
          "Código no Generado";
      }
    });
  }

  handleOpenMenu() {
    debugger;
    const sideBar = document.getElementsByClassName("sidebar");
    if (sideBar) {
      if (sideBar.length > 0 && sideBar[0].classList.contains("d-none"))
        sideBar[0].classList.remove("d-none");
      else sideBar[0].classList.contains("d-none");
    }

    //window.jQuery('.sidebar').removeClass('d-none')
    //window.jQuery('.sidebar').removeClass('active');
    //window.jQuery('.overlay').removeClass('active');
  }

  render() {
    const breadcrumbs = [
      {
        title: Links[0].title,
        url: Links[0].url,
      },
      {
        title: Links[14].title,
        url: Links[14].url,
      },
    ];

    return (
      <section className="items-grid section mt-5 mb-5">
        <div className="container mt-4">
          <TitlePage
            titlePage={this.titlePage}
            breadcrumbs={breadcrumbs}
            position={"left"}
            leftfull={true}
          />

          <a
            className="btn btn-primary btn-disabled open-menu"
            href="#"
            role="button"
            onClick={this.handleOpenMenu}
          >
            <i className="fa fa-search"></i> <span>Menú</span>
          </a>

          <div className="container-fluid">
            {this.state.requireAuthenticacion ? (
              <form
                action=""
                className="contact__form needs-validation center-login"
                name={this.id_form_verified_code}
                id={this.id_form_verified_code}
                method="post"
                noValidate
                onSubmit={this.handleVerifiedCodeSubmit}
              >
                <div className="row">
                  <div className="col-0 col-md-3"></div>

                  <div className="col-12 col-md-6">
                    <div
                      className="alert alert-success"
                      role="alert"
                      id="divAlertRegisterUser"
                      style={{ fontSize: "1.3rem" }}
                    ></div>
                  </div>

                  <div className="col-0 col-md-3"></div>
                </div>

                <div className="row">
                  <div className="col-0 col-md-3 "></div>

                  <div className="col-12 col-md-4 mb-3">
                    <label htmlFor="reporte[codigo]">Código de Acceso</label>
                    <input
                      name="reporte[codigo]"
                      type="text"
                      className="form-control"
                      placeholder="Código de Acceso"
                      data-parsley-required="true"
                    />
                  </div>

                  <div className="col-0 col-md-2 mb-3 pt-2">
                    <div className=" search-btn button ">
                      <input
                        type="submit"
                        className="btn btn-full-screen"
                        name="Verificar"
                        value="Verificar"
                      />
                    </div>
                  </div>

                  <div className="col-0 col-md-3"></div>
                </div>
              </form>
            ) : !this.state.showMap ? (
              <>
                {this.Auth.loggedIn() ? (
                  <HeaderTotales fetch={this.fetch} />
                ) : (
                  ""
                )}

                {this.Auth.loggedIn() ? (
                  <GraficaLineal fetch={this.fetch} />
                ) : (
                  ""
                )}

                <div className="row">
                  <div className="col-md-8">
                    <div className="row">
                      {this.Auth.loggedIn() ? (
                        <ItemsRecently fetch={this.fetch} />
                      ) : (
                        ""
                      )}
                      {this.Auth.loggedIn() ? (
                        <LastMembers fetch={this.fetch} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {this.Auth.loggedIn() ? (
                    <GraficaTorta fetch={this.fetch} />
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              <div className="row">
                {this.Auth.loggedIn() ? (
                  <MapCatastro
                    fetch={this.fetch}
                    languaje={Languaje}
                    onSubmitForm={this.handleSubmitForm}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
          </div>

          {!this.state.requireAuthenticacion ? (
            <nav className="sidebar d-none">
              <div className="dismiss">
                <i className="fa fa-arrow-right"></i>
              </div>

              <ul
                className="nav nav-tabs nav-custom justify-content-center"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    <i className="fa fa-map"></i>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    <i className="fa fa-file"></i>
                  </a>
                </li>
              </ul>
              {this.Auth.loggedIn() ? (
                <div className="tab-content" id="tabAdministracion">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <CollapseMapa
                      derecho_admision={1}
                      buttonName={Languaje.search}
                      languaje={Languaje}
                      onSubmitForm={this.handleSubmitForm}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <CollapseReport
                      derecho_admision={1}
                      buttonName={Languaje.search}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </nav>
          ) : (
            ""
          )}

          <ToastContainer
            enableMultiContainer
            containerId={"Z"}
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
          <ToastContainer />
        </div>
      </section>
    );
  }
}

export default Gerencia;
