import React, { Component } from "react";
import Fetch from "../utils/Fetch";
import { Link } from "react-router-dom";
import config from "../../data/config";

class ModalGacetaTributaria extends Component {
  constructor(props, context) {
    super(props, context);
    this.idModalGT = "modalGacetaTributariaFull";
    this.formModalGT = "formModalGacetaTributaria";

    this.handleVoidEnter = this.handleVoidEnter.bind(this);

    this.state = {
      optionsTypes: undefined,
      optionsGestion: undefined,
    };

    this.fetch = new Fetch();
    this.fetch.setToast(this.props.toast);
  }

  componentDidMount() {
    const domModal = document.getElementById("modalGacetaTributariaFull");
    const modalGT = new window.bootstrap.Modal(domModal, {
      keyboard: true,
      backdrop: "static",
      focus: true,
    });
    modalGT.show();
    this.loadTypes();
    this.loadGestion();

    domModal.addEventListener("shown.bs.modal", (event) => {
      const gacetaTributariaDb = this.props.gacetaTributariaDb;

      if (gacetaTributariaDb) {
        const selectClasificador = document.querySelector(
          "select[name='gt_gaceta_tributaria[id_types]']"
        );

        const optionsClasificador = selectClasificador.options;
        debugger;
        for (let index = 0; index < optionsClasificador.length; index++) {
          const option = optionsClasificador[index];
          if (option.getAttribute("code") === gacetaTributariaDb.type_code)
            selectClasificador.value = option.value;
        }

        const selectGestion = document.querySelector(
          "select[name='gt_gaceta_tributaria[id_gestion]']"
        );
        const optionsGestion = selectGestion.options;

        for (let j = 0; j < optionsGestion.length; j++) {
          const option = optionsGestion[j];
          if (
            parseInt(option.getAttribute("name"), 10) ===
            parseInt(gacetaTributariaDb.gestion_name, 10)
          )
            selectGestion.value = option.value;
        }

        const inputNumber = document.querySelector(
          "input[name='gt_gaceta_tributaria[number]']"
        );
        inputNumber.value = gacetaTributariaDb.number;

        const textAreaTitle = document.querySelector(
          "textarea[name='gt_gaceta_tributaria[title]']"
        );
        textAreaTitle.value = gacetaTributariaDb.title;
        textAreaTitle.parentElement.firstChild.innerHTML =
          gacetaTributariaDb.title.length;

        const textAreaDescription = document.querySelector(
          "textarea[name='gt_gaceta_tributaria[description]']"
        );
        textAreaDescription.value = gacetaTributariaDb.description;
        textAreaDescription.parentElement.firstChild.innerHTML =
          gacetaTributariaDb.description.length;

        debugger;
        const fileDb = new File(
          ["Hello World!"],
          config[0].url + this.props.gacetaTributariaDb.document_url,
          {
            type: "application/pdf",
            lastModified: new Date(),
          }
        );
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(fileDb);

        const fileInput = document.querySelector(
          'input[name="gt_documents_file"]'
        );
        fileInput.files = dataTransfer.files;

        let eInput = new Event("change", { bubbles: true });
        fileInput.dispatchEvent(eInput);
      }
    });
  }

  loadTypes() {
    const self = this;
    const types = this.fetch.fetchGet(`api/gaceta-tributaria/types/all`);
    types.then((res) => {
      if (res && res.status && res.GTTypes) {
        const listItems = res.GTTypes.map((item, index) => {
          return (
            <option
              key={index}
              value={item.id}
              name={item.name}
              code={item.code}
            >
              {item.name} ({item.code})
            </option>
          );
        });
        self.setState({ optionsTypes: listItems });
      }
    });
  }

  loadGestion() {
    const date = new Date();
    const self = this;
    this.fetch
      .axiosAsyncGet(`api/gestion/range-year/2010/${date.getFullYear()}`)
      .then((response) => {
        if (response && response.status) {
          const optionsGestions = response.data.gestion.map(
            ({ id, name, active }, index) => {
              return (
                <option key={index} value={id} name={name}>
                  {name}
                </option>
              );
            }
          );
          self.setState({ optionsGestion: optionsGestions });
        }
      });
  }

  handleVoidEnter(e) {
    e.preventDefault();
    let urlAction = "api/gaceta-tributaria/create";
    let isNew = true;
    if (this.props.gacetaTributariaDb) {
      urlAction = "api/gaceta-tributaria/update";
      isNew = false;
    }
    debugger;
    this.validateForm(e, urlAction, isNew);
  }

  validateForm = async (event, urlAction, isNew) => {
    window.inputConvertUpperCaseForm(this.formModalGT);
    window.textAreaConvertUpperCaseForm(this.formModalGT);

    window
      .jQuery("#" + this.formModalGT)
      .parsley()
      .validate();

    debugger;
    let formHtml = event.target;
    const formData = new FormData(formHtml);
    let existFile = false;

    for (let form of formData.entries()) {
      debugger;
      console.log(form[0] + ", " + form[1]);
      if (form[0].includes("gt_document_file_container_div_input")) {
        formData.append("gt_documents_file", form[1]);
        formData.delete("gt_document_file_container_div_input");
        existFile = true;
      }
    }

    if (!existFile && !isNew) formData.delete("gt_documents_file");

    if (
      window
        .jQuery("#" + formHtml.getAttribute("id"))
        .parsley()
        .isValid()
    ) {
      const saveGacetaTributaria = await this.fetch.fetchPost(
        formData,
        urlAction,
        formHtml
      );
      debugger;
      if (saveGacetaTributaria && saveGacetaTributaria.status) {
        document.getElementById("btnCloseModalGacetaTributaria").click();
        this.props.toast.success(saveGacetaTributaria.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else {
      this.props.toast.warning("El formulario tiene valores obligatorios", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  hanldeOnchangeInputFile(event) {
    debugger;
    const inputFile = event.target;
    if (inputFile) {
      const divContainerFather = inputFile.closest(".row");

      let iconFile = inputFile.parentElement.parentElement.querySelector("i");
      if (iconFile) inputFile.parentElement.parentElement.removeChild(iconFile);

      if (inputFile.files.length > 0) {
        const format = inputFile.files[0].type.split("/");
        if (/^(|pdf)$/i.test(format[1])) {
          const icono = document.createElement("i");
          if (format[1] === "pdf") icono.className = "fas fa-file-pdf icon";

          inputFile.parentElement.parentElement.appendChild(icono);

          const arrayNameFile = inputFile.files[0].name.split("/");
          const nameFile = arrayNameFile[arrayNameFile.length - 1];

          divContainerFather.getElementsByClassName("nameFile")[0].innerHTML =
            (nameFile.length >= 30
              ? "..." +
                nameFile.substring(nameFile.length - 30, nameFile.length)
              : nameFile) +
            " - " +
            window.niceBytes(inputFile.files[0].size);

          window.parsley.addValidator("maxFileSize", {
            requirementType: "integer",
            validateString: function (value, maxSize, parsleyInstance) {
              const files = parsleyInstance.$element[0].files;
              return files.length !== 1 || files[0].size <= maxSize * 1024;
            },
            messages: {
              es: "El archivo no debe superar los %s Kb",
            },
          });

          debugger;
          if (
            this.props.gacetaTributariaDb &&
            !(
              inputFile.files[0].name.includes("https") ||
              inputFile.files[0].name.includes("http")
            )
          ) {
            debugger;
            const parentFile = inputFile.parentElement.parentElement;
            const id = parentFile.getAttribute("id");
            window.create_input_hidden(
              "1",
              "gt_document_file_" + id.replace(/-/g, "_"),
              id
            );
          }
        } else
          this.props.toast.warning("Formato no valido", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
      }
    }
  }

  closeModal(e) {
    this.props.openModalGacetaTributaria(e, false);
  }

  render() {
    return (
      <div
        className="modal fade "
        id={this.idModalGT}
        tabIndex="-1"
        aria-labelledby="modalGacetaTributariaFullTitle"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        style={{ overflowY: "auto" }}
      >
        <div
          className="modal-dialog modal-lg"
          style={{ boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.75)" }}
        >
          <div className="modal-content ">
            <div className="modal-header text-butterfly-bush justify-content-center">
              <h4
                className="modal-title "
                id="modalGacetaTributariaLongTitle"
                style={{ fontWeight: 600 }}
              >
                GACETA TRIBUTARIA
              </h4>

              <button
                type="button"
                className="btn-close h6 me-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="btnCloseModalGacetaTributaria"
                onClick={(e) => this.closeModal(e)}
              ></button>
            </div>
            <div className="modal-body">
              <form
                action=""
                className="contact__form"
                style={{ margin: "0.5rem" }}
                onSubmit={this.handleVoidEnter}
                id={this.formModalGT}
                name={this.formModalGT}
                noValidate
              >
                {this.props.gacetaTributariaDb ? (
                  <input
                    type={"hidden"}
                    name={"gt_gaceta_tributaria[id]"}
                    value={this.props.gacetaTributariaDb.id_gaceta_tributaria}
                  />
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-12 col-md-4 col-lg-4 mb-3">
                    <label
                      htmlFor={"gt_gaceta_tributaria[id_types]"}
                      className="form-label"
                    >
                      Clasificador *
                    </label>
                    <select
                      className="form-select"
                      name={"gt_gaceta_tributaria[id_types]"}
                      required
                      id={"gt_gaceta_tributaria[id_types]"}
                      data-parsley-required="true"
                    >
                      <option value="">Seleccione Clasificador</option>
                      {this.state.optionsTypes}
                    </select>
                  </div>

                  <div className="col-12 col-md-4 col-lg-4 mb-3">
                    <label
                      htmlFor={"gt_gaceta_tributaria[id_gestion]"}
                      className="form-label"
                    >
                      Gestión *
                    </label>
                    <select
                      className="form-select"
                      name={"gt_gaceta_tributaria[id_gestion]"}
                      required
                      data-parsley-required="true"
                    >
                      <option value="">Seleccione Gestión</option>
                      {this.state.optionsGestion}
                    </select>
                  </div>

                  <div className="col-12 col-md-4 col-lg-4 mb-3">
                    <label
                      htmlFor={"gt_gaceta_tributaria[number]"}
                      className="form-label"
                    >
                      Número *
                    </label>
                    <input
                      name={"gt_gaceta_tributaria[number]"}
                      id={"gt_gaceta_tributaria[number]"}
                      type="text"
                      className="form-control input-uppercase"
                      placeholder="Número"
                      required
                      data-parsley-required="true"
                      data-parsley-pattern="[0-9]+"
                      data-parsley-number="1"
                      data-parsley-special="1"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 mb-3">
                    <span className="float-end text-fountain-blue">0</span>
                    <label
                      htmlFor={"gt_gaceta_tributaria[title]"}
                      className="form-label"
                    >
                      Título *
                    </label>
                    <textarea
                      name={"gt_gaceta_tributaria[title]"}
                      id={"gt_gaceta_tributaria[title]"}
                      required
                      data-parsley-required="true"
                      className="form-control input-uppercase"
                      placeholder="Título"
                      pattern='[0-9 ,.A-ZÓÁÉÍÚ\"\/]+'
                      data-parsley-pattern='[0-9 ,.A-ZÓÁÉÍÚ\"\/]+'
                      data-parsley-trigger="keyup"
                      data-parsley-minlength="10"
                      data-parsley-maxlength="250"
                      data-parsley-validation-threshold="10"
                      rows="2"
                      onKeyUp={(event) => window.countCharts(event, 250)}
                      onBlur={(event) => window.countCharts(event, 250)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 mb-3">
                    <span className="float-end text-fountain-blue">0</span>
                    <label
                      htmlFor={"gt_gaceta_tributaria[description]"}
                      className="form-label"
                    >
                      Descripción *
                    </label>
                    <textarea
                      name={"gt_gaceta_tributaria[description]"}
                      id={"gt_gaceta_tributaria[description]"}
                      required
                      data-parsley-required="true"
                      className="form-control input-uppercase"
                      placeholder="Descripción"
                      pattern='[0-9 .,A-ZÓÁÉÍÚ\" \/]+'
                      data-parsley-pattern='[0-9 .,A-ZÓÁÉÍÚ\"\/]+'
                      data-parsley-trigger="keyup"
                      data-parsley-minlength="20"
                      data-parsley-maxlength="300"
                      data-parsley-validation-threshold="10"
                      rows="4"
                      onKeyUp={(event) => window.countCharts(event, 250)}
                      onBlur={(event) => window.countCharts(event, 300)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 mt-1 mb-3">
                    <label
                      htmlFor={"gt_documents[file]"}
                      className="form-label"
                    >
                      Documento*
                    </label>

                    {this.props.gacetaTributariaDb ? (
                      <Link
                        to={{
                          pathname:
                            config[0].url +
                            this.props.gacetaTributariaDb.document_url,
                        }}
                        title="Ver Documento"
                        target={"_blank"}
                        rel="noopener noreferrer"
                        className="float-end text-fountain-blue"
                      >
                        Ver Documento
                      </Link>
                    ) : (
                      ""
                    )}

                    <div
                      className="form-group files text-center"
                      id="container-div-input"
                    >
                      <label
                        tabIndex={"gt_documents[file]"}
                        className="form-control"
                      >
                        <input
                          type="file"
                          className="form-control"
                          multiple=""
                          id={`gt_documents_file`}
                          name={`gt_documents_file`}
                          required={true}
                          rows="4"
                          data-parsley-max-file-size="10000"
                          onChange={(e) => this.hanldeOnchangeInputFile(e)}
                        />
                      </label>
                      <small className="fs-small8em text-maroon-flush nameFile float-start">
                        ...
                      </small>
                      <small className="fs-small8em text-maroon-flush float-end">
                        10MB, Limite Máximo
                      </small>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-ms-6 col-md-6 col-lg-7 text-end"></div>
                  <div className="col-12 col-ms-3 col-md-3 col-lg-3 text-end"></div>

                  <div className="col-12 col-ms-3 col-md-3 col-lg-2 text-end">
                    <div className=" search-btn button mb-3 pointer">
                      <input
                        type="Submit"
                        className="btn btn-full-screen"
                        defaultValue={"Enviar"}
                        name="Enviar"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalGacetaTributaria;
