export default [
    {
        title: 'ApiRestServer',
        //url: 'http://192.168.103.49/restServerAtm/'  //production
        url: 'https://atmapi.cochabamba.bo/restServerAtm/'
    },
    {
        title: 'Año minimo fecha nacimiento',
        anio:  (new Date()).setFullYear( (new Date()).getFullYear() - 18 )
    },
    {
        title: 'Client Url',
        protocol: window.location.protocol,
        //url: 'http://192.168.103.49:3000'    //producction
        url: 'https://simat.cochabamba.bo'
    },
    {
        title: 'Token Authorization',
        token: 'token_auth_prod'
    },
    {
        title: 'Format Date',
        format: 'dd/MM/yyyy'
    },
    {
        title: 'Format Date Moment',
        format_moment: 'DD/MM/YYYY'
    },
    {
        title: 'Código Soporte Gerencial',
        token_sg: 'token_sg'
    },
    {
        title: 'Año Maximo Tramites',
        anio:  (new Date()).setFullYear( (new Date()).getFullYear() )
    },
    {
        title: 'Año Minimo Visación Minuta',
        anio:  1995
    },
    {
        title: 'Longitud Maxima Fur',
        furLongitud:  11
    },
    {
        title: 'ApiAdvertising',
        url: 'https://publicidadsimat.cochabamba.bo'
    }
];
