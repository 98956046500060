import React, { useEffect, useState, useRef } from 'react';
import FormRepresentanteLegal from './FormRepresentanteLegal'
import AsyncSelectCustom from '../../../components/component/AsyncSelectCustom'

let vehiculoTemp = undefined
const FormVehiculoServicioPublico = (props) => {

    const { fetch, placa, servicioPublicoDb, vehiculoRuatDb } = props

    const refSelectTipoServicio = useRef()
    const refSelectTipoOrganizacion = useRef()
    const refCheckBoxEstaAfiliado = useRef()
    const refInputSearchCiPropietario = useRef()

    const [esPropietario, setEsPropietario] = useState(true)
    const [dataPoseedor, setDataPoseedor] = useState(undefined)
    const [showMessageEsPropietario, setShowMessageEsPropietario] = useState(false)
    const [optionsTipoServicio, setOptionsTipoServicio] = useState([])
    const [optionsTipoOrganizacion, setOptionsTipoOrganizacion] = useState([])
    //const [vehiculoGamc, setVehiculoGamc] = useState(undefined)
    const [showForm, setShowForm] = useState(false)
    const [showFormAfiliadoA, setShowFormAfiliadoA] = useState(false)

    //select_linea_servicio_publico
    //id_clasificacion_organizacion_sindical
    const [asyncSelectServicioPublico, setAsyncSelectServicioPublico] = useState(undefined)
    const [asyncSelectClasificiacionOrganizacionSindical, setAsyncSelectClasificiacionOrganizacionSindical] = useState(undefined)

    useEffect(() => {
        findAllTipoServicio()
        findAllTipoOrganizacion()


        //seccion para la edicion
        if (servicioPublicoDb && vehiculoRuatDb) {
            //let propietario = servicioPublicoDb.certificado.propietario
            let terceroResponsable = servicioPublicoDb.certificado.terceroResponsable

            if (terceroResponsable)
                setEsPropietario(false)
            else
                setEsPropietario(false)
            setDataPoseedor(terceroResponsable)
            setShowForm(true)
            setTimeout(async () => {
                if (servicioPublicoDb.clasificacionOrganizacionSindical && servicioPublicoDb.lineaServicioPublico && servicioPublicoDb.organizacionSindical)
                    window.jQuery(refCheckBoxEstaAfiliado.current).bootstrapToggle('on')
                else
                    window.jQuery(refCheckBoxEstaAfiliado.current).bootstrapToggle('off')

                refInputSearchCiPropietario.current.value = servicioPublicoDb.placa
                refSelectTipoServicio.current.value = servicioPublicoDb.id_tipo_servicio
                refSelectTipoOrganizacion.current.value = servicioPublicoDb.clasificacionOrganizacionSindical.id
            }, 300);

            setTimeout(async () => {
                addEventCheckBoxAfiliado()
            }, 200);
        }

    }, []);

    const addEventCheckBoxAfiliado = () => {
        debugger
        let checkEstaAfiliado = refCheckBoxEstaAfiliado.current
        if (checkEstaAfiliado) {
            let events = window.jQuery._data(checkEstaAfiliado.parentElement, "events");
            if (!events || events.change.length === 0) {
                window.jQuery(checkEstaAfiliado.parentElement).change((event) => {
                    debugger
                    if (window.jQuery(event.target).prop('checked') === true) {
                        setShowFormAfiliadoA(true)
                        if (vehiculoTemp)
                            refSelectTipoOrganizacion.current.value = vehiculoTemp.tipoOrganizacion.id

                        if (servicioPublicoDb && servicioPublicoDb.clasificacionOrganizacionSindical
                            && servicioPublicoDb.lineaServicioPublico
                            && servicioPublicoDb.organizacionSindical) {

                                debugger
                            let slclasificacionOrganizacionSindical = paintAsyncSelect('vh_vehiculo[id_clasificacion_organizacion_sindical]',
                                `api/certificacion/vehiculo/organizacion-sindical/`, "Afiliado A", servicioPublicoDb.organizacionSindical)
                            setAsyncSelectClasificiacionOrganizacionSindical(slclasificacionOrganizacionSindical)

                            let select_linea_servicio_publico = paintAsyncSelect('vh_vehiculo[id_linea_servicio_publico]',
                                `api/certificacion/vehiculo/linea-servicio-publico`, "Linea de Servicio Público", servicioPublicoDb.lineaServicioPublico)
                            setAsyncSelectServicioPublico(select_linea_servicio_publico)
                        } else {
                            let slclasificacionOrganizacionSindical = paintAsyncSelect('vh_vehiculo[id_clasificacion_organizacion_sindical]',
                                `api/certificacion/vehiculo/organizacion-sindical/`, "Afiliado A", undefined)
                            setAsyncSelectClasificiacionOrganizacionSindical(slclasificacionOrganizacionSindical)

                            let select_linea_servicio_publico = paintAsyncSelect('vh_vehiculo[id_linea_servicio_publico]',
                                `api/certificacion/vehiculo/linea-servicio-publico`,
                                "Linea de Servicio Público", vehiculoTemp ? vehiculoTemp.lineaServicioPublico : undefined)
                            setAsyncSelectServicioPublico(select_linea_servicio_publico)
                        }
                    } else {
                        setShowFormAfiliadoA(false)
                    }
                })
            }
        }
    }

    const findAllTipoServicio = () => {
        const tipo_doc_res = fetch.fetchGet(`api/tipo-servicios`);
        tipo_doc_res.then(res => {
            if (res && res.status) {
                if (res.TipoServicio.length > 0) {  //is ok
                    const listItems = res.TipoServicio.map((item, index) => {
                        return <option key={index} value={item.id} code={item.code}>{item.name}</option>
                    });
                    setOptionsTipoServicio(listItems)
                }
            }
        })
    }

    const findAllTipoOrganizacion = () => {
        const tipo_doc_res = fetch.fetchGet(`api/tipo-organizaciones`);
        tipo_doc_res.then(res => {
            if (res && res.status) {
                if (res.TipoOrganizacion.length > 0) {  //is ok
                    const listItems = res.TipoOrganizacion.map((item, index) => {
                        return <option key={index} value={item.id} code={item.code}>{item.name}</option>
                    });
                    setOptionsTipoOrganizacion(listItems)
                }
            }
        })
    }

    const handleKeyEnterSearchByCi = async (event) => {
        if (event.charCode === 13) {
            event.preventDefault()
            let documentoIdentidad = event.target.value
            if (documentoIdentidad !== "" && documentoIdentidad.length <= 8) {
                await searchDataVehiculoGamc(documentoIdentidad, placa)
            }

        }
    }

    const handleSearchByCi = async (event) => {
        event.preventDefault()
        let documentoIdentidad = event.target.closest('.input-group').firstChild.value
        if (documentoIdentidad !== "" && documentoIdentidad.length <= 7) {
            await searchDataVehiculoGamc(documentoIdentidad, placa)
            setTimeout(() => {
                debugger
                window.jQuery(function () { window.jQuery('.toogle-input').bootstrapToggle() });
                if (vehiculoTemp) {
                    refSelectTipoServicio.current.value = vehiculoTemp.tipoServicio.id
                }
                addEventCheckBoxAfiliado()
            }, 300);
        }
    }

    const searchDataVehiculoGamc = async (documentoIdentidad, placa) => {

        const dataJson = await fetch.fetchGet('api-ruat/vehiculos/es-propietario/' + placa + '/' + documentoIdentidad);
        if (dataJson && dataJson.status && dataJson.data) {
            const dataJsonVehiculo = await fetch.fetchGet('api-gamc/vehiculos-gamc/consulta-datos/' + placa);
            debugger
            if (dataJsonVehiculo && dataJsonVehiculo.status && dataJsonVehiculo.data) {
                vehiculoTemp = undefined
                debugger
                let vehiculo = dataJsonVehiculo.data.vehiculo
                //setVehiculoGamc(vehiculo)
                vehiculoTemp = vehiculo
            }

            const dataJsonPropietario = await fetch.fetchGet('api-ruat/vehiculos/propietario-por-nro-placa/' + placa + "/" + documentoIdentidad);
            if (dataJsonPropietario && dataJsonPropietario.status && dataJsonPropietario.data.propietario) {
                setDataPoseedor(dataJsonPropietario.data.propietario)
            } else {
                const dataJsonPropietarioGAMC = await fetch.fetchGet('api/recaudaciones/persona-by-ci-no-auth/' + documentoIdentidad);
                if (dataJsonPropietarioGAMC && dataJsonPropietarioGAMC.status) {
                    setDataPoseedor(dataJsonPropietarioGAMC.persona_natural)
                }
            }

            setEsPropietario(dataJson.data.esPropietario)
            setShowMessageEsPropietario(true)
            setShowForm(true)

            fetch.toast.success(dataJson.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        } else {
            setEsPropietario(false)
            setShowMessageEsPropietario(false)
            setShowForm(false)
            fetch.toast.warn("Vuelva a intentarlo.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    const paintAsyncSelect = (name, url, placeHolder, defaultValue) => {
        return <div className="col-12 col-md-4 col-lg-4 form-group">
            <label htmlFor={name} >{placeHolder}:</label>
            <AsyncSelectCustom placeholder={placeHolder} fetch={fetch} name={name}
                url={`${url}`} defaultValue={defaultValue} />
        </div>
    }

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-4 col-lg-4 form-group">
                </div>
                <div className="col-12 col-md-4 col-lg-4 form-group">
                    <div className="input-group mb-3">
                        <input name="certificado_servicio_publico[ci]" type="text" className="form-control" placeholder="Documento Identificación *" ref={refInputSearchCiPropietario}
                            data-parsley-required="true" onKeyPress={handleKeyEnterSearchByCi} />
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="button" onClick={handleSearchByCi}><i className="fa fa-search" aria-hidden="true" ></i></button>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 form-group mt-2 text-center">
                    {
                        showMessageEsPropietario ?
                            esPropietario ?
                                <div><strong >Es Propietario </strong> SI </div>
                                :
                                <div><strong >Es Propietario </strong> NO </div>
                            : ""
                    }
                </div>
            </div>

            {
                showForm ?
                    !esPropietario ?
                        <FormRepresentanteLegal fetch={fetch} dataPropietario={dataPoseedor} />
                        :
                        ""
                    : ""
            }
            <br />
            {
                showForm ?
                    <div className="row">
                        <div className="col-12 mb-3">
                            <h4>Servicio Público</h4>
                        </div>
                    </div>
                    : ""
            }
            {
                showForm ?
                    <div className="row">
                        <div className="col-12 col-md-4 col-lg-4 mb-3">
                            <label htmlFor="vh_vehiculo[id_tipo_servicio]" className="form-label">Tipo Servicio:</label>
                            <select className="form-select" name="vh_vehiculo[id_tipo_servicio]"
                                data-parsley-required="true" ref={refSelectTipoServicio}>
                                <option defaultValue value='' >Seleccione Tipo Servicio</option>
                                {optionsTipoServicio}
                            </select>
                        </div>

                        <div className="col-12 col-md-4 col-lg-4 mb-3">
                            <label htmlFor="vh_vehiculo[esta_afiliado]" className="d-block form-label">Se Encuentra Afiliado?:</label>
                            <input data-toggle="toggle" data-onstyle="secondary" className="toogle-input" data-width="20%"
                                type="checkbox" name="vh_vehiculo[esta_afiliado]" id="vh_vehiculo[esta_afiliado]"
                                data-on="SI" data-off="No"
                                ref={refCheckBoxEstaAfiliado} />
                        </div>

                        {showFormAfiliadoA ?
                            <div className="col-12 col-md-4 col-lg-4 mb-3">
                                <label htmlFor="vh_vehiculo[id_organizacion_sindical]" className="form-label">Tipo de Organización:</label>
                                <select className="form-select" name="vh_vehiculo[id_organizacion_sindical]"
                                    data-parsley-required="true" ref={refSelectTipoOrganizacion}>
                                    <option defaultValue value='' >Seleccione Tipo Organización</option>
                                    {optionsTipoOrganizacion}
                                </select>
                            </div>
                            : ""
                        }

                    </div>
                    : ""
            }

            {showForm && showFormAfiliadoA ?
                <div className="row">
                    { /* <div className="col-12 col-md-8 col-lg-8 mb-3">
                        <label htmlFor="vh_vehiculo[id_clasificacion_organizacion_sindical]" >Afiliado A:</label>
                        <AsyncSelectCustom placeholder="Afiliado A" fetch={fetch} name={'vh_vehiculo[id_clasificacion_organizacion_sindical]'}
                            url={`api/certificacion/vehiculo/organizacion-sindical/`} />
            </div>*/}
                    {asyncSelectClasificiacionOrganizacionSindical}

                    { /* 
                    <div className="col-12 col-md-4 col-lg-4 mb-3">
                        <label htmlFor="vh_vehiculo[id_linea_servicio_publico]" >Linea de Servicio Público:</label>
                        <AsyncSelectCustom placeholder="Linea de Servicio Público" fetch={fetch} name={'vh_vehiculo[id_linea_servicio_publico]'}
                            url={`api/certificacion/vehiculo/linea-servicio-publico`} defaultValue={vehiculoGamc ? vehiculoGamc.lineaServicioPublico : undefined} />
                    </div>
                    */}
                    {asyncSelectServicioPublico}
                </div>
                : ""
            }
        </>
    )
}

export default FormVehiculoServicioPublico