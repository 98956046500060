import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fetch from "../utils/Fetch";
import AutorizationComunication from "../../components/utils/AutorizationComunication";
import FormInmueble from "./FormInmueble";
import FormOperador from "./FormOperador";
import FormRequisitos from "./FormRequisitos";
import FormRestriction from "./FormRestriction";
import FormVistaPrevia from "./FormVistaPrevia";
import {
  formInmueble,
  formDireccion,
  formPropiedad,
  formColindantes,
  formContrucciones,
  formContruccionesAdicionales,
  formDatosTecnicos,
  formTerreno,
  formServiciosBasicos,
  formProviders,
} from "../../components/utils/FormDataInmueble";

const Form = (props) => {
  const fetch = new Fetch();
  fetch.setToast(toast);

  const { dataInmueble } = props;
  const autorizationClass = new AutorizationComunication();

  const [step, setStep] = useState(0);
  const [autorization, setAutorization] = useState(undefined);

  const onSubmitForm = (event, dataForm) => {
    debugger;
    console.log("propiesdass ", props);
    event.preventDefault();
    window
      .jQuery("#" + event.target.getAttribute("id"))
      .parsley()
      .validate();

    let formHtml = event.target; //event.target.getAttribute('id');
    const form = new FormData(event.target);
    const target = event.target;

    if (
      window
        .jQuery("#" + formHtml.getAttribute("id"))
        .parsley()
        .isValid()
    ) {
      const text =
        formHtml.getAttribute("id") === "formDataSell"
          ? "Para continuar con la siguiente sección, el Contribuyente debe tener su PMC actualizado"
          : "Esta Seguro de Continuar.";
      window.createBootbox(text, function (result) {
        if (result === true) {
          switch (formHtml.getAttribute("id")) {
            case "formDataInmueble":
              submitFormDataInmueble(form, target, dataForm);
              break;
            case "formDataOperator":
              submitFormToOperador(form, target, dataForm);
              break;
            case "formDataRequisitos":
              submitFormRequisitos(form, target, dataForm);
              break;
            case "formDataRestriction":
              submitFormRestriction(form, target, dataForm);
              break;
            default:
              break;
          }
        }
      });
    } else {
      toast.warn("El formulario tiene valores obligatorios", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const submitFormDataInmueble = async (form, target, data) => {
    debugger;
    form.append("saat_category[code]", data.inmueble.tipoTramite.codcat);

    form.append(
      "saat_type_procedure[code]",
      data.inmueble.tipoTramite.codtiptramite
    );
    form.append("saat_autorization[fur]", data.inmueble.derechoAdmision.fur);
    if(data.inmueble.tipoTramite.area){
      form.append("saat_autorization[publico]", 0);
    }else
      form.append("saat_autorization[publico]", 1);
    form.append(
      "saat_autorization[area_inmueble]",
      data.inmueble.tipoTramite.area ? data.inmueble.tipoTramite.area : ""
    );
    form.append("saat_autorization[id_tower]", data.inmueble.tower.id);
    if (data.inmueble.fechaInicioTramite)
      form.append(
        "saat_autorization[fecha_inicio_tramite]",
        data.inmueble.fechaInicioTramite.replace(/-/g, "/")
      );

    formInmueble(form, data);
    formDireccion(form, data);
    formPropiedad(form, data);
    formColindantes(form, data);
    formContrucciones(form, data);
    formContruccionesAdicionales(form, data);
    formDatosTecnicos(form, data);
    formTerreno(form, data);
    formServiciosBasicos(form, data);

    const saveAutorization = await fetch.fetchPost(
      form,
      "api/saat/crear",
      target
    );
    if (saveAutorization && saveAutorization.status) {
      debugger;
      if (saveAutorization.data) {
        autorizationClass.setToken(
          saveAutorization.data.saatTelecomunicacion.token
        );
        autorizationClass.setNumeroOrden(
          saveAutorization.data.saatTelecomunicacion.numero
        );
        autorizationClass.setFur(data.inmueble.derechoAdmision.fur);
        setStep(1);
        setAutorization(autorizationClass);

        toast.success(saveAutorization.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.warn(saveAutorization.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  const submitFormToOperador = async (form, target, data) => {
    debugger;

    form.append("saat_autorization[token]", autorization.token);
    form.delete("saat_autorization[pmc_search]");

    formProviders(form, data);
    debugger;
    form.delete("datos_juridicos[id_tipo_documento]");

    form.delete("representante_legal[id_tipo_documento]");
    if (data.apoderado.tipoDocumentoCode === "CI") {
      form.append("representante_legal[id_tipo_documento]", 1);
    }

    if (data.apoderado.tipoDocumentoCode === "CE") {
      form.append("representante_legal[id_tipo_documento]", 2);
    }

    const titularPropietario = await fetch.fetchPost(
      form,
      "api/saat/operador-crear",
      target
    );
    if (titularPropietario && titularPropietario.status) {
      setStep(2);
      toast.success(titularPropietario.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const submitFormRequisitos = async (form, target, data) => {
    debugger;
    form.append("saat_autorization[token]", autorization.token);

    const resqRequisitos = await fetch.fetchPost(
      form,
      "api/saat/requisito-autorizacion",
      target
    );
    if (resqRequisitos && resqRequisitos.status) {
      setStep(3);
      toast.success(resqRequisitos.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const submitFormRestriction = async (form, target, data) => {
    debugger;
    form.append("saat_autorization[token]", autorization.token);

    data.map((row, index) => {
      form.append(`saat_location_restriction[${index}][id]`, row.id);
      form.append(
        `saat_location_restriction[${index}][response]`,
        row.selected ? 1 : 0
      );
      return 0;
    });

    const resqRestricciones = await fetch.fetchPost(
      form,
      "api/saat/restriccion-crear",
      target
    );
    if (resqRestricciones && resqRestricciones.status) {
      setStep(4);
      toast.success(resqRestricciones.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <>
      <div className="row">
        {step === 0 ? (
          <FormInmueble
            dataInmueble={dataInmueble}
            fetch={fetch}
            nameForm={"saat_provider"}
            onSubmitForm={onSubmitForm}
          />
        ) : (
          ""
        )}

        {step === 1 ? (
          <FormOperador
            dataInmueble={dataInmueble}
            dataPropietario={dataInmueble.propietario}
            fetch={fetch}
            nameForm={"saat_autorization"}
            onSubmitForm={onSubmitForm}
          />
        ) : (
          ""
        )}

        {step === 2 ? (
          <FormRequisitos
            dataInmueble={dataInmueble}
            dataPropietario={dataInmueble.propietario}
            fetch={fetch}
            onSubmitForm={onSubmitForm}
          />
        ) : (
          ""
        )}

        {step === 3 ? (
          <FormRestriction
            dataInmueble={dataInmueble}
            dataPropietario={dataInmueble.propietario}
            fetch={fetch}
            onSubmitForm={onSubmitForm}
          />
        ) : (
          ""
        )}

        {step === 4 ? (
          <FormVistaPrevia
            dataInmueble={dataInmueble}
            dataPropietario={dataInmueble.propietario}
            autorization={autorization}
            fetch={fetch}
            //onSubmitForm={onSubmitForm}
          />
        ) : (
          ""
        )}
      </div>

      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Form;
