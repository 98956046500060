import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../style/breadcrumb.css';

const PasosNavigationCambioRadicatoria = ({ titulo_paso1, paso1_active, paso2_active,
    paso3_active }) => {

    var detector = new window.MobileDetect(window.navigator.userAgent)
    if( Boolean(detector.mobile()) ){
        if(paso1_active && !paso2_active && !paso3_active)
            paso1_active = true
        
        if(paso1_active && paso2_active && !paso3_active ){
            paso1_active = false
            paso2_active = true
        }

        if(paso1_active && paso2_active && paso3_active ){
            paso1_active = false
            paso2_active = false
            paso3_active = true
        }
    }
    return (
        <ol className="col-12 breakcrumb-solo mb-3" >
        <li className={paso1_active ? 'active' : "display-active"}>
            <span className={'me-1 '+ (paso1_active ? 'paso1-active icon-vehiculo-active' : "")}></span>
            <span className={ paso1_active ? 'texto-line' : "texto_free"}>{titulo_paso1}</span>
        </li>

        
        <li className={paso2_active ? ' active' : "display-active"}>
            <span className={'me-1 '+ (paso2_active ? 'paso2-active icon-persona-active' : "paso2 icon-persona")}></span>
            <span className={paso2_active ? 'texto-line' : "texto_free"}>Datos del Poseedor</span>
        </li>

        <li className={paso3_active ? 'active' : "display-active"}>
            <span className={'me-1 '+ (paso3_active ? 'paso3-active icon-preview-active' : "paso3 icon-preview")}></span>
            <span className={paso3_active ? 'texto-line' : "texto_free"}>Vista Previa</span>
        </li>
    </ol>)
};

PasosNavigationCambioRadicatoria.prototype = {
    titulo_paso1: PropTypes.string.isRequired,
    paso1_active: PropTypes.bool.isRequired,
    paso2_active: PropTypes.bool.isRequired,
    paso3_active: PropTypes.bool.isRequired,
    paso4_active: PropTypes.bool.isRequired,
    paso5_active: PropTypes.bool.isRequired
}

export default PasosNavigationCambioRadicatoria;