import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'

import ModalPlaca from '../../component/Placa/ModalPlaca';
import Fetch from '../../../components/utils/Fetch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Texto from '../../../data/es'
import FormVehiculoServicioPublico from './FormVehiculoServicioPublico'
import DatosIdentificacionVehiculo from './DatosIdentificacionVehiculo'
import DatosTecnicosVehiculo from './DatosTecnicosVehiculo'
import DatosDeudaVehiculo from './DatosDeudaVehiculo'
import DatosEstadoVehiculo from './DatosEstadoVehiculo'

const CreateCertification = (props) => {

    const { servicioPublicoDb, vehiculoRuatDb } = props

    const fetch = new Fetch();
    fetch.setToast(toast);

    const history = useHistory();

    const [modalPlaca, setModalPlaca] = useState(undefined);

    const [isNew, setIsNew] = useState(true)
    const [showForm, setShowForm] = useState(false)
    const [showMessage, setShowMessage] = useState("")
    const [placa, setPlaca] = useState(false)

    const [dataVehiculo, setDataVehiculo] = useState(undefined)
    const [dataDeudas, setDataDeudas] = useState([])
    const [dataDeudasSolicitadasCeritificacion, setDataDeudasSolicitadasCeritificacion] = useState([])
    const [dataObservaciones, setDataObservaciones] = useState([])
    const [dataObservado, setDataObservado] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0);
        let modalPlacaCreate = new window.bootstrap.Modal(document.getElementById('modalPlacaFull'), {
            keyboard: true,
            backdrop: 'static',
            focus: true
        })
        modalPlacaCreate.show()
        setModalPlaca(modalPlacaCreate);
    }, []);

    const closeModal = (certificacionVehiculo, message) => {
        modalPlaca.hide();
        document.getElementById('modalPlacaFull').addEventListener('hidden.bs.modal', function (event) {
            console.log("modal se ha cerrado.....", certificacionVehiculo);
            debugger
            setDataVehiculo(certificacionVehiculo.datosTecnicos);
            setDataDeudas(certificacionVehiculo.datosDeudas);
            setDataObservaciones(certificacionVehiculo.datosObservacion);
            setDataObservado(certificacionVehiculo.observado);

            setShowForm(true);
            setIsNew(true);
            setShowMessage(message);
            setPlaca(certificacionVehiculo.placa);
        });
    }

    const handleSubmitForm = (event) => {
        event.preventDefault()

        debugger
        window.jQuery("#" + event.target.getAttribute('id')).parsley().validate();

        let form_html = event.target;
        const form = new FormData(event.target);
        var target = event.target

        
        const vehiculo = getDataVehiculo();

        let deudas = vehiculo.dataDeudas
        let obervaciones = vehiculo.dataObservaciones
        let informacion = vehiculo.dataVehiculo

        form.append('vh_vehiculo[placa]', informacion.pl);
        //form.append('vh_vehiculo[poliza]', "");
        //form.append('vh_vehiculo[placa_anterior]', "");
        //form.append('vh_vehiculo[numero_copia_placa_pta]', "");


        form.append('vh_dato_tecnico[clase]', informacion.cl);
        form.append('vh_dato_tecnico[categoria]', informacion.tv);
        form.append('vh_dato_tecnico[marca]', informacion.ma);
        form.append('vh_dato_tecnico[modelo]', informacion.md);
        //form.append('vh_dato_tecnico[pais]', informacion.cl);
        form.append('vh_dato_tecnico[servicio]', informacion.se);
        //form.append('vh_dato_tecnico[traccion]', informacion.cl);
        //form.append('vh_dato_tecnico[cilindrada]', informacion.cl);
        form.append('vh_dato_tecnico[color]', informacion.co);
        //form.append('vh_dato_tecnico[tipo_carroceria]', "");
        //form.append('vh_dato_tecnico[capacidad_carga]', "");
        form.append('vh_dato_tecnico[numero_puerta]', informacion.np);
        //form.append('vh_dato_tecnico[radicatoria]', "");
        //form.append('vh_dato_tecnico[tipo_vehiculo]', "");

        let cont_deuda = 0
        deudas.map((deuda) => {
            form.append('vh_gestion_servicio[' + cont_deuda + '][gestion]', deuda.ge)
            form.append('vh_gestion_servicio[' + cont_deuda + '][detalle]', deuda.cp)
            form.append('vh_gestion_servicio[' + cont_deuda + '][monto]', deuda.mt)
            form.append('vh_gestion_servicio[' + cont_deuda + '][monto_acumulado]', deuda.ma)
            form.append('vh_gestion_servicio[' + cont_deuda + '][base_imponible]', deuda.bi)
            form.append('vh_gestion_servicio[' + cont_deuda + '][solicitado]', deuda.solicitado)
            cont_deuda++
        })

        let cont_obs = 0
        obervaciones.map((observacion) => {
            form.append('vh_observacion[' + cont_obs + '][name]', observacion.dt)
            form.append('vh_observacion[' + cont_obs + '][fecha_registro]', observacion.fr)
            form.append('vh_observacion[' + cont_obs + '][id_tipo_observacion]', observacion.to)
            cont_obs++
        })

        if (window.jQuery("#" + form_html.getAttribute('id')).parsley().isValid()) {
            window.createBootbox("Esta Seguro de Continuar.", function (result) {
                if (result === true) {
                    fetch.fetchPost(form, 'api/certificacion-servicio-publico/create', target).then(dataJson => {
                        if (dataJson !== undefined && dataJson.status === true) {
                            debugger
                            let certificadoServicioPublico = dataJson.data.certificadoServicioPublico
                            history.push("/detalle-certificacion-transporte-publico/" + certificadoServicioPublico.token);
                        }
                    })
                }
            })
        }
    }

    const getDataVehiculo = () => {
        let deudas = dataDeudas.map(deuda => {
            deuda.solicitado = 0
            for (let index = 0; index < dataDeudasSolicitadasCeritificacion.length; index++) {
                if (parseInt(deuda.ge) === parseInt(dataDeudasSolicitadasCeritificacion[index].ge) &&
                    deuda.mt === dataDeudasSolicitadasCeritificacion[index].mt) {
                    deuda.solicitado = 1
                    break
                }
            }
            return deuda
        })
        
        return { dataVehiculo, dataDeudas: deudas, dataObservaciones, dataObservado };
    }

    const addDeudaToCertificacion = (event, deuda) => {
        event.preventDefault()
        let deudas = [...dataDeudasSolicitadasCeritificacion, deuda]
        setDataDeudasSolicitadasCeritificacion(deudas)
    }

    const removeDeudaToCertificacion = (event, deuda) => {
        event.preventDefault()
        let deudas = dataDeudasSolicitadasCeritificacion.filter(deudaOld => deudaOld.ge !== deuda.ge)
    }

    return (
        <div className="row mt-1">
            {showMessage !== "" ?
                <div className="row ">
                    <div className="col-12 ">
                        <div className="alert alert-warning alert-dismissible fade show h5" role="alert">
                            <strong>Alerta!</strong> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {showMessage}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
                : ""}

            {
                showForm ?
                    <form className="contact__form" style={{ margin: "0.5rem" }} name={'formServicioPublico'} id={'formServicioPublico'}
                        method="post" noValidate onSubmit={handleSubmitForm}>

                        <DatosIdentificacionVehiculo dataVehiculo={dataVehiculo} />
                        <DatosTecnicosVehiculo dataVehiculo={dataVehiculo} />

                        {
                            dataDeudas && dataDeudas.length > 0 ?
                                <DatosDeudaVehiculo dataDeudas={dataDeudas} nameFormGestionDeuda="tabla" addDeuda={addDeudaToCertificacion}
                                    removeDeuda={removeDeudaToCertificacion} activeEvent={true} />
                                : ""
                        }

                        {
                            dataObservaciones && dataObservaciones.length > 0 ?
                                <DatosEstadoVehiculo dataObservaciones={dataObservaciones} observacion={dataObservado} />
                                : ""
                        }

                        <div className="card widget widget-simple" >
                            <div className="card-body ">
                                <div className="row mb-3">
                                    <div className="col-12 widget-header">
                                        <h4 className="">{Texto.datos_propietario_tercero_responsable_poseedor}</h4>
                                    </div>
                                </div>

                                <div className="widget-content">
                                    <div className="widget-body">
                                        {
                                            isNew ?
                                                <FormVehiculoServicioPublico fetch={fetch} placa={placa} />
                                                : <FormVehiculoServicioPublico fetch={fetch} placa={placa}
                                                    servicioPublicoDb={servicioPublicoDb} vehiculoRuatDb={vehiculoRuatDb} />
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row text-end">
                            <div className="col-12 search-btn button ">
                                <input className="btn btn-full-screen" name="submit" type="submit" value={'Generar Solicitud CTSP'}  />
                            </div>
                        </div>
                    </form>
                    :
                    <ModalPlaca toast={toast} typeFur="CERTIFICACION_VEHICULO" closeModal={closeModal} />
            }

            <ToastContainer enableMultiContainer containerId={'Z'}
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
            <ToastContainer />
        </div>
    )
}

export default CreateCertification