import React, { useState, useEffect } from "react";
import Config from "../../data/config";

const FormModalPreLiquidacion = (props) => {
  const { auth, toast, data, fetch, title, eventOpenCloseModal } = props;

  const idModal = "modalPreLiquidacion";
  const [optionsCuentasvisacion, setOptionsCuentasvisacion] = useState([]);
  const [cuentasvisacionMinutas, setCuentasvisacionMinutas] = useState([]);
  const [cuentaVisacionSelected, setCuentaVisacionSelected] = useState({});
  const [furPreLiquidacion, setFurPreLiquidacion] = useState("");
  const vendedores = JSON.parse(data.vendedores);

  useEffect(() => {
    //aqui verificar auth
    openModal();
    return () => {};
  }, []);

  const openModal = async () => {
    const modal = new window.bootstrap.Modal(document.getElementById(idModal), {
      keyboard: true,
      backdrop: "static",
      focus: true,
    });
    modal.show();
    getCuentasTasasVisacionesMinutas();
  };

  const closeModal = (event) => {
    eventOpenCloseModal(undefined);
  };

  const getCuentasTasasVisacionesMinutas = async () => {
    const cuentasVisacioM = await fetch.fetchGet(
      `api/visacion-minutas/tasas-visacion-minutas`
    );
    if (cuentasVisacioM && cuentasVisacioM.status) {
      const listItems = cuentasVisacioM.TasasVisacionMinutas.map(
        (item, index) => {
          return (
            <option
              key={item.id}
              cuenta={item.cuenta}
              id={item.id}
              tasa={item.tasa}
            >
              {item.descripcion}
            </option>
          );
        }
      );
      setCuentasvisacionMinutas(cuentasVisacioM.TasasVisacionMinutas);
      setOptionsCuentasvisacion(listItems);
    }
  };

  const changeEventSelected = (event) => {
    event.preventDefault();
    debugger;
    const optionSelected = event.target;
    const cuenta =
      optionSelected.options[optionSelected.selectedIndex].getAttribute(
        "cuenta"
      );

    const cuentaSelected = cuentasvisacionMinutas.filter(
      (cuentaVm) => cuentaVm.cuenta === cuenta
    );
    if (cuentaSelected && cuentaSelected.length > 0) {
      setCuentaVisacionSelected(cuentaSelected[0]);
      document.getElementById("dataCuentaVisacionMinuta").innerHTML =
        cuentaSelected[0].cuenta;
      const totalTasa =  Number.parseInt( Number.parseInt(cuentaSelected[0].tasa, 10)  + Number.parseInt(cuentaSelected[0].reposicion, 10), 10)
      document.getElementById("dataTasaVisacionMinuta").innerHTML = totalTasa;
    } else {
      setCuentaVisacionSelected({});
    }
  };

  const handleSubmitForm = async (event) => {
    event.preventDefault();
    const buttonGenerate = document.getElementById("btnGeneratePreLiquidacion");
    const buttonCancel = document.getElementById(
      "btnGeneratePreLiquidacionCancel"
    );
    if (cuentaVisacionSelected) {
      buttonGenerate.classList.add("btn-disabled");
      buttonCancel.classList.add("btn-disabled");
      buttonGenerate.setAttribute("disabled", "true");
      buttonCancel.setAttribute("disabled", "true");

      const form = new FormData();
      form.append("vm_visacion_minutas[token]", data.token);
      form.append("vm_visacion_minutas[numero]", data.numero);
      form.append(
        "vm_pre_liquidacion[id_tasas_visacion_minutas]",
        cuentaVisacionSelected.id
      );

      const response = await fetch.fetchPost(
        form,
        "api/cobros/preliquidacion/generate",
        undefined
      );
      if (response && response.status) {
        buttonGenerate.classList.remove("btn-disabled");
        buttonCancel.classList.remove("btn-disabled");

        buttonGenerate.removeAttribute("disabled");
        buttonCancel.removeAttribute("disabled");
        setFurPreLiquidacion(response.data.preLiquidacion.fur);

        window.location.href =
          Config[0].url +
          `report/visacion-minutas/pre-liquidacion/${data.numero_ruat}/${data.propiedad_codigo}/${data.token}/download/?auth=` +
          auth.getToken();

        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else {
      console.log("no se ha seleccionado ningun elemento");
      buttonGenerate.removeAttribute("disabled");
      buttonCancel.removeAttribute("disabled");
    }
  };

  return (
    <div
      className="modal fade"
      id={idModal}
      tabIndex="-1"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="btn-close h6"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row mt-3">
              <div className="col-12 mb-3">
                <label
                  htmlFor={"cuentas-visacion-minutas"}
                  className="form-label"
                >
                  Cuentas Visación Minutas *
                </label>
                <select
                  className="form-select"
                  name={"cuentas-visacion-minutas"}
                  data-parsley-required="true"
                  required
                  onChange={changeEventSelected}
                >
                  <option value="">Seleccione Cuenta</option>
                  {optionsCuentasvisacion}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <strong>{vendedores.numero_documento}</strong>
                <br />
                <label className="form-label">Número de Documento</label>
              </div>
              <div className="col-12 col-md-8 col-lg-8 mb-3">
                <strong>{vendedores.nombre}</strong>
                <br />
                <label className="form-label">Vendedor</label>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <strong id="dataTasaVisacionMinuta">{"--"}</strong>
                <br />
                <label className="form-label">Monto (Bs)</label>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <strong>{data.numero}</strong>
                <br />
                <label className="form-label">Nro. Tramite</label>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <strong id="dataCuentaVisacionMinuta">{"--"}</strong>
                <br />
                <label className="form-label">Cuenta</label>
              </div>
            </div>
          </div>

          {furPreLiquidacion ? (
            <div className="row">
              <div className="col-12  mb-3 text-center">
                <h2>
                  <strong>{furPreLiquidacion}</strong>
                </h2>
                <label className="form-label">Fur Pre-Liquidación</label>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="modal-footer">
            <div className="row">
              
              <div className="col-12 col-md-6 col-lg-6 mb-3 text-end">
                <div className="search-btn button ">
                  <button
                    type="button"
                    className="btn bg-maroon-flush"
                    data-bs-dismiss="modal"
                    id="btnGeneratePreLiquidacionCancel"
                    onClick={closeModal}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
              {furPreLiquidacion === "" ? (
                <div className="col-12 col-md-6 col-lg-6 mb-3 text-start">
                  <div className="search-btn button ">
                    <input
                      className="btn btn-full-screen"
                      id="btnGeneratePreLiquidacion"
                      type="button"
                      value="Generar"
                      onClick={handleSubmitForm}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormModalPreLiquidacion;
