import React, { Component } from "react";

import Links from "../../../data/link";
import Fetch from "../../utils/Fetch";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ValidateFur from "./ValidateFur";
import ValidateTypeCertificacion from "./ValidateTypeCertificacion";
import ValidateRadicatoriaPagos from "./ValidateRadicatoriaPagos";
import ValidateTerminosCondiciones from "./ValidateTerminosCondiciones";

var _fetch = null;
class ModalFur extends Component {
  constructor(props, context) {
    super(props, context);
    this.idModalPlaca = "modalPlacaFull";
    this.formModalPlaca = "formModalPlaca";

    this.handleVoidEnter = this.handleVoidEnter.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);

    this.state = {
      dataVehiculo: undefined,
      dataRadicatoriaSelected: undefined,
      dataDerechoAdmision: undefined,
      dataPagosRadicatoria: undefined,
      currentPage: 0,
      csrfkey: undefined,
    };

    this.typeFur = this.props.typeFur;
    this.textTypeDerechoAdmisión = Links[1].title;
    this.rubro = this.props.rubro;

    if (this.typeFur === "CERTIFICACION")
      this.textTypeDerechoAdmisión = Links[49].title;

    this.token = window.getParameterByName("csrf-token");
    this.local = window.getParameterByName("lo");
    this.expiraEn = window.getParameterByName("expiraEn");
    this.preview = window.getParameterByName("preview");
    this.adm = window.getParameterByName("adm");

    _fetch = new Fetch();
    _fetch.setToast(toast);
  }

  handleCancelClick(event) {
    event.preventDefault();
    switch (this.typeFur) {
      case "CERTIFICACION":
        if (
          this.token &&
          this.local &&
          this.expiraEn &&
          this.preview &&
          this.adm
        )
          window.location.href = Links[100].url + "?cert=detallado";
        else if (this.token && this.local && this.expiraEn) {
          if (this.local === "0")
            window.location.href =
              Links[49].url +
              "?csrf-token=" +
              this.token +
              "&lo=" +
              this.local +
              "&expiraEn=" +
              this.expiraEn;
          else window.location.href = Links[112].url;
        } else window.location.href = Links[49].url;
        break;
      default:
        window.location.href = Links[1].url;
    }
  }

  handleVoidEnter(e) {
    e.preventDefault();
    this.validateForm();
  }

  validateForm = () => {
    window
      .jQuery("#" + this.formModalPlaca)
      .parsley()
      .validate();
    var li_error = window.jQuery("#inputModalFur").siblings("ul");
    window.jQuery("#inputModalFur").siblings("ul").remove();
    window
      .jQuery("#inputModalFur")
      .parent("div")
      .parent("div")
      .append(li_error);
  };

  nextPageCurrent = (currentPage, data = undefined) => {
    debugger;
    if (!this.state.csrfkey)
      this.setState((state) => ({
        csrfkey: {
          csrfToken: this.token,
          lo: this.local,
          expiraEn: this.expiraEn,
          preview: this.preview,
          adm: this.adm,
        },
      }));

    if (currentPage === 2 && data.hasOwnProperty("vehiculo")) {
      let dataVehiculo = {
        dataVehiculo: data.vehiculo,
        dataTecnico: data.datosTecnicos,
        dataGestionDeuda: data.gestionDeuda,
      };

      const vehiculoSelected = {
        numeroPta: data.vehiculo.numeroPta,
        numeroPoliza: data.vehiculo.numeroPoliza,
        claseVehiculo: data.vehiculo.claseVehiculo,
        radicatoriaVehiculo: data.vehiculo.radicatoriaVehiculo,
        servicioVehiculo: data.vehiculo.servicioVehiculo,
        codigoVehiculo: data.vehiculo.codigoVehiculo,
        propietario: data.vehiculo.radicatorias[0].propietario,
        estadoRadicatoria: data.vehiculo.radicatorias[0].estadoRadicatoria,
        fechaInicioRadicatoria:
          data.vehiculo.radicatorias[0].fechaInicioRadicatoria,
        fechaFinRadicatoria: data.vehiculo.radicatorias[0].fechaFinRadicatoria,
        idObjetoTributario: data.idObjetoTributario,
        selected: true,
        typeSearch: data.typeSearch,
      };

      this.setState((state) => ({
        currentPage: ++currentPage,
        dataVehiculo: dataVehiculo,
        objetoTributario: data.objetoTributario,
        dataRadicatoriaSelected: vehiculoSelected,
      }));
    }

    if (currentPage === 3 && data.hasOwnProperty("selected"))
      this.setState((state) => ({
        currentPage,
        dataRadicatoriaSelected: data,
      }));

    if (currentPage === 4) {
      this.setState((state) => ({
        currentPage,
        dataPagosRadicatoria: data,
      }));
    }

    if (currentPage !== 2) {
      if (data && data.hasOwnProperty("fur"))
        this.setState((state) => ({
          currentPage: currentPage,
          dataDerechoAdmision: data,
        }));
      else if (data && data.hasOwnProperty("objetoTributario")) {
        this.setState((state) => ({
          currentPage: currentPage,
          objetoTributario: data.objetoTributario,
        }));
      } else this.setState((state) => ({ currentPage: currentPage }));
    }
  };

  render() {
    return (
      <div
        className="modal fade modal-custom-atm"
        id={this.idModalPlaca}
        tabIndex="-1"
        aria-labelledby="modalPlacaFullTitle"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
        style={{ overflowY: "auto" }}
      >
        <div
          className="modal-dialog modal-md"
          style={{ boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.75)" }}
        >
          <div className="modal-content ">
            <div className="modal-header text-butterfly-bush justify-content-center">
              {this.state.currentPage === 0 ? (
                <h4
                  className="modal-title "
                  id="exampleModalLongTitle"
                  style={{ fontWeight: 600 }}
                >
                  VALIDACIÓN
                </h4>
              ) : (
                <h4
                  className="modal-title "
                  id="exampleModalLongTitle"
                  style={{ fontWeight: 600 }}
                >
                  {this.textTypeDerechoAdmisión.toUpperCase()}
                </h4>
              )}
            </div>
            <div className="modal-body">
              <form
                className="contact__form"
                style={{ margin: "0.5rem" }}
                onSubmit={this.handleVoidEnter}
                id={this.formModalPlaca}
              >
                {this.state.currentPage === 0 ? (
                  <ValidateFur
                    rubro={this.rubro}
                    handleCancelClick={this.handleCancelClick}
                    fetch={_fetch}
                    validateForm={this.validateForm}
                    typeFur={this.typeFur}
                    nextPageCurrent={this.nextPageCurrent}
                    csrfkey={this.state.csrfkey}
                  />
                ) : (
                  ""
                )}

                {this.state.currentPage === 1 ? (
                  <ValidateTypeCertificacion
                    rubro={this.rubro}
                    handleCancelClick={this.handleCancelClick}
                    fetch={_fetch}
                    validateForm={this.validateForm}
                    typeFur={this.typeFur}
                    nextPageCurrent={this.nextPageCurrent}
                    csrfkey={this.state.csrfkey}
                    dataDerechoAdmision={this.state.dataDerechoAdmision}
                    idForm={this.formModalPlaca}
                  />
                ) : (
                  ""
                )}

                {this.state.currentPage === 3 ? (
                  <ValidateRadicatoriaPagos
                    handleCancelClick={this.handleCancelClick}
                    fetch={_fetch}
                    nextPageCurrent={this.nextPageCurrent}
                    dataVehiculo={this.state.dataVehiculo}
                    dataDerechoAdmision={this.state.dataDerechoAdmision}
                    objetoTributario={this.state.objetoTributario}
                    dataRadicatoriaSelected={this.state.dataRadicatoriaSelected}
                    idModal={this.idModalPlaca}
                    csrfkey={this.state.csrfkey}
                  />
                ) : (
                  ""
                )}

                {this.state.currentPage === 4 ? (
                  <ValidateTerminosCondiciones
                    handleCancelClick={this.handleCancelClick}
                    fetch={_fetch}
                    nextPageCurrent={this.nextPageCurrent}
                    dataVehiculo={this.state.dataVehiculo}
                    dataDerechoAdmision={this.state.dataDerechoAdmision}
                    dataRadicatoriaSelected={this.state.dataRadicatoriaSelected}
                    objetoTributario={this.state.objetoTributario}
                    dataPagosRadicatoria={this.state.dataPagosRadicatoria}
                    closeModal={this.props.closeModal}
                    csrfkey={this.state.csrfkey}
                  />
                ) : (
                  ""
                )}
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalFur;
