import React, { useState } from "react";
import Config from "../../data/config";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import Languaje from "../../data/es";

const PerfilForm = (props) => {

  const [startDate, setStartDate] = useState(false);
  const [optionsSelect, setOptionsSelect] = useState([]);

  const handlePerfilInformatcionClick = (event) => {
    window.jQuery(".link-style").removeClass("active");
    event.target.classList.add("active");
    window.jQuery("#multiCollapsePerfilInformation").collapse("show");
    window.jQuery("#multiCollapsePerfilEdit").collapse("hide");
    window.jQuery("#multiCollapsePerfilPicture").collapse("hide");
    window.jQuery("#multiCollapseChangePassword").collapse("hide");
  };

  const handlePerfilEditClick = async (event) => {
    window.jQuery(".link-style").removeClass("active");
    event.target.classList.add("active");
    window.jQuery("#multiCollapsePerfilInformation").collapse("hide");
    window.jQuery("#multiCollapsePerfilEdit").collapse("show");
    window.jQuery("#multiCollapsePerfilPicture").collapse("hide");
    window.jQuery("#multiCollapseChangePassword").collapse("hide");
    props.fetch.fetchGet("api/datos-usuario/get-by-user").then((dataJson) => {
      if (dataJson && dataJson.status) {
        if (Boolean(dataJson.DatosUsuario)) {
          var datos = dataJson.DatosUsuario;
          document.getElementsByName("datos[name]")[0].value = datos.name;
          document.getElementsByName("datos[apellido_paterno]")[0].value =
            datos.apellido_paterno;
          document.getElementsByName("datos[apellido_materno]")[0].value =
            datos.apellido_materno;
          document.getElementsByName("datos[ci]")[0].value = datos.ci;
          document.getElementsByName("datos[company]")[0].value = datos.company;
          document.getElementsByName("datos[address]")[0].value = datos.address;
          document.getElementsByName("datos[phone]")[0].value = datos.phone;
          if (Boolean(dataJson.Pais)) {
            var pais = dataJson.Pais;
            document.getElementById("basic-addon-phone-code").innerHTML =
              pais.code_phone;
            setOptionsSelect([{ value: pais.id, label: pais.name }]);
          }

          setStartDate(new Date(datos.fecha_nacimiento));
          props.toast.success(dataJson.message, "RIGHT");
        } else props.toast.warning(dataJson.message, "RIGHT");
      }
    });
  };

  const handlePerfilPictureClick = (event) => {
    event.preventDefault();
    window.jQuery(".link-style").removeClass("active");
    event.target.classList.add("active");
    window.jQuery("#multiCollapsePerfilInformation").collapse("hide");
    window.jQuery("#multiCollapsePerfilEdit").collapse("hide");
    window.jQuery("#multiCollapsePerfilPicture").collapse("show");
    window.jQuery("#multiCollapseChangePassword").collapse("hide");
  };

  const handleChangePasswordClick = (event) => {
    window.jQuery(".link-style").removeClass("active");
    event.target.classList.add("active");
    window.jQuery("#multiCollapsePerfilInformation").collapse("hide");
    window.jQuery("#multiCollapsePerfilEdit").collapse("hide");
    window.jQuery("#multiCollapsePerfilPicture").collapse("hide");
    window.jQuery("#multiCollapseChangePassword").collapse("show");
  };

  const handleDatePickerChange = (date) => {
    setStartDate(date);
  };

  const handleInputChange = async (selectedOption) => {
    if (selectedOption) {
      if (selectedOption.code_phone) {
        document.getElementById("basic-addon-phone-code").innerHTML =
          selectedOption.code_phone;
      } else {
        const response = await props.fetch.fetchGet(
          `api/country/search-by-name/${selectedOption.label}`
        );
        if (response && response.status)
          document.getElementById("basic-addon-phone-code").innerHTML =
            response.data[0].code_phone;
      }
    }
  };

  const loadOption = async (inputValue) => {
    if (inputValue.length > 2) {
      const response = await props.fetch.fetchGet(
        `api/country/search-by-name/${inputValue}`
      );
      if (response && response.status) return response.data;
      else return [];
    } else return optionsSelect;
  };

  return (
    <div className="row">
      <div className="col-12 col-md-3 col-lg-3">
        <ul
          className="ver-inline-menu tabbable margin-bottom-10"
          id="profile-menu-tabs"
        >
          <li style={{ textAlign: "center" }}>
            <img
              alt="gravatar"
              title="avatar"
              id="avatarPerfilUser"
              width="208"
              height="208"
              className="img-fluid"
              src={Config[2].url + "/static/img/avatar_default.avif"}
            />
          </li>
          <li className="active">
            <Link
              to={"#"}
              className={`link-style active`}
              onClick={handlePerfilInformatcionClick}
            >
              {Languaje.perfil_information}
            </Link>
          </li>

          <li className="active">
            <Link
              to={"#"}
              className={`link-style`}
              onClick={handlePerfilEditClick}
            >
              {Languaje.perfil_edit}
            </Link>
          </li>

          <li className="active">
            <Link
              to={"#"}
              className={`link-style`}
              onClick={handlePerfilPictureClick}
            >
              {Languaje.perfil_picture}
            </Link>
          </li>
          <li className="edit-profile">
            <Link
              to={"#"}
              className={`link-style`}
              onClick={handleChangePasswordClick}
            >
              {Languaje.change_password}
            </Link>
          </li>
        </ul>
      </div>

      <div className="col-12 col-md-9 col-lg-9">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="collapse show" id="multiCollapsePerfilInformation">
              <div className="card card-body">
                <ul className="unstyled ">{props.usuario}</ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-12">
            <div className="collapse " id="multiCollapsePerfilEdit">
              <div className="card card-body">
                <div className="row">
                  <div className="col-12 ">
                    <div className="alert alert-info" role="alert">
                      Esto cambiará los datos de su cuenta, esta sección es solo
                      para miembros autentificados.
                    </div>
                  </div>
                </div>

                <form
                  action=""
                  className="contact__form needs-validation"
                  name="formPerfil"
                  id="formPerfil"
                  method="post"
                  noValidate
                  onSubmit={props.onSubmitFormDatos}
                  style={{ width: "100%" }}
                >
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 mb-3">
                      <label htmlFor="datos[name]" className="form-label">
                        Nombre *
                      </label>
                      <input
                        name="datos[name]"
                        id="datos[name]"
                        type="text"
                        className="form-control"
                        placeholder="Nombre"
                        data-parsley-minlength="3"
                        minLength="3"
                        required
                        data-parsley-required="true"
                        pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
                        data-parsley-pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
                      />
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 mb-3">
                      <label
                        htmlFor="datos[apellido_paterno]"
                        className="form-label"
                      >
                        Apellido Paterno *
                      </label>
                      <input
                        name="datos[apellido_paterno]"
                        id="datos[apellido_paterno]"
                        type="text"
                        className="form-control"
                        placeholder="Apellido Paterno"
                        data-parsley-minlength="3"
                        minLength="3"
                        required
                        data-parsley-required="true"
                        pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
                        data-parsley-pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 mb-3">
                      <label
                        htmlFor="datos[apellido_materno]"
                        className="form-label"
                      >
                        Apellido Materno
                      </label>
                      <input
                        name="datos[apellido_materno]"
                        id="datos[apellido_materno]"
                        type="text"
                        className="form-control"
                        placeholder="Apellido Materno"
                        pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
                        data-parsley-pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
                      />
                    </div>

                    <div className="col-12 col-md-6 col-lg-6 mb-3">
                      <label htmlFor="datos[ci]" className="form-label">
                        Documento de Identificación *
                      </label>
                      <input
                        name="datos[ci]"
                        id="datos[ci]"
                        type="text"
                        className="form-control"
                        placeholder="CI"
                        data-parsley-minlength="5"
                        minLength="5"
                        required
                        data-parsley-required="true"
                        pattern="[a-zA-Z0-9-]+"
                        data-parsley-pattern="[a-zA-Z0-9-]+"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 mb-3">
                      <label htmlFor="datos[company]" className="form-label">
                        Institución
                      </label>
                      <input
                        name="datos[company]"
                        id="datos[company]"
                        type="text"
                        className="form-control"
                        placeholder="Institución"
                        pattern="[a-zA-Z0-9 À-ÿ\u00f1\u00d1]+"
                        data-parsley-pattern="[a-zA-Z0-9 À-ÿ\u00f1\u00d1]+"
                      />
                    </div>

                    <div className="col-12 col-md-6 col-lg-6 mb-3">
                      <label
                        htmlFor="datos[fecha_nacimiento]"
                        className="form-label"
                      >
                        Fecha de Nacimiento (DD/MM/YYYY)
                      </label>
                      <DatePicker
                        locale="es"
                        dateFormat={Config[4].format}
                        selected={startDate}
                        onChange={handleDatePickerChange}
                        maxDate={Config[1].anio}
                        className="form-control data-toggle"
                        name="datos[fecha_nacimiento]"
                        id="datos[fecha_nacimiento]"
                        data-toggle="tooltip"
                        data-placement="left"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 mb-3">
                      <label htmlFor="datos[address]" className="form-label">
                        Dirección *
                      </label>
                      <input
                        name="datos[address]"
                        id="datos[address]"
                        type="text"
                        className="form-control"
                        placeholder="Dirección"
                        required
                        data-parsley-required="true"
                        pattern="[.a-zA-Z0-9 À-ÿ\u00f1\u00d1]+"
                        data-parsley-pattern="[.a-zA-Z0-9 À-ÿ\u00f1\u00d1]+"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 mb-3">
                      <label htmlFor="datos[id_country]" className="form-label">
                        Pais *
                      </label>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadOption}
                        defaultOptions
                        onChange={handleInputChange}
                        isClearable
                        isSearchable
                        placeholder="Buscar Pais"
                        data-parsley-required="true"
                        data-parsley-minlength="4"
                        minLength="4"
                        name="datos[id_country]"
                        id="datos[id_country]"
                        required
                        defaultValue={[optionsSelect[0]]}
                      />
                    </div>

                    <div className="col-12 col-md-6 col-lg-6 mb-3">
                      <label htmlFor="inputDatosPhone" className="form-label">
                        Teléfono *
                      </label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="basic-addon-phone-code"
                          >
                            -
                          </span>
                        </div>
                        <input
                          type="text"
                          name="datos[phone]"
                          id="inputDatosPhone"
                          className="form-control"
                          placeholder="Teléfono"
                          aria-label="Teléfono"
                          aria-describedby="basic-addon-phone-code"
                          data-parsley-required="true"
                          data-parsley-minlength="6"
                          minLength="6"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-12 text-end">
                      <div className="search-btn button ">
                        <input
                          name="save"
                          type="submit"
                          className="btn"
                          value="Guardar Cambios"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-12">
            <div className="collapse " id="multiCollapsePerfilPicture">
              <div className="card card-body">
                <form
                  action=""
                  className="contact__form needs-validation"
                  name="formPerfilImage"
                  id="formPerfilImage"
                  method="post"
                  noValidate
                  onSubmit={props.onSubmitFormImage}
                  style={{ width: "100%" }}
                  encType="multipart/form-data"
                >
                  <div className="row">
                    <div className="col-12 files color mb-3">
                      <label htmlFor="usuariofile">Sube tu archivo *</label>
                      <input
                        type="file"
                        name="usuariofile"
                        id="usuariofile"
                        className="form-control"
                        multiple=""
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 text-end">
                      <div className="search-btn button ">
                        <input
                          name="save"
                          type="submit"
                          className="btn"
                          value="Guardar Cambios"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="collapse" id="multiCollapseChangePassword">
              <div className="card card-body">
                <div className="row">
                  <div className="col-12 ">
                    <div className="alert alert-info" role="alert">
                      Esto cambiará la contraseña de acceso a su cuenta, esta
                      sección es solo para miembros autentificados.
                    </div>
                  </div>
                </div>

                <form
                  action=""
                  className="contact__form needs-validation"
                  name="formChangePassword"
                  id="formChangePassword"
                  method="post"
                  noValidate
                  onSubmit={props.onSubmitFormChangePassword}
                  style={{ width: "100%" }}
                >
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 mb-3">
                      <label
                        htmlFor="usuario[password_old]"
                        className="form-label"
                      >
                        Contraseña Actual *
                      </label>
                      <input
                        name="usuario[password_old]"
                        id="usuario[password_old]"
                        type="password"
                        className="form-control"
                        placeholder="Contraseña Actual"
                        data-parsley-required="true"
                        data-parsley-minlength="6"
                        minLength="6"
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 mb-3">
                      <label htmlFor="usuario_password" className="form-label">
                        Nueva Contraseña *
                      </label>
                      <input
                        id="usuario_password"
                        name="usuario[password]"
                        type="password"
                        className="form-control"
                        placeholder="Contraseña"
                        data-parsley-required="true"
                        data-parsley-minlength="6"
                        minLength="6"
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 mb-3">
                      <label
                        htmlFor="usuario[password_repeat]"
                        className="form-label"
                      >
                        Repita la Nueva Contraseña *
                      </label>
                      <input
                        name="usuario[password_repeat]"
                        id="usuario[password_repeat]"
                        type="password"
                        className="form-control"
                        placeholder="Repita la Contraseña"
                        data-parsley-required="true"
                        data-parsley-minlength="6"
                        minLength="6"
                        data-parsley-equalto="#usuario_password"
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-12 ">
                      <div className="search-btn button ">
                        <input
                          name="save"
                          type="submit"
                          className="btn"
                          value="Cambiar Contraseña"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerfilForm;
