import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fetch from "../../../components/utils/Fetch";

const ValidateTerminosCondiciones = (props) => {
  const fetch = new Fetch();
  fetch.setToast(toast);
  const { dataVehiculo, objetoTributario, dataRadicatoriaSelected } = props;
  const numeroPta = dataVehiculo.dataVehiculo.numeroPta;
  const numeroPoliza = dataVehiculo.dataVehiculo.numeroPoliza;
  const datosTecnicos = dataVehiculo.dataTecnico.datosTecnicos;

  //let propietario = dataRadicatoriaSelected.propietario

  const handleAceptoTerminosCondicionesClick = (event) => {
    event.preventDefault();
    if (window.jQuery("#formModalPlaca").parsley().isValid()) {
      fetch
        .fetchGet(
          "api/temporal-contribuyentes/by-csrkey/" + props.csrfkey.csrfToken
        )
        .then((dataJson) => {
          if (dataJson && dataJson.status === true) {
            const objetoOfAction = {
              dataRadicatoriaSelected: dataRadicatoriaSelected,
              vehiculo: props.dataVehiculo,
              derechoAdmision: props.dataDerechoAdmision,
              objetoTributario: objetoTributario,
              dataPagosRadicatoria: props.dataPagosRadicatoria,
              csrfkey: props.csrfkey,
            };

            props.closeModal(objetoOfAction);
          }
        });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 padding-left-0 text-uppercase">
          <strong>
            <p style={{ fontSize: "1em" }}>{objetoTributario.name}</p>
          </strong>
        </div>
      </div>

      <div className="row">
        <div className="col-12 padding-left-0 text-uppercase mb-3">
          <strong style={{ fontSize: ".9em" }}>Nro. Placa: </strong>
          <span>{numeroPta}</span>
          <br />
          <strong style={{ fontSize: ".9em" }}>Nro. Póliza.: </strong>
          <span>{numeroPoliza}</span>
          <br />
          <strong style={{ fontSize: ".9em" }}>Estado: </strong>
          <span>
            {dataRadicatoriaSelected
              ? dataRadicatoriaSelected.estadoRadicatoria
              : ""}
          </span>
          <br />
          <strong style={{ fontSize: ".9em" }}>Clase: </strong>
          <span>{datosTecnicos ? datosTecnicos.clase : ""}</span> <br />
        </div>
      </div>

      <div className="row">
        <div className="col-12 padding-right-0">
          <h4 className="text-center mt-3 mb-3 text-butterfly-bush">
            Términos y Condiciones de Uso
          </h4>
        </div>
      </div>

      <div className="row pt-3 mb-3">
        <div className="col-12 block-terminos-condiciones-modal">
          <p className="text-justify">
            El titular de la póliza número {numeroPoliza}, con número Placa RUAT{" "}
            {numeroPta}, manifiesto haber tomado conocimiento y acepto, todas y
            cada una de las siguientes condiciones de uso y acceso para la
            Solicitud de Tramites en Línea, en la Plataforma Virtual de la
            Administración Tributaria Municipal (ATM), a través del cual
            procederé a la solicitud y seguimiento de trámites administrativos,
            así como la notificación mediante medios electrónicos, y sea en
            conformidad a lo establecido en la normativa legal, descrita:
          </p>

          <p className="text-justify">
            Que, el Art. 78 de la Ley Nº 164 Ley General de Telecomunicaciones,
            Tecnología de Información y Comunicación, establece que: "Tiene
            Validez y probatoria: 1. El acto o negocio jurídico realizado por
            persona natural o jurídica en documento digital y aprobado por las
            partes a través de firma digital, celebrado por medio electrónico u
            otro de mayor avance tecnológico. 2. El mensaje electrónico de
            datos. 3. La firma digital."
          </p>

          <p className="text-justify">
            Que, el Parágrafo III, del Art. 2 y el Art. 3 de la Ley Nº 812 de
            fecha 1 de julio de 2016, de Modificación a la Ley Nº 2492 de 2 de
            agosto de 2003, “Código Tributario Boliviano”, los actos y
            actuaciones de la Administración Tributaria Municipal del Gobierno
            Autónomo Municipal de Cochabamba se notificarán por medios
            electrónicos declarados y proporcionados por el sujeto pasivo dentro
            los Términos y Condiciones de la presente.
          </p>

          <p className="text-justify">
            Que, el Art. 7 del Decreto Supremo Nº 27310 de fecha 9 de Enero de
            2004, Reglamento a la Ley Nº 2492 Código Tributario Boliviano,
            señala “Las operaciones electrónicas realizadas y registradas en el
            sistema informático de la Administración Tributaria por un usuario
            autorizado surten efectos jurídicos. La información generada,
            enviada, recibida, almacenada o comunicada a través de los sistemas
            informáticos o medios electrónicos, por cualquier usuario autorizado
            que de cómo resultado un registro electrónico, tiene validez
            probatoria.”
          </p>

          <p className="text-justify">
            Que, los datos consignados en la Plataforma Virtual de la
            Administración Tributaria Municipal (https://simat.cochabamba.bo),
            condicen la verdad material de los datos personales y los objetos
            tributarios asociados a mi persona, en caso de no corresponder ME
            COMPROMETO a proceder a la actualización de los mismos en los
            registros autorizados para tal efecto.
          </p>

          <p className="text-justify">
            En relación al servicio de carácter administrativo habilitado en la
            plataforma de la Administración Tributaria Municipal (ATM), la
            información y/o documentación adicional será requerida por la ATM en
            apego absoluto a la verdad, dado que estos formularios estarán
            consignados como declaraciones juradas, en virtud de lo dispuesto en
            normativa vigente, adquiriendo plena validez legal.
          </p>

          <p className="text-justify">
            Asimismo, el espacio virtual que se me asigne dentro de la
            plataforma Virtual de la Administración Tributaria Municipal
            (https://simat.cochabamba.bo), se constituirá en el medio oficial,
            mediante el cual, la Administración Tributaria Municipal del GAMC
            podrá notificarme sobre actos y actuaciones administrativas
            referidas a trámites administrativos, en mérito a lo cual asumo
            entera responsabilidad y me comprometo a realizar el seguimiento
            continuo y permanente a cualquier alerta o notificación que pudiera
            recibir por este medio.
          </p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12 col-md-6 col-lg-6 mb-3 text-start">
          <div className="col-12 search-btn button ">
            <input
              className="btn bg-maroon-flush btn-full-screen"
              type="submit"
              value="No Acepto"
              onClick={props.handleCancelClick}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-6 mb-3 text-end">
          <div className="col-12 search-btn button ">
            <input
              className="btn btn-full-screen"
              type="submit"
              value="Acepto"
              onClick={handleAceptoTerminosCondicionesClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ValidateTerminosCondiciones;
