import React, { Component } from 'react';

import Fetch from '../utils/Fetch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { timingSafeEqual } from 'crypto';
import Config from '../../data/config';
import CharJs from  'chart.js'

var _fetch = undefined
var pieData = {}
var pieOptions = {}
var pieChartCanvas = undefined

var pieBrowserData = {}
var pieBrowserChartCanvas = undefined
class GraficaLineal extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            titulo_grafica: "",
            device: undefined,
            browser: undefined
        };

        _fetch = this.props.fetch;

        pieOptions = {
            legend: {
                display: false
            }
        }
    }

    async componentDidMount() {
        pieChartCanvas = window.jQuery('#pieDeviceChart').get(0).getContext('2d')
        pieBrowserChartCanvas = window.jQuery('#pieChart').get(0).getContext('2d')

        await this.paintDeviceChart()
        await this.paintBrowserChart()
    }

    async paintDeviceChart() {

        const response = await _fetch.axiosAsyncGet(`api/history-login/all-device`);

        let labels = []
        let colors = []
        let data = []
        
        if (response !== null && response.status === true && response.Device.length > 0) {

            for (let i = 0; i < response.Device.length; i++) {
                labels[i] = response.Device[i].device;

            }
            for (let i = 0; i < response.Device.length; i++) {
                data[i] = response.Device[i].total;
            }

            let listDevice = response.Device.map((item, index) => {
                let desconocido = false;
                if (item.device === 'ANDROID') {
                    desconocido = true
                    colors[index] = "#28a745"
                    return <li key={index} style={{fontSize: "10px" }}> <i className="fa fa-android text-success" ></i> {item.device}</li>
                }
                
                if (item.device === 'LINUX') {
                    desconocido = true
                    colors[index] = "#17a2b8"
                    return <li key={index} style={{fontSize: "10px" }}> <i className="fa fa-linux text-info"></i> {item.device}</li>
                }

                if (item.device === 'MACINTOSH') {
                    desconocido = true
                    colors[index] = "#212529"
                    return <li key={index} style={{fontSize: "10px" }}><i className="fa fa-chrome text-danger"></i> {item.device}</li>
                }

                if (item.device === 'WINDOWS') {
                    desconocido = true
                    colors[index] = "#007bff"
                    return <li key={index} style={{fontSize: "10px" }} ><i className="fa fa-windows text-primary"></i> {item.device}</li>
                }

                if (item.device === 'IOS') {
                    desconocido = true
                    colors[index] = "#343a40"
                    return <li key={index} style={{fontSize: "10px" }}><i className="fa fa-apple text-dark"></i> {item.device}</li>
                }

                if (item.device === 'WINDOWS PHONE') {
                    desconocido = true
                    colors[index] = "#007bff"
                    return <li key={index} style={{fontSize: "10px" }} ><i className="fa fa-windows text-primary"></i> {item.device}</li>
                }

                if (item.device === 'OTHER') {  //otro
                    desconocido = true
                    colors[index] = "#dc3545"
                    return <li key={index} style={{fontSize: "10px" }}><i className="fa fa-question-circle-o text-danger"></i> {item.device}</li>
                }

                if(!desconocido){
                    colors[index] = "#6c757d"
                    return <li key={index} style={{fontSize: "10px" }}><i className="fa fa-question text-muted"></i> {item.device}</li>
                }
                   
            });
            this.setState({ device: listDevice })
        } else {

            labels = ['Windows', 'Linux', 'IOS', 'Other']
            colors = ['#007bff', '#6c757d', '#343a40', '#dc3545']
            data = [0, 0, 0, 0,]
            this.setState({ device: undefined })
        }

        pieData = {
            labels: labels,
            datasets: [
                {
                    data: data,
                    backgroundColor: colors,
                }
            ]
        }
        var pieChart = new CharJs.Chart(pieChartCanvas, {
            type: 'doughnut',
            data: pieData,
            options: pieOptions
        })
    }

    async paintBrowserChart() {
        const response = await _fetch.axiosAsyncGet(`api/history-login/all-browser`);

        let labels = []
        let colors = []
        let data = []
        
        if (response !== null && response.status === true && response.Browser.length > 0) {

            for (let i = 0; i < response.Browser.length; i++) {
                labels[i] = response.Browser[i].browser;

            }
            for (let i = 0; i < response.Browser.length; i++) {
                data[i] = response.Browser[i].total;
            }

            let listBrowser = response.Browser.map((item, index) => {
                
                if (item.browser === 'OPERA') {
                    colors[index] = "#007bff"
                    return <li key={index} style={{fontSize: "10px" }}> <i className="fa fa-opera text-primary"></i> {item.browser}</li>
                }

                if (item.browser === 'EDGE') {
                    colors[index] = "#212529"
                    return <li key={index} style={{fontSize: "10px" }}><i className="fa fa-internet-explorer text-success"></i> {item.browser}</li>
                }

                if (item.browser === 'FIREFOX') {
                    colors[index] = "#ffc107"
                    return <li key={index} style={{fontSize: "10px" }}><i className="fa fa-firefox text-warning"></i> {item.browser}</li>
                }

                if (item.browser === 'CHROME') {
                    colors[index] = "#dc3545"
                    return <li key={index} style={{fontSize: "10px" }}><i className="fa fa-chrome text-danger"></i> {item.browser}</li>
                }

                if (item.browser === 'SAFARI') {
                    colors[index] = "#17a2b8"
                    return <li key={index} style={{fontSize: "10px" }}><i className="fa fa-safari text-info"></i> {item.browser}</li>
                }

                if (item.browser === 'OTROS') {  //otro
                    colors[index] = "#6c757d"
                    return <li key={index} style={{fontSize: "10px" }}><i className="fa fa-question-circle-o text-secondary"></i> {item.browser}</li>
                }
                   
            });
            this.setState({ browser: listBrowser })

        }else{
            labels = ['Chrome', 'IE', 'FireFox', 'Safari']
            colors = ['#007bff', '#6c757d', '#343a40', '#dc3545']
            data = [0, 0, 0, 0,]

            this.setState({ device: undefined })
        }

        pieBrowserData = {
            labels: labels,
            datasets: [
                {
                    data: data,
                    backgroundColor: colors,
                }
            ]
        }
        var pieBrowserChart = new CharJs.Chart(pieBrowserChartCanvas, {
            type: 'doughnut',
            data: pieBrowserData,
            options: pieOptions
        })
    }

    render() {
        return (
            <div className="col-md-4">

                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Navegadores y Sistemas Operativos</h3>
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="chart-responsive">
                                    <canvas id="pieChart" height="150"></canvas>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <ul className="chart-legend clearfix">
                                    {this.state.browser}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer bg-white p-0">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="chart-responsive">
                                        <canvas id="pieDeviceChart" height="150"></canvas>
                                    </div>
                                </div>

                                <div className="col-md-4">

                                    {this.state.device !== undefined ?
                                        <ul className="chart-legend clearfix">
                                            {this.state.device}
                                        </ul>
                                        :
                                        <ul className="chart-legend clearfix">
                                            <li><i className="fa fa-windows text-danger"></i> Windows</li>
                                            <li><i className="fa fa-linux text-success"></i> Linux</li>
                                            <li><i className="fa fa-firefox text-warning"></i> Nexus</li>
                                            <li><i className="fa fa-safari text-info"></i> Other</li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GraficaLineal;