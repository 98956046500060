import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Config from "../../../data/config";
import SocialNetwork from './SocialNetworks';

const TitleHeaderPage = ({ titlePage, description, visible, step, totalEstep }) => {
	return (
		<div className="row mt-2 mb-2  pb-2 ">

			{(step && totalEstep && step > 0 && totalEstep > 0) && <div className="text-gris" style={{textAlign:"right"}}><span className="border p-2"> Paso <b>{step}</b> de <b>{totalEstep}</b></span></div>}

			<div className="mx-auto align-items-center">
				<Link to={{ pathname: "/" }} className="d-inline ">
					<img
						src={Config[2].url + "/static/img/logo_atm.png"}
						alt="logo"
						className="mx-auto d-block rounded-circle img-fluid"
						style={{ maxWidth: 70 }}
					/>
				</Link>
			</div>			

			<div className="text-center pt-2">				
				<h4 className="text-violeta font-bold">{titlePage}</h4>
			</div>
			

			{description && (
				<div className="bg-gray-5 text-justify col-lg-12 col-md-12 col-sm-12 border-radius-035 pl-3 pr-3  mt-2 pt-2 pb-2">
					{description}
				</div>)
				}

			<SocialNetwork visible={visible}></SocialNetwork>
		</div>
	);
};

TitleHeaderPage.prototype = {
	titlePage: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	visible: PropTypes.bool.isRequired,
};

export default TitleHeaderPage;
