import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Texto from "../../data/es";
import Constant from "../../data/constant";
import Links from "../../data/link";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import Config from "../../data/config";

const ImtoReport = (props) => {
  const {
    fetch,
    auth,
    userAuth,
    submitForm,
    inmueble,
    propiedad,
    visacionMinuta,
    toast,
  } = props;

  const [searchData, setSearchData] = useState(false);

  useEffect(() => {
    debugger;
    if (auth.loggedIn()) {
      if (
        userAuth.code === Constant[0].grupos.funcionario ||
        userAuth.code === Constant[0].grupos.administrador
      ) {
        if (visacionMinuta) {
          setSearchData(true);
          const url = `${
            Config[0].url
          }report/visacion-minutas/reporte-transferencias/${
            inmueble.numero_ruat
          }/${propiedad.codigo_propiedad}/${visacionMinuta.token}/${
            visacionMinuta.numero
          }/preview?auth=${auth.getToken()}`;

          setTimeout(() => {
            document.getElementById("iframeReportImtoPdf").src = url;
          }, 1000);
        }

        window.scrollTo(0, 0);
      } else window.location.href = Links[4].url;
    } else window.location.href = Links[4].url;
  }, []);

  const handleOnloadIFrame = () => {
    document.getElementById("iframeReportImtoPdf").style.height = "500px";
    setSearchData(false);
  };

  const hanldeDownloadPdf = (event) => {
    event.preventDefault();
    const url = `${
      Config[0].url
    }report/visacion-minutas/reporte-transferencias/${inmueble.numero_ruat}/${
      propiedad.codigo_propiedad
    }/${visacionMinuta.token}/${
      visacionMinuta.numero
    }/download?auth=${auth.getToken()}`;
    window.location.href = url;
    setTimeout(() => {
      setSearchData(false);
    }, 3000);
  };

  return (
    <>
      {searchData ? (
        <div className="row mt-3">
          <div className="col-12 ">
            <img
              className="rounded mx-auto d-block"
              alt="pulse-loading"
              src={"/static/img/pulse_200.gif"}
              width="70"
            />
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="row mt-3">
        <div className="col-12 col-md-12 col-lg-12 ">
          <Iframe
            src=""
            width={640}
            height={400}
            frameBorder="0"
            allow=""
            allowFullScreen 
            id="iframeReportImtoPdf"
            display="initial"
            onLoad={handleOnloadIFrame}
          ></Iframe>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-11 col-md-11 col-lg-11 paddingTop15">
          <p>
            En caso no se visualize el pdf, puedes
            <Link
              to="#"
              onClick={hanldeDownloadPdf}
              title="Descargar"
              style={{ paddingLeft: "5px" }}
            >
              hacer click aquí para descargar el archivo PDF.
            </Link>
          </p>
        </div>

        <div className="col-1 col-md-1 col-lg-1 paddingTop15 pull-right">
          <Link
            to="#"
            onClick={hanldeDownloadPdf}
            title="Descargar"
            style={{ fontSize: "2em", float: "right", marginTop: "-5px" }}
          >
            <i className="fa fa-print fa-icon-image" aria-hidden="true"></i>
          </Link>
        </div>
      </div>
    </>
  );
};

export default ImtoReport;
