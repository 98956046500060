import React, { Component } from 'react';
import Fetch from '../../components/utils/Fetch';
import { ToastContainer, toast } from 'react-toastify';
import Constant from '../../data/constant';
import { Link } from 'react-router-dom';
import Config from '../../data/config';
import AuthService from '../../components/Usuario/AuthService';

import '../style/map.css'
import '../style/ol.min.css'
import '../style/ol-ext.css'
//import '../style/icon-font-map.css';

var bounds = [802222.4138964778, 8075257.386522729];

var projection;
var container = undefined;
var content = undefined;
var closer = undefined;
var capa_predios = undefined
var vectorSource = undefined
var vectorLayer = undefined
var _map_catastro = undefined
class MapCatastro extends Component {

    constructor(props, context) {
        super(props, context);

        this.id_modal = "modalMapCatastro";
        this.url_root = 'https://catastroapi.cochabamba.bo/arcgis/services/'; //public dominio

        this.url_root_search = 'https://catastroapi.cochabamba.bo/arcgis/rest/services/'   //public  dominio


        this.format = 'image/png';

        this.predio = 0
        this.catastro = 0
        this.latitud = 0
        this.longitud = 0
        this.coordinate = ""
        this.zona = 0
        this.comuna = ""
        this.distrito = ""
        this.sub_distrito = ""
        this.num_inmueble = 0

        this.state = { center: [0, 0], zoom: 14, showFormMap: false, declaracionJuradas: undefined };

        this.hanldeDownloadPdf = this.hanldeDownloadPdf.bind(this)

        this.fetch = new Fetch();
        this.fetch.setToast(toast);
        this.Auth = new AuthService();

        _map_catastro = this
    }

    componentDidMount() {

        container = document.getElementById('popup');
        content = document.getElementById('popup-content-gerencia');
        closer = document.getElementById('popup-closer');
        this.renderMap();

        var self = this
    }

    componentDidUpdate() {
        this.map.setTarget("map");
        this.map.getView().setZoom(this.state.zoom);
    }

    renderMap() {
        var capa_base = new window.ol.layer.Tile({
            source: new window.ol.source.TileWMS({
                url: this.url_root + 'imagenes/imagen2018_500/MapServer/WMSServer',
                params: {
                    'LAYERS': '0',
                    'VERSION': '1.1.1',
                    'FORMAT': 'image/png',
                    'TILED': true
                },
                serverType: 'mapserver',
            })
        });

        var capa_vias = new window.ol.layer.Image({
            source: new window.ol.source.ImageWMS({
                ratio: 1,
                url: this.url_root + 'planificacion/viasWms/MapServer/WMSServer',  //ok
                params: {
                    'FORMAT': this.format,
                    'VERSION': '1.1.1',
                    'LAYERS': '0',
                    'STYLES': '',
                },
            })
        });

        capa_predios = new window.ol.layer.Image({
            source: new window.ol.source.ImageWMS({
                ratio: 1,
                //url: this.url_root + 'catastro/prediosWms/MapServer/WMSServer',//ok
                //url: this.url_root + 'recaudaciones/prediosWmsAE/MapServer/WMSServer',//ok
                url: this.url_root + 'catastro/predios_cba/MapServer/WMSServer',    //ok nuevo
                params: {
                    'FORMAT': this.format,
                    'VERSION': '1.1.1',
                    'LAYERS': '0',
                    'STYLES': '',
                }
            })
        });

        /**
         * enu => the default easting, northing
         * neu => northing, easting
         * up => useful for "lat/long
         * wnu - westing, northing
         */
        projection = new window.ol.proj.Projection({
            code: 'EPSG:32719',  //32719  4326  EPSG:3857
            units: 'm',
            axisOrientation: 'neu'    //neu
        });

        this.overlay = this.createOverlay();

        this.map = new window.ol.Map({
            controls: window.ol.control.defaults().extend([
                /*new window.ol.control.FullScreen({
                    source: 'fullscreen'
                }),*/
                new window.ol.control.ScaleLine(),
            ]),
            layers: [
                capa_base, capa_predios, capa_vias
            ],
            renderer: 'canvas',
            overlays: [this.overlay],
            view: new window.ol.View({
                projection: projection,
            })
        });

        this.map.getView().setCenter(bounds);
        this.map.getView().setResolution(this.map.getView().getResolution());

        var self = this;
        this.map.getView().on('change:resolution', function (evt) {

            var resolution = evt.target.get('resolution');
            var units = self.map.getView().getProjection().getUnits();
            var dpi = 25.4 / 0.28;
            var mpu = window.ol.proj.METERS_PER_UNIT[units];
            var scale = resolution * mpu * 39.37 * dpi;
            //var scale1 = resolution * mpu * 39.37 * dpi;
            if (scale >= 9500 && scale <= 950000) {
                scale = Math.round(scale / 1000) + "K";
            } else if (scale >= 950000) {
                scale = Math.round(scale / 1000000) + "M";
            } else {
                scale = Math.round(scale);
            }
        });
        vectorSource = new window.ol.source.Vector({  // Creación del vector source generico
        });
        vectorLayer = new window.ol.layer.Vector({
            source: vectorSource,
        });

        this.menuMapControl(this.map)

        setTimeout(() => {
            self.setState({ zoom: self.state.zoom });
            self.map.updateSize()
        }, 1500);
    }

    renderMarketInMap(reporte, total) {
        var self = _map_catastro
        if (reporte !== undefined && Boolean(reporte)) {

            vectorSource.clear(true)

            if (self.map.getOverlays().getLength() > 0) {
                self.map.getOverlays().getArray().slice(0).forEach(function (overlay) {
                    if (overlay.getProperties().element.className.indexOf("ol-popup-custom") < 0) {
                        self.map.removeOverlay(overlay);
                    }
                });
                self.map.updateSize()
            }

            for (let i = 0; i < reporte.length; i++) {
                let coordinate = reporte[i].coordinate.split(",")
                let placemark = self.createPlacemark(reporte[i].tipo_act_eco_temporal_color, reporte[i].act_eco_estado_color, self.map,
                    Math.abs(reporte[i].act_eco_numero) + "_" + reporte[i].act_eco_id)
                self.showPlacemark(placemark, coordinate, reporte[i], self)
            }

            document.getElementById("spanTotalLicenciasFuncionamiento").innerHTML = total
        }
    }

    createPlacemark(colorBackground, colorBackgroundContenido, map, nameClass) {

        var placemark = new window.ol.Overlay.Placemark({
            color: colorBackgroundContenido,  //background del ping  '#369'
            backgroundColor: colorBackground,
            contentColor: '#000',  //color del contenido
            onshow: function () {
                //console.log("You opened a placemark");
            },
            autoPan: true,
            autoPanAnimation: { duration: 250 }
        });

        placemark.setClassName('padding10 placemarkAdm ' + nameClass);
        placemark.setRadius(Number(15));
        placemark.show(placemark.getPosition())

        map.addOverlay(placemark);
        return placemark;
    }

    showPlacemark(placemark, coordinate, actividadEconomica, self) {
        if (actividadEconomica.tipo_act_eco_icon !== undefined && actividadEconomica.tipo_act_eco_icon !== null && actividadEconomica.tipo_act_eco_icon !== 'null' && actividadEconomica.tipo_act_eco_icon !== '')
            placemark.show(coordinate, "<i class='" + actividadEconomica.tipo_act_eco_icon + "'></i>");
        else
            placemark.show(coordinate, "<i class='fa fa-question'></i>");

        var placemark_$ = window.jQuery("." + Math.abs(actividadEconomica.act_eco_numero) + "_" + actividadEconomica.act_eco_id)

        var events = window.jQuery._data(placemark_$[0], "events");
        if (events === undefined || events.click.length === 0) {
            placemark_$.click(function (event) {

                self.pulseFeature(coordinate, self, 'rgb(51, 102, 153)');

                let descripcion = `<div><h5 style="font-size: 1rem;">Nro. Inmueble RUAT: <strong>${actividadEconomica.act_eco_numero}</strong></h5> `
                    + `<h5 style="font-size: .9rem; text-align: center;"><strong>${actividadEconomica.numero_ruat !== null ? actividadEconomica.numero_ruat : ""}</strong></h5>`
                    + `<span>Nro. de Orden: <strong> ${actividadEconomica.numero_orden}</strong></span>`
                    + `<br><span>Cod. Cat.: <strong> ${actividadEconomica.act_eco_cod_cat}</strong></span>`
                    + `<br><span>Rotulo Comercial: <strong>${actividadEconomica.act_eco_rotulo_comercial}</strong></span> `
                    + `<br><span>Total Licencias: <strong>${actividadEconomica.act_eco_total} DDJJ</strong></span>`
                    + `<br><span>Tipo Actividad Económica: <strong>${actividadEconomica.tipo_act_eco_nombre.substring(0, 8)}...</strong></span>`
                    + `<br><span>Temporal: <strong>${actividadEconomica.tipo_act_eco_temporal ? 'Si' : 'No'}</strong></span>`
                    + `<br><span>Sup. Actividad Económica: <strong>${actividadEconomica.act_eco_superficie}m2</strong></span>`
                    + `<br><div class="text-center"><span><a href="#"  class="${actividadEconomica.act_eco_numero + "_more_" + actividadEconomica.act_eco_id}" ><i class="fa fa-chevron-down"></i> Más <i class="fa fa-chevron-down"></i></a></span></div></div>`
                content.innerHTML = descripcion;
                self.overlay.setPosition(coordinate);

                //var notification = window.jQuery("." + actividadEconomica.act_eco_numero + "_more_" + actividadEconomica.act_eco_id)
                var notification = window.jQuery(content).find("." + actividadEconomica.act_eco_numero + "_more_" + actividadEconomica.act_eco_id)

                var events_more = window.jQuery._data(notification[0], "events");
                if (events_more === undefined || events_more.click.length === 0) {
                    notification.click(function (event) {
                        self.notificacionControl(self.map, actividadEconomica, self)
                    });
                }
            });
        }
    }

    createOverlay() {
        var overlay = new window.ol.Overlay({
            element: container,
            autoPan: true,
            autoPanAnimation: {
                duration: 250
            }
        });

        closer.onclick = function () {
            overlay.setPosition(undefined);
            closer.blur();
            return false;
        };
        return overlay;
    }


    pulseFeature(coord, self, color) {
        var f = new window.ol.Feature(new window.ol.geom.Point(coord));
        f.setStyle(new window.ol.style.Style({
            image: new window.ol.style['Circle']({
                radius: 30,
                points: 4,
                src: "../data/smile.png",
                stroke: new window.ol.style.Stroke({ color: color, width: 2 })
            })
        }));
        self.map.animateFeature(f, new window.ol.featureAnimation.Zoom({
            fade: window.ol.easing.easeOut,
            duration: 3000,
            easing: window.ol.easing['easeOut']
        }));
    }

    notificacionControl(map, actividadEconomica, self) {
        var notification = new window.ol.control.Notification({});
        map.addControl(notification);

        let contenido = `<div class="row"> <div class="col-12 col-md-3 text-left"> `
            + `<span>Comuna: <strong>${actividadEconomica.act_eco_comuna}</strong></span> `
            + `<br><span>Distrito: <strong>${actividadEconomica.act_eco_distrito}</strong></span> `
            + `<br><span>Sub Distrito: <strong>${actividadEconomica.act_eco_sub_distrito}</strong></span>  `
            + `<br><span>Zona Tributaria: <strong>${actividadEconomica.zona}</strong></span>  `
            + `<br><span>Coord.: <strong style='font-size: 10.5px;'>${actividadEconomica.coordinate}</strong></span> </div> `
            + `<div class="col-12 col-md-5 text-left"> `
            + `<span>Tipo Actividad Económica: <strong>${actividadEconomica.tipo_act_eco_nombre}</strong></span>`
            + `<br><span>Total: <strong><a href="#" class="${actividadEconomica.act_eco_numero + "_ddjj_" + actividadEconomica.act_eco_id}" style="color: black; text-decoration: underline;" num_doc="${actividadEconomica.persona_ci}" >${actividadEconomica.act_eco_total} DDJJ</a></strong></span> `
            + `<br><span>Dirección: <strong>${actividadEconomica.dom_act_eco_direccion}</strong></span>`
            + `<br><span>Nit: <strong>${Boolean(actividadEconomica.datos_juridicos_nit) ? actividadEconomica.datos_juridicos_nit : ''}</strong></span>`
            + `<br><span>Usuario: <strong>${actividadEconomica.username}</strong></span>`
            + `</div> `
            + `<div class="col-12 col-md-4 text-left"> `
            + `<span>Propietario: <strong> ${actividadEconomica.persona_full_name}</strong></span> `
            + `<br><span>Nro. Documento: <strong>${actividadEconomica.persona_ci}</strong></span>`
            + `<br><span>Edad: <strong>${actividadEconomica.persona_edad}</strong></span>`
            + `<br><span>Género: <strong>${actividadEconomica.persona_genero}</strong></span>`
            + `<br><span>Teléfono: <strong>${actividadEconomica.dom_act_eco_telefono}</strong></span>`
            + `</div>`
            + `</div>`

        var div = window.jQuery('<div style="width: 100%" >').html(contenido);

        window.jQuery('<a style="position: absolute; top: -22px; right: 0;padding-right: 0px; font-size: 20px">').html('<i class="fa fa-close" aria-hidden="true"></i>').click(function () {
            notification.hide();
        })
            .appendTo(div);
        notification.show(div.get(0));
        window.jQuery(div.parent('div')[0]).css("width", '100%')

        window.jQuery("." + actividadEconomica.act_eco_numero + "_ddjj_" + actividadEconomica.act_eco_id).unbind('click');

        var more_ddjj_$ = window.jQuery("." + actividadEconomica.act_eco_numero + "_ddjj_" + actividadEconomica.act_eco_id)
        var events = window.jQuery._data(more_ddjj_$[0], "events");
        if (events === undefined || events.click.length === 0) {
            more_ddjj_$.click(function (event) {

                let num_doc = event.target.getAttribute("num_doc").substring(0, event.target.getAttribute("num_doc").indexOf(" "))
                
                if (Boolean(num_doc)) {
                    const declaraciones_juradas = self.fetch.fetchGet(`api/administracion/get-declaraciones-juradas-by-persona-ci/${num_doc}`);
                    declaraciones_juradas.then(res => {
                        if (res !== undefined && res.status === true) {

                            let list = res.DeclaracionJurada.map((declaracion_jurada, index) => {
                                const { token, tipo_actividad_economica, actividad_economica, numero, fur, created_at, estado_code } = declaracion_jurada //destructuring
                                return (
                                    <tr key={token}>
                                        <td>{tipo_actividad_economica.substring(0, 20)}...</td>
                                        <td>{actividad_economica !== null ? actividad_economica.substring(0, 25) : actividad_economica}...</td>
                                        <td>{numero}</td>
                                        <td>
                                            {estado_code === Constant[0].estado.en_proceso ? <span title={estado_code}><i className="fa fa-cog" aria-hidden="true" ></i></span> : ""}
                                            {estado_code === Constant[0].estado.completado ? <span title={estado_code}><i className="fa fa-file-text-o" aria-hidden="true"></i></span> : ""}
                                            {estado_code === Constant[0].estado.aprobado ? <span title={estado_code}><i className="fa fa-check" aria-hidden="true"></i></span> : ""}
                                            {estado_code === Constant[0].estado.eliminado ? <span title={estado_code}><i className="fa fa-ban" aria-hidden="true"></i></span> : ""}
                                        </td>
                                        <td>{created_at}</td>
                                        <td>
                                            <Link to="#" title="Descargar" token={token} onClick={self.hanldeDownloadPdf}>
                                                <i className="fa fa-download" aria-hidden="true"></i>
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            })

                            self.setState({
                                declaracionJuradas: list
                            })
                            window.jQuery("#modalDDJJMapCatastro").modal("show")
                        }
                    })
                }
            });
        }
    }

    menuMapControl(map) {
        var boton = document.createElement('button');
        boton.innerHTML = '';
        boton.className = 'fa fa-question';
        boton.addEventListener('click', function () {
            window.jQuery("#container-div-help-map").hasClass("hide") ? window.jQuery("#container-div-help-map").removeClass("hide") : window.jQuery("#container-div-help-map").addClass("hide");
            window.jQuery("#container-div-help-map").closest(".ol-control").css("background-color", "transparent")
        });

        var elementoDiv = document.createElement('div');
        elementoDiv.className = 'boton-search-catastro ol-unselectable ol-control';

        var elementoDivContainer = document.createElement("div");
        elementoDivContainer.setAttribute('id', "container-div-help-map")
        elementoDivContainer.setAttribute('class', 'hide')
        elementoDivContainer.setAttribute('class', 'hide')

        //cargamos los iconos en el container
        const comunas = this.fetch.fetchGet(`api/tipo-actividad-economica/get-all-icon`);
        comunas.then(res => {
            if (res !== undefined && res.status === true) {

                let pin = `<div class="ol-popup placemark ol-popup-bottom ol-popup-center anim padding10 placemarkAdm  visible" `
                    + `style="position: initial; color: rgb(204, 0, 0); background-color: color_background_replace; font-size: 15px; left: 357px; bottom: 479px;">`
                    + `   <div> `
                    + `        <button class="closeBox" type="button"></button>`
                    + `       <div class="anchor"> `
                    + `        </div>`
                    + `       <div class="ol-popup-content" style="color: rgb(0, 0, 0)" ><i class="icon_font_replace"></i>`
                    + `       </div>`
                    + `   </div>`
                    + `</div>`
                let li = ""

                window.jQuery.each(res.Icon, function (index, icon) {
                    li += "<li class='list-inline-item' title='Pendiente'>" + pin.replace("icon_font_replace", icon.icon).replace("color_background_replace", "rgb(255, 255, 255)") + "</li>"
                })
                let ul = `<div style="height: 300px;overflow: overlay;"><ul class="list-inline">` + li + `</ul></div>`
                let titulo_principal = "<h5 style='border-bottom: 1px solid;'>Actividades Económicas</h5>"
                let titulo_icono = "<h6 style='margin-top: 1rem; margin-bottom: 0rem;'>Iconos</h6>"
                let titulo_validez = "<h6 style='margin-top: 1rem; margin-bottom: 0rem;' >Derecho de Admición</h6>"

                let li_validez = "<li class='list-inline-item' title='TEMPORAL'>" + pin.replace('icon_font_replace', 'fa fa-question').replace('color_background_replace', 'rgb(255, 178, 217)') + "</li>"
                li_validez += "<li class='list-inline-item' title='PERMANENETE'>" + pin.replace('icon_font_replace', 'fa fa-question').replace('color_background_replace', 'rgb(255, 255, 255)') + "</li>"
                let ul_validez = `<ul class="list-inline">` + li_validez.replace('fa-cutlery', "").replace('fa-cutlery', '') + `</ul>`
                elementoDivContainer.innerHTML = titulo_principal + titulo_icono + ul + titulo_validez + ul_validez
            }
        })

        elementoDiv.appendChild(boton);
        elementoDiv.appendChild(elementoDivContainer);

        var searchControl = new window.ol.control.Control({ element: elementoDiv });
        this.map.addControl(searchControl);


        var elementoDivMenu = document.createElement('div');
        elementoDivMenu.className = 'boton-search-catastro ol-unselectable ol-control';
        elementoDivMenu.style = ' right: .4rem; left: initial; top: 0rem; ';

        var boton_menu = document.createElement('button');
        boton_menu.innerHTML = '';
        boton_menu.className = 'fa fa-expand';

        var container_form = document.getElementById("divContainerFormMap");
        var self = this

        boton_menu.addEventListener('click', function () {

            if (document.getElementsByClassName("content-wrapper")[0].classList.contains('paddingTop80')) {

                document.getElementsByClassName("breadcrumb-area")[0].classList.remove("hide")
                document.getElementsByTagName("footer")[0].classList.remove("hide")

                document.getElementsByClassName("content-wrapper")[0].classList.add("paddingTop30")      //por defecto
                document.getElementsByClassName("content-wrapper")[0].classList.remove("paddingTop80")       //nuevo

                document.getElementById("fullscreen").style.margin = ''

                document.getElementById("fullscreen").style.width = '100%'
                document.getElementById("fullscreen").style.height = '600px'

            } else {
                document.getElementsByClassName("breadcrumb-area")[0].classList.add("hide")
                document.getElementsByTagName("footer")[0].classList.add("hide")

                document.getElementsByClassName("content-wrapper")[0].classList.remove("paddingTop30")      //por defecto
                document.getElementsByClassName("content-wrapper")[0].classList.add("paddingTop80")       //nuevo

                document.getElementById("fullscreen").style.margin = '-15px'
                document.getElementById("fullscreen").style.width = 'calc( ' + window.jQuery(window).width() + 'px - 15px)'
                document.getElementById("fullscreen").style.height = 'calc( ' + window.jQuery(window).height() + 'px - 8%)'
            }
        });

        elementoDivMenu.appendChild(boton_menu);

        var crtol = new window.ol.control.Control({ element: elementoDivMenu });
        this.map.addControl(crtol);
    }

    hanldeDownloadPdf(event) {
        event.preventDefault()
        let token = ""
        if (event.target.parentElement.getAttribute("token") !== undefined)
            token = event.target.parentElement.getAttribute("token")

        if (event.target.getAttribute("token"))
            token = event.target.getAttribute("token")

        window.location.href = Config[0].url + `report/licencia-actividad-economica-download/${token}/?auth=` + this.Auth.getToken();
    }

    render() {
        return (
            <>
                <div className="col-12 col-md-12">
                    <div id="fullscreen" className="fullscreen" style={{ height: '600px' }}>
                        <div id="map" className="map-layer">
                            <div id="popup" className="ol-popup ol-popup-custom">
                                <a href="#" id="popup-closer" className="ol-popup-closer"></a>
                                <div id="popup-content-gerencia"></div>
                            </div>
                        </div>
                    </div>

                    <div id="wrapper">
                        <div id="location"></div>
                    </div>
                </div>

                <div className="modal fade " id="modalDDJJMapCatastro" tabIndex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-lg" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle"> Declaraciones Juradas</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table table-striped">
                                            <tbody>
                                                <tr>
                                                    <th scope="col">Categoría</th>
                                                    <th scope="col">Actividad Económica</th>
                                                    <th scope="col">Número de Orden</th>
                                                    <th scope="col">Estado</th>
                                                    <th scope="col">Creado</th>
                                                    <th scope="col"></th>
                                                </tr>
                                                {this.state.declaracionJuradas}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default MapCatastro;