import React, { } from 'react';
import { useHistory } from 'react-router-dom'
import Links from '../../data/link';
import Config from '../../data/config';
import { Link } from 'react-router-dom';
import Fetch from '../utils/Fetch';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Services = (props) => {

    const fetch = new Fetch();
    fetch.setToast(toast);
    const history = useHistory();

    /*const handleOpenCertificacion = (event) => {
        event.preventDefault();

        const form = new FormData();
        fetch.fetchPost(form, 'api/int/integracion-contribuyentes-certificados', undefined).then(dataJson => {
            if (dataJson && dataJson.status)
                history.push(Links[50].url + "/inm?csrf-token=" + dataJson.csrfToken + "&expiraEn=" + dataJson.expiraEn+"&lo="+dataJson.lo);
        })
    }

    const hanldeOpenCambioRadicatoria = (event) => {
        event.preventDefault();
        const form = new FormData();
        fetch.fetchPost(form, 'api/int/integracion-contribuyentes-certificados', undefined).then(dataJson => {
            if (dataJson && dataJson.status)
                history.push(Links[62].url + "?csrf-token=" + dataJson.csrfToken );
        })
    }*/

    return (
        <section className="why-choose section" style={{ zIndex: 0 }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="choose-content mt-4">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-12">
                                    <Link to={{ pathname: Links[95].url }} className="text-decoration-none w-100" title={Links[95].title} >
                                        <div className="single-circule wow fadeInUp" data-wow-delay=".2s">
                                            <div className="text">
                                                <img className="img-thumbnail rounded mx-auto d-block img-noborder"
                                                    alt="Liquiedaciones Mixtas" width="108" height="108"
                                                    src={Config[2].url + '/static/img/offcanvas/ddjj.png'}
                                                />
                                            </div>
                                            <h4>Notificación R.A.S.</h4>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-lg-3 col-md-6 col-12">
                                    <Link to={{ pathname: Links[105].url }} className="text-decoration-none w-100" title={Links[105].title} >
                                        <div className="single-circule wow fadeInUp" data-wow-delay=".2s">
                                            <div className="text">
                                                <img className="img-thumbnail rounded mx-auto d-block img-noborder"
                                                    alt="Liquiedaciones Mixtas" width="108" height="108"
                                                    src={Config[2].url + '/static/img/offcanvas/ddjj.png'}
                                                />
                                            </div>
                                            <h4>Notificaciones COACTIVAS</h4>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services;
