import React from "react";
import PropTypes from "prop-types";
import Breadcrumb from "./Breadcrumb";

//const TitlePageBreadcrumb = ({titlePage, url, title}) => {
const TitlePageBreadcrumb = ({ titlePage, breadcrumbs, position, leftfull }) => {

	return (
		<>
			<div className="pt-3 bg-gray">
				<div className="container">
				<Breadcrumb
					breadcrumbs={breadcrumbs}
					position={position}
					leftfull={leftfull}
				></Breadcrumb>
				</div>
			</div>
			<div>
			<div className="container">
				<h4 className="text-capitalize border-bottom text-lg-left">
					{titlePage}
				</h4>
				</div>
			</div>
		</>

	);
};

TitlePageBreadcrumb.prototype = {
	titlePage: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};

export default TitlePageBreadcrumb;
