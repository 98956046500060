import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./../../style/style-simat.css";
import Texto from "../../../data/es";

import { toast } from "react-toastify";
import TemporizadorEmail from "./TemporizadorEmail";

const RegisterEmailVerify = ({
	onSubmit,
	dataFormulario,
	setTitlePage,
	setDescription,
	fetch,
	setCurrentStep
}) => {

	const [minutoTemporizador, setMinutoTemporizador] = useState(60);
	const [bloqueado, setBloqueado] = useState(false);
	const [tiempoRestante, setTiempoRestante] = useState(1800); // 30 minutos en segundos

	useEffect(() => {
		if (dataFormulario && dataFormulario.email) {
			handleVerificarRegistroConsecutivo(dataFormulario.email);
		}
	}, []);

	// efect para tiempo restante
	useEffect(() => {
		let interval;

		if (bloqueado && tiempoRestante > 0) {
			// Configurar un intervalo que disminuye el tiempo restante cada segundo
			interval = setInterval(() => {
				setTiempoRestante((prevTiempo) => prevTiempo - 1);
			}, 1000);
		} else if (tiempoRestante === 0) {
			setBloqueado(false); // Desbloquear el botón cuando el tiempo se agota
		}

		// Limpiar el intervalo al desmontar el componente o si cambia el estado
		return () => clearInterval(interval);
	}, [bloqueado, tiempoRestante]);


	// submit
	const handleSubmit = (e) => {
		e.preventDefault();
		setMinutoTemporizador(60);
		// handleCheckEmailValidation();		
		handleCheckLinkEmail();
	}

	const handleCheckLinkEmail = async () => {
		try {
			if (dataFormulario && dataFormulario.email) {
				const form = new FormData();
				form.append("registro[email]", dataFormulario.email);
				const dataJson = await fetch.fetchPost(form, "api/usuario/registro/enlace/verificar-correo");

				if (dataJson && dataJson.status) {
					fetch.toast.success(dataJson.message);
					onSubmit(dataFormulario.email);
				}
			}
		} catch (error) {
			toast.error("Error al realizar la verificación del correo electrónico. Vuelva a intentar");
		}
	}


	// btn - volver
	const handleButtonVolver = () => {

		if (!bloqueado && dataFormulario?.email) {
			setTitlePage(Texto.register_info_email_title);
			setDescription(Texto.register_info_email_description + " " + dataFormulario.email);
			setCurrentStep(3)
		}
	}

	//verificar registro consecutivo
	const handleVerificarRegistroConsecutivo = async () => {
					
			setBloqueado(false);

			if (dataFormulario?.email) {
				const form = new FormData();
				form.append("registro[email]", dataFormulario.email);

				const dataJson = await fetch.fetchPost(form, "api/usuario/registro/consecutivo-correo");
				if (dataJson && dataJson.status) {					
					setBloqueado(true);
					setTiempoRestante(1800); // Reinicia el tiempo a 10 minutos (600 segundos)	
					setMinutoTemporizador(5);
				}			
			}		
	}

	// Convertimos el tiempo restante a minutos y segundos
	const minutos = Math.floor(tiempoRestante / 60);
	const segundos = tiempoRestante % 60;

	/* ================================================================================= */
	return (
		<>

			<div className="text-center mb-3">
				<button className="btn">
					<i className='fa fa-envelope-square fa-4x text-black-50'></i>
				</button>
			</div>
			{/* {bloqueado ? `${minutos}:${segundos < 10 ? `0${segundos}` : segundos}` : ""} */}

			{!bloqueado && dataFormulario && dataFormulario.email &&
				<div className="row mt-3">
					<div className="text-justify col-lg-12 col-md-12 col-sm-12 mx-auto border-radius-035 bg-gray-5 p-3">
						<TemporizadorEmail totalSegundos={minutoTemporizador} Texto={Texto} correoElectronico={dataFormulario.email} fetch={fetch} toast={toast} handleVerificarRegistroConsecutivo={handleVerificarRegistroConsecutivo} />
					</div>
				</div>
			}

			{bloqueado &&
				<div className="alert alert-danger mt-3" role="alert">
					<p>Intente nuevamente en <b>30</b> minutos, para acceder al reenvio del enlace  de verificación del correo electrónico.</p>
				</div>
			}

			<form onSubmit={handleSubmit}>
				<div className="d-flex d-sm-flex flex-sm-row justify-content-between mt-3">
					<div>
						<button
							type="button"
							className="btn btn-fucsia"
							onClick={handleButtonVolver}
						>
							Volver
						</button>
					</div>
					<div>
						<button type="submit" className="btn btn-violeta">
							Continuar
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

RegisterEmailVerify.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default RegisterEmailVerify;
