import React, { useEffect } from 'react';
import Texto from '../../data/es';
import Links from '../../data/link';
import TitlePage from '../utils/TitlePage';

let breadcrumbs = [
    {
        title: Links[0].title,
        url: Links[0].url
    },
    {
        title: Links[38].title,
        url: Links[38].url
    }
];

const Perdonazo2020 = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const redirectToPage = (event, url) => {
        event.preventDefault()
        window.location.href = url
    }

    const redirectToPageExternal = (event, url) => {
        event.preventDefault()
        window.open(url, '_blank');
    }
    return (
        <section className="items-grid section mt-5">
            <div className="container mt-4">
                <TitlePage titlePage={Texto.ddjj} breadcrumbs={breadcrumbs} position={'left'} leftfull={false} />

                <div className="container">
                    <div className="row mt-4">
                        <div className="col-12 col-lg-2"  >
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 wow fadeInUp" onClick={e => redirectToPageExternal(e, Links[24].url)} data-wow-delay=".3s">
                            <div className="button-big button-big-dos " style={{ minHeight: 140 }}>
                                <div className="left"><h3>{Texto.terceraedad}</h3>
                                    <span>SOLICITUD DE AUTORIZACIÓN DE DESCUENTO DE ADULTO MAYOR</span></div>
                                <div className="right icon-grandsparents"></div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4" onClick={e => redirectToPage(e, Links[52].url)} data-wow-delay=".3s">
                            <div className="button-big button-big-dos " style={{ minHeight: 140 }}>
                                <div className="left"><h3>{Texto.serviciopublico}</h3>
                                    <span>SOLICITUD DE CERTIFICADO DE TRANSPORTE DE SERVICIO PUBLICO</span></div>
                                <div className="right icon-service-public"></div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-2"  >
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Perdonazo2020;