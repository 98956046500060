import React, { useEffect, useState } from 'react';
import Links from '../../../data/link';
import Fetch from '../../../components/utils/Fetch';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Form from './Form'

//import Links from '../../data/link';
import TitlePage from '../../../components/utils/TitlePage'
import Texto from '../../../data/es'

const UpdateCertification = (props) => {

    const fetch = new Fetch();
    fetch.setToast(toast);

    const [servicioPublico, setServicioPublico] = useState(undefined)
    const [vehiculoRuat, setVehiculoRuat] = useState(undefined)

    useEffect(() => {
        searchCertificadoServicioPublico()
    }, []);



    //buscar al certificado por su token y su numero
    const searchCertificadoServicioPublico = async () => {
        const token = tokenCertificacionUrl()
        //const numero = numeroCertificacionUrl()
        const dataJsonServicioPublico = await fetch.fetchGet('api/certificacion-servicio-publico/' + token);
        if (dataJsonServicioPublico && dataJsonServicioPublico.status === true && dataJsonServicioPublico.data) {
            setServicioPublico(dataJsonServicioPublico.data.vehiculoServicioPublico)
            setVehiculoRuat(dataJsonServicioPublico.data.vehiculo)
            toast.success(dataJsonServicioPublico.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    const handleSubmitForm = (event, vehiculo) => {
        event.preventDefault()
        window.jQuery("#" + event.target.getAttribute('id')).parsley().validate();

        let form_html = event.target;
        const form = new FormData(event.target);
        var target = event.target

        debugger
        /*let deudas = vehiculo.dataDeudas
        let obervaciones = vehiculo.dataObservaciones
        let observado = vehiculo.dataObservado
        let informacion = vehiculo.dataVehiculo*/

        if (window.jQuery("#" + form_html.getAttribute('id')).parsley().isValid()) {
            window.createBootbox("Esta Seguro de Continuar.", function (result) {
                if (result === true) {
                    /*fetch.fetchPost(form, 'api/certificacion-servicio-publico/create', target).then(dataJson => {
                        if (dataJson !== undefined && dataJson.status === true) {
                            debugger
                            let certificadoServicioPublico = dataJson.data.certificadoServicioPublico
                            history.push("/detalle-certificacion-transporte-publico/" + certificadoServicioPublico.token);
                        }
                    })*/
                }
            })
        }
    }

    const tokenCertificacionUrl = () => {
        const query = window.location.pathname.split("/")
        return query[query.length - 2]
    }

    const numeroCertificacionUrl = () => {
        const query = window.location.pathname.split("/")
        return query[query.length - 1]
    }



    const breadcrumbs = [
        {
            title: Links[0].title,
            url: Links[0].url
        },
        {
            title: Links[38].title,
            url: Links[38].url
        },
        {
            title: 'CTSP',
            url: '#'
        }
    ];

    return (
        <div id="contact" className="contact paddingTop" >
            <TitlePage titlePage={Texto.solicitud_certificado_transporte_servicio_publico} breadcrumbs={breadcrumbs} position={'left'} />

            <div className="container" style={{ marginTop: '-50px' }}>
                {
                    servicioPublico && vehiculoRuat ?
                        <Form onSubmitForm={handleSubmitForm} servicioPublicoDb={servicioPublico} vehiculoRuatDb={vehiculoRuat} />
                        : ""
                }
            </div>
        </div>
    )
}

export default UpdateCertification