import React, { useEffect, useState, useRef } from "react";
import "./css/style.css";
import Links from "../../data/link";
import TitlePageBreadcrumb from "../utils/TitlePageBreadcrumb";
import Config from "../../data/config";
import Texto from "../../data/es";
import AuthService from "../Usuario/AuthService";
import { Link, useHistory } from "react-router-dom";
import Fetch from "../utils/Fetch";
import { ToastContainer, toast } from "react-toastify";
import TributarioButton from "./_common/TributarioButton";
import ModalFormularioFile from "./_common/ModalFormularioFile";
import DataTable from "react-data-table-component";
import CustomLoader from "../utils/SpinnerDataTable";

let breadcrumbs = [
    {
        title: Links[0].title,
        url: Links[0].url,
    },
    {
        title: Links[134].title,
        url: Links[134].url,
    }
];

const AdminRas = (props) => {

    const fetch = new Fetch();
    fetch.setToast(toast);
    // const titlePage = Texto.ras_admin;
    const idForm = "formDataFile";
    const idFormImportFile = "modalFormCreate";

    const titleFormatoRasVehiculo = "VEHICULO.csv";
    // =========================================== //
    const history = useHistory();
    const auth = new AuthService();
    const [dataTributario, setDataTributario] = useState([]);
    const [dataUnidad, setDataUnidad] = useState([]);
    const [selectData, setSelectData] = useState(null);
    const [codeDepartament, setCodeDepartament] = useState(null);
    const [codeObjeto, setCodeObjeto] = useState(null);
    const [titlePage, setTitlePage] = useState(Texto.ras_admin_inicio);
    
    var dataTributaryDescription = "";
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);

    // documents
    const [data, setData] = useState();
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    // items
    const [totalRowsItem, setTotalRowsItem] = useState(0);
    const [perPageItem, setPerPageItem] = useState(10);
    const [currentPageItem, setCurrentPageItem] = useState(1);

    // modales
    const [showModalItemFile, setShowModalItemFile] = useState(false);
    const [showModalForm, setShowModalForm] = useState(false);
    const [modalSearch, setModalSearch] = useState(undefined);

    const [selected, setSelected] = useState([]);
    // const [itemsFileData, setItemsFileData] = useState([]);

    // const userAuth = auth.getProfile();
    if (!auth.loggedIn()) history.replace(Links[4].url);

    useEffect(() => {
        loadDataObjeto()

        if (codeDepartament != null && codeObjeto != null) {
            getdatalistaFileRas();
        }

        window.scrollTo(0, 0);
    }, [codeDepartament, codeObjeto]);




    const getdatalistaFileRas = async () => {
        //setLoading(true);
        debugger;
        const response = await fetch.axiosAsyncGet(
            `api/ras/lista/${codeObjeto}/${codeDepartament}/${currentPage}/${perPage}`
        );

        if (response && response.status) {
            setData(response.data);
            setTotalRows(response.total);
            setLoading(false);
            toast.success(response.message);
        }
    };

    const loadDataObjeto = async () => {
        debugger
        const responseTributario = await fetch.axiosAsyncGet(
            `api/nt-tributario/objeto-tributario/ras`
        );
        console.log('------ responseTributario --- ', responseTributario);
        if (responseTributario && responseTributario.status) {
            debugger
            setDataTributario(responseTributario.ObjetoTributario);
            setDataUnidad(responseTributario.Departament);
        }
    };

    const handlePageChangeFile = async (page) => {
		setLoading(true);
		const response = await fetch.axiosAsyncGet(
			`api/ras/lista/${codeObjeto}/${codeDepartament}/${page}/${perPage}`
		);
		const json = await response.data;
		setLoading(false);
		setData(json);
		setCurrentPage(page);
	};

    const handlePerRowsChangeFile = async (perPage, page) => {
        setLoading(true);
		const response = await fetch.axiosAsyncGet(
			`api/ras/lista/${codeObjeto}/${codeDepartament}/${page}/${perPage}`
		);
		const json = await response.data;
		setLoading(false);
		setData(json);
		setPerPage(perPage);
		setCurrentPage(page);
    };

    const formatDateDMY = (fecha) => {
        return window.moment(fecha).format("DD/MM/YYYY");
    };

    const formatDateDMYHIS = (fechahora) => {
        return window.moment(fechahora).format("DD/MM/YYYY H:mm");
    };

    // text search delete
    const handleButtonClickSearchDelete = () => {
        let inputSearchFile = document.getElementById("search_files");
        if (typeof inputSearchFile !== "undefined" && inputSearchFile !== null) {
            inputSearchFile.value = "";
        }
    };


    // search main documents
    const handleButtonClickSearch = async () => {
        debugger;
        // element input
        let inputSearchFile = document.getElementById("search_files");
        let totalSearchText = inputSearchFile.value.length;
        let textSearchUse = totalSearchText == 0 ? null : inputSearchFile.value;

        setLoading(true);
        // const response = await fetch.axiosAsyncGet(
        // 	`api/nt-tributaria/notificacion-list/${objeto}/${codeTypeNotification}/${currentPage}/${perPage}/${textSearchUse}`
        // );

        // if (response && response.status) {
        // 	// setData(response.data);
        // 	// setTotalRows(response.total);
        // 	// setLoading(false);
        // 	toast.success(response.message, {
        // 		position: "top-right",
        // 		autoClose: 5000,
        // 		hideProgressBar: false,
        // 		closeOnClick: true,
        // 		pauseOnHover: true,
        // 		draggable: true,
        // 	});
        // }
    };

    const openModalFormulario = () => {
        debugger;
        setShowModalItemFile(false);
        setShowModalForm(true);
        setSelected([]);

        setTimeout(() => {
            const modalSearchDomFile = document.getElementById(idFormImportFile);
            const modalSearchImport = new window.bootstrap.Modal(modalSearchDomFile, {
                keyboard: true,
                backdrop: "static",
                focus: true,
            });
            modalSearchImport.show();
            setModalSearch(modalSearchImport);

        }, 500);
    };

   

    const onClickUploadFileInput = (event) => {
        const inputFile = event.target;
        if (inputFile) {
            const divContainerFather = inputFile.closest(".row");

            let iconFile = inputFile.parentElement.parentElement.querySelector("i");
            if (iconFile) inputFile.parentElement.parentElement.removeChild(iconFile);

            if (inputFile.files.length > 0) {
                const format = inputFile.files[0].type.split("/");
                if (/^(|csv)$/i.test(format[1])) {
                    const icono = document.createElement("i");
                    if (format[1] === "csv") {
                        icono.className = "fas fa-file-pdf icon";
                    }

                    inputFile.parentElement.parentElement.appendChild(icono);

                    const arrayNameFile = inputFile.files[0].name.split("/");
                    const nameFile = arrayNameFile[arrayNameFile.length - 1];

                    divContainerFather.getElementsByClassName("nameFile")[0].innerHTML =
                        (nameFile.length >= 30
                            ? "..." +
                            nameFile.substring(nameFile.length - 30, nameFile.length)
                            : nameFile) +
                        " - " +
                        window.niceBytes(inputFile.files[0].size);

                    window.parsley.addValidator("maxFileSize", {
                        requirementType: "integer",
                        validateString: function (value, maxSize, parsleyInstance) {
                            const files = parsleyInstance.$element[0].files;
                            return files.length !== 1 || files[0].size <= maxSize * 1024;
                        },
                        messages: {
                            es: "El archivo no debe superar los %s Kb",
                        },
                    });
                } else {
                    toast.warning("Formato no valido", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    };

    const itemsMenu = dataTributario.map((rowObjectTributario) => (
        <div className="col-lg-4 col-xl-4 col-md-4 col-xs-6 ">
            <TributarioButton
                rowObjectTributario={rowObjectTributario}
                listUnidades={dataUnidad}
                setSelectData={setSelectData}
                setCodeDepartament={setCodeDepartament}
                setCodeObjeto={setCodeObjeto}
                setTitlePage={setTitlePage} />
        </div>
    ));

    const columnsItemsDocumento = [
        // {
        //     name: "Codigo",
        //     selector: "code",
        //     sortable: true,
        //     cell: (row) => <span>{row.code}</span>,
        // },

        {
            name: "Fecha",
            selector: "fecha_publication",
            sortable: true,
            cell: (row) => <span>{formatDateDMY(row.fecha_publication)}</span>,
        },

        {
            name: "Nombre Archivo",
            selector: "nombre",
            sortable: true,
            cell: (row) => <span>{row.nombre}</span>,
        },



        {
            name: "Objeto",
            selector: "objeto",
            sortable: true,
            cell: (row) => <span>{removeTextInParentheses(row.objeto)}</span>,
        },

        {
            name: "Unidad",
            selector: "unidad",
            sortable: true,
            cell: (row) => <span>{row.unidad}</span>,
        },
        {
            name: "Publicado",
            selector: "status_publication",
            sortable: false,
            cell: (row) => <span>{row.status_publication}</span>,
        },

        {
            name: "",
            sortable: true,
            cell: (row) => (
                <div className="text-sm-start text-md-end" style={{ width: "100px" }}>
                    <span
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        className="pe-2 ps-2"
                    >
                        <i className="fas fa-ellipsis-v text-primary "></i>
                    </span>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <li>
                            <Link
                                to="#"
                                className="dropdown-item"
                                title={`Eliminar : ${row.nombre}`}
                            //onClick={() => onClickDocumentDelete(row)}
                            >
                                Ver
                            </Link>
                        </li>

                        {row.status_publication === "NO" ? (
                            <li>
                                {
                                    <Link
                                        to="#"
                                        className="dropdown-item"
                                        title={`Eliminar : ${row.nombre}`}
                                        onClick={() => onClickDocumentDelete(row)}
                                    >
                                        Eliminar
                                    </Link>
                                }
                            </li>
                        ) : (
                            ""
                        )}

                        {row.status_publication === "NO" ? (
                            <li>
                                {
                                    <Link
                                        to="#"
                                        className="dropdown-item"
                                        title={`Publicar : ${row.nombre}`}
                                        onClick={() => onClickDocumentPublish(row)}
                                    >
                                        Publicar
                                    </Link>
                                }
                            </li>
                        ) : (
                            ""
                        )}

                    </ul>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];


    const onClickDocumentPublish = (row) => {
        debugger;
        window.createBootbox(
            "Está seguro de publicar el documento '" + row.nombre + "'.",
            function (result) {
                if (result) {
                    const form = new FormData();
                    form.append("document[code]", row.code);
                    fetch
                        .fetchPost(form, "api/ras/document-publicar")
                        .then((dataJson) => {
                            if (dataJson && dataJson.status) {
                                toast.success(dataJson.message);
                                getdatalistaFileRas();
                            }
                        });
                }
            }
        );
    };

    // eliminar documento sin publicar
    const onClickDocumentDelete = (row) => {
        debugger;

        window.createBootbox(
            "Está seguro de eliminar el documento '" + row.nombre + "'.",
            function (result) {
                if (result) {
                    const dataResponse = fetch.fetchGet(
                        `api/ras/document-delete/${row.code}`
                    );
                    dataResponse
                        .then((res) => {
                            if (res && res.status) {
                                toast.success(res.message);
                                getdatalistaFileRas();
                            } else {
                                toast.error(res.message);
                            }
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                            toast.error('Ocurrio un error, vuelva a intentarlo.');
                        });
                }
            }
        );
    };

    const removeTextInParentheses = (text) => {
        return text.replace(/\s*\(.*?\)\s*/g, '').trim();
    };

    // handle form submit
    const handleFormSubmitModalFile = async (evt) => {
        // setLoading(true);
        debugger;
        evt.preventDefault();
        setDisabled(true);
        setLoadingForm(true);

        if (
            window
                .jQuery("#" + idForm)
                .parsley()
                .isValid()
        ) {
            const thisForm = document.getElementById(`${idForm}`);
            var formData = new FormData(thisForm);
            formData.append("objeto[code]", codeObjeto);
            formData.append("departamento[code]", codeDepartament);

            // typeNotification[code]
            const saveObjetoTributario = await fetch.fetchPost(
                formData,
                "api/ras/document-import",
                evt.target
            );

            if (saveObjetoTributario && saveObjetoTributario.status) {
                setShowModalItemFile(false);
                setShowModalForm(false);
                setLoadingForm(false);
                modalSearch.hide();
                toast.success(saveObjetoTributario.message);
                getdatalistaFileRas();
            }
        }

        setLoadingForm(false);
        setDisabled(false);
    };

    // ================================================================== //

    return (
        <section className="items-grid section mt-5 mb-5 bg-gray">
            {
                selectData === null ?
                    <>
                        <TitlePageBreadcrumb
                            titlePage={titlePage}
                            breadcrumbs={breadcrumbs}
                            position={"left"}
                            leftfull={false}
                        />
                        <div className="container mt-4">
                            <p className="text-justify">
                                Seleccione y haga click sobre el objeto tributario que desea
                                publicar o ver los registros existentes.
                            </p>

                            <div className="row mt-3 justify-content-center align-items-center">
                                {itemsMenu}
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <TitlePageBreadcrumb
                            titlePage={titlePage}
                            breadcrumbs={breadcrumbs}
                            position={"left"}
                            leftfull={false}
                        />
                        <div className="container mt-4">
                            {codeDepartament} - {codeObjeto}

                            <div className="row">
                                <div className="col-lg-12 col-xs-12 text-justify ">
                                    <h6 className="text-fountain-blue mb-3 mt-3">
                                        {dataTributaryDescription}
                                    </h6>
                                </div>

                                <div className="col-lg-6 col-xs-12 ">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            name="search_files"
                                            id="search_files"
                                            className="form-control"
                                            placeholder="Ingrese nombre del archivo adjunto"
                                            aria-label="Buscar"
                                            autoComplete="off"
                                        />
                                        <div className="input-group-prepend">
                                            <button
                                                className="btn btn-outline-danger"
                                                id="btn_delete_search"
                                                title="Borrar"
                                                onClick={handleButtonClickSearchDelete}
                                            >
                                                <i className="fas fa-times"></i>
                                            </button>

                                            <button
                                                className="btn  btn-outline-primary"
                                                title="Buscar ..."
                                                onClick={handleButtonClickSearch}
                                            >
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-xs-12" style={{ textAlign: "right" }}>
                                    <button
                                        className="btn btn-outline-primary btn-sm mb-2 mt-2"
                                        onClick={() => openModalFormulario()}
                                    >
                                        <i className="fa fa-plus"></i> Procesar Archivo Nuevo
                                    </button>

                                    <a
                                        download={true}
                                        href={
                                            "/static/ras/" + titleFormatoRasVehiculo.toUpperCase()
                                        }
                                        className="btn btn-outline-dark btn-sm"
                                        style={{ marginLeft: "5px" }}
                                        title={titleFormatoRasVehiculo.toUpperCase()}
                                    >
                                        <span class="fa fa-download"></span> Formato CSV
                                    </a>
                                </div>
                            </div>



                            <DataTable
                                columns={columnsItemsDocumento}
                                data={data}
                                progressPending={loading}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRowsItem}
                                onChangeRowsPerPage={handlePerRowsChangeFile}
                                onChangePage={handlePageChangeFile}
                                highlightOnHover
                                noDataComponent={Texto.there_are_no_records_to_display}
                                progressComponent={<CustomLoader />}
                                expandableRowsComponent={() => false}
                                noHeader={true}
                            //expandableRows
                            />
                        </div>
                    </>
            }


            {showModalForm && <>

                <div
                    className="modal fade "
                    id={idFormImportFile}
                    name={idFormImportFile}
                    tabIndex="-1"
                    aria-labelledby="modalFormImporCreate"
                    aria-hidden="true"
                    data-backdrop="static"
                    data-keyboard="false"
                    style={{ overflowY: "auto" }}
                >
                    <div className="modal-dialog  modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6
                                    className="modal-title text-butterfly-bush"
                                    id="modalFormCreateTitle"
                                >
                                    {titlePage}
                                </h6>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="col-lg-12 col-xs-12 text-justify ">
                                    <h6 className="text-fountain-blue mb-2">
                                        Formulario de Registro (Archivo CSV)
                                    </h6>
                                </div>
                                <form
                                    noValidate
                                    name={idForm}
                                    id={idForm}
                                    method="post"
                                    onSubmit={handleFormSubmitModalFile}
                                    className="needs-validation"
                                >


                                    <div class="form-group">
                                        <label for="document_fecha">Fecha</label>
                                        <input type="text" class="form-control" id="document_fecha" name="document_fecha" value={window.moment().format("DD/MM/YYYY")} readOnly />
                                    </div>


                                    <div className="row mt-2">
                                        <div className="col-md-11 col-lg-11 col-xs-11">
                                            <label htmlFor="document_file" className="form-label">
                                                <span className="text-danger">(*)</span>
                                                Seleccione un archivo (.csv)
                                            </label>
                                            <small className="fs-small8em text-maroon-flush float-end">
                                                20MB, Limite Máximo
                                            </small>
                                            <div className="form-group files">
                                                <label className="form-control">
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        multiple=""
                                                        id={`document_file`}
                                                        name={`document_file`}
                                                        required={true}
                                                        rows="3"
                                                        data-parsley-max-file-size="20000"
                                                        onChange={(e) => onClickUploadFileInput(e)}
                                                        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet, text/csv, application/octet-stream"
                                                    />
                                                </label>
                                                <small className="fs-small8em text-maroon-flush nameFile float-start">
                                                    ...
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={
                                            loadingForm
                                                ? "row mt-3 text-center"
                                                : "row mt-3 d-none text-center"
                                        }
                                    >
                                        <div className="col-12">
                                            <img
                                                className="rounded mx-auto d-block"
                                                alt="pulse-loading"
                                                src={"/static/img/pulse_200.gif"}
                                                width={40}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div
                                            className="col-md-12  col-lg-12 col-xs-12 mt-4"
                                            style={{ textAlign: "right" }}
                                        >
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                disabled={disabled}
                                            >
                                                Procesar archivo
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </section>
    )

}


export default AdminRas;