import Texto from "../../data/es";
import Constant from "../../data/constant";
import { Link } from "react-router-dom";

export default class Columns {
  constructor(userAuth) {
    this.userAuth = userAuth;
  }
  columns(
    handleDisplayClick,
    handleCloseClick,
    handleOpenDependencyClick,
    handleDeleteClick,
    handleRestoreClick,
    handleOpenModalDocRelacinadosClick,
    handleEditarClick
  ) {
    return [
      {
        name: Texto.numero,
        sortable: true,
        grow: 1,
        maxWidth: "130px",
        cell: (row) => (
          <div>
            <span>{row.number}</span>
          </div>
        ),
      },
      {
        name: Texto.categoria,
        sortable: true,
        grow: 1,
        maxWidth: "100px",
        cell: (row) => (
          <div>
            <span>{row.type_name}</span>
          </div>
        ),
      },
      {
        name: Texto.title,
        selector: "title",
        sortable: true,
        maxWidth: "150px",
        hide: "md",
      },
      {
        name: Texto.descripcion,
        selector: "description",
        sortable: true,
        maxWidth: "120px",
        hide: "md",
      },
      {
        name: Texto.gestion,
        selector: "gestion_name",
        sortable: true,
        hide: "md",
        maxWidth: "120px",
      },
      {
        name: "Publicado",
        selector: "publication_at",
        sortable: true,
        hide: "md",
        maxWidth: "140px",
      },
      {
        name: Texto.estado,
        center: true,
        sortable: true,
        maxWidth: "80px",
        minWidth: "50px",
        cell: (row) => (
          <div>
            {row.estado_code === Constant[0].estado.activo ? (
              <span title={row.estado_code}>
                <i className="fa fa-check" aria-hidden="true"></i>
              </span>
            ) : (
              ""
            )}
            {row.estado_code === Constant[0].estado.bloqueado ? (
              <span title={row.estado_code}>
                <i className="fas fa-ban"></i>
              </span>
            ) : (
              ""
            )}
            {row.estado_code === Constant[0].estado.eliminado ? (
              <span title={row.estado_code}>
                <i className="fas fa-trash-alt"></i>
              </span>
            ) : (
              ""
            )}
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
      },
      {
        name: "Creado",
        selector: "created_at",
        sortable: true,
        hide: "md",
        maxWidth: "140px",
      },

      {
        name: "",
        sortable: true,
        cell: (row) => (
          <div className="text-sm-start text-md-end" style={{ width: "100px" }}>
            <span
              data-bs-toggle="dropdown"
              aria-expanded="false"
              className="pe-2 ps-2"
            >
              <i className="fas fa-ellipsis-v "></i>
            </span>
            <ul className="dropdown-menu">
              <li></li>
              <li>
                {row.permissions ? (
                  row.estado_code === Constant[0].estado.bloqueado &&
                  row.permissions.includes(Constant[0].permission.aprobar) ? (
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={() => handleDisplayClick(row)}
                      title={`Publicar la Gaceta Tributaria: ${row.number}`}
                    >
                      <i
                        className="fa fa-unlock-alt me-2"
                        aria-hidden="true"
                      ></i>{" "}
                      Publicar
                    </Link>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </li>
              <li>
                {row.permissions ? (
                  row.estado_code === Constant[0].estado.activo &&
                  row.permissions.includes(Constant[0].permission.bloqueado) ? (
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={() => handleCloseClick(row)}
                      title={`Bloquear Visibilidad: ${row.number}`}
                    >
                      <i className="fa fa-lock me-2" aria-hidden="true"></i>{" "}
                      Ocultar
                    </Link>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </li>
              <li>
                {row.estado_code === Constant[0].estado.aprobado ? (
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => handleOpenDependencyClick(row)}
                    title={`Ver Dependiente`}
                  >
                    <i className="far fa-file-pdf me-2" aria-hidden="true"></i>{" "}
                    Ver Dependientes
                  </Link>
                ) : (
                  ""
                )}
              </li>
              <li>
                {row.permissions ? (
                  row.permissions.includes(Constant[0].permission.update) &&
                  row.estado_code !== Constant[0].estado.activo &&
                  row.estado_code !== Constant[0].estado.aprobado &&
                  row.estado_code !== Constant[0].estado.eliminado ? ( //&&
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={() => handleDeleteClick(row)}
                      title={`Eliminar Gaceta Tributaria ${row.numero}`}
                    >
                      <i
                        className="far fa-trash-alt me-2"
                        aria-hidden="true"
                      ></i>{" "}
                      Eliminar
                    </Link>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </li>
              <li>
                {row.permissions ? (
                  row.permissions.includes(Constant[0].permission.update) &&
                  row.estado_code === Constant[0].estado.eliminado ? ( //&&
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={() => handleRestoreClick(row)}
                      title={`Restaurar Gaceta Tributaria ${row.numero}`}
                    >
                      <i
                        className="far fa-trash-alt me-2"
                        aria-hidden="true"
                      ></i>{" "}
                      Restaurar
                    </Link>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </li>

              <li>
                {row.permissions ? (
                  row.permissions.includes(Constant[0].permission.update) &&
                  ( row.estado_code === Constant[0].estado.activo || 
                  row.estado_code !== Constant[0].estado.aprobado ) ? ( //&&
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={() => handleEditarClick(row)}
                      title={`Restaurar Gaceta Tributaria ${row.numero}`}
                    >
                      <i
                        className="fas fa-pencil-alt me-2"
                        aria-hidden="true"
                      ></i>{" "}
                      Editar
                    </Link>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </li>
              <li>
                {row.permissions ? (
                  row.permissions.includes(Constant[0].permission.update) &&
                  row.estado_code !== Constant[0].estado.eliminado ? ( //&&
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={(e) =>
                        handleOpenModalDocRelacinadosClick(row, true)
                      }
                      title={`Agregar Relacionados para ${row.numero}`}
                    >
                      <i className="fas fa-link me-2" aria-hidden="true"></i>{" "}
                      Doc. Relacionados
                    </Link>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </li>
            </ul>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];
  }
}
