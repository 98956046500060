import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Texto from "../../data/es";
import PasosNavigationVisacion from "../utils/PasosNavigationVisacionMinutas";
import TableCopropietario from "./TableCopropietario";
import ViewDataJuridico from "../../components/component/Contribuyente/ViewDataJuridico";
import { Link } from "react-router-dom";
import ViewDataNatural from "../../components/component/Contribuyente/ViewDataNatural";

const FormComprador = (props) => {
  const { dataInmueble, onSubmitForm, nameForm, fetch, dataCompradorDb } =
    props;
  const idForm = "formDataBuy";

  const [copropietariosList, setCopropietariosList] = useState([]);
  const [showFormPropietario, setShowFormPropietario] = useState(false);
  const [showFormGlobal, setShowFormGlobal] = useState(true);
  const [message, setMessage] = useState(undefined);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showPanelSearchResult, setShowPanelSearchResult] = useState(false);
  const [showModalSearch, setShowModalSearch] = useState(false);
  const [contribuyentesSearchList, setContribuyentesSearchList] = useState([]);
  const [modalSearchPmc, setModalSearchPmc] = useState(undefined);

  const [dataJuridico, setDataJuridico] = useState(false);
  const [dataNatural, setDataNatural] = useState(false);
  const [isNatural, setIsNatural] = useState(false);
  const [isJuridico, setIsJuridico] = useState(false);

  const refButtonNextForm = useRef();
  const refInputSearchModal = useRef();
  const refInputSearchPmc = useRef();

  useEffect(() => {
    window.inputTextUpperCase();
    if (dataCompradorDb) {
      const arrayCopropietario = [];
      dataCompradorDb.forEach((copropietario) => {
        //NATURAL
        if (
          copropietario.contribuyente_natural &&
          parseInt(copropietario.contribuyente_natural, 10) === 1
        ) {
          const propietario = copropietario.propietario;
          const domicilio = copropietario.domicilio;

          const persona = {
            tipoDocumento: propietario.id_tipo_documento,
            numeroDocumento: propietario.numero_documento,
            nombre: propietario.nombre,
            apellidoPaterno: propietario.apellido_paterno,
            apellidoMaterno: propietario.apellido_materno,
            apellidoCasada: propietario.apellido_casada
              ? propietario.apellido_casada
              : "",
            estadoCivil: propietario.estado_civil,
            genero: propietario.genero,
            fechaNacimiento: propietario.fecha_nacimiento,
            expedidoEn: propietario.expedido_en,
            pmc: propietario.pmc,
            direccion: domicilio ? domicilio.direccion : "",
            celular: domicilio ? domicilio.celular : "",
            contribuyente: "NATURAL",
          };

          const poder = copropietario.poder;

          arrayCopropietario.push({
            propietario: persona,
            contribuyente: "NATURAL",
            poder: poder,
          });
        }

        if (
          copropietario.contribuyente_natural &&
          parseInt(copropietario.contribuyente_natural, 10) === 0
        ) {
          const juridico = copropietario.juridico;
          const apoderado = copropietario.apoderado;
          const poder = copropietario.poder;

          juridico.razonSocial = juridico.razon_social;
          juridico.numeroDocumento = juridico.numero_documento;
          juridico.tipoSociedad = juridico.id_tipo_sociedad;
          juridico.fechaConstitucion = juridico.fecha_constitucion;
          juridico.tipoContribuyenteJuridico =
            juridico.tipo_contribuyente_juridico;
          juridico.domicilioLegal = juridico.direccion;

          apoderado.tipoDocumento = apoderado.id_tipo_documento;
          apoderado.numeroDocumento = apoderado.numero_documento;
          apoderado.expedidoEn = apoderado.expedido_en;
          apoderado.apellidoPaterno = apoderado.apellido_paterno;
          apoderado.apellidoMaterno = apoderado.apellido_materno;
          apoderado.apellidoCasada = apoderado.apellido_casada;
          apoderado.estadoCivil = apoderado.estado_civil;

          apoderado.fechaNacimiento = apoderado.fecha_nacimiento;

          arrayCopropietario.push({
            juridico: juridico,
            apoderado: apoderado,
            poder: poder,
            dataInformationAditional: undefined,
            contribuyente: "JURIDICO",
          });
        }
      });
      setCopropietariosList(arrayCopropietario);
    }
    configureAnexionPropiedad();
    window.scrollTo(0, 0);
  }, []);

  const configureAnexionPropiedad = async () => {
    if (
      dataInmueble.tipoVisacionMinuta &&
      dataInmueble.tipoVisacionMinuta.code ===
        "FRACCIONAMIENTO_ANEXION_ADECUACION_PH"
    ) {
      if (!dataCompradorDb) {
        if (dataInmueble.propietario && dataInmueble.propietario.natural) {
          const propietarioNatural = dataInmueble.propietario.natural;
          debugger;
          let expedidoEn = "SE";
          const ciExpedidos = await fetch.fetchGet(`api/list/ci-expedido`);
          if (ciExpedidos && ciExpedidos.status === true) {
            if (ciExpedidos.ci_expedido) {
              ciExpedidos.ci_expedido.map((item, index) => {
                if (
                  item.name.toUpperCase() ===
                  propietarioNatural.expedido.toUpperCase()
                )
                  expedidoEn = item.code;
              });
            }
          }

          let estadoCivil = "";
          const estadoCivilResp = await fetch.fetchGet(`api/list/estado-civil`);
          if (
            estadoCivilResp &&
            estadoCivilResp.status &&
            estadoCivilResp.estadoCivil
          ) {
            estadoCivilResp.estadoCivil.map((item, index) => {
              if (
                item.name.toUpperCase() ===
                propietarioNatural.estadoCivil.toUpperCase()
              )
                estadoCivil = item.id;
            });
          }
          let tipoDocumento = "";
          const tipoDocRes = await fetch.fetchGet(
            `api/tipo-documentos/actividades_economicas`
          );
          if (tipoDocRes && tipoDocRes.status) {
            if (tipoDocRes.TipoDocumento) {
              tipoDocRes.TipoDocumento.map((item, index) => {
                if (
                  item.description.toUpperCase() ===
                  propietarioNatural.tipoDocumento.toUpperCase()
                )
                  tipoDocumento = item.id;
              });
            }
          }

          let genero = 0;
          if (propietarioNatural.genero.toUpperCase() === "MASCULINO") {
            genero = 2;
          }
          if (propietarioNatural.genero.toUpperCase() === "FEMENINO") {
            genero = 1;
          }
          const informacionAdicional =
            dataInmueble.propietario.informacionAdicional;
          propietarioNatural.tipoDocumento = tipoDocumento;
          propietarioNatural.apellidoCasada = propietarioNatural.apellidoEsposo;
          propietarioNatural.expedidoEn = expedidoEn;
          propietarioNatural.genero = genero;
          propietarioNatural.estadoCivil = estadoCivil;
          propietarioNatural.nombre = propietarioNatural.nombres;
          propietarioNatural.apellidoPaterno =
            propietarioNatural.primerApellido;
          propietarioNatural.apellidoMaterno =
            propietarioNatural.segundoApellido;
          propietarioNatural.pmc = dataInmueble.propietario.pmc;
          propietarioNatural.celular = informacionAdicional
            ? informacionAdicional.celular
            : "";
          propietarioNatural.direccion =
            dataInmueble.propietario.domicilioLegal;

          const dataNatural = {
            contribuyente: "NATURAL",
            propietario: propietarioNatural,
            poder: dataInmueble.propietario.poder,
          };
          setCopropietariosList([dataNatural]);
        }

        if (dataInmueble.propietario && dataInmueble.propietario.juridico) {
          //estamos trabajando en ello.
          debugger;
          const juridico = dataInmueble.propietario.juridico;
          const apoderado = dataInmueble.propietario.apoderado;
          const poder = dataInmueble.propietario.poder;

          debugger;

          juridico.pmc = dataInmueble.propietario.pmc;
          juridico.domicilioLegal = dataInmueble.propietario.domicilioLegal;

          let expedidoEn = "";
          const ciExpedidos = await fetch.fetchGet(
            `api/list/ci-expedido/by-code-ruat/${apoderado.expedido.toUpperCase()}`
          );
          if (ciExpedidos && ciExpedidos.status === true) {
            expedidoEn = ciExpedidos.ci_expedido.id;
          }

          let estadoCivil = "";
          const estadoCivilResp = await fetch.fetchGet(`api/list/estado-civil`);
          if (
            estadoCivilResp &&
            estadoCivilResp.status &&
            estadoCivilResp.estadoCivil
          ) {
            debugger;
            estadoCivilResp.estadoCivil.map((item, index) => {
              if (
                item.name.toUpperCase() === apoderado.estadoCivil.toUpperCase()
              )
                estadoCivil = item.id;
            });
          }

          let tipoDocumento = "";
          const tipoDocRes = await fetch.fetchGet(
            `api/tipo-documentos/actividades_economicas`
          );
          if (tipoDocRes && tipoDocRes.status) {
            if (tipoDocRes.TipoDocumento) {
              tipoDocRes.TipoDocumento.map((item, index) => {
                if (
                  item.code.toUpperCase() ===
                  apoderado.tipoDocumento.toUpperCase()
                )
                  tipoDocumento = item.id;
              });
            }
          }

          let genero = 0;
          if (apoderado.genero.toUpperCase() === "MASCULINO") {
            genero = 2;
          }
          if (apoderado.genero.toUpperCase() === "FEMENINO") {
            genero = 1;
          }

          apoderado.nombre = apoderado.nombres;
          apoderado.estadoCivil = estadoCivil;
          apoderado.expedidoEn = expedidoEn;
          apoderado.genero = genero;
          apoderado.apellidoPaterno = apoderado.primerApellido;
          apoderado.apellidoMaterno = apoderado.segundoApellido;
          apoderado.apellidoCasada = apoderado.apellidoEsposo;
          apoderado.tipoDocumento = tipoDocumento;

          const dataJuridico = {
            juridico: juridico,
            apoderado: apoderado,
            poder: poder,
            dataInformationAditional: undefined,
            contribuyente: "JURIDICO",
          };
          setCopropietariosList([dataJuridico]);
        }

        refButtonNextForm.current.click();
        setTimeout(function () {
          const buttonAlert =
            document.getElementsByClassName("bootbox-accept")[0];
          buttonAlert.click();
          setTimeout(function () {
            if (buttonAlert) {
              document.getElementsByClassName("bootbox-cancel")[0].click();
            }
          }, 300);
        }, 300);
      } else {
        setShowFormGlobal(false);
      }
    }
  };

  const handleOnFocusPmc = async (event) => {
    event.preventDefault();
    setIsNatural(false);
    setIsJuridico(false);
    setDataNatural(undefined);
    setDataJuridico(undefined);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleSearchByPmcOnClick = async (event) => {
    event.preventDefault();
    debugger;
    let input_search = event.target;
    if (input_search.value !== "" && input_search.value.length > 3) {
      const search_persona = fetch.fetchGet(
        `api-ruat/contribuyentes/pmc/${input_search.value}`
      );
      search_persona.then(async (dataJson) => {
        debugger;
        if (dataJson && dataJson.status) {
          let contribuyentes = dataJson.data.contribuyente;
          contribuyentes = contribuyentes.filter(
            (contribuyente) => contribuyente.contribuyenteActualizado === "1"
          );

          if (contribuyentes && contribuyentes.length > 3) {
            setMessage(
              `Se ha detectado multiples contribuyentes con el PMC ${input_search.value}`
            );
          } else {
            setMessage(undefined);
            contribuyentes = contribuyentes[0];
            if (contribuyentes.tipoContribuyente === "NATURAL") {
              let propietario = contribuyentes.natural
                ? contribuyentes.natural
                : undefined;
              const infomacionAdicional = contribuyentes.informacionAdicional;

              const persona = {
                tipoDocumento: propietario.tipoDocumento,
                numeroDocumento: propietario.numeroDocumento,
                nombre: propietario.nombres,
                apellidoPaterno: propietario.primerApellido,
                apellidoMaterno: propietario.segundoApellido,
                apellidoCasada: propietario.apellidoEsposo
                  ? propietario.apellidoEsposo
                  : "",
                estadoCivil: propietario.estadoCivil,
                genero: propietario.genero,
                fechaNacimiento: propietario.fechaNacimiento,
                expedidoEn: propietario.expedido,
                tipoDocumentoCode: propietario.tipoDocumentoCode,
                pmc: contribuyentes.pmc,
                direccion: contribuyentes.domicilioLegal,
                celular: infomacionAdicional ? infomacionAdicional.celular : "",
                contribuyente: "NATURAL",
                //id: propietario.id,
              };

              const apoderado = contribuyentes.apoderado;
              const poder = contribuyentes.poder;

              setDataNatural({
                dataNatural: persona,
                dataApoderado: apoderado,
                dataPoder: poder,
              });
              setIsNatural(true);
              setIsJuridico(false);
              setDataJuridico(undefined);
            }

            if (contribuyentes.tipoContribuyente === "JURIDICO") {
              let representante = contribuyentes.apoderado
                ? contribuyentes.apoderado
                : undefined;

              let representateLegal = undefined;
              if (representante) {
                  representateLegal = {
                    tipoDocumento: representante.tipoDocumento,
                    numeroDocumento: representante.numeroDocumento,
                    nombre: representante.nombres,
                    apellidoPaterno: representante.primerApellido,
                    apellidoMaterno: representante.segundoApellido,
                    apellidoCasada: representante.apellidoEsposo
                      ? representante.apellidoEsposo
                      : "",
                    estadoCivil: representante.estadoCivil,
                    genero: representante.genero,
                    fechaNacimiento: representante.fechaNacimiento,
                    expedidoEn: representante.expedido,
                    tipoDocumentoCode: representante.tipoDocumento,
                    pmc: 0,
                    contribuyente: "JURIDICO",
                    razonSocial: contribuyentes.juridico
                      ? contribuyentes.juridico.razonSocial
                      : undefined,
                  };
              }

              let propietarioJuridico = undefined;
              if (contribuyentes.juridico) {
                propietarioJuridico = contribuyentes.juridico;
                propietarioJuridico.nombreContribuyente =
                  contribuyentes.nombreContribuyente;
                propietarioJuridico.pmc = contribuyentes.pmc;
                propietarioJuridico.domicilioLegal =
                  contribuyentes.domicilioLegal;
                propietarioJuridico.tipoContribuyente =
                  contribuyentes.tipoContribuyente;
                propietarioJuridico.pmc = contribuyentes.pmc;
              }
              const infomacionAdicional = contribuyentes.informacionAdicional;
              const poder = contribuyentes.poder;

              setDataJuridico({
                dataJuridico: propietarioJuridico,
                dataApoderado: representateLegal,
                dataPoder: poder,
                dataInformationAditional: infomacionAdicional,
              });
              setIsNatural(false);
              setIsJuridico(true);
              setDataNatural(undefined);
            }

            input_search.value = "";

            fetch.toast.success(dataJson.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        } else {
          setMessage(
            "No existe registro de Contribuyente asociado al PMC, a mayor información click en "
          );
        }
      });
    }
  };

  const handleClickAddCopropietario = async (event) => {
    debugger;
    if (dataNatural) {
      const propietario = dataNatural.dataNatural;
      const poder = dataNatural.dataPoder;
      //const apoderado = dataNatural.dataApoderado;

      const tipoDocumentoResp = await fetch.fetchGet(
        `api/tipo-documentos/by-code/${propietario.tipoDocumentoCode}`
      );
      const textEstadoCivil = propietario.estadoCivil
        .replace("(", "")
        .replace(")", "");
      const estadoCivilResp = await fetch.fetchGet(
        `api/estado-civil/by-name/${textEstadoCivil}`
      );
      const generoResp = await fetch.fetchGet(
        `api/genero/by-name/${propietario.genero}`
      );

      if (
        (!generoResp || !generoResp.genero) &&
        (!tipoDocumentoResp || !tipoDocumentoResp.TipoDocumento) &&
        (!estadoCivilResp || !estadoCivilResp.estadoCivil)
      ) {
        setMessage(
          "El contribuyente debe tener el PMC actualizado, para poder continuar."
        );
      } else {
        propietario.tipoDocumento = tipoDocumentoResp
          ? tipoDocumentoResp.TipoDocumento.id
          : 0;
        propietario.estadoCivil = estadoCivilResp
          ? estadoCivilResp.estadoCivil.id
          : 0;
        propietario.genero = generoResp ? generoResp.genero.id : 0;

        //aqui aplica con propietario
        let editCopropietario = copropietariosList.filter((copropietarioE) =>
          copropietarioE.propietario
            ? copropietarioE.propietario.numeroDocumento ===
              propietario.numeroDocumento
            : undefined
        );

        if (editCopropietario.length > 0) {
          const currentList = copropietariosList.map((copropietario, index) => {
            debugger;
            if (copropietario.apoderado && editCopropietario[0].apoderado) {
              if (
                copropietario.apoderado.numeroDocumento.trim() ===
                editCopropietario[0].apoderado.numeroDocumento.trim()
              ) {
                return copropietario;
              }
            }

            if (copropietario.propietario && editCopropietario[0].propietario) {
              if (
                copropietario.propietario.numeroDocumento.trim() ===
                editCopropietario[0].propietario.numeroDocumento.trim()
              ) {
                return copropietario;
              }
            }

            return copropietario;
          });
          setCopropietariosList([...currentList]);
        } else {
          setCopropietariosList([
            ...copropietariosList,
            {
              propietario: propietario,
              contribuyente: "NATURAL",
              poder: poder,
            },
          ]);
        }
      }
    }

    if (dataJuridico) {
      debugger;
      const juridico = dataJuridico.dataJuridico;
      const apoderado = dataJuridico.dataApoderado
        ? dataJuridico.dataApoderado
        : {};
      const poder = dataJuridico.dataPoder;
      const dataInformationAditional = dataJuridico.dataInformationAditional;

      debugger
      if (apoderado && apoderado.numeroDocumento) {
        const tipoDocumentoResp = await fetch.fetchGet(
          `api/tipo-documentos/by-code/${apoderado.tipoDocumentoCode}`
        );

        const textEstadoCivil = apoderado.estadoCivil
          .replace("(", "")
          .replace(")", "");
        const estadoCivilResp = await fetch.fetchGet(
          `api/estado-civil/by-name/${textEstadoCivil}`
        );
        const generoResp = await fetch.fetchGet(
          `api/genero/by-name/${apoderado.genero}`
        );

        if (
          (!generoResp || !generoResp.genero) &&
          (!tipoDocumentoResp || !tipoDocumentoResp.TipoDocumento) &&
          (!estadoCivilResp || !estadoCivilResp.estadoCivil)
        ) {
          setMessage(
            "El contribuyente debe tener el PMC actualizado, para poder continuar."
          );
        } else {
          apoderado.tipoDocumento = tipoDocumentoResp
            ? tipoDocumentoResp.TipoDocumento.id
            : 0;
          apoderado.estadoCivil = estadoCivilResp
            ? estadoCivilResp.estadoCivil.id
            : 0;
          apoderado.genero = generoResp ? generoResp.genero.id : 0;

          //aqui ha de aplicar apoderado
          let editCopropietario = copropietariosList.filter((copropietarioE) =>
            copropietarioE.apoderado
              ? copropietarioE.apoderado.numeroDocumento ===
                apoderado.numeroDocumento
              : undefined
          );

          if (editCopropietario.length > 0) {
            const currentList = copropietariosList.map(
              (copropietario, index) => {
                if (copropietario.apoderado && editCopropietario[0].apoderado) {
                  if (
                    copropietario.apoderado.numeroDocumento.trim() ===
                    editCopropietario[0].apoderado.numeroDocumento.trim()
                  ) {
                    return copropietario;
                  }
                }

                if (
                  copropietario.propietario &&
                  editCopropietario[0].propietario
                ) {
                  if (
                    copropietario.propietario.numeroDocumento.trim() ===
                    editCopropietario[0].propietario.numeroDocumento.trim()
                  ) {
                    return copropietario;
                  }
                }
                return copropietario;
              }
            );
            setCopropietariosList([...currentList]);
          } else {
            setCopropietariosList([
              ...copropietariosList,
              {
                juridico: juridico,
                apoderado: apoderado,
                poder: poder,
                dataInformationAditional: dataInformationAditional,
                contribuyente: "JURIDICO",
              },
            ]);
          }
        }
      } else {
        setCopropietariosList([
          ...copropietariosList,
          {
            juridico: juridico,
            apoderado: apoderado,
            poder: poder,
            dataInformationAditional: dataInformationAditional,
            contribuyente: "JURIDICO",
          },
        ]);
      }
    }

    setShowFormPropietario(false);
    setIsNatural(false);
    setIsJuridico(false);
    window.scrollTo(0, 50);
  };

  const handleDeleteCopropietario = (e, numeroDocumento, pmc) => {
    window.deleteBootbox(pmc, function (result) {
      if (result) {
        const newListCopropietario = copropietariosList.filter(
          (copropietario) =>
            copropietario.contribuyente === "NATURAL"
              ? copropietario.propietario.pmc !== pmc
              : copropietario.apoderado.pmc !== pmc
        );
        setCopropietariosList(newListCopropietario);
      }
    });
  };

  const submitFormToBuy = (event) => {
    debugger;
    if (copropietariosList.length > 0) {
      const data = {
        dataCompradores: copropietariosList,
      };
      onSubmitForm(event, data);
    } else {
      event.preventDefault();
      toast.warn("Debe ingresar comprador(es)", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const openModalSearchContribuyente = (event) => {
    event.preventDefault();
    debugger;
    setShowModalSearch(true);
    setTimeout(() => {
      setContribuyentesSearchList([]);
      setShowPanelSearchResult(false);
      const modalSearchDom = document.getElementById(
        "modalSearchContribuyentes"
      );
      const modalSearch = new window.bootstrap.Modal(modalSearchDom, {
        keyboard: true,
        backdrop: "static",
        focus: true,
      });
      modalSearch.show();
      setModalSearchPmc(modalSearch);
    }, 500);
  };

  const handleSubmitSearchForm = (event) => {
    event.preventDefault();
    debugger;
    window.inputConvertUpperCaseForm(idForm);
    const form = event.target;
    const optionSelected =
      form.getElementsByTagName("select")[0].selectedOptions[0].value;
    const inputSearch = form.getElementsByTagName("input")[0].value;

    if (inputSearch.length > 0) {
      setLoadingSearch(true);
      setShowPanelSearchResult(false);

      fetch
        .fetchGet(
          `api-ruat/contribuyentes/search-nit-ci/${inputSearch}/${optionSelected.toUpperCase()}`
        )
        .then((res) => {
          debugger;
          if (res && res.status) {
            setShowPanelSearchResult(true);
            const contribuyentes = res.data.contribuyente;
            setContribuyentesSearchList(contribuyentes);
          } else {
            setContribuyentesSearchList([]);
          }
          setLoadingSearch(false);
          window.inputConvertUpperCaseForm("formSearchDataTable");
        });
    }
  };

  const handleEventSelectedPmc = (event, contribuyente) => {
    const inputSearch = refInputSearchPmc.current;
    inputSearch.focus();
    setTimeout(() => {
      inputSearch.value = contribuyente.pmc;
      const eventBlur = new Event("blur");
      inputSearch.dispatchEvent(eventBlur);
    }, 500);
    refInputSearchModal.current.value = "";
    modalSearchPmc.hide();
  };

  return (
    <>
      <PasosNavigationVisacion
        nro_inmueble={dataInmueble.numeroInmueble}
        paso1_active={true}
        paso2_active={true}
        paso3_active={true}
        paso4_active={false}
        paso5_active={false}
      />

      {showFormGlobal ? (
        <form
          action=""
          className="contact__form needs-validation"
          name={idForm}
          id={idForm}
          method="post"
          noValidate
          onSubmit={submitFormToBuy}
          style={{ width: "100%" }}
        >
          <div className="card widget widget-simple">
            <div className="card-body ">
              <div className="row ">
                <div className="col-12 col-md-8 widget-header mb-3">
                  <h4 className="text-maroon-flush">
                    <i className="fas fa-bookmark me-2"></i> Buscar
                    Comprador/Beneficiario
                  </h4>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 mb-3">
                  <label
                    htmlFor={nameForm + "[pmc_search]"}
                    className="form-label"
                  >
                    Padrón Municipal Contribuyente (PMC) *
                  </label>
                  <div className="input-group ">
                    <input
                      type="text"
                      className="form-control input-uppercase"
                      name={nameForm + "[pmc_search]"}
                      placeholder="PMC"
                      id={nameForm + "[pmc_search]"}
                      pattern="[a-zA-Z0-9]+"
                      data-parsley-pattern="[a-zA-Z0-9]+"
                      onBlur={handleSearchByPmcOnClick}
                      onFocus={handleOnFocusPmc}
                      onKeyDown={handleKeyDown}
                      ref={refInputSearchPmc}
                    />
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Buscar"
                    >
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-lg-4 mb-3 offset-md-4 offset-lg-4 text-end">
                  <Link
                    to="#"
                    title="Consulta Contribuyentes"
                    rel="noopener noreferrer"
                    className="text-decoration-underline"
                    onClick={openModalSearchContribuyente}
                  >
                    No Recuerdo Mi PMC, Click Aquí
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {message ? (
              <div className="col-12">
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <strong>Atención!</strong> {message}
                  <Link
                    to="#"
                    title="Consulta Contribuyentes"
                    style={{ paddingLeft: "5px" }}
                    rel="noopener noreferrer"
                    onClick={openModalSearchContribuyente}
                  >
                    Consulta Contribuyentes
                  </Link>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          {isNatural && !isJuridico ? (
            <ViewDataNatural
              dataNatural={dataNatural.dataNatural}
              dataApoderado={dataNatural.dataApoderado}
              dataPoder={dataNatural.dataPoder}
              handleClickAddCopropietario={handleClickAddCopropietario}
              openModalSearchContribuyente={openModalSearchContribuyente}
              showButtonAdd={true}
              showTitle={true}
            />
          ) : (
            ""
          )}

          {!isNatural && isJuridico ? (
            <ViewDataJuridico
              dataJuridico={dataJuridico.dataJuridico}
              dataApoderado={dataJuridico.dataApoderado}
              dataPoder={dataJuridico.dataPoder}
              dataInformationAditional={dataJuridico.dataInformationAditional}
              handleClickAddCopropietario={handleClickAddCopropietario}
              showButtonAdd={true}
              isVisacion={true}
            />
          ) : (
            ""
          )}

          <div className="card widget widget-simple">
            <div className="card-body ">
              <div className="row ">
                <div className="col-12 col-md-8 widget-header mb-3">
                  <h4 className="text-maroon-flush">
                    <i className="fas fa-bookmark me-2"></i> {Texto.compradores}
                  </h4>
                </div>
              </div>

              <div className="row">
                <div className="col-12 mb-3">
                  {copropietariosList.length > 0 ? (
                    !showFormPropietario ? (
                      <TableCopropietario
                        dataCopropietario={copropietariosList}
                        deleteItem={handleDeleteCopropietario}
                        showButtons={true}
                      />
                    ) : (
                      <>
                        <TableCopropietario
                          dataCopropietario={copropietariosList}
                          deleteItem={handleDeleteCopropietario}
                          showButtons={false}
                        />
                      </>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className={`hol col-12 text-end col-md-9 col-lg-9`}></div>
            {!showFormPropietario &&
            !isNatural &&
            !isJuridico &&
            copropietariosList.length > 0 ? (
              <div className="col-12 col-md-3 col-lg-3 text-end">
                <div className=" search-btn button mb-3">
                  <input
                    type="submit"
                    className="btn btn-full-screen"
                    ref={refButtonNextForm}
                    name="Siguiente"
                    value="Siguiente"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </form>
      ) : (
        <div className="row">
          <div className="col-12 text-start">
            <div className="alert alert-danger text-uppercase" role="alert">
              Opción no disponible, para este tipo de Visación
            </div>
          </div>
        </div>
      )}

      {showModalSearch ? (
        <div
          className="modal fade "
          id={"modalSearchContribuyentes"}
          tabIndex="-1"
          aria-labelledby="modalSearchContribuyentesTitle"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          style={{ overflowY: "auto" }}
        >
          <div className="modal-dialog  modal-dialog-centered modal-lg">
            <div className="modal-content ">
              <div className="modal-header text-butterfly-bush justify-content-center">
                <h4
                  className="modal-title "
                  id="modalSearchContribuyentesTitle"
                  style={{ fontWeight: 600 }}
                >
                  Buscador de Contribuyentes
                </h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form
                  action=""
                  className="contact__form needs-validation center-login"
                  name="formSearchDataTable"
                  id="formSearchDataTable"
                  method="post"
                  noValidate
                  onSubmit={handleSubmitSearchForm}
                  style={{ width: "100%" }}
                >
                  <div className="row">
                    <div className="col-12 col-md-2 col-lg-2 "></div>
                    <div className="col-12 col-md-10 col-lg-10 ">
                      <div className="input-group mb-3">
                        <select
                          className="form-select "
                          name="type"
                          required
                          data-parsley-required="true"
                        >
                          <option key={0} value="CI">
                            Cédula de Identidad
                          </option>
                          <option key={1} value="NIT">
                            Número de Identificación Tributaria
                          </option>
                        </select>

                        <input
                          type="text"
                          name="search"
                          id="search"
                          className="form-control input-uppercase"
                          placeholder="*"
                          pattern="[0-9-A-Z]+"
                          data-parsley-pattern="[0-9-A-Z]+"
                          ref={refInputSearchModal}
                        />

                        <button
                          className="btn btn-outline-secondary"
                          type="submit"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Buscar"
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                {loadingSearch ? (
                  <div className="row">
                    <div className="col-12 mb-1">
                      <img
                        className="rounded mx-auto d-block"
                        alt="pulse-loading"
                        src={"/static/img/pulse_200.gif"}
                        width="70"
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {showPanelSearchResult ? (
                  <div className="card widget widget-simple">
                    <div className="card-body ">
                      <div className="row ">
                        <div className="col-12 col-md-12 widget-header mb-3">
                          <h4 className="text-maroon-flush">
                            <i className="fas fa-bookmark me-2"></i>{" "}
                            {Texto.seleccione_padron_actualizado}
                          </h4>
                        </div>
                        <div className="col-4 col-md-6 widget-header mb-3"></div>
                      </div>

                      <div className="widget-content">
                        <div className="widget-body">
                          <div className="row ">
                            {contribuyentesSearchList.map((contribuyente) => {
                              return (
                                <div
                                  key={contribuyente.pmc}
                                  className="col-sm-12 col-md-4 col-lg-4 text-butterfly-bush pointer"
                                  onClick={(e) =>
                                    handleEventSelectedPmc(e, contribuyente)
                                  }
                                >
                                  <p
                                    className="text-left h3 bg-gallery"
                                    style={{
                                      border: "1px solid rgb(174,24,87, .3)",
                                      borderRadius: 20,
                                      padding: 10,
                                      textAlign: "center",
                                    }}
                                  >
                                    <span className="fw-bolder">
                                      {contribuyente.pmc}
                                    </span>
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {!showPanelSearchResult
                  ? contribuyentesSearchList.length === 0 && refInputSearchModal
                    ? "No se ha encontrado ningún resultado"
                    : ""
                  : ""}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  );
};

export default FormComprador;
