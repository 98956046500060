import React from "react";
import Config from "../../data/config";
import Links from "../../data/link";

const PerfilOptionVarios = (props) => {

  const handleRedirectUrlClick = (ev, url) => {
    ev.preventDefault();
    if (url.includes("certificacion-admin"))
      window.location.href = url + "?cert=detallado";
    else window.location.href = url;
  };

  return (
    <section>
      <div className="row">
        <div className="col-12 mb-2">
          <h4>Gaceta Tributaria</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-3 col-lg-2">
          <div
            className="single-news"
            onClick={(e) => handleRedirectUrlClick(e, Links[120].url)}
          >
            <div className="image bg-aero">
              <img
                className="thumb"
                alt="img-gaceta-tributaria"
                src={Config[2].url + "/static/img/book.png"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PerfilOptionVarios;
