const ruleValidation = (textInputRuat, textInputCatastro, typeValidacion) => {
  let message = "";
  switch (typeValidacion) {
    case "SUPERFICIE_TERRENO":
      if (parseFloat(textInputRuat) !== parseFloat(textInputCatastro)) {
        message =
          "Los datos de Terreno, RUAT y CATASTRO no son iguales, regularice su Inmueble.";
      }
      break;
    case "FECHA_APROBACION_PLANO":
      const date = window.moment(textInputCatastro);
      if (textInputCatastro && !date.isValid()) {
        message =
          "Los datos de Terreno, FECHA APROBACIÓN PLANO no esta registrado,  regularice su Inmueble.";
      }
      break;
    case "GLOBAL_CATASTRO":
      message = "Debe proceder a regularizar su Inmueble, en oficinas de Catastro CBBA.";
      break;
    case "MAPA_CATASTRO":
      message = "No fue posible, ubicar al predio, consulte en oficinas de Catastro CBBA.";
      break;
    default:
      message = "Debe proceder a regularizar su Inmueble.";
      break;
  }
  return message;
};
module.exports = {
  ruleValidation,
};
