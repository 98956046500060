import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import axios from 'axios';
import DataTable from 'react-data-table-component';
//import styled from 'styled-components';  //styled for data table
import { Link } from 'react-router-dom';
import Texto from '../../data/es';

import Links from '../../data/link';
//import Config from '../../data/config';
import Constant from '../../data/constant';
import Fetch from '../../components/utils/Fetch';
import TitlePage from '../../components/utils/TitlePage';
import AuthService from '../../components/Usuario/AuthService';
import ModalPdf from '../utils/ModalPdf';
import CustomLoader from '../utils/SpinnerDataTable'

const columns = [
    {
        name: Texto.categoria,
        selector: 'tipo_actividad_economica',   //npm uninstall <moduleName> --save 
        sortable: true,
        grow: 1,
        hide: 'sm',
        maxWidth: '300px',
    },
    {
        name: Texto.actividad_economica,
        selector: 'actividad_economica',
        sortable: true,
        grow: 1,
        hide: 'md',
        //maxWidth: '200px',
    },
    {
        name: Texto.numero_orden,
        selector: 'numero',
        sortable: true,
        maxWidth: '130px'
    },
    {
        name: Texto.numero_fur,
        selector: 'fur',
        sortable: true,
        hide: 'md',
        maxWidth: '130px'
    },
    {
        name: Texto.estado,
        center: true,
        sortable: true,
        maxWidth: '100px',
        cell: row => <div>
            {row.code_estado === Constant[0].estado.en_proceso ? <span title={row.code_estado}><i className="fa fa-cog" aria-hidden="true" ></i></span> : ""}
            {row.code_estado === Constant[0].estado.completado ? <span title={row.code_estado}><i className="fa fa-file-text-o" aria-hidden="true"></i></span> : ""}
            {row.code_estado === Constant[0].estado.aprobado ? <span title={row.code_estado}><i className="fa fa-check" aria-hidden="true"></i></span> : ""}
            {row.code_estado === Constant[0].estado.eliminado ? <span title={row.code_estado}><i className="fa fa-ban" aria-hidden="true"></i></span> : ""}
        </div>,
        ignoreRowClick: true,
        allowOverflow: true,
    },
    {
        name: 'Creado',
        selector: 'created_at',
        sortable: true,
        hide: 'md',
        maxWidth: '150px',
    },
    {
        name: '',
        sortable: true,
        cell: row => <div>

            {row.code_estado !== Constant[0].estado.ruat ?
                <Link to="#" onClick={() => handleInsertDataRuatClick(row)} style={{ fontSize: '22px', marginRight: '10px' }}
                    title={`Sincronizar la Licencia con número ${row.numero}`} >
                    <i className="fa fa-upload" aria-hidden="true"></i>
                </Link>
                : ""}
        </div>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    }
];

const handleInsertDataRuatClick = (row) => {

    window.createBootbox(Texto.numero_orden_sincronizar_datos.replace("%s", row.numero), function (result) {
        if (result === true) {
            const send_data_ruat = static_fetch.fetchGet(`api-ruat/licencia-actividad-economica-ruat/sent-ddjj/${row.token}`);
            send_data_ruat.then(res => {
                if (res !== undefined && res.status === true) {

                    static_fetch.toast.success(res.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            })
        }
    })
};

var static_fetch = null;
var modal_pdf = null
//var _auth = null
//var _declaracion_jurada = null
class Ruat extends Component {

    constructor(props) {
        super(props);

        this.constant = Constant[0];
        this.fetch = new Fetch();
        this.fetch.setToast(toast);
        this.Auth = new AuthService();
        //_auth = this.Auth

        modal_pdf = new ModalPdf()
        modal_pdf.setToast(toast)
        this.titlePage = Texto.licencia_actividad_economica_ruat

        static_fetch = this.fetch

        this.handleSubmitSearchForm = this.handleSubmitSearchForm.bind(this)

        this.state = {
            data: [],
            loading: false,
            totalRows: 0,
            perPage: 10,
            type_search: 0,
            showSearch: true
        };

        this.profile = this.Auth.getProfile();
    }

    async componentDidMount() {

        if (!this.Auth.loggedIn()) {
            this.props.history.replace(Links[4].url)
        } else {
            if (this.profile.code === Constant[0].grupos.contribuyente)
                this.props.history.replace(Links[4].url)
        }
        window.jQuery(".sc-kAzzGY").remove()  //pertenece al datatable
    }

    handleRedirectUrlClick(ev, url) {
        window.location.href = url;
    }

    handleSubmitSearchForm(event) {
        event.preventDefault()
        const form = new FormData(event.target);
        var self = this
        const { perPage } = this.state;
        self.setState({ loading: true });

        let input = window.jQuery(event.target).find('input').val()
        if (input.length > 0) {
            this.fetch.fetchPost(form, `api/licencia-actividad-economica-ruat/search`).then(res => {
                if (res !== undefined && res.status === true) {
                    self.setState({
                        loading: false,
                        data: res.data,
                        perPage,
                    });
                } else {
                    self.setState({
                        loading: false,
                        data: [],
                        perPage,
                    });
                }
            })
        } else
            window.location.reload();
    }

    render() {
        const { loading, data, totalRows } = this.state

        const breadcrumbs = [
            {
                title: Links[0].title,
                url: Links[0].url
            },
            {
                title: Links[1].title,
                url: Links[1].url
            }
        ];
        return (
            <section className="items-grid section mt-5 mb-5">
                <div className="container mt-4">
                    <TitlePage titlePage={this.titlePage} breadcrumbs={breadcrumbs} position={'left'} leftfull={false} />

                    <section className="panel-menu-info">
                        <div className="panel-menu-info-content">
                            <div className="row">
                                <div className="col-4 col-md-2 col-lg-2">
                                    <div className="single-contact-info pointer" onClick={e => this.handleRedirectUrlClick(e, Links[1].url)}>
                                        <i className="far fa-address-card"  aria-hidden="true"></i>
                                        <p>Licencias Funcionamiento</p>
                                    </div>
                                </div>
                                <div className="col-4 col-md-2 col-lg-2" >
                                    <div className="single-contact-info " >

                                    </div>
                                </div>
                                <div className="col-4 col-md-2 col-lg-2">
                                    <div className="single-contact-info " >
                                    </div>
                                </div>
                                <div className="col-4 col-md-2 col-lg-2">
                                    <div className="single-contact-info " >
                                    </div>
                                </div>
                                <div className="col-4 col-md-2 col-lg-2">
                                    <div className="single-contact-info" >
                                    </div>
                                </div>
                                <div className="col-4 col-md-2 col-lg-2">
                                    <div className="single-contact-info pointer" onClick={e => this.handleRedirectUrlClick(e, Links[8].url)}>
                                        <i className="far fa-user" aria-hidden="true"></i>
                                        <p>Mi Cuenta</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    {
                        this.state.showSearch ?
                            <form action="" className="contact__form center-login" name="formSearchDataTable" id="formSearchDataTable"
                                method="post" noValidate onSubmit={this.handleSubmitSearchForm} >
                                <div className="row" style={{ textAlign: 'right', marginRight: '0px', marginLeft: '0px' }}>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 ">
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-6 ">
                                        <div className="input-group mb-3" style={{ marginBottom: '0rem !important' }}>
                                            <div className="input-group-prepend">
                                                <select className="form-select" name="type" required data-parsley-required="true" >
                                                    <option key={0} value="NUMERO">Número de Orden</option>
                                                    <option key={1} value="FUR">Número de FUR</option>
                                                </select>
                                            </div>
                                            <input type="text" name="search" id="search" className="form-control" placeholder="*" aria-label="Username" aria-describedby="basic-addon1" />
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="submit" data-toggle="tooltip" data-placement="top"
                                                    title="Buscar"><i className="fa fa-search" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            : ""
                    }


                    <DataTable
                        title={this.titlePage}
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        highlightOnHover
                        noDataComponent={Texto.there_are_no_records_to_display}
                        progressComponent={<CustomLoader />}
                    />
                </div>
            
                <ModalPdf />

                <ToastContainer enableMultiContainer containerId={'Z'}
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <ToastContainer />
            </section>
        );
    }
}

export default Ruat;