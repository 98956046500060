import React, { useState, useEffect, useRef } from "react";
import Config from "../../data/config";

const FormModalAutoFur = (props) => {
  const { auth, rubro, toast, fetch, title, eventOpenCloseModal, csrfToken } =
    props;

  const idModal = "modalAutoFur";
  const [optionsTiposTramites, setOptionsTiposTramites] = useState([]);
  const [tiposTramites, setTiposTramites] = useState([]);
  const [furAutogenerado, setFurAutogenerado] = useState("");
  const [entidadSolicitante, setEntidadSolicitante] = useState("");
  const [caso, setCaso] = useState("");
  const [detalleSolicitud, setDetalleSolicitud] = useState("");
  const tipoTramiteRef = useRef();

  useEffect(() => {
    openModal();
    return () => {};
  }, []);

  const openModal = async () => {
    const modal = new window.bootstrap.Modal(document.getElementById(idModal), {
      keyboard: true,
      backdrop: "static",
      focus: true,
    });
    modal.show();
    getTipoTramites();
  };

  const closeModal = (event) => {
    eventOpenCloseModal(undefined);
  };

  const getTipoTramites = async () => {
    debugger;
    const tiposTramite = await fetch.fetchGet(
      "api/tipo-tramite/tramites-rub/" + rubro
    );
    if (tiposTramite && tiposTramite.status) {
      const listItems = tiposTramite.TipoTramites.map((item) => {
        return (
          <option key={item.id} name={item.name} id={item.id} code={item.code}>
            {item.name}
          </option>
        );
      });
      setTiposTramites(tiposTramite.TipoTramites);
      setOptionsTiposTramites(listItems);
    }
  };

  /*
  const changeEventSelected = (event) => {
    event.preventDefault();
    debugger;
    const optionSelected = event.target;
    const tipoTramite =
      optionSelected.options[optionSelected.selectedIndex].getAttribute("id");
    const idSelected = tiposTramites.filter(
      (tipoCer) => tipoCer.id === tipoTramite
    );
    if (idSelected && idSelected.length > 0) {
      setTipoTramiteSelected(idSelected[0]);
    } else {
      setTipoTramiteSelected({});
    }
  };*/

  const handleEntidadOnChange = (event) => {
    setEntidadSolicitante(event.currentTarget.value);
  };

  const handleCasoOnChange = (event) => {
    setCaso(event.currentTarget.value);
  };

  const handleDetalleOnChange = (event) => {
    setDetalleSolicitud(event.currentTarget.value);
  };

  const handleSubmitForm = async (event) => {
    debugger;
    event.preventDefault();

    window
      .jQuery("#" + event.target.getAttribute("id"))
      .parsley()
      .validate();

    if (
      window
        .jQuery("#" + event.target.getAttribute("id"))
        .parsley()
        .isValid()
    ) {
      const buttonGenerate = document.getElementById("btnGenerateAutoFur");
      const buttonCancel = document.getElementById("btnGenerateAutoFurCancel");
      const selectInput = tipoTramiteRef.current;
      buttonGenerate.classList.add("btn-disabled");
      buttonCancel.classList.add("btn-disabled");
      buttonGenerate.setAttribute("disabled", "true");
      buttonCancel.setAttribute("disabled", "true");

      const form = new FormData();
      form.append(
        "tipo_tramite[id_tipo_tramite]",
        selectInput.selectedOptions[0].id
      );

      form.append(
        "tipo_tramite[entidad_solicitante]",
        entidadSolicitante.toUpperCase()
      );

      form.append("tipo_tramite[caso]", caso.toUpperCase());

      form.append(
        "tipo_tramite[detalle_solicitud]",
        detalleSolicitud.toUpperCase()
      );

      form.append("temp_contribuyente[csrf_token]", csrfToken);
      const response = await fetch.fetchPost(
        form,
        "api/cobros/fur-gamc/generate",
        undefined
      );
      if (response && response.status) {
        buttonGenerate.classList.remove("btn-disabled");
        buttonCancel.classList.remove("btn-disabled");

        buttonGenerate.removeAttribute("disabled");
        buttonCancel.removeAttribute("disabled");
        setFurAutogenerado(response.data.furGamc.fur);

        window.location.href =
          Config[0].url +
          `report/fur-gamc/auto-fur/${response.data.furGamc.fur}/${response.data.codigoTramite}/${response.data.furGamc.token}/download/?auth=` +
          auth.getToken();

        toast.success(response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        buttonGenerate.classList.remove("btn-disabled");
        buttonCancel.classList.remove("btn-disabled");
        buttonGenerate.removeAttribute("disabled");
        buttonCancel.removeAttribute("disabled");
      }
    } else {
      toast.warn("El formulario tiene valores obligatorios", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div
      className="modal fade"
      id={idModal}
      tabIndex="-1"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="btn-close h6"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            ></button>
          </div>
          <div className="modal-body">
            <form
              action=""
              className="contact__form needs-validation"
              name="formGenerateFurFree"
              id="formGenerateFurFree"
              method="post"
              noValidate
              onSubmit={handleSubmitForm}
              style={{ width: "100%" }}
            >
              <div className="row mt-3">
                <div className="col-12 mb-3">
                  <label
                    htmlFor={"cuentas-visacion-minutas"}
                    className="form-label"
                  >
                    Tipos de Tramites *
                  </label>
                  <select
                    className="form-select input-uppercase"
                    name={"cuentas-visacion-minutas"}
                    data-parsley-required="true"
                    ref={tipoTramiteRef}
                    required
                    //onChange={changeEventSelected}
                  >
                    <option value="">Seleccione Tipo de Tramite</option>
                    {optionsTiposTramites}
                  </select>
                </div>

                <div className="col-12 mb-3">
                  <label
                    htmlFor={"cuentas-visacion-minutas"}
                    className="form-label"
                  >
                    Entidad Solicitante *
                  </label>
                  <input
                    type="text"
                    className="form-control input-uppercase"
                    placeholder="Entidad Solicitante"
                    data-parsley-required="true"
                    required
                    pattern="[a-zA-Z ]+"
                    data-parsley-pattern="[a-zA-Z ]+"
                    onChange={handleEntidadOnChange}
                  />
                </div>

                <div className="col-12 mb-3">
                  <label
                    htmlFor={"cuentas-visacion-minutas"}
                    className="form-label"
                  >
                    Caso *
                  </label>
                  <input
                    type="text"
                    className="form-control input-uppercase"
                    placeholder="Caso"
                    data-parsley-required="true"
                    required
                    pattern="[a-zA-Z0-9-]+"
                    data-parsley-pattern="[a-zA-Z0-9-]+"
                    onChange={handleCasoOnChange}
                  />
                </div>

                <div className="col-12 mb-3">
                  <label
                    htmlFor={"cuentas-visacion-minutas"}
                    className="form-label"
                  >
                    Detalle Solicitud *
                  </label>
                  <textarea
                    name="pres_validacion_legal_tributario[observacion]"
                    className="form-control input-uppercase"
                    rows="4"
                    placeholder="Detalle"
                    data-parsley-required="true"
                    required
                    pattern="[a-zA-Z0-9-,. ñÑáéíóúÁÉÍÓÚ.]+"
                    data-parsley-pattern="[a-zA-Z0-9-,. ñÑáéíóúÁÉÍÓÚ]+"
                    onChange={handleDetalleOnChange}
                    data-parsley-maxlength="300"
                    maxLength="300"
                    data-parsley-minlength="30"
                    minLength="30"
                  ></textarea>
                </div>
              </div>

              {furAutogenerado ? (
                <div className="row">
                  <div className="col-12  mb-3 text-center">
                    <h2>
                      <strong>{furAutogenerado}</strong>
                    </h2>
                    <label className="form-label">FUR - generado</label>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row">
                <div className="col-12 col-md-6 col-lg-6 mb-3 text-end"></div>

                <div className="col-12 col-md-3 col-lg-3 mb-3 text-end">
                  {furAutogenerado === "" ? (
                    <div className="search-btn button ">
                      <input
                        className="btn btn-full-screen"
                        id="btnGenerateAutoFur"
                        type="submit"
                        value="Generar"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-12 col-md-3 col-lg-3 mb-3 text-end">
                  <div className="search-btn button ">
                    <button
                      type="button"
                      className="btn bg-maroon-flush btn-full-screen"
                      data-bs-dismiss="modal"
                      id="btnGenerateAutoFurCancel"
                      onClick={closeModal}
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormModalAutoFur;
