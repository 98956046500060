import React, { useState, useEffect, useRef } from "react";
import Fetch from "../../../components/utils/Fetch";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const ResetPasswordNewFormulario = ({ onSubmit, urlLoading, dataFormReset }) => {
	const idForm = 'form-reset-password';
	const passwordRef = useRef(null);
	const confirmPasswordRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const fetch = new Fetch();
	fetch.setToast(toast);
	const isMounted = useRef(false);

	useEffect(() => {
		isMounted.current = true;
	
		return () => {
		  isMounted.current = false;
		};
	  }, []);

	// paso 01
	const handleSubmit = (e) => {
		e.preventDefault();
		// verificar el codigo en caso de celular
		window.jQuery("#" + idForm).parsley().validate();
		window.jQuery("#" + idForm).parsley().validate();
		var ul_error_dir = window.jQuery("input[name='password']").parent().parent().find('ul');
		window.jQuery("input[name='password']").parent().parent().find('ul').remove();
		window.jQuery("input[name='password']").parent('div').parent('div').append(ul_error_dir);

		var ul_error_dir2 = window.jQuery("input[name='confirmPassword']").parent().parent().find('ul');
		window.jQuery("input[name='confirmPassword']").parent().parent().find('ul').remove();
		window.jQuery("input[name='confirmPassword']").parent('div').parent('div').append(ul_error_dir2);

		if (window.jQuery("#" + idForm).parsley().isValid()) {
			let email = dataFormReset.metodo.indexOf('@') > 0 ? dataFormReset.email : null;
			let celular = dataFormReset.metodo > 0 ? dataFormReset.phone : null;
			handleUpdateNewPassword(email, celular, dataFormReset.tokenchange, null);
		}
	};

	// registrar nueva contraseña
	const handleUpdateNewPassword = async (email, celular, token, target) => {
		try {
			setLoading(true);
			const form = new FormData();
			form.append("usuario[password]", passwordRef.current.value);
			form.append("usuario[email]", email);
			form.append("usuario[celular]", celular);
			form.append("usuario[token]", token);
			let dataResponse = await fetch.fetchPost(form, "api/password/reset/update", target);

			if (dataResponse && dataResponse.status) {
				!dataResponse.data.actualizado ? toast.warning(dataResponse.message) : toast.success(dataResponse.message);
				if (dataResponse.data.actualizado && isMounted) {
					onSubmit(celular);
				}
			}
		} catch (error) {
			toast.error(error.message);
		} finally {
			setLoading(false);
		}
	}

	const handleOpenPassword = (event, refInputPassword) => {
		event.preventDefault();
		const input = refInputPassword.current;
		const icon = input.nextSibling.firstElementChild.firstElementChild;
		if (input.type === "text") {
			input.type = "password";
			icon.classList.add("fa-eye");
			icon.classList.remove("fa-eye-slash");
		} else {
			input.type = "text";
			icon.classList.add("fa-eye-slash");
			icon.classList.remove("fa-eye");
		}
	};



	/* =================================================== */
	return (
		<>

			{loading && (<div className="col-12 mb-1 mx-auto d-block text-center">
				<img
					className="rounded"
					alt="pulse-loading"
					src={urlLoading}
					width="40"
				/>{" "}
				<span className="ml-5 text-violeta">Procesando</span>
			</div>)}


			<form onSubmit={handleSubmit} id={idForm} name={idForm} noValidate>
				<div className="form-group mb-3">
					<label htmlFor="password">(*) Nueva Contraseña:</label>
					<div className="input-group">
						<input
							type="password"
							id="password"
							name="password"
							ref={passwordRef}
							className="form-control form-control-lg"
							data-parsley-required="true"
							data-parsley-required-message="La contraseña es requerido"
							data-parsley-pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!*?&#])[A-Za-z\d@$!*?&#]{8,}"
							data-parsley-pattern-message="La contraseña debe tener al menos 8 caracteres, una letra minúscula, una letra mayúscula, un número y un carácter especial (@$!*?&#)"
							minLength="8"
							data-parsley-length="[8,16]"
							data-parsley-minlength="8"
							autoComplete="new-password"
						/>

						<div className="input-group-append">
							<button className="btn-icono btn-secondary btn-lg" type="button" onClick={e => handleOpenPassword(e, passwordRef)}>
								<i className='fa fa-eye'></i>
							</button>
						</div>
					</div>
				</div>

				<div className="form-group">
					<label htmlFor="confirmPassword">(*) Repetir Nueva Contraseña:</label>
					<div className="input-group">
						<input
							type="password"
							id="confirmPassword"
							name="confirmPassword"
							ref={confirmPasswordRef}
							required
							data-parsley-equalto="#password"
							data-parsley-required-message="Confirmar lacontraseña es requerido"
							data-parsley-length="[8,16]"
							data-parsley-minlength="8"
							autoComplete="new-password"
							className="form-control form-control-lg"
						/>
						<div className="input-group-append">
							<button className="btn-icono btn-secondary btn-lg" type="button" onClick={e => handleOpenPassword(e, confirmPasswordRef)}>
								<i className='fa fa-eye'></i>
							</button>
						</div>

					</div>
				</div>

				<br />
				<div className="d-flex d-sm-flex flex-sm-row justify-content-between">
					<div>
						<Link to={"/"} className="btn btn-fucsia">
							Cancelar
						</Link>
					</div>
					<div>
						<button type="submit" className="btn btn-violeta">
							Continuar
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

export default ResetPasswordNewFormulario;
