import React, { useState, useRef, useEffect } from "react";
import Fetch from "../utils/Fetch";
import Config from "../../data/config";
import Links from "../../data/link";
import TitlePage from "../utils/TitlePage";
import Texto from "../../data/es";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalPdfBasic from "../utils/ModalPdfBasic";

let textSearch = "";
const getParamRequest = (param) => {
  param = param || "";

  switch (param) {
    case "vehiculos": {
      return "Vehículos";
    }
    case "inmuebles": {
      return "Inmuebles";
    }
    case "mercados": {
      return "Mercados";
    }
    case "publicidad_exterior": {
      return "Publicidad Exterior";
    }
    case "actividades_economicas": {
      return "Actividades Económicas";
    }
    default: {
      return "";
    }
  }
};

const getParamRequestCode = (param) => {
  debugger;
  param = param || "";
  switch (param) {
    case "vehiculos": {
      textSearch =
        "Ingresa el Nro. de placa del vehículo/nombre o la razón social del vendedor";
      return "VEHICULOS";
    }
    case "inmuebles": {
      textSearch = "Ingresa el Nro. número de inmueble/nombre/la razón social";
      return "INMUEBLES";
    }
    case "mercados": {
      textSearch = "Ingresa el Código de Licencia o la razón social";
      return "MERCADOS";
    }
    case "publicidad_exterior": {
      textSearch = "Ingresa el Código de Licencia o la razón social";
      return "PUBLICIDAD_EXTERIOR";
    }
    case "actividades_economicas": {
      textSearch =
        "Ingresa el Nro. de Actividad/Nro. de Licencia/nombre o razón social";
      return "ACTIVIDADES_ECONOMICAS";
    }
    default: {
      return "";
    }
  }
};

const LiquidacionLista = (props) => {
  const fetch = new Fetch();
  fetch.setToast(toast);

  const refModalPdfBasic = useRef();
  const [notificacionesList, setNotificacionesList] = useState("");
  const [totalList, setTotalList] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultSearch, setResultSearch] = useState("");
  const [searchData, setSearchData] = useState(false);

  const paramRequest = window.getParameterByName("proceso");
  let breadcrumbs = [
    {
      title: Links[0].title,
      url: Links[0].url,
    },
    {
      title: Links[33].title,
      url: Links[33].url,
    },
    {
      title: Links[34].title + " " + getParamRequest(paramRequest),
      url: Links[34].url,
    },
  ];

  const titlePage =
    Texto.liquidaciones_mixtas + " " + getParamRequest(paramRequest);

  useEffect(() => {
    let departament = getParamRequestCode(paramRequest);
    getDataLiquidacion("api/fiscalizacion/mixtas/" + departament);
  }, []);

  //////////////////
  const handleOpenFile = (event, code) => {
    event.preventDefault();
    refModalPdfBasic.current.openModal(
      true,
      `documents/preview/${code}`,
      `documents/download/${code}`
    );
  };

  const handleDownladFile = (event, code) => {
    event.preventDefault();
    window.location.href = Config[0].url + `documents/download/${code}`;
  };
  ////////////////////////////

  const hanldeClickReset = (event) => {
    event.preventDefault();
    event.target.closest("div").parentElement.firstElementChild.value = "";
  };

  const hanldeClickSearch = (event) => {
    event.preventDefault();
    let search = event.target
      .closest("div")
      .parentElement.firstElementChild.value.trim();
    search = search.replace(/ /g, "");
    if (search !== "") {
      //window.jQuery.preloader.start();
      setSearchData(true);
      let departament = getParamRequestCode(paramRequest);
      dataSearchRecursive(search, departament, currentPage);
    } else {
      toast.warn("Criterio de busqueda no de valido", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const getDataLiquidacion = (url, callback) => {
    fetch.fetchGet(url).then((dataJson) => {
      if (dataJson !== undefined && dataJson.status === true) {
        if (Boolean(dataJson.Notificacion)) {
          fetch.toast.success(dataJson.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          let cont = 0;
          let notificaciones = dataJson.Notificacion.map((item, index) => {
            return (
              <div
                className={
                  "row pt-3 pb-2 " + (cont % 2 === 0 ? "row-purple" : "")
                }
                key={cont++}
                style={{ marginLeft: 0, marginRight: 0 }}
              >
                <div className="col-12 col-md-2 col-lg-2 text-center pr-0 pt-1 pb-2">
                  <i
                    className={
                      "fa fa-file-pdf-o fa-3x fa-icon-image d-block " +
                      (cont % 2 === 0 ? "" : "text-white")
                    }
                    title={item.title}
                  ></i>
                  <small className="feature-box__text text-center d-block pt-1">
                    {window
                      .moment(item.created_at)
                      .format("DD-MM-YYYY HH:mm:ss")}
                  </small>
                  {item.doc_name.indexOf("a parte") > 0 ? (
                    <strong className="feature-box__text text-center text-overflow">
                      {item.doc_name}
                    </strong>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-12 col-md-8 col-lg-8 pt-1 pb-2">
                  <p className="feature-box__text text-justify pb-0 mb-0 text-overflow">
                    <strong>Nombre:</strong> {item.title}
                  </p>
                  <p
                    className="feature-box__text text-justify pb-0 mb-0"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span className="d-inline-block">
                      <strong>Formato:</strong> {item.doc_content_type}{" "}
                    </span>
                    <span className="d-inline-block" style={{ width: "40%" }}>
                      <strong>Tamaño:</strong> {item.doc_size} KB
                    </span>
                  </p>
                  <p className="feature-box__text text-justify pb-0 mb-0">
                    <strong>Descripción:</strong> {item.description}
                  </p>
                </div>

                <div className="col-12 col-md-2 col-lg-2 text-center pt-1 pb-2">
                  <i
                    className={
                      "far fa-file-pdf fa-2x fa-icon-image pr-3 pointer text-fountain-blue"
                    }
                    onClick={(e) => handleOpenFile(e, item.doc_code)}
                    id={item.doc_id}
                  ></i>
                  <i
                    className={
                      "far fa-arrow-alt-circle-down fa-2x fa-icon-image pointer ms-3 text-fountain-blue"
                    }
                    onClick={(e) => handleDownladFile(e, item.doc_code)}
                    id={item.doc_id}
                  ></i>
                </div>
              </div>
            );
          });
          setNotificacionesList(notificaciones);
          if (
            dataJson.total_mixtas !== undefined &&
            dataJson.total_mixtas > 0
          ) {
            setTotalList(dataJson.total_mixtas);
          }
          if (callback !== undefined)
            callback(
              dataJson.Notificacion,
              dataJson.total_coincidencias_econtradas
            );
        } else {
          if (callback !== undefined) callback(undefined, 0);
          fetch.toast.warn(dataJson.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }

      if (!callback) setSearchData(false);
      //window.jQuery.preloader.stop();
    });
  };

  const dataSearchRecursive = (search, departament, page) => {
    getDataLiquidacion(
      "api/fiscalizacion/search/" +
        search +
        "/" +
        departament +
        "/" +
        page +
        "/10",
      function (notificacion, total_encontrados) {
        if (
          (notificacion.length === 0 || notificacion === undefined) &&
          page + 10 <= totalList
        ) {
          dataSearchRecursive(search, departament, ++page);
          setCurrentPage(page);
        }
        //window.jQuery.preloader.stop();
        else setSearchData(false);
        setResultSearch(
          "Se han encontrado un total de " +
            total_encontrados +
            " coincidencias, en los archivos siguientes."
        );
      }
    );
  };

  return (
    <section className="items-grid section mt-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={titlePage}
          breadcrumbs={breadcrumbs}
          position={"left"}
          leftfull={false}
        />

        <div className="row">
          <div className="col-12 col-md-4 col-lg-6"></div>

          <div className="col-12 col-md-8 col-lg-6">
            <label htmlFor="inputSearch" className="form-label">
              {textSearch}
            </label>
            <div className="input-group mb-3">
              <input
                type="text"
                id="inputSearch"
                className="form-control input-uppercase"
                placeholder="Buscar"
                aria-label="Buscar"
                aria-describedby="Buscar"
                name="buscar"
              />
              <div className="input-group-text padding-0">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={hanldeClickSearch}
                >
                  <i className="fas fa-search" aria-hidden="true"></i>
                </button>
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={hanldeClickReset}
                >
                  <i className="fas fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        {searchData ? (
          <div className="row mt-3">
            <div className="col-12 mb-1">
              <img
                className="rounded mx-auto d-block"
                alt="pulse-loading"
                src={"/static/img/pulse_200.gif"}
                width="70"
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row mb-2">
          <div className="col-12">{resultSearch}</div>
        </div>

        {notificacionesList}

        <div className="row">
          <ModalPdfBasic ref={refModalPdfBasic} isFullScreen={true} />
        </div>

        <ToastContainer
          enableMultiContainer
          containerId={"Z"}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <ToastContainer />
      </div>
    </section>
  );
};

export default LiquidacionLista;