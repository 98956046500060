import React, { useState, useEffect } from "react";
import Links from "./../../../data/link";
import HeaderTitlecard from "./../_common/HeaderTitleCard";
import Consulta from "./../Consulta";
import Constant from "./../../../data/constant";

const Step2EditFormRequisitos = (props) => {

  const { nextClick, fetch, backClick, toast, auth, history, dataClasification, fur, stepNumber } = props;

  const [nodeRequisitos, setNodeRequisitos] = useState([]);
  const consultaSQL = new Consulta();
  const idClasification = dataClasification.id;
  let detalle = Constant[0].publicidad_step_new[2];



  useEffect(() => {
    debugger;
    if (!auth.loggedIn()) history.replace(Links[4].url);

    if (dataClasification) {
      loadRequisitos();
    }
  }, [dataClasification, auth]);

  const loadRequisitos = async () => {

    const esEstructura = (dataClasification.code === Constant[0].publicidad_clasificador.code_empresarial && fur > 0)
    const query = consultaSQL.queryRequirementSiteByClasificationEstructura(idClasification, "REQUIREMENT", esEstructura);
    const respRequistos = await fetch.fetchPostGraphQl(query, "/graphql", null);

    if (respRequistos && respRequistos.requirementSitesByClasification && respRequistos.requirementSitesByClasification.result) {
      const dataRequirementsite = respRequistos.requirementSitesByClasification.data.requirementSite;
      
      let nodeReq = requisitosHtml(dataRequirementsite);
      setNodeRequisitos(nodeReq);
    }
  };



  const requisitosHtml = (respRequistos) => {
    // console.log("respRequistos ++++++++++++++++++ requisitosHtml +++++++++++++++ ", respRequistos);
    return respRequistos.map(
      ({requiredRequirement, requiredImagenInstitucional, name, id }, index) => {
        
        return (
          <div className="row" key={index + "-" + id} index={index}>
            <div
              className="col-12 col-md-12"
              id={"row-first-requisito-" + id}
            >
              <div
                className={
                  "row row-table-custom pt-2 pb-2  me-1 " +
                  " justify-content-center align-items-center requisitos-" + id
                }
              >
                <div className="col-9 col-md-9">
                  <span className="text-justify"> {name} <br /> url: { }</span>
                  <br />

                  <strong> {requiredRequirement && <span className="text-danger" title="Requisito Obligatorio"> (*) </span>} Archivo: </strong>
                  <span className={`doc-name-${id}`}></span>
                </div>
                <div className="col-3 col-md-3">
                  <div className="form-group files text-center">
                    <label tabIndex={id} className="form-control">

                      <input
                        type="file"
                        className="form-control"
                        id={`requirement_site_${id}`}
                        name={`requirement_site[]`}
                        accept={requiredImagenInstitucional ? "image/jpeg, image/png" : ".pdf"}
                        onChange={handleOnchangeInputFile}                        
                        data-parsley-required={requiredRequirement}
                        data-parsley-required-message="Por favor, selecciona un archivo"
                        data-parsley-filemimetypes={requiredImagenInstitucional ? "image/jpeg,image/png" : "application/pdf"}
                        data-parsley-filemimetypes-message={requiredImagenInstitucional ? "Solo se permiten imágenes (JPEG/PNG)" : "Solo se permiten archivos PDF"}
                      />
                      {requiredRequirement} {requiredImagenInstitucional}

                    </label>
                    <span className="fs-small8em text-maroon-flush small-description">
                      7MB, Limite Máximo
                    </span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        );
      }
    );
  };


  const handleOnchangeInputFile = (event) => {

    debugger
    const inputFile = event.target;
    let sizeFileBytes = inputFile.files[0].size;
    let nameFile = inputFile.files[0].name;
    const divContainerFather = inputFile.closest(".row");
    let iconFile = inputFile.parentElement.parentElement.querySelector("i");
    if (iconFile) inputFile.parentElement.parentElement.removeChild(iconFile);
    // console.log("----------------------->>>>>>>>>>>>>>>>>>>>>>   ",event.target, inputFile.files[0]);
    if (sizeFileBytes > 0 && sizeFileBytes<= 7000000) {               

        if (inputFile.files[0].name.includes("https") || inputFile.files[0].name.includes("http")) {
          let nameFile = inputFile.files[0].name.split("/");
          nameFile = nameFile[nameFile.length - 1];
          divContainerFather.firstChild.children[3].innerHTML = `${nameFile.substring(
            nameFile.length - 20,
            nameFile.length
          )}`;
        } else {
          divContainerFather.firstChild.children[3].innerHTML = `${inputFile.files[0].name}`;
        }

        const format = inputFile.files[0].type.split("/");
        if (/^(gif|jpe?g|png|pdf)$/i.test(format[1])) {
          const icono = document.createElement("i");
          if (format[1] === "pdf") {
            icono.className = "fas fa-file-pdf icon";
          }
  
          if (/^(gif|jpe?g|png)$/i.test(format[1])){
            icono.className = "fas fa-file-image icon";
          }              
          inputFile.parentElement.parentElement.appendChild(icono);
        } 
    } else{
      inputFile.value = null;
      divContainerFather.firstChild.children[3].innerHTML = "";
     
      toast.error("Formato no valido o excede los 7MB el archivo");
    }    
  };

  const nextForm = (event) => {
    debugger
    event.preventDefault();
    let inputsDocuments = document.getElementsByName("requirement_site[]");
    const inputFiles = [];
    Array.from(inputsDocuments).filter((input) => {
      if (input.files.length > 0) {
        inputFiles.push({
          id: input.getAttribute("id"),
          file: input.files[0]
        });
      }
    });

    window.scrollTo(0, 0);

    if (inputFiles.length > 0) {
      //nextClick(event, stepNumber, true, inputFiles);
      nextClick(event, stepNumber, false, inputFiles)
    } else {
      toast.error("Adjunte los requisitos solicitados, en formato PDF");
      event.preventDefault();
    }
  }


  return (
    <div className="main-wizard ">
      <HeaderTitlecard titulo={'Requisitos de Emplazamiento'} descripcion={detalle} visibleRequired={true} />

      <form
        className="contact__form"
        id={"formValideRequirement"}
      >
        <div className="mt-4 mb-4">
          {nodeRequisitos}
        </div>

        <div className="buttons button_space justify-content-between mt-2">
          <a className="back_button btn btn-fucsia" onClick={backClick}>Volver</a>
          <button className="next_button btn btn-violeta" onClick={nextForm}>Continuar</button>
        </div>
      </form >
    </div>
  );
};

export default Step2EditFormRequisitos;
