import React, { Component } from "react";

import Links from "../../../data/link";
import Fetch from "../../utils/Fetch";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ValidateFur from "./ValidateFur";
import ValidateDeuda from "./ValidateDeuda";
import ValidateTerminosCondiciones from "./ValidateTerminosCondiciones";
import ValidatePropiedad from "./ValidatePropiedad";
import ValidatePropiedadPagos from "./ValidatePropiedadPagos";
import ValidateTypeCertificacion from "./ValidateTypeCertificacion";

var _fetch = null;
class ModalFur extends Component {
  constructor(props, context) {
    super(props, context);
    this.idModalFur = "modalFurFull";
    this.formModalFur = "formModalFur";

    this.handleVoidEnter = this.handleVoidEnter.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);

    this.state = {
      dataNotificacion: undefined,
      dataInmueble: undefined,
      dataPropiedadSelected: undefined,
      dataDerechoAdmision: undefined,
      dataObjetoTributario: undefined,
      dataPagosPropiedad: undefined,
      currentPage: 0,
      csrfkey: undefined,
    };

    this.typeFur = this.props.typeFur;
    this.textTypeDerechoAdmisión = Links[1].title;
    this.rubro = this.props.rubro;

    if (this.typeFur === "PRESCRIPCION")
      this.textTypeDerechoAdmisión = Links[3].title;
    if (this.typeFur === "CERTIFICACION")
      this.textTypeDerechoAdmisión = Links[49].title;

    this.token = window.getParameterByName("csrf-token");
    this.local = window.getParameterByName("lo");
    this.expiraEn = window.getParameterByName("expiraEn");
    this.preview = window.getParameterByName("preview");
    this.adm = window.getParameterByName("adm");

    _fetch = new Fetch();
    _fetch.setToast(toast);
  }

  handleCancelClick(event) {
    event.preventDefault();
    switch (this.typeFur) {
      case "CERTIFICACION":
        if (
          this.token &&
          this.local &&
          this.expiraEn &&
          this.preview &&
          this.adm
        )
          window.location.href = Links[56].url + "?cert=detallado";
        else if (this.token && this.local && this.expiraEn) {
          window.location.href = Links[111].url;
        } else window.location.href = Links[111].url;
        break;
      default:
        window.location.href = Links[1].url;
    }
  }

  handleVoidEnter(e) {
    e.preventDefault();
    this.validateForm();
  }

  validateForm = () => {
    window
      .jQuery("#" + this.formModalFur)
      .parsley()
      .validate();
    var li_error = window.jQuery("#inputModalFur").siblings("ul");
    window.jQuery("#inputModalFur").siblings("ul").remove();
    window
      .jQuery("#inputModalFur")
      .parent("div")
      .parent("div")
      .append(li_error);
  };

  nextPageCurrent = (currentPage, data = undefined) => {
    if (!this.state.csrfkey)
      this.setState((state) => ({
        csrfkey: {
          csrfToken: this.token,
          lo: this.local,
          expiraEn: this.expiraEn,
          preview: this.preview,
          adm: this.adm,
        },
      }));

    if (currentPage === 2 && data.hasOwnProperty("inmueble")) {
      let dataInmueble = {
        dataInmueble: data.inmueble,
        dataTecnico: data.datosTecnicos,
        dataGestionDeuda: data.gestionDeuda,
      };
      if (data.inmueble.propiedades && data.inmueble.propiedades.length > 1)
        this.setState((state) => ({
          currentPage,
          dataInmueble: dataInmueble,
          objetoTributario: data.objetoTributario,
        }));
      else {
        const inmuebleSelected = {
          codigoCatastral: data.inmueble.codigoCatastral,
          codigoInmueble: data.inmueble.codigoInmueble,
          numeroInmueble: data.inmueble.numeroInmueble,
          codigoPropiedad: data.inmueble.propiedades[0].codigoPropiedad,
          estadoPropiedad: data.inmueble.propiedades[0].estadoPropiedad,
          fechaInicioPropiedad:
            data.inmueble.propiedades[0].fechaInicioPropiedad,
          porcentaje: data.inmueble.propiedades[0].porcentaje,
          propietario: data.inmueble.propiedades[0].propietario,
          idObjetoTributario: data.idObjetoTributario,
          selected: true,

          typeSearch: data.typeSearch,
        };

        this.setState((state) => ({
          currentPage: ++currentPage,
          dataInmueble: dataInmueble,
          objetoTributario: data.objetoTributario,
          dataPropiedadSelected: inmuebleSelected,
        }));
      }
    }

    if (currentPage === 3 && data.hasOwnProperty("selected"))
      this.setState((state) => ({
        currentPage,
        dataPropiedadSelected: data,
      }));

    if (currentPage === 4) {
      this.setState((state) => ({
        currentPage,
        dataPagosPropiedad: data,
      }));
    }

    if (currentPage !== 2) {
      if (data && data.hasOwnProperty("fur"))
        this.setState((state) => ({
          currentPage: currentPage,
          dataDerechoAdmision: data,
        }));
      else if (data && data.hasOwnProperty("objetoTributario")) {
        this.setState((state) => ({
          currentPage: currentPage,
          objetoTributario: data.objetoTributario,
        }));
      } else this.setState((state) => ({ currentPage: currentPage }));
    }
  };

  render() {
    return (
      <div
        className="modal fade modal-custom-atm"
        id={this.idModalFur}
        tabIndex="-1"
        aria-labelledby="modalFurFullTitle"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
        style={{ overflowY: "auto" }}
      >
        <div
          className="modal-dialog modal-md"
          style={{ boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.75)" }}
        >
          <div className="modal-content ">
            <div className="modal-header text-butterfly-bush justify-content-center">
              {this.state.currentPage === 0 ? (
                <h4
                  className="modal-title "
                  id="exampleModalLongTitle"
                  style={{ fontWeight: 600 }}
                >
                  VALIDACIÓN
                </h4>
              ) : (
                <h4
                  className="modal-title "
                  id="exampleModalLongTitle"
                  style={{ fontWeight: 600 }}
                >
                  {this.textTypeDerechoAdmisión.toUpperCase()}
                </h4>
              )}
            </div>
            <div className="modal-body">
              <form
                className="contact__form"
                style={{ margin: "0.5rem" }}
                onSubmit={this.handleVoidEnter}
                id={this.formModalFur}
              >
                {this.state.currentPage === 0 ? (
                  <ValidateFur
                    rubro={this.rubro}
                    handleCancelClick={this.handleCancelClick}
                    fetch={_fetch}
                    validateForm={this.validateForm}
                    typeFur={this.typeFur}
                    nextPageCurrent={this.nextPageCurrent}
                    csrfkey={this.state.csrfkey}
                  />
                ) : (
                  ""
                )}

                {this.state.currentPage === 1 ? (
                  this.typeFur === "PRESCRIPCION" ? (
                    <ValidateDeuda
                      handleCancelClick={this.handleCancelClick}
                      fetch={_fetch}
                      validateForm={this.validateForm}
                      nextPageCurrent={this.nextPageCurrent}
                      dataDerechoAdmision={this.state.dataDerechoAdmision}
                    />
                  ) : (
                    <ValidateTypeCertificacion
                      handleCancelClick={this.handleCancelClick}
                      fetch={_fetch}
                      validateForm={this.validateForm}
                      nextPageCurrent={this.nextPageCurrent}
                      dataDerechoAdmision={this.state.dataDerechoAdmision}
                      idForm={this.formModalFur}
                      csrfkey={this.state.csrfkey}
                    />
                  )
                ) : (
                  ""
                )}

                {this.state.currentPage === 2 ? (
                  <ValidatePropiedad
                    handleCancelClick={this.handleCancelClick}
                    fetch={_fetch}
                    nextPageCurrent={this.nextPageCurrent}
                    dataInmueble={this.state.dataInmueble}
                    objetoTributario={this.state.objetoTributario}
                    dataDerechoAdmision={this.state.dataDerechoAdmision}
                    csrfkey={this.state.csrfkey}
                  />
                ) : (
                  ""
                )}

                {this.state.currentPage === 3 ? (
                  <ValidatePropiedadPagos
                    handleCancelClick={this.handleCancelClick}
                    fetch={_fetch}
                    nextPageCurrent={this.nextPageCurrent}
                    dataInmueble={this.state.dataInmueble}
                    dataDerechoAdmision={this.state.dataDerechoAdmision}
                    objetoTributario={this.state.objetoTributario}
                    dataPropiedadSelected={this.state.dataPropiedadSelected}
                    idModal={this.idModalFur}
                    csrfkey={this.state.csrfkey}
                  />
                ) : (
                  ""
                )}

                {this.state.currentPage === 4 ? (
                  <ValidateTerminosCondiciones
                    handleCancelClick={this.handleCancelClick}
                    fetch={_fetch}
                    nextPageCurrent={this.nextPageCurrent}
                    dataInmueble={this.state.dataInmueble}
                    dataDerechoAdmision={this.state.dataDerechoAdmision}
                    dataPropiedadSelected={this.state.dataPropiedadSelected}
                    objetoTributario={this.state.objetoTributario}
                    dataPagosPropiedad={this.state.dataPagosPropiedad}
                    closeModal={this.props.closeModal}
                    csrfkey={this.state.csrfkey}
                  />
                ) : (
                  ""
                )}
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalFur;
