import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AuthService from "../../components/Usuario/AuthService";
import Fetch from "../utils/Fetch";
import { useParams, useHistory } from "react-router-dom";
import Links from "../../data/link";
import HeaderTitlecard from "./_common/HeaderTitleCard";
import "react-toastify/dist/ReactToastify.css";
import "../../components/style/form-wizard.css";
import { Link } from "react-router-dom";
import Config from "../../data/config";
import Constant from "../../data/constant";
import Consulta from "./Consulta";


const Review = (props) => {
    const { token, numero } = useParams();

    const fetch = new Fetch();
    const auth = new AuthService();
    fetch.setToast(toast);
    const history = useHistory();
    const tokenAdvertising = token;
    const consultaSQL = new Consulta();
    const urlPdfRepositorio = `${Config[10].url}/`;  //src/

    const [dataAdvertising, setDataAdvertising] = useState(null);
    const [nodeRequisitos, setNodeRequisitos] = useState([]);
    const [totalValidateRequisito, setTotalValidateRequisito] = useState(0);
    const [totalRequisito, setTotalRequisito] = useState(0);
    // modal - rechazados
    const [showModal, setShowModal] = useState(false);
    const [infoShowModal, setInfoShowModal] = useState(undefined);
    // setModalSearchPmc(modalSearch)
    const [requirementNameRechazado, setRequirementNameRechazado] = useState(null);
    const [requirementToken, setRequirementToken] = useState(null);
    const [requirementCodeEstado, setRequirementCodeEstado] = useState(null);

    useEffect(() => {
        debugger;
        if (!auth.loggedIn()) history.replace(Links[4].url);
        if (tokenAdvertising) {
            loadRequirements(tokenAdvertising);
            // data advertising
            dataPublicidad(tokenAdvertising);
        }
    }, [dataAdvertising?.fur]);


    // abrir un una nueva pestaña
    function openInNewTab(url) {
        var win = window.open(url, '_blank');
        win.focus();
    }

    // 
    const loadRequirements = async (tokenAdvertising) => {
        debugger;

        const query = consultaSQL.queryRequirementsSiteAdvertising(tokenAdvertising, "REQUIREMENT");
        fetch
            .fetchPostGraphQl(query, "/graphql", null)
            .then((data) => {
                debugger
                if (data && data.requirementsSiteAdvertising && data.requirementsSiteAdvertising.result) {
                    const respRequisitos = data.requirementsSiteAdvertising.data.advertisingRequirementSite;
                    setTotalRequisito(respRequisitos.length);
                    const approvedCount = respRequisitos.filter(({ Estado }) => Estado.code === Constant[0].estado.aprobado).length;
                    setTotalValidateRequisito(approvedCount);
                    let nodeReq = requisitosHtml(respRequisitos);
                    setNodeRequisitos(nodeReq);
                }
            });
    };


    const handleChangeStateAdv = (tokenAdvertising, tokenAdvRequirement, codeEstadoOld, estadoCode, operacion, descripcionObservation) => {
        
        window.createBootbox("¿Está seguro de continuar con la operación de \"" + operacion + "\" el requisito seleccionado ? ", function (result) {
            if (result === true) {
                
                const query = consultaSQL.updateEstadoAdvertisingRequerimentSite(tokenAdvertising, tokenAdvRequirement, codeEstadoOld, estadoCode, descripcionObservation)              

                fetch
                    .fetchPostGraphQl(query, "/graphql", null)
                    .then((data) => {
                        debugger
                        if (data.updateEstadoAdvertisingRequeriment && data.updateEstadoAdvertisingRequeriment.result) {
                            // toast.success(data.updateEstadoAdvertisingRequeriment.message);
                            toast.success("La revisión del requisito fue registrado con éxito.");
                            setTimeout(() => {
                                loadRequirements(tokenAdvertising);    
                            }, 1000);
                                                        
                        }
                        else {
                            toast.error("No se registro la actualización de la operación. Vuelva a intentarlo")
                            //toast.error(data.updateEstadoAdvertisingRequeriment.message);
                        }
                    });

                // verifica si todos fueron aprobados
                if (totalRequisito > 0 && totalRequisito === totalValidateRequisito && dataAdvertising && dataAdvertising.Estado.code === Constant[0].estado.aprobado) {
                    onclickValidarRequisitosAll();
                }
            }
        });
    }


    const requisitosHtml = (respRequistos) => {

        return respRequistos.map(
            ({ id, token, url, AdRequirementSite, Estado }, index) => {

                return (
                    <tr className=" border-bottom pt-3 pb-3 pr-2" key={index + "-" + id} index={index}>
                        <td className="">
                            <p className="text-justify">{AdRequirementSite.name}</p>
                        </td>

                        <td className="text-center">
                            <p className="">{Estado.name}</p>
                        </td>

                        <td className="text-center">
                            <button type="button" onClick={(e) => openInNewTab(urlPdfRepositorio + url)} className="btn btn-link" title="Ver documento/fotografía">
                                {
                                    tipoExtensionFile(urlPdfRepositorio + url) === "pdf" ? <i className="fas fa-file-pdf h3"></i>:<i className="fas fa-file-image h3"></i>
                                }
                                
                            </button>
                        </td>

                        <td className="text-center">
                            {(dataAdvertising && dataAdvertising.Estado.code == Constant[0].estado.aprobado && (Estado.code == Constant[0].estado.pendiente_aprobacion))
                                &&
                                <>
                                    <Link
                                        to="#"
                                        className="btn btn-link link-success"
                                        onClick={(e) => handleChangeStateAdv(tokenAdvertising, token, Estado.code, "APROBADO", 'Aprobar', '')}
                                        title={`Aprobar Requisito`}
                                    >
                                        <i className="fas fa-check-circle h3"></i>
                                    </Link>

                                    <Link
                                        to="#"
                                        className="btn link link-danger"
                                        onClick={(e) => openModalRequirement(e, AdRequirementSite.name, token, Estado.code, "RECHAZADO")}
                                        title={`Rechazar Requisito`}
                                    >
                                        <i className="fas fa-times-circle h3"></i>
                                    </Link>
                                </>
                            }
                        </td>
                    </tr>
                );
            }
        );
    };

    const tipoExtensionFile = (url)=>{
        const extension = url.split('.').pop().toLowerCase();
        return extension.toLowerCase();
    }

    const dataPublicidad = (token) => {
        const query = consultaSQL.queryInfoAdvertisingByToken(token);
        fetch
            .fetchPostGraphQl(query, "/graphql", null)
            .then((data) => {
                if (data && data.advertising && data.advertising.result) {
                    setDataAdvertising(data.advertising.data.advertising);
                }
            });
    }

    const backClick = () => {
        // history.goBack(-1);
        // history.push('/actividades-economicas/publicidad/en_proceso');
        history.push(Links[132].url);
    };

    // si contribuytente natural o juridico
    const contribuyente = (listaPersona) => {
        if (Array.isArray(listaPersona) && listaPersona.length > 0) {
            let objetoTitular = listaPersona[0];
            let natural = parseInt(objetoTitular.natural);
            if (natural == 1) {
                return objetoTitular.Persona.nombre + " " + objetoTitular.Persona.apellidoPaterno + " " + objetoTitular.Persona.apellidoMaterno;
            } else {
                debugger
                return objetoTitular.AdTitularJuridico.razonSocial;
            }
        }
        return "Sin datos";
    }


    const onclickValidarRequisitosAll = () => {
        //event.preva
        // window.createBootbox("¿Está seguro de validar todos los requisitos ? ", function (result) {
        if (dataAdvertising && tokenAdvertising && (totalRequisito === totalValidateRequisito)) {
            const query = consultaSQL.queryUpdateEstadoAdvertising(Constant[0].estado.aprobado, Constant[0].estado.revisado, tokenAdvertising);
            fetch.fetchPostGraphQl(query, "/graphql", null).then((data) => {
                debugger
                if (data.updateEstadoAdvertising && data.updateEstadoAdvertising.result) {
                    toast.success(data.updateEstadoAdvertising.message);
                    setTimeout(() => {
                        history.push(Links[132].url);
                    }, 3000);
                } else {
                    toast.error(data.updateEstadoAdvertising.message);
                }
            });

        } else {
            toast.error("cancelado");
        }
    };


    // modal rechazado
    const handleCloseModal = (event) => {
        setShowModal(false);
    };

    // handle modal
    const openModalRequirement = (event, requirement, tokenRequirement, codeEstadoOld, codeEstadoNuevo) => {
        event.preventDefault();
        debugger;
        setShowModal(true);
        setRequirementNameRechazado(requirement);
        setRequirementToken(tokenRequirement);
        setRequirementCodeEstado(codeEstadoOld);

        setTimeout(() => {
            const observationTextarea = document.getElementById('form_observation');                         
            observationTextarea.value = '';
            const modalSearchDom = document.getElementById("modalSearchContribuyentesAutorization");
            const modalSearch = new window.bootstrap.Modal(modalSearchDom, {
                keyboard: true,
                backdrop: "static",
                focus: true,
            });
            modalSearch.show();
            setInfoShowModal(modalSearch);
        }, 500);
    };


    const handleSubmitFormRechazado = (event) => {
        event.preventDefault();
        const formHtml = event.target.closest("form")
        window
            .jQuery("#" + formHtml.getAttribute("id"))
            .parsley()
            .validate();
        if (window.jQuery("#" + formHtml.getAttribute("id")).parsley().isValid()) {
            
            setShowModal(false);
            infoShowModal.hide();          

            const observation = event.target.elements.form_observation.value;
            handleChangeStateAdv(tokenAdvertising, requirementToken, requirementCodeEstado, "RECHAZADO", 'Rechazar', observation);
        }
    }


    /* ================================================================================================  */
    return (
        <section className="items-grid section mt-5 mb-5">
            <div className="container mt-4">
                <div className="card-w">
                    <HeaderTitlecard titulo={'Revisión Requisitos de Publicidad '} descripcion={'Revisión de los requisitos adjuntos por el contribuyente, de acuerdo al tipo y la categoria. Una vez aprobado todos los requisitos se debe poceder a habilitar para la emision de la proforma o CRAPE.'} />
                </div>
                {dataAdvertising && <div className="row mb-4">
                    <div className="col-md-2 col-xs-6"><b>Contribuyente: </b></div>
                    <div className="col-3">{contribuyente(dataAdvertising.AdTitulars)}</div>
                    <div className="col-md-2 col-xs-6"><b>Fur:</b></div>
                    <div className="col-md-1">{dataAdvertising.fur}</div>
                    <div className="col-md-2 col-xs-6"><b>Número:</b></div>
                    <div className="col-md-1">{dataAdvertising.numero}</div>
                    <div className="col-md-2 col-xs-6"><b>Clasificación: </b></div>
                    <div className="col-3">{dataAdvertising.clasificationCategoriesAdv[0].clasificationCategoryPublicity.clasification.name}</div>
                    <div className="col-md-2 col-xs-6"><b>Categoria: </b></div>
                    <div className="col-3">{dataAdvertising.clasificationCategoriesAdv[0].clasificationCategoryPublicity.categoryPublicity.name}</div>
                </div>
                }

                <button type="button" className="btn btn-success" disabled>
                    Total aprobados <span className="badge badge-light">{totalValidateRequisito} de {totalRequisito}</span>
                    <span className="sr-only">unread messages</span>
                </button>

                <div className="table-responsive mt-2">
                    <table className="table table-bordered" style={{ fontSize: 15 }}>
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">Requisitos</th>
                                <th scope="col">Estado</th>
                                <th scope="col">Documento</th>
                                <th scope="col" className="col-2">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {nodeRequisitos}
                        </tbody>
                    </table>
                </div>

                <div className="buttons button_space justify-content-center mt-3">
                    {
                        (totalRequisito > 0 && totalRequisito === totalValidateRequisito && dataAdvertising && dataAdvertising.Estado.code === Constant[0].estado.aprobado) ?

                            <a className="next_button btn btn-violeta col-2" onClick={onclickValidarRequisitosAll}>Continuar</a>
                            :
                            <a className="next_button btn btn-fucsia col-2" onClick={backClick}>Volver</a>
                    }
                </div>
            </div>

            {/* modal - rechazado */}
            {showModal ? (
                <div
                    className="modal fade "
                    id={"modalSearchContribuyentesAutorization"}
                    tabIndex="-1"
                    aria-labelledby="modalSearchContribuyentesAutorizationTitle"
                    aria-hidden="true"
                    data-backdrop="static"
                    data-keyboard="false"
                    style={{ overflowY: "auto" }}
                >
                    <div className="modal-dialog   modal-lg">
                        <div className="modal-content ">
                            <div className="modal-header">
                                <h4
                                    className="modal-title "
                                    id="modalSearchContribuyentesAutorizationTitle"
                                >
                                    Observación del Requisito
                                </h4>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">

                                <div className="row mb-3">
                                 
                                    <div className="col-12">
                                        <b>Requisito:</b><br/>
                                        <p className="text-justify pr-2">{requirementNameRechazado}</p></div>
                                </div>

                                <form
                                    action=""
                                    className="contact__form needs-validation center-login"
                                    name="formSearchDataTable"
                                    id="formSearchDataTable"
                                    method="post"
                                    noValidate
                                    onSubmit={handleSubmitFormRechazado}
                                >

                                    <div className="form-group">
                                        <label for="form_observation">(*) Descripción de la observación</label>
                                        <textarea className="form-control"
                                            id="form_observation"
                                            name="form_observation"
                                            rows="5"
                                            data-parsley-required="true"
                                            data-parsley-maxlength="300"
                                            data-parsley-minLength="10"
                                            data-parsley-required-message="Este campo es obligatorio"
                                            data-parsley-maxlength-message="La descripción no puede tener más de 300 caracteres."
                                        ></textarea>
                                    </div>

                                    <div className="buttons button_space justify-content-between mt-2">
                                        <button type="button" className="back_button btn btn-fucsia" data-bs-dismiss="modal"
                                            onClick={handleCloseModal}>Volver</button>
                                        <button type={"submit"} className="next_button btn btn-violeta">Continuar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}

        </section>
    )

}
export default Review;