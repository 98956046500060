export default class VisacionMinuta {
  constructor() {
      this.token = ""
      this.fur = ""
      this.numeroOrden = ""
  }

  setToken(token) {
      this.token = token
  }

  getToken() {
      return this.token
  }

  setFur(fur){
      this.fur = fur
  }

  getFur(){
      return this.fur
  }

  setNumeroOrden(numeroOrden){
      this.numeroOrden = numeroOrden
  }

  getNumeroOrden(){
      return this.numeroOrden
  }
}
