import React from "react";
import PopOverCustom from "./../PopOverCustom";

const TableColindantes = (props) => {
  const { data, tipoVisacionMinuta, handleInputProgramas } = props;

  const handleEventInputPopOver = (event, value, codeAtribute) => {
    //event.preventDefault();
    debugger;
    const data = {};

    if (codeAtribute === "ESTE") data.colindateEste = value;
    if (codeAtribute === "OESTE") data.colindateOeste = value;
    if (codeAtribute === "NORTE") data.colindateNorte = value;
    if (codeAtribute === "SUD") data.colindateSud = value;
    handleInputProgramas(event, data);
  };

  const rowTable = (sub_cont, descripcionColindancia, orientacion, id) => {
    return (
      <div
        className={"row pointer row-table-custom ml-1 pt-3 "}
        key={sub_cont + "_" + orientacion}
      >
        <div className="col-5 col-md-4 ">
          <p>{orientacion}</p>
        </div>
        <div className="col-7 col-md-7 ">
        { descripcionColindancia }
          {tipoVisacionMinuta.code === "PROGRAMAS_CASA_SEGURA_MI_PLANO" ? (
            <div className="ps-2 d-inline-block">
              <PopOverCustom
                id={"colindante" + orientacion}
                title={"Colindante " + orientacion}
                regex={/^([A-Z 0-9.-])+/}
                placeHolder="LOTE NRO 2"
                namePopOver={"colindante-" + orientacion}
                handleEventButton={(e, value) =>
                  handleEventInputPopOver(e, value, orientacion)
                }
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  let sub_cont = 0;

  const table = data.map(({ descripcionColindancia, orientacion }) => {
    sub_cont++;
    return rowTable(sub_cont, descripcionColindancia, orientacion);
  });

  return (
    <div className="">
      <div className="row ">
        <div className="col-5 col-md-4 pt-3 text-left">
          <p className="font-weight-bold">
            <strong>Orientación</strong>
          </p>
        </div>
        <div className="col-7 col-md-7 pt-3">
          <p className="font-weight-bold text-left">
            <strong>Descripcion</strong>
          </p>
        </div>
      </div>

      {table !== undefined ? table : ""}
    </div>
  );
};

export default TableColindantes;
