import React from "react";
import Links from "../../data/link";
import { Link } from "react-router-dom";
import "./css/style.css";

const TributarioButton = ({ rowObjectTributario }) => {
	const nombreObjTributario = rowObjectTributario.name;
	const codeObjTributario = rowObjectTributario.code.toLowerCase();
	const dataTypeTributario = JSON.parse(rowObjectTributario.data_tributary);

	// =========================================== //
	const icons = {
		inmueble: "fas fa-home fa-3x rounded-circle border p-3",
		vehiculo: "fa fa-car fa-3x rounded-circle border p-3",
		patente_municipal: "fas fa-store fa-3x rounded-circle border p-3",
	};
	// =========================================== //

	const handleRedirectUrlClick = (ev, url) => {
		ev.preventDefault();
		window.location.href = url;
	};
	// =========================================== //
	return (
		<div className="card">
			<div className="card-body">
				<div className="text-center mb-2">
					<i className={icons[codeObjTributario]} aria-hidden="true"></i>
					<h5 className="pt-4 pb-1 opacity-25">
						<strong>{nombreObjTributario}</strong>
					</h5>
				</div>
			</div>

			<div className="card-footer text-muted pointer">
				<div className="dropdown">
					<div
						className="text-center text-wrap text-green3278-bold "
						data-bs-toggle="dropdown"
						aria-expanded="false"
						style={{ minHeight: 40 }}
					>
						{nombreObjTributario}
					</div>

					{codeObjTributario !== undefined ? (
						<div className="dropdown-menu">
							{dataTypeTributario.map((rowData) => (
								<Link
									to="#"
									// rel="noopener noreferrer"
									className="dropdown-item"
									onClick={(e) =>
										handleRedirectUrlClick(
											e,
											Links[118].url +
												"/" +
												codeObjTributario +
												"/" +
												rowData.code.toLowerCase()
										)
									}
									title={rowData.name}
								>
									{rowData.name}
								</Link>
							))}
						</div>
					) : (
						""
					)}
				</div>
			</div>
		</div>
	);
};

export default TributarioButton;
