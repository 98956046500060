import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import Languaje from '../../data/es';


const AsyncSelectCustom = (props) => {

    const { placeHolder, name, url, fetch, defaultValue } = props
    let optionsSelect = [];

    if (defaultValue) {
        optionsSelect = [
            {
                value: defaultValue.id,
                label: defaultValue.name,
            },
        ]
    }

    const loadOptionsSelect = async (inputValue) => {
        if (inputValue.length > 0) {
            const response = await fetch.axiosAsyncGet(`${url}/${inputValue}`);
            if (response.status === true)
                return response.data;
            else
                return [];
        } else {
            return optionsSelect
        }
    }

    const noOptionsMessage = (props) => {
        let search = Languaje.linea_servicio_publico;
        if (props.inputValue === '') {
            return (search);
        }
        return (Languaje.criterio_busqueda_no_corresponde);
    }

    return (
        <AsyncSelect
            cacheOptions
            name={name}
            loadOptions={loadOptionsSelect}
            defaultOptions
            isClearable
            isSearchable
            placeholder={placeHolder}
            required
            defaultValue={[optionsSelect[0]]}
            noOptionsMessage={noOptionsMessage}
        />
    )
}

export default AsyncSelectCustom;