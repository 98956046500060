import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalAutorizacion from "./ModalAutorizacion";
import Form from './Form';
import Links from "../../data/link";
import TitlePage from "../../components/utils/TitlePage";
import Texto from "../../data/es";
import { useHistory } from "react-router-dom";
import AuthService from "../Usuario/AuthService";

const Create = (props) => {
  const [initialState, setInitialState] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [modalVisacion, setModalVisacion] = useState(undefined);
  const [dataInmueble, setDataInmueble] = useState(undefined);
  const auth = new AuthService();
  const history = useHistory();

  if (!auth.loggedIn()) history.replace(Links[4].url);

  useEffect(() => {
    if (auth.loggedIn()) {
      const modalFur = new window.bootstrap.Modal(
        document.getElementById("modalAutorizationFull"),
        {
          keyboard: true,
          backdrop: "static",
          focus: true,
        }
      );
      modalFur.show();
      setModalVisacion(modalFur);
    } else window.location.href = Links[4].url;
  }, [initialState]);

  const closeModal = (data, message) => {
    modalVisacion.hide();
    document
      .getElementById("modalAutorizationFull")
      .addEventListener("hidden.bs.modal", function (event) {
        console.log("modal se ha cerrado.....", data);
        setDataInmueble(data.dataPropiedad);
        setShowForm(true);
        setTimeout(function () {
          window.jQuery('[data-toggle="tooltip"]').tooltip();
        }, 3000);
      });
  };

  const breadcrumbs = [
    {
      title: Links[0].title,
      url: Links[0].url,
    },
    {
      title: Links[108].title,
      url: Links[108].url,
    },
    {
      title: "Nuevo",
      url: "#",
    },
  ];

  return (
    <section className="items-grid section mt-5 mb-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={Texto.autorizacion_telecomunicaciones}
          breadcrumbs={breadcrumbs}
          position={"left"}
        />

        {showForm ? (
          <Form toast={toast} dataInmueble={dataInmueble} />
        ) : (
          <p>Esperando confirmación previsa solicitud de la prescripción...</p>
        )}
      </div>

      <ModalAutorizacion toast={toast} closeModal={closeModal} />

      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </section>
  );
};

export default Create;
