import React, { useState, useEffect } from "react";
import Config from "../../data/config";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";

registerLocale("es", es); // register it with the name you want
const DatePickerAtm = (props) => {
  const { placeHolder, name, maxDate, minDate, mandatory, startDateSelected } = props;
  const [startDate, setStartDate] = useState( startDateSelected ? startDateSelected : null);

  useEffect(() => {}, [startDate]);

  const handleChangeDatePicker = (date) => {
    setStartDate(date);
  };

  return <>
  { mandatory ? (
    <DatePicker
      locale="es"
      dateFormat={Config[4].format}
      selected={startDate ? startDate : startDateSelected}
      onChange={handleChangeDatePicker}
      maxDate={maxDate ? maxDate : ''}
      minDate={minDate ? minDate : ''}
      className="form-control"
      name={name ? name : ''}
      data-parsley-required="true"
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
      placeholderText={placeHolder}
      required
    />
  ) : (
    <DatePicker
      locale="es"
      dateFormat={Config[4].format}
      selected={startDate ? startDate : startDateSelected}
      onChange={handleChangeDatePicker}
      maxDate={maxDate}
      minDate={minDate}
      className="form-control"
      name={name}
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
      placeholderText={placeHolder}
    />
  )}
  </>
};

export default DatePickerAtm;
/*
{ mandatory ? (
    <DatePicker
      locale="es"
      dateFormat={Config[4].format}
      selected={startDate}
      onChange={handleChangeDatePicker}
      maxDate={maxDate ? maxDate : ''}
      className="form-control"
      name={name ? name : ''}
      data-parsley-required="true"
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
      placeholderText={placeHolder}
      required
    />
  ) : (
    <DatePicker
      locale="es"
      dateFormat={Config[4].format}
      selected={startDate}
      onChange={handleChangeDatePicker}
      maxDate={maxDate}
      className="form-control"
      name={name}
      data-parsley-required="true"
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
      placeholderText={placeHolder}
    />
  )}
*/
