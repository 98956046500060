import React, { useEffect } from "react";
//import { useHistory } from 'react-router-dom'

import Fetch from "./../../components/utils/Fetch";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Texto from "./../../data/es";
import PasosNavigationCambioRadicatoria from "../../components/utils/PasosNavigationCambioRadicatoria";

const FormDatosVehiculo = (props) => {
  const { datosTecnicos, numeroPlaca, datosVehiculo } = props;

  const fetch = new Fetch();
  fetch.setToast(toast);

  debugger
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="row">
      <PasosNavigationCambioRadicatoria
        titulo_paso1={"Datos Vehículo"}
        paso1_active={true}
        paso2_active={false}
        paso3_active={false}
      />

      <form
        action=""
        className="contact__form needs-validation"
        name="formDatosVehiculos"
        id="formDatosVehiculos"
        method="post"
        noValidate
        onSubmit={props.onSubmitForm}
        style={{ width: "100%" }}
      >
        <div className="card widget widget-simple w-100">
          <div className="card-body ">
            <div className="row ">
              <div className="col-12 widget-header">
                <h4 className="">{Texto.datos_identificacion_vehiculo}</h4>
              </div>
            </div>

            <div className="widget-content">
              <div className="widget-body">
                <div className="row ">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <p className="text-left">
                      <strong>Placa PTA: </strong>
                      <span>{numeroPlaca ? numeroPlaca.toUpperCase() : "--"}</span>
                    </p>
                  </div>

                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <p className="text-left">
                      <strong>Póliza: </strong>
                      <span>{datosVehiculo.numeroPoliza ? datosVehiculo.numeroPoliza : "--"}</span>
                    </p>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <p className="text-left">
                      <strong>Placa Anterior: </strong> <span>{datosVehiculo.numeroPlacaAnterior ? datosVehiculo.numeroPlacaAnterior : "--"}</span>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <p className="text-left">
                      <strong>Nro. Copia Válida Placa PTA: </strong>
                      <span></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card widget widget-simple w-100">
          <div className="card-body ">
            <div className="row ">
              <div className="col-12 widget-header">
                <h4 className="">{Texto.datos_tecnicos}</h4>
              </div>
            </div>

            <div className="widget-content">
              <div className="widget-body">
                <div className="row ">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <p className="text-left">
                      <strong>Clase: </strong>
                      <span>{datosTecnicos.clase ? datosTecnicos.clase : "--"}</span>
                    </p>
                  </div>

                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <p className="text-left">
                      <strong>Tipo: </strong>
                      <span>{datosTecnicos.tipo ? datosTecnicos.tipo : "--"}</span>
                    </p>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <p className="text-left">
                      <strong>Marca: </strong>{" "}
                      <span>{datosTecnicos.marca ? datosTecnicos.marca : "--"}</span>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <p className="text-left">
                      <strong>Modelo: </strong>
                      <span>{datosTecnicos.modelo ? datosTecnicos.modelo : "--"}</span>
                    </p>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <p className="text-left">
                      <strong>Pais: </strong>
                      <span>{datosTecnicos.pais ? datosTecnicos.pais : "--"}</span>
                    </p>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <p className="text-left">
                      <strong>Servicio: </strong>
                      <span>{datosTecnicos.servicio ? datosTecnicos.servicio : "--"}</span>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <p className="text-left">
                      <strong>Tracción: </strong>
                      <span>{datosTecnicos.traccion ? datosTecnicos.traccion : "--"}</span>
                    </p>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <p className="text-left">
                      <strong>Cilindrada (Cc.): </strong>
                      <span>{datosTecnicos.cilindrada ? datosTecnicos.cilindrada : "--"}</span>
                    </p>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <p className="text-left">
                      <strong>Color: </strong>
                      <span>{datosTecnicos.color ? datosTecnicos.color : "--"}</span>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <p className="text-left">
                      <strong>Tipo Carrocería: </strong>
                      <span>{datosTecnicos.tipoCarroceria ? datosTecnicos.tipoCarroceria : "--"}</span>
                    </p>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <p className="text-left">
                      <strong>Cap. Carga (Tn.): </strong>
                      <span>{datosTecnicos.capCarga ? datosTecnicos.capCarga : "--"}</span>
                    </p>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <p className="text-left">
                      <strong>Nro Plazas: </strong>
                      <span>{datosTecnicos.nroPlazas ? datosTecnicos.nroPlazas : "--"}</span>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <p className="text-left">
                      <strong>Radicatoría: </strong>
                      <span>{datosVehiculo.radicatoriaVehiculo ? datosVehiculo.radicatoriaVehiculo : "--"}</span>
                    </p>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <p className="text-left">
                      <strong>Tipo Vehículo: </strong>
                      <span>{}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12 col-md-12 col-lg-12 text-end">
            <div className=" search-btn button ">
              <input
                name="Siguiente"
                type="submit"
                className="btn btn-full-screen"
                value={props.buttonName}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormDatosVehiculo;
