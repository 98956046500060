import Config from './config';
export default [
    {
        title: "Actividades Económicas",
        src: Config[2].url + '/carrousel/IMPUESTOS-ACTECO-1600x500.jpg',
        href: "/",
        alt: "Actividades Económicas",
        class_active: '',
        target: "_self",
        key: 0
    },
    {
        title: "Inmuebles",
        src: Config[2].url + '/carrousel/IMPUESTOS-INM-1600x500.jpg',
        href: "/",
        alt: "Inmuebles",
        class_active: 'active',
        target: "_self",
        key: 4 
    },
];