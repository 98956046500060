import React, { useState, useEffect } from "react";
import DatePickerAtm from "../component/DatePickerAtm";
import "react-toastify/dist/ReactToastify.css";
import Texto from "../../data/es";
import Constant from "../../data/constant";
import Links from "../../data/link";

const ImtoTransferencia = (props) => {
  const { fetch, auth, userAuth, submitForm, token, numero, toast } = props;

  const [inmueble, setInmueble] = useState({});
  const [compradores, setCompradores] = useState({});
  const [dataFullInmueble, setDataFullInmueble] = useState({});
  const [visacionMinuta, setVisacionMinuta] = useState({});
  const [tipoVisacionMinuta, setTipoVisacionMinuta] = useState({});
  const [propietarioApoderado, setPropietarioApoderado] = useState({});
  const [showFormSentenciaJudicial, setShowFormSentenciaJudicial] =
    useState(false);
  const [documentoTransferencia, setDocumentoTransferencia] = useState({});
  const [usuarioCertificacion, setUsuarioCertificacion] = useState(undefined);
  const [usuarioPreliquidacion, setUsuarioPreliquidacion] = useState(undefined);
  const [propiedad, setPropiedad] = useState(undefined);
  const [message, setMessage] = useState("");
  const [showButtonImto, setShowButtonImto] = useState(true);

  useEffect(() => {
    if (auth.loggedIn()) {
      if (
        userAuth.code === Constant[0].grupos.funcionario ||
        userAuth.code === Constant[0].grupos.administrador
      ) {
        loadDataVisacionMinutaInmueble(token, numero);
        debugger;
        console.log(tipoVisacionMinuta);
        window.scrollTo(0, 0);
      } else window.location.href = Links[4].url;
    } else window.location.href = Links[4].url;
  }, []);

  const loadDataVisacionMinutaInmueble = async (token, numero) => {
    debugger;
    if (token && numero) {
      const response = await fetch.fetchGet(
        `api/visacion-minutas/by-token-and-numero/${token}/${numero}`
      );
      if (response && response.status) {
        debugger;
        const tipoVisacionMinuta = response.data.TipoVisacionMinuta;
        debugger;

        if (
          response.data.TipoVisacionMinuta.code === "ADJUDICACION_JUDICIAL" ||
          response.data.TipoVisacionMinuta.code === "COMPRA_VENTA_NORMAL" ||
          response.data.TipoVisacionMinuta.code ===
            "PROGRAMAS_CASA_SEGURA_MI_PLANO" ||
          response.data.TipoVisacionMinuta.code === "DIVORCIO"
        ) {
          if (
            response.data.ruatLocal.propietario &&
            response.data.ruatLocal.propietario.natural
          ) {
            setPropietarioApoderado(
              response.data.ruatLocal.propietario.natural
            );
          }

          if (
            response.data.ruatLocal.propietario &&
            response.data.ruatLocal.propietario.apoderado
          ) {
            setPropietarioApoderado(
              response.data.ruatLocal.propietario.apoderado
            );
          }

          setInmueble(response.data.Inmueble);
          setCompradores(response.data.Comprador);
          setDataFullInmueble(response.data.ruatLocal);
          setVisacionMinuta(response.data.VisacionMinuta);
          setTipoVisacionMinuta(response.data.TipoVisacionMinuta);
          setDocumentoTransferencia(response.data.DocumentoTransferenia);
          setPropiedad(response.data.Propiedad);

          if (response.data.VisacionMinuta.usuarioCertificacion) {
            setUsuarioCertificacion(
              response.data.VisacionMinuta.usuarioCertificacion
            );
          }

          if (response.data.VisacionMinuta.usuarioPreLiquidacion) {
            setUsuarioPreliquidacion(
              response.data.VisacionMinuta.usuarioPreLiquidacion
            );
          }

          if (
            tipoVisacionMinuta.name_form_transfer === "form_sentencia_judicial"
          ) {
            setShowFormSentenciaJudicial(true);
          } else {
            setShowFormSentenciaJudicial(false);
          }
        } else {
          setMessage(
            "La Visación no aplica para la Transferencia IMTO, exceptuando aquellas realizadas por Asociaciones Cíviles o Profesionales Independientes: (Ej. Fundaciones, ONG, Sindicatos, Religiosas, etc.)"
          );
        }
      }
    }
  };

  const submitFormMinuta = async (event) => {
    debugger;
    const formDom = event.target.closest("form");
    if (formDom) window.inputConvertUpperCaseForm(formDom.getAttribute("id"));
    let isValidForm = true;
    let form = undefined;
    if (formDom) {
      form = new FormData(formDom);
      window
        .jQuery("#" + formDom.getAttribute("id"))
        .parsley()
        .validate();
      isValidForm = window
        .jQuery("#" + formDom.getAttribute("id"))
        .parsley()
        .isValid();
    } else form = new FormData();

    if (isValidForm) {
      form.append("vm_visacion_minutas[token]", visacionMinuta.token);

      window.createBootbox(
        "Esta Seguro de Continuar.",
        async function (result) {
          if (result === true) {
            setShowButtonImto(false);
            const response = await fetch.fetchPost(
              form,
              "api/visacion-minutas/imto/create",
              undefined
            );
            debugger;
            if (response && response.status) {
              const data = {
                inmueble: inmueble,
                propiedad: propiedad,
                visacionMinuta: visacionMinuta,
              };
              submitForm(1, data);
            }
            setShowButtonImto(true);
          }
        }
      );
    } else {
      toast.warn("El formulario tiene valores obligatorios", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <>
      {message ? (
        <div className="row">
          <div className="col-12">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div
            className={showFormSentenciaJudicial ? "col-12 col-md-8" : "col-12"}
          >
            <div className="card widget widget-simple">
              <div className="card-body ">
                <div className="row ">
                  <div className="col-12 col-md-8 widget-header mb-3">
                    <h4 className="text-maroon-flush">
                      <i className="fas fa-bookmark me-2"></i> {Texto.inmueble}
                    </h4>
                  </div>
                </div>

                <div className="widget-content">
                  <div className="widget-body">
                    <div className="row">
                      <div className="col-12 col-md-12 mb-1">
                        <strong className="fs-4">Datos de la Visación</strong>
                        <hr className="mt-0 " />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>No. Orden: </strong>
                          <span>
                            {visacionMinuta ? visacionMinuta.numero : ""}
                          </span>
                        </p>
                      </div>

                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Fur: </strong>
                          <span>
                            {visacionMinuta ? visacionMinuta.fur : ""}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Aprobado: </strong>
                          <span>
                            {visacionMinuta && visacionMinuta.fecha_aprobacion
                              ? window
                                  .moment(visacionMinuta.fecha_aprobacion)
                                  .format("YYYY/MM/DD HH:mm:ss ")
                              : "--"}
                          </span>
                        </p>
                      </div>

                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Tipo Visación: </strong>
                          <span>
                            {tipoVisacionMinuta ? tipoVisacionMinuta.name : ""}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-12 mt-3">
                        <strong className="fs-4">Datos de la Propiedad</strong>
                        <hr className="mt-0 " />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Nro. Inmueble: </strong>
                          <span>{inmueble ? inmueble.numero_ruat : ""}</span>
                        </p>
                      </div>

                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Cod. Cat.: </strong>
                          <span style={{ fontSize: "15" }}>
                            {inmueble ? inmueble.catastro_ruat : ""}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Matrícula Comput.: </strong>
                          <span>{inmueble ? inmueble.matricula_gamc : ""}</span>
                        </p>
                      </div>

                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Asiento: </strong>
                          <span>{inmueble ? inmueble.asiento_gamc : ""}</span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          {dataFullInmueble &&
                          dataFullInmueble.claseInmueble ===
                            "PROPIEDAD HORIZONTAL" ? (
                            <>
                              <strong>Sup. Construcción: </strong>
                              <span>
                                {dataFullInmueble &&
                                dataFullInmueble.dataTecnico
                                  ? dataFullInmueble.dataTecnico
                                      .construcciones[0].superficieM2
                                  : ""}
                              </span>
                            </>
                          ) : (
                            <>
                              <strong>Sup. Terreno/Catastro m2: </strong>
                              <span>
                                {dataFullInmueble &&
                                dataFullInmueble.dataTecnico
                                  ? dataFullInmueble.dataTecnico.terreno[0]
                                      .inclinacion
                                  : ""}
                              </span>
                            </>
                          )}
                        </p>
                      </div>

                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Tipo Propiedad: </strong>
                          <span>
                            {dataFullInmueble && dataFullInmueble.dataTecnico
                              ? dataFullInmueble.dataTecnico.datosGenerales
                                  .tipoPropiedad
                              : ""}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Clase: </strong>
                          <span>
                            {dataFullInmueble && dataFullInmueble.dataTecnico
                              ? dataFullInmueble.dataTecnico.datosGenerales
                                  .clase
                              : ""}
                          </span>
                        </p>
                      </div>

                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Area: </strong>
                          <span>
                            {dataFullInmueble && dataFullInmueble.dataTecnico
                              ? dataFullInmueble.dataTecnico.datosGenerales.area
                              : ""}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Zona Tributaria: </strong>
                          <span>
                            {dataFullInmueble && dataFullInmueble.dataTecnico
                              ? dataFullInmueble.dataTecnico.datosGenerales
                                  .zonaTributaria
                              : ""}
                          </span>
                        </p>
                      </div>

                      {dataFullInmueble &&
                      dataFullInmueble.claseInmueble !==
                        "PROPIEDAD HORIZONTAL" ? (
                        <div className="col-12 col-md-6 col-lg-6 ">
                          <p className="text-left">
                            <strong>Inclinación: </strong>
                            <span>
                              {dataFullInmueble && dataFullInmueble.dataTecnico
                                ? dataFullInmueble.dataTecnico.terreno[0]
                                    .inclinacion
                                : ""}
                            </span>{" "}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    {dataFullInmueble &&
                    dataFullInmueble.claseInmueble !==
                      "PROPIEDAD HORIZONTAL" ? (
                      <>
                        <div className="row">
                          <div className="col-12 col-md-6 col-lg-6 ">
                            <p className="text-left">
                              <strong>Ubicación: </strong>
                              <span>
                                {dataFullInmueble &&
                                dataFullInmueble.dataTecnico
                                  ? dataFullInmueble.dataTecnico.terreno[0]
                                      .ubicacion
                                  : ""}
                              </span>
                            </p>
                          </div>

                          <div className="col-12 col-md-6 col-lg-6 ">
                            <p className="text-left">
                              <strong>Materíal Vía: </strong>
                              <span>
                                {dataFullInmueble &&
                                dataFullInmueble.dataTecnico
                                  ? dataFullInmueble.dataTecnico.terreno[0]
                                      .materialVia
                                  : ""}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-12 col-lg-12 ">
                            <p className="text-left">
                              <strong>Servicios: </strong>
                              <span>
                                {dataFullInmueble &&
                                dataFullInmueble.dataTecnico
                                  ? dataFullInmueble.dataTecnico.terreno[0]
                                      .servicios
                                  : ""}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-12 col-lg-12 ">
                            <p className="text-left"></p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 ">
                          <p className="text-left">
                            <strong>Construcciones: </strong>
                            <span>
                              {dataFullInmueble && dataFullInmueble.dataTecnico
                                ? dataFullInmueble.dataTecnico.construcciones
                                    .length
                                : ""}
                            </span>
                          </p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 ">
                          <p className="text-left">
                            <strong>Const. Adicionales: </strong>
                            <span>
                              {dataFullInmueble && dataFullInmueble.dataTecnico
                                ? dataFullInmueble.dataTecnico
                                    .construccionesAdicionales.length
                                : ""}
                            </span>
                          </p>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-12 col-md-12 mt-3">
                        <strong className="fs-4">Datos del Vendedor</strong>
                        <hr className="mt-0 " />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-12 ">
                        <p className="text-left">
                          <strong>Nombre(s): </strong>
                          <span>
                            {propietarioApoderado
                              ? propietarioApoderado.nombres +
                                " " +
                                propietarioApoderado.primerApellido +
                                " " +
                                propietarioApoderado.segundoApellido
                              : ""}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Tipo Documento: </strong>
                          <span>
                            {propietarioApoderado
                              ? propietarioApoderado.tipoDocumento
                              : ""}
                          </span>
                        </p>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Número Documento: </strong>
                          <span>
                            {propietarioApoderado
                              ? propietarioApoderado.numeroDocumento
                              : ""}
                            {propietarioApoderado.expedido
                              ? " " + propietarioApoderado.expedido
                              : ""}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Género: </strong>
                          <span>
                            {propietarioApoderado
                              ? propietarioApoderado.genero
                              : ""}
                          </span>
                        </p>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Estado Civil: </strong>
                          <span>
                            {propietarioApoderado
                              ? propietarioApoderado.estadoCivil
                              : ""}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>PMC: </strong>
                          <span>
                            {dataFullInmueble && dataFullInmueble.propietario
                              ? dataFullInmueble.propietario.pmc
                              : ""}
                          </span>
                        </p>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Copropietarios: </strong>
                          <span>
                            {dataFullInmueble && dataFullInmueble.propietario
                              ? dataFullInmueble.propietario.copropietarios
                                  .length
                              : ""}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-12 mt-3">
                        <strong className="fs-4">Datos del Comprador</strong>
                        <hr className="mt-0 " />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-12 ">
                        <p className="text-left">
                          <strong>Nombre(s): </strong>
                          <span>
                            {compradores && compradores.Persona
                              ? compradores.Persona.nombre +
                                " " +
                                compradores.Persona.apellido_paterno +
                                " " +
                                compradores.Persona.apellido_materno
                              : ""}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Tipo Documento: </strong>
                          <span>
                            {compradores && compradores.Persona
                              ? compradores.Persona.tipoDocumento.name
                              : ""}
                          </span>
                        </p>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Nro. Documento: </strong>
                          <span>
                            {compradores && compradores.Persona
                              ? compradores.Persona.numero_documento
                              : ""}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Género: </strong>
                          <span>
                            {compradores && compradores.Persona
                              ? compradores.Persona.genero.name
                              : ""}
                          </span>
                        </p>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Estado Civil: </strong>
                          <span>
                            {compradores && compradores.Persona
                              ? compradores.Persona.estadoCivil.name
                              : ""}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>PMC: </strong>
                          <span>
                            {compradores && compradores.Persona
                              ? compradores.Persona.pmc
                              : ""}
                          </span>
                        </p>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Copropietarios: </strong>
                          <span>
                            {compradores && compradores.copropietarios
                              ? compradores.copropietarios.length
                              : ""}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-12 mt-3">
                        <strong className="fs-4">
                          Documentos Transferencia
                        </strong>
                        <hr className="mt-0 " />
                      </div>
                    </div>

                    {tipoVisacionMinuta.code ===
                    "FRACCIONAMIENTO_ANEXION_ADECUACION_PH" ? (
                      <>
                        <div className="row">
                          <div className="col-12 col-md-6 col-lg-6 ">
                            <p className="text-left">
                              <strong>Fecha: </strong>
                              <span>
                                {documentoTransferencia &&
                                documentoTransferencia.date
                                  ? window
                                      .moment(documentoTransferencia.date)
                                      .format("DD/MM/YYYY ")
                                  : " "}
                              </span>
                            </p>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6 ">
                            <p className="text-left">
                              <strong>Numero Testimonio/RTA: </strong>
                              <span>
                                {documentoTransferencia &&
                                documentoTransferencia.numero
                                  ? documentoTransferencia.numero
                                  : ""}
                              </span>
                            </p>
                          </div>
                        </div>

                        {documentoTransferencia &&
                        documentoTransferencia.tipoDocumentoTransferencia &&
                        documentoTransferencia.tipoDocumentoTransferencia
                          .code === "TESTIMONIO_PUBLICO" ? (
                          <div className="row">
                            <div className="col-12 col-md-6 col-lg-6 ">
                              <p className="text-left">
                                <strong>Nombre Notario: </strong>
                                <span>
                                  {documentoTransferencia &&
                                  documentoTransferencia.notario
                                    ? documentoTransferencia.notario
                                    : ""}
                                </span>
                              </p>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 ">
                              <p className="text-left">
                                <strong>Número de Notaria: </strong>
                                <span>
                                  {documentoTransferencia &&
                                  documentoTransferencia.numero_notario
                                    ? documentoTransferencia.numero
                                    : ""}
                                </span>
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 ">
                          <p className="text-left">
                            <strong>Monto: </strong>
                            <span>
                              {documentoTransferencia &&
                              documentoTransferencia.amount
                                ? documentoTransferencia.amount +
                                  " " +
                                  (documentoTransferencia.divisa.code === "BOB"
                                    ? "BS"
                                    : "US")
                                : ""}
                            </span>
                          </p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 ">
                          <p className="text-left">
                            <strong>Fecha: </strong>
                            <span>
                              {documentoTransferencia &&
                              documentoTransferencia.date
                                ? window
                                    .moment(documentoTransferencia.date)
                                    .format("DD/MM/YYYY ")
                                : ""}
                            </span>
                          </p>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-12 col-md-12 mt-3">
                        <strong className="fs-4">
                          Datos de la Certificación
                        </strong>
                        <hr className="mt-0 " />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Fur Preliquidación: </strong>
                          <span>
                            {visacionMinuta && visacionMinuta.fur_preliquidacion
                              ? visacionMinuta.fur_preliquidacion
                              : ""}
                          </span>
                        </p>
                      </div>

                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Usuario PreLiquidación: </strong>
                          <span>
                            {usuarioPreliquidacion
                              ? usuarioPreliquidacion.username
                              : ""}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Fecha: </strong>
                          <span>
                            {visacionMinuta &&
                            visacionMinuta.fecha_certificacion
                              ? window
                                  .moment(visacionMinuta.fecha_certificacion)
                                  .format("DD/MM/YYYY ")
                              : ""}
                          </span>
                        </p>
                      </div>

                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Usuario: </strong>
                          <span>
                            {usuarioCertificacion
                              ? usuarioCertificacion.username
                              : ""}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6 ">
                        <p className="text-left">
                          <strong>Impresiones: </strong>
                          <span>
                            {visacionMinuta &&
                            visacionMinuta.nro_impresiones_certificado
                              ? visacionMinuta.nro_impresiones_certificado
                              : ""}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showFormSentenciaJudicial ? (
            <div className="col-12 col-md-4">
              <div className="card widget widget-simple">
                <div className="card-body ">
                  <div className="row ">
                    <div className="col-12 col-md-12 widget-header mb-3">
                      <h4 className="text-maroon-flush">
                        <i className="fas fa-bookmark me-2"></i>{" "}
                        {Texto.sentencia_judicial}
                      </h4>
                    </div>
                  </div>

                  <div className="widget-content">
                    <div className="widget-body">
                      <form
                        action=""
                        className="contact__form needs-validation"
                        name="formSentenciaJudicial"
                        id="formSentenciaJudicial"
                        method="post"
                        noValidate
                        style={{ width: "100%" }}
                      >
                        <div className="row">
                          <div className="col-12 col-md-12 mb-3">
                            <label
                              htmlFor={"vm_sentencia_judicial[register_judge]"}
                              className="form-label"
                            >
                              Registro Judicial *
                            </label>
                            <input
                              name={"vm_sentencia_judicial[register_judge]"}
                              id={"vm_sentencia_judicial[register_judge]"}
                              type="text"
                              className="form-control input-uppercase"
                              placeholder="Registro Judicial"
                              required
                              data-parsley-required="true"
                              pattern="^[a-zA-Z0-9 ]+"
                              data-parsley-pattern="^[a-zA-Z0-9 ]+"
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-12 mb-3">
                            <label
                              htmlFor={"vm_sentencia_judicial[name_judge]"}
                              className="form-label"
                            >
                              Juez *
                            </label>
                            <input
                              name={"vm_sentencia_judicial[name_judge]"}
                              id={"vm_sentencia_judicial[name_judge]"}
                              type="text"
                              className="form-control input-uppercase"
                              placeholder="Nombre del Juez"
                              required
                              data-parsley-required="true"
                              pattern="^[a-zA-Z ]+"
                              data-parsley-pattern="^[a-zA-Z ]+"
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-12 mb-3">
                            <label
                              htmlFor={"vm_sentencia_judicial[number]"}
                              className="form-label"
                            >
                              Número *
                            </label>
                            <input
                              name={"vm_sentencia_judicial[number]"}
                              id={"vm_sentencia_judicial[number]"}
                              type="text"
                              className="form-control input-uppercase"
                              placeholder="Número de Sentencia"
                              required
                              data-parsley-required="true"
                              pattern="^[a-zA-Z0-9 ]+"
                              data-parsley-pattern="^[a-zA-Z0-9 ]+"
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-12 col-lg-12 mb-3">
                            <label
                              htmlFor={"vm_sentencia_judicial[date]"}
                              className="form-label"
                            >
                              Fecha *
                            </label>
                            <DatePickerAtm
                              placeHolder={"Fecha Sentencia"}
                              name={"vm_sentencia_judicial[date]"}
                              maxDate={new Date()}
                              mandatory={true}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-12 mt-4 text-end">
                            <div className=" search-btn button mb-3">
                              <input
                                type="button"
                                className="btn btn-full-screen"
                                defaultValue={"Registrar IMTO"}
                                onClick={submitFormMinuta}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : showButtonImto ? (
            <div className="row">
              <div className="col-12 col-md-12 mt-4 text-end">
                <div className=" search-btn button mb-3">
                  <input
                    type="button"
                    className="btn btn-full-screen"
                    defaultValue={"Registrar IMTO"}
                    onClick={submitFormMinuta}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

export default ImtoTransferencia;
