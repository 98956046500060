import React, { Component } from 'react';
import Links from '../../data/link';
//import Config from '../../data/config';
import TitlePage from '../../components/utils/TitlePage';
import Texto from '../../data/es';
import Constant from '../../data/constant';
import AuthService from '../../components/Usuario/AuthService';
import Fetch from '../../components/utils/Fetch';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
//import styled from 'styled-components';  //styled for data table
import { Link } from 'react-router-dom';
import ModalIntent from './ModalIntentos'
import '../style/parsley.css';
import CustomLoader from '../utils/SpinnerDataTable'

const columns = [
    {
        name: Texto.usuario,
        selector: 'username',   //npm uninstall <moduleName> --save 
        sortable: true,
        grow: 1,
        maxWidth: '180px',
    },
    /*{
        name: Texto.ci,
        selector: 'dato_ci',
        sortable: true,
        hide: 'md',
        maxWidth: '140px'
    },*/
    {
        name: Texto.nombre,
        center: false,
        sortable: true,
        maxWidth: '450px',
        hide: 'md',
        cell: row => <div>
            {(row.dato_name !== null ? row.dato_name : "") + " " + (row.dato_apellido_paterno !== null ? row.dato_apellido_paterno : "") + " " + (row.dato_apellido_materno !== null ? row.dato_apellido_materno : "")}
        </div>
    },
    {
        name: Texto.intentos_fur,
        selector: 'intentos_fur',
        sortable: true,
        center: true,
        hide: 'sm',
        maxWidth: '50px'
    },
    {
        name: Texto.estado,
        center: true,
        sortable: true,
        maxWidth: '50px',
        cell: row => <div>
            {row.estado_code === Constant[0].estado.pendiente_aprobacion ? <span title={row.estado_code}><i className="fa fa-cog" aria-hidden="true" ></i></span> : ""}
            {row.estado_code === Constant[0].estado.activo ? <span title={row.estado_code}><i className="fa fa-check" aria-hidden="true"></i></span> : ""}
            {row.estado_code === Constant[0].estado.bloqueado ? <span title={row.estado_code}><i className="fa fa-ban" aria-hidden="true"></i></span> : ""}
            {row.estado_code === Constant[0].estado.eliminado ? <span title={row.estado_code}><i className="fa fa-ban" aria-hidden="true"></i></span> : ""}
        </div>
    },
    {
        name: 'Creado',
        selector: 'created_at',
        sortable: true,
        hide: 'md',
        maxWidth: '140px',
    },
    {
        name: '',
        sortable: true,
        cell: row => <div>
            {row.permissions.includes(Constant[0].permission.update) && row.estado_code === Constant[0].estado.activo ?
                <Link to="#" onClick={() => handleOpenNumIntentos(row)} style={{ fontSize: '18px', marginRight: '10px' }} title={`Incrementar el número de intentos para la cuenta - ` + row.username}  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                </Link>
                : ""}

            {row.permissions.includes(Constant[0].permission.update) && (row.estado_code === Constant[0].estado.pendiente_aprobacion || row.estado_code === Constant[0].estado.bloqueado) ?
                <Link to="#" onClick={() => handleActiveAccount(row)} style={{ fontSize: '18px', marginRight: '10px' }} title={`Activar cuenta - ` + row.username}  >
                    <i className="fa fa-check" aria-hidden="true"></i>
                </Link>
                : ""}

            {row.permissions.includes(Constant[0].permission.update) && row.estado_code === Constant[0].estado.activo ?
                <Link to="#" onClick={() => handleBlockAccount(row)} style={{ fontSize: '18px', marginRight: '10px' }} title={`Bloquear la cuenta - ` + row.username}  >
                    <i className="fa fa-ban" aria-hidden="true"></i>
                </Link>
                : ""}
        </div>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    }
];

const handleOpenNumIntentos = (row) => {

    var self = _usuario
    modal_intent.show(row.token, row.username)
}

const handleActiveAccount = (row) => {
    alert("trabajando")
}

const handleBlockAccount = (row) => {
    alert("trabajando");
}

var fetch = null
var _usuario = null
var modal_intent = null
class Usuario extends Component {

    constructor(props) {
        super(props);

        this.fetch = new Fetch();
        this.fetch.setToast(toast);
        this.Auth = new AuthService();
        fetch = this.fetch

        this.modal_intent = new ModalIntent()
        modal_intent = this.modal_intent

        this.titlePage = Texto.usuarios
        _usuario = this
        this.handleSubmitSearchForm = this.handleSubmitSearchForm.bind(this)
        this.handleEventRefrestTable = this.handleEventRefrestTable.bind(this)

        this.state = {
            data: [],
            loading: false,
            totalRows: 0,
            perPage: 10,
            type_search: 0,
            showSearch: true
        };
    }

    async componentDidMount() {

        if (this.Auth.loggedIn()) {
            let usuario = this.Auth.getProfile()
            if (usuario.code.toUpperCase() === Constant[0].grupos.contribuyente.toUpperCase())
                this.props.history.replace(Links[4].url)

            const { perPage } = this.state;
            this.setState({ loading: true });  //api/usuario/per-page/(:num)/(:num)/(:num)
            const response = await this.fetch.fetchGet(`api/usuario/per-page/1/${perPage}/${this.state.type_search}`);
            if (response !== null && response.status === true) {
                this.setState({
                    data: response.data,
                    totalRows: response.total,
                    loading: false,
                });

                document.getElementById("pPendiente").innerHTML = "Pendiente - " + response.pendientes
                document.getElementById("pActivo").innerHTML = "Activo - " + response.activos
                document.getElementById("pBloqueado").innerHTML = response.bloqueados !== undefined ? "Bloqueado - " + response.bloqueados : 0

                toast.success(response.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }
        } else
            this.props.history.replace(Links[4].url)

        window.jQuery(".sc-kAzzGY").remove()  //pertenece al datatable
    }

    async hanldeSearchUsuarios(event, type_search) {

        const { perPage } = this.state;
        this.setState({ loading: true });
        const response = await this.fetch.fetchGet(`api/usuario/per-page/1/${perPage}/${type_search}`);
        if (response !== null && response.status === true) {
            this.setState({
                data: response.data,
                totalRows: response.total,
                loading: false,
                type_search: type_search
            });
        }
    }

    handlePageChange = async page => {
        const { perPage } = this.state;
        this.setState({ loading: true });

        const response = await this.fetch.fetchGet(`api/usuario/per-page/${page}/${perPage}/${this.state.type_search}`);
        this.setState({
            loading: false,
            data: response.data,
        });
    };

    handlePerRowsChange = async (perPage, page) => {
        this.setState({ loading: true });

        const response = await this.fetch.fetchGet(`api/usuario/per-page/${page}/${perPage}/${this.state.type_search}`);
        this.setState({
            loading: false,
            data: response.data,
            perPage,
        });
    };

    handleRedirectUrlClick(ev, url) {
        window.location.href = url;
    }

    handleSubmitSearchForm(event) {
        event.preventDefault()
        const form = new FormData(event.target);
        var self = this
        const { perPage } = this.state;
        self.setState({ loading: true });
        let input = window.jQuery(event.target).find('input').val()
        if (input.length > 0) {
            this.fetch.fetchPost(form, `api/usuario/search`).then(res => {
                if (res !== undefined && res.status === true) {
                    self.setState({
                        loading: false,
                        data: res.data,
                        totalRows: res.total,
                        perPage,
                    });
                }
            })
        } else
            window.location.reload();
    }

    async handleEventRefrestTable(event) {
        const { perPage } = this.state;
        this.setState({ loading: true });
        const response = await this.fetch.fetchGet(`api/usuario/per-page/1/${perPage}/${this.state.type_search}`);
        if (response !== null && response.status === true) {
            this.setState({
                data: response.data,
                totalRows: response.total,
                loading: false,
            });
        }
    }

    handleRedirectUrlClick(ev, url) {
        window.location.href = url;
    }


    render() {
        const { loading, data, totalRows } = this.state

        const breadcrumbs = [
            {
                title: Links[0].title,
                url: Links[0].url
            },
            {
                title: Links[12].title,
                url: Links[12].url
            }
        ];
        return (
            <section className="items-grid section mt-5">
                <div className="container mt-4">
                    <TitlePage titlePage={this.titlePage} breadcrumbs={breadcrumbs} position={'left'} leftfull={false} />

                    <section className="panel-menu-info">
                        <div className="panel-menu-info-content">
                            <div className="row">
                                <div className="col-4 col-md-2 col-lg-2">
                                    <div className="single-contact-info pointer" onClick={e => this.hanldeSearchUsuarios(e, 1)}>
                                        <i className="fa fa-cog" aria-hidden="true"></i>
                                        <p id="pPendiente">Pendiente(s) - 0</p>
                                    </div>
                                </div>
                                <div className="col-4 col-md-2 col-lg-2">
                                    <div className="single-contact-info pointer" onClick={e => this.hanldeSearchUsuarios(e, 2)}>
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                        <p id="pActivo">Activo(s) - 0</p>
                                    </div>
                                </div>
                                <div className="col-4 col-md-2 col-lg-2">
                                    <div className="single-contact-info pointer" onClick={e => this.hanldeSearchUsuarios(e, 3)}>
                                        <i className="fa fa-ban" aria-hidden="true"></i>
                                        <p id="pBloqueado">Bloqueado(s) - 0</p>
                                    </div>
                                </div>
                                <div className="col-4 col-md-2 col-lg-2">
                                    <div className="single-contact-info pointer" onClick={e => this.handleRedirectUrlClick(e, Links[14].url)} >
                                        <i className="fas fa-chart-line" aria-hidden="true"></i>
                                        <p id="pBloqueado">Soporte Gerencial</p>
                                    </div>
                                </div>
                                <div className="col-4 col-md-2 col-lg-2">
                                    <div className="single-contact-info " >
                                    </div>
                                </div>
                                <div className="col-4 col-md-2 col-lg-2">
                                </div>
                            </div>
                        </div>
                    </section>

                    {
                        this.state.showSearch ?
                            <form action="" className="contact__form center-login" name="formSearchDataTable" id="formSearchDataTable"
                                method="post" noValidate onSubmit={this.handleSubmitSearchForm} >
                                <div className="row" style={{ textAlign: 'right', marginRight: '0px', marginLeft: '0px' }}>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 ">
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 ">
                                        <div className="input-group mb-3" style={{ marginBottom: '0rem !important' }}>
                                            <div className="input-group-prepend">
                                                <select className="form-select" name="type" required data-parsley-required="true" >
                                                    <option key={0} value="USERNAME">Usuario</option>
                                                    <option key={1} value="CI">CI</option>
                                                </select>
                                            </div>
                                            <input type="text" name="search" id="search" className="form-control" placeholder="*" aria-label="Username" aria-describedby="basic-addon1" />
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="submit" data-toggle="tooltip" data-placement="top"
                                                    title="Buscar"><i className="fa fa-search" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            : ""
                    }
                    <DataTable
                        title={this.titlePage}
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        noDataComponent={Texto.there_are_no_records_to_display}
                        highlightOnHover
                        progressComponent={<CustomLoader />}
                    />
                </div>

                <ModalIntent closeEvent={this.handleEventRefrestTable} />
            </section>
        );
    }
}
export default Usuario;