import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Languaje from "../../../data/es";
import TableColindantes from "./TableColindantes";
import MapCatastroPublic from "../MapCatastroPublic";
import Links from "../../../data/link";
import PopOverCustom from "./../PopOverCustom";

const CardDataAdministration = (props) => {
  const {
    dataInmueble,
    nameFormInmueble,
    dataInmuebleCatastro,
    fetch,
    onEventData,
    ruleValidation,
    isVisacionMinuta,
    handleInputProgramas,
  } = props;

  //const [dataMap, setDataMap] = useState(false);
  const [image64, setImage64] = useState(false);

  useEffect(() => {
    if (dataInmueble.codigoCatastral) {
      if (
        dataInmueble.claseInmueble === "PROPIEDAD HORIZONTAL" ||
        dataInmueble.claseInmueble === "PH"
      ) {
        let array_codigo_cat = dataInmueble.codigoCatastral.split("-");
        let value =
          array_codigo_cat[0] +
          "" +
          array_codigo_cat[1] +
          "" +
          array_codigo_cat[2];
        paintMap(
          `catastro/predios_cba/MapServer/0/query?f=json&where=UPPER(CodCat)%20LIKE%20%27%25${value}%25%27&returnGeometry=true&spatialRel=esriSpatialRelIntersects&maxAllowableOffset=0.13229193125052918&outFields=*&outSR=32719&resultRecordCount=12`
        );
      } else {
        let value = dataInmueble.codigoCatastral.replace(/-/g, "");
        paintMap(
          `catastro/predios_cba/MapServer/0/query?f=json&where=UPPER(CodCat)%20LIKE%20%27%25${value}%25%27&returnGeometry=true&spatialRel=esriSpatialRelIntersects&maxAllowableOffset=0.13229193125052918&outFields=*&outSR=32719&resultRecordCount=12`
        );
      }
    } else {
      debugger;
      setImage64(dataInmuebleCatastro.imageCatastro.base64Image);
      document.getElementById("mapUbicacionActividadEconomica").innerHTML = "";
      const coordinate = dataInmuebleCatastro.imageCatastro.coordinate;
      const coordinateArray =
        dataInmuebleCatastro.imageCatastro.coordinate.split(",");
      const coordenasWGS84 = window.getUniversalFormatCoodinate([
        coordinateArray[0] + "," + coordinateArray[1],
      ]);
      dataInmuebleCatastro.codigoCatastral = "--";
      dataInmuebleCatastro.superficiePredio = "0";

      let latLon = window.ol.proj.toLonLat(coordinateArray); //devuelve longitud, latitud de coordenas catastro
      const data = {
        codCatastral: dataInmuebleCatastro.codCatastral ? dataInmuebleCatastro.codCatastral : "CODCG"+Math.floor(Math.random() * 10000000),
        comuna: "NO DEFINIDO",
        distrito: "0",
        subDistrito: "NO DEFINIDO",
        nroSubDistrito: "0",
        predio: "0",
        nroInmueble: dataInmuebleCatastro.nroInmueble ? dataInmuebleCatastro.nroInmueble : "NROIG"+Math.floor(Math.random() * 10000000),
        nroManzana: "0",
        zonaTributaria: "0",
        coordinate: coordinate,
        latitud: latLon[1],
        longitud: latLon[0],
        latitudWGS84: coordenasWGS84[1],
        longitudWGS84: coordenasWGS84[0],
        image64: dataInmuebleCatastro.imageCatastro.base64Image,
      };
      onEventData(data);
    }

    if (
      dataInmuebleCatastro &&
      dataInmuebleCatastro.informesTecnico.length > 0
    ) {
      ruleValidation(
        undefined,
        dataInmuebleCatastro.informesTecnico[0].fechaAprobacionPlano,
        "FECHA_APROBACION_PLANO"
      );
    } else ruleValidation(undefined, undefined, "FECHA_APROBACION_PLANO");
  }, []);

  const paintMap = (relative_url) => {
    const predios_ = fetch.fetchGetExternal(`${Links[30].url}${relative_url}`);
    predios_.then((res) => {
      if (res && res.hasOwnProperty("features")) {
        const castratoMap = new MapCatastroPublic();
        if (res.features.length > 0) {
          let features = res.features[0];
          let center = window.getCenterCoorderdanada(
            features.geometry.rings[0]
          );

          castratoMap.props = {
            width: 724,
            height: 398,
          };
          castratoMap.captureImage(center, (dataUrl) => {
            debugger;
            const base64 = dataUrl.split(",")[1];

            setImage64(base64);
            document.getElementById(
              "mapUbicacionActividadEconomica"
            ).innerHTML = "";
            const coordenasWGS84 = window.getUniversalFormatCoodinate([
              center[0] + "," + center[1],
            ]);

            let latLon = window.ol.proj.toLonLat(center); //devuelve longitud, latitud
            const data = {
              codCatastral: features.attributes.CodCat,
              comuna: features.attributes.comuna,
              distrito: features.attributes.distrito,
              subDistrito: features.attributes.Sbdistrito,
              nroSubDistrito: features.attributes.Sbdist_Nro,
              predio: features.attributes.Nro_predio,
              nroInmueble: features.attributes.NoInmueble,
              nroManzana: features.attributes.Nro_manzan,
              zonaTributaria: features.attributes.ZTributari,
              coordinate: center[0] + "," + center[1],
              latitud: latLon[1],
              longitud: latLon[0],
              latitudWGS84: coordenasWGS84[1],
              longitudWGS84: coordenasWGS84[0],
              image64: base64,
            };
            onEventData(data);
            castratoMap.hiddenMap();
            debugger;
            //setDataMap(data);
          });
        } else {
          castratoMap.hiddenMap();
          ruleValidation(undefined, undefined, "MAPA_CATASTRO");
        }
      }
    });
  };

  const handleEventInputPopOver = (event, value, codeAtribute) => {
    //event.preventDefault();
    debugger;
    const data = {};
    if (codeAtribute === "MATRICULA") data.matricula = value;

    if (codeAtribute === "ASIENTO") data.asiento = value;

    if (codeAtribute === "FECHA_APROBACION_PLANO")
      data.fecha_aprobacion_plano = value;

    if (codeAtribute === "FECHA_RESOLUCION_PLANO")
      data.fecha_resolucion_plano = value;

    if (codeAtribute === "RTA_NRO") data.rta_nro = value;
    handleInputProgramas(event, data);
  };

  return (
    <>
      <div className="card widget widget-simple">
        <div className="card-body ">
          <div className="row ">
            <div className="col-12 col-md-6 widget-header mb-3">
              <h4 className="text-maroon-flush">
                <i className="fas fa-bookmark me-2"></i>{" "}
                {Languaje.datos_administrativos_legales}
              </h4>
            </div>
            <div className="col-4 col-md-6 widget-header mb-3"></div>
          </div>

          <div className="widget-content">
            <div className="widget-body">
              <div className="row ">
                <div className="col-12 col-md-5 col-lg-5 ">
                  <h5 className="">{Languaje.datos_catastro.toUpperCase()}</h5>
                </div>

                <div className="col-12 col-md-7 col-lg-7 ">
                  <h5 className="">
                    {Languaje.imagen_catastral.toUpperCase()}
                  </h5>
                </div>
              </div>

              <div className="row ">
                <div className="col-sm-12 col-md-5 col-lg-5 ">
                  <p className="text-left">
                    <strong>Cod. Cat.: </strong>
                    <span>
                      {dataInmueble && dataInmueble.codigoCatastral
                        ? dataInmueble.codigoCatastral
                        : "--"}
                    </span>
                  </p>
                </div>

                <div className="col-sm-12 col-md-7 col-lg-7 ">
                  <div className="d-inline-block float-start">
                    <p className="text-left">
                      <strong>Nro. Inmueble: </strong>
                      <span>
                        {dataInmueble && dataInmueble.numeroInmueble
                          ? dataInmueble.numeroInmueble
                          : "--"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="row ">
                {isVisacionMinuta ? (
                  <div className="col-sm-12 col-md-5 col-lg-5 ">
                    <div className="d-inline-block">
                      <p className="text-left">
                        <strong>Matrícula Comput.: </strong>
                        <span>
                          {" "}
                          {dataInmuebleCatastro
                            ? dataInmuebleCatastro.informesLegal[0].matricula
                            : ""}
                        </span>
                      </p>
                    </div>

                    {dataInmueble.tipoVisacionMinuta.code ===
                    "PROGRAMAS_CASA_SEGURA_MI_PLANO" ? (
                      <div className="ps-2 d-inline-block">
                        <PopOverCustom
                          id="catastroMatricula"
                          title="Matrícula Computarizada"
                          regex={/^([0-9]{13})+$/}
                          namePopOver="matricula"
                          handleEventButton={(e, value) =>
                            handleEventInputPopOver(e, value, "MATRICULA")
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <br />
                    <div className="d-inline-block">
                      <p className="text-left">
                        <strong>Asiento: </strong>
                        <span>
                          {" "}
                          {dataInmuebleCatastro
                            ? dataInmuebleCatastro.informesLegal[0].asiento
                            : ""}
                        </span>
                      </p>
                    </div>

                    {dataInmueble.tipoVisacionMinuta.code ===
                    "PROGRAMAS_CASA_SEGURA_MI_PLANO" ? (
                      <div className="ps-2 d-inline-block">
                        <PopOverCustom
                          id="catastroAsiento"
                          title="Asiento"
                          placeHolder="A-1"
                          regex={/^[A]{1}([\-])(0|1?[0-9]|2)$/}
                          namePopOver="asiento"
                          handleEventButton={(e, value) =>
                            handleEventInputPopOver(e, value, "ASIENTO")
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <p className="text-left">
                      <strong>Colindantes: </strong>
                      <span>
                        {dataInmuebleCatastro
                          ? window.removeNullOrUndefined(
                              dataInmuebleCatastro.informesTecnico[0]
                                .informesTecnicoColindancia
                            ).length
                          : 0}{" "}
                      </span>
                    </p>

                    {dataInmuebleCatastro ? (
                      <TableColindantes
                        data={
                          dataInmuebleCatastro.informesTecnico[0]
                            .informesTecnicoColindancia
                        }
                        tipoVisacionMinuta={dataInmueble.tipoVisacionMinuta}
                        handleInputProgramas={handleInputProgramas}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}

                <div
                  className={`col-sm-12 ${
                    isVisacionMinuta
                      ? "col-md-7 col-lg-7"
                      : "col-md-12 col-lg-12"
                  } `}
                >
                  <div className="d-inline-block float-end">
                    {isVisacionMinuta ? (
                      <p className="text-end">
                        <strong>Ubicación Geoespacial: </strong>
                        {image64 ? (
                          <i
                            className="fa fa-map-marker ms-2 text-fountain-blue"
                            aria-hidden="true"
                            title={`Imagen del mapa capturado`}
                          ></i>
                        ) : (
                          ""
                        )}
                      </p>
                    ) : (
                      ""
                    )}

                    {!dataInmuebleCatastro.imageCatastro ? (
                      <MapCatastroPublic
                        fillPaint={true}
                        classDisplay={"d-none"}
                        width="724"
                        height="261"
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="d-block d-sm-none mt-2"></div>

                  {image64 ? (
                    <img
                      id={nameFormInmueble + "[image]"}
                      className="img-thumbnail rounded mx-auto d-block"
                      alt="img-domicilio-actividad-economica"
                      src={"data:image/png;base64," + image64}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {isVisacionMinuta ? (
                <div className="row mt-3">
                  <div className="col-12 col-md-5 col-lg-5 ">
                    <div className="d-inline-block">
                      <p className="text-left">
                        <strong>Fecha Aprobación Plano: </strong>
                        <span>
                          {dataInmuebleCatastro
                            ? dataInmuebleCatastro.informesTecnico.length > 0
                              ? dataInmuebleCatastro.informesTecnico[0]
                                  .fechaAprobacionPlano
                                ? window
                                    .moment(
                                      dataInmuebleCatastro.informesTecnico[0]
                                        .fechaAprobacionPlano
                                    )
                                    .format("DD-MM-YYYY")
                                : ""
                              : ""
                            : ""}
                        </span>
                      </p>
                    </div>

                    {dataInmueble.tipoVisacionMinuta.code ===
                    "PROGRAMAS_CASA_SEGURA_MI_PLANO" ? (
                      <div className="ps-2 d-inline-block">
                        <PopOverCustom
                          id="fechaAprobPlano"
                          title="Fecha Aprobación Plano"
                          placeHolder="DD/MM/YYYY"
                          regex={
                            /^([0-2][0-9]|3[0-1])(\/|-)(0[1-9]|1[0-2])\2(\d{4})$/
                          }
                          namePopOver="fecha-aprob-plano"
                          handleEventButton={(e, value) =>
                            handleEventInputPopOver(
                              e,
                              value,
                              "FECHA_APROBACION_PLANO"
                            )
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-12 col-md-3 col-lg-3 ">
                    <div className="d-inline-block">
                      <p className="text-left">
                        <strong>R.T.A. Nro.: </strong>
                        <span>
                          {dataInmuebleCatastro
                            ? dataInmuebleCatastro.informesTecnico.length > 0
                              ? dataInmuebleCatastro.informesTecnico[0]
                                  .nroResolucionMunicipal
                              : ""
                            : ""}
                        </span>
                      </p>
                    </div>
                    {dataInmueble.tipoVisacionMinuta.code ===
                    "PROGRAMAS_CASA_SEGURA_MI_PLANO" ? (
                      <div className="ps-2 d-inline-block">
                        <PopOverCustom
                          id="rtanro"
                          title="R.T.A. Nro."
                          regex={/^([0-9/])+/}
                          namePopOver="rta-rno"
                          handleEventButton={(e, value) =>
                            handleEventInputPopOver(e, value, "RTA_NRO")
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-12 col-md-4 col-lg-4 ">
                    <div className="d-inline-block">
                      <p className="text-left">
                        <strong>Fecha Resolución Plano: </strong>
                        <span>
                          {dataInmuebleCatastro
                            ? dataInmuebleCatastro.informesTecnico.length > 0
                              ? dataInmuebleCatastro.informesTecnico[0]
                                  .fechaResolucionPlano
                                ? window
                                    .moment(
                                      dataInmuebleCatastro.informesTecnico[0]
                                        .fechaResolucionPlano
                                    )
                                    .format("DD-MM-YYYY")
                                : ""
                              : ""
                            : ""}
                        </span>
                      </p>
                    </div>
                    {dataInmueble.tipoVisacionMinuta.code ===
                    "PROGRAMAS_CASA_SEGURA_MI_PLANO" ? (
                      <div className="ps-2 d-inline-block">
                        <PopOverCustom
                          id="fechaResolucionPlano"
                          title="Fecha Resolución Plano"
                          placeHolder="DD/MM/YYYY"
                          regex={
                            /^([0-2][0-9]|3[0-1])(\/|-)(0[1-9]|1[0-2])\2(\d{4})$/
                          }
                          namePopOver="fecha-res-plano"
                          handleEventButton={(e, value) =>
                            handleEventInputPopOver(
                              e,
                              value,
                              "FECHA_RESOLUCION_PLANO"
                            )
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              {dataInmueble.claseInmueble &&
              dataInmueble.claseInmueble === "PROPIEDAD HORIZONTAL" ? (
                <div className="row">
                  <div className="col-12 col-md-5 col-lg-5 ">
                    <p className="text-left">
                      <strong>Edificio: </strong>
                      <span>
                        {dataInmuebleCatastro
                          ? dataInmuebleCatastro.informesTecnico.length > 0 &&
                            dataInmuebleCatastro.informesTecnico[0]
                              .informesTecnicosConstruccion.length > 0
                            ? dataInmuebleCatastro.informesTecnico[0]
                                .informesTecnicosConstruccion[0].edificio
                              ? dataInmuebleCatastro.informesTecnico[0].informesTecnicosConstruccion[0].edificio.replace(
                                  /-/g,
                                  ""
                                )
                              : "NO DEFINIDO"
                            : ""
                          : ""}
                      </span>
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CardDataAdministration;
