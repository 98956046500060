import React, { useState, useRef, useEffect, useContext } from 'react';
import AsyncSelect from 'react-select/async';
import TitlePage from '../utils/TitlePage';
import Constant from '../../data/constant';
import Fetch from '../../components/utils/Fetch';
import Links from '../../data/link';
import { toast } from 'react-toastify';
import AuthService from '../../components/Usuario/AuthService';

import 'react-toastify/dist/ReactToastify.css';
import '../style/parsley.css';
import Texto from '../../data/es';

const DeclaracionJuradaEditTipoActividad = (props) => {

    const titlePage = Texto.edit_tipo_actividad_economica;

    //const derecho_admision = props.derecho_admision;
    const optionsSelect = [];

    const fetch = new Fetch();
    fetch.setToast(toast);
    const auth = new AuthService();

    const [tipoActividadEconomica, setTipoActividadEconomica] = useState(undefined);
    const [declaracionJurada, setDeclaracionJurada] = useState(undefined);
    const [actividadEconomica, setActividadEconomica] = useState(undefined);
    const [derechoAdmision, setDerechoAdmision] = useState(undefined);

    const TOKEN_DDJJ = window.getParameterByName('token');
    const NUMERO_DDJJ = window.getParameterByName('numero');


    useEffect(() => {
        window.scrollTo(0, 0);
        if (auth.loggedIn()) {
            let userAuth = auth.getProfile();
            if (userAuth.code === Constant[0].grupos.funcionario || userAuth.code === Constant[0].grupos.administrador) {
                loadDataDeclaracionJurada(TOKEN_DDJJ, NUMERO_DDJJ);
            }
        } else
            window.location.href = Links[4].url
    }, []);

    const loadDataDeclaracionJurada = (token, numero) => {
        if (token !== '' && token.length > 30) {
            const response = fetch.fetchGet(`api/licencia-actividad-economica/by-token/${token}`);
            response.then(res => {
                if (res && res.status) {
                    const declaracionJuradaDb = res.data.DeclaracionJurada;
                    const tipoActividadEconomicaDb = res.data.TipoActividadEconomica;
                    const actividadEconomicaDb = res.data.ActividadEconomica;
                    if (declaracionJuradaDb.numero === numero) {
                        if (window.stringToBoolean(tipoActividadEconomicaDb.temporal))
                            setDerechoAdmision(2);
                        else
                            setDerechoAdmision(1);
                        setActividadEconomica(actividadEconomicaDb);
                        setDeclaracionJurada(declaracionJuradaDb);
                        setTipoActividadEconomica(tipoActividadEconomicaDb);
                    } else
                        window.location.href = Links[4].url
                }
            })
        }
    }

    const loadOption = async (inputValue) => {
        if (inputValue.length > 2) {
            const response = await fetch.axiosAsyncGet(`api/tipo-actividad-economica/search-by-name/${inputValue}/${derechoAdmision}`);
            if (response.status === true)
                return response.data;
            else
                return [];
        } else {
            return optionsSelect
        }
    }

    const handleInputChange = selectedOption => {
        /*if (selectedOption !== null)
            window.create_input_hidden(selectedOption.value, 'actividad_economica[id_tipo_actividad]', 'formDJ');*/
    };

    const noOptionsMessage = (props) => {
        let search = "";
        if ((parseInt(derechoAdmision) === parseInt(Constant[0].derecho_admision.permanente)))
            search = Texto.ingresa_tipo_actividad_economica + " - " + Texto.permanente
        else
            search = Texto.ingresa_tipo_actividad_economica + " - " + Texto.temporal
        if (props.inputValue === '') {
            return (search);
        }
        return (Texto.criterio_busqueda_no_corresponde);
    }

    const onSubmitForm = (event) => {
        event.preventDefault();

        window.jQuery("#" + event.target.getAttribute('id')).parsley().validate();

        const form_html = event.target;
        const form = new FormData(form_html);

        form.append('declaracion_jurada[token]', TOKEN_DDJJ);
        form.append('declaracion_jurada[numero]', NUMERO_DDJJ);


        if (window.jQuery("#" + form_html.getAttribute('id')).parsley().isValid() && form.get("tipo_actividad_economica[id]") !== "") {
            window.createBootbox("Esta Seguro de Continuar.", function (result) {
                debugger
                if (result === true) {
                    fetch.fetchPost(form, 'api/declaraciones-juradas/update-tipo-actividad', form_html).then(dataJson => {
                        if (dataJson && dataJson.status === true) {
                            toast.success(dataJson.message, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true
                            });
                            window.redirect(Links[1].url);
                        }
                    })
                }
            })
        } else {
            toast.warn('El formulario tiene valores obligatorios', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    const [breadcrumbs, setBreadcrumbs] = useState([
        {
            title: Links[0].title,
            url: Links[0].url
        },
        {
            title: Links[1].title,
            url: Links[1].url
        },
        {
            title: Texto.edit_tipo_actividad_economica,
            url: '#'
        }
    ]);

    return (
        <section className="items-grid section mt-5">
            <div className="container mt-4">
                <TitlePage titlePage={titlePage} breadcrumbs={breadcrumbs} position={'left'} leftfull={false} />
                <form action="" className="contact__form needs-validation" name="formUpdateTipoActividadEconomica" id="formUpdateTipoActividadEconomica"
                    method="post" noValidate onSubmit={onSubmitForm} style={{ width: '100%' }}>

                    <div className="card widget widget-simple w-100" >
                        <div className="card-body ">
                            <div className="row mb-3">
                                <div className="col-12 widget-header">
                                    <h4 className="">{Texto.datos_actuales_actividad_economica}</h4>
                                </div>
                            </div>

                            <div className="widget-content">
                                <div className="widget-body">
                                    <div className="row ">
                                        <div className="col-sm-12 col-md-6 padding-left-right-0">
                                            <p className="text-left" ><strong >Tipo Actividad Económica: </strong><span>{tipoActividadEconomica ? tipoActividadEconomica.name.toUpperCase() : ''}</span></p>
                                        </div>

                                        <div className="col-sm-12 col-md-6 padding-left-right-0">
                                            <p className="text-left"><strong >Categoría: </strong><span>{derechoAdmision ? (derechoAdmision === 1 ? 'PERMANENTE' : 'TEMPORAL') : ""}</span></p>
                                        </div>
                                    </div>

                                    <div className="row ">
                                        <div className="col-sm-12 col-md-6 padding-left-right-0">
                                            <p className="text-left" ><strong >Fur: </strong><span>{declaracionJurada ? declaracionJurada.fur : ''}</span></p>
                                        </div>

                                        <div className="col-sm-12 col-md-6 padding-left-right-0">
                                            <p className="text-left"><strong >Número: </strong><span>{declaracionJurada ? declaracionJurada.numero : ""}</span></p>
                                        </div>
                                    </div>

                                    <div className="row ">
                                        <div className="col-sm-12 col-md-6 padding-left-right-0">
                                            <p className="text-left" ><strong >Rotulo Comercial: </strong><span>{actividadEconomica ? (actividadEconomica.rotulo_comercial ? actividadEconomica.rotulo_comercial.toUpperCase() : '') : ''}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card widget widget-simple w-100" >
                        <div className="card-body ">
                            <div className="row mb-3">
                                <div className="col-12 widget-header">
                                    <h4 className="">{Texto.tipo_actividad_economica}</h4>
                                </div>
                            </div>

                            <div className="widget-content">
                                <div className="widget-body">

                                    <div className="row ">
                                        <div className="col-12 col-md-6 col-lg-6 mb-3">
                                            <label htmlFor="tipo_actividad_economica[id]" className="form-label">Actividad Económica *</label>
                                            <AsyncSelect
                                                cacheOptions
                                                loadOptions={loadOption}
                                                defaultOptions
                                                onChange={handleInputChange}
                                                isClearable
                                                isSearchable
                                                placeholder={Texto.tipo_actividad_economica}
                                                required
                                                defaultValue={[optionsSelect[0]]}
                                                noOptionsMessage={noOptionsMessage}
                                                name={"tipo_actividad_economica[id]"}
                                                data-parsley-required="true"
                                            />

                                        </div>

                                        <div className="col-12 col-md-6 col-lg-6 mb-3 text-end">
                                            <label htmlFor="" className="form-label"> </label>
                                            <div className=" search-btn button ">
                                                <input name="Siguiente" type="submit" className="btn btn-full-screen" value={'Actualizar'} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    )

}

export default DeclaracionJuradaEditTipoActividad;