import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../../components/style/form-wizard.css";
import AuthService from "../../components/Usuario/AuthService";
import Fetch from "../utils/Fetch";
import Links from "./../../data/link";
import funcChangeFormatErrorMessageParsley from './../utils/ChangeFormatErrorMessageParsley';
// import Step1FormValideFur from "./Step1FormValideFur";
import Constant from "../../data/constant";
import Texto from "./../../data/es";
import TitlePage from "../utils/TitlePage";
import Config from "./../../data/config";
import Consulta from "./Consulta";
import Step1EventualFormValideDatosTecnicos from "./_edit/Step1EventualFormValideDatosTecnicos";
import Step2EditFormRequisitos from "./_edit/Step2EditFormRequisitos";
import Step5Finish from "./_edit/Step5Finish";

import Step1EmpresaFormDatosTecnicos from "./_edit/Step1EmpresaFormDatosTecnicos";
import Step1ActEconomicaFormDatosTecnicos from "./_edit/Step1ActEconomicaFormDatosTecnicos";

const breadcrumbs = [
    {
        title: Links[0].title,
        url: Links[0].url,
    },
    {
        title: Links[128].title,
        url: Links[128].url,
    },
    {
        title: Links[129].title,
        url: Links[129].url,
    },
    {
        title: Links[130].title,
        url: Links[130].url,
    },
    {
        title: Links[136].title,
        url: Links[136].url,
    }
];

let dataForm = {};

const Edit = () => {

    const { token } = useParams();

    const titlePage = Texto.publicity_edit;
    const urlPdfRepositorioMain = `${Config[10].url}`;

    let formnumber = 0;

    const [dataAdvertising, setDataAdvertising] = useState(null);
    const [dataClasification, setDataClasification] = useState(null);
    const [dataCategoryPublicity, setDataCategoryPublicity] = useState(null);
    // const [dataAdvertisingRequirementSite, setDataAdvertisingRequirementSite] = useState([]);
    // const [dataAdTitular, setDataAdTitular] = useState(null);
    const fetch = new Fetch();
    fetch.setToast(toast);
    const history = useHistory();
    const auth = new AuthService();
    const consultaSQL = new Consulta();

    useEffect(() => {
        window.scrollTo(0, 0);

        if (!auth.loggedIn()) {
            history.replace(Links[4].url);
            return;
        }

        dataPublicidad();

    }, []); // Asegúrate que `token` no sea dinámico, si lo es, inclúyelo en las dependencias.


    // datos de la publicidad
    const dataPublicidad = async () => {
        try {
            const query = consultaSQL.queryInfoAdvertisingByTokenForEdit(token);
            const data = await fetch.fetchPostGraphQl(query, "/graphql", null);

            if (data && data.advertising && data.advertising.result) {
                const publicidadinfo = data.advertising.data.advertising;
                console.log("+++++++++++++ publicidadinfo edit +++++++++++++++++   ", publicidadinfo);
                setDataAdvertising(publicidadinfo);

                if (publicidadinfo && publicidadinfo.clasificationCategoriesAdv) {
                    const clasificationCategoriesAdvInfo = publicidadinfo.clasificationCategoriesAdv[0];
                    setDataClasification(clasificationCategoriesAdvInfo.clasificationCategoryPublicity.clasification);
                    setDataCategoryPublicity(clasificationCategoriesAdvInfo.clasificationCategoryPublicity.categoryPublicity);
                    // setDataAdvertisingRequirementSite(publicidadinfo.AdAdvertisingRequirementSite);
                    // setDataAdTitular(publicidadinfo.AdTitulars);
                }

                toast.success("Datos de la publicidad encontrados.");
            } else {
                toast.error("No existen datos de la publicidad");
            }
        } catch (error) {
            // console.error("Error al cargar datos de la publicidad: ", error);
            toast.error("Error al cargar los datos de la publicidad");
        }
    };

    //  const filteredDataRequirement = dataAdvertisingRequirementSite.filter(item => item.AdRequirementSite.code === 'REQUIREMENT');
    // ================================================ revisando ========================================== //
    // next click
    const nextClick = async (event, numForm, submit, data) => {
        debugger
        //let valideForm = valideInputsForm(event);
        if (!valideInputsForm(event))
            return false;

        if (numForm === 0) {
            dataForm.datosTecnicos = data;
        }

        if (numForm === 1)
            dataForm.requirement = data;


        formnumber = numForm + 1;

        setTimeout(() => {
            debugger
            updateform();
            if (!submit) {
                progressForward(formnumber + 1);
                contentchange();
            }
        }, 300);

        if (submit) {
            debugger
            progressForward(formnumber);
            console.log(" +++++++++ datos para actualizar +++ dataForm ++++++++++ ", dataForm);
            if(dataAdvertising.Estado.code === Constant[0].estado.en_proceso){
                const advertisingDbUpdate = await updateAdvertisingInfo(dataForm);
                if (advertisingDbUpdate ) {
                    eliminarRequisitosPublicidad(dataAdvertising.token);
                    toast.success("La publicidad con Nro. orden: " + dataAdvertising.numero + " se actualizo.");
                    const dataAdvRequirementSite = await updateRequisitosPublicidad(dataForm);
                } 
            }
                    
        }
    };

    const backClick = (event, numForm) => {
        // console.log("********* nextClick  ", numForm);

        debugger
        formnumber = numForm - 1;

        updateform();
        progressBackward(formnumber + 1);
        contentchange();
    };

    const updateform = () => {
        const mainForm = document.querySelectorAll(".main-wizard");
        mainForm.forEach(function (mainformNumber) {
            mainformNumber.classList.remove('active-f-w');
        })
        mainForm[formnumber] && mainForm[formnumber].classList.add('active-f-w');
    }


    // modificado
    const progressForward = (currentStep) => {
        const stepList = document.querySelectorAll(".progress-bar-f-wizard li");
        const num = document.querySelector(".step-number");

        // Actualizar el número del paso actual
        num.innerHTML = currentStep;
        console.log("nro de paso ", currentStep);
        // Marcar el paso actual como activo
        if (currentStep > 0 && currentStep <= stepList.length) {
            stepList.forEach((step, index) => {
                if (index < currentStep) {
                    step.classList.add('active-f-w');
                } else {
                    step.classList.remove('active-f-w');
                }
            });
        }
    };

    function progressBackward(currentStep) {
        debugger
        const stepList = document.querySelectorAll(".progress-bar-f-wizard li");
        const formNum = formnumber + 1;
        stepList[formNum].classList.remove('active-f-w');
        const num = document.querySelector(".step-number");
        num.innerHTML = formNum;
    }

    const contentchange = () => {
        const stepNumContent = document.querySelectorAll(".step-number-content");
        stepNumContent.forEach(function (content) {
            content.classList.remove('active-f-w');
            content.classList.add('d-none');
        });
        if (stepNumContent[formnumber]) {
            stepNumContent[formnumber].classList.add('active-f-w');
            stepNumContent[formnumber].classList.remove('d-none');
        }
    }

    /* VALIDACION DEL FORMULARIO PREVIO AL SGTE PASO */
    const valideInputsForm = (event) => {
        event.preventDefault();
        const formHtml = event.target.closest("form")
        window.jQuery("#" + formHtml.getAttribute("id"))
            .parsley()
            .validate();

        funcChangeFormatErrorMessageParsley('input', 'datosTecnicos-nroActividad', null);
        funcChangeFormatErrorMessageParsley('input', 'datosTecnicos-nroInmueble', null);
        funcChangeFormatErrorMessageParsley('select', 'datosTecnicos-category', null);
        funcChangeFormatErrorMessageParsley('select', 'datosTecnicos-caracterization', null);
        funcChangeFormatErrorMessageParsley('input', 'ubicacion-adyacente_entre', 'error_adyacente_entre');
        funcChangeFormatErrorMessageParsley('input', 'ubicacion-adyacente_y', 'error_adyacente_y');

        if (window.jQuery("#" + formHtml.getAttribute("id")).parsley().isValid())
            return true;  //return JSON.stringify(jsonObject);
        return false;
    }

    /* actualizar los  DATOS DEL FORMULARIO */
    const updateAdvertisingInfo = async (dataForm) => {
        let resultado = false;

        if (dataForm && dataForm?.datosTecnicos) {
            const dataTechnical = dataForm.datosTecnicos;
            // info datos AdAdvertising
            const advertising = dataTechnical.advertising;
            const dateRange = advertising.rangeDate.split("-");
            const dateStart = fechaFormateado(dateRange[0].trim());
            const dateEnd = fechaFormateado(dateRange[1].trim());

            const idCaracterizacion = (advertising.caracterization === undefined ? 0 : advertising.caracterization);
            const idCategorization = (advertising.categorization === undefined ? 0 : advertising.categorization);
            const idComunication = (advertising.comunication === undefined ? 0 : advertising.comunication);

            const advDatosCatastro = dataTechnical.modificado.datostecnicos ? (advertising.inmueble.datosCatastro) : (advertising.inmueble.AdUbicacions[0]);
            const actEconomicaRuat = dataTechnical.actividadEconomica.actividadEconomicaRuat;

            const area = { id: 0, zona: "" }
            if ("idAreaHomogenea" in advDatosCatastro)
                area.id = advDatosCatastro.idAreaHomogenea
            if ("zonaTributaria" in advDatosCatastro)
                area.zona = advDatosCatastro.zonaTributaria

            let urlImage = (dataTechnical.modificado.datostecnicos ? advDatosCatastro.base64Image : (advDatosCatastro.urlImagen));

            const queryUpdate = `mutation {
            updateAdvertising(advertising: {token: "${token}", dateStart: "${dateStart}", dateExpires:"${dateEnd}", fur:"0"}, 
            clasification: {id: ${advertising.clasification}},
            categoryPublicity: {id:${advertising.category}}, 
            caracterization: {id: ${idCaracterizacion}}, 
            categorization: {id: ${idCategorization}}, 
            comunication: {id: ${idComunication}},
            dataTechnical: { ancho: ${advertising.ancho}, alto:${advertising.alto}},
            areaHomogenea:{ id: ${area.id}, zona: "${area.zona}"},
            ubication:{ latitud:"${advDatosCatastro.latitud}", longitud:"${advDatosCatastro.longitud}", coordinate:"${advDatosCatastro.coordinate}",  
                urlImagen:"${urlImage}", comuna: "${advDatosCatastro.comuna}", direccion:"${advertising.direccion}",  direccionEntre:"${advertising.direccionEntre}", 
                direccionY:"${advertising.directionY}" },
            actividadEconomica: {
                nroActividad: "${actEconomicaRuat?.numeroActividad}", 
                nroLicencia:"${actEconomicaRuat?.licencia}", 
                codigoClasificador: "${actEconomicaRuat?.codigoClasificador}", 
                codigoActividadEconomica: "${actEconomicaRuat?.codigoActividadEconomica}", 
                tipoLicencia:"${actEconomicaRuat?.tipoLicencia}", 
                tipoActividad:"${actEconomicaRuat?.tipoActividad}"
            }
            ){
              message
              result                        
            }
          }`;

            const respAdvertising = await fetch.fetchPostGraphQl(queryUpdate, "/graphql", null);
            console.log("+++++++++++++++++ queryUpdate respAdvertising +++++++++++++++ ", respAdvertising); 
            if (respAdvertising && respAdvertising.updateAdvertising && respAdvertising.updateAdvertising.result) {
                             
                return respAdvertising.updateAdvertising.result; //.data.advertising;
            }
        }

        return null;
    }

    // requisitos update
    const updateRequisitosPublicidad = async (dataForm) => {     
            debugger
            let listIdAdvRequirementSite = [];
            let listAdvRequirementSite = [];

            try {
              
                const requirement = dataForm.requirement;
                console.log("++++++++++++++++++ requirement +++ updateRequisitosPublicidad ++++ ", requirement);
                if (requirement.length > 0 && parseInt(dataAdvertising.id) > 0) {
                    debugger
    
                    let queryPromise = requirement.map(async (rowRequeriment) => {
                        const file = rowRequeriment.file;
                        const idArray = rowRequeriment.id.split("_");
                        const formData = new FormData();
                        formData.append('idAdvertising', dataAdvertising.id);                        
                        formData.append('idRequirementSite', idArray[idArray.length - 1]);
                        formData.append('file', file);
                        debugger
                        //console.log("formdata > _________________", formData);
                        debugger
                        const resqRequisitos = await fetch.fetchPostFormDataGraphQl(formData, "/api/advertising/edit-requirement-site", undefined);
                        console.log(" ---- resqRequisitos resqRequisitos resqRequisitos -------- ", resqRequisitos);
                        if(resqRequisitos && resqRequisitos.result){
                            // const dataAdvRequirementSite = 
                            listAdvRequirementSite.push(resqRequisitos); 
                        }
    
                        return listAdvRequirementSite;
                    });
    
                    toast.success("Se actualizo los requisitos adjuntos");
                    queryPromise = await Promise.all(queryPromise);
                    return queryPromise.filter((requirement) => requirement);
    
                } else {
                    toast.error("No existe la publicidad, no podrá realizar la operación.");
                }
            } catch (err) {
                console.error(err);
                toast.error("Ocurrio un error al momento de subir los archivos pdf.");
            }
        }

        const eliminarRequisitosPublicidad  = async (tokenAdvertising) => {
            console.log("Ingreso ..... ");
            const querydelete = `mutation{ deleteAdvertisingRequirementsSite(advertising: {token: "${tokenAdvertising}" }) {
                message result 
              }}`;

              try {
                
                const respAdvertising = await fetch.fetchPostGraphQl(querydelete, "/graphql", null);
                console.log("+++++++++++++++++ queryUpdate respAdvertising +++++++++++++++ ", respAdvertising); 
                if (respAdvertising && respAdvertising.deleteAdvertisingRequirementsSite && respAdvertising.deleteAdvertisingRequirementsSite.result) {
                                 
                    return respAdvertising.deleteAdvertisingRequirementsSite.result; //.data.advertising;
                }

              } catch (error) {
                
              }
        
        }  



    /* PASOS DEL DETALLE Y PASO POR CLASIFICADOR */
    const stepDetailInfo = () => {
        return (<>
            {
                Constant[0].publicidad_step_register_update.map((step, index) => (
                    index == 0 ?
                        <p key={index + 1} className="step-number-content text-justify active-f-w">{step}</p>
                        :
                        <p key={index + 1} className="step-number-content text-justify d-none">{step}</p>
                ))
            }
        </>)
    }

    const calculoSuperficieM2 = (alto, ancho) => {
        let totalM2 = 0;
        if (alto > 0 && ancho > 0) {
            debugger
            totalM2 = alto * ancho;
        }
        return totalM2;
    }

    const fechaFormateado = (dateParam) => {
        let resultado = null;
        const lengthDate = dateParam.trim().length;
        const diaActual = window.moment().format('DD');

        if (lengthDate > 0 && lengthDate === 7) {
            resultado = window.moment(diaActual + "/" + dateParam, "DD/MM/YYYY").format("YYYY-MM-DD");
        }
        if (lengthDate > 0 && lengthDate === 10) {
            resultado = window.moment(dateParam, "DD/MM/YYYY").format("YYYY-MM-DD");
        }
        return resultado;
    }

    /* ============================================================================ */
    return (
        <>
            <section className="items-grid section mt-5 ">
                <TitlePage
                    titlePage={titlePage}
                    breadcrumbs={breadcrumbs}
                    position={"left"}
                    leftfull={false}
                />
                <div className="container-f-wizard">
                    <div className="card-w">
                        <div className="form">
                            <div className="left-side bg-teklet-deep">
                                <div className="left-heading">
                                    <h4 className="text-center">Editar Publicidad
                                        <br />
                                        {dataClasification?.name}
                                    </h4>
                                    <p className="text-justify mt-4">
                                        <b>Fur: </b> {dataAdvertising?.fur} <br />
                                        <b>Nº Orden: </b> {dataAdvertising?.numero}
                                    </p>

                                </div>
                                <div className="steps-content">
                                    <h4>Paso <span className="step-number">1</span></h4>
                                    {stepDetailInfo()}
                                </div>

                                <ul className="progress-bar-f-wizard ">
                                    {/* <li className="active-f-w" key={0}>Derecho de Admisión</li> */}
                                    <li className="active-f-w" key={0}>Datos Técnicos</li>
                                    <li key={1}>Requisitos</li>
                                    <li key={2}>Vista Prevía</li>
                                </ul>
                            </div>

                            <div className="right-side">
                                {
                                    dataAdvertising && dataClasification && dataCategoryPublicity ?

                                        // eventual
                                        <>
                                            {dataClasification && dataClasification.code === Constant[0].publicidad_clasificador.code_eventual &&
                                                <Step1EventualFormValideDatosTecnicos
                                                    fetch={fetch}
                                                    toast={toast}
                                                    nextClick={nextClick}
                                                    //backClick={(e) => backClick(e, 1)}
                                                    auth={auth}
                                                    history={history}
                                                    dataClasification={dataClasification}
                                                    dataCategoryPublicity={dataCategoryPublicity}
                                                    dataAdvertising={dataAdvertising}
                                                    calculoSuperficieM2={calculoSuperficieM2}
                                                    stepNumber={0}
                                                    Texto={Texto}
                                                    urlPdfRepositorioMain={urlPdfRepositorioMain}
                                                />
                                            }

                                            {/* // estructura - empresa*/}
                                            {(dataClasification && dataClasification.code === Constant[0].publicidad_clasificador.code_empresarial) &&
                                                <Step1EmpresaFormDatosTecnicos
                                                    fetch={fetch}
                                                    toast={toast}
                                                    nextClick={nextClick}
                                                    //backClick={(e) => backClick(e, 1)}
                                                    auth={auth}
                                                    history={history}
                                                    dataClasification={dataClasification}
                                                    dataCategoryPublicity={dataCategoryPublicity}
                                                    dataAdvertising={dataAdvertising}
                                                    calculoSuperficieM2={calculoSuperficieM2}
                                                    stepNumber={0}
                                                    Texto={Texto}
                                                    urlPdfRepositorioMain={urlPdfRepositorioMain}
                                                />
                                            }

                                            {/* // act economica */}
                                            {(dataClasification && dataClasification.code === Constant[0].publicidad_clasificador.code_actividad) &&
                                                <Step1ActEconomicaFormDatosTecnicos
                                                    fetch={fetch}
                                                    toast={toast}
                                                    nextClick={nextClick}
                                                    auth={auth}
                                                    history={history}
                                                    dataClasification={dataClasification}
                                                    dataCategoryPublicity={dataCategoryPublicity}
                                                    dataAdvertising={dataAdvertising}
                                                    calculoSuperficieM2={calculoSuperficieM2}
                                                    stepNumber={0}
                                                    Texto={Texto}
                                                    urlPdfRepositorioMain={urlPdfRepositorioMain}
                                                    Constant={Constant}
                                                />
                                            }


                                            {/* requisitos */}

                                            <Step2EditFormRequisitos
                                                fetch={fetch}
                                                toast={toast}
                                                nextClick={nextClick}
                                                backClick={(e) => backClick(e, 1)}
                                                auth={auth}
                                                history={history}
                                                fur={dataAdvertising.fur}
                                                dataClasification={dataClasification}
                                                stepNumber={1}
                                            />


                                            <Step5Finish
                                                fetch={fetch}
                                                toast={toast}
                                                backClick={(e) => backClick(e, 2)}
                                                auth={auth}
                                                history={history}
                                                stepNumber={2}
                                                Constant={Constant}
                                                dataAdvertising={dataAdvertising}
                                            />
                                        </>

                                        : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Edit;