import React, { useRef, useState, useEffect } from "react"; //
import PropTypes from "prop-types";
// import Links from "../../../data/link";
import { Link , useHistory} from "react-router-dom";
import Texto from "../../../data/es";
import { toast } from "react-toastify";

const RegisterPmcFormulario = ({ onSubmit, dataFormulario, fetch, setDescriptionInicio, urlImageLoading }) => {

	const idForm = "form-pmc";
	const history = useHistory();
	// let Auth = new AuthService();
	// fetch.setToast(toast);
	const pmcRef = useRef(null);
	const passwordRef = useRef(null);
	const confirmPasswordRef = useRef(null);
	const [agreed, setAgreed] = useState(false);
	const [message, setMessage] = useState(Texto.register_pmc_description);
	const [loading, setLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [totalNoActualizado, setTotalNoActualizado] = useState(0);

	// modal pmc
	const inputSearchPmcRef = useRef();
	const [showModalSearch, setShowModalSearch] = useState(false);
	const [showPanelSearchResult, setShowPanelSearchResult] = useState(false);
	const [modalSearchPmc, setModalSearchPmc] = useState(undefined);
	const [contribuyentesSearchList, setContribuyentesSearchList] = useState([]);
	const [loadingSearch, setLoadingSearch] = useState(false);

	console.log(" dataFormulario ++++++++++++ ", dataFormulario);

	useEffect(() => {
		//window.scrollTo(0, 0);
		pmcRef.current.focus();
	}, []);


	const handleFormSubmit = (e) => {
		e.preventDefault();

		window.jQuery("#" + idForm).parsley().validate();
		var ul_error_dir = window.jQuery("input[name='password']").parent().parent().find('ul');
		window.jQuery("input[name='password").parent().parent().find('ul').remove();
		window.jQuery("input[name='password").parent('div').parent('div').append(ul_error_dir);

		var ul_error_dir2 = window.jQuery("input[name='confirmPassword']").parent().parent().find('ul');
		window.jQuery("input[name='confirmPassword").parent().parent().find('ul').remove();
		window.jQuery("input[name='confirmPassword").parent('div').parent('div').append(ul_error_dir2);

		const validate = window.jQuery("#" + idForm).parsley().isValid();
		if (validate) {
			setLoading(true);
			// handleValidarDatosPrevios();
			handleVerifyByPmcOnClick();	
			
		}
	};


	// btn validate - verify pmc
	const handleVerifyByPmcOnClick = async () => {
		debugger;
		setIsError(false);
		setLoading(true);
		setMessage(Texto.register_pmc_description);

		// verifica si el pmc no se encuentra registrado		
		// si no esta en uso
		if (pmcRef.current.value > 3) {
			debugger;
			const dataResponse = await fetch.fetchGet(`api/usuario/register/verify-pmc/${pmcRef.current.value}`);

			if (dataResponse && dataResponse.status) {
				debugger;
				let existPmc = dataResponse.data.exist;
				if (existPmc) {
					setMessage(dataResponse.message);
					setIsError(true);
				} else {
					setMessage(Texto.register_pmc_description);
					setIsError(false);

					const responseContribuyente = await fetch.fetchGet(`api-ruat/contribuyentes/pmc/${pmcRef.current.value}`);

					if (responseContribuyente && responseContribuyente.status) {
						debugger;
						let dataResponse = responseContribuyente.data;
						let totalDataResponse = Object.keys(dataResponse).length;
						if (totalDataResponse === 0) {
							debugger;
							setDescriptionInicio(Texto.register_pmc_notexist);
							setMessage(Texto.register_pmc_notexist);
							setIsError(true);
						} else {
							debugger;
							let contribuyente = dataResponse.contribuyente;
							let listaPmcs = contribuyente.filter(
								(contribuyente) => contribuyente.contribuyenteActualizado === "1"
							);

							if (listaPmcs && Object.keys(listaPmcs).length > 1) {
								debugger;
								setDescriptionInicio('Existen varios contribuyentes con el PMC ingresado');
								setMessage(`Se ha detectado multiples contribuyentes con el PMC ${pmcRef.current.value}`);
								setIsError(true);
							} else {
								debugger;
								let contribuyenteActualizado = contribuyente[0].contribuyenteActualizado;
								const contrasena = passwordRef.current.value;
								let tipoDocumentoCode = undefined;
								if (contribuyente[0].juridico)
									tipoDocumentoCode = contribuyente[0].juridico.tipoDocumentoCode
								if (contribuyente[0].natural)
									tipoDocumentoCode = contribuyente[0].natural.tipoDocumentoCode
								let isValidateCodeTypeDoc = validateCodeTypeDocument(contribuyente[0].tipoContribuyente, tipoDocumentoCode);
								// console.log('validateCodeTypeDocument ', isValidateCodeTypeDoc);
								if (contribuyenteActualizado === "1") {
									setIsError(false)
									setMessage(Texto.register_pmc_description);
									setLoading(true);
									if (isValidateCodeTypeDoc) {
										handleRegisterContribuyenteUser(dataFormulario.phone, dataFormulario.email, contrasena, contribuyente[0])
									} else {
										setMessage("El tipo de documento consignado al PMC no es válido.");
										setIsError(true);
									}
								} else {
									setMessage(Texto.register_pmc_error);
									setIsError(true);
								}
							}
						}
						setLoading(false);
					} else {
						setMessage(Texto.register_pmc_notexist);
						setIsError(true);
						setLoading(false);
					}
				}
			}

			setLoading(false);
		}
	};

	// validate code_tipo_documento
	const validateCodeTypeDocument = (tipoContribuyente, codeTypeDocument) => {

		if (tipoContribuyente != null && tipoContribuyente === "JURIDICO" && (codeTypeDocument === null || codeTypeDocument === undefined)) {
			return false;
		} else {
			return true;
		}
	}

	// const handleValidarDatosPrevios = async()=>{

	// 	setLoading(true);
	// 	const storedData = localStorage.getItem("dataForm");		
	// 	try {			
			
	// 		if(storedData){
	// 			const dataInfo = JSON.parse(storedData);				
	// 			const form = new FormData();
	// 			form.append("registro[celular]", dataInfo.phone);
	// 			form.append("registro[codigo]", dataInfo.phonecode);
	// 			form.append("registro[email]", dataInfo.email);
	// 			form.append("registro[token]", dataInfo.token);
	// 			const dataResponse = await fetch.fetchPost(form, "api/usuario/registro/validar-datos", undefined);
				
	// 			if (dataResponse && dataResponse.status) {
	// 				toast.success(dataResponse.message);
	// 				setTimeout(() => {
	// 					handleVerifyByPmcOnClick();	
	// 				}, 2000);
					
	// 			}

	// 			// if(dataResponse === undefined){
					
	// 			// }				
	// 		}
	// 	} catch (error) {
	// 		toast.error("Error al realizar la verificación del número de celular.");			
	// 	}

	// 	setLoading(false);
	// }

	/* ================================================================================== */
	// registrar usuario - simat 
	/* ================================================================================== */
	const handleRegisterContribuyenteUser = async (celular, email, password, dataContibuyente) => {
		// celular - email - password - dataContibuyente		
		setLoading(true);
		let urlRegister = "";

		const formData = new FormData();
		formData.append("usuario[celular]", celular);
		formData.append("usuario[codigo]", dataFormulario.phonecode);		
		formData.append("usuario[email]", email);
		formData.append("usuario[password]", password);
		formData.append("contribuyente[codigo_contribuyente]", dataContibuyente.codigoContribuyente);
		formData.append("contribuyente[pmc]", dataContibuyente.pmc);
		formData.append("contribuyente[tipo_contribuyente]", dataContibuyente.tipoContribuyente);
		formData.append("contribuyente[domicilio_legal]", dataContibuyente.domicilioLegal);
		formData.append("contribuyente[actualizado]", dataContibuyente.contribuyenteActualizado);

		if (dataContibuyente.tipoContribuyente === "NATURAL") {
			debugger;
			urlRegister = "api/usuario/register/natural";
			formPersona(formData, dataContibuyente);
		}

		if (dataContibuyente.tipoContribuyente === "JURIDICO") {

			urlRegister = "api/usuario/register/juridico";
			formPersonaJuridica(formData, dataContibuyente);
		}

		// info-adicional	
		formInfoAdicional(formData, dataContibuyente);

		try {

			if (urlRegister) {
				toast.info('Enviando datos para verificacion y/o registro de  usuario ...');

				const dataJson = await fetch.fetchPost(formData, urlRegister);
				if (dataJson !== undefined && dataJson.status === true) {
					debugger;

					let recordState = dataJson.data.estado;

					if (recordState) {
						debugger;
						toast.success(dataJson.message);
						onSubmit(dataContibuyente.pmc);
					} else {
						debugger;
						toast.warning(dataJson.message);
					}
					setLoading(false);
				}
			}
			setLoading(false);

		} catch (error) {
			//console.error("Error fetching data registro usuario simat =>>>>> ", error);
			toast.error("Error al realizar la verificación del número de celular.");
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const formPersona = (form, data) => {
		form.append("contribuyente[natural][nombres]", data.natural.nombres);
		form.append("contribuyente[natural][apellido_paterno]", data.natural.primerApellido);
		form.append("contribuyente[natural][apellido_materno]", data.natural.segundoApellido);
		form.append("contribuyente[natural][apellido_casada]", data.natural.apellidoEsposo);
		form.append("contribuyente[natural][numero_documento]", data.natural.numeroDocumento);
		form.append("contribuyente[natural][tipo_documento]", data.natural.tipoDocumento);
		form.append("contribuyente[natural][tipo_documento_code]", data.natural.tipoDocumentoCode);
		form.append("contribuyente[natural][expedido_en]", data.natural.expedido);
		form.append("contribuyente[natural][fecha_nacimiento]", data.natural.fechaNacimiento);
		form.append("contribuyente[natural][estado_civil]", data.natural.estadoCivil);
		form.append("contribuyente[natural][genero]", data.natural.genero);
	}

	const formPersonaJuridica = (form, data) => {
		// datos juridicos
		form.append("contribuyente[juridico][razon_social]", data.juridico.razonSocial);
		form.append("contribuyente[juridico][sigla]", data.juridico.sigla);
		form.append("contribuyente[juridico][nit]", data.juridico.numeroDocumento);
		form.append("contribuyente[juridico][tipo_documento]", data.juridico.tipoDocumento);

		form.append("contribuyente[juridico][tipo_documento_code]", data.juridico.tipoDocumentoCode);
		form.append("contribuyente[juridico][tipo_sociedad]", data.juridico.tipoSociedad);
		form.append("contribuyente[juridico][fecha_constitucion]", data.juridico.fechaConstitucion);
		form.append("contribuyente[juridico][tipo_contribuyente_juridico]", data.juridico.tipoContribuyenteJuridico);

		// apoderado
		form.append("contribuyente[juridico][apoderado][nombres]", data.apoderado.nombres);
		form.append("contribuyente[juridico][apoderado][apellido_paterno]", data.apoderado.primerApellido);
		form.append("contribuyente[juridico][apoderado][apellido_materno]", data.apoderado.segundoApellido);
		form.append("contribuyente[juridico][apoderado][apellido_casada]", data.apoderado.apellidoEsposo);
		form.append("contribuyente[juridico][apoderado][numero_documento]", data.apoderado.numeroDocumento);
		form.append("contribuyente[juridico][apoderado][tipo_documento]", null);
		form.append("contribuyente[juridico][apoderado][tipo_documento_code]", data.apoderado.tipoDocumento);
		form.append("contribuyente[juridico][apoderado][expedido_en]", data.apoderado.expedido);
		form.append("contribuyente[juridico][apoderado][fecha_nacimiento]", data.apoderado.fechaNacimiento);
		form.append("contribuyente[juridico][apoderado][estado_civil]", data.apoderado.estadoCivil);
		form.append("contribuyente[juridico][apoderado][genero]", data.apoderado.genero);

		//info  poder
		form.append("contribuyente[juridico][poder][numero]", data.poder.numeroPoder);
		form.append("contribuyente[juridico][poder][fecha]", data.poder.fecha);
		form.append("contribuyente[juridico][poder][observacion]", data.poder.observacion);
	}

	const formInfoAdicional = (form, data) => {
		form.append("adicional[telefono]", data.informacionAdicional.telefonoReferencia);
		form.append("adicional[celular]", data.informacionAdicional.celular);
		form.append("adicional[correo]", data.informacionAdicional.correo);
	}

	/* ============================================================ */

	const openModalSearchContribuyente = (event) => {
		event.preventDefault();

		setShowModalSearch(true);
		setContribuyentesSearchList([]);

		setTimeout(() => {
			setShowPanelSearchResult(false);
			const modalSearchDom = document.getElementById("modalSearchPmc");
			const modalSearch = new window.bootstrap.Modal(modalSearchDom, {
				keyboard: true,
				backdrop: "static",
				focus: true
			});
			modalSearch.show();
			setModalSearchPmc(modalSearch);
			inputSearchPmcRef.current.focus();
			inputSearchPmcRef.current.value = null;
		}, 500);
	};

	// form - submit
	const handleSubmitSearchPmcForm = (event) => {
		event.preventDefault();
		window.jQuery("#formSearchPmc").parsley().validate();
		const validate = window.jQuery("#formSearchPmc").parsley().isValid();

		const form = event.target;
		const optionSelected = form.getElementsByTagName("select")[0].selectedOptions[0].value;
		const inputSearch = form.getElementsByTagName("input")[0].value;

		if (validate && inputSearch.length > 0) {
			setLoadingSearch(true);
			setShowPanelSearchResult(false);

			fetch.fetchGet(`api-ruat/contribuyentes/search-info-nit-ci/${inputSearch}/${optionSelected}`)
				.then((res) => {
					if (res && res.status) {
						console.log('RUAT: ', res.data);
						setShowPanelSearchResult(true);
						setContribuyentesSearchList(res.data.contribuyente);
						setTotalNoActualizado(res.data.observados)
					} else {
						setContribuyentesSearchList([]);
					}
					setLoadingSearch(false);
				});
		} else {
			setShowPanelSearchResult(false);
		}
	};

	// select button pmc
	const handleEventSelectedPmc = (event, contribuyente) => {
		setMessage(Texto.register_pmc_description);
		setIsError(false);

		setTimeout(() => {
			if (pmcRef.current && typeof pmcRef.current.dispatchEvent === 'function') {
				inputSearchPmcRef.current.focus();
				pmcRef.current.value = contribuyente.pmc;
				const eventBlur = new Event("blur");
				pmcRef.current.dispatchEvent(eventBlur);

				inputSearchPmcRef.current.value = "";
			}
		}, 500);

		modalSearchPmc.hide();
	};

	const handleOpenPassword = (event, refInputPassword) => {
		event.preventDefault();
		const input = refInputPassword.current;
		const icon = input.nextSibling.firstElementChild.firstElementChild;
		if (input.type === "text") {
			input.type = "password";
			icon.classList.add("fa-eye");
			icon.classList.remove("fa-eye-slash");
		} else {
			input.type = "text";
			icon.classList.add("fa-eye-slash");
			icon.classList.remove("fa-eye");
		}
	};

	const handleEventClickCancelar = (e) => {
		window.createBootbox("¿Está seguro de cancelar el registro de Usuario SIAMT?  ", function (result) {
           if (result === true) {		
				const storedData = localStorage.getItem("dataForm");
				if (storedData) {
					localStorage.removeItem("dataForm");
				}
				
				const stepData = localStorage.getItem("currentStep");
				if(stepData){
					localStorage.removeItem("currentStep");
				} 
				
				history.push('/');
			}
		})		
	};
	/* ============================================================ */
	return (
		<>
			{message && (
				<div className={"text-justify col-lg-12 col-md-12 col-sm-12 mx-auto border-radius-035 bg-gray-5 mt-2 p-2" + (isError ? " text-danger" : "")}>
					{isError === true && (<h5 className="text-center">Importante</h5>)}
					{message}
				</div>)
			}

			{loading ? (<div className="col-12 mb-1 mx-auto d-block text-center">
				<img
					className="rounded"
					alt="pulse-loading"
					src={urlImageLoading}
					width="40"
				/>{" "}
				<span className="ml-5 text-violeta">Procesando</span>
			</div>) : <></>}

			<form
				onSubmit={handleFormSubmit}
				noValidate
				name={idForm}
				id={idForm}
				method="post"
			>
				<div className="row">
					<div className="col-sm-12 col-lg-12 ">
						<label htmlFor="pmc">(*) Número PMC ({Texto.pmc_detail})</label>
						<input
							type="pmc"
							id="pmc"
							className={"form-control" + (isError ? " parsley-error" : "")}
							ref={pmcRef}
							required
							data-parsley-required-message="El número de PMC es requerido"
							data-parsley-pattern="^\d{4,15}$"
							data-parsley-pattern-message="Debe ser un número de 4 hasta 15 dígitos."
							autoComplete="one-time-code"
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-12 d-inline pl-3 mt-2 mb-2 text-small">
						En caso de no recordar el PMC:{" "}
						<Link
							to="#"
							title="Consulta Contribuyentes"
							rel="noopener noreferrer"
							className="text-decoration-underline"
							onClick={openModalSearchContribuyente}
						>
							No recuerdo mi PMC
						</Link>
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-sm-12 col-lg-12">
						<label htmlFor="password" className="label">(*)Crear Contraseña:</label>
						<div className="input-group">
							<input
								type='password'
								id="password"
								name="password"
								className="form-control"
								ref={passwordRef}
								data-parsley-required="true"
								data-parsley-required-message="La contraseña es requerido"
								data-parsley-pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!*?&#])[A-Za-z\d@$!*?&#]{8,}"
								data-parsley-pattern-message="La contraseña debe tener al menos 8 caracteres, una letra minúscula, una letra mayúscula, un número y un carácter especial (@$!*?&#)"
								minLength="8"
								data-parsley-length="[8,16]"
								data-parsley-minlength="8"
								autoComplete="one-time-code"
							/>
							<div className="input-group-append">
								<button className="btn-icono btn-secondary" type="button" onClick={e => handleOpenPassword(e, passwordRef)}>
									<i className='fa fa-eye'></i>
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="row mt-3" >
					<div className="col-sm-12 col-lg-12">
						<label htmlFor="confirmPassword">(*) Confirmar Contraseña:</label>
						<div className="input-group">
							<input
								type='password'
								id="confirmPassword"
								name="confirmPassword"
								className="form-control"
								ref={confirmPasswordRef}
								required
								data-parsley-equalto="#password"
								data-parsley-required-message="Confirmar contraseña es requerido"
								data-parsley-length="[8,16]"
								data-parsley-minlength="8"
								autoComplete="one-time-code"
							/>
							<div className="input-group-append">
								<button className="btn-icono btn-secondary" type="button" onClick={e => handleOpenPassword(e, confirmPasswordRef)}>
									<i className='fa fa-eye'></i>
								</button>
							</div>
						</div>
					</div>
				</div>

				{/* checkbox - terms */}
				<div className="form-check mt-3 ml-3">
					<input
						type="checkbox"
						id="terms"
						name="terms"
						className="form-check-input"
						checked={agreed}
						onChange={(e) => setAgreed(e.target.checked)}
						required
						data-parsley-required-message="Debe aceptar los términos y condiciones"
					/>
					<label className="form-check-label d-inline ml-3" htmlFor="terms">
						He leído y acepto los <a href="/terminos-y-condiciones" target="_blanck">términos y condiciones</a>
					</label>
				</div>

				<div className="d-flex d-sm-flex flex-sm-row justify-content-between mt-4">
					<div>
						<button type="button"  className="btn btn-fucsia" onClick={handleEventClickCancelar}>
							Cancelar
						</button>
					</div>
					<div>
						<button type="submit" className="btn btn-violeta">
							Continuar
						</button>
					</div>
				</div>
			</form>


			{/*v ============================================ 
			// modal pmc - contribuyente 
			==========================================================================================*/}
			{showModalSearch ? (
				<div
					className="modal fade "
					id={"modalSearchPmc"}
					tabIndex="-1"
					aria-labelledby="modalSearchPmcTitle"
					aria-hidden="true"
					data-backdrop="static"
					data-keyboard="false"
					style={{ overflowY: "auto" }}
				>
					<div className="modal-dialog modal-lg">
						<div className="modal-content ">
							<div className="modal-header text-butterfly-bush">
								<h4
									className="modal-title "
									id="modalSearchPmcTitle"
								>
									Buscador de Contribuyente
								</h4>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
								></button>
							</div>
							<div className="modal-body">
								<form
									name="formSearchPmc"
									id="formSearchPmc"
									method="post"
									noValidate
									onSubmit={handleSubmitSearchPmcForm}
									autoComplete="off"
								>
									<div className="col-12 ">
										<div className="input-group mb-3">
											<select
												className="form-select "
												name="type"
												required
												data-parsley-required="true"
											>
												<option key={0} value="CI">
													Cédula de Identidad
												</option>
												<option key={1} value="NIT">
													Número de Identificación Tributaria
												</option>
											</select>

											<input
												type="text"
												name="search"
												id="search"
												className="form-control input-uppercase"
												placeholder="*"
												pattern="[0-9-A-Z]+"
												data-parsley-pattern="[0-9-A-Z]+"
												ref={inputSearchPmcRef}
											/>

											<button
												className="btn btn-icono btn-secondary p-1"
												type="submit"
												data-toggle="tooltip"
												data-placement="top"
												title="Buscar"
											>
												<i className="fa fa-search" aria-hidden="true"></i>
											</button>
										</div>

									</div>
								</form>

								{loadingSearch && (
									<div className="col-12 mb-1">
										<img
											className="rounded mx-auto d-block"
											alt="pulse-loading"
											src={urlImageLoading}
											width="50"
										/>
									</div>
								)}

								{showPanelSearchResult ? (
									<div className="card widget widget-simple">
										<div className="card-body ">
											<div className="col-12 col-md-12 widget-header mb-3">
												<h5 className="text-maroon-flush">
													<i className="fas fa-bookmark me-2"></i> {Texto.seleccione_pmc}
												</h5>
											</div>
											<div className="widget-content">
												<div className="widget-body">
													<div className="row">
														<div className="col-12">

															{contribuyentesSearchList.map((contribuyente) => {
																return (
																	<>
																		<button
																			key={contribuyente.pmc}
																			onClick={(e) =>
																				handleEventSelectedPmc(e, contribuyente)
																			}
																			className={contribuyente.contribuyenteActualizado === "0" ? "btn btn-outline-dark btn-lg col-sm-3 mb-2" : "btn btn-outline-success btn-lg col-sm-3 mb-2"}
																			style={{ fontSize: '15px' }}
																			disabled={contribuyente.contribuyenteActualizado === "0" ? true : false}


																		>
																			{contribuyente.contribuyenteActualizado === "0" && (<i className="fa fa-exclamation-circle text-danger" title="No actualizado"></i>)} {" "}
																			{contribuyente.pmc}
																		</button> <span>&nbsp;</span>
																	</>
																);
															})
															}
														</div>
														{
															totalNoActualizado > 0 ?
																<div className="col-12 pt-4 text-justify" style={{ fontSize: '12px' }} >
																	<i className="fa fa-exclamation-circle text-danger"></i> {Texto.ruat_search_pmc_error}
																</div>
																:
																<></>
														}

													</div>
												</div>
											</div>
										</div>
									</div>
								) : (
									""
								)}

								{!showPanelSearchResult
									? inputSearchPmcRef
										? "No se ha encontrado ningún resultado"
										: ""
									: ""}
							</div>
						</div>
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

RegisterPmcFormulario.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default RegisterPmcFormulario;
