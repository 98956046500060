import React from "react";
import Languaje from "../../data/es";

const PerfilOptionUsuario = (props) => {
  /*const handleRedirectUrlClick = (ev, url) => {
    ev.preventDefault();
    if (url.includes("certificacion-admin"))
      window.location.href = url + "?cert=detallado";
    else window.location.href = url;
  };*/

  return (
    <section>
      <div className="row">
        <div className="col-12 mb-2">
          <h4>Perfil de Usuario</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-3 col-lg-2">
          <div className="single-news" onClick={props.hanldePerfilClick}>
            <div className="image">
              <div
                className="single-contact-info pointer"
                style={{
                  height: 136,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <i
                  className="fas fa-user-tie"
                  aria-hidden="true"
                  style={{ fontSize: "5em" }}
                ></i>
              </div>
              <p className="text-center fw-bold mb-2">{Languaje.perfil}</p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-3 col-lg-2">
          <div className="single-news" onClick={props.handleLoginHistory}>
            <div className="image">
              <div
                className="single-contact-info pointer"
                style={{
                  height: 136,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <i
                  className="fas fa-history"
                  aria-hidden="true"
                  style={{ fontSize: "5em" }}
                ></i>
              </div>
              <p className="text-center fw-bold mb-2">{Languaje.historial}</p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-3 col-lg-2">
          <div className="single-news" onClick={props.handleActivity}>
            <div className="image">
              <div
                className="single-contact-info pointer"
                style={{
                  height: 136,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <i
                  className="fa fa-tasks"
                  aria-hidden="true"
                  style={{ fontSize: "5em" }}
                ></i>
              </div>
              <p className="text-center fw-bold mb-2">{Languaje.actividades}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PerfilOptionUsuario;
