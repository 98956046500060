import { useState, useEffect } from 'react';

const Temporizador = ({ totalSegundos, fetch, toast, Texto, celular, handleVerificarRegistroConsecutivo }) => {

  const [seconds, setSeconds] = useState(totalSegundos);
  const [isTimerActive, setIsTimerActive] = useState(true); // Controla si el temporizador está activo o no

  useEffect(() => {
    if (!isTimerActive || seconds === 0) return; // No hacer nada si el temporizador no está activo o ya es 0

    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(intervalId); // Detener el temporizador cuando llegue a 0
          setIsTimerActive(false); // Pausar el temporizador
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId); // Limpiar intervalo al desmontar el componente
  }, [isTimerActive, seconds]);

  // Función para reiniciar el temporizador cuando se hace clic en el enlace
  const handleButtonResendCode = async (e) => {
    e.preventDefault(); // Evitar que el enlace redirija
		
    try {
			const dataJsonCodigo = await fetch.fetchGet(`api/usuario/registro/enviar-codigo/${celular}`);
			if (dataJsonCodigo && dataJsonCodigo.status) {				
				fetch.toast.success(dataJsonCodigo.message);
        setTimeout(() => {
          handleVerificarRegistroConsecutivo(celular);
          totalSegundos <= 5 ?setSeconds(60):setSeconds(totalSegundos); // Reiniciar los segundos
          setIsTimerActive(true); // Reactivar el temporizador
        }, 1000);
			}
		} catch (error) {
			toast.error('Ocurrio un error, vuelva a intentarlo');
			// dataJson.message
		}
	}


  // Si los segundos llegan a 0, mostrar el enlace
  let enlace = "";
  if (seconds === 0) {
    enlace = <button type="button" className="btn btn-link-cyan" onClick={handleButtonResendCode}> {Texto.phone_codigo_repeat} </button>
  }

  // Mientras los segundos no lleguen a 0, mostrar los segundos restantes
  return <>
      Puede solicitar otro código en <b>{seconds}</b> segundos.
			<br /> { enlace }
  </>  
};

export default Temporizador;
