import React, { useEffect } from 'react';
import Fetch from '../../utils/Fetch';
import Config from '../../../data/config';
import Links from '../../../data/link';
import TitlePage from '../../utils/TitlePage';
import Texto from '../../../data/es';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';

let breadcrumbs = [
    {
        title: Links[0].title,
        url: Links[0].url
    },
    {
        title: Links[47].title,
        url: Links[47].url
    },
    {
        title: Links[78].title,
        url: Links[78].url
    }
];

const Piet = (props) => {

    const fetch = new Fetch();
    fetch.setToast(toast);
    const history = useHistory();

    const titlePage = Texto.piet

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleRedirectUrlClick = (ev, url) => {
        ev.preventDefault();
        history.push(url );
    }

    return (
        <section className="items-grid section mt-5">
            <div className="container mt-4">
                <TitlePage titlePage={titlePage} breadcrumbs={breadcrumbs} position={'left'} leftfull={false} />

                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                        <div className="single-news wow fadeInUp" data-wow-delay=".2s" 
                        onClick={e => handleRedirectUrlClick(e,  Links[81].url)}
                        >
                            <div className="image">
                                <img className='thumb' alt='img-piet-vehiculo'
                                    src={Config[2].url + '/static/img/bt_vehiculo.png'} />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                        <div className="single-news wow fadeInUp" data-wow-delay=".2s" 
                        onClick={e => handleRedirectUrlClick(e, Links[80].url)}
                        rel="noreferrer noopener">
                            <div className="image">
                                <img className='thumb' alt='img-piet-inmueble'
                                    src={Config[2].url + '/static/img/bt_inmuebles.png'} />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                        <div className="single-news wow fadeInUp" data-wow-delay=".2s" 
                        onClick={e => handleRedirectUrlClick(e, Links[79].url)}
                        rel="noreferrer noopener">
                            <div className="image">
                                <img className='thumb' alt='img-piet-actividades-economicas'
                                    src={Config[2].url + '/static/img/bt_actividad_economica.png'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Piet;