import React, { useEffect } from "react";
import { useHistory } from "react-router-dom"; // useHistory
import Config from "../../data/config";
import Texto from "../../data/es";
import Links from "../../data/link";
import "./../style/style-simat.css";
import TitlePage from "../utils/TitlePage";

const Home = (props) => {
    const titlePage = Texto.publicity_home;
    const history = useHistory(); // <-- useHistory

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const breadcrumbs = [
        {
            title: Links[0].title,
            url: Links[0].url,
        },
        {
            title: Links[128].title,
            url: Links[128].url,
        },
        {
            title: Links[129].title,
            url: Links[129].url,
        },
    ];

    /*const handleButtonClickDDJJ = () => {
        // Realiza la navegación a '/ruta-destino'
        history.push(Links[130].url);
        //return <Redirect to={Links[130].url} />;
    };*/

    const handleButtonRedirect = (e, action) => {
        debugger
        if (action === "REQUISITOS")
            history.push(Links[61].url);
        if (action === "DDJJ")
            history.push(Links[130].url);

        if (action === "MIXTAS")
            history.push("/mixtas?liquidacion=patentes-publicidad");
    };

    return (
        <section className="items-grid section mt-5 mb-5 bg-gray">
            <TitlePage
                titlePage={titlePage}
                breadcrumbs={breadcrumbs}
                position={"left"}
                leftfull={false}
            />
            <div className="container mt-4">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <img
                            className="mx-auto d-block imagen-roja"
                            style={{ minWidth: '130px' }}
                            alt="img-licencia-funcionamiento"
                            src={Config[2].url + "/static/img/offcanvas/publicidad-cyan.png"}
                        />
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-md-4 col-lg-4 col-sm-12 glyphicon-align-center mt-4">
                        <div className="btn-publicity slide_diagonal" title={Links[130].title} onClick={(e) => handleButtonRedirect(e, "DDJJ")}>
                            <img src={Config[2].url + "/static/img/offcanvas/ddjj.png"} alt="Icono" className="icono-imagen mx-auto d-block " />
                            <div className="text-center">
                                Declaración Jurada
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-4 col-sm-12 glyphicon-align-center mt-4">
                        <div className="btn-publicity slide_diagonal">
                            <img src={Config[2].url + "/static/img/offcanvas/consulta_deuda.png"} alt="Icono" className="icono-imagen mx-auto d-block " />
                            <div className="text-center">
                                Consulta tu Deuda
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-4 col-sm-12 glyphicon-align-center mt-4">
                        <div className="btn-publicity slide_diagonal">
                            <img src={Config[2].url + "/static/img/offcanvas/qr_pago_atm.png"} alt="Icono" className="icono-imagen mx-auto d-block " />
                            <div className="text-center">
                                Pagos Qr
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-md-4 col-lg-4 col-sm-12 glyphicon-align-center mt-4">
                        <div className="btn-publicity slide_diagonal">
                            <img src={Config[2].url + "/static/img/offcanvas/qr_pago_atm.png"} alt="Icono" className="icono-imagen mx-auto d-block " />

                            <div className="text-center">Mixtas</div>
                        </div>
                    </div> */}

                    <div className="col-md-4 col-lg-4 col-sm-12 glyphicon-align-center mt-4">
                        <div className="btn-publicity slide_diagonal" title={'Liquidaciones Mixtas'} onClick={(e) => handleButtonRedirect(e, "MIXTAS")}>
                            <div className="text-center icono-imagen mx-auto d-block mt-4 mb-4 ">
                                <i className="fas fa-envelope-open-text fa-4x mx-auto d-block" alt="icono"></i>
                            </div>
                            <div className="text-center">
                                Liquidaciones Mixtas
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-4 col-sm-12 glyphicon-align-center mt-4">
                        <div className="btn-publicity slide_diagonal" onClick={(e) => handleButtonRedirect(e, "REQUISITOS")}>
                            <img src={Config[2].url + "/static/img/offcanvas/requirement.png"} alt="Icono" className="icono-imagen mx-auto d-block " />
                            <div className="text-center">Requisitos</div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-4 col-sm-12 glyphicon-align-center mt-4">
                        <div className="btn-publicity slide_diagonal " title={'Pagos Qr'} onClick={(event) => handleButtonRedirect(event, "PAGO_QR_OTROS")}>
                            <div className="text-center icono-imagen mx-auto d-block mt-4 mb-4 ">
                                <i className="fas fa-print fa-4x mx-auto d-block" alt="icono"></i>
                            </div>
                            <div className="text-center">
                                Imprimir Pagos Multas y Otros
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default Home;
