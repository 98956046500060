import Config from './config';
const constant = [
    {
        //title: 'Tipo Documento de Identifación',
        tipo: {
            ci: "CI",
            pasaporte: "CE"
        },
        //title: 'Estado Civil',
        estado_civil: {
            soltero: "1",
            casado: "2",
            viudo: "4",
        },
        //title: 'Tipo Contribuyente',
        contribuyente: {
            natural: "NATURAL",
            juridico: "JURIDICA",
        },
        //title: 'Ci Expedido',
        ci_expedido: {
            cbba: { new: 'CB', old: 'CBA', name: 'COCHABAMBA' },
            lapaz: { new: 'LP', old: 'LPZ', name: 'LA PAZ' },
            santacruz: { new: 'SC', old: 'SCZ', name: 'SANTA CRUZ' },
            oruro: { new: 'OR', old: 'ORU', name: 'ORURO' },
            potosi: { new: 'PO', old: 'POT', name: 'POTOSI' },
            pando: { new: 'PD', old: 'PAD', name: 'PANDO' },
            beni: { new: 'BN', old: 'BEN', name: 'BENI' },
            tarija: { new: 'TJ', old: 'TAJ', name: 'TARIJA' },
            sucre: { new: 'CH', old: 'CHU', name: 'CHUQUISACA' },
            sin_expedicion: { new: 'SE', old: 'SE', name: 'SIN EXPEDICION' }
        },
        //title: 'Objecto Tributario',
        tributario: {
            inmueble: "1",
            vehiculo: "2",
            patente_funcionamiento: "3",
            patente_publicidad_exterior: "4",
            patente_unica_municipal: "5",

        },
        //title: 'Estado',
        estado: {
            en_proceso: "EN_PROCESO",
            aprobado: "APROBADO",
            reaperturado: "REAPERTURADO",
            completado: "COMPLETADO",
            rechazado: "RECHAZADO",
            eliminado: "CANCELADO",
            pendiente_aprobacion: "PENDIENTE_APROBACION",
            bloqueado: "BLOQUEADO",
            activo: "ACTIVO",
            consolidado_ruat: "CONSOLIDADO_RUAT",
            certificado_transferencia: "CERTIFICACION_TRANSFERENCIA",
            registrar_transferencia_imto: "REGISTRAR_TRANSFERENCIA_IMTO",
            revisado: "REVISADO",
            certificado: "CERTIFICADO",
        },
        //title: 'permisos de usuario',  
        permission: {
            create: 'CREATE',
            update: 'UPDATE',
            delete: 'DELETE',
            reaperturar: 'REAPERTURAR',
            aprobar: 'APROBAR',
            rechazar: 'RECHAZAR',
            certificacion_visacion: 'CERTIFICACION_VISACION',
            update_tipo_actividad_economica: 'UPDATE_TIPO_ACTIVIDAD_ECONOMICA',
            transferencia_imto: 'TRANSFERENCIA_IMTO',
            bloqueado: 'BLOQUEADO',
            update_tipo_visacion_minuta: 'UPDATE_TIPO_VISACION_MINUTA'
        },
        //title: 'Storage',
        token: localStorage.getItem(Config[3].token), //=== null ? undefined:  localStorage.getItem('token').substring(0, localStorage.getItem('token').indexOf("::") ),
        derecho_admision: {
            permanente: 1,  //'PERMANENTE'
            temporal: 2,  //'TEMPORAL'
            natural: 1,     //
            juridico: 2,
            vehiculos: 7,
        },
        grupos: {
            contribuyente: "CONTRIBUYENTE",
            funcionario: "FUNCIONARIO",
            administrador: "ADMINISTRADOR",
        },
        modules: {
            actividad_economica: "ACTIVIDAD_ECONOMICA",
            prescripcion: "PRESCRIPCION",
            certificacion: "CERTIFICACION",
        },
        //title: 'tipos de certificacion inmuebles',  
        tipo_certificacion_inmueble: {
            pip: 'PIP',
            pipg: 'PIPG',
            pimt: 'PIMT',
            pc: 'PC',
            ndip: 'NDIP',
            ndimt: 'NDIMT',
            rcdt: 'RCDT',
            rdtp: 'RDTP',
            cd: 'CD'
        },
        //title: 'tipos de certificacion vehículos',  
        tipo_certificacion_vehiculo: {
            pip: 'PIP',
            pipg: 'PIPG',
            pimt: 'PIMT',
            pc: 'PC',
            ndip: 'NDIP',
            ndimt: 'NDIMT',
            rcdt: 'RCDT',
            rdtp: 'RDTP',
            cd: 'CD'
        },
        prescripcion: {
            liquidacion: 10
        },
        promocion: {
            prontoPago: '2022/12/31 23:59:59'
        },
        publicidad_step_register_update: [
            "Ingrese datos técnicos de la Publicidad y de la Actividad Económica, como la categoria, superficie (m2), ubicación espacial, nro. de la Actividad Económica, etc.",
            "Adjunte documentación solicitada en Formato de Documento Portátil (PDF).",
            "El emplazamiento de la publicidad se regirá bajo las condiciones técnicas y prohibiciones",
            "Datos del Propietario y/o Representante Legal",
            "Presione el botón \"Revisar DDJJ\" para visualizar la Declaración Jurada, el botón \"Confirmar Datos\" para validar. ",
            "Vista previa"
        ],

        publicidad_step_new: [
            "Seleccione el Clasificador de la publicidad e ingrese el número de derecho de admisión (FUR o CRAPE).",
            "Ingrese datos técnicos de la Publicidad y de la Actividad Económica, como la categoria, superficie (m2), ubicación espacial, nro. de la Actividad Económica, etc.",
            "Adjunte documentación solicitada en Formato de Documento Portátil (PDF).",
            "El emplazamiento de la publicidad se regirá bajo las condiciones técnicas y prohibiciones",
            "Datos del Propietario y/o Representante Legal",
            "Presione el botón \"Revisar DDJJ\" para visualizar la Declaración Jurada, el botón \"Confirmar Datos\" para validar. ",
            "Vista previa"
        ],
               
        publicidad_clasificador: {
            code_eventual: 'EVENTUAL',
            code_empresarial: 'EMPRESA',
            code_actividad: 'ACT_ECO',
        }
    }
];

export default constant;