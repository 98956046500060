import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Fetch from "../../components/utils/Fetch";
import Config from "../../data/config";
import Links from "../../data/link";
import TitlePage from "../utils/TitlePage";
import Texto from "../../data/es";
import Ayuda from "../utils/Ayuda";

import "react-toastify/dist/ReactToastify.css";

let breadcrumbs = [
  {
    title: Links[0].title,
    url: Links[0].url,
  },
  {
    title: Links[49].title,
    url: Links[49].url,
  },
];

const Certificacion = (props) => {
  const titlePage = Texto.certificacion;
  const fetch = new Fetch();
  fetch.setToast(toast);

  const history = useHistory();
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRedirectUrlClick = (ev, url, rubro) => {
    ev.preventDefault();

    const token = window.getParameterByName("csrf-token");
    const cliente = window.getParameterByName("lo");
    const expiraEn = window.getParameterByName("expiraEn");
    if (token) {
      if (cliente || expiraEn) {
        fetch
          .fetchGet("api/temporal-contribuyentes/by-csrkey/" + token)
          .then((dataJson) => {
            if (dataJson && dataJson.status === true) {
              switch (rubro) {
                case "inm":
                  history.push(
                    url +
                      "/" +
                      rubro +
                      "?csrf-token=" +
                      dataJson.csrfToken +
                      "&lo=" +
                      dataJson.lo +
                      "&expiraEn=" +
                      dataJson.expiraEn
                  );
                  break;
                case "veh":
                  alert("Certificación de Vehículos en construcción.");
                  break;
                case "aec":
                  alert(
                    "Certificación de Actividades Económicas en construcción."
                  );
                  break;
                default:
                  alert("No existe el rubro");
                  break;
              }
            } else {
              setShowMessage(true);
              setIntervalMessage();
            }
          });
      } else {
        setShowMessage(true);
        setIntervalMessage();
        toast.warning(
          "No es posible continuar con la obtención del certificado.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      setShowMessage(true);
      setIntervalMessage();
    }
  };

  const setIntervalMessage = () => {
    const interval = setInterval(() => {
      setShowMessage(false);
      clearInterval(interval);
    }, 10000);
  };

  return (
    <section className="items-grid section mt-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={titlePage}
          breadcrumbs={breadcrumbs}
          position={"left"}
          leftfull={false}
        />

        {showMessage ? (
          <div className="row">
            <div className="col-12 ">
              <div
                className="alert alert-warning wow fadeInUp"
                data-wow-delay=".3s"
                role="alert"
              >
                <div className="text-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
                <h4 className="alert-heading">
                  <i className="fas fa-exclamation-triangle"></i> Lo sentimos!
                </h4>
                <p>
                  No es posible acceder a este servicio, sino procedio al
                  REGISTRO CIUDADANO en la pagina{" "}
                  <a href="https://atencion-funcionario.cochabamba.bo">
                    https://atencion-funcionario.cochabamba.bo
                  </a>{" "}
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div
              className="single-news wow fadeInUp"
              data-wow-delay=".3s"
              onClick={(e) => handleRedirectUrlClick(e, Links[99].url, "veh")}
            >
              <div className="image">
                <img
                  className="thumb"
                  alt="img-certificacion-vehiculo"
                  src={Config[2].url + "/static/img/bt_vehiculo.png"}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div
              className="single-news wow fadeInUp"
              data-wow-delay=".3s"
              onClick={(e) => handleRedirectUrlClick(e, Links[50].url, "inm")}
            >
              <div className="image">
                <img
                  className="thumb"
                  alt="img-certificacion-inmueble"
                  src={Config[2].url + "/static/img/bt_inmuebles.png"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Ayuda />
    </section>
  );
};

export default Certificacion;
