import React, { useState, useEffect } from 'react';
//import { useHistory } from 'react-router-dom'
import Iframe from 'react-iframe'
import Config from '../../data/config';
import Fetch from './../../components/utils/Fetch';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from '../../components/Usuario/AuthService';
//import Texto from './../../data/es'
import PasosNavigationCambioRadicatoria from '../../components/utils/PasosNavigationCambioRadicatoria';
import { Link } from 'react-router-dom';

const FormVistaPrevia = (props) => {

    const { cambioRadicatoria } = props

    const fetch = new Fetch();
    fetch.setToast(toast);

    const [searchData, setSearchData] = useState(true);
    const csrfToken = window.getParameterByName("csrf-token");
    const auth = new AuthService()

    useEffect(() => {
        window.scrollTo(0, 0);
        downloadPdf(`${Config[0].url}report/cambio-radicatoria/${cambioRadicatoria.token}/preview?auth=${auth.getToken()}&csrf-token=${csrfToken}`);

    }, []);

    const downloadPdf = (url) => {
        document.getElementById('iframePreviewLicPdf').src = url
    }

    const handleOnladIFrame = (event) => {
        setSearchData(false);
    }

    const handleFinalizar = (event) => {
        window.location.href = Config[2].url;
    }

    const handleDownloadPdf = (event) => {
        event.preventDefault();
        window.location.href = Config[0].url + `report/cambio-radicatoria/${cambioRadicatoria.token}/download?auth=${auth.getToken()}&csrf-token=${csrfToken}`;
    }

    return (
        <>
            <div className="row">
                <PasosNavigationCambioRadicatoria titulo_paso1={'Datos Vehículo'} paso1_active={true} paso2_active={true} paso3_active={true} />
            </div>

            {searchData ?
                <div className="row">
                    <div className="col-12 mb-1">
                        <img className='rounded mx-auto d-block' alt='pulse-loading' src={"/static/img/pulse_200.gif"} width="70" />
                    </div>
                </div>
                : ""
            }

            <div className="row">
                <div className="col-12 col-md-12 col-lg-12 embed-container">
                    <Iframe
                        src=""
                        width="640"
                        height="360"
                        frameBorder="0"
                        allow="allow-same-origin allow-scripts allow-popups allow-forms"
                        allowFullScreen
                        id="iframePreviewLicPdf"
                        display="initial"
                        onLoad={handleOnladIFrame}
                    >
                    </Iframe>
                </div>
            </div>

            <div className="row mt-3 mb-3">
                <div className="col-10 col-md-10 col-lg-10 paddingTop15">
                    <p className="text-left">En caso no se visualize el pdf, puedes
                        <Link to="#" onClick={handleDownloadPdf} title="Descargar" style={{ paddingLeft: '5px' }} >
                            hacer click aquí para descargar el archivo PDF.
                        </Link>
                    </p>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 col-md-12 col-lg-12 text-end">
                    <div className="search-btn button">
                        <input name="Finalizar" type="submit" className="btn bg-maroon-flush btn-full-screen" value={'Finalizar'} onClick={handleFinalizar} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormVistaPrevia