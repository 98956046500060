import { useState, useEffect } from 'react';

const TemporizadorEmail = ({ totalSegundos, fetch, toast, Texto, correoElectronico, handleVerificarRegistroConsecutivo }) => {

  const [seconds, setSeconds] = useState(totalSegundos);
  const [isTimerActive, setIsTimerActive] = useState(true); // Controla si el temporizador está activo o no

  useEffect(() => {
    if (!isTimerActive || seconds === 0) return; // No hacer nada si el temporizador no está activo o ya es 0

    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(intervalId); // Detener el temporizador cuando llegue a 0
          setIsTimerActive(false); // Pausar el temporizador
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId); // Limpiar intervalo al desmontar el componente
  }, [isTimerActive, seconds]);

  // Función para reiniciar el temporizador cuando se hace clic en el enlace
  const handleEnviarEnlaceVerificacion = async (e) => {
    e.preventDefault(); // Evitar que el enlace redirija
		
    try {
			const form = new FormData();
			form.append("registro[email]", correoElectronico);
			
			const dataJson = await fetch.fetchPost(form, "api/usuario/registro/enviar-correo");
			if (dataJson && dataJson.status) {				
				fetch.toast.success(dataJson.message);
        setTimeout(() => {
          handleVerificarRegistroConsecutivo(correoElectronico)
          totalSegundos <= 5 ?setSeconds(60):setSeconds(totalSegundos); //setSeconds(totalSegundos); // Reiniciar los segundos
          setIsTimerActive(true); // Reactivar el temporizador
        }, 1000);
			}
		} catch (error) {
			toast.error('Ocurrio un error, vuelva a intentarlo');
			// dataJson.message
		}
	} // end handleEnviarEnlaceVerificacion


  // Si los segundos llegan a 0, mostrar el enlace
  let enlace = "";
  if (seconds === 0) {
    enlace = <button className="btn btn-link-cyan" onClick={(e)=>handleEnviarEnlaceVerificacion(e)}> {Texto.email_link_verify} </button>
  }

  // Mientras los segundos no lleguen a 0, mostrar los segundos restantes
  return <>
      Puedes solicitar otro enlace de verificación en <b>{seconds}</b> segundos.
			<br /> { enlace }
  </>  
};

export default TemporizadorEmail;
