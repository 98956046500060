import React, { useState, useEffect, useRef } from "react";
import Fetch from "../../../components/utils/Fetch";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import Texto from "../../../data/es";
import CountDownTimer from "../../utils/CountDownTimer";


const ResetPasswordMetodoInfo = ({
	onSubmit,
	urlLoading,
	setDescriptionInicio,
	handleChangeVisibleOrData,
	dataFormReset
}) => {
	const idForm = "form-method-info";
	const tipoSeleccionado = dataFormReset.metodo;
	const celular = dataFormReset.phone;
	const [codePhone, setCodePhone] = useState(null);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const fetch = new Fetch();
	fetch.setToast(toast);
	const isMounted = useRef(false);
	// contador de segundos
	const secondsRemaining = CountDownTimer(60);

	useEffect(() => {
		isMounted.current = true;

		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		// verificar el codigo en caso de celular
		window.jQuery("#" + idForm).parsley().validate();
		if (window.jQuery("#" + idForm).parsley().isValid() && isMounted.current) {

			if (tipoSeleccionado.indexOf('@') > 0) {
				handleVerifyLinkEmailReset(dataFormReset.email, dataFormReset.token, e.target);
			} else {
				handleVerifyCodePhone(celular, codePhone, e.target);
			}

		}
	};

	// verificar code para celular
	const handleVerifyCodePhone = async (celular, codigo, target) => {

		try {
			setLoading(true);
			setError(null);
			const form = new FormData();
			form.append("usuario[celular]", celular);
			form.append("usuario[codigo]", codigo);
			let dataResponse = await fetch.fetchPost(form, "api/password/reset/verify-code", target);
			if (dataResponse && dataResponse.status) {
				console.log('realizando la verificacion : ', dataResponse);
				!dataResponse.data.verificado ? toast.warning(dataResponse.message) : toast.success(dataResponse.message);

				if (dataResponse.data.verificado && isMounted) {
					onSubmit('phone');
				} else {
					setError(dataResponse.message);
				}
			}

		} catch (error) {
			toast.error(error.message);
		} finally {
			setLoading(false);
		}
	}

	// verificar si uso link enviado al email
	const handleVerifyLinkEmailReset = async (email, token, target) => {
		try {
			setLoading(true);
			const form = new FormData();
			form.append("email", email);
			form.append("token", token);

			let dataResponse = await fetch.fetchPost(form, "api/password/reset/verify-link-email", target);
			if (dataResponse && dataResponse.status) {
				!dataResponse.data.verificado ? toast.warning(dataResponse.message) : toast.success(dataResponse.message);
				if (dataResponse.data.verificado && isMounted) {
					onSubmit('phone');
				}
			}

		} catch (error) {
			toast.error(error.message);
		} finally {
			setLoading(false);
		}
	}

	const handleEventClickVolver = () => {
		// Aquí puedes agregar la lógica que deseas ejecutar cuando se hace clic		
		// setTitlePageInicio(Texto.reset_title);
		setDescriptionInicio(Texto.reset_description_method);
		handleChangeVisibleOrData('form_method', true, true);
		handleChangeVisibleOrData('form_method_info', false, true);
	};

	// onchange code phone
	const onchangeCodePhone = (e) => {
		setError('');
		setCodePhone(e.target.value)
	}

	// btn - reenviar email
	const handleButtonResendEmail = async () => {
		// handleChangeDataOrForm('tokenchange', tokenchange, false);
		setLoading(true);
		try {
			const dataJson = await fetch.fetchGet(
				`api/password/reset/resend-link-email/${dataFormReset.token}`
			);
			if (dataJson && dataJson.status) {
				console.log("resend email: ", dataJson);
				if (dataJson.data.enviado) {
					toast.success(dataJson.message);
					handleChangeVisibleOrData('tokenchange', dataJson.data.token, false);
				}

			}
		} catch (error) {
			//toast.error('Ocurrio un error, vuelva a intentarlo');
			toast.error(error.message);
			// dataJson.message
		} finally {
			setLoading(false);
		}
	}

	// btn enviar codigo para WhatsApp	
	const handleButtonResendCode = async () => {

		setCodePhone('');
		setLoading(true);
		try {
			const dataJson = await fetch.fetchGet(
				`api/password/reset/resend-code/${dataFormReset.token}`
			);
			if (dataJson && dataJson.status) {
				console.log("resend code phone: ", dataJson);
				if (dataJson.data.enviado) {
					toast.success(dataJson.message);
					handleChangeVisibleOrData('tokenchange', dataJson.data.token, false);
				} else {
					toast.error(dataJson.message);
				}
			} /*else {
				toast.error(dataJson.message);
			}*/
		} catch (error) {
			//toast.error('Ocurrio un error, vuelva a intentarlo');
			toast.error(error.message);
		} finally {
			setLoading(false);
		}
	}


	return (
		<>

			{loading && (<div className="col-12 mb-1 mx-auto d-block text-center">
				<img
					className="rounded"
					alt="pulse-loading"
					src={urlLoading}
					width="40"
				/>{" "}
				<span className="ml-5 text-violeta">Procesando</span>
			</div>)}

			<form
				onSubmit={handleSubmit}
				noValidate
				name={idForm}
				id={idForm}
				method="post"
			>
				<input type='hidden' defaultValue={tipoSeleccionado} />

				{
					(tipoSeleccionado.indexOf('@') > 0) ?
						<>
							<div className="text-center mb-3">
								<button className="btn">
									<i className='fa fa-envelope-square fa-4x text-black-50'></i>
								</button>
							</div>

							<div className="text-justify col-lg-12 col-md-12 col-sm-12 mx-auto border-radius-035 bg-gray-5 p-3">
								Puedes solicitar otro enlace de verificación <b>{secondsRemaining}</b> segundos.
								<br />
								{
									secondsRemaining === 0 ? <button type="button" className="btn btn-link-cyan" onClick={handleButtonResendEmail}> {Texto.email_link_verify} </button> : <></>
								}
							</div>
						</>
						:
						<>
							<div className="form-group">
								<label htmlFor="codecelular">(*) Código de Verificación:</label>
								<input
									type="number"
									id="codecelular"
									name="codecelular"
									className={error ? 'form-control form-control-lg parsley-error' : 'form-control form-control-lg'}									
									value={codePhone}
									onChange={(e) => onchangeCodePhone(e)}
									required
									data-parsley-required-message="El código de verificación es requerido"
									data-parsley-type="number"
									data-parsley-number-message="Ingrese solo ńumeros"
									data-parsley-pattern="^\d{5}$"
									data-parsley-pattern-message="El número debe tener 5 dígitos"
								/>
							</div>

							{error !== null && (
								<div className="parsley-errors-list filled">{error}</div>
							)}

							<div className="text-justify col-lg-12 col-md-12 col-sm-12 mx-auto border-radius-035 bg-gray-5 mt-3 p-3">
								Puede solicitar otro código en <b>{secondsRemaining}</b> segundos.
								<br />
								{
									secondsRemaining === 0 ? <button type="button" className="btn btn-link-cyan" onClick={handleButtonResendCode}> {Texto.phone_codigo_repeat} </button> : <></>
								}
							</div>
						</>
				}

				<div className="d-flex d-sm-flex flex-sm-row justify-content-between mt-2">
					<div>
						<button
							type="button"
							className="btn btn-fucsia"
							onClick={handleEventClickVolver}
						>
							Volver
						</button>
					</div>
					<div>
						<button type="submit" className="btn btn-violeta">
							Continuar
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

ResetPasswordMetodoInfo.propTypes = {
	// options: PropTypes.arrayOf(
	// 	PropTypes.shape({
	// 		value: PropTypes.string.isRequired,
	// 		label: PropTypes.string.isRequired,
	// 	})
	// ).isRequired,
	urlLoading: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default ResetPasswordMetodoInfo;
