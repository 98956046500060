import { toast } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";

export default class Fetch {
  success(message, position) {
    if (position === "RIGHT") {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }

  warning(message, position) {
    if (position === "RIGHT") {
      toast.warn(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }

  getToast() {
    return toast;
  }
}
