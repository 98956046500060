import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

//const TitlePage = ({titlePage, url, title}) => {
const TitlePage = ({ titlePage, breadcrumbs, position, leftfull }) => {
	let text_left = "",
		content_justify;
	switch (position) {
		case "center": {
			text_left = "text-center";
			content_justify = "justify-content-center";
			break;
		}
		case "left": {
			text_left = "text-left";
			content_justify = "justify-content-left";
			break;
		}
		default:
			text_left = "text-left";
			content_justify = "justify-content-left";
			break;
	}
	return (
		<div className="breadcrumb-area bg-img bg-overlay jarallax mt-3">
			<div className={leftfull ? "container-fluid h-100" : "container h-100"}>
				<div className="row align-items-center">
					<div className="col-12 padding-left-0 padding-right-0">
						<div className={`breadcrumb-content ${text_left}`}>
							<h2 className="page-title text-butterfly-bush text-capitalize">
								{titlePage}
							</h2>
							<nav
								aria-label="breadcrumb"
								style={{ borderBottom: "1px solid #e7e7e7" }}
							>
								<ol className={`breadcrumb  ${content_justify}`}>
									{breadcrumbs &&
										breadcrumbs.map((breadcrumb, key) => ({
											...(key !== breadcrumbs.length - 1 ? (
												<li key={key} className="breadcrumb-item">
													<Link
														to={{
															pathname: breadcrumb.url,
														}}
														className={`breadcrumb-item  text-butterfly-bush ${
															key === breadcrumbs.length - 1 ? " active " : ""
														}`}
														style={{ marginRight: "0rem" }}
														onClick={breadcrumb.eventClick}
													>
														{breadcrumb.title}
													</Link>
												</li>
											) : (
												<li
													key={key}
													aria-current="page"
													className="breadcrumb-item text-butterfly-bush active"
												>
													{breadcrumb.title}
												</li>
											)),
										}))}
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

TitlePage.prototype = {
	titlePage: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};

export default TitlePage;