import React, { Component } from "react";

class ValidatePlaca extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleSearchByPlacaOrPoliza =
      this.handleSearchByPlacaOrPoliza.bind(this);
    this.handleKeyEnterSearchByPlacaOrPoliza =
      this.handleKeyEnterSearchByPlacaOrPoliza.bind(this);
    this.handlePlacaOnChange = this.handlePlacaOnChange.bind(this);
    //this.handleCancelClick = this.handleCancelClick.bind(this);
    this.state = {
      verifyPlaca: false,
      inputPlaca: "",
      message: "",
      searchData: false,
    };
  }

  handlePlacaOnChange(event) {
    event.preventDefault();
    var button = document.getElementById("btnModalSmallSubmit");

    if (event.currentTarget.value.length >= 6) {
      button.classList.remove("btn-disabled");
      this.setState({
        verifyPlaca: true,
        inputPlaca: event.currentTarget.value,
      });
    } else {
      button.classList.add("btn-disabled");
      this.setState({ verifyPlaca: false, inputPlaca: "" });
    }
  }

  handleKeyEnterSearchByPlacaOrPoliza(event) {
    if (event.charCode === 13) {
      if (event.currentTarget.value.length >= 6) {
        event.preventDefault();
        this.props.validateForm();
        let placaOrPolica = event.target.value;
        if (placaOrPolica !== "" && placaOrPolica.length <= 7) {
          this.searchVehiculo(placaOrPolica);
        }
      }
    }
  }

  handleSearchByPlacaOrPoliza(event) {
    event.preventDefault();
    this.props.validateForm();
    let placaOrPolica = document.getElementById("inputModalPlaca").value;
    if (placaOrPolica !== "" && placaOrPolica.length <= 7) {
      this.searchVehiculo(placaOrPolica);
    }
  }

  searchVehiculo(placaOrPolica) {
    var buttonSubmit = document.getElementById("btnModalSmallSubmit");
    var buttonCancel = document.getElementById("btnModalSmallCancel");
    buttonSubmit.classList.add("btn-disabled");
    buttonCancel.classList.add("btn-disabled");
    this.setState({ searchData: true });
    let self = this;

    const csrfToken = window.getParameterByName("csrf-token");
    this.props.fetch
      .fetchGet(
        "api-ruat/vehiculos/consulta-datos-otros-municipios/" +
          placaOrPolica +
          "/" +
          csrfToken
      )
      .then((dataJson) => {
        debugger;
        if (dataJson && dataJson.status && dataJson.data) {
          if (dataJson.data.vehiculo) {
            debugger
            const tempVehiculo = dataJson.data.vehiculo;
            const vehiculo = {
              datosTecnicos: tempVehiculo.datosTenicos,
              datosDeudas: tempVehiculo.deudas,
              datosObservacion: dataJson.data.vehiculo.lo,  //qutar
              observado: tempVehiculo.observado,
              vehiculo: tempVehiculo.vehiculo,
              placa: placaOrPolica,
            };
            self.props.closeModal(vehiculo, "");
          } else {
            self.props.closeModal(undefined, dataJson.message);
            self.props.fetch.toast.warning(dataJson.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        }

        buttonSubmit.classList.remove("btn-disabled");
        buttonCancel.classList.remove("btn-disabled");
        this.setState({ searchData: false });
      });
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 padding-right-0 text-center mt-4 mb-4">
            <i
              className="far fa-file-alt"
              aria-hidden="true"
              style={{ fontSize: "8.5rem" }}
            ></i>
            <h4 className="text-center mt-4 text-butterfly-bush">
              Buscar vehículo
            </h4>
          </div>
        </div>

        <div className="row">
          {this.state.message !== "" ? (
            <div className="col-12 padding-left-0 padding-right-0">
              <div
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                <strong>Atención!</strong> {this.state.message}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="row">
          <div className="col-12 padding-right-0">
            {this.state.messageDeclarcionJurada}
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="form-group">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    Nº PLACA
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control input-uppercase"
                  placeholder="PLACA"
                  aria-label="Placa"
                  id="inputModalPlaca"
                  aria-describedby="basic-addon1"
                  //onChange={this.handlePlacaChange}
                  data-parsley-required="true"
                  data-parsley-maxlength="7"
                  maxLength="7"
                  data-parsley-minlength="6"
                  minLength="6"
                  required
                  pattern="[a-zA-Z0-9]+"
                  onKeyPress={this.handleKeyEnterSearchByPlacaOrPoliza}
                  onChange={this.handlePlacaOnChange}
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.searchData ? (
          <div className="row mt-3">
            <div className="col-12 mb-1">
              <img
                className="rounded mx-auto d-block"
                alt="pulse-loading"
                src={"/static/img/pulse_200.gif"}
                width="70"
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row mt-3">
          <div className="col-12 col-md-6 col-lg-6 mb-3 text-start">
            <div className="col-12 search-btn button ">
              <input
                className="btn bg-maroon-flush btn-full-screen"
                name="submit"
                id="btnModalSmallCancel"
                type="submit"
                value="Cancelar"
                onClick={this.props.handleCancelClick}
              />
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-6 mb-3 text-end">
            <div className="col-12 search-btn button ">
              <input
                className="btn btn-disabled btn-full-screen"
                name="submit"
                id="btnModalSmallSubmit"
                type="submit"
                value="Verificar"
                //onClick={this.handleClickPlaca}
                onClick={this.handleSearchByPlacaOrPoliza}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ValidatePlaca;
