import React, { useState, useRef, useEffect } from "react";
import Links from "./../../../data/link";
import HeaderTitlecard from "./../_common/HeaderTitleCard";
import Config from './../../../data/config'
import Consulta from "../Consulta";


const Step5Finish = (props) => {
    const { fetch, backClick, toast, auth, history, stepNumber, Constant, dataAdvertising } = props;

    const consultaSQL = new Consulta();

    const [isDataConfirm, setIsDataConfirm] = useState(false);
    const [isCheckDDJJ, setIsCheckDDJJ] = useState(false);

    if (!auth.loggedIn()) history.replace(Links[4].url);

    useEffect(() => {
        debugger;
        setIsCheckDDJJ(false);
    }, []);


    const viewDDJJ = (event) => {
        event.preventDefault();
        const url = `${Config[10].url}/api/advertising/report-ddjj/${dataAdvertising.numero}/${dataAdvertising.token}/ddjj?report=preview&auth=${auth.getToken()}`;
        window.open(url, '_blank');
        setTimeout(() => setIsCheckDDJJ(true), 1000);
    };

    const handleConfirmData = async (event) => {

        event.preventDefault();
        setIsDataConfirm(false);
        window.createBootbox("¿Está seguro de continuar con la operación? Esta acción bloqueará la Declaración Jurada.", async (result) => {
            if (result && dataAdvertising) {
                debugger;
                const query = consultaSQL.queryUpdateEstadoAdvertising(Constant[0].estado.en_proceso, Constant[0].estado.aprobado, dataAdvertising.token);
                try {
                    const data = await fetch.fetchPostGraphQl(query, "/graphql");
                    if (data.updateEstadoAdvertising?.result) {
                        debugger;
                        toast.success(data.updateEstadoAdvertising.message);
                        setIsDataConfirm(true);
                    } else {
                        toast.error(data.updateEstadoAdvertising.message);
                    }
                } catch (error) {
                    toast.error("Ocurrió un error al intentar completar DDJJ");
                }
            } else {
                toast.error("Se canceló la confirmación de datos de DDJJ.");
            }
        });
    };


    const printDDJJ = (event) => {
        event.preventDefault();
        viewDDJJ(event);
    };


    const handleCloseDDJJ = (event) => {
        event.preventDefault();
        setTimeout(() => history.push('/actividades-economicas/publicidad/lista'), 1000);
    };

    // proxima ventana    
    return (
        <div className="main-wizard ">

            <HeaderTitlecard titulo="Vista Previa" descripcion={Constant[0].publicidad_step_new[5]} visibleRequired={false} />

            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>

            <div className="text-wizard congrats">
                <h3 className="text-success">¡Publicidad modificada con éxito!</h3>
            </div>

            <p className="text-justify p-5 text-secondary">
                Los datos modificados de la publicidad se registraron con éxito, proceda a revisar la Declaración Jurada presionando el botón "Revisar DDJJ", si todo esta correcto proceda a presionar el botón "Confirmar Datos".
            </p>

            <form
                className="contact__form"
                id={"formValidePropietario"}
            >

                <div className="row">
                    <div className="col-sm-12 col-md-4 mb-2 text-center">
                        {isCheckDDJJ && !isDataConfirm &&
                            <a className="back_button btn btn-fucsia btn-md" onClick={backClick}>Volver a editar</a>
                        }
                    </div>

                    <div className="col-sm-12 col-md-4 mb-2 text-center">
                        {!isDataConfirm &&
                            <button className="btn btn-secondary btn-md" onClick={viewDDJJ} title="Revisar DDJJ">
                                <i className="fas fa-file-pdf me-2"></i> Revisar DDJJ
                            </button>}
                        {isDataConfirm &&
                            <button className="btn btn-fucsia btn-md" onClick={printDDJJ}>
                                <i className="fas fa-print me-2"></i> Imprimir DDJJ
                            </button>
                        }
                    </div>

                    <div className="col-sm-12 col-md-4 mb-2 text-center">
                        {(isCheckDDJJ && !isDataConfirm) &&
                            <button className="btn btn-violeta btn-md" onClick={handleConfirmData} title="Confirmar Datos de la DDJJ">
                                <i className="fas fa-check-circle me-2"></i> Confirmar Datos
                            </button>
                        }

                        {isDataConfirm &&
                            <button className="btn btn-violeta btn-md" onClick={handleCloseDDJJ}>
                                <i className="fas fa-sign-out-alt me-2"></i> Salir
                            </button>
                        }
                    </div>
                </div>

            </form>
        </div>
    );
};

export default Step5Finish;
