import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Fetch from '../../components/utils/Fetch';
import Texto from '../../data/es';
import Config from '../../data/config';
import '../style/parsley.css';

var fetch = null
class Contact extends Component {

    constructor(props, context) {
        super(props, context);

        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.id_modal = "modalFormContacts"
        fetch = new Fetch();
        fetch.setToast(toast);
    }

    onSubmitForm(event) {
        event.preventDefault();
        window.jQuery("#" + event.target.getAttribute('id')).parsley().validate();
        const form = new FormData(event.target);

        if (window.jQuery("#" + event.target.getAttribute('id')).parsley().isValid()) {
            fetch.fetchPost(form, 'api/contact/create', event.target).then(dataJson => {
                if (dataJson && dataJson.status === true) {
                    document.getElementsByClassName("contact__msg")[0].style.display = "inline-block"
                    toast.success(dataJson.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            })
        } else {
            toast.warn(Texto.campos_obligatorios, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    render() {
        return (
            <>
                <div className="modal fade" id={this.id_modal} tabIndex="-1" aria-labelledby="contactModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="contactModalCenterTitle">Mantengamonos en Contacto</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.handleCloseOnClick}></button>
                            </div>
                            <div className="modal-body">

                                <div className="row">
                                    <div className="col-12 col-md-5 offset-md-2 col-lg-6 offset-lg-0">
                                        <p>{Texto.description_contactanos}</p>
                                        <br />
                                        <div className="row">
                                            <div className="col-1 no-padding text-center">
                                                <div className="contact-icon text-blue">
                                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div className="col-11">
                                                <p className="text-small">Plaza Colon, acera este, Calle San Martin. <br />Nro. 448, Cochabamba</p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-1 no-padding text-center">
                                                <div className="contact-icon text-blue">
                                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div className="col-11">
                                                <p className="text-small">4 4258030</p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-1 no-padding">
                                                <div className="contact-icon text-blue">
                                                    <i className="fa fa-globe" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div className="col-11 xs-margin-50px-bottom">
                                                <p className="text-small">{Config[2].url}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-5 offset-md-2 col-lg-6 offset-lg-0">

                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <div className="alert alert-success contact__msg" style={{ display: "none", role: "alert" }}>
                                                    Su mensaje fue enviado.
                                                </div>
                                            </div>
                                        </div>

                                        <form action="" className="contact__form needs-validation" name="formContact" id="formContact"
                                            method="post" noValidate onSubmit={this.onSubmitForm} style={{ width: '100%' }}>
                                            <div className="row">
                                                <div className="col-md-12 mb-3">
                                                    <input name="contact[name]" type="text" className="form-control" placeholder="Nombre" required />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12 mb-3">
                                                    <input name="contact[email]" type="email" className="form-control" placeholder="Email" required />
                                                </div>
                                            </div>

                                            <div className="row ">
                                                <div className="col-12 mb-3">
                                                    <textarea name="contact[comment]" className="form-control" rows="5" placeholder="Comentario" required ></textarea>
                                                </div>
                                            </div>

                                            <div className="row mt-1 text-end">
                                                <div className="col-12 search-btn button ">
                                                    <input className="btn btn-full-screen" name="submit" type="submit" value="Enviar" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer enableMultiContainer containerId={'Z'}
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <ToastContainer />
            </>
        );
    }
}

export default Contact;
