import React, { useState, useEffect, useCallback } from "react";
// Importa tus componentes de formulario
import RegisterPhoneFormulario from "./RegisterPhoneFormulario";
import RegisterPhoneCodeFormulario from "./RegisterPhoneCodeFormulario";
import RegisterEmailFormulario from "./RegisterEmailFormulario";
import TitleHeaderPage from "../Utils/TitleHeaderPage";
import RegisterEmailVerify from "./RegisterEmailVerify";
import RegisterPmcFormulario from "./RegisterPmcFormulario";
import RegisterUserMessage from "./RegisterUserMessage";
import Texto from "../../../data/es";
import { ToastContainer, toast } from "react-toastify";
import Fetch from "../../utils/Fetch";

function RegisterUser() {
  const urlImgLoading = "/static/img/pulse_200.gif";
  const fetch = new Fetch();
  fetch.setToast(toast);

  const [titlePage, setTitlePage] = useState(Texto.register_user_title_init);
  const [description, setDescription] = useState(Texto.register_user_description_init);  

  // if (!auth.loggedIn()) history.replace(Links[4].url)

  const [dataForm, setDataForm] = useState(() => 
  {
    const storedData = localStorage.getItem("dataForm")
    const dataInicio = {
      phone: "",
      phonecode: "",
      email: "",
      email_valido: "",
      pmc: "",
      password: "",
      token: "",
      fecha: new Date() // Añadir la fecha actual
    };

    return storedData? JSON.parse(storedData) : dataInicio;
  }
  );

  // Estado para manejar el paso actual, recuperado de localStorage al cargar la página
  const [currentStep, setCurrentStep] = useState(() => {
    const savedStep = localStorage.getItem("currentStep");
    return savedStep ? parseInt(savedStep, 10) : 1; // Paso 1 por defecto si no hay nada en localStorage
  });


  // Función para calcular la diferencia de tiempo y borrar los datos si han pasado más de 30 minutos
  const checkAndClearLocalStorage = useCallback(() => {
    const storedData = localStorage.getItem("dataForm");
    
    if (storedData) {     
      const parsedData = JSON.parse(storedData);
      const { fecha } = parsedData;
      const savedDate = new Date(fecha);
      
      savedDate.setMinutes(savedDate.getMinutes() + 120);
      const savedTime = savedDate.getTime();
      
      const dateNow = new Date();
      const currentTime = dateNow.getTime();     
      
      if (currentTime > savedTime) {
        // Borrar los datos si han pasado más de 30 minutos
        localStorage.removeItem("dataForm");
        localStorage.removeItem("currentStep");

        setDataForm({
          phone: "",
          phonecode: "",
          email: "",
          email_valido: "",
          pmc: "",
          password: "",
          token: "",
          fecha: new Date()
        });
        setCurrentStep(1);
        toast.info("Los datos han sido borrados por inactividad.");
      } 
    }
  }, []);


  /******************************************************************************************** */
  // titulo y descripcion por step
  const titleStepCurrent = () => {
    if (currentStep === 1) {
      setTitlePage(Texto.register_user_title_init);
      setDescription(Texto.register_user_description_init);
    }

    if (currentStep === 2) {
      setTitlePage(Texto.register_phone_code);
      setDescription(Texto.register_phone_code_description + " " + maskPhoneNumber(dataForm.phone, 5));
    }

    if (currentStep === 3) {
      setTitlePage(Texto.register_email);
      setDescription(Texto.register_email_description);
    }

    if (currentStep === 4) {
      setTitlePage(Texto.register_info_email_title);
      setDescription(Texto.register_info_email_description + " " + maskEmail(dataForm.email, 5));
    }

    if (currentStep === 5) {
      setTitlePage(Texto.register_pmc_title);
      setDescription("");
    }

    if (currentStep === 6) {
      setTitlePage(Texto.register_title_end_ok);
      setDescription("");
    }
  }
  /* ******************************************************************************************* */
  /* ******************************************************************************************* */  
  // info dataForm
  useEffect(() => {
    const storedData = localStorage.getItem("dataForm");
    if (storedData) {
      setDataForm(JSON.parse(storedData));      
    }

    window.addEventListener("storage", (event) => {
      if (event.key === "dataForm") {
        const updatedData = JSON.parse(event.newValue);
        if (updatedData) {
          setDataForm(updatedData);
        }
      }
    });  

    return () => {
      window.removeEventListener("storage", () => { });
    };
  }, []); 

  
  useEffect(() => {
    const intervalId = setInterval(() => {
      checkAndClearLocalStorage();
    }, 90000); // Verifica cada 60 segundos (1 minuto) (2*60000)

    return () => clearInterval(intervalId); // Limpiar intervalo al desmontar
  }, [checkAndClearLocalStorage]);


  useEffect(() => {
    localStorage.setItem("dataForm", JSON.stringify(dataForm));   
  }, [dataForm]);

  // Guardar el paso actual en localStorage cada vez que cambie
  useEffect(() => {
    localStorage.setItem("currentStep", currentStep);
    titleStepCurrent();
  }, [currentStep]);  


  // Manejadores de eventos para cada paso del formulario
  const handlePhoneSubmit = (celular) => {
    // setTitlePage(Texto.register_phone_code);
    // setDescription(Texto.register_phone_code_description + " " + maskPhoneNumber(celular, 5));
    handleChangeDataForm("phone", celular);
    setCurrentStep(2); // Avanzar al paso 2    
  };

  const handlePhoneCodeSubmit = (codePhone) => {
    // setTitlePage(Texto.register_email);
    // setDescription(Texto.register_email_description);
    handleChangeDataForm("phonecode", codePhone);
    setCurrentStep(3); // Avanzar al paso 3
  };

  const handleEmailSubmit = (email) => {
    // setTitlePage(Texto.register_info_email_title);
    // setDescription(Texto.register_info_email_description + " " + maskEmail(email, 5));
    handleChangeDataForm("email", email);
    setCurrentStep(4); // Avanzar al paso 4
  };

  const handleEmailInfoSubmit = (emailestado) => {
    // setTitlePage(Texto.register_pmc_title);
    handleChangeDataForm("email_valido", emailestado);
    setCurrentStep(5); // Avanzar al paso 5
  };

  const handlePmcInfoSubmit = (pmc) => {
    // setTitlePage(Texto.register_title_end_ok);
    // setDescription("");
    handleChangeDataForm("pmc", pmc);
    setCurrentStep(6); // Finalizar
  };

  const handleChangeDataForm = (key, value) => {
    setDataForm((prevPreferences) => ({
      ...prevPreferences,
      [key]: value,      
    }));
  };

  // Funciones de máscara
  const maskEmail = (email, visibleLength) => {
    if (!email) return "";
    const atIndex = email.indexOf("@");
    if (atIndex <= visibleLength) return email;
    const visiblePart = email.substring(0, visibleLength);
    const maskedPart = "*".repeat(atIndex - visibleLength);
    return visiblePart + maskedPart + email.substring(atIndex);
  };

  const maskPhoneNumber = (phone, visibleLength) => {
    if (!phone) return "";
    const visiblePart = phone.substring(0, visibleLength);
    const maskedPart = "*".repeat(phone.length - visibleLength);
    return visiblePart + maskedPart;
  };

  // ==================================================================== //
  // ==================================================================== //

  return (
    <div className="section mt-5">
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-10 col-sm-12 col-lg-5 mx-auto d-block">
            <div className="card card-minw-25">

              <div className="card-body">
                <TitleHeaderPage titlePage={titlePage} description={description} visible={false} step={currentStep} totalEstep={6} />

                {currentStep === 1 && dataForm && (
                  <RegisterPhoneFormulario
                    onSubmit={handlePhoneSubmit}
                    dataFormPhone={dataForm}
                    urlImageLoading={urlImgLoading}
                  />
                )}

                {currentStep === 2 && dataForm && dataForm.phone > 0 && (
                  <RegisterPhoneCodeFormulario
                    onSubmit={handlePhoneCodeSubmit}
                    dataFormUser={dataForm}
                    fetchInit={fetch}
                    urlImageLoading={urlImgLoading}
                    setTitlePage={setTitlePage}
                    setDescription={setDescription}
                    setCurrentStep={setCurrentStep}
                  />
                )}

                {currentStep === 3 && dataForm && dataForm.phone> 0 && (
                  <RegisterEmailFormulario
                    onSubmit={handleEmailSubmit}
                    correoelectronico={dataForm.email}
                    celular={dataForm.phone}
                    urlImageLoading={urlImgLoading}
                    setTitlePage={setTitlePage}
                    setDescription={setDescription}
                    setCurrentStep={setCurrentStep}
                    infoBottom={Texto.email_link_response}
                  />
                )}

                {currentStep === 4 && dataForm && dataForm.email && (
                  <RegisterEmailVerify
                    onSubmit={handleEmailInfoSubmit}
                    dataFormulario={dataForm}
                    fetch ={fetch}
                    setTitlePage={setTitlePage}
                    setDescription={setDescription}
                    setCurrentStep={setCurrentStep}
                  />
                )}

                {currentStep === 5 && dataForm && (
                  <RegisterPmcFormulario
                    onSubmit={handlePmcInfoSubmit}
                    dataFormulario={dataForm}
                    fetch ={fetch}
                    urlImageLoading={urlImgLoading}
                  />
                )}

                {currentStep === 6 && dataForm?.email && <RegisterUserMessage email={maskEmail(dataForm.email, 5)} />}
              </div>
            </div>
          </div>
        </div>
      </div>


      <ToastContainer
        theme="light"
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default RegisterUser;
