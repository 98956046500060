import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Header from './../components/Global/Header';
import Content from './../components/Global/Content';
import Footer from './../components/Global/Footer';

import WithAuth from './../components/Usuario/WithAuth';
import { UserContext } from './utils/UserContext';
import Menu from '../data/menu';

const App = (props) => {

    const { children } = props;
    const [showPanelTop, setShowPanelTop] = useState(true);
    const [isPageOrigin, setIsPageOrigin] = useState("origen");

    const updateShowPanelTop = (event, showPanel) => {
        setShowPanelTop(showPanel);
        setIsPageOrigin("");
    }

    return (
        <UserContext.Provider value={{ showPanelTop, setShowPanelTop, isPageOrigin, setIsPageOrigin }}>
            <Header title="ATM" menu={Menu} user={props.user} updateShowPanelTop={updateShowPanelTop}></Header>           
            <Content body={children} />
            <Footer />
        </UserContext.Provider>
    );
}

App.propTypes = {
    children: PropTypes.object.isRequired
};

export default WithAuth(App);