import { Component } from "react";
import PropTypes from "prop-types";

class Content extends Component {
  PropTypes = {
    body: PropTypes.object.isRequired,
  };

  render() {
    const { body } = this.props;
    return body;
  }
}

export default Content;