import React, { Component } from 'react';

//import Config from '../../data/config';
import Constant from '../../data/constant';
//import Links from '../../data/link';
import Fetch from '../../components/utils/Fetch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import AsyncSelect from 'react-select/async';
//import DatePicker, { registerLocale } from 'react-datepicker';
//import datepicker from "react-datepicker/dist/react-datepicker.css";
import FormSearchLicencia from "../../components/Gerencia/FormSearchLicencia";

var _fetch = null
var _languaje = undefined
class CollapseMapa extends Component {

    constructor(props, context) {
        super(props, context);

        this.constant = Constant[0];

        this.state = {
            showAdvanceSearch: false,
        };

        _fetch = new Fetch();
        _fetch.setToast(toast);

        _languaje = this.props.languaje
    }

    componentDidMount() {
    }
    render() {
        return (
            <>
                <div className="accordion" id="accordionMapaATM" style={{ padding: "10px 5px" }}>
                    <div className="card">
                        <div className="card-header" id="headingMap">
                            <h5 className="mb-0">
                                <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseLicenciaMap" aria-expanded="true" aria-controls="collapseLicenciaMap">
                                    Licencias de Funcionamiento <span className="badge badge-light" id="spanTotalLicenciasFuncionamiento"></span>
                                </button>
                            </h5>
                        </div>

                        <div id="collapseLicenciaMap" className="collapse show scroll-force " aria-labelledby="headingMap" data-parent="#accordionMapaATM" >
                            <div className="card-body ">
                                <FormSearchLicencia languaje={_languaje} onSubmitForm = {this.props.onSubmitForm}/>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header" id="headingPrescripcionMapa">
                            <h5 className="mb-0">
                                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapsePrescripcionMapa" aria-expanded="false" aria-controls="collapsePrescripcionMapa">
                                    Prescripciones
                                </button>
                            </h5>
                        </div>
                        <div id="collapsePrescripcionMapa" className="collapse" aria-labelledby="headingPrescripcionMapa" data-parent="#accordionMapaATM">
                            <div className="card-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                            </div>
                        </div>
                    </div>
                    
                    <div className="card">
                        <div className="card-header" id="headingCramMap">
                            <h5 className="mb-0">
                                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseCramMap" aria-expanded="false" aria-controls="collapseCramMap">
                                    CRAM
                                </button>
                            </h5>
                        </div>
                        <div id="collapseCramMap" className="collapse" aria-labelledby="headingCramMap" data-parent="#accordionMapaATM">
                            <div className="card-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CollapseMapa;