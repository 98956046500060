import Links from "../../data/link";
import TitlePage from "../../components/utils/TitlePage";
import Texto from "../../data/es";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AuthService from "../../components/Usuario/AuthService";
import { useHistory } from "react-router-dom";
import FormModalAutoFur from "./FormModalAutoFur";
import React, { useState, useEffect } from "react";
import Fetch from "../../components/utils/Fetch";
import DataTable from "react-data-table-component";
import Constant from "../../data/constant";
import CustomLoader from "../utils/SpinnerDataTable";
import styled from "styled-components"; //styled for data table
import { Link } from "react-router-dom";
import Config from "../../data/config";
import ModalPdf from "../utils/ModalPdf";

const FurAdm = (props) => {
  const idFormCodeVerification = "formCodeVerificationFurFree";
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [typeSearch, setTypeSearch] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const auth = new AuthService();
  const userAuth = auth.getProfile();
  const [showFilterSearch, setShowFilterSearch] = useState(false);
  const [data, setData] = useState();
  const [showModalAutoFur, setShowModalAutoFur] = useState(false);
  const [csrfToken, setCsrfToken] = useState(false);
  const [requireAuthenticacion, setRequireAuthenticacion] = useState(true);

  const fetch = new Fetch();
  fetch.setToast(toast);

  const modalPdf = new ModalPdf();
  modalPdf.setToast(toast);

  const { rubro } = useParams();
  const titlePage = Texto.fur_sin_costo + " " + rubro;

  const breadcrumbs = [
    {
      title: Links[0].title,
      url: Links[0].url,
    },
    {
      title: Links[8].title,
      url: Links[8].url,
    },
    {
      title: Links[97].title + " " + rubro,
      url: Links[97].url,
    },
  ];

  const handleOpenFur = (event) => {
    const form = new FormData();
    fetch
      .fetchPost(form, "api/integracion-contribuyentes", undefined)
      .then((dataJson) => {
        if (dataJson && dataJson.status) {
          setShowModalAutoFur(!showModalAutoFur);
          setCsrfToken(dataJson.csrfToken);
        }
        // history.push(url+ "?csrf-token=" + dataJson.csrfToken + "&expiraEn=" + dataJson.expiraEn+"&lo="+dataJson.lo);
      });
  };

  useEffect(() => {
    debugger;
    if (auth.loggedIn()) {
      if (auth.getCodigoSoporteGerencial()) {
        isActiveToken();
      }else{
        sendMailCode();
        setRequireAuthenticacion(true);
      }
    } else window.location.href = Links[4].url;
  }, [requireAuthenticacion]);

  const isActiveToken = () => {
    fetch
      .fetchGet(`api/administracion/is-active-code-token/SFF`)
      .then((dataJson) => {
        debugger;
        if (dataJson && dataJson.status) {
          if (!requireAuthenticacion) {
            if (
              userAuth.code === Constant[0].grupos.funcionario ||
              userAuth.code === Constant[0].grupos.administrador
            ) {
              setShowFilterSearch(true);
            }

            getDataFurGamc();
            window.jQuery(".sc-kAzzGY").remove(); //pertenece al datatable
            window.jQuery(".rdt_TableHeader").css("padding-left", "0");
            setTimeout(() => {
              window.jQuery(".rdt_Table").css("width", "98%");
              window.jQuery(".hAZHBB").css("width", "30px");
            }, 500);
          } else {
            /*if (auth.getCodigoSoporteGerencial()) {
              setRequireAuthenticacion(false);
              addEventDismissButton();
            } else {
              sendMailCode();
              setRequireAuthenticacion(true);
            }*/
            setRequireAuthenticacion(false);
            addEventDismissButton();
          }
        } else {
          auth.removeCodeToken();
        }
      });
  };

  const getDataFurGamc = async () => {
    debugger;
    setLoading(true);
    const response = await fetch.fetchGet(
      `api/fur-gamc/for-page/${currentPage}/${perPage}/${typeSearch}/${rubro
        .toLowerCase()
        .substring(0, 3)}`
    );

    if (response && response.status) {
      setData(response.data);
      setTotalRows(response.total);
      setLoading(false);

      document.getElementById("pExpirado").innerHTML =
        "Expirado - " + response.expirados;

      if (
        userAuth.code === Constant[0].grupos.funcionario ||
        userAuth.code === Constant[0].grupos.administrador
      ) {
        document.getElementById("pActivo").innerHTML =
          "Activo - " + response.activos;

        document.getElementById("pCompletado").innerHTML =
          "Consolidado - " + response.completados;
      }

      toast.success(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handlePerRowsChange = async (perPage, page) => {
    setLoading(true);
    const response = await fetch.fetchGet(
      `api/fur-gamc/for-page/${page}/${perPage}/${typeSearch}/${rubro
        .toLowerCase()
        .substring(0, 3)}`,
      {}
    );
    const json = await response.data;
    setLoading(false);
    setData(json);
    setPerPage(perPage);
    setCurrentPage(page);
  };

  const handlePageChange = async (page) => {
    setLoading(true);
    const response = await fetch.fetchGet(
      `api/fur-gamc/for-page/${page}/${perPage}/${typeSearch}/${rubro
        .toLowerCase()
        .substring(0, 3)}`,
      {}
    );
    const json = await response.data;
    setLoading(false);
    setData(json);
    setCurrentPage(page);
  };

  const handlePreviewClick = (row) => {
    const url = `${Config[0].url}report/fur-gamc/auto-fur/${row.fur}/${row.codigo_tramite}/${row.token}`;
    modalPdf.showPdf(
      `${url}/preview?auth=${auth.getToken()}}`,
      "Datos del Fur: " + row.fur,
      row.token,
      undefined,
      `${url}/download?auth=${auth.getToken()}`
    );
  };

  const columns = [
    {
      name: Texto.tramite,
      sortable: true,
      grow: 1,
      maxWidth: "230px",
      cell: (row) => (
        <div>
          <span>{row.tipo_tramite ? row.tipo_tramite : ""}</span>
        </div>
      ),
    },
    {
      name: Texto.fur_sin_costo,
      sortable: true,
      maxWidth: "150px",
      cell: (row) => (
        <div>
          <span>{row.fur ? row.fur : ""}</span>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      hide: "md",
    },
    {
      name: Texto.nro_orden_tramite,
      selector: "numero_orden_tramite",
      sortable: true,
      maxWidth: "200px",
    },
    {
      name: Texto.estado,
      center: true,
      sortable: true,
      maxWidth: "40px",
      cell: (row) => (
        <div>
          {row.code_estado === Constant[0].estado.activo ? (
            <span title={row.code_estado}>
              <i className="fa fa-check" aria-hidden="true"></i>
            </span>
          ) : (
            ""
          )}
          {row.code_estado === Constant[0].estado.completado ? (
            <span title={row.code_estado}>
              <i className="far fa-handshake" aria-hidden="true"></i>
            </span>
          ) : (
            ""
          )}
          {row.code_estado === Constant[0].estado.eliminado ? (
            <span title={row.code_estado}>
              <i className="fa fa-cog" aria-hidden="true"></i>
            </span>
          ) : (
            ""
          )}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
    {
      name: "Creado",
      selector: "created_at",
      sortable: true,
      hide: "md",
      maxWidth: "140px",
    },
    {
      name: "Expirado",
      selector: "expires_at",
      sortable: true,
      hide: "md",
      maxWidth: "140px",
    },
    {
      name: "",
      sortable: true,
      cell: (row) => (
        <div className="text-sm-start text-md-end" style={{ width: "100px" }}>
          <span
            data-bs-toggle="dropdown"
            aria-expanded="false"
            className="pe-2 ps-2"
          >
            <i className="fas fa-ellipsis-v "></i>
          </span>
          <ul className="dropdown-menu">
            <li>
              {row.code_estado === Constant[0].estado.activo ||
              row.code_estado === Constant[0].estado.completado ||
              row.code_estado === Constant[0].estado.eliminado ? (
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => handlePreviewClick(row)}
                  title={`FUR: ${row.fur}`}
                >
                  <i className="far fa-file-pdf me-2" aria-hidden="true"></i>{" "}
                  Ver FUR
                </Link>
              ) : (
                ""
              )}
            </li>
          </ul>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const hanldeSearchFilterFurGamc = async (event, type_search) => {
    setLoading(true);
    const response = await fetch.fetchGet(
      `api/fur-gamc/for-page/1/${perPage}/${type_search}/${rubro
        .toLowerCase()
        .substring(0, 3)}`
    );
    if (response && response.status) {
      setData(response.data);
      setTotalRows(response.total);
      setLoading(false);
      setTypeSearch(type_search);
      toast.success(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleSubmitSearchForm = (event) => {
    event.preventDefault();
    debugger;
    const form = new FormData(event.target);
    form.append("rubro", rubro.toLowerCase().substring(0, 3));
    setLoading(false);
    let input = window.jQuery(event.target).find("input").val();
    if (input.length > 0) {
      fetch.fetchPost(form, `api/fur-gamc/search`).then((res) => {
        if (res !== undefined && res.status === true) {
          setLoading(false);
          setData(res.data);
          setPerPage(perPage);
          setTotalRows(res.total);
        } else {
          setLoading(false);
          setData([]);
          setPerPage(perPage);
        }
      });
    } else window.location.reload();
  };

  const SampleStyle = styled.div`
    padding: 10px;
    display: block;
    width: 90%;
    p {
      font-size: 12px;
      word-break: break-all;
    }
  `;

  const IntentExpandedComponent = ({ data }) => {
    const vendedor = data.vendedores ? JSON.parse(data.vendedores) : {};
    const juridico = vendedor.juridico ? vendedor.juridico : {};
    return (
      <SampleStyle>
        <div className="row">
          <div className="col-12 col-md-4 mb-3">
            <span>
              <strong>{"DATOS FUR"} </strong>
            </span>
            <hr className="mt-0 " />
            <p>
              <strong>{Texto.fur_sin_costo}: </strong>
              {data.fur}
            </p>
            <p>
              <strong>{Texto.usuario}: </strong>
              {data.username}
            </p>
            <p>
              <strong>{"Institución Solicitante"}: </strong>
              {data.entidad}
            </p>
            <p>
              <strong>{"Caso"}: </strong>
              {data.caso}
            </p>
            <p>
              <strong>{"Detalle Solicitud"}: </strong>
              {data.detalle}
            </p>
          </div>
        </div>
      </SampleStyle>
    );
  };

  const handleCodeVerificationSubmit = (event) => {
    event.preventDefault();
    window
      .jQuery("#" + idFormCodeVerification)
      .parsley()
      .validate();
    const form_javascript = event.target;

    if (
      window
        .jQuery("#" + idFormCodeVerification)
        .parsley()
        .isValid()
    ) {
      const form = new FormData(event.target);

      fetch
        .fetchPost(
          form,
          `api/administracion/verificar-codigo-sg/SFF`,
          form_javascript
        )
        .then((dataJson) => {
          if (dataJson && dataJson.status) {
            if (Boolean(dataJson.code)) {
              setRequireAuthenticacion(false);
              auth.setCodigoSoporteGerencial(dataJson.code);
              addEventDismissButton();

              toast.success(dataJson.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            } else {
              toast.warn(dataJson.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          }
        });
    } else {
      toast.warn(Texto.campos_obligatorios, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const addEventDismissButton = () => {
    debugger;
    setTimeout(() => {
      window.jQuery(".dismiss, .overlay").on("click", function () {
        window.jQuery(".sidebar").removeClass("active");
        window.jQuery(".overlay").removeClass("active");
      });
    }, 500);
  };

  const sendMailCode = () => {
    debugger;
    const send_mail = fetch.fetchGet(
      `api/administracion/enviar-codido-to-mail/SFF`
    );
    send_mail.then((res) => {
      if (res && res.status) {
        document
          .getElementById("divAlertRegisterUser")
          .classList.remove("alert-warning");
        document
          .getElementById("divAlertRegisterUser")
          .classList.add("alert-success");
        document.getElementById("divAlertRegisterUser").innerHTML = res.message;
      } else {
        document
          .getElementById("divAlertRegisterUser")
          .classList.remove("alert-success");
        document
          .getElementById("divAlertRegisterUser")
          .classList.add("alert-warning");
        document.getElementById("divAlertRegisterUser").innerHTML =
          "Código no Generado";
      }
    });
  };

  return (
    <section className="items-grid section mt-5 mb-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={titlePage}
          breadcrumbs={breadcrumbs}
          position={"left"}
          leftfull={false}
        />

        {requireAuthenticacion ? (
          <form
            action=""
            className="contact__form center-login mt-5"
            name={idFormCodeVerification}
            id={idFormCodeVerification}
            method="post"
            noValidate
            onSubmit={handleCodeVerificationSubmit}
          >
            <div className="row">
              <div className="col-12 col-md-3 "></div>

              <div className="col-12 col-md-6 ">
                <div
                  className="alert alert-success"
                  role="alert"
                  id="divAlertRegisterUser"
                  style={{ fontSize: "1.3rem" }}
                ></div>
              </div>

              <div className="col-12 col-md-3 "></div>
            </div>

            <div className="row">
              <div className="col-12 col-md-3  "></div>
              <div className="col-12 col-md-6 ">
                <label htmlFor="reporte[codigo]">Código de Acceso</label>
                <input
                  name="reporte[codigo]"
                  type="text"
                  className="form-control"
                  placeholder="Código de Acceso"
                  data-parsley-required="true"
                />
              </div>

              <div className="col-12 col-md-3 "></div>
            </div>

            <div className="row mt-3 ">
              <div className="col-12 col-md-3  "></div>
              <div className="col-12 col-md-6 text-end">
                <div className=" search-btn button mb-3">
                  <input
                    type="submit"
                    className="btn btn-full-screen"
                    //defaultValue={"Siguiente"}
                    value={"Verificar Código"}
                    //onClick={handleValidConfirmForm}
                  />
                </div>
              </div>
              <div className="col-12 col-md-3 "></div>
            </div>
          </form>
        ) : (
          <>
            <section className="panel-menu-info">
              <div className="panel-menu-info-content">
                <div className="row">
                  {showFilterSearch ? (
                    <div className="col-4 col-md-2 col-lg-2">
                      <div
                        className="single-contact-info pointer"
                        onClick={(e) => hanldeSearchFilterFurGamc(e, 1)}
                      >
                        <i className="fa fa-check" aria-hidden="true"></i>
                        <p id="pActivo">Activo - 0</p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {showFilterSearch ? (
                    <div className="col-4 col-md-2 col-lg-2">
                      <div
                        className="single-contact-info pointer"
                        onClick={(e) => hanldeSearchFilterFurGamc(e, 3)}
                      >
                        <i className="fa fa-cog" aria-hidden="true"></i>
                        <p id="pExpirado">Expirado - 0</p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {showFilterSearch ? (
                    <div className="col-4 col-md-2 col-lg-2">
                      <div
                        className="single-contact-info pointer"
                        onClick={(e) => hanldeSearchFilterFurGamc(e, 2)}
                      >
                        <i className="far fa-handshake" aria-hidden="true"></i>
                        <p id="pCompletado">Consolidado - 0</p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-4 col-md-2 col-lg-2 mb-3"></div>

                  <div className="col-4 col-md-2 col-lg-2 mb-3"></div>

                  {showFilterSearch ? (
                    <div className="col-4 col-md-2 col-lg-2 mb-3">
                      <div
                        className="single-contact-info pointer"
                        onClick={handleOpenFur}
                      >
                        <i className="far fa-file" aria-hidden="true"></i>
                        <p>Nuevo FUR</p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </section>
            <form
              action=""
              className="contact__form center-login"
              name="formSearchDataTable"
              id="formSearchDataTable"
              method="post"
              noValidate
              onSubmit={handleSubmitSearchForm}
            >
              <div className="row">
                <div className="col-12 col-md-4 col-lg-6 "></div>
                <div className="col-12 col-md-8 col-lg-6 ">
                  <div className="input-group mb-3">
                    <select
                      className="form-select "
                      name="type"
                      required
                      data-parsley-required="true"
                    >
                      <option key={0} value="FUR">
                        Número de FUR
                      </option>
                    </select>
                    <input
                      type="text"
                      name="search"
                      id="search"
                      className="form-control"
                      placeholder="*"
                      aria-label="Username"
                    />

                    <button
                      className="btn btn-outline-secondary"
                      type="submit"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Buscar"
                    >
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <DataTable
              title={titlePage}
              columns={columns}
              data={data}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              highlightOnHover
              noDataComponent={Texto.there_are_no_records_to_display}
              progressComponent={<CustomLoader />}
              expandableRowsComponent={<IntentExpandedComponent />}
              expandableRows
            />
          </>
        )}
      </div>
      <ModalPdf />
      {showModalAutoFur ? (
        <FormModalAutoFur
          auth={auth}
          toast={toast}
          fetch={fetch}
          rubro={rubro.toLowerCase().substring(0, 3)}
          title={"Emisión FUR"}
          csrfToken={csrfToken}
          eventOpenCloseModal={handleOpenFur}
        />
      ) : (
        ""
      )}
    </section>
  );
};

export default FurAdm;
