import React, { useState } from "react";
import TitleHeaderPage from "../Utils/TitleHeaderPage";
import ResetPasswordEmailFormulario from "./ResetPasswordEmailFormulario";
import ResetPasswordMetodoFormulario from "./ResetPasswordMetodoFormulario";
import ResetPasswordMetodoInfo from "./ResetPasswordMetodoInfo";
import ResetPasswordNewFormulario from "./ResetPasswordNewFormulario";
import { Link } from "react-router-dom";
import Texto from "../../../data/es";

const ResetPassword = () => {
	const urlVolver = "/login";
	const urlImgLoading = "/static/img/pulse_200.gif";

	const [titlePage, setTitlePage] = useState(Texto.reset_title);
	const [description, setDescription] = useState(Texto.reset_descripction_init);
	const [dataOptions, setDataOptions] = useState([]);
	const [visibleForm, setVisibleForm] = useState({
		form_email: true,
		form_method: false,
		form_method_info: false,
		form_reset: false,
		form_end: false
	});

	const [dataForm, setDataForm] = useState({
		phone: "",
		email: "",
		token: "",
		tokenchange: "",
		contrasena: false,
		metodo: ""
	});	
	// ======================================================= //
	// paso 01
	// ======================================================= //
	const handleResetPasswordSubmit = (email, celular, token) => {
		// update dataForm
		handleChangeDataOrForm('email', email, false); //data
		
		if(celular > 0){
			handleChangeDataOrForm('phone', celular, false); //data
			setDataOptions([
				{
					value: email,
					label: "Enviar un enlace por correo electrónico a " + maskEmail(email, 5),
				},
				{
					value: celular.toString(),
					label: "Enviar código por WhatsApp a mi teléfono, terminado en " + maskPhoneNumber(celular, 5),
				}			
			]);			
		}else{
			setDataOptions([
				{
					value: email,
					label: "Enviar un enlace por correo electrónico a " + maskEmail(email, 5),
				}			
			]);
		}
		
		handleChangeDataOrForm('token', token, false); //data
		// visible form
		handleChangeDataOrForm('form_email', false, true);
		handleChangeDataOrForm('form_method', true, true);

		//setTitlePage(Texto.reset_title_method);
		setDescription(Texto.reset_description_method);		
	};

	// ======================================================= //
	// paso 2 
	// ======================================================= //
	const handleSelectionSubmit = (selectedOption, tokenchange) => {
		// data
		handleChangeDataOrForm('tokenchange', tokenchange, false);
		handleChangeDataOrForm('token', tokenchange, false); //data
		handleChangeDataOrForm('metodo', selectedOption, false);
		// form visible
		handleChangeDataOrForm('form_method', false, true);
		handleChangeDataOrForm('form_method_info', true, true);

		(selectedOption.indexOf('@') > 0) ? setDescription(Texto.reset_info_email_description) : setDescription(Texto.reset_phone_code_description);
	};
	// ======================================================= //
	// paso 3
	// ======================================================= //
	const handleMessageSubmit = (estado) => {

		if (estado === 'phone') {
			handleChangeDataOrForm('form_method_info', false, true);
			handleChangeDataOrForm('form_reset', true, true);
		} else {
			handleChangeDataOrForm('form_method_info', false, true);
			handleChangeDataOrForm('form_reset', true, true);
		}
		setTitlePage(Texto.reset_form_title);
		setDescription(Texto.reset_form_description)
	};
	// ======================================================= //
	// paso 4 - register new password
	// ======================================================= //
	const handleSubmitRegisterReset = (modificado) => {
		handleChangeDataOrForm('contrasena', true, false);
		handleChangeDataOrForm('form_reset', false, true);
		handleChangeDataOrForm('form_end', true, true);
		// setDescription(Texto.reset_info_email_description)
		setTitlePage(Texto.reset_title);
		setDescription('');
	}
	// ======================================================= //
	// cambiar el valor de acuerdo al item del objeto-data
	// ======================================================= //
	const handleChangeDataOrForm = (key, value, isVisible) => {
		if (isVisible) {
			setVisibleForm((prevPreferences) => ({
				...prevPreferences,
				[key]: value,
			}));
		} else {
			setDataForm((prevPreferences) => ({
				...prevPreferences,
				[key]: value,
			}));
		}
	};

	// mask email
	const maskEmail = (email, visibleLength) => {
		if (!email) return '';
		
		const atIndex = email.indexOf('@');
		if (atIndex <= visibleLength) return email;
	
		const visiblePart = email.substring(0, visibleLength);
		const maskedPart = '*'.repeat(atIndex - visibleLength);
		const domainPart = email.substring(atIndex);
		
		return visiblePart + maskedPart + domainPart;
	  };

	// mask celular
	const maskPhoneNumber = (phone, visibleLength) => {
		if (!phone) return '';

		const atIndex = phone.toString().length;
		if (atIndex <= visibleLength) return phone;

		const visiblePart = phone.substring(0, visibleLength);
		const maskedPart = '*'.repeat(atIndex - visibleLength);
				
		return visiblePart + maskedPart;
	}

	/* =================================================== */

	return (
		<div className="section mt-5">
			<div className="container mt-4">
				<div className="row">
					<div className="col-md-8 col-sm-12 col-lg-5 mx-auto d-block">
						<div className="card card-minw-25">
							<div className="card-body">
								<TitleHeaderPage
									titlePage={titlePage}
									description={description}
									visible={false}
								></TitleHeaderPage>

								{visibleForm.form_email ? (
									<ResetPasswordEmailFormulario
										onSubmit={handleResetPasswordSubmit}
										url={urlVolver}
										urlLoading={urlImgLoading}
										correoelectronico={dataForm.email}
										setTitlePageInicio={setTitlePage}
										setDescriptionInicio={setDescription}
										handleChangeVisibleOrData={handleChangeDataOrForm}
									/>
								) : (<></>)}

								{visibleForm.form_method ? (
									<ResetPasswordMetodoFormulario
										options={dataOptions}
										onSubmit={handleSelectionSubmit}
										correoelectronico={dataForm.email}
										urlLoading={urlImgLoading}
										setDescriptionInicio={setDescription}
										handleChangeVisibleOrData={handleChangeDataOrForm}
										dataFormReset={dataForm}
									/>
								) : (<></>)}

								{visibleForm.form_method_info ? (
									<ResetPasswordMetodoInfo
										urlLoading={urlImgLoading}
										onSubmit={handleMessageSubmit}
										setDescriptionInicio={setDescription}
										handleChangeVisibleOrData={handleChangeDataOrForm}
										dataFormReset={dataForm}
									/>
								) : (<></>)}

								{visibleForm.form_reset && (
									<ResetPasswordNewFormulario
										onSubmit={handleSubmitRegisterReset}
										urlLoading={urlImgLoading}										
										dataFormReset={dataForm}
									/>
								)}

								{visibleForm.form_end && (<>
									<div className="row mt-3 p-2">
										<div className="text-center mb-3 col-sm-12 col-lg-12">
											<button className="btn">
												<i className='fa fa-check-circle fa-4x text-success'></i>
											</button>
										</div>
										<div className="text-justify col-lg-12 col-md-12 col-sm-12 mx-auto border-radius-035 bg-gray-5 p-3">
											<p>La contraseña fue modificada exitosamente.</p>
																						
										</div>
									</div>
									<div className="col-sm-12 col-lg-12 text-center mt-3">
										<div>

											<Link to={"/"} className="btn btn-violeta">
												Aceptar
											</Link>
										</div>
									</div>
								</>)}


							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
