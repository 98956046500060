import React, { Component } from 'react';
import Texto from '../../data/es';
import '../style/ol-geocoder.min.css';

const hereLayers = [
    {
        base: 'base',
        type: 'maptile',
        scheme: 'normal.day'
    },
    {
        base: 'base',
        type: 'maptile',
        scheme: 'normal.day.transit'
    },
    {
        base: 'base',
        type: 'maptile',
        scheme: 'pedestrian.day'
    },
    {
        base: 'aerial',
        type: 'maptile',
        scheme: 'terrain.day'
    },
    {
        base: 'aerial',
        type: 'maptile',
        scheme: 'satellite.day'
    },
    {
        base: 'aerial',
        type: 'maptile',
        scheme: 'hybrid.day'
    }
];

class MapOpenLayer extends Component {

    constructor(props, context) {
        super(props, context);

        this.id_modal = "modalMapDomicilio";
        this.latitud = 0
        this.longitud = 0
        this.coordinate = "";
        this.layers = []

        this.handleCloseOnClick = this.handleCloseOnClick.bind(this);
        this.handleConfirmOnClick = this.handleConfirmOnClick.bind(this);

        this.state = { center: [0, 0], zoom: 17 };
    }

    handleCloseOnClick(event) {
        event.preventDefault();
        this.latitud = 0
        this.longitud = 0
        this.coordinate = "";
    }

    handleConfirmOnClick(event) {
        event.preventDefault();
        if (Number(this.latitud) && Number(this.longitud)) {
            const { module } = this.props

            debugger
            if (module !== undefined && (this.props.module === 'actividad_economica' || this.props.module === 'prescripcion')) {

                window.jQuery("input[name='domicilio[latitud]']").val(this.latitud);
                window.jQuery("input[name='domicilio[longitud]']").val(this.longitud);
                window.jQuery("input[name='domicilio[coordinate]']").val(this.coordinate);

                window.jQuery("#" + this.id_modal).modal("hide");

                this.map.once('postcompose', function (event) {
                    var canvas = event.context.canvas;
                    if (navigator.msSaveBlob) {
                        navigator.msSaveBlob(canvas.msToBlob(), 'map.png');
                    } else {
                        canvas.toBlob(function (blob) {
                            var blobUrl = URL.createObjectURL(blob);

                            document.getElementById("domicilio[image]").src = blobUrl;  //img
                            document.getElementById("domicilio[image]").style.width = '100%';
                            window.blobToBase64(blob, function (img64) {
                                if (Boolean(img64)) {
                                    document.getElementsByName('domicilio[image]')[0].value = img64; //input
                                }
                            });
                        });
                    }
                });
                this.map.renderSync();
            }
        }
    }

    componentDidMount() {

        window.jQuery(".linkHelpMD").popover({
            title: '<h3 class="custom-title"><i class="fas fa-map-marker-alt"></i> Ayuda</h3>',
            content: '<p><img src="/static/img/plus_zoom.jpg" className="rounded z-depth-0" alt="zoom plus map" width="18px" /> Ampliar la imagen del mapa. <br />' +
                '<img src="/static/img/minus_zoom.jpg" className="rounded z-depth-0" alt="zoom plus map" width="18px" /> Reducir la imagen del mapa. <br/>' +
                '<img src="/static/img/full_screen.jpg" className="rounded z-depth-0" alt="zoom plus map" width="18px" /> Mapa en pantalla completa.<br/>' +
                '<img src="/static/img/search_map.jpg" className="rounded z-depth-0" alt="zoom plus map" width="18px" /> Buscar direcciones en el mapa por: <br/>' +
                '&nbsp;&nbsp; Ej. 1: "25 de mayo, Ayacucho, Cochabamba" <br/> ' +
                '&nbsp;&nbsp; Ej. 2: Terminal de buses, cochabamba<br/> ' +
                '&nbsp;&nbsp; Ej. 3: sombrero de chola, cochabamba <br/> ' +
                '&nbsp;&nbsp; Ej. 4: plaza murillo, la paz </p>',
            html: true
        });

        var view = new window.ol.View({
            center: this.state.center,
            zoom: this.state.zoom
        });

        //cargamos los layers
        this.layers.push(new window.ol.layer.Tile({
            preload: Infinity,
            source: new window.ol.source.OSM({
                attributions: [
                    'All maps © <a href="https://www.opencyclemap.org/">OpenCycleMap</a>', window.ol.source.OSM.ATTRIBUTION],
                url:
                    // 'https://{a-c}.tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=9ba6de985c764b44ba0aa2cf151b0d00',  //primero a utilizar  1
                    'https://{a-c}.tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=4803ac1d47ef4482af22c1dc6cecf079', //este es su oficial  0
                    //'https://{a-c}.tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=fb7c77d1ae8b438b945a5ef265e67971', //tercera opción auxiliar 2
            })
        }));

        //la proyeccion por defecto es EPSG:3857
        this.map = new window.ol.Map({
            controls: window.ol.control.defaults().extend([
                new window.ol.control.FullScreen({
                    source: 'fullscreen'
                })
            ]),
            layers: this.layers,
            loadTilesWhileInteracting: true,
            view: view
        });

        // Listen to map changes
        this.map.on("moveend", () => {
            let center = this.map.getView().getCenter();
            let zoom = this.map.getView().getZoom();
            this.setState({ center, zoom });
        });

        var self = this;

        var vectorSource = new window.ol.source.Vector({});
        var vectorLayer = new window.ol.layer.Vector({
            source: vectorSource,
        });

        this.map.on('singleclick', function (evt) {
            vectorSource.clear();
            var coordinate = evt.coordinate;
            var hdms = window.ol.coordinate.toStringHDMS(window.ol.proj.transform(coordinate, 'EPSG:3857', 'EPSG:4326'));

            var lat_lon = window.ol.proj.toLonLat(coordinate);  //devuelve longitud, latitud
            self.longitud = lat_lon[0]  //longitud
            self.latitud = lat_lon[1] //latitud
            self.coordinate = coordinate.toString()

            self.paintIcon(coordinate, vectorSource, vectorLayer, self.map);
            self.map.getView().setCenter([coordinate[0], coordinate[1]])
        });

        // para el puntero del raton
        this.map.on('pointerup', function (evt) {
            var pixel = self.map.getEventPixel(evt.originalEvent);
            self.map.forEachFeatureAtPixel(pixel, function (feature) {
                var coordinate = feature.getGeometry().getCoordinates()
                var lat_lon = window.ol.proj.toLonLat(coordinate);  //devuelve longitud, latitud
                self.longitud = lat_lon[0]  //longitud
                self.latitud = lat_lon[1] //latitud
                self.coordinate = coordinate.toString()

                self.map.getView().setCenter([coordinate[0], coordinate[1]])
            });
        });

        window.jQuery('#' + this.id_modal).on('show.bs.modal', function () {
            //search map
            const geocoder = new window.Geocoder('nominatim', {
                provider: 'osm',
                lang: 'en',
                placeholder: 'Buscar por ...',
                limit: 5,
                debug: false,
                autoComplete: true,
                keepOpen: true,
                featureStyle: null
            });

            self.map.addControl(geocoder);

            geocoder.on('addresschosen', (evt) => {

                self.setState({ center: [evt.coordinate[0], evt.coordinate[1]], zoom: 17 });
                self.map.updateSize()
            });

            setTimeout(() => {
                if (self.props.coordinate === undefined || self.props.coordinate === "")
                    self.setState({ center: [-7364539.872113073, -1966694.8795634112], zoom: 17 });  //default
                else {
                    var coodinate = JSON.parse("[" + self.props.coordinate + "]")
                    self.setState({ center: [coodinate[0], coodinate[1]], zoom: 17 });  //coordenas para editar
                }
                self.map.updateSize()
            }, 1500);
        });
    }

    paintIcon(coordinate, vectorSource, vectorLayer, map) {
        var iconFeature = new window.ol.Feature({
            geometry: new window.ol.geom.Point(coordinate),
        });

        var iconStyle = [new window.ol.style.Style({
            image: new window.ol.style.Icon({
                anchor: [0.5, 0.5],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                src: 'http://maps.google.com/mapfiles/ms/micons/blue.png',
                crossOrigin: 'anonymous',
            })
        })];
        iconFeature.setStyle(iconStyle);
        vectorSource.addFeature(iconFeature);
        vectorLayer = new window.ol.layer.Vector({
            source: vectorSource,
        });
        map.addLayer(vectorLayer);

        //move to icon feature
        var translate1 = new window.ol.interaction.Translate({
            features: new window.ol.Collection([iconFeature])
        });

        map.addInteraction(translate1);
    }

    componentDidUpdate() {
        this.map.setTarget("mapAddress");
        this.map.getView().setCenter(this.state.center);
        this.map.getView().setZoom(this.state.zoom);
    }

    render() {
        return (
            <div className="modal fade " id={this.id_modal} tabIndex="-1" role="dialog"
                aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-lg" >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" ><img src="/static/img/search_map.jpg" className="rounded z-depth-0" alt="zoom plus map" width="24px" /> Ubicación del Domicilio</h5>
                            <button type="button" className="btn link-help linkHelpMD" id="linkHelpMD" style={{ position: 'absolute', right: '35px', top: '2px' }}><i className="fa fa-question" aria-hidden="true"></i></button>
                            <button type="button" className="bootbox-close-button close mt-1" data-bs-dismiss="modal" aria-label="Close" onClick={this.handleCloseOnClick}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div id="fullscreen" className="fullscreen">
                                    <div id="mapAddress" className="map-layer"></div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <mark><em className="text-left">{Texto.falsedad_de_datos_documento}</em></mark>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer text-end">
                            <button type="button" className="btn link-help linkHelpMD" id="linkHelpMD"><i className="fa fa-question-circle" aria-hidden="true"></i></button>
                            <div className="search-btn button ">
                                <button type="button" className="btn bg-mandy" data-bs-dismiss="modal" aria-label="Close" onClick={this.handleCloseOnClick}>Cerrar</button>
                            </div>
                            &nbsp;

                            <div className="search-btn button ">
                                <input name="submit" type="button" className="btn"
                                    value="Confirmar" style={{ marginLeft: '0px' }} onClick={this.handleConfirmOnClick} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MapOpenLayer;
