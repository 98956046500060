import React, { useEffect } from 'react';

import HeaderCarroucel from './HeaderCarroucel';
import Services from './Services';
import Ayuda from '../utils/Ayuda';


const LegalTributario = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <HeaderCarroucel />
            <Services />
            <Ayuda />
        </>
    )
}

export default LegalTributario;