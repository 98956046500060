import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Texto from "../../../data/es";
import Fetch from "../../../components/utils/Fetch";
import { toast } from "react-toastify";

const RegisterEmailFormulario = ({
	onSubmit,
	correoelectronico,
	setTitlePage,
	setDescription,
	infoBottom,
	celular,
	urlImageLoading,
	setCurrentStep
}) => {
	const idForm = "form-email";
	const fetch = new Fetch();
	fetch.setToast(toast);
	// state
	const [error, setError] = useState("");
	const [email, setEmail] = useState(correoelectronico);
	const [loading, setLoading] = useState(false);

	const isMounted = useRef(false);

	useEffect(() => {
		isMounted.current = true;

		return () => {
			isMounted.current = false;
		};
	}, []);

	// event submit
	const handleSubmit = async(e) => {
		e.preventDefault();
		setError("");
		window.jQuery("#" + idForm).parsley().validate();
		if (window.jQuery("#" + idForm).parsley().isValid() && isMounted.current) {
			handleVerificarEmail();						
		}
	};

	// verificar email ingresado
	const handleVerificarEmail = async () => {
		setLoading(true)
		try {
			const form = new FormData();
			form.append("registro[email]", email);
			
			const dataJson = await fetch.fetchPost(form, "api/usuario/registro/verificar-correo");
			if (dataJson && dataJson.status) {
				
				const dataSendEmail = await fetch.fetchPost(form, "api/usuario/registro/enviar-correo");
				if (dataSendEmail && dataSendEmail.status) {
					fetch.toast.success(dataJson.message);
					setTimeout(() => {
						fetch.toast.success(dataSendEmail.message);							
						onSubmit(email);
					}, 1000);
				}	
										
			}			
		} catch (error) {
			toast.error("Error al realizar la verificación del correo electrónico. Vuelva a intentar.");
		}
		setLoading(false);
	}

	// volver
	const handleEventClickVolver = (e) => {
		//window.createBootbox("¿Está seguro de continuar con la operación?  ", function (result) {
           // if (result === true) {		
				setTitlePage(Texto.register_user_title_init);
				setDescription(Texto.register_user_description_init);
				setCurrentStep(2)
			// }
		//})		
	};

	// modificacion  del email
	const changeEmail = (e) => {
		setError('');
		setEmail(e.target.value)
		setLoading(false);
	}

	/* ============================================================ */
	return (
		<form
			onSubmit={handleSubmit}
			noValidate
			name={idForm}
			id={idForm}
			method="post"
		>

			{loading && (<div className="col-12 mb-1 mx-auto d-block text-center">
				<img
					className="rounded"
					alt="pulse-loading"
					src={urlImageLoading}
					width="40"
				/>{" "}
				<span className="ml-5 text-violeta">Procesando</span>
			</div>)}

			<div className="form-group mb-2">
				<label htmlFor="email">(*) Correo Electrónico:</label>
				<input
					type="email"
					id="email"
					value={email}
					onChange={(e) => changeEmail(e)}
					className="form-control form-control-lg"
					required
					data-parsley-required-message="Correo electrónico es requerido"
					data-parsley-type="email"
					data-parsley-email-message="Correo electrónico no es válido"
				/>
			</div>

			{error !== '' ? (
				<div className="parsley-errors-list filled">
					{error}
				</div>
			) : ''}

			{infoBottom == null ? (
				""
			) : (
				<div className="text-justify col-lg-12 col-md-12 col-sm-12 mx-auto border-radius-035 bg-gray-5 mt-3 p-3">
					{infoBottom}
				</div>
			)}

			<div className="d-flex d-sm-flex flex-sm-row justify-content-between mt-3">
				<div>

					<button
						type="button"
						className="btn btn-fucsia"
						onClick={handleEventClickVolver}
					>
						Volver
					</button>

				</div>
				<div>
					<button type="submit" className="btn btn-violeta">
						Continuar
					</button>
				</div>
			</div>
		</form>
	);
};

RegisterEmailFormulario.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	infoBottom: PropTypes.string.isRequired,
	correoelectronico: PropTypes.string.isRequired,
	celular: PropTypes.string.isRequired,
	urlImageLoading: PropTypes.string.isRequired
};

export default RegisterEmailFormulario;
