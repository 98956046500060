import React, { useEffect } from 'react';
import Texto from '../../../data/es'

const DatosTecnicosVehiculo = (props) => {

    const { dataVehiculo } = props

    useEffect(() => {

    }, []);
    //pais
    //traccion
    //cilindrada
    //tipo carroceria
    //capacidad carga
    //radicatoria


    /*
    cl: "CAMIONETA"
co: "PLATEADO"
ma: "NISSAN"
md: "1989"
mt: "M8Z9E0028"
np: "5"
pl: "623FHH"
se: "PARTICULAR"
tv: "CAMIONETA"
*/

    return (
        <div className="card widget widget-simple w-100" >
            <div className="card-body ">
                <div className="row ">
                    <div className="col-12 widget-header">
                        <h4 className="">{Texto.datos_tecnicos}</h4>
                    </div>
                </div>

                <div className="widget-content">
                    <div className="widget-body">

                        <div className="row ">
                            <div className="col-sm-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left" ><strong >Clase: </strong><span>{dataVehiculo.cl ? dataVehiculo.cl : "--"}</span></p>
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left"><strong >Tipo: </strong><span>{dataVehiculo.tv ? dataVehiculo.tv : ""}</span></p>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left"><strong >Marca: </strong> <span>{dataVehiculo.ma ? dataVehiculo.ma : ""}</span></p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left"><strong >Modelo: </strong><span>{dataVehiculo.md ? dataVehiculo.md : ""}</span></p>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left"><strong >Pais: </strong><span>{}</span></p>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left"><strong >Servicio: </strong><span>{dataVehiculo.se ? dataVehiculo.se : ""}</span></p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left"><strong >Tracción: </strong><span>{}</span></p>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left"><strong >Cilindrada (Cc.): </strong><span>{}</span></p>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left"><strong >Color: </strong><span>{dataVehiculo.co ? dataVehiculo.co : ""}</span></p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left"><strong >Tipo Carrocería: </strong><span>{}</span></p>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left"><strong >Cap. Carga (Tn.): </strong><span>{}</span></p>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left"><strong >Nro PLazas: </strong><span>{dataVehiculo.np ? dataVehiculo.np : ""}</span></p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left"><strong >Radicatoría: </strong><span>{}</span></p>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 padding-left-right-0">
                                <p className="text-left"><strong >Tipo Vehículo: </strong><span>{}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DatosTecnicosVehiculo