import React, { useEffect } from "react";
import Links from "../../data/link";
import TitlePage from "../utils/TitlePage";
import Texto from "../../data/es";
import Config from "../../data/config";

const breadcrumbs = [
  {
    title: Links[0].title,
    url: Links[0].url,
  },
  {
    title: Links[72].title,
    url: Links[72].url,
  },
];

const PagosRuat = (props) => {
  const titlePage = Texto.guia;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRedirectPagosRuat = (event, link) => {
    event.preventDefault();
    window.open(link, "_blank");
  };

  return (
    <section className="items-grid section mt-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={titlePage}
          breadcrumbs={breadcrumbs}
          position={"left"}
          leftfull={false}
        />

        <div className="row">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div
              className="single-news"
              data-wow-delay=".2s"
              onClick={(e) => handleRedirectPagosRuat(e, Links[48].url)}
            >
              <div className="image">
                <img
                  className="thumb"
                  alt="img-pagos-ruat"
                  src={Config[2].url + "/static/img/bt_pago_ruat.png"}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div
              className="single-news"
              data-wow-delay=".2s"
              onClick={(e) => handleRedirectPagosRuat(e, Links[73].url)}
            >
              <div className="image">
                <img
                  className="thumb"
                  alt="img-pagos-qr-ruat"
                  src={Config[2].url + "/static/img/pagos_ruat_qr.png"}
                />
              </div>
            </div>
            <div
              className=" button-link-pago text-center"
              onClick={(e) =>
                handleRedirectPagosRuat(
                  e,
                  "https://www.ruat.gob.bo/vehiculos/consultapago/InicioBusquedaVehiculo.jsf"
                )
              }
              style={{ border: "1px solid rgb(249,177,84,.5)" }}
            >
              <strong className="text-mandy">IMPRIMIR PAGOS</strong>
              <img
                className="thumb ms-2"
                alt="revizar-pagos"
                src={Config[2].url + "/static/img/printer-mandy.png"}
              />
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div
              className="single-news"
              data-wow-delay=".2s"
              onClick={(e) => handleRedirectPagosRuat(e, Links[89].url)}
            >
              <div className="image">
                <img
                  className="thumb"
                  alt="pagos-ruat-inm-qr"
                  src={Config[2].url + "/static/img/pagos_ruat_inm_qr.png"}
                />
              </div>
            </div>
            <div
              className=" button-link-pago text-center"
              onClick={(e) =>
                handleRedirectPagosRuat(
                  e,
                  "https://www.ruat.gob.bo/inmuebles/consultapago/InicioBusquedaInmueble.jsf"
                )
              }
              style={{ border: "1px solid rgb(249,177,84,.5)" }}
            >
              <strong className="text-mandy">IMPRIMIR PAGOS</strong>
              <img
                className="thumb ms-2"
                alt="revizar-pagos"
                src={Config[2].url + "/static/img/printer-mandy.png"}
              />
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div
              className="single-news"
              data-wow-delay=".2s"
              onClick={(e) => handleRedirectPagosRuat(e, Links[90].url)}
            >
              <div className="image">
                <img
                  className="thumb"
                  alt="pagos-ruat-multas-otros"
                  src={
                    Config[2].url + "/static/img/pagos_ruat_multas_otros.png"
                  }
                />
              </div>
            </div>
            <div
              className=" button-link-pago text-center"
              onClick={(e) =>
                handleRedirectPagosRuat(
                  e,
                  "https://www.ruat.gob.bo/ingresosnotributarios/consultapago/InicioBusquedaIngresosNoTributarios.jsf?cGM=GM-CBBA"
                )
              }
              style={{ border: "1px solid rgb(249,177,84,.5)" }}
            >
              <strong className="text-mandy">IMPRIMIR PAGOS</strong>
              <img
                className="thumb ms-2"
                alt="revizar-pagos"
                src={Config[2].url + "/static/img/printer-mandy.png"}
              />
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div
              className="single-news"
              data-wow-delay=".2s"
              onClick={(e) => handleRedirectPagosRuat(e, Links[92].url)}
            >
              <div className="image">
                <img
                  className="thumb"
                  alt="pagos-ruat-act-eco"
                  src={
                    Config[2].url + "/static/img/pago_qr_act_eco.png"
                  }
                />
              </div>
            </div>
            <div
              className=" button-link-pago text-center"
              onClick={(e) =>
                handleRedirectPagosRuat(
                  e,
                  "https://www.ruat.gob.bo/actividadeseconomicas/consultapago/InicioBusquedaActividadesEconomicas.jsf"
                )
              }
              style={{ border: "1px solid rgb(249,177,84,.5)" }}
            >
              <strong className="text-mandy">IMPRIMIR PAGOS</strong>
              <img
                className="thumb ms-2"
                alt="revizar-pagos-act-eco"
                src={Config[2].url + "/static/img/printer-mandy.png"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PagosRuat;