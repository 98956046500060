import React, { useEffect, useState } from "react";
//import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Fetch from "../../components/utils/Fetch";
import CustomLoader from "../utils/SpinnerDataTable";
import Links from "../../data/link";
import TitlePage from "../utils/TitlePage";
import Texto from "../../data/es";
import ModalPdf from "../utils/ModalPdf";
import AuthService from "../../components/Usuario/AuthService";
import Constant from "../../data/constant";
import Config from "../../data/config";

import "react-toastify/dist/ReactToastify.css";

const RawHtml = ({ children = "", tag: Tag = "div", ...props }) => (
  <Tag {...props} dangerouslySetInnerHTML={{ __html: children }} />
);

const SampleStyle = styled.div`
  padding: 10px;
  display: block;
  width: 100%;
  p {
    font - size: 12px;
    word-break: break-all;
  }
`;

const IntentExpandedComponent = ({ data }) => (
  <SampleStyle>
    <p>
      <strong>{Texto.nro_orden}: </strong>
      {data.cambio_radicatoria_numero}
    </p>
    <p>
      <strong>{Texto.usuario}: </strong>
      {data.cambio_radicatoria_username}
    </p>
    <p>
      <strong>{Texto.creado}: </strong>
      {data.cambio_radicatoria_registrado}
    </p>
    <br />
    <h6>{Texto.datos_vehiculo}</h6>
    <hr />
    <p>
      <strong>{Texto.placa}: </strong>
      {data.vehiculo_placa}
    </p>
    <p>
      <strong>{Texto.clase}: </strong>
      {data.vehiculo_dato_tecnico_clase}
    </p>
    <p>
      <strong>{Texto.color}: </strong>
      {data.vehiculo_dato_tecnico_color}
    </p>
    <p>
      <strong>{Texto.marca}: </strong>
      {data.vehiculo_dato_tecnico_marca}
    </p>
    <p>
      <strong>{Texto.modelo}: </strong>
      {data.vehiculo_dato_tecnico_modelo}
    </p>
    <p>
      <strong>{Texto.numero_plaza}: </strong>
      {data.vehiculo_dato_tecnico_numero_plaza}
    </p>
    <p>
      <strong>{Texto.servicio}: </strong>
      {data.vehiculo_dato_tecnico_servicio}
    </p>
    <p>
      <strong>{Texto.tipo}: </strong>
      {data.vehiculo_dato_tecnico_tipo_vehiculo}
    </p>

    <br />
    <h6>{Texto.datos_poseedor}</h6>
    <hr />
    <p>
      <strong>{Texto.nombre_completo}: </strong>
      {data.poseedor_nombre +
        " " +
        data.poseedor_primer_apellido +
        " " +
        data.poseedor_segundo_apellido}
    </p>
    <p>
      <strong>{Texto.numero_documento}: </strong>
      {data.poseedor_tipo_documento +
        " " +
        data.poseedor_numero_documento +
        " " +
        data.poseedor_expedido_en}
    </p>
    <p>
      <strong>{Texto.estado_civil}: </strong>
      {data.poseedor_estado_civil}
    </p>
    <p>
      <strong>{Texto.fecha_poder}: </strong>
      {data.poseedor_fecha_poder}
    </p>

    <p>
      <strong>{Texto.documento}: </strong>
      {data.poseedor_vehiculo_tipo_documento}
    </p>
  </SampleStyle>
);

let breadcrumbs = [
  {
    title: Links[0].title,
    url: Links[0].url,
  },
  {
    title: Links[64].title,
    url: Links[64].url,
  },
];

const CambioRadicatoria = (props) => {
  const titlePage = Texto.cambio_radicatoria;

  const fetch = new Fetch();
  fetch.setToast(toast);
  const auth = new AuthService();

  const modalPdf = new ModalPdf();
  modalPdf.setToast(toast);

  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [typeSearch, setTypeSearch] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (auth.loggedIn()) {
      let userAuth = auth.getProfile();
      if (
        userAuth.code === Constant[0].grupos.funcionario ||
        userAuth.code === Constant[0].grupos.administrador
      ) {
        getDataCertificaciones();
      }
    } else window.location.href = Links[4].url;
    window.jQuery(".sc-kAzzGY").remove(); //pertenece al datatable
  }, []);

  const getDataCertificaciones = async () => {
    setLoading(true);
    const response = await fetch.axiosAsyncGet(
      `api/vehiculos/cambio-radicatoria/per-page/1/${perPage}/${typeSearch}`
    );
    if (response && response.status) {
      setData(response.data);
      setTotalRows(response.total);
      setLoading(false);

      document.getElementById("pEnProceso").innerHTML =
        "En Proceso - " + response.enProceso;
      document.getElementById("pCompletado").innerHTML =
        "Completados - " + response.completado;
      document.getElementById("pAprobado").innerHTML =
        "Aprobados - " + response.aprobado;
      document.getElementById("pRechazados").innerHTML =
        "Rechazados - " + response.rechazado;

      toast.success(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleOpenCertificacion = (event) => {
    event.preventDefault();

    const form = new FormData();
    fetch
      .fetchPost(
        form,
        "api/int/integracion-contribuyentes-certificados",
        undefined
      )
      .then((dataJson) => {
        debugger;
        if (dataJson && dataJson.status)
          history.push(
            Links[62].url + "?csrf-token=" + dataJson.csrfToken + "&call=adm"
          );
      });
  };

  const hanldeSearchLicencias = async (event, typeSearchInt) => {
    setLoading(true);
    const response = await fetch.axiosAsyncGet(
      `api/vehiculos/cambio-radicatoria/per-page/1/${perPage}/${typeSearchInt}`
    );
    if (response && response.status) {
      setData(response.data);
      setLoading(false);
      setTotalRows(response.total);
      setTypeSearch(typeSearchInt);
      toast.success(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleSubmitSearchForm = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    setLoading(true);

    let input = window.jQuery(event.target).find("input").val();
    if (input.length > 0) {
      fetch
        .fetchPost(form, `api/vehiculos/cambio-radicatoria/search`)
        .then((res) => {
          debugger;
          if (res && res.status) {
            setLoading(false);
            setData(res.data);
          } else {
            setLoading(false);
            setData([]);
          }
        });
    } else window.location.reload();
  };

  const handlePreviewClick = (row) => {
    const form = new FormData();
    fetch
      .fetchPost(
        form,
        "api/int/integracion-contribuyentes-certificados",
        undefined
      )
      .then((dataJson) => {
        if (dataJson && dataJson.status) {
          const url = `${Config[0].url}report/cambio-radicatoria/${row.cambio_radicatoria_token}`;
          modalPdf.showPdf(
            `${url}/preview?auth=${auth.getToken()}&csrf-token=${
              dataJson.csrfToken
            }`,
            "Datos del Vehículo con Placa: " + row.vehiculo_placa,
            row.cambio_radicatoria_token,
            undefined,
            `${url}/download?auth=${auth.getToken()}`
          );
        }
      });
  };

  const handleToCheckClick = async (row) => {
    await window.createBootbox(
      "Esta Seguro de Continuar.",
      async function (result) {
        if (result === true) {
          setLoading(true);
          const response = await fetch.axiosAsyncGet(
            `api/vehiculos/cambio-radicatoria/check/${row.cambio_radicatoria_token}`
          );
          if (response && response.status) {
            getDataCertificaciones();
            toast.success(response.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
          setLoading(false);
        }
      }
    );
  };

  const handleToRefuseClick = async (row) => {
    setLoading(true);
    await window.createBootbox(
      "Esta Seguro de Continuar.",
      async function (result) {
        if (result === true) {
          setLoading(true);
          const response = await fetch.axiosAsyncGet(
            `api/vehiculos/cambio-radicatoria/un-check/${row.cambio_radicatoria_token}`
          );
          if (response && response.status) {
            getDataCertificaciones();
            toast.success(response.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
          setLoading(false);
        }
      }
    );
  };

  const handlePageChange = async (page) => {
    setLoading(true);

    const response = await fetch.axiosAsyncGet(
      `api/vehiculos/cambio-radicatoria/per-page/${page}/${perPage}/${typeSearch}`
    );
    if (response && response.status) {
      setData(response.data);
      setCurrentPage(page);
      setLoading(false);
    }
  };

  const handlePerRowsChange = async (perPage, page) => {
    setLoading(true);
    const response = await fetch.axiosAsyncGet(
      `api/vehiculos/cambio-radicatoria/per-page/${page}/${perPage}/${typeSearch}`
    );
    if (response && response.status) {
      setData(response.data);
      setPerPage(perPage);
      setCurrentPage(page);
      setLoading(false);
    }
  };

  const columns = [
    {
      name: Texto.placa,
      selector: "vehiculo_placa",
      sortable: true,
      grow: 1,
      maxWidth: "60px",
    },
    {
      name: Texto.nro_orden,
      selector: "cambio_radicatoria_numero",
      sortable: true,
      grow: 1,
      hide: "md",
      maxWidth: "150px",
    },
    {
      name: Texto.nro_documento,
      sortable: true,
      grow: 1,
      maxWidth: "120px",
      cell: (row) => (
        <RawHtml tag={"span"}>
          {row.poseedor_numero_documento + " " + row.poseedor_expedido_en}
        </RawHtml>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
    {
      name: Texto.servicio,
      selector: "vehiculo_dato_tecnico_servicio",
      sortable: true,
      hide: "md",
      maxWidth: "100px",
    },
    {
      name: Texto.marca,
      selector: "vehiculo_dato_tecnico_marca",
      sortable: true,
      hide: "md",
      maxWidth: "100px",
    },
    {
      name: Texto.tipo,
      selector: "vehiculo_dato_tecnico_tipo_vehiculo",
      sortable: true,
      hide: "md",
      maxWidth: "150px",
    },
    {
      name: Texto.estado,
      center: true,
      sortable: true,
      maxWidth: "100px",
      cell: (row) => (
        <RawHtml tag={"span"}>{row.cambio_radicatoria_estado}</RawHtml>
      ),
      hide: "md",
      ignoreRowClick: true,
      allowOverflow: true,
    },
    {
      name: "Creado",
      selector: "cambio_radicatoria_registrado",
      sortable: true,
      hide: "md",
      maxWidth: "100px",
    },
    {
      name: "",
      sortable: true,
      cell: (row) => (
        <div className="btn-group dropstart">
          <span
            data-bs-toggle="dropdown"
            aria-expanded="false"
            className="pe-2 ps-2"
          >
            <i className="fas fa-ellipsis-v "></i>
          </span>
          <ul className="dropdown-menu">
            <li>
              <Link
                to="#"
                className="dropdown-item"
                onClick={() => handlePreviewClick(row)}
                title={
                  `Cambio de Radicatoria Detallado- ` +
                  row.cambio_radicatoria_numero
                }
              >
                <i className="far fa-file-pdf me-2"></i>
                Resumido
              </Link>
            </li>
            <li>
              <Link
                to="#"
                className="dropdown-item"
                onClick={() => handleToCheckClick(row)}
                title={`Finalizar- ` + row.cambio_radicatoria_numero}
              >
                <i className="fas fa-check me-2"></i>
                Aprobar
              </Link>
            </li>
            <li>
              <Link
                to="#"
                className="dropdown-item"
                onClick={() => handleToRefuseClick(row)}
                title={`Finalizar- ` + row.cambio_radicatoria_numero}
              >
                <i className="fas fa-times me-2"></i>
                Rechazar
              </Link>
            </li>
          </ul>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <section className="items-grid section mt-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={titlePage}
          breadcrumbs={breadcrumbs}
          position={"left"}
          leftfull={false}
        />

        <section className="panel-menu-info">
          <div className="panel-menu-info-content">
            <div className="row">
              <div className="col-4 col-md-2 col-lg-2 mb-3">
                <div
                  className="single-contact-info pointer"
                  onClick={(e) => hanldeSearchLicencias(e, 1)}
                >
                  <i className="fas fa-cog" aria-hidden="true"></i>
                  <p id="pEnProceso">En Proceso</p>
                </div>
              </div>

              <div className="col-4 col-md-2 col-lg-2 mb-3">
                <div
                  className="single-contact-info pointer"
                  onClick={(e) => hanldeSearchLicencias(e, 2)}
                >
                  <i className="far fa-file-alt" aria-hidden="true"></i>
                  <p id="pCompletado">Completado</p>
                </div>
              </div>

              <div className="col-4 col-md-2 col-lg-2 mb-3">
                <div
                  className="single-contact-info pointer"
                  onClick={(e) => hanldeSearchLicencias(e, 3)}
                >
                  <i className="fa fa-check" aria-hidden="true"></i>
                  <p id="pAprobado">Aprobados</p>
                </div>
              </div>

              <div className="col-4 col-md-2 col-lg-2 mb-3">
                <div
                  className="single-contact-info pointer"
                  onClick={(e) => hanldeSearchLicencias(e, 4)}
                >
                  <i className="fas fa-times" aria-hidden="true"></i>
                  <p id="pRechazados">Rechazados</p>
                </div>
              </div>

              <div className="col-4 col-md-2 col-lg-2 mb-3"></div>

              <div className="col-4 col-md-2 col-lg-2 mb-3">
                <div
                  className="single-contact-info pointer"
                  onClick={handleOpenCertificacion}
                >
                  <i className="far fa-file" aria-hidden="true"></i>
                  <p>Nuevo</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <form
            action=""
            className="contact__form center-login"
            name="formSearchDataTable"
            id="formSearchDataTable"
            method="post"
            noValidate
            onSubmit={handleSubmitSearchForm}
          >
            <div
              className="row"
              style={{
                textAlign: "right",
                marginRight: "0px",
                marginLeft: "0px",
              }}
            >
              <div className="col-12 col-md-4 col-lg-6 "></div>

              <div className="col-12 col-md-8 col-lg-6 ">
                <div className="input-group mb-3">
                  <select
                    className="form-select "
                    name="type"
                    required
                    data-parsley-required="true"
                  >
                    <option key={0} value="NUMERO">
                      Número de Orden
                    </option>
                    <option key={1} value="PLACA">
                      Placa
                    </option>
                  </select>
                  <input
                    type="text"
                    name="search"
                    id="search"
                    className="form-control"
                    placeholder="*"
                    aria-label="Buscar Cambio Radicatoria"
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="submit"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Buscar"
                  >
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </section>

        <section>
          <DataTable
            title={titlePage}
            columns={columns}
            data={data}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            //customTheme={rowTheme}
            highlightOnHover
            noDataComponent={Texto.there_are_no_records_to_display}
            progressComponent={<CustomLoader />}
            expandableRowsComponent={<IntentExpandedComponent />}
            expandableRows
          />

          <ModalPdf />
        </section>
      </div>
    </section>
  );
};

export default CambioRadicatoria;
