import React, { useState, useEffect } from "react";
import Texto from "../../data/es";
import Config from "../../data/config";
import Links from "../../data/link";
import TitlePage from "../utils/TitlePage";
import { Link } from "react-router-dom";

const HomeMenuActividadesEconomicas = (props) => {
  const titlePage = Texto.liquidaciones_mixtas;

  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      title: Links[0].title,
      url: Links[0].url,
    },
    {
      title: Links[113].title,
      url: Links[113].url,
    },
    {
      title: Links[33].title,
      url: Links[33].url,
    },
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="items-grid section mt-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={titlePage}
          breadcrumbs={breadcrumbs}
          position={"left"}
          leftfull={false}
        />

        <div className="d-flex align-content-around justify-content-center flex-wrap pt-5 pb-5">
        <div className="p-2 bd-highlight">
            <Link
              to={`${Links[121].url}?liquidacion=patentes-sitios`}
              className="text-decoration-none w-100"
              title={"Sitios y Mercados"}
              rel="noopener noreferrer"
            >
              <div className="square">
                <div className="top bg-aero effect">
                  <img
                    className="mx-auto d-block img-noborder image"
                    alt="img-guia-mercado"
                    width="108"
                    height="108"
                    src={Config[2].url + "/static/img/offcanvas/market.png"}
                  />
                  <div className="text">Sitios y Mercados</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ver</div>
                </div>
              </div>
            </Link>
          </div>

          <div className="p-2 bd-highlight">
            <Link
              to={`${Links[121].url}?liquidacion=patentes-publicidad`}
              className="text-decoration-none w-100"
              title={"Publicidad Exterior"}
              rel="noopener noreferrer"
            >
              <div className="square">
                <div className="top bg-aero effect">
                  <img
                    className="mx-auto d-block img-noborder image"
                    alt="img-guia-publicidad-exterior"
                    width="108"
                    height="108"
                    src={Config[2].url + "/static/img/offcanvas/ads.png"}
                  />
                  <div className="text">Publicidad Exterior</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ver</div>
                </div>
              </div>
            </Link>
          </div>

          <div className="p-2 bd-highlight">
            <Link
              to={`${Links[121].url}?liquidacion=patentes-actividad`}
              className="text-decoration-none w-100"
              title={"Actividad Economica"}
              rel="noopener noreferrer"
            >
              <div className="square">
                <div className="top bg-aero effect">
                  <img
                    className="mx-auto d-block img-noborder image"
                    alt="img-guia-actividades-economicas"
                    width="108"
                    height="108"
                    src={
                      Config[2].url +
                      "/static/img/offcanvas/actividades_econominas.png"
                    }
                  />
                  <div className="text">Actividad Economica</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ver</div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeMenuActividadesEconomicas;
