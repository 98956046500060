import React, { useEffect} from 'react';
import Texto from '../../data/es';
import Config from '../../data/config';

const Guia = (props) => {

    const titlePage = Texto.quienes_somos

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <section className="call-action overlay section bg-picton-blue mt-5" id="alcalde-cbba">
            <div className="container mt-4">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 col-12">
                        <div className="inner container-img-top-up">
                            <div className="content">
                                <div className="wow fadeInUp" data-wow-delay=".3s">
                                    <img className="img-fluid img-thumbnail item-rounded"
                                        src={Config[2].url + '/static/img/offcanvas/cap-manfred-reyes-villa.jpg'}
                                        alt="Cap. Manfred Reyes Villa Bacigalupi" width="410" height="410"
                                    />
                                </div>
                                <h4 className="wow fadeInUp mt-4 text-white fw-normal text-manfred-center" data-wow-delay=".3s">
                                    Cap. Manfred Reyes Villa Bacigalupi
                                </h4>
                                <h6 className="text-white fw-normal">ALCALDE CONSTITUCIONAL</h6>
                                <div className="button wow fadeInUp" data-wow-delay=".3s">
                                    <a href={'https://www.cochabamba.bo/documents/pdf/Manfred-Reyes-Villa.pdf'} className="btn" target="_blank" rel="noreferrer noopener">Descargar Biografía</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="pricing-table section background-image-mebretado-cbba" id="quienes-somos">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="wow fadeInUp d-flex justify-content-center" data-wow-delay=".3s" >
                            <img src={Config[2].url + '/static/img/offcanvas/logo_atm_purple.png'}
                                alt="escudo cbba" className="img-fluid logo-header"
                            />
                        </div>
                    </div>
                </div>
                <div className="row pb-5">

                    <div className="col-lg-12 col-md-12 col-12">
                        <h2 className="wow fadeInUp text-butterfly-bush text-center pb-3" data-wow-delay=".3s">
                            Quienes Somos..?
                        </h2>
                        <p className="text-justify text-mision wow fadeInUp" data-wow-delay=".3s">
                            Somos la Administración Tributaria Municipal (ATM) del Gobierno
                            Autónomo Municipal de Cochabamba, cuya función es administrar tributos
                            municipales a través de la recaudación y fiscalización orientada al
                            cumplimiento oportuno de las obligaciones, para realizar tus tramites o
                            consultas te invitamos a visitar nuestra página web <a href={Config[2].url} title="simat.cochabamba.bom" alt="simat.cochabamba.bo"> https://simat.cochabamba.bo</a>,
                            donde encontraras servicios de Pago Virtual, Consulta de Deudas, obtención
                            de Licencia de Funcionamiento, Visación de Minutas entre Otros.
                        </p>
                    </div>
                </div>
                <div className="row pb-5">
                    <div className="col-lg-12 col-md-12 col-12">
                        <h2 className="wow fadeInUp text-butterfly-bush text-center pb-3" data-wow-delay=".3s">
                            Misión
                        </h2>

                        <p className="text-justify text-mision wow fadeInUp" data-wow-delay=".3s">
                            La misión de la Administración Tributaria Municipal (ATM) del
                            Gobierno Autonomo Municipal de Cochabamba, es administrar la
                            recaudacion , control y fiscalizacion de los tributos municipales,
                            promoviendo el cumplimiento voluntario de los mismos, de acuerdo a
                            la política físcal formulada sobre la base de los principios
                            tributarios constitucionales.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <h2 className="wow fadeInUp text-butterfly-bush text-center pb-3" data-wow-delay=".3s">
                            Visión
                        </h2>
                        <p className="text-justify text-mision wow fadeInUp" data-wow-delay=".3s">
                            La vision que guia y motiva a la Administración Tributaria
                            Municipal (ATM) del Gobierno Autonomo Municipal de Cochabamba, es
                            ser la administracion tributaria referente en la region, cercana
                            al ciudadano, orientada a la innovacion, socialmente responsable y
                            liderando la transformacion digital.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Guia;