import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Config from "../../data/config";
import Texto from "../../data/es";
import Links from "../../data/link";
import TitlePage from "../utils/TitlePage";

const PatentesMunicipales = (props) => {
  const titlePage = Texto.patentes_municipales;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOpenUrlClick = async (ev, url, rubro) => {
    ev.preventDefault();
    /*debugger;
    const csrfToken = await generateToken();
    if (csrfToken && csrfToken.status) {
      if (rubro)
        history.push(
          url +
            "/" +
            rubro +
            "?csrf-token=" +
            csrfToken.csrfToken +
            "&lo=" +
            csrfToken.lo +
            "&expiraEn=" +
            csrfToken.expiraEn
        );
      else history.push(url + "?csrf-token=" + csrfToken.csrfToken);
    }*/
  };

  const breadcrumbs = [
    {
      title: Links[0].title,
      url: Links[0].url,
    },
    {
      title: Links[113].title,
      url: Links[113].url,
    },
  ];
  return (
    <section className="items-grid section mt-5 mb-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={titlePage}
          breadcrumbs={breadcrumbs}
          position={"left"}
          leftfull={false}
        />

        <div className="d-flex align-content-around justify-content-center flex-wrap">
          <div className="p-2 bd-highlight">
            <Link
              to={{ pathname: Links[1].url }}
              className="text-decoration-none w-100"
              title={Links[1].title}
            >
              <div className="square">
                <div className="top bg-aero">
                  <img
                    className="mx-auto d-block img-noborder image"
                    alt="img-licencia-funcionamiento"
                    width="108"
                    height="108"
                    src={
                      Config[2].url +
                      "/static/img/offcanvas/actividades_econominas.png"
                    }
                  />
                  <div className="text">Licencia Funcionamiento</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
          </div>
          <div className="p-2 bd-highlight">
            <Link
              to={{ pathname: Links[92].url }}
              className="text-decoration-none w-100 d-block"
              title={Links[92].title}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="square">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image"
                    alt="img-pago-qr"
                    width="108"
                    height="108"
                    src={
                      Config[2].url + "/static/img/offcanvas/qr_pago_atm.png"
                    }
                  />
                  <div className="text">Pagos QR</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>

            <Link
              to={{
                pathname:
                  "https://www.ruat.gob.bo/actividadeseconomicas/consultapago/InicioBusquedaActividadesEconomicas.jsf",
              }}
              className="text-decoration-none w-100 mtr-1em"
              title={"Imprimir Pagos"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="square-footer ">
                <div className="text bg-carrot-orange-light text-white fw-600">
                  Imprimir Pagos
                </div>
              </div>
            </Link>
          </div>
          
          <div className="p-2 bd-highlight">
            <Link
              to={{
                pathname:
                  "https://www.ruat.gob.bo/actividadeseconomicas/consultageneral/InicioBusquedaActividadesEconomicas.jsf",
              }}
              className="text-decoration-none w-100"
              title={"Consulta tu Deuda"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="square">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image"
                    alt="img-consulta-deuda"
                    width="108"
                    height="108"
                    src={
                      Config[2].url + "/static/img/offcanvas/consulta_deuda.png"
                    }
                  />
                  <div className="text">Consulta tu Deuda</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
          </div>

          <div className="p-2 bd-highlight">
            <Link
              to={{
                pathname:
                  "https://tramitesgamc.cochabamba.bo/#/consulta-sitiosymercados-iframe",
              }}
              className="text-decoration-none w-100"
              title={"Sitios y Mercados"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="square">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image"
                    alt="img-market"
                    width="108"
                    height="108"
                    src={Config[2].url + "/static/img/offcanvas/market.png"}
                  />
                  <div className="text">Sitios y Mercados</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
          </div>

          <div className="p-2 bd-highlight">
            <Link
              to={{
                pathname:
                  "https://tramitesgamc.cochabamba.bo/#/consulta-sitiosymercados-iframe",
              }}
              className="text-decoration-none w-100"
              title={"Publicidad Exterior"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="square">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image"
                    alt="img-publicidad-exterior"
                    width="108"
                    height="108"
                    src={Config[2].url + "/static/img/offcanvas/ads.png"}
                  />
                  <div className="text">Publicidad Exterior</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
          </div>

          {/*<div className="p-2 bd-highlight">
            <Link
              to={`${Links[122].url}`}
              className="text-decoration-none w-100"
              title={"Liquidaciones Mixtas"}
            >
              <div className="square">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image"
                    alt="img-liquidacion-mixta"
                    width="108"
                    height="108"
                    src={Config[2].url + "/static/img/offcanvas/ddjj.png"}
                  />
                  <div className="text">Liquidaciones Mixtas</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
            </div>*/}

          <div className="p-2 bd-highlight">
            <Link
              to={`${Links[124].url}`}
              className="text-decoration-none w-100"
              title={"Liquidaciones Mixtas"}
            >
              <div className="square">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image"
                    alt="img-liquidacion-mixta"
                    width="108"
                    height="108"
                    src={Config[2].url + "/static/img/offcanvas/ddjj.png"}
                  />
                  <div className="text">Liquidaciones Mixtas</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
          </div>

          <div className="p-2 bd-highlight">
            <Link
              to={{ pathname: Links[114].url }}
              className="text-decoration-none w-100"
              title={"Requisitos"}
            >
              <div className="square">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image"
                    alt="img-requiment"
                    width="108"
                    height="108"
                    src={
                      Config[2].url + "/static/img/offcanvas/requirement.png"
                    }
                  />
                  <div className="text">Requisitos</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
          </div>

          <div className="p-2 bd-highlight">
            <Link
              to={{ pathname: Links[90].url }}
              className="text-decoration-none w-100 d-block"
              title={Links[89].title}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="square">
                <div className="top bg-aero">
                  <img
                    className=" mx-auto d-block img-noborder image"
                    alt="img-pagos-qr"
                    width="108"
                    height="108"
                    src={
                      Config[2].url + "/static/img/offcanvas/qr_pago_atm.png"
                    }
                  />
                  <div className="text">Multas y Otros</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Ingresar</div>
                </div>
              </div>
            </Link>
            <Link
              to={{
                pathname:
                  "https://www.ruat.gob.bo/ingresosnotributarios/consultapago/InicioBusquedaIngresosNoTributarios.jsf?cGM=GM-CBBA",
              }}
              className="text-decoration-none w-100 mtr-1em"
              title={"Imprimir Pagos"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="square-footer ">
                <div className="text bg-carrot-orange-light text-white fw-600">
                  Imprimir Pagos
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PatentesMunicipales;
