import React, { Component } from "react";

import Links from "../../data/link";
import Fetch from "../utils/Fetch";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ValidateTipoVisaciones from "./ValidateTipoVisaciones";
import ValidatePropiedad from "./ValidatePropiedad";
import ValidateFur from "./ValidateFur";

class ModalVisacionMinutas extends Component {
  constructor(props, context) {
    super(props, context);
    this.idModalVisacion = "modalVisacionFull";
    this.formModalVisacion = "formModalVisacion";

    this.handleVoidEnter = this.handleVoidEnter.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);

    this.state = {
      tipoVisacionMinuta: {},
      dataInmueble: undefined,
      dataPropiedadSelected: undefined,
      dataDerechoAdmision: undefined,
      gestionesDeuda: undefined,
      currentPage: 0,
      csrfkey: undefined,
    };

    this.token = window.getParameterByName("csrf-token");
    this.local = window.getParameterByName("lo");
    this.expiraEn = window.getParameterByName("expiraEn");
    this.preview = window.getParameterByName("preview");
    this.adm = window.getParameterByName("adm");

    this.fetch = new Fetch();
    this.fetch.setToast(toast);
  }

  handleCancelClick(event) {
    event.preventDefault();
    // aqui verificar el token csr-key
    if (this.token && this.local && this.expiraEn && this.preview && this.adm)
      window.location.href = Links[56].url + "?cert=detallado";
    else if (this.token && this.local && this.expiraEn) {
      if (this.local === "0")
        window.location.href =
          Links[49].url +
          "?csrf-token=" +
          this.token +
          "&lo=" +
          this.local +
          "&expiraEn=" +
          this.expiraEn;
      else window.location.href = Links[39].url;
    } else window.location.href = Links[39].url;
  }

  handleVoidEnter(e) {
    e.preventDefault();
    this.validateForm();
  }

  validateForm = () => {
    window
      .jQuery("#" + this.formModalVisacion)
      .parsley()
      .validate();

    if (document.getElementById("inputModalFur")) {
      const liErrorFur = document.getElementById("inputModalFur").nextSibling;
      if(liErrorFur){
        document
        .getElementById("inputModalFur")
        .parentElement.parentElement.append(liErrorFur);
      }
      
    }

    if (document.getElementById("tipo_visacion[numero]")) {
      const ul_error_ci = document.getElementById(
        "tipo_visacion[numero]"
      ).nextSibling;
      if(ul_error_ci){
        document
        .getElementById("tipo_visacion[numero]")
        .parentElement.parentElement.append(ul_error_ci);
      }
      
    }
  };

  nextPageCurrent = (currentPage, data = undefined) => {
    if (!this.state.csrfkey)
      this.setState((state) => ({
        csrfkey: {
          csrfToken: this.token,
          lo: this.local,
          expiraEn: this.expiraEn,
          preview: this.preview,
          adm: this.adm,
        },
      }));
    if (currentPage === 1) {
      this.setState((state) => ({
        currentPage: currentPage,
        dataDerechoAdmision: data,
      }));
    }
    if (currentPage === 2 && data.hasOwnProperty("inmueble")) {
      let dataInmueble = {
        dataInmueble: data.inmueble,
        dataTecnico: data.datosTecnicos,
        dataGestionDeuda: data.gestionDeuda,
      };
      const inmuebleSelected = {
        claseInmueble: data.inmueble.claseInmueble,
        codigoCatastral: data.inmueble.codigoCatastral,
        codigoInmueble: data.inmueble.codigoInmueble,
        numeroInmueble: data.inmueble.numeroInmueble,
        codigoPropiedad: data.inmueble.propiedades[0].codigoPropiedad,
        codigoContribuyente: data.inmueble.propiedades[0].codigoContribuyente,
        estadoPropiedad: data.inmueble.propiedades[0].estadoPropiedad,
        fechaInicioPropiedad: window
          .moment(
            data.inmueble.propiedades[0].fechaInicioPropiedad,
            "DD/MM/YYYY",
            true
          )
          .isValid()
          ? data.inmueble.propiedades[0].fechaInicioPropiedad
          : null,
        fechaFinPropiedad: window
          .moment(
            data.inmueble.propiedades[0].fechaFinPropiedad,
            "DD/MM/YYYY",
            true
          )
          .isValid()
          ? data.inmueble.propiedades[0].fechaFinPropiedad
          : null,
        porcentaje: data.inmueble.propiedades[0].porcentaje,
        propietario: data.inmueble.propiedades[0].propietario,
        tipoVisacionMinuta: data.tipoVisacionMinuta,
        selected: true,

        typeSearch: data.typeSearch,
      };

      this.setState((state) => ({
        currentPage: currentPage,
        dataInmueble: dataInmueble,
        tipoVisacionMinuta: data.tipoVisacionMinuta,
        derechoAdmision: data.derechoAdmision,
        dataPropiedadSelected: inmuebleSelected,
      }));
    }

    if (currentPage === 3) {
      this.setState((state) => ({
        currentPage: currentPage,
        gestionesDeuda: data.gestionesDeuda,
        dataPropiedadSelected: data,
      }));
    }
  };

  render() {
    return (
      <div
        className="modal fade modal-custom-atm"
        id={this.idModalVisacion}
        tabIndex="-1"
        aria-labelledby="modalVisacionMinutaFullTitle"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        style={{ overflowY: "auto" }}
      >
        <div
          className="modal-dialog modal-md"
          style={{ boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.75)" }}
        >
          <div className="modal-content ">
            <div className="modal-header text-butterfly-bush justify-content-center">
              <h4
                className="modal-title "
                id="modalVisacionMinutaLongTitle"
                style={{ fontWeight: 600 }}
              >
                VISACIÓN MINUTAS
              </h4>
            </div>
            <div className="modal-body">
              <form
                className="contact__form"
                style={{ margin: "0.5rem" }}
                onSubmit={this.handleVoidEnter}
                id={this.formModalVisacion}
              >
                {this.state.currentPage === 0 ? (
                  <ValidateFur
                    handleCancelClick={this.handleCancelClick}
                    fetch={this.fetch}
                    validateForm={this.validateForm}
                    nextPageCurrent={this.nextPageCurrent}
                    csrfkey={this.state.csrfkey}
                  />
                ) : (
                  ""
                )}
                {this.state.currentPage === 1 ? (
                  <ValidateTipoVisaciones
                    handleCancelClick={this.handleCancelClick}
                    fetch={this.fetch}
                    dataDerechoAdmision={this.state.dataDerechoAdmision}
                    validateForm={this.validateForm}
                    nextPageCurrent={this.nextPageCurrent}
                    csrfkey={this.state.csrfkey}
                  />
                ) : (
                  ""
                )}

                {this.state.currentPage === 2 ? (
                  <ValidatePropiedad
                    handleCancelClick={this.handleCancelClick}
                    fetch={this.fetch}
                    nextPageCurrent={this.nextPageCurrent}
                    dataInmueble={this.state.dataInmueble}
                    tipoVisacionMinuta={this.state.tipoVisacionMinuta}
                    dataDerechoAdmision={this.state.dataDerechoAdmision}
                    csrfkey={this.state.csrfkey}
                    closeModal={this.props.closeModal}
                  />
                ) : (
                  ""
                )}
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalVisacionMinutas;
