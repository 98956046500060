const linksSimat = [
  {
    title: "Inicio",
    url: "/",
  },
  // 2
  {
    title: "Licencia de Funcionamiento",
    url: "/patentes-municipales/licencia-actividad-economica",
    code: "licenciaFuncionamiento",
  },
  {
    title: "Declaracion Jurada Create",
    url: "/patentes-municipales/licencia-actividad-economica-create",
  },
  {
    title: "Prescripción",
    //url: "/prescripcion",
    url: "/page-404",
  },
  {
    title: "Login",
    url: "/login",
  },
  {
    title: "Registrar Usuario",
    url: "/usuario-create",
  },
  {
    title: "Declaracion Jurada Edit",
    url: "/patentes-municipales/licencia-actividad-economica-edit",
  },
  {
    title: "Confirmar Cuenta de Usuario",
    url: "/usuario-confirmar-cuenta",
  },
  {
    title: "Perfil",
    url: "/usuario-perfil",
  },
  {
    title: "404",
    url: "/page-404",
  },
  {
    title: "Recuperar Contraseña",
    url: "/recuperar-contrasenia",
  },
  {
    title: "Centro de Ayuda",
    url: "/ayuda",
  },
  {
    //12
    title: "Usuario",
    url: "/usuario",
  },
  {
    //13
    title: "Solicitar Envio de Mail para activación de cuenta.",
    url: "/usuario-reactivacion-cuenta",
  },
  {
    //14
    title: "Soporte Gerencial",
    url: "/soporte-gerencial",
  },
  {
    //15
    title: "Certificado de Registro Ambiental Municipal", //Certificado de Registro Ambiental Municipal
    code: "Cram",
    url: "/cram",
  },
  {
    //16
    title: "Cram create",
    code: "CramCreate",
    url: "/cram-create",
  },
  {
    //17
    title: "Ruat",
    code: "Ruat",
    url: "/patentes-municipales/licencia-actividad-economica-ruat",
  },
  {
    //18
    title: "Plataforma de Atención Virtual",
    code: "PlataformaAtencionVirtual",
    url: "#",
  },
  {
    //19
    title: "Prescripción Create",
    // url: "/prescripcion-create",
    url: "/page-404",
  },
  {
    //20
    title: "Prescripción Edit",
    // url: "/prescripcion-edit",
    url: "/page-404",
  },
  {
    //21
    title: "Notificaciones",
    code: "NOTIFICACIONES",
    url: "/notificacion",
  },
  {
    //22
    title: "Guías",
    code: "GUIA",
    url: "/guia",
  },
  {
    //23
    title: "Perdonazo 2020",
    code: "Perdonazo2020",
    url: "/perdonazo-2020",
  },
  {
    //24
    title: "Tercera Edad",
    code: "TerceraEdad",
    //url: 'http://sandbox.cochabamba.bo/descuentos/solicitar-autorizacion'
    url: "https://perdonazo2020.cochabamba.bo",
  },
  {
    //25
    title: "Servicio Público",
    code: "ServicioPublico",
    //url: 'http://sandbox.cochabamba.bo/descuentos/seleccionar-persona'
    url: "https://perdonazo2020.cochabamba.bo/seleccionar-persona",
  },
  {
    //26
    title: "Sitio y Mercados",
    code: "SitioMercados",
    url: "https://tramitesgamc.cochabamba.bo/#/consulta-sitiosymercados-iframe",
  },
  {
    //27
    title: "LEY MUNICIPAL DE INCENTIVO TRIBUTARIO 0719/2020",
    code: "0719/2020",
    url: "https://atmapi.cochabamba.bo/restServerAtm/legal-tributario/document/10",
  },
  {
    //28
    title:
      "DECRETO MUNICIPAL 187-2020 09-10-2020 APRUEBA REGLAMENTO L.M. 0719-2020 INCENTIVO TRIBUTARIO",
    code: "187/2020",
    url: "https://atmapi.cochabamba.bo/restServerAtm/legal-tributario/document/11",
  },
  {
    //29
    title: "API Raiz Catastro",
    code: "ARCGIS_ROOT",
    url: "https://catastroapi.cochabamba.bo/arcgis/services/",
  },
  {
    //30
    title: "API Raiz Catastro busqueda",
    code: "ARCGIS_SEARCH",
    url: "https://catastroapi.cochabamba.bo/arcgis/rest/services/",
  },
  {
    //31
    title: "ENCUESTA DE PERCEPCIÓN 2020",
    code: "AIT",
    url: "https://docs.google.com/forms/d/1XS-8hZcAsES838DmRoubEZ2wE8aRbBRKhWuTW3DfTHg/viewform?edit_requested=true",
  },
  {
    //32
    title: "CONSULTA TU DEUDA",
    code: "CTD",
    url: "/notificacion",
  },
  {
    //33
    title: "Liquidaciones Mixtas",
    code: "LIQUIDACIONES ",
    url: "/liquidaciones",
  },
  {
    //34
    title: "Liquidaciones Mixtas",
    code: "LIQUIDACIONES MIXTAS",
    url: "/liquidaciones/mixtas",
  },
  {
    //35
    title: "Notificaciones Mixtas",
    code: "NOTIFICACIONES MIXTAS",
    url: "/notificaciones/mixtas",
  },
  {
    //36
    title: "Consulta tu Deuda",
    code: "CONSULTA TU DEUDA",
    url: "/consulta-tu-deuda",
  },
  {
    //37
    title: "Prescripcion Edit",
    // url: "/prescripcion-edit",
    url: "/page-404",
  },
  {
    //38
    title: "Declaraciones Juradas",
    code: "ddjj",
    url: "/declaraciones-juradas",
  },
  {
    //39
    title: "Visación Minutas Inmuebles",
    code: "VISACION",
    url: "/inmuebles/visacion-minutas",
    //url: "https://sistemavisacionminutas.cochabamba.bo/datos_registro",
  },
  {
    //40
    title: "Fiscalización",
    code: "FISCALIZACION",
    url: "/fiscalizacion",
  },
  {
    //41
    title: "Consolidación Fiscalización",
    code: "CONSOLIDACION_FISCALIZACION",
    // url: "/prescripcion-fiscalizacion/consolidacion",
    url: "/page-404",
  },
  {
    //42
    title: "Prescripción Detalle",
    code: "PRESCRIPCION_DETALLE",
    // url: "/prescripcion-fiscalizacion/detalle",
    url: "/page-404",
  },
  {
    //43
    title: "Prescripción Fiscalizacion",
    code: "PRESCRIPCION FISCALIZACION",
    // url: "/prescripcion-fiscalizacion",
    url: "/page-404",
  },
  {
    //44
    title: "Prescripción Detalle",
    code: "PRESCRIPCION_DETALLE_FISCALIZACION",
    //url: "/prescripcion-legal-tributario/detalle",
    url: "/page-404",
  },
  {
    //45
    title: "Prescripción Legal Tributario",
    code: "PRESCRIPCION_LEGAL_TRIBUTARIO",
    // url: "/prescripcion-legal-tributario",
    url: "/page-404",
  },
  {
    //46
    title: "Prescripción Legal Tributario Consolidación",
    code: "PRESCRIPCION_LEGAL_TRIBUTARIO_CONSOLIDACION",
    // url: "/prescripcion-legal-tributario/consolidacion",
    url: "/page-404",
  },
  {
    //47
    title: "Legal Tributario",
    code: "LEGAL TRIBUTARIO",
    url: "/legal-tributario",
  },
  {
    //48
    title: "Pagos RUAT",
    code: "PAGOS_RUAT",
    url: "https://www.ruat.gob.bo/serviciosdisponibles/pagointernet/InicioPagoInternet.jsf",
  },
  {
    //49
    title: "Certificaciones",
    code: "CERTIFICACION",
    url: "/inmuebles/certificaciones",
  },
  {
    //50
    title: "Emision de Certificado de Bienes Inmuebles",
    code: "EMISION_CERTIFICADO_INMUEBLES",
    url: "/inmuebles/certificaciones/emitir",
  },
  {
    //51
    title: "Prescripción Legal Tributario Registro de la RTA",
    code: "PRESCRIPCION_LEGAL_TRIBUTARIO_RTA",
    // url: "/prescripcion-legal-tributario/registrar-rta",
    url: "/page-404",
  },
  {
    //52
    title: "Certificación Transporte Público",
    code: "CERTIFICADO_TRANSPORTE_PUBLICO",
    url: "/certificacion-transporte-publico",
  },
  {
    //53
    title: "RESOLUCION TECNICO ADMINISTRATIVO 1754/2021",
    code: "1754/2021",
    url: "https://atmapi.cochabamba.bo/restServerAtm/legal-tributario/document/47",
  },
  {
    //54
    title: "INNOVA",
    code: "INNOVA",
    url: "https://innova.cochabamba.bo/",
  },
  {
    //55
    title: "Quienes Somos?",
    code: "QUIENES_SOMOS",
    url: "/quienes-somos",
  },
  {
    //56
    title: "Certificación Admin?",
    code: "CERTIFICACION_ADM",
    url: "/certificacion-admin",
  },
  {
    //57
    title: "Requisitos Inmuebles",
    code: "REQUISITOS_INMUEBLES",
    url: "/guia/requisitos-inmuebles",
  },
  {
    //58
    title: "RESOLUCION TECNICO ADMINISTRATIVO 051/2021",
    code: "051/2021",
    url: "https://atmapi.cochabamba.bo/restServerAtm/legal-tributario/document/37",
  },
  {
    //59
    title: "Requisitos Vehículos",
    code: "REQUISITOS_VEHICULOS",
    url: "/guia/requisitos-vehiculos",
  },
  {
    //60
    title: "Requisitos Actividades Ecónomicas",
    code: "REQUISITOS_ACTIVIDADES_ECONOMICAS",
    url: "/guia/requisitos-actividades-economicas",
  },
  {
    //61
    title: "Requisitos Publicidad",
    code: "REQUISITOS_PUBLICIDAD",
    url: "/guia/requisitos-publicidad",
  },
  {
    //62
    title: "Registro Cambio de Radicatoria Vehículos",
    code: "VEHICULOS_CAMBIO_RADICATORIA_REGISTRO",
    url: "/vehiculos-cambio-radicatoria/registrar",
  },
  {
    //63
    title: "RESOLUCION TECNICO ADMINISTRATIVO 2767/2021",
    code: "2767/2021",
    url: "https://atmapi.cochabamba.bo/restServerAtm/legal-tributario/document/51",
  },
  {
    //64
    title: "Administración Cambio de Radicatoria Vehículos",
    code: "VEHICULOS_CAMBIO_RADICATORIA_ADMIN",
    url: "/vehiculos-cambio-radicatoria-admin",
  },
  {
    //65
    title: "Resolución Técnica Administrativa 2801/2021",
    code: "RTA_2801_2021",
    url: "https://atmapi.cochabamba.bo/restServerAtm/documents/preview/rta-2801-2021",
  },
  {
    //66
    title: "Requisito Renovacion Actividad Economica Permanente",
    code: "REQUISITO_RENOVACION_ACT_ECO_PERMANENTE",
    url: "https://atmapi.cochabamba.bo/restServerAtm/documents/download/req-act-eco-renovacion-permante",
  },
  {
    //67
    title: "Requisito Baja sin Deuda Actividad Economica Permanente",
    code: "REQUISITO_BAJA_SIN_DEUDA_ACT_ECO_PERMANENTE",
    url: "https://atmapi.cochabamba.bo/restServerAtm/documents/download/req-act-eco-baja-sin-deuda",
  },
  {
    //68
    title:
      "Requisito Fallecimiento Sujeto Pasivo Actividad Economica Permanente",
    code: "REQUISITO_FALLECIMIENTO_SUJETO_PASIVO_ACT_ECO_PERMANENTE",
    url: "https://atmapi.cochabamba.bo/restServerAtm/documents/download/req-act-eco-fallecimiento-sujeto-pasivo",
  },
  {
    //69
    title: "Requisito Duplicidad Actividad Economica Permanente",
    code: "REQUISITO_DUPLICIDAD_ACT_ECO_PERMANENTE",
    url: "https://atmapi.cochabamba.bo/restServerAtm/documents/download/req-act-eco-duplicidad",
  },
  {
    //70
    title: "Requisito Inconsistencia de Datos Actividad Economica Permanente",
    code: "REQUISITO_INCONSISTENCIA_DATOS_ACT_ECO_PERMANENTE",
    url: "https://atmapi.cochabamba.bo/restServerAtm/documents/download/req-act-eco-inconsistencia-datos",
  },
  {
    //71
    title: "Requisito Baja Definitiva con Deuda Actividad Economica Permanente",
    code: "REQUISITO_BAJA_DEFINITIVA_CON_DEUDA_ACT_ECO_PERMANENTE",
    url: "https://atmapi.cochabamba.bo/restServerAtm/documents/download/req-act-eco-baja-definitiva-con-deuda",
  },
  {
    //72
    title: "Métodos de Pago Online Ruat",
    code: "METODO_PAGO_ONLINE_RUAT",
    url: "/metodos-pago-ruat",
  },
  {
    //73
    title: "Método de Pago QR Vehículos",
    code: "METODO_PAGO_QR_VEHICULOS",
    url: "https://www.ruat.gob.bo/pagosqr/InicioBusquedaVehiculo.jsf?SDG3WF24=1",
  },
  {
    //74
    title: "Resolución Técnica Administrativa 2881/2021",
    code: "RTA_2881_2021",
    url: "https://atmapi.cochabamba.bo/restServerAtm/documents/preview/rta-2881-2021",
  },
  {
    //75
    title: "Requisito Otorgación Actividad Economica Temporal",
    code: "REQUISITO_OTORGACION_ACT_ECO_TEMPORAL",
    url: "https://atmapi.cochabamba.bo/restServerAtm/documents/download/req-act-eco-otorgacion-temporal",
  },
  {
    //76
    title: "Requisito Renovación Actividad Economica Temporal",
    code: "REQUISITO_RENOVACION_ACT_ECO_TEMPORAL",
    url: "https://atmapi.cochabamba.bo/restServerAtm/documents/download/req-act-eco-renovacion-temporal",
  },
  {
    //77
    title: "Infracciones-Sacciones",
    code: "INFRACCIONES_SANCIONES",
    url: "/infracciones-sanciones",
  },
  {
    //78
    title: "Proceso de Inicio de Ejecución Tributaria",
    code: "PIET",
    url: "/legal-tributario/piets",
  },
  {
    //79
    title: "Proceso de Inicio de Ejecución Tributaria Actividades Economicas",
    code: "PIET_ACTIVIDADES_ECONOMICAS",
    url: "/legal-tributario/piets/actividades-economicas",
  },
  {
    //80
    title: "Proceso de Inicio de Ejecución Tributaria Vehículos",
    code: "PIET_VEHICULOS",
    url: "/legal-tributario/piets/inmuebles",
  },
  {
    //81
    title: "Proceso de Inicio de Ejecución Tributaria Inmuebles",
    code: "PIET_INMUEBLES",
    url: "/legal-tributario/piets/vehiculos",
  },
  {
    //82
    title: "Certificaciones",
    code: "CERTIFICACIONES",
    url: "/certificaciones",
  },
  {
    //83
    title: "RTA 42/2021 0001",
    code: "RTA_42_2021",
    url: "https://atmapi.cochabamba.bo/restServerAtm/documents/preview/rta_42_2021_0001",
  },
  {
    //84
    title: "RTA 534/2022 0001",
    code: "RTA_534_2021",
    url: "https://atmapi.cochabamba.bo/restServerAtm/documents/preview/rta-534-2022-0001",
  },
  {
    //85
    title: "Prescripción Liquidación",
    code: "Liquidación",
    // url: "/prescripcion-liquidacion",
    url: "/page-404",
  },
  {
    //86
    title: "Visación",
    code: "VISACION",
    url: "/visacion",
  },
  {
    //87
    title: "Visación Registro",
    code: "VISACION CREATE",
    url: "/inmuebles/visacion-minutas/create",
  },
  {
    //88
    title: "Visación Minutas",
    url: "/inmuebles/visacion-minutas",
  },
  {
    //89
    title: "Ruat QR Inmuebles",
    url: "https://www.ruat.gob.bo/pagosqr/InicioBusquedaInm.jsf?SDG3WF24=2",
  },
  {
    //90
    title: "Ruat QR Multas y Otros",
    url: "https://www.ruat.gob.bo/pagosqr/InicioPagosQrIngNoTrib.jsf?SDG3WF24=9",
  },
  {
    //91
    title: "Visación Edición",
    code: "VISACION EDIT",
    url: "/inmuebles/visacion-minutas/edit",
  },
  {
    //92
    title: "Actividades Economicas",
    code: "ACTIVIDADES_ECONOMICAS_PAGO",
    url: "https://www.ruat.gob.bo/pagosqr/InicioBusquedaActEco.jsf?SDG3WF24=4",
  },
  {
    //93
    title: "Movilidad Urbana Inicio",
    code: "MOVILIDAD_URBANA_HOME",
    url: "/inicio-movilidad-urbana",
  },
  {
    //94
    title: "Legal Tributario Inicio",
    code: "LEGAL TRIBUTARIO_HOME",
    url: "/inicio-legal-tributario",
  },
  {
    //95
    title: "Resolción Administrativa Sancionatoria",
    code: "RAS",
    url: "/inicio-movilidad-urbana/ras",
  },
  {
    //96
    title: "Resolción Administrativa Sancionatoria Vehículos",
    code: "RAS_VEHICULOS",
    url: "/inicio-movilidad-urbana/ras/vehiculos",
  },
  {
    //97
    title: "Fur Gratuito",
    code: "FUR_GRATUITO",
    url: "/fur-gratuito/",
  },
  {
    //98
    title: "Contribuyentes",
    code: "CONTRIBUYENTE",
    url: "/contribuyentes/",
  },
  {
    //99
    title: "Emision de Certificado de Vehículos Automotores Terrestres",
    code: "EMISION_CERTIFICADO_VEHICULOS",
    url: "/vehiculos/certificacion/emitir",
  },
  {
    //100
    title: "Certificación Admin?",
    code: "CERTIFICACION_VEH_ADM",
    url: "/certificacion-vehiculos-admin",
  },
  {
    //101
    title: "Impuesto Municipal a la Transferencia Onerosa",
    code: "IMTO",
    url: "/inmuebles/visacion-minutas/imto/",
  },
  {
    //102
    //title: "Impuesto Municipal al Consumo Especifico",
    //code: "IMCE",
    //url: "/imce/",
  }, 
  {
    //103
    //title: "Chicha de Maiz",
    //code: "CHICHA-MAIZ",
    //url: "/imce/chicha-maiz/",
  }, 
  {
    //104
    //title: "Registrar Chicha de Maiz",
    //code: "REGISTRAR-CHICHA-MAIZ",
    //url: "/imce/chicha-maiz/create",
  }, 
  {
    //105
    title: "Notificaciones Coactivas",
    code: "NOTIFICACIONES-COACTIVAS",
    url: "/inicio-movilidad-urbana/ras-coactivos/vehiculos",
  },
  {
    //106
    title: "Resolución Técnica Administrativa 0001/2023",
    code: "RTA_0001_2023",
    url: "https://atmapi.cochabamba.bo/restServerAtm/documents/preview/rta-0001-2023",
  },
  {
    //107
    title: "Resolución Técnica Administrativa 0097/2023",
    code: "RTA_0097_2023",
    url: "https://atmapi.cochabamba.bo/restServerAtm/documents/preview/rta-0097-2023",
  },
  {
    //108
    title: "Autorización Telecomunicaciones",
    code: "AUTORIZACION_TELECOMUNICACIONES",
    url: "/autorizacion-telecomunicaciones",
  },
  {
    //109
    title: "Autorización Telecomunicaciones Registro",
    code: "AUTORIZACION_TELECOMUNICACIONES_CREATE",
    url: "/autorizacion-telecomunicaciones/create",
  },
  {
    //110
    title: "Autorización Telecomunicaciones Edición",
    code: "AUTORIZACION_TELECOMUNICACIONES_EDIT",
    url: "/autorizacion-telecomunicaciones/edit",
  },
  {
    //111
    title: "Inmuebles",
    code: "INMUEBLES",
    url: "/inmuebles",
  },
  {
    //112
    title: "Vehiculos",
    code: "VEHICULOS",
    url: "/vehiculos",
  },
  {
    //113
    title: "Patentes Municipales",
    code: "PATENTES_MUNICIPALES",
    url: "/patentes-municipales",
  },
  {
    //114
    title: "Guías Patentes Municipales",
    code: "GUIA_PATENTES",
    url: "/guia-patentes-municipales",
  },
  {
    //115
    title: "Gaceta Tributaria",
    code: "GACETA_TRIBUTARIA",
    url: "/gaceta-tributaria",
  },
  // liquidacion tributaria - masiva
  {
    //116
    title: "Liquidaciones Mixtas",
    code: "LIQUIDACION_INICIO",
    url: "/mixtas",
  },
  {
    //117
    title: "Liquidaciones Mixtas (Funcionario)",
    code: "LIQUIDACION_INICIO",
    url: "/mixtas/gamc",
  },
  {
    //118
    title: "Objeto Tributario (Funcionario)",
    code: "LIQUIDACION_OBJETOTRIBUTARIO",
    url: "/mixtas/lista",
  },
  {
    //119
    title: "Búsqueda Personalizada",
    code: "LIQUIDACION_SEARCH",
    url: "/mixtas/consulta",
  },
  {
    //120
    title: "Gaceta Tributaria Admin",
    code: "GACETA_TRIBUTARIA ADMIN",
    url: "/gaceta-tributaria-admin",
  },
  {
    //121
    title: "Liquidacion Mixtas Masivas",
    code: "LIQUIDACION MIXTAS MASIVAS",
    url: "/mixtas",
  },
  {
    //122
    title: "Liquidacion Mixtas Inmuebles",
    code: "LIQUIDACION MIXTAS INMUEBLES",
    url: "/mixtas/inicio/inmuebles",
  },
  {
    //123
    title: "Liquidacion Mixtas Vehiculos",
    code: "LIQUIDACION MIXTAS VEHICULOS",
    url: "/mixtas/inicio/vehiculos",
  },
  {
    //124
    title: "Liquidacion Mixtas Patentes Municipales",
    code: "LIQUIDACION MIXTAS PATENTES MUNICIPALES",
    url: "/mixtas/inicio/patentes",
  },
  {
    //125
    title: "Resolución Técnica Administrativa 018/2024",
    code: "RTA_018_2024",
    url: "https://atmapi.cochabamba.bo/restServerAtm/documents/preview/rta-018-2024",
  },
  {
    //126
    title: "Resetear Contraseña",
    code: "RESETEAR CONTRASEÑA",
    url: "/login/reset",
  },

  {
    //127
    title: "Registrar usuario SIMAT",
    code: "REGISTRAR USUARIO SIMAT",
    url: "/register",
  },
  // 128/
  {    
    title: "Actividades Económicas",
    code: "ACTIVIDADES_ECONOMICAS",
    url: "/actividades-economicas",
  },
  // 129
  {    
    title: "Publicidad",
    code: "PUBLICIDAD_INICIO",
    url: "/actividades-economicas/publicidad",
  },
  // 130
  {    
    title: "Declaración Jurada",
    code: "PUBLICIDAD_INICIO_DDJJ",
    url: "/actividades-economicas/publicidad/ddjj",
  },
  // 131
  {    
    title: "Nuevo",
    code: "PUBLICIDAD_NUEVO_REGISTRO",
    url: "/actividades-economicas/publicidad/create",
  },
//132
  {    
    title: "Registro Publicidad pendiente",
    code: "PUBLICIDAD_LISTA_DDJJ",
    url: "/actividades-economicas/publicidad/lista",
  },
  // 133
  {
    title: "Autorización Publicidad",
    code: "PUBLICIDAD_LISTA",
    url: "#",
  },
  // ras  134
  {
    title: "Resolucion Administrativa Sancionatoria ",
    code: "RAS_ADMIN",
    url: "/ras/admin",
  },

  // 135 - ras/vehiculo
  {
    title: "Resolucion Administrativa Sancionatoria ",
    code: "RAS_CONTRIBUYENTE_VEHICULO",
    url: "/ras/vehiculo",
  },
  {
    //136 -
    title: "Editar",
    code: "PUBLICIDAD_EDIT",
    url: "/actividades-economicas/publicidad/editar",
  },
  {
    //137 -
    title: "Publicidad Revision",
    code: "PUBLICIDAD_EDIT",
    url: "/actividades-economicas/publicidad/revision",
  },
 
  {
    //138 -
    title: "Publicidad Observados",
    code: "PUBLICIDAD_OBSERVADOS_CONTRI",
    url: "/actividades-economicas/publicidad/observados",
  },
  
  {
    //139
    title: "Licencia Funcionamiento",
    code: "LICENCIA_FUNCIONAMIENTO",
    url: "/actividades-economicas/funcionamiento",
  },
 
  
  {
    //140
    title: "Requisitos",
    code: "LICENCIA_FUNCIONAMIENTO_REQUSITOS",
    url: "/actividades-economicas/funcionamiento/requisitos",
  },
 
  
];

export default linksSimat;