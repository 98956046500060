// import React from "react";
const ChangeFormatErrorMessageParsley = (typeInput, nameInput, targetDivId) => {


  if (typeInput === 'input' || typeInput === 'select') {
    let input = window.jQuery(`${typeInput}[name='${nameInput}']`);
    let ulErrorDiv = input.closest('div').find('ul').first();    
    ulErrorDiv.remove();

    if (targetDivId === null || targetDivId === "") {
      input.closest('div').after(ulErrorDiv);
    } else {
      window.jQuery(`#${targetDivId}`).append(ulErrorDiv);
    }
  }
};

export default ChangeFormatErrorMessageParsley;
