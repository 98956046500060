import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../style/input-file-drag.css";
import AuthService from "../Usuario/AuthService";
import { useHistory } from "react-router-dom";
import Links from "../../data/link";
import PasosNavigationAutorizacion from "../utils/PasosNavigationAutorizacion";

const FormRestriction = (props) => {
  const { dataInmueble, onSubmitForm, fetch, dataSiteRestrictionDb } = props;
  const idForm = "formDataRestriction";
  let rowsEventSelected = [];
  const auth = new AuthService();
  const history = useHistory();

  const [message, setMessage] = useState(undefined);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [emplazamientos, setEmplazamientos] = useState([]);
  const [restricciones, setRestricciones] = useState([]);

  if (!auth.loggedIn()) history.replace(Links[4].url);

  useEffect(() => {
    debugger;
    loadData();
    window.scrollTo(0, 0);
  }, []);

  const loadData = async () => {
    await loadEmplazamientos();
    await loadRestricciones();
    if (dataSiteRestrictionDb) {
      setTimeout(() => {
        updateSelectedEmplazamientosRestriction(dataSiteRestrictionDb);
      }, 600);
    }
  };

  const loadEmplazamientos = async () => {
    const respEmplazamientos = await fetch.axiosAsyncGet(
      `api/saat/restriccion/EMPLAZAMIENTO`
    );
    if (respEmplazamientos && respEmplazamientos.status) {
      debugger;
      const emplazamientoRestriccion =
        respEmplazamientos.data.SaatEmplazamientoRestriccion;

      let cont = 0;
      let fatherName = "";
      let showTitle = false;
      const nodeEmplazamientos = emplazamientoRestriccion.map(
        ({ id, id_father, name_dependent, name_father }, key) => {
          showTitle = !(fatherName === name_father);
          if (fatherName !== name_father) fatherName = name_father;
          return (
            <div key={id}>
              {showTitle ? (
                <div className="row mb-3 mt-4">
                  <div className="col-12 col-md-12">
                    <strong>{name_father} </strong>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div
                className={
                  "row pointer row-table-custom pt-2 pb-2 ms-2 me-2 " +
                  (key % 2 === 0 ? " bg-picton-blue " : " background-white ") +
                  " justify-content-center align-items-center emplazamiento-" +
                  id +
                  " "
                }
                key={cont++}
                onClick={(e) =>
                  handleClickRow(e, {
                    selected: true,
                    id,
                    id_father,
                    name_dependent,
                    name_father,
                  })
                }
              >
                <div className="col-11 col-md-11">
                  <span> {name_dependent}</span>
                </div>
                <div className="col-1 col-md-1 text-center">
                  {/*<i
                    className="far fa-square fa-2x fa-icon-image"
                    aria-hidden="true"
              ></i>*/}
                  <i
                    className="fas fa-toggle-off  fa-3x fa-icon-image"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          );
        }
      );

      setEmplazamientos(nodeEmplazamientos);
    }
  };

  const loadRestricciones = async () => {
    const respRestricciones = await fetch.axiosAsyncGet(
      `api/saat/restriccion/RESTRICCIONES`
    );
    if (respRestricciones && respRestricciones.status) {
      debugger;
      const emplazamientoRestriccion =
        respRestricciones.data.SaatEmplazamientoRestriccion;

      const nodeRestriccion = emplazamientoRestriccion.map(
        ({ id, id_father, name_dependent, name_father }, index) => {
          return (
            <div
              className={
                "row pointer row-table-custom pt-2 pb-2 ms-2 me-2 " +
                (index % 2 === 0 ? " bg-picton-blue " : " background-white ") +
                " justify-content-center align-items-center emplazamiento-" +
                id +
                " "
              }
              key={index}
              onClick={(e) =>
                handleClickRow(e, {
                  selected: true,
                  id,
                  id_father,
                  name_dependent,
                  name_father,
                })
              }
            >
              <div className="col-11 col-md-11">
                <span> {name_father}</span>
              </div>
              <div className="col-1 col-md-1 text-center">
                <i
                  className="fas fa-toggle-off  fa-3x fa-icon-image"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          );
        }
      );
      setRestricciones(nodeRestriccion);
    }
  };

  const updateSelectedEmplazamientosRestriction = (dataSiteRestriction) => {
    debugger;
    //console.log("verificando los registros ................. ");
    const emplazamientosHtml =
      document.getElementsByClassName("row-table-custom");
    Array.from(emplazamientosHtml).forEach((node) => {
      const idEmplamientoTemp =
        node.classList[node.classList.length - 1].split("-")[1];
      const dataEmplazamientoArray = dataSiteRestriction.emplazamiento.filter(
        (data) => parseInt(data.id, 10) === parseInt(idEmplamientoTemp, 10)
      );
      if (dataEmplazamientoArray && dataEmplazamientoArray.length > 0) {
        const dataEmplTemp = dataEmplazamientoArray[0];
        if (parseInt(idEmplamientoTemp, 10) === parseInt(dataEmplTemp.id, 10)) {
          dispatchEventRow(node, dataEmplTemp);
        }
      }

      const dataRestrictionArray = dataSiteRestriction.restriction.filter(
        (data) => parseInt(data.id, 10) === parseInt(idEmplamientoTemp, 10)
      );

      if (dataRestrictionArray && dataRestrictionArray.length > 0) {
        const dataRestTemp = dataRestrictionArray[0];
        if (parseInt( idEmplamientoTemp, 10) === parseInt(dataRestTemp.id,10)) {
          dispatchEventRow(node, dataRestTemp);
        }
      }
    });
  };

  const dispatchEventRow = (nodeHtml, data) => {
    let event = new Event("click", { bubbles: false, cancelable: false });
    Object.defineProperty(event, "target", {
      writable: false,
      value: nodeHtml,
    });
    nodeHtml.dispatchEvent(event);

    handleClickRow(event, {
      selected: true,
      id: data.id,
      father: data.father,
      name_dependent: data.name_dependent,
      name_father: data.name_father,
    });
  };

  const handleClickRow = (event, pago) => {
    event.preventDefault();
    seleccionarRow(event, pago);
  };

  const seleccionarRow = (event, row) => {
    let nodeI = event.target.closest(".row").getElementsByTagName("i")[0];
    if (nodeI.classList.contains("fa-toggle-off")) {
      nodeI.classList.remove("fa-toggle-off");
      nodeI.classList.add("fa-toggle-on");
      rowsEventSelected = [...rowsEventSelected, row];
    } else {
      nodeI.classList.add("fa-toggle-off");
      nodeI.classList.remove("fa-toggle-on");
      rowsEventSelected = rowsEventSelected.filter(
        (item) => row.id !== item.id
      );
    }
    setRowsSelected(rowsEventSelected);
  };

  const submitFormToRestriction = (event) => {
    debugger;
    event.preventDefault();

    const total = restricciones.length + emplazamientos.length;
    if (rowsSelected.length > 0) {
      //&& total === rowsSelected.length
      if (total >= emplazamientos.length) {
        setMessage("");
        onSubmitForm(event, rowsSelected);
      }
    } else {
      setMessage("Debe de seleccionar Emplazamientos y/o Restricciones!!");
      fetch.toast.warn(
        "Debe de seleccionar Emplazamientos y/o Restricciones!!",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <PasosNavigationAutorizacion
        nro_inmueble={dataInmueble.numeroInmueble}
        paso1_active={true}
        paso2_active={true}
        paso3_active={true}
        paso4_active={true}
        paso5_active={false}
      />

      <form
        action=""
        className="contact__form needs-validation"
        name={idForm}
        id={idForm}
        method="post"
        noValidate
        onSubmit={submitFormToRestriction}
        style={{ width: "100%" }}
      >
        <div className="card widget widget-simple">
          <div className="card-body ">
            <div className="row ">
              <div className="col-12 col-md-8 widget-header mb-3">
                <h4 className="text-maroon-flush">
                  <i className="fas fa-bookmark me-2"></i>{" "}
                  Emplazamientos/Restricciones
                </h4>
              </div>
            </div>
            {message ? (
              <div className="row">
                <div className="col-12 text-start">
                  <div
                    className="alert alert-danger text-uppercase"
                    role="alert"
                  >
                    {message}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="mt-3 mb-3">
              <h3 className="text-center">EMPLAZAMIENTOS</h3>
              <div className="col-12 line-horizontal bg-green-haze mb-1"></div>
              <div className="col-12 line-horizontal bg-minsk"></div>
            </div>

            {emplazamientos}
            <div className="mt-4 mb-3">
              <h3 className="text-center ">RESTRICCIONES</h3>
              <div className="col-12 line-horizontal bg-green-haze mb-1"></div>
              <div className="col-12 line-horizontal bg-minsk"></div>
            </div>
            {restricciones}
          </div>
        </div>

        <div className="row">
          <div className={`hol col-12 text-end col-md-9 col-lg-9`}></div>
          <div className="col-12 col-md-3 col-lg-3 text-end">
            <div className=" search-btn button mb-3">
              <input
                type="submit"
                className="btn btn-full-screen"
                name="Siguiente"
                value="Siguiente"
              />
            </div>
          </div>
        </div>
      </form>

      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  );
};

export default FormRestriction;
