import React, { useEffect, useState } from "react";
import Links from "../../data/link";
import { useParams } from "react-router-dom"; //useLocation
import { Link, useHistory } from "react-router-dom";
import TitlePage from "../utils/TitlePage";
import Texto from "../../data/es";
import Fetch from "../utils/Fetch";
import DataTable from "react-data-table-component";
import CustomLoader from "../utils/SpinnerDataTable";
import AuthService from "../Usuario/AuthService";
import { toast, ToastContainer } from "react-toastify"; // ToastContainer
import "react-toastify/dist/ReactToastify.css";
import "./css/stylefileinput.css";

import { MultiSelect } from "react-multi-select-component";

const optionsMultiSelect = {
	allItemsAreSelected: "Todas las gestiones seleccionadas.",
	clearSearch: "Eliminar",
	clearSelected: "Eliminar",
	noOptions: "No options",
	search: "Buscar",
	selectAll: "Seleccionar todos",
	selectAllFiltered: "Seleccionar todos (Filtrado)",
	selectSomeItems: "Seleccionar gestión(es)",
	create: "Crear",
};

let breadcrumbs = [
	{
		title: Links[0].title,
		url: Links[0].url,
	},
	{
		title: Links[117].title,
		url: Links[117].url,
	},
	{
		title: Links[118].title,
		url: Links[118].url,
	},
];

const TributarioLista = (props) => {
	const fetch = new Fetch();
	fetch.setToast(toast);

	var date = new Date();
	var todayDate =
		date.getFullYear() +
		"-" +
		("00" + (date.getMonth() + 1)).slice(-2) +
		"-" +
		("00" + date.getDate()).slice(-2) +
		" " +
		("00" + date.getHours()).slice(-2) +
		":" +
		("00" + date.getMinutes()).slice(-2) +
		":" +
		("00" + date.getSeconds()).slice(-2);

	const idForm = "formDataFile";
	const idFormImportFile = "modalFormCreate";

	const [selected, setSelected] = useState([]);

	const [loading, setLoading] = useState(false);
	const [loadingForm, setLoadingForm] = useState(false);
	// documents
	const [data, setData] = useState();
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	// items
	const [totalRowsItem, setTotalRowsItem] = useState(0);
	const [perPageItem, setPerPageItem] = useState(10);
	const [currentPageItem, setCurrentPageItem] = useState(1);
	// modales
	const [showModalItemFile, setShowModalItemFile] = useState(false);
	const [showModalForm, setShowModalForm] = useState(false);
	const [stateProcess2Publi, setStateProcess2Publi] = useState(false);
	const [modalSearch, setModalSearch] = useState(undefined);
	// set
	const [dataInfoFileUpload, setDataInfoFileUpload] = useState([]);
	const [itemsFileData, setItemsFileData] = useState([]);
	const [dataDepartamento, setDataDepartamento] = useState([]);
	const [dataPublicacion, setDataPublicacion] = useState([]);
	const [dataGestiones, setDataGestiones] = useState([]);
	const [dataInfo, setDataInfo] = useState(null);
	const [columNameItem, setColumNameItem] = useState();
	const [fechaVencimientoFile, setFechaVencimientoFile] = useState();
	const [isDisabled, setDisabled] = useState(false);
	// parametros pasados
	const { objeto, tipo } = useParams();
	const titleFormatoMixta = objeto + "-" + tipo;
	const titlePage = objeto.replace("_", " ") + " - " + tipo.toUpperCase();
	const codeTypeNotification = tipo.toLowerCase();
	// code_document en uso - code_document
	const [codeDocumentUse, setCodeDocumentUse] = useState();
	// tipo de proceso masiva
	const typeProcessCode = "MASIVAS";
	const history = useHistory();

	const [dataProcess2da, setDataProcess2da] = useState({
		procesado: 0,
		total_registro: 0,
	});

	let code_objeto =
		dataInfoFileUpload.obj_code === undefined
			? ""
			: dataInfoFileUpload.obj_code.toLowerCase();

	let placeholderItems = "Buscar por RD o " + columNameItem;

	const auth = new AuthService();

	if (!auth.loggedIn()) history.replace(Links[4].url);
	// ============================================================= //
	useEffect(() => {
		if (!auth.loggedIn()) {
			window.location.href = Links[5].url;
		}

		loadDataInfo();
		getDataListaTributaria();

		window.scrollTo(0, 0);
	}, []);

	// ============================================================= //

	const formatDateDMY = (fecha) => {
		return window.moment(fecha).format("DD/MM/YYYY");
	};

	const formatDateDMYHIS = (fechahora) => {
		return window.moment(fechahora).format("DD/MM/YYYY H:mm");
	};

	// text search delete
	const handleButtonClickSearchDelete = () => {
		let inputSearchFile = document.getElementById("search_files");
		if (typeof inputSearchFile !== "undefined" && inputSearchFile !== null) {
			inputSearchFile.value = "";
		}
	};

	// search main documents
	const handleButtonClickSearch = async () => {
		debugger;
		// element input
		let inputSearchFile = document.getElementById("search_files");
		let totalSearchText = inputSearchFile.value.length;
		let textSearchUse = totalSearchText == 0 ? null : inputSearchFile.value;

		setLoading(true);
		const response = await fetch.axiosAsyncGet(
			`api/nt-tributaria/notificacion-list/${objeto}/${codeTypeNotification}/${currentPage}/${perPage}/${textSearchUse}`
		);

		if (response && response.status) {
			setData(response.data);
			setTotalRows(response.total);
			setLoading(false);
			toast.success(response.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	const handleButtonClickSearchItems = async () => {
		debugger;
		setLoading(true);
		let searchValueInput = document.getElementById("search_items").value;
		let totalSearchText = searchValueInput.length;
		let textSearch =
			totalSearchText == 0 ? null : searchValueInput.replace("/", "_");

		const responseItem = await fetch.axiosAsyncGet(
			`api/nt-tributaria/search-items/${codeDocumentUse}/${currentPageItem}/${perPageItem}/${textSearch}`
		);

		if (responseItem && responseItem.status) {
			setItemsFileData(responseItem.data);
			setTotalRowsItem(responseItem.total);
			setLoading(false);

			toast.success(responseItem.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		} else {
			setItemsFileData([]);
		}
	};

	// ============================================================= //
	const IntentExpandedComponent = ({ data }) => {
		return (
			<div className=" pt-2 pb-2 text-secondary" style={{ fontSize: "0.79em" }}>
				<div className="row" style={{ margin: "0 0 0 25px" }}>
					<div className="col-lg-12 col-md-12 col-xs-12">{data.name_doc}</div>

					<div className="col-lg-6 col-md-6 col-xs-12">
						<b>Gestiones masivas: </b>
						{data.gestiones_json}
						<br />
						<b>Total registrados:</b> {data.total_registros} de {data.doc_total}
					</div>

					<div className="col-lg-6 col-md-6 col-xs-12">
						<b>Publicado: </b>
						{data.publicado} &nbsp; <b>Fecha pub.: </b>
						{data.fecha_publicado != null
							? formatDateDMY(data.fecha_publicado)
							: ""}
						{data.hora_publicado != null ? " " + data.hora_publicado : ""}
						{/* {data.fecha_vencimiento} */}
						<br />
						<b>Fecha registro: </b>
						{formatDateDMYHIS(data.created_at)}
					</div>
				</div>
			</div>
		);
	};

	const getDataListaTributaria = async () => {
		setLoading(true);
		debugger;
		const response = await fetch.axiosAsyncGet(
			`api/nt-tributaria/notificacion-list/${objeto}/${codeTypeNotification}/${currentPage}/${perPage}`
		);

		if (response && response.status) {
			setData(response.data);
			setTotalRows(response.total);
			setLoading(false);
			toast.success(response.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	const handlePerRowsChange = async (perPage, page) => {
		setLoading(true);
		const response = await fetch.axiosAsyncGet(
			`api/nt-tributaria/notificacion-list/${objeto}/${codeTypeNotification}/${page}/${perPage}`
		);
		const json = await response.data;
		setLoading(false);
		setData(json);
		setPerPage(perPage);
		setCurrentPage(page);
	};

	const handlePageChange = async (page) => {
		setLoading(true);
		const response = await fetch.axiosAsyncGet(
			`api/nt-tributaria/notificacion-list/${objeto}/${codeTypeNotification}/${page}/${perPage}`
		);
		const json = await response.data;
		setLoading(false);
		setData(json);
		setCurrentPage(page);
	};

	/// item
	const handlePerRowsChangeItem = async (perPage, page) => {
		setLoading(true);
		const response = await fetch.axiosAsyncGet(
			`api/nt-tributaria/search-items/${codeDocumentUse}/${page}/${perPage}`
		);
		const json = await response.data;
		setLoading(false);
		setItemsFileData(json);
		setPerPageItem(perPage);
		setCurrentPageItem(page);
	};

	const handlePageChangeItem = async (page) => {
		setLoading(true);
		const response = await fetch.axiosAsyncGet(
			`api/nt-tributaria/search-items/${codeDocumentUse}/${page}/${perPage}`
		);
		const json = await response.data;
		setLoading(false);
		setItemsFileData(json);
		setCurrentPageItem(page);
	};

	// ============================================================= //
	// modal
	// ============================================================= //
	const openModalItemsFile = (row) => {
		debugger;
		let elementInput = document.getElementById("search_items");
		if (typeof elementInput !== "undefined" && elementInput !== null) {
			elementInput.value = "";
		}

		setShowModalItemFile(!showModalItemFile);
		setShowModalForm(false);
		dataItemsFile(row.code_document);
		setDataInfoFileUpload(row);
		setCodeDocumentUse(row.code_document);
		setFechaVencimientoFile(row.fecha_vencimiento);

		setTimeout(() => {
			const modalSearchDom = document.getElementById("modalItemsFile");
			const modalSearch = new window.bootstrap.Modal(modalSearchDom, {
				keyboard: true,
				backdrop: "static",
				focus: true,
			});

			modalSearch.show();
			setModalSearch(modalSearch);
		}, 500);
	};

	const openModalFormulario = () => {
		debugger;
		loadDataGestiones();
		loadDataPublicacion();
		loadDataDepartamento();
		setShowModalItemFile(false);
		setShowModalForm(true);
		setSelected([]);

		setTimeout(() => {
			const modalSearchDomFile = document.getElementById(idFormImportFile);
			const modalSearchImport = new window.bootstrap.Modal(modalSearchDomFile, {
				keyboard: true,
				backdrop: "static",
				focus: true,
			});
			modalSearchImport.show();
			setModalSearch(modalSearchImport);
			const formPost = document.getElementById(idForm);
			formPost.reset();
		}, 500);
	};

	// ======================================= //
	// modals end
	// ======================================= //
	const dataItemsFile = (code) => {
		setColumNameItem();
		const itemsFileData = fetch.fetchGet(
			`api/nt-tributaria/search-items/${code}/${currentPageItem}/${perPageItem}`
		);

		itemsFileData.then((res) => {
			if (res && res.status && res.data) {
				setItemsFileData(res.data);
				setTotalRowsItem(res.total);
				setColumNameItem(res.rowTributary);
			} else {
				setItemsFileData([]);
			}
		});
	};

	const loadDataInfo = async () => {
		const response = await fetch.axiosAsyncGet(
			`api/nt-tributario/objeto-tributario/info/${objeto}/${tipo}`
		);
		if (response && response.status) {
			setDataInfo([response.ObjetoTributario]);
		}
	};

	const loadDataGestiones = async () => {
		// gestiones
		const responseGestiones = await fetch.axiosAsyncGet(
			`api/nt-tributaria/gestion`
		);
		const dataGestion = await responseGestiones.Gestion;
		if (dataGestion && responseGestiones.status) {
			setDataGestiones(dataGestion);
		}
	};

	const loadDataDepartamento = async () => {
		// unidades o departamentos
		const responseDpto = await fetch.axiosAsyncGet(
			`api/nt-tributaria/departamento-list`
		);
		if (responseDpto && responseDpto.status) {
			const dataDepartament = responseDpto.data.Departament;
			setDataDepartamento([dataDepartament]);
		}
	};

	const loadDataPublicacion = async () => {
		// nro publicacion
		const responsePublicacion = await fetch.axiosAsyncGet(
			`api/nt-tributaria/publicacion-list`
		);
		const dataPublicacion = await responsePublicacion.data.Publicacion;
		if (dataPublicacion && responsePublicacion.status) {
			let dataResponseResult = dataPublicacion.filter(
				(row) => row.code == "1RA_PUBLICACION"
			);
			setDataPublicacion(dataResponseResult);
		}
	};

	// handle form submit
	const handleFormSubmitFile = async (evt) => {
		// setLoading(true);
		debugger;
		evt.preventDefault();
		setDisabled(true);
		setLoadingForm(true);

		if (
			window
				.jQuery("#" + idForm)
				.parsley()
				.isValid()
		) {
			const thisForm = document.getElementById(`${idForm}`);
			var formData = new FormData(thisForm);
			formData.append("objetoTributario[code]", objeto.toUpperCase());
			formData.append("typeNotification[code]", typeProcessCode);
			formData.append("document[type]", tipo);
			if (selected.length > 0) {
				var listagestiones = selected.map(function (gestion) {
					return gestion.value;
				});
				formData.append("gestion[year]", JSON.stringify(listagestiones));
			}
			// typeNotification[code]
			const saveObjetoTributario = await fetch.fetchPost(
				formData,
				"api/nt-tributaria/document-import",
				evt.target
			);

			if (saveObjetoTributario && saveObjetoTributario.status) {
				setShowModalItemFile(false);
				setShowModalForm(false);
				setLoadingForm(false);
				modalSearch.hide();
				toast.success(saveObjetoTributario.message, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});

				getDataListaTributaria();
			}
		}
		setLoadingForm(false);
		setDisabled(false);
	};

	// fecha
	const esValidoFechaLimite = () => {
		let result = false;

		if (fechaVencimientoFile != null) {
			let datelimite = new Date(fechaVencimientoFile);
			let dateNow = new Date(todayDate);
			result = dateNow <= datelimite;
		}
		return result;
	};

	// ============================================================= //
	//  items
	// ============================================================= //
	const onClickDeleteItemDoc = (row) => {
		debugger;
		window.createBootbox(
			"¿Está seguro de continuar con la deshabilitación del registro seleccionado?.",
			function (result) {
				if (result) {
					const form = new FormData();
					form.append("NotTribuPubDoc[id]", row.idnottribupubdoc);
					fetch
						.fetchPost(form, "api/nt-tributaria/notificacion-delete")
						.then((dataJson) => {
							if (dataJson && dataJson.status) {
								dataItemsFile(row.code_document);
								toast.success(dataJson.message, {
									position: "top-right",
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});
							}
						});
				}
			}
		);
	};

	const deudaBs = (code_publicacion, total_bs, total_2da) => {
		let resultado = "-";
		if (
			code_publicacion == "2DA_PUBLICACION" &&
			total_2da >= 0 &&
			total_2da != null
		) {
			resultado = total_2da;
		} else if (total_bs > 0) {
			resultado = total_bs;
		}
		return resultado >= 0 ? parseInt(resultado) : resultado;
	};

	// ============================================================= //
	// columnas para docs_file registros
	// ============================================================= //
	const columns = [
		{
			name: Texto.nro_publicacion,
			selector: "publi_nombre",
			sortable: true,
			//hide: "md",
			maxWidth: "140px",
			cell: (row) => (
				<div>
					{row.publi_nombre.substring(0, row.publi_nombre.indexOf(" "))}
				</div>
			),
		},
		{
			name: Texto.gestion,
			selector: "gestion_publicacion",
			sortable: true,
			//hide: "md",
			maxWidth: "100px",
		},

		{
			name: Texto.archivo_adjunto,
			sortable: true,
			grow: 1,
			cell: (row) => (
				<div>
					{row.name_doc.length > 30
						? row.name_doc.substring(0, 30) + "..."
						: row.name_doc}
				</div>
			),
		},

		{
			name: Texto.obj_tributario,
			selector: "nombre_tributario",
			sortable: false,
			hide: "md",
			cell: (row) => <div>{row.nombre_tributario}</div>,
		},

		{
			name: "Publicado",
			selector: "publicado",
			sortable: true,
			hide: "md",
			maxWidth: "90px",
		},
		{
			name: "Fecha reg.",
			selector: "created_at",
			sortable: true,
			hide: "md",
			maxWidth: "120px",
			cell: (row) => <span>{formatDateDMY(row.created_at)}</span>,
		},
		{
			name: "",
			sortable: true,
			cell: (row) => (
				<div className="text-sm-start text-md-end" style={{ width: "100px" }}>
					<span
						data-bs-toggle="dropdown"
						aria-expanded="false"
						className="pe-2 ps-2"
					>
						<i className="fas fa-ellipsis-v text-primary "></i>
					</span>
					<ul className="dropdown-menu dropdown-menu-right">
						<li>
							{
								<Link
									to="#"
									className="dropdown-item"
									onClick={() => openModalItemsFile(row)}
									title={`Ver ${row.name_doc}`}
								>
									Ver
								</Link>
							}
						</li>

						{row.publicado === "NO" ? (
							<li>
								{
									<Link
										to="#"
										className="dropdown-item"
										title={`Eliminar : ${row.name_doc}`}
										onClick={() => onClickDocumentDelete(row)}
									>
										Eliminar
									</Link>
								}
							</li>
						) : (
							""
						)}

						{row.publicado === "NO" ? (
							<li>
								{
									<Link
										to="#"
										className="dropdown-item"
										title={`Publicar : ${row.name_doc}`}
										onClick={() => onClickDocumentPublish(row)}
									>
										Publicar
									</Link>
								}
							</li>
						) : (
							""
						)}

						{btn2DAPublicacion(row)}
					</ul>
				</div>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	];

	const columnsItemsDocumento = [
		{
			name: "Nº RD",
			selector: "numero_rd",
			sortable: true,
			cell: (row) => <span>{row.numero_rd}</span>,
		},
		{
			name:
				code_objeto === "inmueble"
					? Texto.inmueble_nt
					: code_objeto === "vehiculo"
					? Texto.vehiculo_nt
					: code_objeto === "patente_municipal"
					? Texto.actividad_economica_nt
					: Texto.publicidad_exterior_nt,
			//	selector: "numero_rd",
			sortable: false,
			cell: (row) => (
				<span className="text-wrap">
					{row.placa_poliza != null
						? row.placa_poliza
						: row.inmueble != null
						? row.inmueble
						: row.licencia != null
						? row.licencia
						: row.actividad}
				</span>
			),
		},
		{
			name: "Nombre / Razón Social",
			sortable: false,
			cell: (row) => <span className="text-wrap">{row.razon_social}</span>,
			hide: "md",
		},
		{
			name: "Gestión",
			selector: "gestion_year",
			sortable: true,
			maxWidth: "100px",
			cell: (row) => (
				<span>
					{row.gestion_year == null
						? formatDateDMY(row.fecha_minuta)
						: row.gestion_year}
				</span>
			),
			hide: "md",
		},
		{
			name: "Deuda Bs.",
			selector: "total_bs",
			sortable: true,
			cell: (row) => (
				<span>
					{deudaBs(row.code_publicacion, row.total_bs, row.total_bs_2da)}
				</span>
			),
		},
		{
			//name: "",
			sortable: false,
			maxWidth: "100px",
			cell: (row) => (
				<span className="bg-success">
					{(fechaVencimientoFile === null || esValidoFechaLimite()) &&
					row.code_publicacion == "1RA_PUBLICACION" ? (
						<Link
							to="#"
							className="btn btn-sm btn-outline-danger"
							onClick={() => onClickDeleteItemDoc(row)}
							title={`Deshabilitar ` + row.numero_rd}
						>
							<i className="far fa-trash-alt" aria-hidden="true"></i>
						</Link>
					) : (
						""
					)}
				</span>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	];
	// ============================================================= //
	// info de los datos de la liquidacion tributaria
	// ============================================================= //
	var dataTypeTributario =
		dataInfo != null ? JSON.parse(dataInfo[0].data_tributary) : null;
	var dataTributaryDescription =
		dataTypeTributario != null ? dataTypeTributario[0].description : null;

	// ============================================================= //
	//  info publish
	// ============================================================= //

	const btn2DAPublicacion = (row) => {
		let gestionPubliValido = row.gestion_publicacion >= 2023;
		let esPrimeraPubli = row.publi_code == "1RA_PUBLICACION";
		let publicado = row.publicado === "SI";
		let totalRegistro = parseInt(row.total_registros);
		let totalProcesado2do = parseInt(row.total_procesado_2do);

		if (
			gestionPubliValido &&
			esPrimeraPubli &&
			publicado &&
			totalProcesado2do < totalRegistro
		) {
			return (
				<Link
					to="#"
					className={"dropdown-item"}
					title={`Generar 2da Publicación`}
					onClick={(event) => onClickPublish2(event, row)}
				>
					Generar 2da Publicación
				</Link>
			);
		} else {
			return "";
		}
	};

	const loadInfoProcessPublish2 = async (code_document) => {
		debugger;
		let codeFile = code_document;
		let totalProcesado = null;
		let totalRegistro = null;

		const dataResponse = await fetch.fetchGet(
			`api/nt-tributaria/document-publish-2do-info/${codeFile}`
		);

		if (dataResponse && dataResponse.status) {
			totalProcesado = dataResponse.data.total_procesado;
			totalRegistro = dataResponse.data.total_primera;
			dataProcess2da.procesado = totalProcesado;
			dataProcess2da.total_registro = totalRegistro;
			setDataProcess2da({ ...dataProcess2da });
			// const [dataProcess2da, setDataProcess2da] = useState({'procesado':0, 'total_registro':0});
			document.getElementById("info2doPubli").innerText =
				"Procesando " + totalProcesado + " de " + totalRegistro;
		}
	};

	const onClickPublish2 = (event, row) => {
		debugger;
		let idIntervalInfo;
		try {
			window.createBootbox(
				"Está seguro de generar la 2da Publicación del documento '" +
					row.name_doc +
					" (" +
					row.publi_nombre +
					")'.",
				function (result) {
					if (result) {
						idIntervalInfo = setInterval(async () => {
							await loadInfoProcessPublish2(row.code_document);
							if (
								dataProcess2da.procesado > 0 &&
								dataProcess2da.procesado === dataProcess2da.total_registro
							) {
								clearInterval(idIntervalInfo);
							}
						}, 10000);

						setStateProcess2Publi(true);
						getProcessPublish2(row.code_document, idIntervalInfo);
					}
				}
			);
		} catch (error) {
			if (idIntervalInfo) {
				clearInterval(idIntervalInfo);
			}
		}
	};

	const getProcessPublish2 = async (code_document, idIntervalInfo) => {
		const formData = new FormData();
		formData.append("document[code]", code_document);
		const dataResponse = await fetch.fetchPost(
			formData,
			"api/nt-tributaria/document-publish-2do-fase"
		);
		if (dataResponse && dataResponse.status) {
			toast.success(dataResponse.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});

			getDataListaTributaria();
		}
		if (idIntervalInfo) {
			clearInterval(idIntervalInfo);
		}

		setStateProcess2Publi(false);
	};

	const onClickDocumentPublish = (row) => {
		debugger;

		window.createBootbox(
			"Está seguro de publicar el documento '" + row.name_doc + "'.",
			function (result) {
				if (result) {
					const form = new FormData();
					form.append("document[code]", row.code_document);
					fetch
						.fetchPost(form, "api/nt-tributaria/document-publish")
						.then((dataJson) => {
							if (dataJson && dataJson.status) {
								toast.success(dataJson.message, {
									position: "top-right",
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});

								getDataListaTributaria();
							}
						});
				}
			}
		);
	};

	// eliminar documento sin publicar
	const onClickDocumentDelete = (row) => {
		debugger;

		window.createBootbox(
			"Está seguro de eliminar el documento '" + row.name_doc + "'.",
			function (result) {
				if (result) {
					const dataResponse = fetch.fetchGet(
						`api/nt-tributaria/delete-file/${row.code_document}/${row.publi_code}`
					);
					dataResponse
						.then((res) => {
							if (res && res.status) {
								toast.success(res.message, {
									position: "top-right",
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});

								getDataListaTributaria();
							} else {
								toast.error(res.message, {
									position: "top-right",
									autoClose: 5000,
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
								});
							}
						})
						.catch((error) => {
							console.error("Error:", error);
						});
				}
			}
		);
	};

	const onClickUploadFileInput = (event) => {
		const inputFile = event.target;
		if (inputFile) {
			const divContainerFather = inputFile.closest(".row");

			let iconFile = inputFile.parentElement.parentElement.querySelector("i");
			if (iconFile) inputFile.parentElement.parentElement.removeChild(iconFile);

			if (inputFile.files.length > 0) {
				const format = inputFile.files[0].type.split("/");
				if (/^(|csv)$/i.test(format[1])) {
					const icono = document.createElement("i");
					if (format[1] === "csv") {
						icono.className = "fas fa-file-pdf icon";
					}

					inputFile.parentElement.parentElement.appendChild(icono);

					const arrayNameFile = inputFile.files[0].name.split("/");
					const nameFile = arrayNameFile[arrayNameFile.length - 1];

					divContainerFather.getElementsByClassName("nameFile")[0].innerHTML =
						(nameFile.length >= 30
							? "..." +
							  nameFile.substring(nameFile.length - 30, nameFile.length)
							: nameFile) +
						" - " +
						window.niceBytes(inputFile.files[0].size);

					window.parsley.addValidator("maxFileSize", {
						requirementType: "integer",
						validateString: function (value, maxSize, parsleyInstance) {
							const files = parsleyInstance.$element[0].files;
							return files.length !== 1 || files[0].size <= maxSize * 1024;
						},
						messages: {
							es: "El archivo no debe superar los %s Kb",
						},
					});
				} else {
					toast.warning("Formato no valido", {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
				}
			}
		}
	};

	// ==================================================== //
	return (
		<div className="items-grid section mt-5">
			<div className="container mt-2">
				<TitlePage
					titlePage={titlePage}
					breadcrumbs={breadcrumbs}
					position={"left"}
					leftfull={false}
				/>

				<div className="">
					<div className="row">
						<div className="col-lg-12 col-xs-12 text-justify ">
							<h6 className="text-fountain-blue mb-3 mt-3">
								{dataTributaryDescription}
							</h6>
						</div>

						<div className="col-lg-6 col-xs-12 ">
							<div className="input-group">
								<input
									type="text"
									name="search_files"
									id="search_files"
									className="form-control form-control-sm"
									placeholder="Ingrese el nombre del archivo adjunto"
									aria-label="Buscar"
									autoComplete="off"
								/>
								<div className="input-group-prepend">
									<button
										className="btn btn-sm btn-outline-danger"
										id="btn_delete_search"
										title="Borrar"
										onClick={handleButtonClickSearchDelete}
									>
										<i className="fas fa-times"></i>
									</button>

									<button
										className="btn btn-sm btn-outline-primary"
										title="Buscar ..."
										onClick={handleButtonClickSearch}
									>
										<i className="fas fa-search"></i>
									</button>
								</div>
							</div>
						</div>

						<div className="col-lg-6 col-xs-12" style={{ textAlign: "right" }}>
							<button
								className="btn btn-outline-primary btn-sm mb-2 mt-2"
								onClick={() => openModalFormulario(tipo)}
							>
								<i className="fa fa-plus"></i> Nuevo (Procesar Archivo)
							</button>

							<a
								download={true}
								href={
									"/static/mixtas/" + titleFormatoMixta.toUpperCase() + ".csv"
								}
								className="btn btn-outline-dark btn-sm"
								style={{ marginLeft: "5px" }}
								title={titleFormatoMixta.toUpperCase()}
							>
								<span class="fa fa-download"></span> Formato CSV
							</a>
						</div>
					</div>

					<div
						className={
							stateProcess2Publi
								? "row mt-3 text-center"
								: "row mt-3 d-none text-center"
						}
					>
						<div className="col-12 justify-content-center align-content-center align-items-center">
							<img
								className="rounded mx-auto d-block"
								alt="pulse-loading"
								src={"/static/img/pulse_200.gif"}
								width={40}
							/>
							<small className="text-primary">
								Procesando los datos para generar 2da publicación.
							</small>
							<div id="info2doPubli"></div>
						</div>
					</div>

					<DataTable
						columns={columns}
						data={data}
						progressPending={loading}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						highlightOnHover
						noDataComponent={Texto.there_are_no_records_to_display}
						progressComponent={<CustomLoader />}
						expandableRowsComponent={<IntentExpandedComponent />}
						expandableRows
						noHeader={true}
					/>
				</div>

				{showModalItemFile ? (
					<div
						className="modal fade "
						id={"modalItemsFile"}
						tabIndex="-1"
						aria-labelledby="modalItemsFileTitle"
						aria-hidden="true"
						data-backdrop="static"
						data-keyboard="false"
						style={{ overflowY: "auto" }}
					>
						<div className="modal-dialog  modal-dialog-centered modal-lg">
							<div className="modal-content">
								<div className="modal-header">
									<h6
										className="modal-title text-butterfly-bush text-wrap"
										id="modalItemsFileTitle"
									>
										{dataInfoFileUpload.obj_nombre} -{" "}
										{dataInfoFileUpload.nombre_tributario}
										<br />
										<small className="text-fountain-blue ">
											{dataInfoFileUpload.name_doc} (
											{dataInfoFileUpload.publi_nombre})
										</small>
									</h6>
									<button
										type="button"
										className="btn-close"
										data-bs-dismiss="modal"
										aria-label="Close"
									></button>
								</div>
								<div className="modal-body">
									<div className="input-group">
										<input
											type="text"
											name="search_items"
											id="search_items"
											className="form-control form-control-sm"
											placeholder={placeholderItems}
											aria-label={placeholderItems}
											autoComplete="off"
										/>
										<div className="input-group-prepend">
											<button
												className="btn btn-sm btn-outline-primary"
												onClick={handleButtonClickSearchItems}
											>
												<i className="fas fa-search"></i>
											</button>
										</div>
									</div>
									<DataTable
										columns={columnsItemsDocumento}
										data={itemsFileData}
										progressPending={loading}
										pagination
										paginationServer
										paginationTotalRows={totalRowsItem}
										onChangeRowsPerPage={handlePerRowsChangeItem}
										onChangePage={handlePageChangeItem}
										highlightOnHover
										noDataComponent={Texto.there_are_no_records_to_display}
										progressComponent={<CustomLoader />}
										expandableRowsComponent={() => false}
										noHeader={true}
										//expandableRows
									/>
								</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}

				{showModalForm ? (
					<div
						className="modal fade "
						id={idFormImportFile}
						name={idFormImportFile}
						tabIndex="-1"
						aria-labelledby="modalFormImporCreate"
						aria-hidden="true"
						data-backdrop="static"
						data-keyboard="false"
						style={{ overflowY: "auto" }}
					>
						<div className="modal-dialog  modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h6
										className="modal-title text-butterfly-bush"
										id="modalFormCreateTitle"
									>
										{objeto.toUpperCase().replace("_", " ")} -{" "}
										{tipo.toUpperCase().replace("_", " ")}
									</h6>
									<button
										type="button"
										className="btn-close"
										data-bs-dismiss="modal"
										aria-label="Close"
									></button>
								</div>
								<div className="modal-body">
									<div className="col-lg-12 col-xs-12 text-justify ">
										<h6 className="text-fountain-blue mb-2">
											{dataTributaryDescription}
										</h6>
									</div>
									<form
										noValidate
										name={idForm}
										id={idForm}
										method="post"
										onSubmit={handleFormSubmitFile}
										className="needs-validation"
									>
										<div className="row mb-3">
											<div className="col-md-12 col-lg-12 col-xs-12">
												<label
													htmlFor="departament_code"
													className="form-label"
												>
													Departamento
												</label>
												<select
													className="form-select"
													id="departament_code"
													name="departament[code]"
													required
												>
													{dataDepartamento.map((dpto) => (
														<option key={dpto.code} value={dpto.code}>
															{dpto.name}
														</option>
													))}
												</select>
											</div>
										</div>

										<div className="row mb-3">
											<div className="col-md-12 col-lg-12 col-xs-12">
												<label
													htmlFor="publication_code"
													className="form-label"
												>
													<span className="text-danger">(*)</span>
													Nº Publicación
												</label>
												<select
													className="form-select"
													id="publication_code"
													name="publication[code]"
													defaultValue={""}
													data-parsley-trigger="change"
													required
												>
													{/* <option value={""}> * Seleccione * </option> */}
													{dataPublicacion.map((row) => (
														<option key={row.code} value={row.code}>
															{row.name}
														</option>
													))}
												</select>
											</div>
										</div>
										<div className="row mb-3">
											<div className="col-md-12 col-lg-12 col-xs-12">
												<label htmlFor="gestion_code" className="form-label">
													<span className="text-danger">(*)</span>
													Gestión(es)
												</label>
												<MultiSelect
													options={dataGestiones}
													value={selected}
													onChange={setSelected}
													labelledBy="Seleccionar gestión (es)"
													overrideStrings={optionsMultiSelect}
													data-parsley-trigger="change"
													required
												/>
											</div>
										</div>

										<div className="row">
											<div className="col-md-11 col-lg-11 col-xs-11">
												<label htmlFor="document_file" className="form-label">
													<span className="text-danger">(*)</span>
													Seleccione un archivo (.csv)
												</label>
												<small className="fs-small8em text-maroon-flush float-end">
													20MB, Limite Máximo
												</small>
												<div className="form-group files">
													<label className="form-control">
														<input
															type="file"
															className="form-control"
															multiple=""
															id={`document_file`}
															name={`document_file`}
															required={true}
															rows="3"
															data-parsley-max-file-size="20000"
															onChange={(e) => onClickUploadFileInput(e)}
															accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet, text/csv, application/octet-stream"
														/>
													</label>
													<small className="fs-small8em text-maroon-flush nameFile float-start">
														...
													</small>
												</div>
											</div>
										</div>

										<div
											className={
												loadingForm
													? "row mt-3 text-center"
													: "row mt-3 d-none text-center"
											}
										>
											<div className="col-12">
												<img
													className="rounded mx-auto d-block"
													alt="pulse-loading"
													src={"/static/img/pulse_200.gif"}
													width={40}
												/>
											</div>
										</div>

										<div className="row">
											<div
												className="col-md-12  col-lg-12 col-xs-12 mt-4"
												style={{ textAlign: "right" }}
											>
												<button
													type="submit"
													className="btn btn-primary"
													disabled={isDisabled}
												>
													Procesar archivo
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}
			</div>

			<ToastContainer
				enableMultiContainer
				containerId={"Z"}
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnVisibilityChange
				draggable
				pauseOnHover
			/>
		</div>
	);
};

export default TributarioLista;
