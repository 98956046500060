import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fetch from "../utils/Fetch";
import DataTecnicos from "../component/CardInmueble/CardDataTecnicos";
import DataAdministration from "../component/CardInmueble/CardDataAdministration";
import PasosNavigationAutorizacion from "../utils/PasosNavigationAutorizacion";
import ReglasValidacion from "../utils/ReglasValidacion";
import MapCatastroPublicModal from "../component/MapCatastroPublicModal";
import DefaultInmuebleCatastro from "./../VisacionMinutas/DefaultCatatroInmueble";

const FormInmueble = (props) => {
  const fetch = new Fetch();
  fetch.setToast(toast);
  const { dataInmueble, onSubmitForm } = props;

  const [dataPropietario, setDataPropietario] = useState(undefined);
  const [ruleValidation, setRuleValidation] = useState(undefined);
  const [dataGeoEspacial, setDataGeoEspacial] = useState(undefined);
  const [dataServicesBasic, setDataServicesBasic] = useState(undefined);
  const [dataMaterialVia, setDataMaterialVia] = useState(undefined);
  const [dataTecnicosInmueble, setDataTecnicosInmueble] = useState(undefined);
  const [dataInmuebleCatastro, setDataInmuebleCatastro] = useState(undefined);

  useEffect(() => {
    loadInmuebleRuat();
    window.scrollTo(0, 0);
  }, []);

  const handleEventMap = async (
    dataInmuebleCatastro
  ) => {
    debugger;
    const defaultInmueble = DefaultInmuebleCatastro;
    defaultInmueble.codCatastral = dataInmuebleCatastro.catastro;
    defaultInmueble.nroInmueble = dataInmuebleCatastro.nroInmueble;
    defaultInmueble.imageCatastro = {
      fullBase6image4: dataInmuebleCatastro.fullBase6image4,
      base64Image: dataInmuebleCatastro.base64Image,
      coordinate: dataInmuebleCatastro.coordinate,
    };

    const propiedad = {
      apoderado: null,
      codigoContribuyente: "0",
      contribuyenteActualizado: "1",
      documentoIdentidad: "1",
      domicilioLegal: "INNOMINADA",
      juridico: null,
      nombreContribuyente: "CONTRIBUYENTE PUBLICO",
      pmc: "0",
      poder: null,
      tipoContribuyente: "NATURAL",
      copropietarios: [],
      informacionAdicional: {
        celular: "77777777",
        correo: null,
        telefonoReferencia: null,
      },
      natural: {
        apellidoEsposo: null,
        estadoCivil: "SOLTERO(A)",
        expedido: "COCHABAMBA",
        fechaNacimiento: "01/01/1980",
        genero: "MASCULINO",
        nombres: "COCHABAMBA",
        numeroDocumento: "111111",
        primerApellido: "CERCADO",
        segundoApellido: "",
        tipoDocumento: "CEDULA DE IDENTIDAD",
        tipoDocumentoCode: "CI",
      },
    };

    setDataInmuebleCatastro(defaultInmueble);
    setDataPropietario(propiedad);
    setDataTecnicosInmueble(dataInmueble.dataTecnico);
  };

  const loadInmuebleRuat = async () => {
    if (
      !dataInmueble.codigoCatastral &&
      !dataInmueble.propietario &&
      dataInmueble.tipoTramite.public
    ) {
      const modalCatastro = new window.bootstrap.Modal(
        document.getElementById("modalMapCatastro")
      );
      modalCatastro.show();
    } else {
      await loadInmuebleCatastro(dataInmueble.codigoCatastral);
      setDataPropietario(dataInmueble.propietario);
      const form = new FormData();
      form.append("propiedades[codigoPropiedad]", dataInmueble.codigoPropiedad);
      form.append("propiedades[codigoInmueble]", dataInmueble.codigoInmueble);
      fetch
        .fetchPost(form, `api-ruat/inmuebles/datos-tecnicos`, undefined)
        .then((dataJson) => {
          debugger;
          if (dataJson && dataJson.status)
            setDataTecnicosInmueble(dataJson.data);
        });
    }
  };

  const loadInmuebleCatastro = async (codigo_catrastral) => {
    const resInmuebleCatastro = await fetch.fetchGet(
      `api-ruat/inmuebles-catastro/codigo-catastral/${codigo_catrastral}`
    );
    if (resInmuebleCatastro && resInmuebleCatastro.status) {
      if (resInmuebleCatastro.data.inmueble.length > 0) {
        setDataInmuebleCatastro(resInmuebleCatastro.data.inmueble[0]);
      } else {
        ruleValidationFormInmueble("", "", "GLOBAL_CATASTRO");
      }
    }
  };

  const submitFormAdministrativos = (event) => {
    debugger;
    //para el caso de publicos, verificar si es publico aqui tambien
    if (!dataInmueble.propietario && dataInmuebleCatastro) {
      const generateRandom = Math.floor(Math.random() * 10000000);
      dataInmueble.codigoCatastral = dataInmuebleCatastro.codCatastral
        ? dataInmuebleCatastro.codCatastral
        : "CODCAT" + generateRandom;
      if(dataInmuebleCatastro.nroInmueble === 'Nulo')
        dataInmuebleCatastro.nroInmueble = '';
      
      dataInmueble.codigoInmueble = "CODINM" + generateRandom;
      dataInmueble.numeroInmueble = dataInmuebleCatastro.nroInmueble
        ? dataInmuebleCatastro.nroInmueble
        : "CODCAT" + generateRandom;
      dataInmueble.codigoPropiedad = "CODPRO" + generateRandom;
      dataInmueble.estadoPropiedad = "ACTIVO";
      dataInmueble.porcentaje = 0;
      dataInmueble.codigoContribuyente = "123456";
      dataInmuebleCatastro.informesLegal[0].asiento = "A-1";
      dataInmuebleCatastro.informesLegal[0].matricula = "1111111111111";
      dataInmuebleCatastro.informesTecnico[0].fechaAprobacionPlano =
        "1990/01/01";
      dataInmuebleCatastro.informesTecnico[0].fechaResolucionPlano =
        "1990/01/01";
      dataInmueble.fechaInicioPropiedad = "01/01/1990";
      dataInmueble.fechaFinPropiedad = "01/01/2099";
    }

    const data = {
      inmueble: dataInmueble,
      propiedad: {
        porcentaje: dataInmueble.porcentaje,
        codigoPropiedad: dataInmueble.codigoPropiedad,
        codigoContribuyente: dataInmueble.codigoContribuyente,
        estadoPropiedad: dataInmueble.estadoPropiedad,
        fechaInicioPropiedad: dataInmueble.fechaInicioPropiedad,
        fechaFinPropiedad: dataInmueble.fechaFinPropiedad,
        propietario: dataInmueble.propietario,
      },
      datosTecnicosRuat: dataTecnicosInmueble,
      datosTecnicosCatastroCbba: dataInmuebleCatastro,
      dataGeoEspacial: dataGeoEspacial,
      dataServiceBasic: dataServicesBasic,
      dataMaterialVia: dataMaterialVia,
    };
    onSubmitForm(event, data);
  };

  const onEventData = (dataGeoEspacial) => {
    setDataGeoEspacial(dataGeoEspacial);
  };

  const onEventDataService = (dataService) => {
    setDataServicesBasic(dataService);
  };

  const onEventDataMaterialVia = (dataMaterialVia) => {
    setDataMaterialVia(dataMaterialVia);
  };

  const ruleValidationFormInmueble = (
    textInputRuat,
    textInputCatastro,
    typeValidacion
  ) => {
    debugger;
    let message = undefined;
    if (
      typeValidacion === "SUPERFICIE_TERRENO" &&
      dataInmueble.fechaInicioTramite
    ) {
      message = undefined;
    } else {
      if (
        typeValidacion === "SUPERFICIE_TERRENO" &&
        !dataInmueble.fechaInicioTramite
      ) {
        message = undefined;
      } else {
        message = ReglasValidacion.ruleValidation(
          textInputRuat,
          textInputCatastro,
          typeValidacion
        );
      }
    }

    if (message) setRuleValidation(message);
  };

  return (
    <>
      <PasosNavigationAutorizacion
        nro_inmueble={dataInmueble.numeroInmueble}
        paso1_active={true}
        paso2_active={false}
        paso3_active={false}
        paso4_active={false}
        paso5_active={false}
      />

      <form
        action=""
        className="contact__form needs-validation"
        name="formDataInmueble"
        id="formDataInmueble"
        method="post"
        noValidate
        onSubmit={submitFormAdministrativos}
        style={{ width: "100%" }}
      >
        {dataInmueble && dataInmuebleCatastro ? (
          <DataAdministration
            dataInmueble={dataInmueble}
            dataPropietario={dataPropietario}
            dataInmuebleCatastro={dataInmuebleCatastro}
            fetch={fetch}
            onEventData={onEventData}
            ruleValidation={ruleValidationFormInmueble}
            isVisacionMinuta={false}
          />
        ) : (
          ""
        )}

        {dataTecnicosInmueble && dataInmuebleCatastro ? (
          <DataTecnicos
            dataInmueble={dataInmueble}
            dataTecnicosInmueble={dataTecnicosInmueble}
            dataInmuebleCatastro={dataInmuebleCatastro}
            fetch={fetch}
            nameFormInmueble={"inmueble"}
            nameFormConstruccion={"construccion"}
            nameFormConstruccionAdicional={"construccionAdicional"}
            nameFormDireccionInmueble={"inmueble_direccion"}
            onEventDataService={onEventDataService}
            onEventDataMaterialVia={onEventDataMaterialVia}
            ruleValidation={ruleValidationFormInmueble}
            isVisacionMinuta={false}
          />
        ) : (
          ""
        )}

        {ruleValidation ? (
          <div className="row">
            <div className="col-12 text-start">
              <div className="alert alert-danger text-uppercase" role="alert">
                {ruleValidation}
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-ms-6 col-md-6 col-lg-7 text-end"></div>
            <div className="col-12 col-ms-3 col-md-3 col-lg-3 text-end"></div>

            <div className="col-12 col-ms-3 col-md-3 col-lg-2 text-end">
              <div className=" search-btn button mb-3 pointer">
                <input
                  type="Submit"
                  className="btn btn-full-screen"
                  defaultValue={"Siguiente"}
                  name="Siguiente"
                />
              </div>
            </div>
          </div>
        )}
        
      </form>

      {dataInmueble &&
      !dataInmueble.codigoCatastral &&
      !dataInmueble.propietario ? (
        <MapCatastroPublicModal
          module={"antenas"}
          nameForm={""}
          fillPaint={true}
          width="724"
          height="261"
          //mode="basic"
          title="Ubicación"
          handleEventMap={handleEventMap}
        />
      ) : (
        ""
      )}

      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  );
};

export default FormInmueble;
