import React, { useEffect, useState, useRef } from "react";
import "./css/style.css";
import Links from "../../data/link";
import TitlePageBreadcrumb from "../utils/TitlePageBreadcrumb";
import Config from "../../data/config";
import Texto from "../../data/es";
import { ToastContainer, toast } from "react-toastify";
import Fetch from "../utils/Fetch";
import Iframe from "react-iframe";
import ReactDatePicker from "react-datepicker";

// import { Link } from "react-router-dom";
// import AuthService from "../Usuario/AuthService";
// import { useHistory } from "react-router-dom";
// import TributarioButton from "./_common/TributarioButton";
// import ModalFormularioFile from "./_common/ModalFormularioFile";


const breadcrumbs = [
    {
        title: Links[0].title,
        url: Links[0].url,
    },
    {
        title: Links[112].title,
        url: Links[112].url,
    },
    {
        title: Links[135].title,
        url: Links[135].url,
    },
];

const ContribuyenteInicioVehiculo = (props) => {

    const fetch = new Fetch();
    fetch.setToast(toast);

    const [titlePage, setTitlePage] = useState(Texto.ras_contribuyente_inicio);
    const [selectData, setSelectData] = useState(null)
    const [unidades, setUnidades] = useState([])
    const [loading, setLoading] = useState(false);
    const [isFrame, setIsFrame] = useState(false);
    const [urlPdf, setUrlPdf] = useState('/static/ras/PUBLICACION_VEHICULO_RAS.pdf');
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        loadUnidadVehiculo()
        window.scrollTo(0, 0);
    }, []);


    const enabledDates = [
        // '2024-08-01',
        // '2024-08-10',
        // '2024-08-20'
    ];

    const loadUnidadVehiculo = async () => {
        debugger
        const responseTributario = await fetch.axiosAsyncGet(
            `api/nt-tributaria/departamento-ras-vehiculo`
        );

        if (responseTributario && responseTributario.status) {
            debugger
            setUnidades(responseTributario.data.Departament);
        }
    };



    const previewPdf = async (codeUnidad, codeObjeto, fecha) => {
        debugger;
        setLoading(true);


        setIsFrame(true);
        setTimeout(
            downloadPdf(codeUnidad, codeObjeto, fecha),
            1000
        );
    };

    // ================================================ //
    const downloadPdf = (codeUnidad, codeObjeto, fecha) => {
        let url = '';
        //`${Config[0].url}api/nt-tributaria/reporte-pdf-masiva/${year}/${objectTributary}/${typeImp}/${codePubli}/preview`;
        setUrlPdf(url);
    };

    // api/nt-tributaria/departamento-ras-vehiculo

    return (<>
        <section className="items-grid section mt-5 mb-5 bg-gray">

            <TitlePageBreadcrumb
                titlePage={titlePage}
                breadcrumbs={breadcrumbs}
                position={"left"}
                leftfull={false}
            />
            <div className="container mt-4">
                <form>
                <div className="row justify-content-center">
                        <div className="form-group col-md-4 col-sm-12 mb-2">
                            <select className="form-control" required>
                                <option key={0}>* Seleccione *</option>
                                {unidades.map(department => (
                                    <option key={department.id} value={department.id}>
                                        {department.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-2 form-group  col-sm-12 mb-2">
                            <ReactDatePicker
                                selected={selectedDate}
                                onChange={date => setSelectedDate(date)}
                                dateFormat="yyyy-MM-dd"
                                locale="es"
                                // includeDates={enabledDates} 
                                customInput={<input type="text" placeholder="Select a date" required />}
                            />
                        </div>
                        <div className="col-md-3 form-group  col-sm-12 mb-2">
                            <button  type="submit" className="btn btn-success">Buscar Publicacion</button>
                        </div>
                </div>

            </form>
            {/* {isFrame && ( */}
            <div className="d-flex flex-row vh-100 justify-content-center align-content-center align-items-center">
                <div className="col-md-9 col-lg-9 col-xs-12 border border-secondary">
                    <div className="w-100">
                        <Iframe
                            id="iframePreviewMasivaPdf"
                            src={urlPdf != null ? urlPdf + "#zoom=95" : ""}
                            frameBorder="0"
                            allow="allow-same-origin allow-scripts allow-popups allow-forms"
                            allowFullScreen
                            display="initial"
                            className="embed-responsive-item vh-100"
                            loading="pulse-loading"
                            onLoad={() => setLoading(false)}

                        ></Iframe>
                    </div>
                </div>
            </div>
            {/* // ) } */}
        </div>
    </section>
    </>);

}

export default ContribuyenteInicioVehiculo;