import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Fetch from "../utils/Fetch";
import Links from "../../data/link";
import TitlePage from "../utils/TitlePage";
import Texto from "../../data/es";
import { Link } from "react-router-dom";
import Config from "../../data/config";
import ModalPdf from "../utils/ModalPdf";

const breadcrumbs = [
  {
    title: Links[0].title,
    url: Links[0].url,
  },
  {
    title: Links[115].title,
    url: Links[115].url,
  },
];

const SHOW_LEY = "SHOW_LEY";
const SHOW_RTA = "SHOW_RTA";
const SHOW_DECRETO = "SHOW_DECRETO";
let showPanelContainer = "";
let textSearchCurrent = "";

const GacetaTributaria = (props) => {
  const titlePage = Texto.gaceta_tributaria;
  const fetch = new Fetch();
  fetch.setToast(toast);

  const modalPdf = new ModalPdf();
  modalPdf.setToast(toast);

  const refSelectGestion = useRef();
  const refInputTextSearch = useRef();
  const refInputNumberSearch = useRef();
  let currentPage = 1;
  let perPage = 10;

  const [data, setData] = useState();
  const [optionsGestion, setOptionsGestion] = useState(undefined);
  const [showForm, setShowForm] = useState(false);
  const [totalFounded, setTotalFounded] = useState(0);

  let titleLey = "LEYES MUNICIPALES";
  let titleRTA = "RESOLUCION TEC. ADMINISTRATIVA";
  let titleDecreto = "DECRETOS MUNICIPALES";

  const widthViewPort = document.documentElement.clientWidth;
  if (widthViewPort <= 960) {
    titleLey = "LM";
    titleRTA = "RTA";
    titleDecreto = "DM";
  }

  useEffect(() => {
    if (!showPanelContainer)
      searchGacetaTributaria(currentPage, perPage, "0", 0, "--", 0);
    window.scrollTo(0, 0);
  }, []);

  const loadGestion = () => {
    const date = new Date();
    fetch
      .axiosAsyncGet(`api/gestion/range-year/2010/${date.getFullYear()}`)
      .then((response) => {
        if (response && response.status) {
          const dataResp = response.data.gestion.map(
            ({ id, name, active }, index) => {
              return (
                <option key={index} value={id} name={name}>
                  {name}
                </option>
              );
            }
          );
          setOptionsGestion(dataResp);
        }
      });
  };

  const searchGacetaTributaria = async (
    page,
    perPage,
    type,
    number,
    text,
    gestion
  ) => {
    const response = await fetch.axiosAsyncGet(
      `api/gaceta-tributaria/search/${page}/${perPage}/${type}/${number}/${text}/${gestion}`
    );
    if (response && response.status) {
      const arrayTemp = [];
      const dataResp = response.data.map(
        ({
          description,
          id,
          title,
          number,
          publication_at,
          type_code,
          documents,
        }) => {
          const document = JSON.parse(documents);
          arrayTemp.push({
            codegt: "gt-code-" + id,
            id: id,
            codedoc: document ? document.code : undefined,
          });
          return (
            <div
              className="col-12 col-md-6 mb-4"
              key={id + "-" + number + "-" + type_code + "-"}
            >
              <div className="gaceta-tributaria">
                <div className="header-gt">
                  <div className="title text-birch-09">{title}</div>
                </div>
                <div className="body-gt pt-3">{description}</div>

                <div className="footer-gt">
                  <div className="left-gt">
                    <span
                      className={
                        "title-footer " +
                        (document ? "fw-bold text-maroon-flush" : "")
                      }
                    >
                      {getTextByType(type_code, number)}
                    </span>
                    <div className="footer-gt-body pt-1">
                      {document ? (
                        <Link
                          to="#"
                          className=""
                          rel="noreferrer noopener"
                          title={document.name}
                          onClick={(e) =>
                            openModal(e, document.code, number, type_code)
                          }
                        >
                          <i className="fas fa-file-download h4"></i>
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="right-gt">
                    <span className={"title-footer float-start"}>
                      Normativa Relacionada
                    </span>
                    <div
                      className="footer-gt-body pt-1"
                      codegt={"gt-code-" + id}
                      codetype={type_code}
                    >
                      <span>
                        <i className="fas fa-spinner fa-spin h4 spinner-doc-relation"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      );

      setData(dataResp);
      getPaginationNumbers(response.totalPages, response.page);
      setTotalFounded(response.total);
      await loadChildsReglamentos(arrayTemp);
    } else {
      setData([]);
    }
  };

  const openModal = (e, code, number, type) => {
    e.preventDefault();
    const url = `${Config[0].url}api/gaceta-tributaria/documents/preview-download`;
    modalPdf.showPdf(
      `${url}/preview/${code}`,
      getTextByType(type, number),
      code,
      undefined,
      `${url}/download/${code}`
    );
  };

  const getTextByType = (type, number) =>
    type === "LEYES"
      ? "LM Nro " + number
      : type === "DECRETOS"
      ? "DM Nro " + number
      : type === "RTA"
      ? "RTA Nro " + number
      : "";

  const loadChildsReglamentos = async (gacetasTributarias) => {
    if (gacetasTributarias && gacetasTributarias.length > 0) {
      const listChilds = [];
      for (let index = 0; index < gacetasTributarias.length; index++) {
        const childsTemp = await searchGacetaTributariaByFather(
          gacetasTributarias[index].id
        );
        if (childsTemp) {
          listChilds.push({
            codegt: gacetasTributarias[index].codegt,
            childs: childsTemp,
          });
          if (
            document.querySelector(
              '[codegt="' + gacetasTributarias[index].codegt + '"]'
            )
          )
            document.querySelector(
              '[codegt="' + gacetasTributarias[index].codegt + '"]'
            ).innerHTML = "";
        }
      }

      if (listChilds && listChilds.length > 0) {
        for (let j = 0; j < listChilds.length; j++) {
          for (let k = 0; k < listChilds[j].childs.length; k++) {
            const child = listChilds[j].childs[k];
            const documentJson = JSON.parse(child.documents);
            if (documentJson) {
              const nodeI = document.createElement("i");
              nodeI.classList.add("fas");
              nodeI.classList.add("fa-file-download");
              nodeI.classList.add("h4");

              const link = document.createElement("a");
              link.appendChild(nodeI);
              const nameDoc = documentJson.name.toUpperCase();
              link.title = nameDoc;
              if (!nameDoc.includes("/")) {
                const lastIndex = nameDoc.lastIndexOf(" ");
                link.title =
                  nameDoc.substring(0, lastIndex) +
                  "/" +
                  nameDoc.substr(lastIndex + 1);
              }

              link.target = "_blank";
              link.classList.add("pe-3");
              link.href = `#`;
              link.onclick = (e) =>
                openModal(e, documentJson.code, child.number, child.type_code);

              const container = document.querySelector(
                '[codegt="' + listChilds[j].codegt + '"]'
              );
              container.appendChild(link);
            }
          }
        }
      }
    }

    const spinners = document.getElementsByClassName("spinner-doc-relation");
    for (let index = 0; index < spinners.length; index++) {
      spinners[index].parentElement.classList.add("d-none");
    }
  };

  const searchGacetaTributariaByFather = async (idFather) => {
    const response = await fetch.axiosAsyncGet(
      `api/gaceta-tributaria/search-by-id-father/${idFather}`
    );
    if (response && response.status && response.data && response.data.length)
      return response.data;
    return null;
  };

  const handleChangeEventGaceta = (e, eventGaceta) => {
    e.preventDefault();
    const bottom = e.target;
    for (
      let index = 0;
      index <
      Array.from(document.getElementsByClassName("square-footer")).length;
      index++
    ) {
      const element = Array.from(
        document.getElementsByClassName("square-footer")
      )[index];

      const classList = element.firstChild.classList;
      classList.add("bg-carrot-orange-light");
      classList.remove("bg-fountai-blue");
    }
    bottom.classList.remove("bg-carrot-orange-light");
    bottom.classList.add("bg-fountai-blue");

    if (eventGaceta === "LEY") {
      searchGacetaTributaria(1, perPage, "LEYES", 0, "--", 0);
      loadGestion();
      showPanelContainer = SHOW_LEY;
    }

    if (eventGaceta === "RTA") {
      searchGacetaTributaria(1, perPage, "RTA", 0, "--", 0);
      loadGestion();
      showPanelContainer = SHOW_RTA;
    }

    if (eventGaceta === "DECRETO") {
      searchGacetaTributaria(1, perPage, "DECRETOS", 0, "--", 0);
      loadGestion();
      showPanelContainer = SHOW_DECRETO;
    }
    setShowForm(true);
  };

  const handleSearchEvent = (event) => {
    event.preventDefault();
    debugger;
    const formHtml = event.target;
    window
      .jQuery("#" + event.target.getAttribute("id"))
      .parsley()
      .validate();

    if (
      window
        .jQuery("#" + formHtml.getAttribute("id"))
        .parsley()
        .isValid()
    ) {
      searchForm(1);
    }
  };

  const appendPageNumber = (index, currentPage) => {
    const liPage = document.createElement("li");
    liPage.setAttribute("page-index", index);
    liPage.setAttribute("aria-label", "Page " + index);
    if (index === currentPage) liPage.classList.add("active");

    const linkPage = document.createElement("a");
    linkPage.innerHTML = index;
    linkPage.setAttribute("href", "#");

    linkPage.onclick = handleChangePagination;
    liPage.appendChild(linkPage);

    document.getElementById("pagination-numbers").appendChild(liPage);
  };

  const getPaginationNumbers = (pageCount, currentPage) => {
    document.getElementById("pagination-numbers").innerHTML = "";
    for (let i = 1; i <= pageCount; i++) {
      appendPageNumber(i, currentPage);
    }
  };

  const handleChangePagination = (event) => {
    event.preventDefault();
    const pageClick = event.target.parentElement.getAttribute("page-index");
    currentPage = pageClick;

    if (!showPanelContainer)
      searchGacetaTributaria(pageClick, perPage, "0", 0, "--", 0);
    else searchForm(currentPage);
  };

  const searchForm = (currentPage) => {
    const inputTextSearch = refInputTextSearch.current;
    const inputNumberSearch = refInputNumberSearch.current;
    const selectionGestion = refSelectGestion.current;
    const yearSelected =
      selectionGestion.selectedOptions[0].getAttribute("name");
    const textSearch = inputTextSearch.value;
    const numberSearch = inputNumberSearch.value;

    if (
      !textSearch &&
      !numberSearch &&
      !yearSelected //|| yearSelected === "0"
    ) {
      fetch.toast.warning("Debe seleccionar un criterio de búsqueda", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      if (showPanelContainer === SHOW_LEY) {
        searchGacetaTributaria(
          currentPage,
          perPage,
          "LEYES",
          numberSearch ? numberSearch : 0,
          textSearch ? textSearch : "--",
          yearSelected
        );
      }

      if (showPanelContainer === SHOW_RTA) {
        searchGacetaTributaria(
          currentPage,
          perPage,
          "RTA",
          numberSearch ? numberSearch : 0,
          textSearch ? textSearch : "--",
          yearSelected
        );
      }

      if (showPanelContainer === SHOW_DECRETO) {
        searchGacetaTributaria(
          currentPage,
          perPage,
          "DECRETOS",
          numberSearch ? numberSearch : 0,
          textSearch ? textSearch : "--",
          yearSelected
        );
      }

      refInputTextSearch.current.disabled = false;
      refInputNumberSearch.current.disabled = false;
      refInputTextSearch.current.value = "";
      refInputNumberSearch.current.value = "";

      debugger;
      if (textSearch) textSearchCurrent = textSearch;
      if (numberSearch) textSearchCurrent = numberSearch;
    }
  };

  const handleOnBlurNumber = (event) => {
    event.preventDefault();
    const target = event.target;
    if (target.value.length > 0) refInputTextSearch.current.disabled = true;
    else refInputTextSearch.current.disabled = false;
  };

  const handleOnBlurDescription = (event) => {
    event.preventDefault();
    const target = event.target;
    if (target.value.length > 0) refInputNumberSearch.current.disabled = true;
    else refInputNumberSearch.current.disabled = false;
  };

  return (
    <section className="items-grid section mt-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={titlePage}
          breadcrumbs={breadcrumbs}
          position={"left"}
          leftfull={false}
        />

        <div className="row mt-5">
          <div className="col-12 col-md-1"></div>
          <div className="col-12 col-md-10">
            <div className="row">
              <div className="col-4 col-md-4">
                <div
                  className="text-center square-footer w-100 pointer"
                  onClick={(e) => handleChangeEventGaceta(e, "LEY")}
                >
                  <p className="text text-white fw-600 bg-carrot-orange-light">
                    {titleLey}
                  </p>
                </div>
              </div>

              <div className="col-4 col-md-4">
                <div
                  className="text-center square-footer w-100 pointer"
                  onClick={(e) => handleChangeEventGaceta(e, "DECRETO")}
                >
                  <p className="text bg-carrot-orange-light text-white fw-600">
                    {titleDecreto}
                  </p>
                </div>
              </div>

              <div className="col-4 col-md-4">
                <div
                  className="text-center square-footer w-100 pointer"
                  onClick={(e) => handleChangeEventGaceta(e, "RTA")}
                >
                  <p className="text bg-carrot-orange-light text-white fw-600">
                    {titleRTA}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-1"></div>
        </div>

        {showForm ? (
          <form
            action=""
            className="contact__form needs-validation"
            id="formSearchGacetaTributaria"
            method="post"
            noValidate
            onSubmit={handleSearchEvent}
            style={{ width: "100%" }}
          >
            <div className="row mt-3">
              <div className="col-12 col-md-1"></div>
              <div className="col-12 col-md-10">
                <div className="row">
                  <div className="col-12 col-md-2 mb-3 ">
                    <label htmlFor={"gaceta_tributaria[number]"} className="">
                      Número
                    </label>
                    <input
                      name={"gaceta_tributaria[number]"}
                      id={"gaceta_tributaria[number]"}
                      type="text"
                      ref={refInputNumberSearch}
                      className="form-control input-uppercase"
                      placeholder="Ingrese Nro"
                      /*required
                      data-parsley-required="true"*/
                      data-parsley-pattern="[\/0-9]+$"
                      data-parsley-number="1"
                      data-parsley-special="1"
                      onBlur={handleOnBlurNumber}
                      //onFocus={handleOnFocuNumber}
                    />
                  </div>
                  <div className="col-12 col-md-6 mb-3 ">
                    <label
                      htmlFor={"gaceta_tributaria[description]"}
                      className=""
                    >
                      Contenido
                    </label>
                    <input
                      name={"gaceta_tributaria[description]"}
                      id={"gaceta_tributaria[description]"}
                      type="text"
                      ref={refInputTextSearch}
                      className="form-control input-uppercase"
                      placeholder="Ingrese Descripción"
                      /*required
                      data-parsley-required="true"*/
                      data-parsley-pattern="[a-z A-Z 0-9]+$"
                      data-parsley-number="1"
                      data-parsley-special="1"
                      onBlur={handleOnBlurDescription}
                    />
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="row">
                      <div className="col-12 col-md-8 mb-3">
                        <label
                          htmlFor={"gaceta_tributaria[description]"}
                          className=""
                        >
                          Gestión
                        </label>
                        <select
                          className="form-select"
                          name={"gaceta_tributaria[id_documentos_minuta]"}
                          ref={refSelectGestion}
                          required
                          data-parsley-required="true"
                        >
                          <option value="0" name="0">
                            Todas las Gestiones
                          </option>
                          {optionsGestion}
                        </select>
                      </div>
                      <div className="col-12 col-md-4 mb-3">
                        <div className=" search-btn button text-end">
                          <label htmlFor={"buttomSubmit"} className=""></label>
                          <input
                            type="submit"
                            name="buttomSubmit"
                            className="btn btn-full-screen"
                            style={{ padding: "7px 22px 7px 22px" }}
                            value="Buscar"
                            //onClick={handleSearchEvent}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-1"></div>
            </div>
          </form>
        ) : (
          ""
        )}

        {data ? (
          <>
            <div className="row mt-3">
              <div className="col-12">
                <p
                  className="titleSearch  pt-2"
                  style={{
                    borderTop: "1px solid rgba(35, 38, 45, 0.15)",
                  }}
                >
                  {"Cerca de " +
                    totalFounded +
                    " resultados encontrados" +
                    (textSearchCurrent
                      ? ', sobre "' + textSearchCurrent + '"'
                      : textSearchCurrent)}
                </p>
              </div>
            </div>
            <div className="row mt-3">{data}</div>

            <div className="row mt-3">
              <div className="col-12">
                <div className="pagination right">
                  <ul className="pagination-list " id="pagination-numbers"></ul>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>

      <ModalPdf />
    </section>
  );
};

export default GacetaTributaria;
