import React, { useState, useEffect, useRef } from "react";
import DatePickerAtm from "../component/DatePickerAtm";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Texto from "../../data/es";
import Config from "../../data/config";
import PasosNavigationVisacion from "../utils/PasosNavigationVisacionMinutas";

const FormSujetoPasivo = (props) => {
  const { dataInmueble, onSubmitForm, nameForm, dataMinutaDb, fetch } = props;
  const idForm = "formDataMinuta";

  const [optionsMonedaMinuta, setOptionsMonedaMinuta] = useState([]);
  const [optionsDocumentoMinuta, setOptionsDocumentoMinuta] = useState([]);
  const [showAmount, setShowAmount] = useState(false);
  const [showFormTestimonio, setShowFormTestimonio] = useState(false);
  const [showFormRta, setShowFormRta] = useState(false);
  const [showFormMinuta, setShowFormMinuta] = useState(false);
  const [showDatosNotario, setShowDatosNotario] = useState(false);
  const [startDatePropietario, setStartDatePropietario] = useState(undefined);
  const [showModalConfirmData, setShowModalConfirmData] = useState(false);
  const [confirmaDataMessage, showConfirmaDataMessage] = useState(undefined);
  const [modalConfirmData, setModalConfirmData] = useState(undefined);

  const refInputAmount = useRef();
  const refSelectMoneda = useRef();
  const refSelectDocumento = useRef();
  const refInputNumero = useRef();
  const refInputNotario = useRef();
  const refInputNumeroNotario = useRef();

  useEffect(() => {
    window.inputTextUpperCase();
    loadTiposMonedas();
    loadTiposDocumentos();
    if (dataInmueble.tipoVisacionMinuta.showAmount) {
      setShowAmount(true);
      setShowFormTestimonio(false);
      setShowFormRta(false);
      setShowFormMinuta(true);
      setTimeout(() => {
        if (dataMinutaDb && refInputAmount.current) {
          refInputAmount.current.value = dataMinutaDb.amount.replace(".", ",");
        }
        window.jQuery(".linkHelpMontoDecimal").popover({
          title:
            '<h3 class="custom-title"><i class="fa fa-map-marker"></i> Decimales</h3>',
          content:
            '<p>Debe utilizar ",", para separar los decimales Ej: (1235,58) .</p>',
          html: true,
        });
      }, 100);
    } else setShowAmount(false);

    if (
      dataInmueble.tipoVisacionMinuta.code ===
      "FRACCIONAMIENTO_ANEXION_ADECUACION_PH"
    ) {
      setShowFormTestimonio(true);
      setShowFormRta(true);
      setShowFormMinuta(false);
    }

    if (dataMinutaDb) {
      setStartDatePropietario(new Date(dataMinutaDb.date));
      if (refInputNumero.current)
        refInputNumero.current.value = dataMinutaDb.numero;
      if (refInputNotario.current)
        refInputNotario.current.value = dataMinutaDb.notario;
      if (refInputNumeroNotario.current)
        refInputNumeroNotario.current.value = dataMinutaDb.numero_notario;
    }
    window.scrollTo(0, 0);
  }, []);

  const loadTiposMonedas = () => {
    const divisas = fetch.fetchGet(`api/list/divisas`);
    divisas.then((res) => {
      if (res && res.status && res.divisas) {
        const listItems = res.divisas.map((item, index) => {
          return (
            <option
              key={index}
              value={item.id}
              name={item.name}
              code={item.code}
            >
              {item.name} ({item.code})
            </option>
          );
        });
        debugger;
        setOptionsMonedaMinuta(listItems);
        if (dataMinutaDb && dataMinutaDb.divisa)
          refSelectMoneda.current.value = dataMinutaDb.divisa;
      }
    });
  };

  const loadTiposDocumentos = async () => {
    const documents = await fetch.fetchGet(
      `api/visacion-minutas/documentos-minuta`
    );
    if (documents && documents.status && documents.DocumentosMinuta) {
      const listItems = documents.DocumentosMinuta.map((item, index) => {
        return (
          <option key={index} value={item.id} name={item.name} code={item.code}>
            {item.name}
          </option>
        );
      });
      debugger;
      setOptionsDocumentoMinuta(listItems);
      if (dataMinutaDb && dataMinutaDb.id_documentos_minuta) {
        if (refSelectDocumento.current) {
          refSelectDocumento.current.value = dataMinutaDb.id_documentos_minuta;
          var event = new Event("onchange", {
            bubbles: true,
            cancelable: true,
          });
          refSelectDocumento.current.dispatchEvent(event);
          hanldeChangeTipoDocumento(event);
        }
      }
    }
  };

  const submitFormMinuta = (event) => {
    debugger;
    const form = event.target.closest("form");
    window
      .jQuery("#" + form.getAttribute("id"))
      .parsley()
      .validate();
    if (
      window
        .jQuery("#" + form.getAttribute("id"))
        .parsley()
        .isValid()
    ) {
      setShowModalConfirmData(true);
      setTimeout(() => {
        const modalConfirm = document.getElementById(
          "modalConfirmVisacionMinuta"
        );
        const modalFur = new window.bootstrap.Modal(modalConfirm, {
          keyboard: true,
          backdrop: "static",
          focus: true,
        });
        modalFur.show();
        modalConfirm.addEventListener("shown.bs.modal", function (e) {
          window.jQuery("#formDataMinuta").parsley().validate();
        });
        setModalConfirmData(modalFur);
      }, 500);
    }
  };

  const handleValidConfirmForm = (event) => {
    debugger;
    event.preventDefault();
    const form = event.target;
    if (window.jQuery(form).parsley().isValid()) {
      let isEquals = true;
      const confirmMount = document.getElementById("[monto_confirm]");
      const confirmDate = document.getElementsByName(
        "[fecha_minuta_confirm]"
      )[0].value;
      const confirmManyOption = document.getElementsByName(
        "[moneda_minuta_confirm]"
      )[0];

      const mount = refInputAmount.current;
      const monedaOption = refSelectMoneda.current;
      const fecha = document.getElementsByName("persona[fecha_minuta]")[0]
        .value;

      if (
        confirmMount &&
        parseFloat(confirmMount.value.replace(",", "."), 2) !==
          parseFloat(mount.value.replace(",", "."), 2)
      ) {
        isEquals = false;
        showConfirmaDataMessage(
          `Los Montos no son iguales (${confirmMount.value} != ${mount.value})`
        );
      }

      if (
        confirmManyOption &&
        confirmManyOption.selectedOptions[0].getAttribute("code") !==
          monedaOption.selectedOptions[0].getAttribute("code")
      ) {
        isEquals = false;
        showConfirmaDataMessage(
          `Las Monedas no son iguales (${confirmManyOption.selectedOptions[0].getAttribute(
            "code"
          )} != ${monedaOption.selectedOptions[0].getAttribute("code")})`
        );
      }
      if (
        !window
          .moment(confirmDate, "DD/MM/YYYY")
          .isSame(window.moment(fecha, "DD/MM/YYYY"))
      ) {
        isEquals = false;
        showConfirmaDataMessage(
          `Las Fechas no son iguales (${confirmDate} != ${fecha})`
        );
      }

      if (isEquals) {
        showConfirmaDataMessage(``);
        modalConfirmData.hide();
        const data = {
          dataMinuta: {
            amount: showAmount ? refInputAmount.current.value : 0,
            divisa: refSelectMoneda.current ? refSelectMoneda.current.value : 0,
            date: document.getElementsByName("persona[fecha_minuta]")[0].value,
            documentoMinuta: refSelectDocumento.current
              ? refSelectDocumento.current.value
              : 0,
            numero: refInputNumero.current ? refInputNumero.current.value : 0,
            notario: refInputNotario.current
              ? refInputNotario.current.value
              : "",
            numeroNotario: refInputNumeroNotario.current
              ? refInputNumeroNotario.current.value
              : 0,
          },
        };
        onSubmitForm(event, data);
      }
    }
  };

  const hanldeChangeTipoDocumento = (event) => {
    event.preventDefault();
    debugger;
    const optionsSelected = event.target.selectedOptions[0];
    switch (optionsSelected.getAttribute("code")) {
      case "TESTIMONIO_PUBLICO":
        setShowDatosNotario(true);
        break;
      case "RESOLUCION_TECNICA_ADMINISTRATIVA":
        setShowDatosNotario(false);
        break;
      default:
        setShowDatosNotario(false);
        break;
    }
  };

  return (
    <>
      <PasosNavigationVisacion
        nro_inmueble={dataInmueble.numeroInmueble}
        paso1_active={true}
        paso2_active={true}
        paso3_active={true}
        paso4_active={true}
        paso5_active={false}
      />

      <form
        action=""
        className="contact__form needs-validation"
        name="formConfirmData"
        id="formConfirmData"
        method="post"
        noValidate
        style={{ width: "100%" }}
      >
        <div className="card widget widget-simple">
          <div className="card-body ">
            <div className="row ">
              <div className="col-12 col-md-6 widget-header mb-3">
                <h4 className="text-maroon-flush"><i className="fas fa-bookmark me-2"></i> {Texto.datos}</h4>
              </div>
              <div className="col-4 col-md-6 widget-header mb-3"></div>
            </div>

            <div className="widget-content">
              <div className="widget-body">
                <div className="row">
                  {showFormTestimonio || showFormRta ? (
                    <div className="col-12 col-md-4 col-lg-4 mb-3">
                      <label
                        htmlFor={nameForm + "[id_documentos_minuta]"}
                        className="form-label"
                      >
                        Documentos *
                      </label>
                      <select
                        className="form-select"
                        name={nameForm + "[id_documentos_minuta]"}
                        ref={refSelectDocumento}
                        required
                        data-parsley-required="true"
                        onChange={hanldeChangeTipoDocumento}
                      >
                        <option value="">Seleccione Documento</option>
                        {optionsDocumentoMinuta}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  {showFormMinuta && showAmount ? (
                    <>
                      <div className="col-12 col-md-4 col-lg-4 mb-3">
                        <label
                          htmlFor={nameForm + "[monto]"}
                          className="form-label"
                        >
                          Monto * (Ej: 1000,59)
                        </label>
                        <button
                          type="button"
                          className="btn link-help linkHelpMontoDecimal"
                          id="linkHelpMontoDecimal"
                          style={{
                            position: "absolute",
                            top: "52px",
                            left: "320px",
                          }}
                        >
                          <i className="fa fa-question-circle"></i>
                        </button>
                        <input
                          name={nameForm + "[monto]"}
                          id={nameForm + "[monto]"}
                          type="text"
                          ref={refInputAmount}
                          className="form-control input-uppercase"
                          placeholder="1000,59"
                          required
                          data-parsley-required="true"
                          data-parsley-pattern="^[1-9]\d*(\,\d{1})?\d{0,1}$"
                          data-parsley-number="1"
                          data-parsley-special="1"
                        />
                      </div>

                      <div className="col-12 col-md-4 col-lg-4 mb-3">
                        <label
                          htmlFor={nameForm + "[moneda_minuta]"}
                          className="form-label"
                        >
                          Moneda *
                        </label>
                        <select
                          className="form-select"
                          name={nameForm + "[moneda_minuta]"}
                          ref={refSelectMoneda}
                          required
                          data-parsley-required="true"
                        >
                          <option value="" name={""} code={""}>
                            Seleccione Moneda
                          </option>
                          {optionsMonedaMinuta}
                        </select>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div className="col-12 col-md-4 col-lg-4 mb-3">
                    <label
                      htmlFor={nameForm + "[fecha_minuta]"}
                      className="form-label"
                    >
                      Fecha *
                    </label>
                    <DatePickerAtm
                      placeHolder={"Fecha"}
                      name={nameForm + "[fecha_minuta]"}
                      startDateSelected={startDatePropietario}
                      maxDate={new Date()}
                      mandatory={true}
                      minDate={new Date(Config[8].anio + "/01/01")}
                    />
                  </div>

                  {showFormTestimonio || showFormRta ? (
                    <div className="col-12 col-md-4 col-lg-4 mb-3">
                      <label
                        htmlFor={nameForm + "[numero]"}
                        className="form-label"
                      >
                        Número *
                      </label>
                      <input
                        name={nameForm + "[numero]"}
                        id={nameForm + "[numero]"}
                        type="text"
                        ref={refInputNumero}
                        className="form-control input-uppercase"
                        placeholder="Número"
                        required
                        data-parsley-required="true"
                        pattern="^[0-9\/]+"
                        data-parsley-pattern="^[0-9\/]+"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {(showFormTestimonio || showFormRta) && showDatosNotario ? (
                  <div className="row">
                    <div className="col-12 col-md-4 col-lg-4 mb-3">
                      <label
                        htmlFor={nameForm + "[notario]"}
                        className="form-label"
                      >
                        Notario *
                      </label>
                      <input
                        name={nameForm + "[notario]"}
                        id={nameForm + "[notario]"}
                        type="text"
                        ref={refInputNotario}
                        className="form-control input-uppercase"
                        placeholder="Nombre completo"
                        required
                        data-parsley-required="true"
                        pattern="^[A-Za-z ñÑáéíóúÁÉÍÓÚ]+"
                        data-parsley-pattern="^[A-Za-z ñÑáéíóúÁÉÍÓÚ]+"
                      />
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 mb-3">
                      <label
                        htmlFor={nameForm + "[numero_notario]"}
                        className="form-label"
                      >
                        Nro. Notario *
                      </label>
                      <input
                        name={nameForm + "[numero_notario]"}
                        id={nameForm + "[numero_notario]"}
                        type="text"
                        ref={refInputNumeroNotario}
                        className="form-control input-uppercase"
                        placeholder="Número de Notario"
                        required
                        data-parsley-required="true"
                        pattern="^[0-9]+"
                        data-parsley-pattern="^[0-9]+"
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6 col-lg-6 text-end"></div>
          <div className="col-12 col-md-2 col-lg-2 text-end">
            <div className=" search-btn button mb-3"></div>
          </div>

          <div className="col-12 col-md-4 col-lg-4 text-end">
            <div className=" search-btn button mb-3">
              <input
                type="button"
                className="btn btn-full-screen"
                defaultValue={"Siguiente"}
                onClick={submitFormMinuta}
              />
            </div>
          </div>
        </div>
      </form>

      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />

      {showModalConfirmData ? (
        <div
          className="modal fade "
          id={"modalConfirmVisacionMinuta"}
          tabIndex="-1"
          aria-labelledby="modalConfirmVisacionMinutaTitle"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          style={{ overflowY: "auto" }}
        >
          <div className="modal-dialog  modal-dialog-centered modal-lg">
            <div className="modal-content ">
              <div className="modal-header text-butterfly-bush justify-content-center">
                <h4
                  className="modal-title "
                  id="modalConfirmVisacionMinutaTitle"
                  style={{ fontWeight: 600 }}
                >
                  CONFIRMACION DE DATOS
                </h4>
              </div>
              <div className="modal-body">
                <form
                  data-parsley-trigger="keyup"
                  data-parsley-validate
                  name={idForm}
                  id={idForm}
                  onSubmit={handleValidConfirmForm}
                >
                  <div className="row">
                    {confirmaDataMessage ? (
                      <div className="alert alert-danger" role="alert">
                        {confirmaDataMessage}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row">
                    {showFormMinuta && showAmount ? (
                      <>
                        <div className="col-12 col-md-4 col-lg-4 mb-3">
                          <label
                            htmlFor={"[monto_confirm]"}
                            className="form-label"
                          >
                            Monto *
                          </label>
                          <input
                            name={"[monto_confirm]"}
                            id={"[monto_confirm]"}
                            type="text"
                            className="form-control input-uppercase"
                            placeholder="1000,59"
                            required
                            data-parsley-required="true"
                            data-parsley-pattern="^[1-9]\d*(\,\d{1})?\d{0,1}$"
                          />
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 mb-3">
                          <label
                            htmlFor={"[moneda_minuta_confirm]"}
                            className="form-label"
                          >
                            Moneda *
                          </label>
                          <select
                            className="form-select"
                            name={"[moneda_minuta_confirm]"}
                            required
                            data-parsley-required="true"
                          >
                            <option value="" name={""} code={""}>
                              Seleccione Moneda
                            </option>
                            {optionsMonedaMinuta}
                          </select>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div className="col-12 col-md-4 col-lg-4 mb-3">
                      <label
                        htmlFor={"[fecha_minuta_confirm]"}
                        className="form-label"
                      >
                        Fecha *
                      </label>
                      <DatePickerAtm
                        placeHolder={"Fecha"}
                        name={"[fecha_minuta_confirm]"}
                        maxDate={new Date()}
                        mandatory={true}
                        minDate={new Date(Config[8].anio + "/01/01")}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-7 text-end"></div>
                    <div className="col-12 col-md-3 col-lg-2 text-end">
                      <div className=" search-btn button mb-3">
                        <div className=" search-btn button mb-3">
                          <button
                            type="button"
                            className="btn bg-mandy btn-full-screen"
                            data-bs-dismiss="modal"
                          >
                            Cerrar
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-3 col-lg-3 text-end">
                      <div className=" search-btn button mb-3">
                        <input
                          type="submit"
                          className="btn btn-full-screen"
                          //defaultValue={"Siguiente"}
                          value={"Siguiente"}
                          //onClick={handleValidConfirmForm}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default FormSujetoPasivo;
