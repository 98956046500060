import React, { useEffect, useState } from "react";
//import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import Fetch from "../utils/Fetch";
//import Config from '../../data/config';
import Links from "../../data/link";
import TitlePage from "../utils/TitlePage";
import Texto from "../../data/es";
//import Ayuda from '../utils/Ayuda';

import "react-toastify/dist/ReactToastify.css";
//import { isForInStatement } from 'typescript';

import ModalPlaca from "./../component/Placa/ModalPlaca";
//import FormDatosVehiculo from  './FormDatosVehiculo';
import FormDatosVehiculo from "./FormDatosVehiculo";
import FormDatosPoseedor from "./FormDatosPoseedor";
import FormVistaPrevia from "./FormVistaPrevia";

const Create = (props) => {
  const titlePage = Texto.cambio_radicatoria;
  const fetch = new Fetch();
  fetch.setToast(toast);

  const [showForm, setShowForm] = useState(false);
  const [vehiculo, setVehiculo] = useState(undefined);
  const [cambioRadicatoriaDB, setCambioRadicatoriaDB] = useState(undefined);
  const [modalPlaca, setModalPlaca] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    let modalPlacaCreate = new window.bootstrap.Modal(
      document.getElementById("modalPlacaFull"),
      {
        keyboard: true,
        backdrop: "static",
        focus: true,
      }
    );
    modalPlacaCreate.show();
    setModalPlaca(modalPlacaCreate);
  }, []);

  const closeModal = (vehiculoData, message) => {
    modalPlaca.hide();
    document
      .getElementById("modalPlacaFull")
      .addEventListener("hidden.bs.modal", function (event) {
        console.log("modal se ha cerrado.....", vehiculoData);
        debugger;
        if (vehiculoData) {
          setShowForm(true);
          setVehiculo(vehiculoData);
        }
      });
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();

    window
      .jQuery("#" + event.target.getAttribute("id"))
      .parsley()
      .validate();

    let form_html = event.target; //event.target.getAttribute('id');
    const form = new FormData(event.target);
    var target = event.target;

    configureErrorMessageInputGroup();

    if (
      window
        .jQuery("#" + form_html.getAttribute("id"))
        .parsley()
        .isValid()
    ) {
      window.createBootbox("Esta Seguro de Continuar.", function (result) {
        if (result === true) {
          switch (form_html.getAttribute("id")) {
            case "formDatosVehiculos":
              submitFormDataVehiculos(form, target);
              break;

            case "formDatosPoseedor":
              submitFormDatosPoseedor(form, target);
              break;
            default:
              break;
          }
        }
      });
    }
  };

  const configureErrorMessageInputGroup = () => {
    if (document.getElementsByName("persona[expedido_en]").length > 0) {
      const ul_error_ci_ex = window
        .jQuery("input[name='persona[numero_documento]']")
        .parent()
        .parent()
        .find("ul");
      window
        .jQuery("input[name='persona[numero_documento]']")
        .parent()
        .parent()
        .find("ul")
        .remove();
      window
        .jQuery("input[name='persona[numero_documento]']")
        .parent("div")
        .parent("div")
        .append(ul_error_ci_ex);
    }

    const ul_error_email = window
      .jQuery("input[name='domicilio[email]']")
      .parent()
      .parent()
      .find("ul");
    window
      .jQuery("input[name='domicilio[email]']")
      .parent()
      .parent()
      .find("ul")
      .remove();
    window
      .jQuery("input[name='domicilio[email]']")
      .parent("div")
      .parent("div")
      .append(ul_error_email);

    const ul_error_ci = window
      .jQuery("input[name='apoderado[numero_documento]']")
      .parent()
      .parent()
      .find("ul");
    window
      .jQuery("input[name='apoderado[numero_documento]']")
      .parent()
      .parent()
      .find("ul")
      .remove();
    window
      .jQuery("input[name='apoderado[numero_documento]']")
      .parent("div")
      .parent("div")
      .append(ul_error_ci);

    const ul_error_adyacente_entre = window
      .jQuery("input[name='domicilio[adyacente_entre]']")
      .parent()
      .parent()
      .find("ul");
    window
      .jQuery("input[name='domicilio[adyacente_entre]']")
      .parent()
      .parent()
      .find("ul")
      .remove();
    window
      .jQuery("input[name='domicilio[adyacente_entre]']")
      .parent("div")
      .parent("div")
      .parent("div")
      .append(ul_error_adyacente_entre);

    const ul_error_adyacente_y = window
      .jQuery("input[name='domicilio[adyacente_y]']")
      .parent()
      .parent()
      .find("ul");
    window
      .jQuery("input[name='domicilio[adyacente_y]']")
      .parent()
      .parent()
      .find("ul")
      .remove();
    window
      .jQuery("input[name='domicilio[adyacente_y]']")
      .parent("div")
      .parent("div")
      .parent("div")
      .append(ul_error_adyacente_y);

    const ul_error_numero = window
      .jQuery("input[name='domicilio[numero]']")
      .parent()
      .parent()
      .find("ul");
    window
      .jQuery("input[name='domicilio[numero]']")
      .parent()
      .parent()
      .find("ul")
      .remove();
    window
      .jQuery("input[name='domicilio[numero]']")
      .parent("div")
      .parent("div")
      .append(ul_error_numero);
  };

  const submitFormDataVehiculos = (form, target) => {
    debugger
    form.append("vh_vehiculo[servicio]", vehiculo.datosTecnicos.servicio);
    form.append("vh_vehiculo[placa]", vehiculo.vehiculo.numeroPta);
    form.append("vh_vehiculo[esta_afiliado]", 0);
    form.append("vh_dato_tecnico[modelo]", vehiculo.datosTecnicos.modelo);
    form.append("vh_dato_tecnico[clase]", vehiculo.datosTecnicos.clase);
    form.append("vh_dato_tecnico[marca]", vehiculo.datosTecnicos.marca);
    form.append("vh_dato_tecnico[color]", vehiculo.datosTecnicos.color);
    form.append("vh_dato_tecnico[servicio]", vehiculo.datosTecnicos.servicio);
    form.append("vh_dato_tecnico[tipo_vehiculo]", vehiculo.datosTecnicos.tipo);
    form.append("vh_dato_tecnico[numero_plaza]", vehiculo.datosTecnicos.nroPlazas);
    form.append("csrf-token", window.getParameterByName("csrf-token"));

    //enviar todos los datos de vehiculos para
    fetch
      .fetchPost(form, "api/vehiculos/cambio-radicatoria/create", target)
      .then((dataJson) => {
        debugger;
        if (dataJson) {
          if (Boolean(dataJson.data)) {
            setCurrentPage(1);
            setCambioRadicatoriaDB(dataJson.data.cambioRadicatoria);
            toast.success(dataJson.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } else {
            toast.warn(dataJson.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        }
      });
  };

  const submitFormDatosPoseedor = (form, target) => {
    debugger;
    console.log(cambioRadicatoriaDB);
    form.append("vh_vehiculo[placa]", vehiculo.vehiculo.numeroPta);
    form.append("vh_cambio_radicatoria[token]", cambioRadicatoriaDB.token);

    fetch
      .fetchPost(form, "api/vehiculos/poseedor/create", target)
      .then((dataJson) => {
        debugger;
        if (dataJson && dataJson.status === true) {
          if (Boolean(dataJson.data)) {
            setCurrentPage(2);
            toast.success(dataJson.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } else {
            toast.warn(dataJson.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        }
      });
  };

  let breadcrumbs = [
    {
      title: Links[0].title,
      url: Links[0].url,
    },
    {
      title: Links[62].title,
      url: Links[62].url,
    },
  ];

  return (
    <section className="items-grid section mt-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={titlePage}
          breadcrumbs={breadcrumbs}
          pos
          ition={"left"}
          leftfull={false}
        />

        {!showForm ? <p>Esperando confirmación de Nro. de Placa...</p> : ""}

        {showForm && currentPage === 0 && vehiculo ? (
          <FormDatosVehiculo
            toast={toast}
            onSubmitForm={handleSubmitForm}
            datosTecnicos={vehiculo.datosTecnicos}
            numeroPlaca={vehiculo.placa}
            datosVehiculo={vehiculo.vehiculo}
            buttonName={"Siguiente"}
          />
        ) : (
          ""
        )}

        {showForm && currentPage === 1 && vehiculo ? (
          <FormDatosPoseedor
            toast={toast}
            onSubmitForm={handleSubmitForm}
            dataVehiculo={vehiculo.datosTecnicos}
            buttonName={"Siguiente"}
            nameForm="persona"
            nameFormDomicilio="domicilio"
            nameFormPoseedor="vh_poseedor"
          />
        ) : (
          ""
        )}

        {showForm && currentPage === 2 && vehiculo ? (
          <FormVistaPrevia
            toast={toast}
            onSubmitForm={handleSubmitForm}
            dataVehiculo={vehiculo.datosTecnicos}
            cambioRadicatoria={cambioRadicatoriaDB}
            buttonName={"Siguiente"}
          />
        ) : (
          ""
        )}

        {!showForm ? (
          <ModalPlaca
            toast={toast}
            typePlaca="CAMBIO_RADICATORIA"
            closeModal={closeModal}
          />
        ) : (
          ""
        )}
      </div>

      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </section>
  );
};

export default Create;
