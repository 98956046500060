import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
//import { useHistory } from 'react-router-dom'
import AsyncSelect from 'react-select/async';
import Texto from '../../data/es';
import Config from '../../data/config';
import { toast } from 'react-toastify';
import Constant from '../../data/constant';
import Fetch from '../utils/Fetch';
import Links from '../../data/link';
import TitlePage from '../utils/TitlePage';

const ActividadesEconomicas = (props) => {

    const derecho_admision_permanante = 1;
    const derecho_admision_temporal = 2;
    let optionsSelect = [];
    const titlePage = Texto.guia
    fetch = new Fetch();
    fetch.setToast(toast);

    const [showRequisitosActAco, setShowRequisitosActAco] = useState(true);
    const [showVideoActEco, setShowVideoActEco] = useState(false);
    const [tipoActividadEconomica, setTipoActividadEconomica] = useState(undefined);
    const [searchData, setSearchData] = useState(false);
    const [showDocumentTemporal, setShowDocumentTemporal] = useState(false);
    const [showDocumentPermanente, setShowDocumentPermanente] = useState(false);

    const refRequisitos = useRef();
    const refTutorial = useRef();

    const onSubmitForm = (event) => {
        event.preventDefault()
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleClickShowActividadEconomica = (event) => {
        event.preventDefault();
        setShowRequisitosActAco(true)
        setShowVideoActEco(false)
        refRequisitos.current.classList.add("active");
        refTutorial.current.classList.remove("active");
    }

    const handleClickShowVideo = (event) => {
        event.preventDefault();
        setShowRequisitosActAco(false)
        setShowVideoActEco(true)

        refRequisitos.current.classList.remove("active");
        refTutorial.current.classList.add("active");
    }

    const loadOptionPermanente = async (inputValue) => {
        if (inputValue.length > 2) {
            const response = await fetch.axiosAsyncGet(`api/tipo-actividad-economica/search-by-name-free/${inputValue}/${derecho_admision_permanante}`);
            if (response.status === true) {
                setShowDocumentTemporal(false);
                setShowDocumentPermanente(true);
                return response.data;
            } else
                return [];
        } else
            return optionsSelect
    }

    const noOptionsMessage = (props) => {
        let search = "";
        if ((parseInt(derecho_admision_permanante) === parseInt(Constant[0].derecho_admision.permanente)))
            search = Texto.ingresa_tipo_actividad_economica + " - " + Texto.permanente
        else
            search = Texto.ingresa_tipo_actividad_economica + " - " + Texto.temporal
        if (props.inputValue === '') {
            return (search);
        }
        return (Texto.criterio_busqueda_no_corresponde);
    }

    const loadOptionTemporal = async (inputValue) => {
        if (inputValue.length > 2) {
            const response = await fetch.axiosAsyncGet(`api/tipo-actividad-economica/search-by-name-free/${inputValue}/${derecho_admision_temporal}`);
            if (response.status === true) {
                setShowDocumentTemporal(true);
                setShowDocumentPermanente(false);
                return response.data;
            } else
                return [];
        } else
            return optionsSelect
    }

    const handleOnChange = (inputValue) => {
        if (inputValue && inputValue.ciiu) {
            setTipoActividadEconomica(undefined)
            setSearchData(true);
            const responseTipoActividadEconomica = fetch.axiosAsyncGet(`api/tipo-actividad-economica/search-by-ciiu/${inputValue.ciiu}`).then(dataJson => {
                if (dataJson && dataJson.status) {
                    console.log(responseTipoActividadEconomica);
                    setTipoActividadEconomica(dataJson.data)
                }
                setSearchData(false);
            });
        }
    }

    const downloadPdf = (event, url) => {
        event.preventDefault();
        window.location.href = url;
    }

    const [breadcrumbs, setBreadcrumbs] = useState([
        {
            title: Links[0].title,
            url: Links[0].url,
        },
        {
            title: Links[22].title,
            url: Links[22].url,
        },
        {
            title: Links[60].title,
            url: "#",
        }
    ]);

    return (

        <section className="items-grid section mt-5">
            <div className="container mt-1">
                <TitlePage titlePage={titlePage} breadcrumbs={breadcrumbs} position={'left'} leftfull={false} />

                <div className="nav-scroller bg-body shadow-sm bg-picton-blue mt-2" style={{ marginTop: -20 }}>
                    <nav className="nav nav-underline" aria-label="Secondary navigation">
                        <Link to={""} className={`nav-link active`} title={Texto.requisitos} ref={refRequisitos} onClick={handleClickShowActividadEconomica}>
                            Requisitos
                        </Link>
                        <Link to={""} className={`nav-link `} aria-current="page" title={Texto.tutorial} ref={refTutorial} onClick={handleClickShowVideo} >
                            Tutorial
                        </Link>
                    </nav>
                </div>

                {
                    showRequisitosActAco ?
                        <>
                            <div className="row mt-3" >
                                <div className="col-12 col-md-6  mb-3">
                                    <strong>DA88 - PERMANENTE </strong>
                                    <strong>Bs. 55</strong>
                                    <form className="contact__form needs-validation mt-3"
                                        method="post" noValidate onSubmit={onSubmitForm} style={{ width: '100%' }}>

                                        <AsyncSelect
                                            cacheOptions
                                            loadOptions={loadOptionPermanente}
                                            defaultOptions
                                            isClearable
                                            isSearchable
                                            placeholder={Texto.tipo_actividad_economica}
                                            required
                                            defaultValue={[optionsSelect[0]]}
                                            noOptionsMessage={noOptionsMessage}
                                            onChange={handleOnChange}
                                        />
                                    </form>
                                </div>

                                <div className="col-12 col-md-6  mb-3">
                                    <strong>DA89 - TEMPORAL </strong>
                                    <strong>Bs. 100</strong>

                                    <form className="contact__form needs-validation mt-3"
                                        method="post" noValidate onSubmit={onSubmitForm} style={{ width: '100%' }}>
                                        <AsyncSelect
                                            className=""
                                            cacheOptions
                                            loadOptions={loadOptionTemporal}
                                            defaultOptions
                                            isClearable
                                            isSearchable
                                            placeholder={Texto.tipo_actividad_economica}
                                            required
                                            defaultValue={[optionsSelect[0]]}
                                            noOptionsMessage={noOptionsMessage}
                                            onChange={handleOnChange}
                                        />
                                    </form>
                                </div>
                            </div>

                            <div className="row mt-3" >
                                <div className="col-12  ">
                                    <p className="text-justify">
                                        Para el llenado del formulario, previamente debe adquirir el Formulario Único de Recaudaciones (FUR), en caja recaudadora (GAMC) o entidades financieras autorizadas.
                                </p>
                                </div>
                            </div>

                            <div className="row mt-3">
                                {searchData ?
                                    <div className="col-12 mt-3 mb-3">
                                        <img className='rounded mx-auto d-block' alt='pulse-loading' src={"/static/img/pulse_200.gif"} width="70" />
                                    </div>
                                    : ""}
                            </div>

                            {
                                tipoActividadEconomica ?
                                    <>
                                        <div className="row  mt-3">
                                            <div className="col-12 ">
                                                <strong>Requisitos Adicionales</strong>
                                            </div>
                                        </div>

                                        <div className="row mt-3 ">
                                            <div className="col-12">
                                                {
                                                    parseInt(tipoActividadEconomica.emsa) === 1 ?
                                                        <p><i className="fas fa-check" aria-hidden="true"></i> EMSA</p>
                                                        : ""
                                                }
                                                {
                                                    parseInt(tipoActividadEconomica.plano_aprobado) === 1 ?
                                                        <p><i className="fas fa-check" aria-hidden="true"></i> Plano Aprobado</p>
                                                        : ""
                                                }
                                                {
                                                    parseInt(tipoActividadEconomica.plano_funcionamiento) === 1 ?
                                                        <p><i className="fas fa-check" aria-hidden="true"></i> Plano Funcionamiento</p>
                                                        : ""
                                                }
                                                {
                                                    parseInt(tipoActividadEconomica.certificado_registro_ambiental) === 1 ?
                                                        <p><i className="fas fa-check" aria-hidden="true"></i> Certificado Registro Ambiental (CRAM)</p>
                                                        : ""
                                                }
                                                {
                                                    parseInt(tipoActividadEconomica.licencia_ambiental) === 1 ?
                                                        <p><i className="fas fa-check" aria-hidden="true"></i> Licencia Ambiental (LA)</p>
                                                        : ""
                                                }
                                                {
                                                    parseInt(tipoActividadEconomica.registro_ambiental_industrial) === 1 ?
                                                        <p><i className="fas fa-check" aria-hidden="true"></i> Registro Media Ambiental (RAI)</p>
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                    </>
                                    : ""
                            }

                            {
                                showDocumentTemporal ?
                                    <>
                                        <div className="row mt-3">
                                            <div className="col-12 ">
                                                <strong>Documentos de Apoyo - Temporales</strong>
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-6 col-md-3 text-center">
                                                <Link to={"#"} title={Links[75].title} className="me-2 mb-3"
                                                    onClick={e => downloadPdf(e, Links[75].url)}>
                                                    <i class="far fa-file-pdf fa-3x" aria-hidden="true"></i><br />
                                                    Nuevo
                                                </Link>
                                            </div>

                                            <div className="col-6 col-md-3 text-center">
                                                <Link to={"#"} title={Links[76].title} className="me-2 mb-3"
                                                    onClick={e => downloadPdf(e, Links[76].url)}>
                                                    <i class="far fa-file-pdf fa-3x" aria-hidden="true"></i><br />
                                                    Renovación
                                                </Link>
                                            </div>

                                            <div className="col-6 col-md-3 text-center">
                                                <Link to={"#"} title={Links[67].title} className=" me-2 mb-3"
                                                    onClick={e => downloadPdf(e, Links[67].url)}>
                                                    <i class="far fa-file-pdf fa-3x" aria-hidden="true"></i><br />
                                                Cierre sin Deuda
                                            </Link>
                                            </div>

                                            <div className="col-6 col-md-3 text-center">
                                                <Link to={"#"} title={Links[71].title} className=" me-2 mb-3"
                                                    onClick={e => downloadPdf(e, Links[71].url)}>
                                                    <i class="far fa-file-pdf fa-3x" aria-hidden="true"></i><br />
                                                    Cierre con Deuda
                                                </Link>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    ""
                            }

                            {
                                showDocumentPermanente ?
                                    <>
                                        <div className="row mt-3">
                                            <div className="col-12 ">
                                                <strong>Documentos de Apoyo - Permanentes</strong>
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-6 col-md-3 text-center">
                                                <Link to={"#"} title={`Obtención de Nueva Licencia`} className=" me-2 mb-3"
                                                    onClick={e => downloadPdf(e, Config[0].url + "documents/download/req-act-eco-otorgacion-permante")}>
                                                    <i className="far fa-file-pdf fa-3x" aria-hidden="true"></i><br />
                                                    Nuevo
                                                </Link>
                                            </div>

                                            <div className="col-6 col-md-3 text-center">
                                                <Link to={"#"} title={`Renovación de Licencia`} className="me-2 mb-3"
                                                    onClick={e => downloadPdf(e, Links[66].url)}>
                                                    <i class="far fa-file-pdf fa-3x" aria-hidden="true"></i><br />
                                                    Renovación
                                                </Link>
                                            </div>

                                            <div className="col-6 col-md-3 text-center">
                                                <Link to={"#"} title={Links[67].title} className=" me-2 mb-3"
                                                    onClick={e => downloadPdf(e, Links[67].url)}>
                                                    <i class="far fa-file-pdf fa-3x" aria-hidden="true"></i><br />
                                                Cierre sin Deuda
                                            </Link>
                                            </div>

                                            <div className="col-6 col-md-3 text-center">
                                                <Link to={"#"} title={Links[71].title} className=" me-2 mb-3"
                                                    onClick={e => downloadPdf(e, Links[71].url)}>
                                                    <i class="far fa-file-pdf fa-3x" aria-hidden="true"></i><br />
                                                    Cierre con Deuda
                                                </Link>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    ""
                            }

                            {
                                showDocumentTemporal || showDocumentPermanente
                                    ?
                                    <div className="row mt-3">
                                        <div className="col-6 col-md-3 text-center">
                                            <Link to={"#"} title={Links[68].title} className=" me-2 mb-3"
                                                onClick={e => downloadPdf(e, Links[68].url)}>
                                                <i class="far fa-file-pdf fa-3x" aria-hidden="true"></i><br />
                                                    Fallecimiento
                                                </Link>
                                        </div>

                                        <div className="col-6 col-md-3 text-center">
                                            <Link to={"#"} title={Links[69].title} className=" me-2 mb-3"
                                                onClick={e => downloadPdf(e, Links[69].url)}>
                                                <i class="far fa-file-pdf fa-3x" aria-hidden="true"></i><br />
                                                    Duplicidad
                                                </Link>
                                        </div>

                                        <div className="col-6 col-md-3 text-center">
                                            <Link to={"#"} title={Links[70].title} className=" me-2 mb-3"
                                                onClick={e => downloadPdf(e, Links[70].url)}>
                                                <i class="far fa-file-pdf fa-3x" aria-hidden="true"></i><br />
                                                    Inconsistencia de Datos
                                                </Link>
                                        </div>

                                        <div className="col-6 col-md-3 ">
                                        </div>
                                    </div>
                                    : ""
                            }
                        </>
                        : ""
                }

                {
                    showVideoActEco ?
                        <div className="row">
                            <div className="col-12 mt-3">
                                <div className="recuros-block">
                                    <h6>Guía para el llenado de la DDJJ de Actividades Económicas</h6>
                                </div>

                                <div className="embed-container mt-4">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/i0moEU0GGrM" frameBorder="0" 
                                        title='Tutorial llenado Actividades Economicas'
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen  >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                        : ""
                }
            </div>
        </section >
    )
}

export default ActividadesEconomicas;