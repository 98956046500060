import React, { Component, useState, useRef } from 'react';
import Fetch from '../utils/Fetch';
import Constant from '../../data/constant';
import Config from '../../data/config';
import AuthService from '../Usuario/AuthService';
import Links from '../../data/link';
import TitlePage from '../utils/TitlePage';
import Texto from '../../data/es';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ModalPdfBasic from '../utils/ModalPdfBasic';

let breadcrumbs = [
    {
        title: Links[0].title,
        url: Links[0].url
    },
    {
        title: Links[21].title,
        url: Links[21].url
    }
];
const _notificaciones = undefined
const Notificacion = (props) => {

    const constant = Constant[0];
    const fetch = new Fetch();
    fetch.setToast(toast);
    const auth = new AuthService();

    const refModalPdfBasic = useRef();

    const titlePage = Texto.notificaciones
    const profile = auth.getProfile();

    const [showPlataforma, setShowPlataforma] = useState(false)
    const [showFiscalización, setShowFiscalización] = useState(false)
    const [showLegalTributario, setShowLegalTributario] = useState(false)
    const [showNormaTributaria, setShowNormaTributaria] = useState(false)

    const [showEdictosLegalTributario, setShowEdictosLegalTributario] = useState(false)
    const [showMasivasLegalTributario, setShowMasivaLegalTributario] = useState(false)

    const [notificacionesList, setNotificacionesList] = useState("")

    const onSubmitForm = (event) => {

    }

    const hanldeClickPrimaryPage = (event) => {
        event.preventDefault()
        setShowPlataforma(false)
        setShowFiscalización(false)
        setShowLegalTributario(false)

        setShowMasivaLegalTributario(false)
        setShowEdictosLegalTributario(false)

        setShowMasivaLegalTributario(false)
        setShowNormaTributaria(false)
    }

    const hanldeClickSecondPage = (event) => {
        event.preventDefault()
        setShowPlataforma(showPlataforma)
        setShowFiscalización(showFiscalización)
        setShowLegalTributario(showLegalTributario)

        setShowMasivaLegalTributario(false)
        setShowEdictosLegalTributario(false)
    }

    const showPlataformaClick = (event) => {
        setShowPlataforma(true)
        setShowFiscalización(false)
        setShowLegalTributario(false)
        setShowNormaTributaria(false)
    }

    const showFiscalizaciónClick = (event) => {
        setShowPlataforma(false)
        setShowFiscalización(true)
        setShowLegalTributario(false)
        setShowNormaTributaria(false)
    }

    const showLegalTributarioClick = (event) => {
        setShowPlataforma(false)
        setShowFiscalización(false)
        setShowLegalTributario(true)
        setShowNormaTributaria(false)

        breadcrumbs[1] = { title: Links[21].title, url: "", eventClick: hanldeClickPrimaryPage }
        breadcrumbs[2] = { title: "Legal Tributario", url: "" }
    }

    const showNormaTributariaClick = (event) => {
        debugger
        fetch.fetchGet('api/fiscalizacion/notification/NORMA_TRIBUTARIA/NORMA_TRIBUTARIA').then(dataJson => {
            if (dataJson !== undefined && dataJson.status === true) {
                if (Boolean(dataJson.Notificacion)) {
                    fetch.toast.success(dataJson.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    let cont = 0
                    let notificaciones = dataJson.Notificacion.map(({ created_at, notification }) => {
                        cont++
                        let sub_cont = 0
                        let body_noti = notification.map(({ id, created_at, name_notified, number, title }) => {
                            sub_cont++
                            return <div className="col-12 col-md-3 bottom-space" key={sub_cont}>
                                <div className="feature-box ">
                                    <i className="fa fa-file-pdf-o fa-5x fa-icon-image"></i>
                                    <h3 className="heading-tertiary u-margin-bottom-small pt-3"> {number}</h3>
                                    <p className="feature-box__text text-justify">{title}</p>
                                    <small className="feature-box__text text-center">{Texto.publicado + ' ' + Texto.el + ' ' + window.fechaToLiteral(created_at)}</small>

                                    <div className="feature-box-on">
                                        <p className="buttom-element btn-disabled" onClick={(e) => handleOpenFile(e, id)} id={id}><i className="fa fa-eye fa-3x fa-icon-image"></i></p>
                                        <p className="buttom-element btn-disabled" onClick={(e) => handleDownladFile(e, id)} id={id}><i className="fa fa-download fa-3x fa-icon-image" aria-hidden="true"></i></p>
                                    </div>
                                </div>
                            </div>
                        })

                        return <section className="container paddingTop30" key={cont}>
                            <div className="row  text-right  pt-4"  >
                                <div className="col-12" >
                                    <span className="pl-5" >{window.fechaToLiteral(created_at)}</span>
                                </div>
                            </div>
                            <div className="row paddingTop30" >
                                {body_noti}
                            </div>
                        </section>
                    })

                    setNotificacionesList(notificaciones)
                } else {
                    fetch.toast.warn(dataJson.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }

                breadcrumbs[1] = { title: Links[21].title, url: "", eventClick: hanldeClickPrimaryPage }
                breadcrumbs[2] = { title: "Legal Tributario", url: "" }

                setShowPlataforma(false)
                setShowFiscalización(false)
                setShowLegalTributario(false)
                setShowNormaTributaria(true)
            }
        })
    }

    const showLegalTributarioMasivasClick = (event) => {

        fetch.fetchGet('api/fiscalizacion/notification/MASIVAS/LEGAL_TRIBUTARIO').then(dataJson => {
            if (dataJson !== undefined && dataJson.status === true) {
                if (Boolean(dataJson.Notificacion)) {
                    fetch.toast.success(dataJson.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    let cont = 0
                    let notificaciones = dataJson.Notificacion.map(({ created_at, notification }) => {
                        cont++
                        let sub_cont = 0
                        let body_noti = notification.map(({ id, created_at, name_notified, number, title }) => {
                            sub_cont++
                            return <div className="col-12 col-md-3 bottom-space" key={sub_cont}>
                                <div className="feature-box ">
                                    <i className="fa fa-file-pdf-o fa-5x fa-icon-image"></i>
                                    <h3 className="heading-tertiary u-margin-bottom-small pt-3"> {number}</h3>
                                    <p className="feature-box__text text-justify">{title}</p>
                                    <small className="feature-box__text text-center">{Texto.publicado + ' ' + Texto.el + ' ' + window.fechaToLiteral(created_at)}</small>

                                    <div className="feature-box-on">
                                        <p className="buttom-element btn-disabled" onClick={(e) => handleOpenFile(e, id)} id={id}><i className="fa fa-eye fa-3x fa-icon-image"></i></p>
                                        <p className="buttom-element btn-disabled" onClick={(e) => handleDownladFile(e, id)} id={id}><i className="fa fa-download fa-3x fa-icon-image" aria-hidden="true"></i></p>
                                    </div>
                                </div>
                            </div>
                        })

                        return <section className="container paddingTop30" key={cont}>
                            <div className="row  text-right  pt-4"  >
                                <div className="col-12" >
                                    <span className="pl-5" >{window.fechaToLiteral(created_at)}</span>
                                </div>
                            </div>
                            <div className="row paddingTop30" >
                                {body_noti}
                            </div>
                        </section>
                    })

                    setNotificacionesList(notificaciones)
                } else {
                    fetch.toast.warn(dataJson.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }

                breadcrumbs[2] = { title: "Legal Tributario", url: "", eventClick: hanldeClickSecondPage }
                breadcrumbs[3] = { title: "Edictos", url: "" }

                setShowMasivaLegalTributario(true)
                setShowEdictosLegalTributario(false)

                setShowPlataforma(false)
                setShowFiscalización(false)
                setShowLegalTributario(false)
            }
        })
    }

    const showLegalTributarioEdictosClick = (event) => {

        fetch.fetchGet('api/fiscalizacion/notification/EDICTOS/LEGAL_TRIBUTARIO').then(dataJson => {
            if (dataJson !== undefined && dataJson.status === true) {
                if (Boolean(dataJson.Notificacion)) {
                    fetch.toast.success(dataJson.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    let cont = 0
                    let notificaciones = dataJson.Notificacion.map(({ created_at, notification }) => {

                        cont++
                        let sub_cont = 0
                        let body_noti = notification.map(({ id, created_at, name_notified, number, title }) => {
                            sub_cont++
                            return <div className="col-12 col-md-3 bottom-space" key={sub_cont}>
                                <div className="feature-box ">
                                    <i className="fa fa-file-pdf-o fa-5x fa-icon-image"></i>
                                    <h3 className="heading-tertiary u-margin-bottom-small pt-3"> {number}</h3>
                                    <p className="feature-box__text text-justify">{title}</p>
                                    <small className="feature-box__text text-center">{Texto.publicado + ' ' + Texto.el + ' ' + window.fechaToLiteral(created_at)}</small>
                                    <div className="feature-box-on">
                                        <p className="buttom-element btn-disabled" onClick={(e) => handleOpenFile(e, id)} id={id}><i className="fa fa-eye fa-3x fa-icon-image"></i></p>
                                        <p className="buttom-element btn-disabled" onClick={(e) => handleDownladFile(e, id)} id={id}><i className="fa fa-download fa-3x fa-icon-image" aria-hidden="true"></i></p>
                                    </div>
                                </div>
                            </div>
                        })

                        return <section className="container paddingTop30" key={cont}>
                            <div className="row  text-right  pt-4" >
                                <div className="col-12" >
                                    <span className="pl-5" >{window.fechaToLiteral(created_at)}</span>
                                </div>
                            </div>
                            <div className="row paddingTop30" >
                                {body_noti}
                            </div>
                        </section>
                    })

                    setNotificacionesList(notificaciones)
                } else {
                    fetch.toast.warn(dataJson.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }

                breadcrumbs[2] = { title: "Legal Tributario", url: "", eventClick: hanldeClickSecondPage }
                breadcrumbs[3] = { title: "Edictos", url: "" }

                setShowEdictosLegalTributario(true)
                setShowMasivaLegalTributario(false)

                setShowPlataforma(false)
                setShowFiscalización(false)
                setShowLegalTributario(false)
            }
        })
    }

    const handleOpenFile = (event, id) => {
        event.preventDefault()
        refModalPdfBasic.current.openModal(true, `legal-tributario/document/${id}`, `legal-tributario/document-download/${id}`)
    }

    const handleDownladFile = (event, id) => {
        event.preventDefault()
        window.location.href = Config[0].url + `legal-tributario/document-download/${id}`;
    }

    return (
        <div className="paddingTop30" id="features" >
            <TitlePage titlePage={titlePage} breadcrumbs={breadcrumbs} position={'left'} leftfull={false} />


            <div className="btn-group dropleft float-right menu-breadcrumb">
                <a className=" dropdown-toggle " type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
                <div className="dropdown-menu">
                    <a className="dropdown-item" href="#" onClick={showPlataformaClick}>{Texto.plataforma}</a>
                    <a className="dropdown-item" href="#" onClick={showFiscalizaciónClick} >{Texto.fiscalizacion}</a>
                    <a className="dropdown-item" href="#" onClick={showLegalTributarioClick}>{Texto.legal_tributario}</a>
                    <a className="dropdown-item" href="#" onClick={showNormaTributariaClick}>{Texto.norma_tributaria}</a>
                </div>
            </div>

            <div className="container features " style={{ marginTop: '-30px' }}  >
                {!showPlataforma && !showFiscalización && !showLegalTributario && !showEdictosLegalTributario && !showMasivasLegalTributario && !showNormaTributaria ?
                    <section className="" style={{ padding: 15 }}>
                        <div className="row">
                            <div className="col-12 col-md-0 col-lg-2"  >
                            </div>
                            <div className="col-12 col-md-6 col-lg-4" onClick={showPlataformaClick} >
                                <div className="button-big button-big-dos " >
                                    <div className="left"><h3>{Texto.plataforma}</h3>
                                        <span>Elementos constitutivos de relación obligatoría</span></div>
                                    <div className="right"><i className="fa fa-file-text-o" aria-hidden="true"></i></div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4" onClick={showFiscalizaciónClick} >
                                <div className="button-big button-big-dos " >
                                    <div className="left"><h3>{Texto.fiscalizacion}</h3>
                                        <span>Actuados y Edictos</span></div>
                                    <div className="right"><i className="fa fa-envelope-o" aria-hidden="true"></i></div>
                                </div>
                            </div>
                            <div className="col-12 col-md-0 col-lg-2"  >
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-0 col-lg-2"  >
                            </div>
                            <div className="col-12 col-md-6 col-lg-4" onClick={showLegalTributarioClick} >
                                <div className="button-big button-big-dos ">
                                    <div className="left"><h3>{Texto.legal_tributario}</h3>
                                        <span>Actuados y Edictos</span></div>
                                    <div className="right"><i className="fa fa-envelope-o" aria-hidden="true"></i></div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-4"  >
                                <div className="button-big button-big-dos " onClick={showNormaTributariaClick}>
                                    <div className="left"><h3>{Texto.norma_tributaria}</h3>
                                        <span></span></div>
                                    <div className="right"><i className="fa fa-file-text-o" aria-hidden="true"></i></div>
                                </div>
                            </div>
                            <div className="col-12 col-md-0 col-lg-2"  >
                            </div>
                        </div>
                    </section> : ""
                }

                {/* mostramos los dos botones que pertenecen a legal tributario */}
                {showLegalTributario && !showPlataforma && !showFiscalización ?
                    <section className=" " style={{ padding: 15 }}>
                        <div className="row">
                            <div className="col-12 col-md-2 col-lg-2"  >
                            </div>
                            <div className="col-12 col-md-4 col-lg-4" onClick={showLegalTributarioMasivasClick} >
                                <div className="button-big button-big-dos " >
                                    <div className="left"><h3>Masiva</h3>
                                        <span>Listado de personas notificadas</span></div>
                                    <div className="right"><i className="fa fa-envelope-o" aria-hidden="true"></i></div>
                                </div>
                            </div>

                            <div className="col-12 col-md-4 col-lg-4" onClick={showLegalTributarioEdictosClick} >
                                <div className="button-big button-big-dos ">
                                    <div className="left"><h3>Individual</h3>
                                        <span>Personas notificadas</span></div>
                                    <div className="right"><i className="fa fa-envelope-o" aria-hidden="true"></i></div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 col-lg-2"  >
                            </div>
                        </div>
                    </section>
                    : ""
                }

                {showEdictosLegalTributario && !showMasivasLegalTributario ?
                    <section className="container-bb-vine  ">
                        <form action="" className="contact__form needs-validation" name="formSearchNotification" id="formSearchNotification"
                            method="post" noValidate onSubmit={onSubmitForm} style={{ width: '100%' }}>
                        </form>
                    </section> : ""
                }

                {showEdictosLegalTributario && !showMasivasLegalTributario ?
                    <>{notificacionesList}</>
                    : ""
                }

                {!showEdictosLegalTributario && showMasivasLegalTributario ?
                    <>{notificacionesList}</>
                    : ""
                }

                {showNormaTributaria ?
                    <>{notificacionesList}</> : ""
                }
            </div>

            <div className="row">
                <ModalPdfBasic ref={refModalPdfBasic} />
            </div>
        </div>
    );
}

export default Notificacion;