import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom"; // useHistory
import Config from "../../data/config";
import Texto from "../../data/es";
import Links from "../../data/link";
import "./../style/style-simat.css";
import TitlePage from "../utils/TitlePage";

const HomeFuncionamiento = (props) => {
    const titlePage = Texto.licencia_actividad_economica;
    const history = useHistory(); // <-- useHistory

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const breadcrumbs = [
        {
            title: Links[0].title,
            url: Links[0].url,
        },
        {
            title: Links[128].title,
            url: Links[128].url,
        },

        {
            title: Links[139].title,
            url: Links[139].url,
        }
    ];

    const handleButtonRedirect = (e, action) => {
        debugger
        if (action === "REQUISITOS")
            history.push(Links[140].url);
        if (action === "DDJJ")
            history.push(Links[1].url);

        if (action === "MIXTAS")
            history.push("/mixtas?liquidacion=patentes-actividad");

        if (action === "CONSULTA_DEUDA") {
            // history.push(Links[92].url)
            window.open("https://www.ruat.gob.bo/actividadeseconomicas/consultageneral/InicioBusquedaActividadesEconomicas.jsf", '_blank')
        }

        if (action === "PAGO_QR") {
            window.open(Links[92].url)
        }

        if (action === "PAGO_QR_OTROS") {
            window.open("https://www.ruat.gob.bo/ingresosnotributarios/consultapago/InicioBusquedaIngresosNoTributarios.jsf?cGM=GM-CBBA")
        }

        if (action === "MULTAS_OTROS") {
            window.open("https://www.ruat.gob.bo/pagosqr/InicioPagosQrIngNoTrib.jsf?SDG3WF24=9", '_blank')
        }
    };


    return (
        <section className="items-grid section mt-5 mb-5 bg-gray">
            <TitlePage
                titlePage={titlePage}
                breadcrumbs={breadcrumbs}
                position={"left"}
                leftfull={false}
            />

            <div className="container mt-4">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 ">
                        <img
                            className="mx-auto d-block"
                            style={{ minWidth: '100px', color: '#0099CC' }}
                            alt="img-licencia-funcionamiento"
                            src={Config[2].url + "/static/img/offcanvas/funcionamiento-cyan.png"}
                        />
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-md-4 col-lg-4 col-sm-12 glyphicon-align-center mt-4">
                        <div className="btn-publicity slide_diagonal" title={Links[130].title} onClick={(e) => handleButtonRedirect(e, "DDJJ")}>
                            <img src={Config[2].url + "/static/img/offcanvas/ddjj.png"} alt="Icono" className="icono-imagen mx-auto d-block " style={{ maxWidth: 110 }} />
                            <div className="text-center">
                                Declaración Jurada
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-4 col-sm-12 glyphicon-align-center mt-4">
                        <div className="btn-publicity slide_diagonal" title={'Pagos Qr'} onClick={(event) => handleButtonRedirect(event, "PAGO_QR")}>
                            <img src={Config[2].url + "/static/img/offcanvas/qr_pago_atm.png"} alt="Icono" className="icono-imagen mx-auto d-block " />

                            <div className="text-center">
                                Pagos Qr
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-4 col-sm-12 glyphicon-align-center mt-4">
                        <div className="btn-publicity slide_diagonal " title={'Pagos Qr'} onClick={(event) => handleButtonRedirect(event, "PAGO_QR")}>
                            {/* <img src={Config[2].url + "/static/img/offcanvas/qr_pago_atm.png"} alt="Icono" className="icono-imagen mx-auto d-block " /> */}

                            <div className="text-center icono-imagen mx-auto d-block mt-4 mb-4 ">
                                <i className="fas fa-print fa-4x mx-auto d-block" alt="icono"></i>
                            </div>
                            <div className="text-center">
                                Imprimir Pagos
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-4 col-sm-12 glyphicon-align-center mt-4">
                        <div className="btn-publicity slide_diagonal" title={'Consulta tu Deuda'} onClick={(e) => handleButtonRedirect(e, "CONSULTA_DEUDA")}>
                            <img src={Config[2].url + "/static/img/offcanvas/consulta_deuda.png"} alt="Icono" className="icono-imagen mx-auto d-block " />
                            <div className="text-center">
                                Consulta tu Deuda
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-4 col-sm-12 glyphicon-align-center mt-4">
                        <div className="btn-publicity slide_diagonal" title={'Liquidaciones Mixtas'} onClick={(e) => handleButtonRedirect(e, "MIXTAS")}>
                            <div className="text-center icono-imagen mx-auto d-block mt-4 mb-4 ">
                                <i className="fas fa-envelope-open-text fa-4x mx-auto d-block" alt="icono"></i>
                            </div>
                            <div className="text-center">
                                Liquidaciones Mixtas
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-4 col-sm-12 glyphicon-align-center mt-4">
                        <div className="btn-publicity slide_diagonal" title="Requisitos" onClick={(e) => handleButtonRedirect(e, "REQUISITOS")}>
                            <img src={Config[2].url + "/static/img/offcanvas/requirement.png"} alt="Icono" className="icono-imagen mx-auto d-block " />
                            <div className="text-center">Requisitos</div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-4 col-sm-12 glyphicon-align-center mt-4" title="Multas y Otros" onClick={(e) => handleButtonRedirect(e, "MULTAS_OTROS")}>
                        <div className="btn-publicity slide_diagonal">
                            {/* <img src={Config[2].url + "/static/img/offcanvas/qr_pago_atm.png"} alt="Icono" className="icono-imagen mx-auto d-block " />
                            <div className="text-center">Multas y Otros</div> */}
                            <div className="text-center icono-imagen mx-auto d-block mt-4 mb-4 ">
                                <i className="fas fa-money-check-alt fa-4x mx-auto d-block" alt="icono"></i>
                            </div>
                            <div className="text-center">
                                Multas y Otros
                            </div>

                        </div>

                    </div>

                    <div className="col-md-4 col-lg-4 col-sm-12 glyphicon-align-center mt-4">
                        <div className="btn-publicity slide_diagonal " title={'Pagos Qr'} onClick={(event) => handleButtonRedirect(event, "PAGO_QR_OTROS")}>

                            <div className="text-center icono-imagen mx-auto d-block mt-4 mb-4 ">
                                <i className="fas fa-print fa-4x mx-auto d-block" alt="icono"></i>
                            </div>
                            <div className="text-center">
                                Imprimir Pagos Multas y Otros
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default HomeFuncionamiento;
