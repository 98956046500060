import React, { useState, useEffect } from 'react';
import Constant from '../../data/constant';
import { useHistory } from 'react-router-dom'
import Config from '../../data/config';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Links from '../../data/link';
import Texto from '../../data/es';
import CustomLoader from '../utils/SpinnerDataTable'
import Fetch from '../../components/utils/Fetch';
import AuthService from '../../components/Usuario/AuthService';
import TitlePage from '../../components/utils/TitlePage';
import ModalPdf from '../utils/ModalPdf';

const RawHtml = ({ children = "", tag: Tag = 'div', ...props }) =>
    <Tag {...props} dangerouslySetInnerHTML={{ __html: children }} />;


const SampleStyle = styled.div`
  padding: 10px;
  display: block;
  width: 100%;
  p {
    font - size: 12px;
    word-break: break-all;
  }
`;

const IntentExpandedComponent = ({ data }) => (
    <SampleStyle>
        <div className="row">
          <div className="col-12 col-md-4 mb-3">
            <span>
              <strong>{Texto.certificacion.toUpperCase()} </strong>
            </span>
            <hr className="mt-0 " />
            <p>
              <strong>{Texto.numero_copias}: </strong>
              {data.certificado_nro_copias_contribuyente}
            </p>
            <p>
              <strong>{Texto.numero_copias_funcionario_resumida}: </strong>
              {data.certificado_nro_copias_funcionario_resumida}
            </p>
            <p>
              <strong>{Texto.numero_copias_funcionario_detallada}: </strong>
              {data.certificado_nro_copias_funcionario_detallada}
            </p>
          </div>
          <div className="col-12 col-md-4">
            <span>
              <strong>{Texto.inmueble.toUpperCase()} </strong>
            </span>
            <hr className="mt-0 " />
            <p>
              <strong>{Texto.nro_inmueble}: </strong>
              {data.propiedad_numero_inmueble}
            </p>
          </div>

          <div className="col-12 col-md-4">
            <span>
              <strong>{Texto.contribuyente.toUpperCase()} </strong>
            </span>
            <hr className="mt-0 " />
            <p>
              <strong>{Texto.contribuyente}: </strong>
              {data.certificado_titular === '1' ? Texto.titular: Texto.tercero_responsable}
            </p>
            <p>
              <strong>{Texto.nombre_completo}: </strong>
              {data.apoderado_nombre_completo}
            </p>
            <p>
              <strong>{Texto.numero_documento}: </strong>
              {data.apoderado_numero_documento}
            </p>
            <p>
              <strong>{Texto.numero_poder}: </strong>
              {data.apoderado_numero_poder}
            </p>
            <p>
              <strong>{Texto.fecha_poder}: </strong>
              {data.apoderado_fecha_poder}
            </p>
          </div>
        </div>
    </SampleStyle>
);


const CertificacionAdm = (props) => {

    //const constant = Constant[0];
    const titlePage = Texto.certificacion;
    const fetch = new Fetch();
    fetch.setToast(toast);
    const auth = new AuthService();
    const history = useHistory();

    const modalPdf = new ModalPdf()
    modalPdf.setToast(toast)

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [typeSearch, setTypeSearch] = useState(0);

    useEffect(() => {
        if (auth.loggedIn()) {
            //aqui verificar si el usuario tiene los permisos respectivos
            let userAuth = auth.getProfile();
            if( userAuth.code === Constant[0].grupos.funcionario || userAuth.code === Constant[0].grupos.administrador ){
                getDataCertificaciones();
            }
        } else
            window.location.href = Links[4].url
        window.jQuery(".sc-kAzzGY").remove()  //pertenece al datatable
    }, []);

    const getDataCertificaciones = async () => {
        setLoading(true);
        const response = await fetch.axiosAsyncGet(`api/certificacion/per-page/1/${perPage}/${typeSearch}`);
        if (response && response.status) {
            setData(response.data);
            setTotalRows(response.total);
            setLoading(false);

            document.getElementById("pEnProceso").innerHTML = "En Proceso - " + response.enProceso;
            document.getElementById("pRapido").innerHTML = "Rápidos - " + response.resumida;
            document.getElementById("pCompletado").innerHTML = "Completados - " + response.completado;

            toast.success(response.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    const handleOpenCertificacion = (event) => {
        event.preventDefault();
        const form = new FormData();
        fetch.fetchPost(form, 'api/int/integracion-contribuyentes-certificados', undefined).then(dataJson => {
            if (dataJson && dataJson.status)
                history.push(Links[50].url + "/inm?csrf-token=" + dataJson.csrfToken + "&expiraEn=" + dataJson.expiraEn + "&lo=" + dataJson.lo + "&preview=1&adm=1");
        })
    }

    const handlePageChange = async page => {
        setLoading(true);
        const response = await fetch.axiosAsyncGet(`api/certificacion/per-page/${page}/${perPage}/${typeSearch}`);
        if (response && response.status) {
            setData(response.data);
            setCurrentPage(page);
            setLoading(false);
        }
    };

    const handlePerRowsChange = async (perPage, page) => {
        setLoading(true);
        const response = await fetch.axiosAsyncGet(`api/certificacion/per-page/${page}/${perPage}/${typeSearch}`);
        if (response && response.status) {
            setData(response.data);
            setPerPage(perPage);
            setCurrentPage(page);
            setLoading(false);
        }
    };

    const handleSubmitSearchForm = (event) => {
        event.preventDefault()
        const form = new FormData(event.target);
        setLoading(true);

        let input = window.jQuery(event.target).find('input').val()
        if (input.length > 0) {
            fetch.fetchPost(form, `api/certificacion/inmueble/search`).then(res => {
                if (res !== undefined && res.status === true) {
                    setLoading(false);
                    setData(res.data);
                } else {
                    setLoading(false);
                    setData([]);
                }
            })
        } else
            window.location.reload();
    }

    const hanldeSearchLicencias = async (event, typeSearchInt) => {
        setLoading(true);
        const response = await fetch.axiosAsyncGet(`api/certificacion/per-page/1/${perPage}/${typeSearchInt}`);
        if (response && response.status) {
            setData(response.data);
            setLoading(false);
            setTotalRows(response.total);
            setTypeSearch(typeSearchInt);
            toast.success(response.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    const handleDownloadCertificacionResumidaClick = (row) => {
        debugger
        fetch.fetchGet('api/inmCertificado/by-token/' + row.certificado_token).then(dataJson => {
            if (dataJson && dataJson.status) {
                debugger
                if (parseInt(dataJson.copia_funcionario_resumida) < 2) {
                    const url = `${Config[0].url}report/certificacion-generar-resumida/${row.propiedad_numero_inmueble}/${row.propiedad_codigo_propiedad}/${row.certificado_token}`;
                    modalPdf.showPdf(`${url}/preview/${row.zona}?auth=${auth.getToken()}`, "Datos del Inmueble: " + row.propiedad_numero_inmueble, row.token, undefined, `${url}/download?auth=${auth.getToken()}`);
                } else {
                    toast.warning("Número maximo de intentos superado.", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            }
        })
    }

    const handleDownloadCertificacionDetalladaClick = async (row) => {
        setLoading(true);
        fetch.fetchGet('api/inmCertificado/by-token/' + row.certificado_token).then(async dataJson => {
            if (dataJson && dataJson.status) {
                debugger
                if (parseInt(dataJson.copia_funcionario_detallada) < 2) {
                    const url = `${Config[0].url}report/certificacion-generar/${row.propiedad_numero_inmueble}/${row.propiedad_codigo_propiedad}/${row.certificado_token}`
                    modalPdf.showPdf(`${url}/preview/${row.zona}?auth=${auth.getToken()}`, "Datos del Inmueble: " + row.propiedad_numero_inmueble, row.token, undefined, `${url}/download?auth=${auth.getToken()}`);
            
                    const response = await fetch.axiosAsyncGet(`api/certificacion/per-page/${currentPage}/${perPage}/${typeSearch}`);
                    if (response && response.status) {
                        setData(response.data);
                    }
                }else {
                    toast.warning("Número maximo de intentos superado.", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
                setLoading(false);
            }
        })
    }
   
    const breadcrumbs = [
        {
            title: Links[0].title,
            url: Links[0].url
        },
        {
            title: Links[49].title,
            url: Links[49].url
        }
    ];

    const columns = [
        {
            name: Texto.nro_inmueble,
            selector: 'propiedad_numero_inmueble',   //npm uninstall <moduleName> --save 
            sortable: true,
            grow: 1,
            maxWidth: '100px',
        },
        {
            name: Texto.nro_orden,
            selector: 'certificado_nro_orden',
            sortable: true,
            grow: 1,
            maxWidth: '200px',
        },
        {
            name: Texto.numero_fur,
            selector: 'certificado_fur',
            sortable: true,
            maxWidth: '100px'
        },
        {
            name: Texto.fecha_certificacion,
            selector: 'certificado_fecha_certificacion',
            sortable: true,
            hide: 'md',
            maxWidth: '150px'
        },
        {
            name: Texto.fecha_vencimiento,
            selector: 'certificado_fecha_vencimiento',
            sortable: true,
            hide: 'md',
            maxWidth: '150px'
        },
        {
            name: Texto.estado,
            center: true,
            sortable: true,
            maxWidth: '100px',
            cell: row =>
                <RawHtml tag={'span'} >
                    {row.certificado_icon_estado}
                </RawHtml>
            ,
            ignoreRowClick: true,
            allowOverflow: true,
        },
        {
            name: 'Creado',
            selector: 'certificado_created_at',
            sortable: true,
            hide: 'md',
            maxWidth: '150px',
        },
        {
            name: '',
            sortable: true,
            cell: row => <div className="btn-group dropstart">
                <span data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fas fa-ellipsis-v ps-2 pe-2"></i>
                </span>
                <ul className="dropdown-menu">
                    <li>
                        <Link to="#" className="dropdown-item" onClick={() => handleDownloadCertificacionResumidaClick(row)} title={`Descargar Certificación Resumida - ` + row.numero}  >
                            Resumida
                        </Link>
                    </li>
                    <li>
                        <Link to="#" className="dropdown-item" onClick={() => handleDownloadCertificacionDetalladaClick(row)} title={`Descargar Certificación Detallada - ` + row.numero}  >
                            Detallada
                        </Link>
                    </li>
                </ul>
            </div>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];

    return (
        <section className="items-grid section mt-5">
            <div className="container mt-4">
                <TitlePage titlePage={titlePage} breadcrumbs={breadcrumbs} position={'left'} />

                <section className="panel-menu-info">
                    <div className="panel-menu-info-content">
                        <div className="row">
                            <div className="col-4 col-md-2 col-lg-2 mb-3">
                                <div className="single-contact-info pointer" onClick={e => hanldeSearchLicencias(e, 1)}>
                                    <i className="fas fa-cog" aria-hidden="true"></i>
                                    <p id="pEnProceso">En Proceso</p>
                                </div>
                            </div>

                            <div className="col-4 col-md-2 col-lg-2 mb-3">
                                <div className="single-contact-info pointer" onClick={e => hanldeSearchLicencias(e, 2)}>
                                    <i className="fas fa-check" aria-hidden="true"></i>
                                    <p id="pRapido">Resumida</p>
                                </div>
                            </div>
                            <div className="col-4 col-md-2 col-lg-2 mb-3">
                                <div className="single-contact-info pointer" onClick={e => hanldeSearchLicencias(e, 3)}>
                                    <i className="fas fa-check-double" aria-hidden="true"></i>
                                    <p id="pCompletado">Completado</p>
                                </div>
                            </div>

                            <div className="col-4 col-md-2 col-lg-2 mb-3">
                            </div>

                            <div className="col-4 col-md-2 col-lg-2 mb-3">
                                <div className="single-contact-info pointer" onClick={handleOpenCertificacion}>
                                    <i className="far fa-file" aria-hidden="true"></i>
                                    <p>Nueva Certificación</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <form action="" className="contact__form center-login" name="formSearchDataTable" id="formSearchDataTable"
                        method="post" noValidate onSubmit={handleSubmitSearchForm} >
                        <div className="row" style={{ textAlign: 'right', marginRight: '0px', marginLeft: '0px' }}>
                            <div className="col-12 col-md-4 col-lg-6 ">
                            </div>

                            <div className="col-12 col-md-4 col-lg-6 ">
                                <div className="input-group mb-3">
                                    <select className="form-select " name="type" required data-parsley-required="true" >
                                        <option key={0} value="NUMERO">Número de Orden</option>
                                        <option key={1} value="FUR">Número de FUR</option>
                                    </select>
                                    <input type="text" name="search" id="search" className="form-control" placeholder="*" aria-label="Username" />
                                    <button className="btn btn-outline-secondary" type="submit" data-toggle="tooltip" data-placement="top"
                                        title="Buscar"><i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>

                <section>
                    <DataTable
                        title={titlePage}
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        highlightOnHover
                        noDataComponent={Texto.there_are_no_records_to_display}
                        progressComponent={<CustomLoader />}
                        expandableRowsComponent={<IntentExpandedComponent />}
                        expandableRows
                    />

                    <ModalPdf />
                </section>
            </div>            
        </section>
    )
}

export default CertificacionAdm;
