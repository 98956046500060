import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Fetch from '../../../components/utils/Fetch';
import Links from '../../../data/link';
import Texto from '../../../data/es';
import TitleHeaderPage from '../Utils/TitleHeaderPage';

class ConfirmEmailReset extends Component {
    constructor(props) {
        super(props);

        this.fetch = new Fetch();
        this.fetch.setToast(toast);

        this.state = {
            loading: true,
            error: null,
            successMessage: null,
        };
    }

    // constructor
    componentDidMount() {
        this.confirmEmail();
    }

    confirmEmail() {
        const token = window.getParameterByName('token');
        const tokenchange = window.getParameterByName('tokenchange');
        const expires = window.getParameterByName('expiresat');
        const response = this.fetch.fetchGet(`api/password/reset/validate-email-link/${token}/${tokenchange}/${expires}`);

        response.then(res => {
            if (res && res.status === true) {
                toast.success(res.message);
                this.setState({ loading: false, successMessage: res.message });
            } else {
                toast.error(res.message);
                this.setState({ loading: false, error: "" });
            }
        }).catch(error => {
            this.setState({ loading: false, error: Texto.network_error });
        });
    }

    handleContactanosClick = (event) => {
        event.preventDefault();
        window.jQuery("#modalFormContacts").modal("show");
    };

    closeWindow = () => {
        window.open('','_parent',''); 
        window.close();
    };

    render() {
        const { loading, error, successMessage } = this.state;

        return (
            <div className="section mt-5">
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-md-10 col-sm-12 col-lg-5 mx-auto d-block">
                            <div className="card card-minw-25">
                                <div className="card-body">
                                    <TitleHeaderPage
                                        titlePage={'Verificación de Correo Electrónico'}
                                        description={''}
                                        visible={false}
                                    >

                                    </TitleHeaderPage>

                                    <div className="text-center mb-3">
                                        <button className="btn">
                                            {this.state.error === null ?
                                                <i className='fa fa-check-circle fa-4x text-success'></i>
                                                :
                                                <i className='fa fa-exclamation-circle fa-4x text-danger'></i>
                                            }
                                        </button>
                                    </div>

                                    <div id="features" className="text-center features-caption-custom features">
                                        {loading && <p>Cargando...</p>}
                                        {error && <p className='text-center mb-2'>{error}</p>}
                                        {successMessage &&
                                            <>
                                                <p className='text-center mb-2'><b>{successMessage}</b></p>

                                                <p className='text-center'>
                                                    <button className='btn btn-dark' onClick={this.closeWindow}>Cerrar</button>
                                                </p>
                                            </>
                                        }
                                    </div>

                                    {!loading &&
                                        <p className='text-justify mb-2 mt-2'>
                                            En caso de haber expirado el enlace de verificación. Vuelva a solicitar un nuevo o comuníquese con el
                                            <Link to={{ pathname: Links[11].url }} className="ps-1 pe-1">centro de ayuda</Link>
                                            o envíenos una solicitud mediante el
                                            <Link to={{ pathname: '#' }} className="ps-1 pe-1" onClick={this.handleContactanosClick}>formulario de contacto</Link>
                                            para comunicarse con nuestro equipo.
                                        </p>
                                    }
                                </div>

                                <ToastContainer
                                    enableMultiContainer
                                    containerId={'toast-email'}
                                    position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover />
                                <ToastContainer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmEmailReset;
