
import React from "react";
import PropTypes from "prop-types";

const InfoPublicidad = (props) => {

    const { data, Texto, formatDateDMY , contribuyente} = props;

    return (
        <>
            <span>
                <strong>{Texto.publicidad.toUpperCase()} </strong>
            </span>
            <hr className="mt-0 " />
            <p>
                <strong>{Texto.numero_orden}: </strong>
                {data.numero}
            </p>
            <p>
                <strong>{Texto.numero_fur}: </strong>
                {data.fur}
            </p>

            <p>
                <strong>{Texto.contribuyente}: </strong>
                
                {contribuyente(data.AdTitulars)}
            </p>
            

            <p>
                <strong>{Texto.tipo}: </strong>
                {data.clasificationCategoriesAdv[0].clasificationCategoryPublicity.clasification.name}
            </p>
            <p>
                <strong>{Texto.categoria}: </strong>
                {data.clasificationCategoriesAdv[0].clasificationCategoryPublicity.categoryPublicity.name}
            </p>

            <p>
                <strong>{Texto.usuario}: </strong>
                {data.username}
            </p>
            <p>
                <strong>{Texto.creado}: </strong>
                {window.moment(data.created_at).format("DD/MM/YYYY HH:mm")}
            </p>
            <p>
                <strong>{Texto.en_revision}: </strong>
                {data.fecha_aprobacion}
            </p>
        </>
    );
}


InfoPublicidad.prototype = {
    data: PropTypes.object.isRequired,
    // descripcion: PropTypes.string.isRequired,
    // clasificador:PropTypes.string,
    // categoria:PropTypes.string
};

export default InfoPublicidad;
