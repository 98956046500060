import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Texto from "../../data/es";
import Fetch from "../utils/Fetch";
import AuthService from "../Usuario/AuthService";
import TitlePage from "../utils/TitlePage";
import Constant from "../../data/constant";
import Links from "../../data/link";
import ImtoTransferencia from "./ImtoTransferencia";
import ImtoReport from "./ImtoReport";

const ImtoFormTransferencia = (props) => {
  const { token, numero } = useParams();

  const fetch = new Fetch();
  fetch.setToast(toast);
  const auth = new AuthService();
  const userAuth = auth.getProfile();

  const [inmueble, setInmueble] = useState(undefined);
  const [propiedad, setPropiedad] = useState(undefined);
  const [visacionMinuta, setVisacionMinuta] = useState(undefined);
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (auth.loggedIn()) {
      if (
        userAuth.code === Constant[0].grupos.funcionario ||
        userAuth.code === Constant[0].grupos.administrador
      ) {
        window.scrollTo(0, 0);
      } else window.location.href = Links[4].url;
    } else window.location.href = Links[4].url;
  }, []);

  const submitFormMinuta = async (step, data = undefined) => {
    debugger;
    setVisacionMinuta(data.visacionMinuta);
    setPropiedad(data.propiedad);
    setInmueble(data.inmueble);
    setStep(step);
  };

  const breadcrumbs = [
    {
      title: Links[0].title,
      url: Links[0].url,
    },
    {
      title: Links[88].title,
      url: Links[88].url,
    },
    {
      title: "Imto",
      url: "#",
    },
  ];

  return (
    <section className="items-grid section mt-5 mb-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={Texto.visacion_minutas + " - IMTO"}
          breadcrumbs={breadcrumbs}
          position={"left"}
        />

        {step === 0 ? (
          <ImtoTransferencia
            fetch={fetch}
            auth={auth}
            userAuth={userAuth}
            token={token}
            numero={numero}
            toast={toast}
            submitForm={submitFormMinuta}
          />
        ) : (
          ""
        )}

        {step === 1 ? (
          <ImtoReport
            fetch={fetch}
            auth={auth}
            userAuth={userAuth}
            toast={toast}
            submitForm={submitFormMinuta}
            inmueble={inmueble}
            propiedad={propiedad}
            visacionMinuta={visacionMinuta}
          />
        ) : (
          ""
        )}
      </div>

      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </section>
  );
};

export default ImtoFormTransferencia;
