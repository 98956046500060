import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Breadcrumb = ({ breadcrumbs, position, leftfull }) => {
	let text_left = "",
		content_justify;
	switch (position) {
		case "center": {
			text_left = "text-center";
			content_justify = "justify-content-center";
			break;
		}
		default:
			text_left = "text-left";
			content_justify = "justify-content-left";
			break;
	}

	return (
		<div className="breadcrumb-area bg-img bg-overlay jarallax">
			<div className={leftfull ? "container-fluid h-100" : "container h-100"}>
				<div className="row align-items-center">
					<div className="col-12 padding-left-0 padding-right-0">
						<div className={`breadcrumb-content ${text_left}`}>
							<nav aria-label="breadcrumb">
								<ol className={`breadcrumb  ${content_justify}`}>
									{breadcrumbs &&
										breadcrumbs.map((breadcrumb, key) => ({
											...(key !== breadcrumbs.length - 1 ? (
												<li key={key} className="breadcrumb-item">
													<Link
														to={{
															pathname: breadcrumb.url,
														}}
														className={`breadcrumb-item  text-fountain-blue ${key === breadcrumbs.length - 1 ? " active " : ""
															}`}
														style={{ marginRight: "0rem" }}
														onClick={breadcrumb.eventClick}
													>
														{breadcrumb.title}
													</Link>
												</li>
											) : (
												<li
													key={key}
													aria-current="page"
													className="breadcrumb-item text-fountain-blue active"
												>
													{breadcrumb.title}
												</li>
											)),
										}))}
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Breadcrumb.prototype = {
	url: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired
};

export default Breadcrumb;
