import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom'
import AuthService from '../../components/Usuario/AuthService';
import 'react-toastify/dist/ReactToastify.css';
import Fetch from '../../components/utils/Fetch';
import Links from '../../data/link';
import Constant from '../../data/constant';
import Config from '../../data/config';
import Iframe from 'react-iframe'

const Form = (props) => {
debugger
    const fetch = new Fetch();
    fetch.setToast(toast);
    const fetchCheck = new Fetch();
    fetchCheck.setToast(toast);
    const { rubro, csrfkey } = props;
    const auth = new AuthService();
    const history = useHistory();

    const [searchData, setSearchData] = useState(false);
    const [showIframe, setShowIframe] = useState(false);
    const [vehiculoCertificado, setVehiculoCertificado] = useState(false);

    const form = new FormData();

    const token = window.getParameterByName("csrf-token");
    const local = window.getParameterByName("lo");
    const expiraEn = window.getParameterByName("expiraEn");
    const preview = window.getParameterByName("preview");
    const adm = window.getParameterByName("adm");

    useEffect(() => {
        window.scrollTo(0, 0);
        registerCertificacionVehiculo(form);        
    }, []);

    const handleCreateNewCerticate = (event) => {
        event.preventDefault();
        if (token && local && expiraEn && preview && adm)
            history.push(Links[100].url + "?cert=detallado");
        else
            if (token && local && expiraEn) {
                if (local === '0')
                    history.push(Links[49].url + "?csrf-token=" + token + "&lo=" + local + "&expiraEn=" + expiraEn);
                else {
                    const form = new FormData();
                    fetch.fetchPost(form, 'api/int/integracion-contribuyentes-certificados', undefined).then(dataJson => {
                        if (dataJson && dataJson.status) {
                            history.replace(Links[99].url + "/veh?csrf-token=" + dataJson.csrfToken + "&expiraEn=" + dataJson.expiraEn + "&lo=" + dataJson.lo);
                            history.go();
                        }
                    })
                }
            } else
                history.push(Links[49].url);
    }

    const handleFinalizarCertificado = () => {

        if (token && local && expiraEn && preview && adm)
            history.push(Links[100].url + "?cert=detallado");
        else
            if (token && local && expiraEn) {
                if (local === '0')
                    history.push(Links[49].url + "?csrf-token=" + token + "&lo=" + local + "&expiraEn=" + expiraEn);
                else
                    history.push(Links[0].url);
            } else
                history.push(Links[49].url);
    }

    const registerCertificacionVehiculo = (form) => {
debugger
        setSearchData(true);
        //Registramos las gestiones de las certificaciones PIPG y DTP
        if (props.objetoTributario.code === Constant[0].tipo_certificacion_vehiculo.pipg || props.objetoTributario.code === Constant[0].tipo_certificacion_vehiculo.rdtp) {
            if (props.pagosRadicatoria)
                Array.from(props.pagosRadicatoria).forEach((pago, key) => {
                    form.append('veh_detalle_pagos[' + key + '][gestion]', pago.gestion);
                });
        }
        debugger
        //Mandamos el CsrKey
        form.append('csr_key', props.csrfkey.csrfToken);
        //Mandamos el codigo del tipo de certificacion
        form.append('codigo_tipo_certificacion', props.objetoTributario.code);
        //Registramos en tablas de certificaciones
        form.append('veh_certificado_radicatoria[numero_pta]', props.numeroPta);
        form.append('veh_certificado_radicatoria[numero_poliza]', props.numeroPoliza);
        form.append('veh_certificado_radicatoria[codigo_vehiculo]', props.codigoVehiculo);
        form.append('veh_certificado[fur]', props.fur);
        fetch.fetchPost(form, 'api/certificacion-vehiculo/create', undefined).then(dataJson => {
            debugger
            if (dataJson && dataJson.status) {
                let vehiculoCertificadoTemp = {
                    token: dataJson.data.vehCertificado.token,
                    numPta: dataJson.data.vehCertificadoRadicatoria.numero_pta,
                    codVehiculo: dataJson.data.vehCertificadoRadicatoria.codigo_vehiculo
                }
                setVehiculoCertificado(vehiculoCertificadoTemp);
                if (csrfkey && csrfkey.preview === "1") {
                    setShowIframe(true)
                    downloadPdf(Config[0].url + 'report/certificacion-vehiculo-generar-resumida/' + vehiculoCertificadoTemp.numPta + '/' + vehiculoCertificadoTemp.codVehiculo + '/' + vehiculoCertificadoTemp.token + '/preview?auth=' + auth.getToken(), true);
                } else {
                    downloadPdf(Config[0].url + 'report/certificacion-vehiculo-generar-resumida/' + vehiculoCertificadoTemp.numPta + '/' + vehiculoCertificadoTemp.codVehiculo + '/' + vehiculoCertificadoTemp.token + '/download?auth=' + auth.getToken(), false);
                    setTimeout(() => { setSearchData(false); }, 8000);
                }

                toast.success(dataJson.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });

                if (csrfkey && (csrfkey.preview === "0" || csrfkey.preview === '')) {
                    setTimeout(() => { setSearchData(false); }, 8000);
                }
            }
        })
    }

    const downloadPdf = (url, preview) => {
        if (preview)
            document.getElementById('iframePreviewLicPdf').src = url;
        else
            window.location.href = url;
    }

    const forceDownload = (event) => {
        debugger
        event.preventDefault();
        setSearchData(true);
        fetch.fetchGet('api/temporal-contribuyentes/by-csrkey/' + csrfkey.csrfToken).then(dataCsrKey => {
            if (dataCsrKey && dataCsrKey.status)
                fetch.fetchGet('api/vehCertificado/by-token/' + vehiculoCertificado.token).then(dataJson => {
                    if (dataJson && dataJson.status)
                        if (parseInt(dataJson.copia_contribuyente) < 2) {
                            debugger
                            downloadPdf(Config[0].url + 'report/certificacion-vehiculo-generar-resumida/' + vehiculoCertificado.numPta + '/' + vehiculoCertificado.codVehiculo + '/' + vehiculoCertificado.token + '/download?auth=' + auth.getToken());
                            setTimeout(() => { setSearchData(false); }, 8000);
                        } else {
                            toast.warning("Número maximo de intentos superado.", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true
                            });
                            setSearchData(false);
                        }
                })
        })
    }

    const handleOnloadIFrame = () => {
        setSearchData(false)
    }

    return (
        <>
            <div className="row mt-5">
                {!showIframe ?
                    <div className="col-12 ">
                        <div className="alert alert-success" role="alert">
                            <h4 className="alert-heading">Felicidades!</h4>
                            <p>Se ha completado la optención de la CERTIFICACIÓN DE INFORMACIÓN RAPIDA.</p>
                            <hr />
                            <p className="mb-0">En caso no se haya logrado descargar de forma automatica, haga click <a href="#" onClick={forceDownload}>aquí</a>.</p>
                        </div>
                    </div>
                    : ""}

                {searchData ?
                    <div className="col-12 mt-3 mb-3">
                        <img className='rounded mx-auto d-block' alt='pulse-loading' src={"/static/img/pulse_200.gif"} width="70" />
                    </div>
                    : ""}
                    
                {showIframe ?
                    <div className="col-12 embed-container">
                        <Iframe
                            src=""
                            width="640"
                            height="360"
                            frameBorder="0"
                            allow="allow-same-origin allow-scripts allow-popups allow-forms"
                            allowFullScreen
                            id="iframePreviewLicPdf"
                            display="initial"
                            onLoad={handleOnloadIFrame}
                        >
                        </Iframe>
                    </div>
                    : ""}

                <div className="col-6 mt-4 mb-1 text-center">
                    <div className=" search-btn button ">
                        <input className="btn btn-full-screen" name="submit" type="submit"
                            value="Nuevo Certificado" onClick={handleCreateNewCerticate} />
                    </div>
                </div>

                <div className="col-6 mt-4 mb-1 text-center">
                    <div className=" search-btn button ">
                        <input className="btn bg-maroon-flush btn-full-screen" name="submit" type="submit"
                            value="Finalizar" onClick={handleFinalizarCertificado} />
                    </div>
                </div>

            </div>
        </>
    )
}

export default Form;