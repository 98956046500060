import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalFur from "./../component/Fur/ModalFur";
import Form from "../../components/Certificacion/Form";
import Fetch from "../../components/utils/Fetch";

import Links from "../../data/link";
import TitlePage from "../../components/utils/TitlePage";
import Texto from "../../data/es";

const CertificacionCreate = (props) => {
  const [show_form, setShowForm] = useState(false);
  const [derechoAdmision, setDerechoAdmision] = useState(undefined);
  const [inmueble, setInmueble] = useState(undefined);
  const [registroCiudadano, setRegistroCiudadano] = useState(undefined);
  const [modalFur, setModalFur] = useState(undefined);
  const [objetoTributario, setObjetoTributario] = useState(undefined);
  const [propiedadSeleccionada, setPropiedadSeleccionada] = useState(undefined);
  const [pagos, setPagos] = useState(undefined);
  const [zona, setZona] = useState(undefined);
  const [csrfkey, setCsrfkey] = useState(undefined);
  const history = useHistory();

  const fetch = new Fetch();
  fetch.setToast(toast);

  const token = window.getParameterByName("csrf-token");
  const local = window.getParameterByName("lo");
  const expiraEn = window.getParameterByName("expiraEn");
  const { rubro } = useParams();

  const verificarCsrfKey = useCallback(async () => {
    if (token && local && expiraEn) {
      const dataJsonCsrfKey = await fetch.fetchGet(
        "api/temporal-contribuyentes/by-csrkey/" + token
      );
      if (dataJsonCsrfKey && dataJsonCsrfKey.status === true) {
        let modalFurCreate = new window.bootstrap.Modal(
          document.getElementById("modalFurFull"),
          {
            keyboard: true,
            backdrop: "static",
            focus: true,
          }
        );
        modalFurCreate.show();
        setModalFur(modalFurCreate);
        setRegistroCiudadano(dataJsonCsrfKey.TempContribuyente);
      } else {
        history.replace(
          Links[49].url +
            "?csrf-token=" +
            token +
            "&lo=" +
            local +
            "&expiraEn=" +
            expiraEn
        );
      }
    } else {
      history.replace(Links[0].url);
    }
  }, []);

  useEffect(() => {
    if (rubro === "inm" || rubro === "veh") {
      verificarCsrfKey();
    } else {
      history.replace(Links[50].url);
    }
    window.scrollTo(0, 0);
  }, [verificarCsrfKey]);

  const closeModal = (certificacion) => {
    modalFur.hide();
    document
      .getElementById("modalFurFull")
      .addEventListener("hidden.bs.modal", function (event) {
        debugger
        console.log("modal se ha cerrado.....", certificacion);
        const zonaArray = certificacion.inmueble.dataTecnico.datosGenerales.zonaTributaria.match(/(\d+)/g);
        setPropiedadSeleccionada(certificacion.dataPropiedadSelected);
        setDerechoAdmision(certificacion.derechoAdmision);
        setInmueble(certificacion.inmueble.dataInmueble);
        setPagos(certificacion.dataPagosPropiedad);
        setObjetoTributario(certificacion.objetoTributario);
        setCsrfkey(certificacion.csrfkey);
        setZona(zonaArray.length > 0 ? zonaArray[0] : 0);

        setShowForm(true);
        debugger;
        console.log("datos del registro ciudadano", registroCiudadano);
      });
  };

  let urlCertificacionBreadcrumbs = Links[49].url;
  if (token && local && expiraEn) {
    if (local === "1")
      urlCertificacionBreadcrumbs =
        Links[50].url +
        "/inm?csrf-token=" +
        token +
        "&lo=" +
        local +
        "&expiraEn=" +
        expiraEn;
    else
      urlCertificacionBreadcrumbs =
        Links[50].url +
        "?csrf-token=" +
        token +
        "&lo=" +
        local +
        "&expiraEn=" +
        expiraEn;
  }

  const breadcrumbs = [
    {
      title: Links[0].title,
      url: Links[0].url,
    },
    {
      title: Links[49].title,
      url: urlCertificacionBreadcrumbs,
    },
    {
      title: "Nuevo",
      url: "#",
    },
  ];

  return (
    <section className="items-grid section mt-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={Texto.certificacion}
          breadcrumbs={breadcrumbs}
          position={"left"}
        />

        <div className="container">
          {show_form ? (
            <Form
              toast={toast}
              rubro={rubro}
              fur={derechoAdmision.numero}
              numeroInmueble={inmueble.numeroInmueble}
              codigoInmueble={inmueble.codigoInmueble}
              objetoTributario={objetoTributario}
              codigoPropiedad={propiedadSeleccionada.codigoPropiedad}
              pagosPropiedad={pagos}
              zona={zona}
              csrfkey={csrfkey}
            />
          ) : (
            <p>Esperando confirmación de Fur...</p>
          )}
        </div>

        <ModalFur
          rubro={rubro}
          toast={toast}
          typeFur="CERTIFICACION"
          closeModal={closeModal}
        />

        <ToastContainer
          enableMultiContainer
          containerId={"Z"}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <ToastContainer />
      </div>
    </section>
  );
};

export default CertificacionCreate;
