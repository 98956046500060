
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Texto from '../../data/es';
import Config from '../../data/config';
import TitlePage from '../utils/TitlePage';
import Links from '../../data/link';

const Vehiculos = (props) => {

    const titlePage = Texto.guia

    const [showRequisitosVehiculo, setShowRequisitosVehiculo] = useState(true);

    const refRequisitosVehiculo = useRef();
    const refImgRequisitoVehiculo = useRef();

    const handleClickShowRequisitosVehiculo = (event) => {
        event.preventDefault();

        setShowRequisitosVehiculo(true)
        refRequisitosVehiculo.current.classList.add("active");
        refRequisitosVehiculo.current.classList.remove("active");
    }

    const handleOnchangeRequisitosVehiculos = (event) => {
        event.preventDefault();

        if (event.target.selectedOptions.length > 0) {
            const config = Config[2];
            const optionValue = event.target.selectedOptions[0];
            refImgRequisitoVehiculo.current.setAttribute("alt", 'img-requisito-Vehiculo-' + event.target.selectedOptions[0].value);
            refImgRequisitoVehiculo.current.setAttribute("width", '600');
            switch (optionValue.value) {
                case "INSCRIPCION_VEHICULO_FACTURA":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/1_INSCRIPCION VEHICULO_CON FACTURA.jpg');
                    break;
                case "INSCRIPCION_VEHICULO_FACTURA_TRANSFERENCIA_NUEVO":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/2_INSCRIP_VEHICULO CON_FACTURA_TRANsFERENCIA_NUEVO.jpg');
                    break;
                case "INSCRIPCION_IMPORTACION_DIRECTA_NUEVO":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/3_INSCRIPCION_IMPORTACION_DIRECTA_NUEVO.jpg');
                    break;
                case "INSCRIPCION_DIRECTA_TRANSFERENCIA":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/4_INSCRIPCION_IMPORTACION_DIRECTA_TRANSFERENCIA.jpg');
                    break;
                case "TRANSFERENCIA_VEHICULO_AUTOMOTOR_TERRESTRE":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/5_TRANSFERENCIA_VEHICULO_AUTOMOTOR_TERRESTRE.jpg');
                    break;
                case "TRANSFERENCIA_VEHICULO_AUTOMOTOR_TERRESTRE_CAMBIO_RADICATORIA_NUEVO":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/6_TRANSFERENCIA_VEHICULO_AUTOMOTOR_TERRESTRE_CAMBIO_RADICATORIA_NUEVO.jpg');
                    break;
                case "CAMBIO_RADICATORIA_NUEVO":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/7_CAMBIO_RADICATORIA_NUEVO.jpg');
                    break;
                case "CAMBIO_SERVICIO_PARTICULAR_PUBLICO_NUEVO":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/8_CAMBIO_SERVICIO_PARTICULAR_PUBLICO NUEVO.jpg');
                    break;
                case "CAMBIO_RADICATORIA_POSEEDOR":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/17_CAMBIO_RADICATORIA_POSEEDOR.jpg');
                    break;
                case "MODIFICACION_DATOS_TECNICOS_CAMBIO_ESTRUCTURA_NUEVO":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/9_MODIFICACION_DATOS_TECNICOS_CAMBIO_ESTRUCTURA _NUEVO.jpg');
                    break;
                case "DUPLICADO_CASO1_EXTRAVIO_CERTIFICADO_PROPIEDAD":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/10_1_DUPLICADO_CASO1_EXTRAVIOE_CERTIFICADO_PROPIEDAD.jpg');
                    break;
                case "DUPLICADO_CASO2_ROBO_HURTO_CERTIFICADO_PROPIEDAD":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/10_2_DUPLICADOCASO2_ROBO_HURTO_ CERTIFICADO_PROPIEDAD.jpg');
                    break;
                case "DUPLICADO_CASO3_DETERIORO_CERTIFICADO_PROPIEDAD":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/10_3_DUPLICADO _CASO3_DETERIORO _CERTIFICADO_PROPIEDAD.jpg');
                    break;
                case "DUPLICADO_CASO4_EXTRAVIO_ROBO_PLACAS_METALICAS_PLAQUETAS":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/10_4_DUPLICADO_ CASO4_EXTRAVI_ROBO_PLACAS_METALICAS_PLAQUETAS.jpg');
                    break;
                case "DUPLICADO_CASO5_DETERIORO_PLACAS_METALICAS_PLAQUETAS":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/10_5_DUPLICADO_CASO5_DETERIORO_PLACAS_METALICAS_PLAQUETAS.jpg');
                    break;
                case "REGULARIZACION_PROPIEDAD_VEHICULO":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/11_REGLARIZACION_PROPIEDAD_VEHICULO.jpg');
                    break;
                case "REEMPLAQUE_NUEVO":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/12_REEMPLAQUE_NUEVO.jpg');
                    break;
                case "BAJA_ROBO":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/13_BAJA_ROBO.jpg');
                    break;
                case "BAJA_FIN_VIDA_UTIL":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/14_BAJA_FIN_VDA_UTIL.jpg');
                    break;
                case "BAJA_SINIESTRO":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/15_BAJA_SINIESTRO.jpg');
                    break;
                case "BAJA_FUERA_CIRCULACION":
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/requisitos/vehiculos/16_BAJA_FUERA_CIRCULACION.jpg');
                    break;
                default:
                    refImgRequisitoVehiculo.current.setAttribute("width", '128');
                    refImgRequisitoVehiculo.current.setAttribute("src", config.url + '/static/img/preview.png');
                    break;
            }
        }
    }

    const [breadcrumbs, setBreadcrumbs] = useState([
        {
            title: Links[0].title,
            url: Links[0].url,
        },
        {
            title: Links[22].title,
            url: Links[22].url,
        },
        {
            title: Links[59].title,
            url: "#",
        }
    ]);

    return (

        <section className="items-grid section mt-5">
            <div className="container mt-1">
                <TitlePage titlePage={titlePage} breadcrumbs={breadcrumbs} position={'left'} leftfull={false} />

                <div className="nav-scroller bg-body shadow-sm bg-picton-blue mt-2 wow fadeInUp" data-wow-delay=".3s" style={{ marginTop: -20 }}>
                    <nav className="nav nav-underline" aria-label="Secondary navigation">
                        <Link to={""} className={`nav-link active`} title={Texto.requisitos} ref={refRequisitosVehiculo} onClick={handleClickShowRequisitosVehiculo}>
                            Requisitos
                        </Link>
                    </nav>
                </div>

                {
                    showRequisitosVehiculo ?
                        <>
                            <div className="row mt-3 wow fadeInUp" data-wow-delay=".3s" >
                                <div className="col-12 col-md-6 col-lg-4  mb-3">
                                    <label className="form-label">Requisitos Vehiculos</label>
                                    <select className="form-select" aria-label="Requisitos Vehiculos" onChange={handleOnchangeRequisitosVehiculos} >
                                        <option defaultValue value="">Seleccione Requisito Vehiculo</option>
                                        <option value="INSCRIPCION_VEHICULO_FACTURA">Inscripción Vehículo con Factura</option>
                                        <option value="INSCRIPCION_VEHICULO_FACTURA_TRANSFERENCIA_NUEVO">Inscripción Vehículo con Factura y Transferencia Nuevo</option>
                                        <option value="INSCRIPCION_IMPORTACION_DIRECTA_NUEVO">Inscripción Importación Directa Nuevo</option>
                                        <option value="INSCRIPCION_DIRECTA_TRANSFERENCIA">Inscripción Directa con Transferencia</option>
                                        <option value="TRANSFERENCIA_VEHICULO_AUTOMOTOR_TERRESTRE">Transferencia del Vehículo Automotor Terrestre</option>
                                        <option value="TRANSFERENCIA_VEHICULO_AUTOMOTOR_TERRESTRE_CAMBIO_RADICATORIA_NUEVO">Transferencia del Vehículo Automotor Terrestre con Cambio de Radicatoria Nuevo</option>
                                        <option value="CAMBIO_RADICATORIA_NUEVO">Cambio de Radicatoria Nuevo</option>
                                        <option value="CAMBIO_SERVICIO_PARTICULAR_PUBLICO_NUEVO">Cambio de Servicio Particular a Público Nuevo</option>
                                        <option value="CAMBIO_RADICATORIA_POSEEDOR">Cambio de Radicatoria Poseedor</option>
                                        <option value="MODIFICACION_DATOS_TECNICOS_CAMBIO_ESTRUCTURA_NUEVO">Modificación Datos Técnicos Cambio de Estructura Nuevo</option>
                                        <option value="DUPLICADO_CASO1_EXTRAVIO_CERTIFICADO_PROPIEDAD">Duplicado Caso 1 Extravío de Certificado de Propiedad</option>
                                        <option value="DUPLICADO_CASO2_ROBO_HURTO_CERTIFICADO_PROPIEDAD">Duplicado Caso 2 Robo - Hurto de Certificado de Propiedad</option>
                                        <option value="DUPLICADO_CASO3_DETERIORO_CERTIFICADO_PROPIEDAD">Duplicado Caso 3 Deterioro de Certificado de Propiedad</option>
                                        <option value="DUPLICADO_CASO4_EXTRAVIO_ROBO_PLACAS_METALICAS_PLAQUETAS">Duplicado Caso 4 Extravío - Robo de Placas Metálicas Plaquetas</option>
                                        <option value="DUPLICADO_CASO5_DETERIORO_PLACAS_METALICAS_PLAQUETAS">Duplicado Caso 5 Deterioro Placas Metálicas Plaquetas</option>
                                        <option value="REGULARIZACION_PROPIEDAD_VEHICULO">Regularización de Propiedad de Vehículo</option>
                                        <option value="REEMPLAQUE_NUEVO">Reemplaque Nuevo</option>
                                        <option value="BAJA_ROBO">Baja por Robo</option>
                                        <option value="BAJA_FIN_VIDA_UTIL">Baja por Fin de Vida útil</option>
                                        <option value="BAJA_SINIESTRO">Baja por Siniestro</option>
                                        <option value="BAJA_FUERA_CIRCULACION">Baja por Fuera de Circulación</option>
                                    </select>
                                </div>
                            </div>

                            <div className="row mt-3 wow fadeInUp" data-wow-delay=".3s">
                                <div className="col-12 text-center">
                                    <img className='rounded mx-auto d-block img-thumbnail' alt={'img-requisito-Vehiculo-preview'} src={Config[2].url + '/static/img/preview.png'}
                                        width="128" ref={refImgRequisitoVehiculo} />
                                </div>
                            </div>
                        </>
                        : ""}
            </div>
        </section>
    )
}

export default Vehiculos;