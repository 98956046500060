import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fetch from "../utils/Fetch";
import Texto from "../../data/es";
import PasosNavigationVisacion from "../utils/PasosNavigationVisacionMinutas";

const FormSujetoPasivo = (props) => {
  const { dataInmueble, dataPropietario, onSubmitForm } = props;

  const fetch = new Fetch();
  fetch.setToast(toast);

  const [renderCopropietario, setRenderCopropietario] = useState([]);
  const [nextButton, setNextButton] = useState(true);
  let propietario = {};
  let apoderado;
  let poder;

  if (dataPropietario.natural) {
    propietario = dataPropietario.natural;
  }

  debugger;
  if (dataPropietario.juridico) {
    propietario = dataPropietario.juridico;
  }

  if (dataPropietario.apoderado) {
    apoderado = dataPropietario.apoderado;
  }
  if (dataPropietario.poder) {
    poder = dataPropietario.poder;
  }

  useEffect(() => {
    if (
      dataPropietario.copropietarios &&
      dataPropietario.copropietarios.length > 0
    ) {
      setNextButton(false);
      copropietariosData(dataPropietario.copropietarios);
    }
    window.scrollTo(0, 0);
  }, []);

  const openModalCopropietarios = (event, typeModal) => {
    event.preventDefault();

    const modal = new window.bootstrap.Modal(
      document.getElementById("idModalCopropietarios"),
      {
        keyboard: true,
        backdrop: "static",
        focus: true,
      }
    );

    if (
      window.removeNullOrUndefined(dataPropietario.copropietarios).length > 0
    ) {
      const renderCopropietario = dataPropietario.copropietarios.map(
        (copropietario) => {
          return copropietario ? (
            <div
              key={copropietario.documentoIdentidad}
              className="col-12 col-md-12"
            >
              <div className="card widget widget-simple w-100">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 ">
                      <p className="text-start">
                        <strong>Nombre Completo: </strong>
                        <span>{copropietario.nombreCompleto}</span>
                      </p>
                    </div>

                    <div className="col-12 col-md-12 col-lg-12 ">
                      <p className="text-right">
                        <strong>Documento: </strong>
                        <span>{` ${copropietario.documentoIdentidad}`}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          );
        }
      );

      setRenderCopropietario(renderCopropietario);
    }

    event.currentTarget.firstElementChild.style.color = "#0f5132";
    modal.show();
  };

  const submitFormToSell = async (event) => {
    const data = {
      dataPmc: dataPropietario.pmc,
      dataDomicilioLegal: dataPropietario.domicilioLegal,
      dataTipoContribuyente: dataPropietario.tipoContribuyente,
      dataCopropietarios: dataPropietario.copropietarios,
      dataPropietario: propietario,
      dataApoderado: apoderado,
      dataPoder: poder,
    };
    onSubmitForm(event, data);
  };

  const copropietariosData = async (copropietariosRuat) => {
    let copropietarios = copropietariosRuat.map(async (coprop) => {
      if (coprop.codigoContribuyente) {
        const copropietarioRuat = await fetch.fetchGet(
          `api-ruat/contribuyentes/cc/${coprop.codigoContribuyente}`
        );
        if (copropietarioRuat && copropietarioRuat.status)
          return copropietarioRuat.data.contribuyente[0];
      }
      return undefined;
    });

    await Promise.all(copropietarios).then((responseCopro) => {
      let copropietariosTemp = responseCopro.filter((coprop) => coprop);
      if (copropietariosTemp.length > 0) {
        copropietariosTemp = responseCopro.map((coprop) => {
          debugger;
          if (coprop && coprop.tipoContribuyente === "NATURAL") {
            const natural = coprop.natural;
            return {
              codigoContribuyente: coprop.codigoContribuyente,
              documentoIdentidad:
                natural.tipoDocumentoCode +
                " " +
                natural.numeroDocumento +
                " " +
                (natural.expedido ? natural.expedido : ""),
              nombreCompleto: coprop.nombreContribuyente,
              tipoDocumento: natural.tipoDocumento,
              tipoDocumentoCode: natural.tipoDocumentoCode,
              numeroDocumento: coprop.documentoIdentidad,
              pmc: coprop.pmc,
              expedido: natural.expedido ? natural.expedido : "",
              nombres: natural.nombres,
              primerApellido: natural.primerApellido,
              segundoApellido: natural.segundoApellido
                ? natural.segundoApellido
                : "",
              apellidoEsposo: natural.apellidoEsposo
                ? natural.apellidoEsposo
                : "",
              estadoCivil: natural.estadoCivil,
              genero: natural.genero,
              fechaNacimiento: natural.fechaNacimiento,
              celular: coprop.informacionAdicional.celular,
            };
          }
          return undefined;
        });
        copropietariosTemp = copropietariosTemp.filter((coprop) => coprop);
        dataPropietario.copropietarios = copropietariosTemp;
      }
      setNextButton(true);
    });
  };

  return (
    <>
      <PasosNavigationVisacion
        nro_inmueble={dataInmueble.numeroInmueble}
        paso1_active={true}
        paso2_active={true}
        paso3_active={false}
        paso4_active={false}
      />

      <form
        action=""
        className="contact__form needs-validation"
        name="formDataSell"
        id="formDataSell"
        method="post"
        noValidate
        onSubmit={submitFormToSell}
        style={{ width: "100%" }}
      >
        <div className="card widget widget-simple">
          <div className="card-body ">
            <div className="row ">
              <div className="col-12 col-md-6 widget-header mb-3">
                <h4 className="text-maroon-flush">
                  <i className="fas fa-bookmark me-2"></i>{" "}
                  {Texto.datos_sujeto_pasivo_vendedor}
                </h4>
              </div>
              <div className="col-4 col-md-6 widget-header mb-3"></div>
            </div>

            <div className="widget-content">
              <div className="widget-body">
                <div className="row ">
                  <div className="col-sm-12 col-md-5 col-lg-5 ">
                    <p className="text-left">
                      <strong>{Texto.pmc.toUpperCase()}: </strong>
                      <span>{dataPropietario.pmc}</span>
                    </p>
                  </div>

                  <div className="col-sm-12 col-md-4 col-lg-4 ">
                    <p className="text-left">
                      <strong>{Texto.tipo_contribuyente}: </strong>
                      <span>{dataPropietario.tipoContribuyente}</span>
                    </p>
                  </div>
                </div>

                {dataPropietario.natural ? (
                  <div className="row ">
                    <div className="col-sm-12 col-md-12 col-lg-12 ">
                      <p className="text-left">
                        <strong>{Texto.nombre}(s): </strong>
                        <span>{propietario.nombres}</span>
                      </p>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 ">
                      <p className="text-left">
                        <strong>{Texto.apellido}ss: </strong>
                        <span>
                          {propietario.primerApellido}{" "}
                          {propietario.segundoApellido}{" "}
                          {propietario.segundoApellido
                            ? " de " + propietario.apellidoEsposo
                              ? propietario.apellidoEsposo
                              : ""
                            : ""}{" "}
                        </span>
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="row ">
                    <div className="col-sm-12 col-md-12 ">
                      <p className="text-left">
                        <strong>{Texto.razon_social}: </strong>
                        <span>{dataPropietario.nombreContribuyente}</span>
                      </p>
                    </div>
                  </div>
                )}
                <div className="row ">
                  <div className="col-sm-12 col-md-12 col-lg-12 ">
                    <p className="text-left">
                      <strong>{Texto.direccion}: </strong>
                      <span>{dataPropietario.domicilioLegal}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card widget widget-simple">
          <div className="card-body ">
            <div className="row ">
              <div className="col-12 col-md-6 widget-header mb-3">
                <h4 className="text-maroon-flush">
                  <i className="fas fa-bookmark me-2"></i>{" "}
                  {Texto.informacion_complementaria}
                </h4>
              </div>
              <div className="col-4 col-md-6 widget-header mb-3"></div>
            </div>

            <div className="widget-content">
              <div className="widget-body">
                {dataPropietario.natural ? (
                  <>
                    <div className="row ">
                      <div className="col-sm-12 col-md-4 col-lg-4 ">
                        <p className="text-left">
                          <strong>{Texto.tipo_documento}: </strong>
                          <span>{propietario.tipoDocumento}</span>
                        </p>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-4 ">
                        <p className="text-left">
                          <strong>{Texto.documento_identidad}: </strong>
                          <span>{propietario.numeroDocumento}</span>
                        </p>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-4 ">
                        <p className="text-left">
                          <strong>{Texto.expedido}: </strong>
                          <span>{propietario.expedido}</span>
                        </p>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-sm-12 col-md-4 col-lg-4 ">
                        <p className="text-left">
                          <strong>{Texto.estado_civil}: </strong>
                          <span>{propietario.estadoCivil}</span>
                        </p>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-4 ">
                        <p className="text-left">
                          <strong>{Texto.fecha_nacimiento}: </strong>
                          <span>
                            {propietario ? propietario.fechaNacimiento : " -- "}
                          </span>
                        </p>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-4 ">
                        <p className="text-left">
                          <strong>{Texto.genero}: </strong>
                          <span>{propietario.genero}</span>
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row ">
                      <div className="col-sm-12 col-md-5 col-lg-5 ">
                        <p className="text-left">
                          <strong>{Texto.tipo_doc}: </strong>
                          <span>
                            {propietario.tipoDocumento
                              ? propietario.tipoDocumento
                              : "--"}
                          </span>
                        </p>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-4 ">
                        <p className="text-left">
                          <strong>{Texto.numero}: </strong>
                          <span>{propietario.numeroDocumento}</span>
                        </p>
                      </div>

                      <div className="col-sm-12 col-md-3 col-lg-3 ">
                        <p className="text-left">
                          <strong>{Texto.tipo_sociedad}: </strong>
                          <span>{propietario.tipoSociedad}</span>
                        </p>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-sm-12 col-md-5 col-lg-5 ">
                        <p className="text-left">
                          <strong>{Texto.fecha_constitucion}: </strong>
                          <span>{propietario.fechaConstitucion}</span>
                        </p>
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-4 ">
                        <p className="text-left">
                          <strong>{Texto.tipo_contribuyente_juridico}: </strong>
                          <span>{propietario.tipoContribuyenteJuridico}</span>
                        </p>
                      </div>

                      <div className="col-sm-12 col-md-3 col-lg-3 ">
                        <p className="text-left">
                          <strong>{Texto.sigla}: </strong>
                          <span>{propietario.sigla}</span>
                        </p>
                      </div>
                    </div>
                  </>
                )}

                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 ">
                    <p className="text-left">
                      <strong>Copropietarios: </strong>
                      <span>
                        {dataPropietario
                          ? window.removeNullOrUndefined(
                              dataPropietario.copropietarios
                            ).length
                          : 0}{" "}
                      </span>

                      {dataPropietario &&
                      window.removeNullOrUndefined(
                        dataPropietario.copropietarios
                      ).length > 0 ? (
                        <Link
                          to="#"
                          className="h6 ms-2 pointer"
                          title={`Total Copropietarios - ${
                            window.removeNullOrUndefined(
                              dataPropietario.copropietarios
                            ).length
                          }`}
                          data-toggle="modal"
                          data-target=".modal-contrucciones"
                          onClick={(event) =>
                            openModalCopropietarios(event, "COPROPIETARIOS")
                          }
                        >
                          <i className="fa fa-user" aria-hidden="true"></i>
                        </Link>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {apoderado ? (
          <div className="card widget widget-simple">
            <div className="card-body ">
              <div className="row ">
                <div className="col-12 col-md-6 widget-header mb-3">
                  <h4 className="text-maroon-flush">
                    <i className="fas fa-bookmark me-2"></i>{" "}
                    {Texto.representante_legal}
                  </h4>
                </div>
                <div className="col-4 col-md-6 widget-header mb-3"></div>
              </div>

              <div className="widget-content">
                <div className="widget-body">
                  <div className="row ">
                    <div className="col-sm-12 col-md-5 col-lg-5 ">
                      <p className="text-left">
                        <strong>{Texto.tipo_documento}: </strong>
                        <span>
                          {apoderado.tipoDocumento
                            ? apoderado.tipoDocumento
                            : "--"}
                        </span>
                      </p>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 ">
                      <p className="text-left">
                        <strong>{Texto.documento_identidad}: </strong>
                        <span>
                          {apoderado.numeroDocumento +
                            " " +
                            (apoderado.expedido ? apoderado.expedido : "")}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-sm-12 col-md-12 ">
                      <p className="text-left">
                        <strong>{Texto.nombre}: </strong>
                        <span>
                          {apoderado.nombres ? apoderado.nombres : "--"}
                        </span>
                      </p>
                    </div>

                    <div className="col-sm-12 col-md-12 ">
                      <p className="text-left">
                        <strong>{Texto.apellido + "s"}: </strong>
                        <span>
                          {apoderado.primerApellido +
                            " " +
                            (apoderado.segundoApellido
                              ? apoderado.segundoApellido
                              : "") +
                            " " +
                            (apoderado.apellidoEsposo
                              ? " de " + apoderado.apellidoEsposo
                              : "")}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-sm-12 col-md-5 col-lg-5 ">
                      <p className="text-left">
                        <strong>{Texto.genero}: </strong>
                        <span>
                          {apoderado.genero ? apoderado.genero : "--"}
                        </span>
                      </p>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 ">
                      <p className="text-left">
                        <strong>{Texto.estado_civil}: </strong>
                        <span>{apoderado.estadoCivil}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {poder ? (
          <div className="card widget widget-simple">
            <div className="card-body ">
              <div className="row ">
                <div className="col-12 col-md-6 widget-header mb-3">
                  <h4 className="text-maroon-flush">
                    <i className="fas fa-bookmark me-2"></i> {Texto.poder}
                  </h4>
                </div>
                <div className="col-4 col-md-6 widget-header mb-3"></div>
              </div>

              <div className="widget-content">
                <div className="widget-body">
                  <div className="row ">
                    <div className="col-sm-12 col-md-5 col-lg-5 ">
                      <p className="text-left">
                        <strong>{Texto.numero}: </strong>
                        <span>
                          {poder.numeroPoder ? poder.numeroPoder : "--"}
                        </span>
                      </p>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 ">
                      <p className="text-left">
                        <strong>{Texto.fecha}: </strong>
                        <span>
                          {window
                            .moment(dataPropietario.poder.fecha, "DD-MM-YYYY")
                            .format("DD-MM-YYYY")}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-sm-12 col-md-12 ">
                      <p className="text-left">
                        <strong>{Texto.observacion}: </strong>
                        <span>
                          {poder.observacion ? poder.observacion : "--"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {nextButton ? (
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6 text-end"></div>
            <div className="col-12 col-md-2 col-lg-2 text-end">
              <div className=" search-btn button mb-3"></div>
            </div>

            <div className="col-12 col-md-4 col-lg-4 text-end">
              <div className=" search-btn button mb-3">
                <input
                  type="Submit"
                  className="btn btn-full-screen"
                  defaultValue={"Siguiente"}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </form>

      <div
        className="modal fade modal-copropietarios"
        tabIndex="-1"
        role="dialog"
        id="idModalCopropietarios"
        aria-labelledby="h5ModalCopropietarios"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="h5ModalCopropietarios">
                Copropietarios
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="row content-overflow">{renderCopropietario}</div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  );
};

export default FormSujetoPasivo;
