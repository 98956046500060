import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Fetch from '../../../components/utils/Fetch';
import Config from "../../../data/config";

class ValidateDeuda extends Component {

    constructor(props, context) {
        super(props, context);
        this.handleSearchInmuebleClick = this.handleSearchInmuebleClick.bind(this)
        this.state = {
            optionsTipoCertificacion: "",
            searchData: false,
            msg: ""
        };
        this.refSlcTipoCertificacion = React.createRef();
        this.refInputSearchObjectTributari = React.createRef()
        this.refInputCancelObjectTributari = React.createRef()
        this.refInputSearchInmueble = React.createRef()
    }

    componentDidMount() {
        this.loadObjetoTributario()
    }

    loadObjetoTributario = () => {
        let self = this
        const typeCertification = this.props.fetch.fetchGet(`api/criterio/by-rub-con/inm/tipo_certificacion`);
        typeCertification.then(res => {
            if (res && res.status === true) {
                const listItems = res.Criterio.map((item, index) => {
                    return <option key={index} value={item.id} titleinput={item.description} code={item.abreviacion} >{item.descripcion}</option>
                });
                self.setState({ optionsTipoCertificacion: listItems });
            }
        })
    }

    async handleSearchInmuebleClick(event) {
        const fetch = new Fetch();
        fetch.setToast(toast);
        debugger
        event.preventDefault()
        this.props.validateForm();

        if (window.jQuery("#" + this.props.idForm).parsley().isValid()) {

            const resTempContribuyente = await this.props.fetch.axiosAsyncGet(`api/temporal-contribuyentes/by-csrkey/${this.props.csrfkey.csrfToken}`)
            if (resTempContribuyente && resTempContribuyente.status === true) {
                debugger
                let input = this.refInputSearchInmueble.current
                let selectTipoCertificacion = this.refSlcTipoCertificacion.current
                let button = this.refInputSearchObjectTributari.current
                let buttonCancel = this.refInputCancelObjectTributari.current
                this.setState({ searchData: true });

                input.setAttribute("readonly", true)
                selectTipoCertificacion.setAttribute("disabled", true)
                button.classList.add("btn-disabled")
                button.setAttribute("disabled", true)

                buttonCancel.classList.add("btn-disabled")
                buttonCancel.setAttribute("disabled", true)

                const respInmueble = await this.props.fetch.axiosAsyncGet(`api-ruat/inmuebles/nro-inmueble/${input.value}`)
                if (Boolean(respInmueble) && respInmueble.status === true) {
                    const propietarios = respInmueble.data.resInmueble.propiedades
                    if (propietarios.length === 1) {
                        this.setState({ searchData: false });
                        const data = {
                            showForm: true,
                            inmueble: respInmueble.data.resInmueble,
                            datosTecnicos: respInmueble.data.resTecnico,
                            objetoTributario: {
                                value: selectTipoCertificacion.selectedOptions[0].value,
                                code: selectTipoCertificacion.selectedOptions[0].getAttribute("code"),
                                name: selectTipoCertificacion.selectedOptions[0].text
                            }
                        }
                        this.props.nextPageCurrent(2, data)
                    } else {
                        const data = {
                            showForm: true,
                            inmueble: respInmueble.data.resInmueble,
                            datosTecnicos: respInmueble.data.resTecnico,
                            objetoTributario: {
                                value: selectTipoCertificacion.selectedOptions[0].value,
                                code: selectTipoCertificacion.selectedOptions[0].getAttribute("code"),
                                name: selectTipoCertificacion.selectedOptions[0].text
                            }
                        }
                        this.props.nextPageCurrent(2, data)
                    }
                } else {
                    input.removeAttribute("readonly")
                    selectTipoCertificacion.removeAttribute("disabled")
                    button.classList.remove("btn-disabled")
                    button.removeAttribute("disabled")
                    buttonCancel.classList.remove("btn-disabled")
                    buttonCancel.removeAttribute("disabled")
                    this.setState({ searchData: false, msg: "Número de Inmueble no registrado!!!" });
                }
            }
        } else {
            this.setState({ msg: "El formulario tiene valores obligatorios" });
            this.props.fetch.toast.warn('El formulario tiene valores obligatorios', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-12 padding-right-0">
                        <img className='rounded mx-auto d-block' alt='terminos-condiciones' src={"/static/img/type_certification.png"} width="100" />
                        <h4 className="text-center mt-3 mb-3 text-butterfly-bush">Tipos de Certificación</h4>
                    </div>
                </div>

                {this.state.msg !== "" ?
                    <div className="col-12 padding-right-0 padding-left-0 mb-3">
                        <div className="alert alert-danger alert-dismissible">
                            <strong>Atención!</strong> {this.state.msg}
                        </div>
                    </div>
                    : ""}

                <div className="row">
                    <div className="col-12 mb-3">
                        <select className="form-select" name={'[id_objeto_tributario]'} required data-parsley-required="true" aria-label="Tipo de Certificación"
                            ref={this.refSlcTipoCertificacion} >
                            <option defaultValue value="">Seleccione Tipo de Certificación</option>
                            {this.state.optionsTipoCertificacion}
                        </select>
                    </div>

                    <div className="col-12">
                        <input name={'[numero]'} id={'[numero]'} type="text" className="form-control input-uppercase" placeholder="Número Inmueble"
                            aria-label="Número" aria-describedby="button-addon1" ref={this.refInputSearchInmueble} required data-parsley-required="true"
                            pattern="[0-9]+" data-parsley-pattern="[0-9]+" data-parsley-maxlength={Config[9].furLongitud} maxLength={Config[9].furLongitud} />
                    </div>
                </div>

                {
                    this.state.searchData ?
                        <div className="row mt-3">
                            <div className="col-12  mb-1">
                                <img className='rounded mx-auto d-block' alt='pulse-loading' src={"/static/img/pulse_200.gif"} width="70" />
                            </div>
                        </div> : ""}

                <div className="row mt-3">
                <div className="col-12 col-md-6 col-lg-6 mb-3 text-start">
                        <div className="col-12 search-btn button ">
                            <input className="btn bg-maroon-flush btn-full-screen" name="submit" type="submit" value="Cancelar" ref={this.refInputCancelObjectTributari}
                                onClick={this.props.handleCancelClick} />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 mb-3 text-end">
                        <div className="col-12 search-btn button ">
                            <input className="btn btn-full-screen" name="submit" type="submit" value="Buscar" ref={this.refInputSearchObjectTributari}
                                onClick={this.handleSearchInmuebleClick} />
                        </div>
                    </div>
                    
                </div>
            </>
        )
    }
}
export default ValidateDeuda;