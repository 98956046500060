import React, { useState, useEffect } from "react";
import Config from "../../data/config";
import DatePickerAtm from "../component/DatePickerAtm";

const ValidateTipoTramite = (props) => {
  const {
    handleCancelClick,
    fetch,
    dataDerechoAdmision,
    nextPageCurrent,
    validateForm,
  } = props;

  const [optionsCategorys, setOptionsCategorys] = useState([]);
  const [optionsTowers, setOptionsTowers] = useState([]);
  const [optionsProcedures, setOptionsProcedures] = useState([]);
  const [searchData, setSearchData] = useState(false);
  const [message, setMessage] = useState("");
  //const [isPublic, setIsPublic] = useState(false);
  const [usoSuelo, setUsoSuelo] = useState(true);
  const [areaUrbano, setAreaUrbano] = useState(false);
  const [showDateInit, setShowDateInit] = useState(false);

  const refSlcCategoria = React.createRef();
  const refSlcTramite = React.createRef();
  const refInputCancel = React.createRef();
  const refInputSearchMap = React.createRef();
  //const refcheckIsPublicMap = React.createRef();
  const refSlcTower = React.createRef();
  const refSlcUsoInmueble = React.createRef();
  const refSlcAreaInmueble = React.createRef();

  useEffect(() => {
    loadCategorys();
  }, []);

  const loadCategorys = () => {
    const categorys = fetch.fetchGet(`api/saat/categoria`);
    categorys.then((res) => {
      debugger;
      if (res && res.status && res.data.Categoria) {
        const listItems = res.data.Categoria.map((item, index) => {
          return (
            <option key={index} value={item.id} name={item.name}>
              {item.name}
            </option>
          );
        });
        setOptionsCategorys(listItems);
      }
    });
  };

  const loadTowers = (idCategory) => {
    const categorys = fetch.fetchGet(
      `api/saat/tower-by-category/${idCategory}`
    );
    categorys.then((res) => {
      debugger;
      if (res && res.status && res.data.Tower) {
        const listItems = res.data.Tower.map((item, index) => {
          return (
            <option key={index} value={item.id} name={item.name}>
              {item.name}
            </option>
          );
        });
        setOptionsTowers(listItems);
      }
    });
  };

  const handleSearchInmuebleClick = async (event) => {
    debugger;
    if(!event.target.classList.contains("btn-disabled")){
      event.preventDefault();
      await eventInputSearch();
    }
  };

  const handleNumeroOnChange = (event) => {
    event.preventDefault();
    const button = document.getElementById("btnModalSmallSubmit");

    if (event.currentTarget.value.length >= 3)
      button.classList.remove("btn-disabled");
    else button.classList.add("btn-disabled");
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      await eventInputSearch();
    }
  };

  const eventInputSearch = async () => {
    validateForm();
    debugger;
    if (
      window
        .jQuery(
          "#" +
            document.getElementById("formModalAutorization").getAttribute("id")
        )
        .parsley()
        .isValid()
    ) {
      let input = refInputSearchMap.current;
      //const refCheckIsPublic = refcheckIsPublicMap.current;
      let buttonCancel = refInputCancel.current;
      let selectCategory = refSlcCategoria.current;
      let selectTipoTramite = refSlcTramite.current;
      let selectTower = refSlcTower.current;
      let selectUsoInmueble = refSlcUsoInmueble.current;
      let selectAreaInmueble = refSlcAreaInmueble.current;
      let fechaInicioTramite = document.getElementsByName(
        "fecha_inicio_regularizacion"
      );

      let dateInitTramite = "";
      if (fechaInicioTramite && fechaInicioTramite.length > 0) {
        const arrayDate = fechaInicioTramite[0].value.split("/");
        dateInitTramite = window
          .moment(arrayDate[2] + "-" + arrayDate[1] + "-" + arrayDate[0])
          .format("YYYY/MM/DD");
      }

      setSearchData(true);
      selectCategory.setAttribute("disabled", true);
      selectTower.setAttribute("disabled", true);
      selectTipoTramite.setAttribute("disabled", true);
      selectUsoInmueble.setAttribute("disabled", true);
      if(selectAreaInmueble)
        selectAreaInmueble.setAttribute("disabled", true);
      buttonCancel.classList.add("btn-disabled");
      buttonCancel.setAttribute("disabled", true);

      let continuAsPublic = false;
      //uso inmueble publico
      if (
        selectUsoInmueble.selectedOptions.length > 0 &&
        parseInt(selectUsoInmueble.selectedOptions[0].value, 10) === 1
      ) {
        continuAsPublic = true;
      } else {
        //area inmueble rural
        if (
          selectAreaInmueble.selectedOptions.length > 0 &&
          selectAreaInmueble.selectedOptions[0].value === "RURAL"
        ) {
          continuAsPublic = true;
        }
      }

      if (!input && continuAsPublic) {
        // console.log("--------------------", "caso publicos");
        const category = {
          id: parseInt(selectCategory.selectedOptions[0].value),
          name: selectCategory.selectedOptions[0].getAttribute("name"),
        };

        const tipoTramite = {
          id: parseInt(selectTipoTramite.selectedOptions[0].value),
          name: selectTipoTramite.selectedOptions[0].getAttribute("name"),
          codcat: selectTipoTramite.selectedOptions[0].getAttribute("codcat"),
          codtiptramite:
            selectTipoTramite.selectedOptions[0].getAttribute("codtiptramite"),
          public: 1,
          area: selectAreaInmueble
            ? selectAreaInmueble.selectedOptions[0].value
            : "",
        };

        const tower = {
          id: parseInt(selectTower.selectedOptions[0].value),
        };

        const form = new FormData();
        form.append("propiedades[codigoPropiedad]", 0);

        debugger;
        const tecnico = {
          anioObligacionTributaria: "0",
          areasComunes: [],
          construcciones: [],
          construccionesAdicionales: [],
          datosGenerales: {
            area: "NO DEFINIDO",
            clase: "TERRENO",
            referenciaUbicacion: null,
            tipoPropiedad: "UNICA",
            ubicacion: "INNOMINADA",
            zonaTributaria: "ZONA 0",
          },
          terreno: [
            {
              inclinacion: "NO DEFINIDO",
              materialVia: "TIERRA",
              servicios: "LUZ",
              superficeM2: 0,
              ubicacion: "NO DEFINIDO",
            },
          ],
        };

        const propiedades = [
          {
            codigoCatastral: undefined,
            codigoInmueble: undefined,
            numeroInmueble: undefined,
            claseInmueble: "",
            category: category,
            tipoTramite: tipoTramite,
            dataTecnico: tecnico,
            derechoAdmision: dataDerechoAdmision,
            gestionesDeuda: [],
            fechaInicioTramite: dateInitTramite,
            tower,
          },
        ];
        nextPageCurrent(2, propiedades[0]);
      } else {
        const tower = {
          id: parseInt(selectTower.selectedOptions[0].value),
        };

        if (
          input.value &&
          selectCategory.selectedIndex > 0 &&
          selectTipoTramite.selectedIndex > 0
        ) {
          input.setAttribute("readonly", true);
          const respInmueble = await fetch.axiosAsyncGet(
            `api-ruat/inmuebles/nro-inmueble/${input.value}`
          );
          if (Boolean(respInmueble) && respInmueble.status) {
            const propiedades = respInmueble.data.resInmueble.propiedades;
            const category = {
              id: parseInt(selectCategory.selectedOptions[0].value),
              name: selectCategory.selectedOptions[0].getAttribute("name"),
            };

            const tipoTramite = {
              id: parseInt(selectTipoTramite.selectedOptions[0].value),
              name: selectTipoTramite.selectedOptions[0].getAttribute("name"),
              codcat:
                selectTipoTramite.selectedOptions[0].getAttribute("codcat"),
              codtiptramite:
                selectTipoTramite.selectedOptions[0].getAttribute(
                  "codtiptramite"
                ),
              public: 0,
              area: selectAreaInmueble
                ? selectAreaInmueble.selectedOptions[0].value
                : "",
            };

            const form = new FormData();
            form.append(
              "propiedades[codigoPropiedad]",
              propiedades[0].codigoPropiedad
            );
            form.append("module","saat"); 
            const resDeudas = await fetch.fetchPost(
              form,
              `api-ruat/inmuebles/deudas`,
              undefined
            );
            resDeudas.data.gestionesDeuda = [];  //para evitar la deuda
            debugger;
            if (fechaInicioTramite && fechaInicioTramite.length > 0) {
              if (
                resDeudas &&
                resDeudas.status &&
                resDeudas.data.gestionesDeuda &&
                resDeudas.data.gestionesDeuda.length > 0
              ) {
                const yearInicioTramite = window
                  .moment(fechaInicioTramite[0].value)
                  .format("YYYY");
                let deudasInmueble = resDeudas.data.gestionesDeuda.map(
                  (deuda) =>
                    parseInt(deuda.gestion, 10) <
                    parseInt(yearInicioTramite, 10)
                      ? deuda.gestion
                      : undefined
                );
                deudasInmueble = deudasInmueble.filter(
                  (deuda) => deuda !== undefined
                );

                deudasInmueble = deudasInmueble.filter((item, index) => {
                  return deudasInmueble.indexOf(item) === index;
                });
                if (deudasInmueble && deudasInmueble.length <= 0) {
                  input.removeAttribute("readonly");
                  selectCategory.removeAttribute("disabled");
                  selectTipoTramite.removeAttribute("disabled");
                  selectTower.removeAttribute("disabled");
                  buttonCancel.classList.remove("btn-disabled");
                  buttonCancel.removeAttribute("disabled");

                  responseNotHasDeudaGestion(
                    propiedades,
                    respInmueble,
                    category,
                    tipoTramite,
                    resDeudas,
                    dateInitTramite,
                    tower
                  );
                  nextPageCurrent(2, propiedades[0]);
                } else {
                  responseHasDeudaGestion(deudasInmueble);
                }
              } else {
                responseNotHasDeudaGestion(
                  propiedades,
                  respInmueble,
                  category,
                  tipoTramite,
                  resDeudas,
                  dateInitTramite,
                  tower
                );
                nextPageCurrent(2, propiedades[0]);
              }
            } else {
              if (
                resDeudas &&
                resDeudas.status &&
                resDeudas.data.gestionesDeuda.length <= 0
              ) {
                debugger;
                input.removeAttribute("readonly");
                selectCategory.removeAttribute("disabled");
                selectTipoTramite.removeAttribute("disabled");
                selectTower.removeAttribute("disabled");
                selectUsoInmueble.removeAttribute("disabled");
                selectAreaInmueble.removeAttribute("disabled");
                buttonCancel.classList.remove("btn-disabled");
                buttonCancel.removeAttribute("disabled");

                responseNotHasDeudaGestion(
                  propiedades,
                  respInmueble,
                  category,
                  tipoTramite,
                  resDeudas,
                  dateInitTramite,
                  tower
                );
                nextPageCurrent(2, propiedades[0]);
              } else {
                if (resDeudas) {
                  let deudasInmueble = resDeudas.data.gestionesDeuda.map(
                    (deuda) => {
                      return deuda.gestion;
                    }
                  );
                  deudasInmueble = deudasInmueble.filter((item, index) => {
                    return deudasInmueble.indexOf(item) === index;
                  });
                  //deudasInmueble = [];    //para evitar la deuda
                  responseHasDeudaGestion(deudasInmueble);
                } else
                  setMessage(
                    "Verifique el número de inmueble o consulte con la administración"
                  );
                setSearchData(false);

                selectCategory.removeAttribute("disabled");
                selectTipoTramite.removeAttribute("disabled");
                selectTower.removeAttribute("disabled");
                selectUsoInmueble.removeAttribute("disabled");
                selectAreaInmueble.removeAttribute("disabled");
                buttonCancel.classList.remove("btn-disabled");
                buttonCancel.removeAttribute("disabled");
                input.removeAttribute("readonly");
              }
            }
          } else {
            input.removeAttribute("readonly");
            selectCategory.removeAttribute("disabled");
            selectTipoTramite.removeAttribute("disabled");
            selectTower.removeAttribute("disabled");
            selectUsoInmueble.removeAttribute("disabled");
            selectAreaInmueble.removeAttribute("disabled");
            buttonCancel.classList.remove("btn-disabled");
            buttonCancel.removeAttribute("disabled");
            setSearchData(false);
          }
        } else {
          fetch.toast.warning("El formulario tiene campos obligatorio", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }
    }
  };

  const responseHasDeudaGestion = (deudasInmueble) => {
    let deudasMessage = "";
    if (deudasInmueble.length > 9) {
      deudasMessage =
        deudasInmueble[0] +
        "," +
        deudasInmueble[1] +
        "," +
        deudasInmueble[2] +
        ", ... ," +
        deudasInmueble[deudasInmueble.length - 3] +
        "," +
        deudasInmueble[deudasInmueble.length - 2] +
        "," +
        deudasInmueble[deudasInmueble.length - 1];
    } else deudasMessage = deudasInmueble.join(",");
    setMessage(
      "No es posible continuar debido a que el inmueble adeuda las gestiones [" +
        deudasMessage +
        "]"
    );
    setSearchData(false);
  };

  const responseNotHasDeudaGestion = (
    propiedades,
    respInmueble,
    category,
    tipoTramite,
    resDeudas,
    fechaInicioTramite,
    tower
  ) => {
    propiedades[0].codigoCatastral =
      respInmueble.data.resInmueble.codigoCatastral;
    propiedades[0].codigoInmueble =
      respInmueble.data.resInmueble.codigoInmueble;
    propiedades[0].numeroInmueble =
      respInmueble.data.resInmueble.numeroInmueble;
    propiedades[0].claseInmueble = respInmueble.data.resInmueble.claseInmueble;
    propiedades[0].category = category;
    propiedades[0].tipoTramite = tipoTramite;
    propiedades[0].dataTecnico = respInmueble.data.resTecnico;
    propiedades[0].derechoAdmision = dataDerechoAdmision;
    propiedades[0].gestionesDeuda = resDeudas.data.gestionesDeuda;
    propiedades[0].fechaInicioTramite = fechaInicioTramite;
    propiedades[0].tower = tower;
  };

  const handleChangeUso = async (event) => {
    debugger;
    const select = event.target;
    const button = document.getElementById("btnModalSmallSubmit");
    if (select.selectedOptions[0].value) {
      if (parseInt(select.selectedOptions[0].value, 10) === 1) {
        //link directo
        button.classList.remove("btn-disabled");
        setUsoSuelo(true);
      } else {
        button.classList.add("btn-disabled");
        setUsoSuelo(false);
        setAreaUrbano(false);
      }
    } else {
      button.classList.remove("btn-disabled");
      setUsoSuelo(true);
    }
  };

  const handleChangeArea = async (event) => {
    debugger;
    const select = event.target;
    const button = document.getElementById("btnModalSmallSubmit");
    if (select.selectedOptions[0].value) {
      if (select.selectedOptions[0].value === "URBANO") {
        button.classList.remove("btn-disabled");
        setAreaUrbano(true);
      }

      if (select.selectedOptions[0].value === "RURAL") {
        //button.classList.add("btn-disabled");
        button.classList.remove("btn-disabled");
        setAreaUrbano(false);
      }
    } else {
      button.classList.add("btn-disabled");
      setAreaUrbano(false);
    }
  };

  const handleChangeSelectCategory = async (event) => {
    debugger;
    setOptionsProcedures([]);
    const optionCategorySelected = event.target.selectedOptions[0];

    if (optionCategorySelected && optionCategorySelected.value) {
      const typeProcedure = fetch.fetchGet(
        `api/saat/tipotramite/${optionCategorySelected.value}/`
      );
      typeProcedure.then((res) => {
        if (res && res.status && res.data) {
          const listItems = res.data.CatTipoTramite.map((item, index) => {
            return (
              <option
                key={index}
                value={item.id}
                name={item.tipoTramite}
                codcat={item.codeCategoria}
                codtiptramite={item.codeTipoTramite}
                hasfechainiciocategoria={item.hasFechaInicio}
              >
                {item.tipoTramite}
              </option>
            );
          });
          setOptionsProcedures(listItems);
        }
      });
      loadTowers(optionCategorySelected.value);
    }
  };

  const handleChangeTipoTramite = async (event) => {
    const optionTipoTramSelected = event.target.selectedOptions[0];
    if (
      parseInt(
        optionTipoTramSelected.getAttribute("hasfechainiciocategoria"),
        10
      ) === 1
    ) {
      setShowDateInit(true);
    } else setShowDateInit(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-12 padding-right-0">
          <img
            className="rounded mx-auto d-block"
            alt="extension-prescripcion"
            src={"/static/img/clasficador.png"}
            width="150"
          />
          <h4 className="text-center mt-3 mb-3">Categoría y Trámite</h4>
        </div>
      </div>

      {message !== "" ? (
        <div className="row">
          <div className="col-12">
            <div
              className="alert alert-warning alert-dismissible fade show text-justify"
              role="alert"
            >
              <strong>Atención!</strong> {message}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="row">
        <div className="col-12 mb-3">
          <select
            className="form-select"
            name={"[id_category]"}
            required
            data-parsley-required="true"
            ref={refSlcCategoria}
            onChange={handleChangeSelectCategory}
          >
            <option defaultValue value="">
              Seleccione Categoría
            </option>
            {optionsCategorys}
          </select>
        </div>
        <div className="col-12 mb-3">
          <select
            className="form-select"
            name={"[id_tower]"}
            required
            data-parsley-required="true"
            ref={refSlcTower}
          >
            <option defaultValue value="">
              Seleccione Torre o Estación
            </option>
            {optionsTowers}
          </select>
        </div>
        <div className="col-12 mb-3">
          <select
            className="form-select"
            name={"[id_tipo_tramite]"}
            required
            data-parsley-required="true"
            ref={refSlcTramite}
            onChange={handleChangeTipoTramite}
          >
            <option defaultValue value="">
              Seleccione Trámite
            </option>
            {optionsProcedures}
          </select>
        </div>

        {showDateInit ? (
          <div className="col-12 mb-3">
            <DatePickerAtm
              placeHolder={"Fecha Inicio Solicitud"}
              name={"fecha_inicio_regularizacion"}
              maxDate={new Date("2022/12/23")}
              mandatory={true}
              minDate={new Date(Config[8].anio + "/01/01")}
            />
          </div>
        ) : (
          ""
        )}

        <div className="col-12 mb-3">
          <select
            className="form-select"
            name={"[uso_inmueble]"}
            required
            data-parsley-required="true"
            ref={refSlcUsoInmueble}
            onChange={handleChangeUso}
          >
            <option defaultValue value="">
              Seleccione Uso Inmueble
            </option>
            <option key={0} value={0}>
              Privado
            </option>
            <option key={1} value={1}>
              Público
            </option>
          </select>
        </div>

        {!usoSuelo ? (
          <div className="col-12">
            <div className="form-group">
              <div className="input-group mb-3">
                <select
                  className="form-select"
                  name={"[area_inmueble]"}
                  id={"[area_inmueble]"}
                  required
                  data-parsley-required="true"
                  ref={refSlcAreaInmueble}
                  onChange={handleChangeArea}
                >
                  <option defaultValue value="">
                    Seleccione Area
                  </option>
                  <option key={0} value={"URBANO"}>
                    Urbano
                  </option>
                  <option key={1} value={"RURAL"}>
                    Rural
                  </option>
                </select>

                {/*
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon0">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="checkBoxAutorizacionPublica"
                      ref={refcheckIsPublicMap}
                      onChange={handleChangeAutorizacionPublica}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="checkBoxAutorizacionPublica"
                    >
                      Propiedad Pública
                    </label>
                  </div>
                </span>
              </div> */}

                {areaUrbano ? (
                  <>
                    <div className="input-group-prepend inputif">
                      <span
                        className="input-group-text"
                        id="basic-addon1"
                        style={{ backgroundColor: "transparent", border: 0 }}
                      ></span>
                    </div>
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text bg-butterfl-bush text-white"
                        id="basic-addon3"
                      >
                        Nro. Inmueble
                      </span>
                    </div>
                    <input
                      name={"saat_autorizacion[numero]"}
                      id={"saat_autorizacion[numero]"}
                      type="text"
                      className="form-control input-uppercase"
                      placeholder="Número"
                      aria-label="Número"
                      aria-describedby="Número"
                      onChange={handleNumeroOnChange}
                      onKeyDown={handleKeyDown}
                      data-parsley-required="true"
                      ref={refInputSearchMap}
                    />
                  </>
                ) : (
                  <>
                    <div className="input-group-prepend inputelse">
                      <span
                        className="input-group-text"
                        id="basic-addon2"
                        style={{ backgroundColor: "transparent", border: 0 }}
                      ></span>
                    </div>
                    <div className="input-group-prepend" style={{ width: 260 }}>
                      <span
                        className="input-group-text"
                        id="basic-addon3"
                        style={{ backgroundColor: "transparent", border: 0 }}
                      ></span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {searchData ? (
        <div className="row">
          <div className="col-12 mb-1">
            <img
              className="rounded mx-auto d-block"
              alt="pulse-loading"
              src={"/static/img/pulse_200.gif"}
              width="70"
            />
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="row mt-3">
        <div className="col-12 col-md-6 col-lg-6 mb-3 text-start ">
          <div className="col-12 search-btn button ">
            <input
              className="btn bg-maroon-flush btn-full-screen"
              name="submit"
              type="submit"
              value="Cancelar"
              ref={refInputCancel}
              onClick={handleCancelClick}
            />
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-6 mb-3 text-end ">
          <div className="col-12 search-btn button ">
            <input
              className="btn btn-disabled btn-full-screen"
              name="submit"
              id="btnModalSmallSubmit"
              type="submit"
              value="Buscar"
              onClick={handleSearchInmuebleClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ValidateTipoTramite;
