import { useState, useEffect } from 'react';

const CountDownTimer = (totalSegundos) => {
  //const secondsTotal = toSecond >= 30 ? toSecond : 60; // minimo debe ser 30 segundos
  const [seconds, setSeconds] = useState(totalSegundos);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(intervalId); // Detener el temporizador cuando llegue a cero
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return seconds;
};

export default CountDownTimer;
