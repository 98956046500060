import React, { useState, useEffect, useRef } from "react";
import Links from "./../../data/link";
import Consulta from "./Consulta";
import HeaderTitlecard from "./_common/HeaderTitleCard";
import Constant from "../../data/constant";
import constant from "../../data/constant";

const Step1FormValideFur = (props) => {
  const { nextClick, fetch, toast, auth, history, stepNumber } = props;

  const refSelectClasification = useRef(null);
  const refInputFur = useRef(null);

  const [typeProcess, setTypeProcess] = useState("");
  const [optionsClasification, setOptionsClasification] = useState([]);
  const [furOrCrapeIsValid, setFurOrCrapeIsValid] = useState(false);
  const [dataAdvetisingCrape, setDataAdvertisingCrape] = useState(null);
  const [showInputCrape, setShowInputCrape] = useState(false);
  const [showViewQR, setShowViewQR] = useState(false);

  const consultaSQL = new Consulta();

  if (!auth.loggedIn()) history.replace(Links[4].url);
  useEffect(() => {
    optionsClasifications()
  }, []);

  // generar QR
  const clickGenerateQr = (event) => {
    event.preventDefault();
    setShowViewQR(!showViewQR);
  }

  const handleKeyDownValideFur = (event) => {
    const input = event.target;
    if (event.key === 'Enter' || event.keyCode === 13) {
      if (input.value && parseInt(input.value)) {
        debugger
        document.getElementById("nextButtonStep1").setAttribute('disabled', "");
        const selectClasification = refSelectClasification.current;
        const selectItem = selectClasification.selectedOptions[0];
        requestSearchFur(input.value, selectItem.getAttribute("code"));
      }
    }
  }

  const handleClickValideFurOrCrape = (event) => {
    debugger
    const button = event.target;
    const inputFur = refInputFur.current.value;

    if (inputFur && inputFur.length > 3) {
      if (typeProcess == "CRAPE") {
        searchInfoCrapeEmpresa(inputFur)
      } else {
        const selectClasification = refSelectClasification.current;
        const selectItem = selectClasification.selectedOptions[0];
        requestSearchFur(inputFur, selectItem.getAttribute("code"));
      }
    }
  }

  const requestSearchFur = async (furParam, codeClasification) => {
    setFurOrCrapeIsValid(false);
    codeClasification = codeClasification === Constant[0].publicidad_clasificador.code_empresarial ? "CRAPE" : codeClasification;
    const query = `query {
      existAdvertisingWhitFur ( advertising: {fur: "${furParam}"} ) {
        message
        result
      }
    }`;
    const verifyFur = await fetch.fetchPostGraphQl(query, "/graphql", null);
    if (verifyFur && verifyFur.existAdvertisingWhitFur && !verifyFur.existAdvertisingWhitFur.result) {
      fetch.fetchGet("api/validar-fur-publicidad/" + furParam + "/" + codeClasification)
        .then((fur) => {
          if (fur && fur.status && fur.Fur) {
            const glosa = fur.Fur.glosa1; // + "( "+fur.Fur.glosa2+ ")"
            document.getElementById("nextButtonStep1").removeAttribute('disabled');
            setFurOrCrapeIsValid(true);
            toast.success('Fur verificado exitosamente. ' + glosa);
          } else {
            setFurOrCrapeIsValid(false);
          }
        });
    } else
      toast.warning(verifyFur.existAdvertisingWhitFur.message);
  }

  // verificar crape
  const searchInfoCrapeEmpresa = (nroCrape) => {
    debugger;
    try {

      setFurOrCrapeIsValid(false);
      const query = consultaSQL.queryInfoAdvertisingByNumeroLicenciaAdmision(nroCrape);
      fetch.fetchPostGraphQl(query, "/graphql", null).then((data) => {

        if (data.advertisingByNumeroLicenciaByAdmision && data.advertisingByNumeroLicenciaByAdmision.result) {
          debugger;
          const advertisingData = data.advertisingByNumeroLicenciaByAdmision.data.advertising;
          const esEmpresaEstructura = (advertisingData.clasificationCategoriesAdv) ? advertisingData.clasificationCategoriesAdv[0].clasificationCategoryPublicity.clasification.code === constant[0].publicidad_clasificador.code_empresarial : false;
          if (esEmpresaEstructura) {
            setDataAdvertisingCrape(advertisingData);
            setFurOrCrapeIsValid(true);
            toast.success('Estructura Publicitaria localizada y validada.');
            document.getElementById("nextButtonStep1").removeAttribute('disabled');
          }
        } else {
          debugger
          document.getElementById("nextButtonStep1").setAttribute('disabled', "");
          //toast.error(data.advertisingByNumero.message);
          toast.error("El número de licencia del CRAPE ingresado no es válido.")
        }
      });

    } catch (error) {
      toast.error("Ocurrio un error al momento de realizar la validación del número de licencia de CRAPE.");
    }

  }

  const optionsClasifications = () => {
    const query = consultaSQL.queryClasifications();
    fetch
      .fetchPostGraphQl(query, "/graphql", null)
      .then((data) => {
        if (data.clasifications && data.clasifications.result) {
          const clasification = data.clasifications.data.clasification;
          const listItems = clasification.map((item, index) => {
            return (
              <option
                key={index}
                value={item.id}
                code={item.code}
              >
                {item.name}
              </option>
            );
          });
          setOptionsClasification(listItems);
        }
      });
  }

  const nextForm = (e) => {
    e.preventDefault();
    debugger
    if (furOrCrapeIsValid) {
      const selectClasification = refSelectClasification.current;
      const inputFur = refInputFur.current;
      const selectItem = selectClasification.selectedOptions[0];

      if (selectItem.value) {
        const data = {
          clasification: {
            id: selectItem.value,
            code: selectItem.getAttribute("code"),
            name: selectClasification.options[selectClasification.selectedIndex].text
          },
          fur: inputFur.value,
          tipoFormulario: typeProcess,
          dataAdvetisingCrape: dataAdvetisingCrape
        }
        nextClick(e, stepNumber, false, data);
      } else {
        nextClick(e, stepNumber, false, {});
      }
    } else {
      toast.warning('Derecho de Admisión no verificado');
    }
  }

  // habilitar crape cuando sea empresarial
  const onchangeClasification = (e) => {
    e.preventDefault();
    debugger
    const codeClasification = refSelectClasification.current;
    const selectItem = codeClasification.selectedOptions[0];

    if (selectItem.getAttribute("code")) {

      setTypeProcess("FUR");
      setFurOrCrapeIsValid(false);
      const inputFur = document.getElementById("input-fur");

      if (selectItem.getAttribute("code") === Constant[0].publicidad_clasificador.code_empresarial) {
        setShowInputCrape(false);
        if (inputFur)
          inputFur.setAttribute("placeholder", "Ingresa Nro Fur");
      } else {
        setShowInputCrape(true);
        if (inputFur)
          inputFur.setAttribute("placeholder", "Ingresa Nro Fur");
      }
    } else
      setTypeProcess("");

  }

  const handleCheckboxChange = (e) => {
    e.preventDefault();
    debugger
    const checkbox = e.target;
    setShowInputCrape(true);
    setTypeProcess("FUR");

    setTimeout(() => {
      debugger
      const inputFur = document.getElementById("input-fur");

      inputFur.setAttribute("type", "fur");
      if (checkbox.name === "flexRadioFur") {
        inputFur.setAttribute("type", "fur");
        inputFur.setAttribute("placeholder", "Ingresa Nro Fur");
      }

      if (checkbox.name === "flexRadioCrape") {
        inputFur.setAttribute("type", "crape");
        inputFur.setAttribute("placeholder", "Ingresa Nro Crape");
        setTypeProcess("CRAPE");
      }
    }, 300);
  }

  const handleClikQR = (event) => {
    event.preventDefault();
    const button = event.target.closest("div");
    if(button.childNodes[1].classList.contains("text-mandy")){
      button.childNodes[1].classList.remove("text-mandy");
      button.childNodes[2].classList.remove("text-mandy");
      button.childNodes[1].classList.add("text-fountain-blue");
      button.childNodes[2].classList.add("text-fountain-blue");
    }
    window.open("https://www.ruat.gob.bo/pagosqr/InicioPagosQrIngNoTrib.jsf?SDG3WF24=9", "_blank");
  }

  return (
    <div className="main-wizard active-f-w">
      <HeaderTitlecard titulo={'Derecho de Admisión'} descripcion={Constant[0].publicidad_step_new[0]} />
      <form
        className=""
        id={"formValidateFur"}
      >

        <div className="row">
          <div className="col-lg-6 col-xs-12 mb-2">
            <select
              className="form-select"
              data-parsley-required="true"
              name="input-clasificador"
              ref={refSelectClasification}
              onChange={onchangeClasification}
            >
              <option value="">Seleccione Clasificador</option>
              {optionsClasification}
            </select>
          </div>

          <div className="col-lg-6 col-xs-12 mb-2">
            {
              typeProcess ?

                !showInputCrape ?
                  <div className="input-group mt-2">
                    <div className="form-check pe-5">
                      <input className="form-check-input" type="radio" name="flexRadioFur" id="flexRadioFur" onChange={handleCheckboxChange} />
                      <label className="form-check-label" htmlFor="flexRadioFur"> &nbsp; Nro. Fur</label>
                    </div>

                    <div className="input-group-append">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="flexRadioCrape" id="flexRadioCrape" onChange={handleCheckboxChange} />
                        <label className="form-check-label" htmlFor="flexRadioCrape"> &nbsp; Nro. Crape</label>
                      </div>
                    </div>
                  </div>
                  : <div className="input-group">
                    <input
                      type="text"
                      className="form-control input-uppercase"
                      id="input-fur"
                      name="input-fur"
                      data-parsley-required="true"
                      data-parsley-minlength="4"
                      placeholder=""
                      onKeyDown={handleKeyDownValideFur}
                      ref={refInputFur}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={handleClickValideFurOrCrape}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                : ""
            }

          </div>
        </div>

        <div className="row mt-2">
          <p onClick={clickGenerateQr} className="pointer border-radius-035 btn-link-cyan">Si no cuentas con tu derecho de admisión clic para generar código QR
            <i className="fas fa-qrcode ps-2 h4"></i>
          </p>
        </div>

        {
          showViewQR ? <div className="row mt-2">
            <div className="col-6 col-md-3 text-center mb-3 pointer" onClick={handleClikQR}>
              <i className="fas fa-qrcode display-1 text-butterfly-bush" ></i>
              <p className="text-uppercase fw-normal text-mandy" >DA121</p>
              <p className="text-uppercase fw-normal text-mandy">Act. Económica</p>
            </div>
            <div className="col-6 col-md-3 text-center mb-3 pointer" onClick={handleClikQR}>
              <i className="fas fa-qrcode display-1 text-butterfly-bush"></i>
              <p className="text-uppercase fw-normal text-mandy">DA122</p>
              <p className="text-uppercase fw-normal text-mandy">Empresarial</p>
            </div>
            <div className="col-6 col-md-3 text-center mb-3 pointer" onClick={handleClikQR}>
              <i className="fas fa-qrcode display-1 text-butterfly-bush"></i>
              <p className="text-uppercase fw-normal text-mandy">DA123</p>
              <p className="text-uppercase fw-normal text-mandy">Eventual</p>
            </div>
          </div> : ""
        }

        <div className="buttons text-end mt-3">
          <button className={furOrCrapeIsValid ? "next_button btn btn-violeta" : "next_button btn btn-violeta disabled"} id="nextButtonStep1" onClick={nextForm}>Continuar</button>
        </div>
      </form>
    </div>
  );
};

export default Step1FormValideFur;

