import React, { Component } from 'react';

class TerminosCondiciones extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render = () => {
        return <section className="items-grid section mt-5">
            <div className="container mt-4">
                <div className="row">
                    <div className="col-md-12">
                        <div className="error-template">
                            <h1> Términos y Condiciones</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }
}

export default TerminosCondiciones;