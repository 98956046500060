import React, { useState, useRef, useEffect, useSyncExternalStore } from "react";
import Links from "../../data/link";
import ViewDataJuridico from "../component/Contribuyente/ViewDataJuridico";
import ViewDataNatural from "../component/Contribuyente/ViewDataNatural";
import HeaderTitlecard from "./_common/HeaderTitleCard";
import Constant from "../../data/constant";
//import Texto from "../../data/es";

const StepFormContribuyente = (props) => {
    const { nextClick, fetch, backClick, toast, auth, history, stepNumber, dataForm } = props;

    const [loadingSearch, setLoadingSearch] = useState(false);
    const [isNatural, setIsNatural] = useState(false);
    const [isJuridico, setIsJuridico] = useState(false);
    const [dataJuridico, setDataJuridico] = useState(null);
    const [contribuyentesSearchList, setContribuyentesSearchList] = useState(undefined);
    const [dataNatural, setDataNatural] = useState(null);
    const [nextButton, setNextButton] = useState(false);
    const [existMorePMC, setExistMorePMC] = useState(false);

    const refSelectDocIdentification = useRef(null);
    const refInputDocIdentification = useRef(null);

    if (!auth.loggedIn()) history.replace(Links[4].url);
    debugger

    useEffect(() => {
        debugger;
    }, [dataForm.datosTecnicos]);

    const handleClickSearchContribuyente = async (event) => {
        event.preventDefault();
        debugger
        searchContribuyente(event);
    }

    const handlekeyDownSearchContribuyente = async (event) => {
        if (event.key === "Enter" && event.target.value.length >= 3) {
            debugger
            event.preventDefault();
            const form = window.jQuery("#formValidePropietario");
            form.parsley().validate();
            if (form.parsley().isValid()) {
                searchContribuyente(event);
            }
        }
    }

    const searchContribuyente = (event) => {
        event.preventDefault();
        debugger;
        const optionSelected = refSelectDocIdentification.current.selectedOptions[0].value;
        const inputSearch = refInputDocIdentification.current.value;

        if (inputSearch.length > 0 && optionSelected) {
            setLoadingSearch(true);
            setIsNatural(false);
            setIsJuridico(false);
            setNextButton(false);
            setExistMorePMC(false);
            fetch
                .fetchGet(
                    `api-ruat/contribuyentes/search-nit-ci/${inputSearch}/${optionSelected.toUpperCase()}`
                )
                .then((res) => {
                    debugger;
                    if (res && res.status) {
                        if (res.data.contribuyente.length === 1) {
                            //const contribuyentes = res.data.contribuyente[0];
                            setContribuyentesSearchList(undefined);
                            dataContribuyente(res.data.contribuyente[0]);
                            setExistMorePMC(false);
                        } else {
                            setContribuyentesSearchList(res.data.contribuyente);
                            setExistMorePMC(true);
                        }
                    }
                    setLoadingSearch(false);
                    window.inputConvertUpperCaseForm("formSearchDataTable");
                });
        } else
            toast.info('Seleccione Tipo Documento');
    };

    const nextForm = (event) => {
        debugger
        event.preventDefault();
        window.scrollTo(0, 0);

        if (nextButton) {
            let next = 1;

            const data = {
                natural: dataNatural,
                juridico: dataJuridico,
                isNatural: dataNatural ? true : false
            };

            if (!dataNatural && !dataJuridico)
                next = 0;

            if (next === 1)
                nextClick(event, stepNumber, true, data)
            else
                toast.info("Datos del Propietario y/o Representante legal son requeridos");
        } else
            toast.info('Datos del Propietario y/o Representante legal son requeridos');
    }

    const handleEventSelectedPmc = (event, contribuyente) => {
        event.preventDefault();
        dataContribuyente(contribuyente);
        setExistMorePMC(false)
    }

    const dataContribuyente = (contribuyentes) => {
        if (contribuyentes.tipoContribuyente === "NATURAL") {
            let propietario = contribuyentes.natural
                ? contribuyentes.natural
                : undefined;
            const infomacionAdicional = contribuyentes.informacionAdicional;

            const persona = {
                tipoDocumento: propietario.tipoDocumento,
                numeroDocumento: propietario.numeroDocumento,
                nombre: propietario.nombres,
                apellidoPaterno: propietario.primerApellido,
                apellidoMaterno: propietario.segundoApellido,
                apellidoCasada: propietario.apellidoEsposo
                    ? propietario.apellidoEsposo
                    : "",
                estadoCivil: propietario.estadoCivil,
                genero: propietario.genero,
                fechaNacimiento: propietario.fechaNacimiento,
                expedidoEn: propietario.expedido,
                tipoDocumentoCode: propietario.tipoDocumentoCode,
                pmc: contribuyentes.pmc,
                codigoContribuyente: contribuyentes.codigoContribuyente,
                direccion: contribuyentes.domicilioLegal,
                celular: infomacionAdicional ? infomacionAdicional.celular : "",
                contribuyente: "NATURAL",
                //id: propietario.id,
            };

            const apoderado = contribuyentes.apoderado;
            const poder = contribuyentes.poder;
            setDataNatural({
                dataNatural: persona,
                dataApoderado: apoderado,
                dataPoder: poder,
            });
            setIsNatural(true);
        }

        if (contribuyentes.tipoContribuyente === "JURIDICO") {
            let representante = contribuyentes.apoderado
                ? contribuyentes.apoderado
                : undefined;

            let representateLegal = undefined;
            if (representante) {
                representateLegal = {
                    tipoDocumento: representante.tipoDocumento,
                    numeroDocumento: representante.numeroDocumento,
                    nombre: representante.nombres,
                    apellidoPaterno: representante.primerApellido,
                    apellidoMaterno: representante.segundoApellido,
                    apellidoCasada: representante.apellidoEsposo
                        ? representante.apellidoEsposo
                        : "",
                    estadoCivil: representante.estadoCivil,
                    genero: representante.genero,
                    fechaNacimiento: representante.fechaNacimiento,
                    expedidoEn: representante.expedido,
                    tipoDocumentoCode: representante.tipoDocumento,
                    pmc: 0,
                    contribuyente: "JURIDICO",
                    razonSocial: contribuyentes.juridico
                        ? contribuyentes.juridico.razonSocial
                        : undefined,
                };
            }

            let propietarioJuridico = undefined;
            if (contribuyentes.juridico) {
                propietarioJuridico = contribuyentes.juridico;
                propietarioJuridico.nombreContribuyente =
                    contribuyentes.nombreContribuyente;
                propietarioJuridico.pmc = contribuyentes.pmc;
                propietarioJuridico.codigoContribuyente = contribuyentes.codigoContribuyente;
                propietarioJuridico.domicilioLegal =
                    contribuyentes.domicilioLegal;
                propietarioJuridico.tipoContribuyente =
                    contribuyentes.tipoContribuyente;
                propietarioJuridico.pmc = contribuyentes.pmc;
            }
            const infomacionAdicional = contribuyentes.informacionAdicional;
            const poder = contribuyentes.poder;

            setDataJuridico({
                dataJuridico: propietarioJuridico,
                dataApoderado: representateLegal,
                dataPoder: poder,
                dataInformationAditional: infomacionAdicional,
            });
            setIsNatural(false);
            setIsJuridico(true);
            setDataNatural(undefined);
        }
        setNextButton(true);
    }

    const handleOnChange = (event) => {
        event.preventDefault();
        const selected = event.target;
        const option = selected.selectedOptions[0];
        const inputDocIdentLabel = refInputDocIdentification.current.closest(".input-div").firstChild
        const inputDocIdent = refInputDocIdentification.current

        debugger
        inputDocIdentLabel.innerText = "(*) Nro Documento";
        refInputDocIdentification.current.setAttribute("placeholder", "Nro Documento")
        if (option.value === "CI"){
            inputDocIdentLabel.innerText = "(*) Cédula de Identidad";
            //inputDocIdent.placeholder
            refInputDocIdentification.current.setAttribute("placeholder", "Nro de Cédula de Identidad")
        }
            
        if (option.value === "NIT"){
            inputDocIdentLabel.innerText = "(*) Nro. Identificación Tributaria";
            refInputDocIdentification.current.setAttribute("placeholder", "Nro. Identificación Tributaria")
        }
    }

    return (
        <div className="main-wizard ">
            <HeaderTitlecard titulo={'Contribuyente'} descripcion={Constant[0].publicidad_step_new[4]} visibleRequired={true} />

            <form
                className="contact__form"
                id={"formValidePropietario"}
            >
                <div className="input-text">
                    <div className="input-div">
                        <label htmlFor={"type"} className="form-label">
                            (*) Tipo Contribuyente
                        </label>
                        <select
                            className="form-select"
                            name="type"
                            required
                            data-parsley-required="true"
                            onChange={handleOnChange}
                            ref={refSelectDocIdentification}
                        >
                            <option key="0" value="" defaultValue>
                                Seleccione Tipo Contribuyente
                            </option>
                            <option key="1" value="CI">
                                Natural (CI)
                            </option>
                            <option key="2" value="NIT">
                                Jurídico (NIT)
                            </option>
                        </select>
                    </div>
                    <div className="input-div">
                        <label htmlFor={"type"} className="form-label">
                            (*) Nro Documento
                        </label>
                        <div className="input-group ">
                            <input
                                type="text"
                                className="form-control input-uppercase"
                                name="datosTecnicos-nroDocumento"
                                placeholder="Nro Documento"
                                aria-label="Nro Documento"
                                aria-describedby="basic-addon2"
                                required
                                data-parsley-required="true"
                                onKeyDown={handlekeyDownSearchContribuyente}
                                ref={refInputDocIdentification}
                            />
                            <div className="input-group-append">
                                <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={handleClickSearchContribuyente}
                                >
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {loadingSearch ? (
                    <div className="row">
                        <div className="col-12 mb-1">
                            <img
                                className="rounded mx-auto d-block"
                                alt="pulse-loading"
                                src={"/static/img/pulse_200.gif"}
                                width="60"
                            />
                        </div>
                    </div>
                ) : (
                    ""
                )}

                {existMorePMC ?
                    <div className="card widget widget-simple">
                        <div className="card-body ">
                            <div className="widget-content">
                                <div className="widget-body">
                                    <div className="row">
                                        <div className="col-12">
                                            {contribuyentesSearchList.map((contribuyente, index) => {
                                                return (
                                                    <button
                                                        key={index}
                                                        onClick={(e) =>
                                                            handleEventSelectedPmc(e, contribuyente)
                                                        }
                                                        className={contribuyente.contribuyenteActualizado === "0" ? "btn btn-outline-dark btn-lg col-sm-3 mb-2 me-4" : "btn btn-outline-success btn-lg col-sm-3 mb-2 me-4"}
                                                        style={{ fontSize: '15px' }}
                                                        disabled={contribuyente.contribuyenteActualizado === "0" ? true : false}
                                                    >
                                                        {contribuyente.contribuyenteActualizado === "0" && (<i className="fa fa-exclamation-circle text-danger" title="No actualizado"></i>)} {" "}
                                                        {contribuyente.pmc}
                                                    </button>
                                                );
                                            })
                                            }
                                        </div>
                                        {/*
                                            totalNoActualizado > 0 ?
                                                <div className="col-12 pt-4 text-justify" style={{ fontSize: '12px' }} >
                                                    <i className="fa fa-exclamation-circle text-danger"></i> {Texto.ruat_search_pmc_error}
                                                </div>
                                                :
                                                <></>
                                        */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""}

                {isNatural && !isJuridico ? (
                    <ViewDataNatural
                        dataNatural={dataNatural.dataNatural}
                        dataApoderado={dataNatural.dataApoderado}
                        dataPoder={dataNatural.dataPoder}
                        showTitle={false}
                        showButtonAdd={false}
                    />
                ) : (
                    ""
                )}

                {!isNatural && isJuridico ? (
                    <ViewDataJuridico
                        dataJuridico={dataJuridico.dataJuridico}
                        dataApoderado={dataJuridico.dataApoderado}
                        dataPoder={dataJuridico.dataPoder}
                        dataInformationAditional={dataJuridico.dataInformationAditional}
                        showButtonAdd={false}
                        showTitle={false}
                        isVisacion={false}
                    />
                ) : (
                    ""
                )}
                <div className="buttons button_space justify-content-between">
                    <a className="back_button btn btn-fucsia" onClick={backClick}>Volver</a>
                    <button className={nextButton ? "next_button btn btn-violeta" : "next_button btn btn-violeta disabled"} onClick={nextForm}>Continuar</button>
                </div>
            </form>
        </div>
    );
};

export default StepFormContribuyente;
