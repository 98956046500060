import React, { useState, useEffect } from "react";
import Fetch from "../utils/Fetch";
import { Link } from "react-router-dom";
import AuthService from "../Usuario/AuthService";
import Links from "../../data/link";

const ModalRequisitos = (props) => {
  const { toast, title, userAuth, Constant, autorization, clearRequisitos } =
    props;

  const fetch = new Fetch();
  fetch.setToast(toast);

  const auth = new AuthService();

  const idModal = "modalRequisitosAutorization";
  let modal = undefined;

  const [nodeRequirements, setNodeRequirements] = useState([]);

  useEffect(() => {
    if (!auth.loggedIn()) window.location.href = Links[4].url;
    openModalRequeriments(autorization.token);
    window.scrollTo(0, 0);
  }, []);

  const openModalRequeriments = (tokenAutorization) => {
    modal = new window.bootstrap.Modal(document.getElementById(idModal), {
      keyboard: true,
      backdrop: "static",
      focus: true,
    });
    modal.show();
    window.jQuery("#" + idModal).on("shown.bs.modal", function (e) {
      paintRequirements(autorization);
    });
  };

  const paintRequirements = (autorization) => {
    debugger;
    fetch
      .fetchGet(
        `api/saat/requisito-autorizacion-by-token-autorization/${autorization.token}`
      )
      .then((dataJson) => {
        if (dataJson && dataJson.status) {
          let cont = 0;
          const tableRequisitos = dataJson.data.requisitos.map(
            ({ name, status, url, statusCode, mime, token }) => {
              cont++;
              return (
                <div
                  className={"row pointer row-table-custom pt-3 "}
                  code={token}
                  key={cont + "_" + token}
                >
                  <div className="col-5 col-xs-5 col-md-7 ">
                    <p className="fs-small9em">{name}</p>

                    {autorization.estado === Constant.estado.completado &&
                    statusCode === Constant.estado.rechazado ? (
                      <>
                        <strong>Documento: </strong>
                        <span className={`doc-name-${token}`}></span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-3 col-xs-3 col-md-2 text-center">
                    <p className="fs-small9em">{status}</p>
                    <img
                      className="rounded mx-auto d-block d-none"
                      alt="pulse-loading"
                      src={"/static/img/pulse_200.gif"}
                      id={"img-spinner-" + token}
                      width="50"
                    />
                  </div>

                  <div className="col-2 col-xs-2 col-md-1 text-center">
                    <Link
                      to="#"
                      className="pt-3"
                      onClick={(e) => handleOpenFile(e, url)}
                      title={`Visualizar Documento en una nueva ventana`}
                    >
                      {mime.includes("pdf") ? (
                        <i className="fas fa-file-pdf h2"></i>
                      ) : (
                        <i className="far fa-image h2"></i>
                      )}
                    </Link>
                  </div>
                  <div className="col-2 col-xs-2 col-md-2 text-center">
                    {(userAuth.code === Constant.grupos.funcionario ||
                      userAuth.code === Constant.grupos.administrador) &&
                    autorization.permissions &&
                    autorization.permissions.includes(
                      Constant.permission.aprobar
                    ) &&
                    autorization.estado === Constant.estado.completado &&
                    statusCode === Constant.estado.pendiente_aprobacion ? (
                      <>
                        <Link
                          to="#"
                          className=""
                          onClick={(e) =>
                            handleChangeState(
                              e,
                              token,
                              "APROBAR",
                              autorization.token
                            )
                          }
                          title={`Aprobar Requisito`}
                        >
                          <i className="fas fa-check-circle h2"></i>
                        </Link>
                        <br />
                      </>
                    ) : (
                      ""
                    )}

                    {(userAuth.code === Constant.grupos.funcionario ||
                      userAuth.code === Constant.grupos.administrador) &&
                    autorization.permissions &&
                    autorization.permissions.includes(
                      Constant.permission.rechazar
                    ) &&
                    autorization.estado === Constant.estado.completado &&
                    statusCode === Constant.estado.pendiente_aprobacion ? (
                      <Link
                        to="#"
                        className="mt-1"
                        onClick={(e) =>
                          handleChangeState(
                            e,
                            token,
                            "RECHAZAR",
                            autorization.token
                          )
                        }
                        title={`Rechazar Requisito`}
                      >
                        <i className="fas fa-times-circle h2"></i>
                      </Link>
                    ) : (
                      ""
                    )}

                    {autorization.estado === Constant.estado.completado &&
                    statusCode === Constant.estado.rechazado ? (
                      <div className="form-group files mb-3 text-center">
                        <label tabIndex={token} className="form-control">
                          <input
                            type="file"
                            className="form-control"
                            multiple=""
                            id={`saat_cat_req_autorization_${token}`}
                            name={`saat_cat_req_autorization[${token}]`}
                            data-parsley-max-file-size="7100"
                            onChange={(e) =>
                              hanldeOnchangeInputFile(
                                e,
                                autorization.token,
                                token
                              )
                            }
                            title=""
                          />
                        </label>
                        <small className="fs-small8em text-maroon-flush">7MB, Limite Máximo</small>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            }
          );

          setNodeRequirements(tableRequisitos);
        }
      });
  };

  const handleChangeState = (
    event,
    tokenCatReq,
    codeStatus,
    tokenAutorization
  ) => {
    event.preventDefault();
    debugger;
    const butonEvent = event.target;
    const parentContainer = butonEvent.closest(`[code='${tokenCatReq}']`);

    const form = new FormData();
    form.append("saat_cat_req_autorization[token]", tokenCatReq);
    form.append("saat_cat_req_autorization[status]", codeStatus);
    //form.append("saat_cat_req_autorization[description]", 0);
    fetch
      .fetchPost(
        form,
        `api/saat/requisito-autorizacion/change-state`,
        undefined
      )
      .then(async (dataJson) => {
        if (dataJson && dataJson.status) {
          await refrestRow(parentContainer, tokenAutorization, tokenCatReq);

          toast.success(dataJson.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };

  const handleOpenFile = (event, url) => {
    event.preventDefault();
    window.open(url);
  };

  const handleCloseModal = (event) => {
    clearRequisitos();
  };

  const hanldeOnchangeInputFile = (event, tokenAutorization, tokenCatReq) => {
    const inputFile = event.target;
    const parentContainer = inputFile.closest(`[code='${tokenCatReq}']`);
    const divContainerFather = inputFile.closest(".row");

    let iconFile = inputFile.parentElement.parentElement.querySelector("i");
    if (iconFile) inputFile.parentElement.parentElement.removeChild(iconFile);

    if (inputFile.files.length > 0) {
      divContainerFather.firstChild.children[2].innerHTML = `${inputFile.files[0].name}`;
      const spinner =
        divContainerFather.children[1].getElementsByTagName("img")[0];
      spinner.classList.remove("d-none");

      const format = inputFile.files[0].type.split("/");
      if (/^(gif|jpe?g|png|pdf)$/i.test(format[1])) {
        const icono = document.createElement("i");
        if (format[1] === "pdf") {
          icono.className = "fas fa-file-pdf icon";
        }

        if (/^(gif|jpe?g|png)$/i.test(format[1])) {
          icono.className = "fas fa-file-image icon";
        }

        inputFile.parentElement.parentElement.appendChild(icono);

        const form = new FormData();
        form.append("saat_autorization[token]", tokenAutorization);
        form.append("saat_cat_req_autorization[token]", tokenCatReq);
        form.append("saat_cat_req_autorization_file", inputFile.files[0]);

        fetch
          .fetchPost(form, `api/saat/requisito-autorizacion/update`, undefined)
          .then(async (dataJson) => {
            debugger;
            if (dataJson && dataJson.status) {
              spinner.classList.add("d-none");
              await refrestRow(parentContainer, tokenAutorization, tokenCatReq);
            }
          });
      } else
        toast.warning("Formato no valido", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
    }
  };

  const refrestRow = async (
    parentContainer,
    tokenAutorization,
    tokenCatReq
  ) => {
    const resp = await fetch.fetchGet(
      `api/saat/requisito-autorizacion-by-token-autorization-and-token-requirement/${tokenAutorization}/${tokenCatReq}`
    );

    if (resp && resp.status) {
      const childs = parentContainer.children;
      if (childs && childs.length > 0) {
        childs[1].firstChild.innerHTML = resp.data.requisito.status;
        childs[3].innerHTML = "";

        childs[2]
          .querySelector("a")
          .setAttribute("href", resp.data.requisito.url);
        const imgLinkClone = childs[2].querySelector("a").cloneNode(true);

        childs[2].innerHTML = "";
        imgLinkClone.setAttribute("target", "_blank");
        childs[2].appendChild(imgLinkClone);
      }
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id={idModal}
        tabIndex="-1"
        aria-labelledby="movalPdfFullLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="titleAutorizationPdfFullLabel">
                {title}
              </h5>
              <button
                type="button"
                className="btn-close h6 me-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body me-3 ms-3">
              <div id="spinner-loading-modal-pdf" className="row d-none mt-3">
                <div className="col-12 mb-1">
                  <img
                    className="rounded mx-auto d-block"
                    alt="pulse-loading"
                    src={"/static/img/pulse_200.gif"}
                    width="70"
                  />
                </div>
              </div>

              <div className="row">
                <div className={"row pointer row-table-custom pt-3 "}>
                  <div className="col-5 col-xs-5 col-md-8 ">
                    <strong>Requisito</strong>
                  </div>
                  <div className="col-3 col-xs-3 col-md-2 text-center">
                    <strong>Estado</strong>
                  </div>
                  <div className="col-2 col-xs-2 col-md-1 text-center">
                    <strong>Documento</strong>
                  </div>
                  <div className="col-2 col-xs-2 col-md-1 text-center"></div>
                </div>

                <div className="col-12 col-md-12 col-lg-12 embed-container overflow-visible">
                  {nodeRequirements}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="col-12 search-btn button text-end">
                <button
                  type="button"
                  className="btn bg-maroon-flush"
                  data-bs-dismiss="modal"
                  onClick={handleCloseModal}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalRequisitos;
