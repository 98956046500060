import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Fetch from '../utils/Fetch';
import Config from '../../data/config';
import Links from '../../data/link';
import TitlePage from '../utils/TitlePage';
import Texto from '../../data/es';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let breadcrumbs = [
    {
        title: Links[0].title,
        url: Links[0].url
    },
    {
        title: Links[82].title,
        url: Links[82].url
    }
];

const Home = (props) => {

    const fetch = new Fetch();
    fetch.setToast(toast);
    const history = useHistory();

    const titlePage = Texto.certificaciones;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleRedirectUrlClick = (ev, url) => {
        ev.preventDefault();
        const form = new FormData();
        fetch.fetchPost(form, 'api/int/integracion-contribuyentes-certificados', undefined).then(dataJson => {
            if (dataJson && dataJson.status)
                history.push(url+ "?csrf-token=" + dataJson.csrfToken + "&expiraEn=" + dataJson.expiraEn+"&lo="+dataJson.lo);
        })
    }

    return (
        <section className="items-grid section mt-5">
            <div className="container mt-4">
                <TitlePage titlePage={titlePage} breadcrumbs={breadcrumbs} position={'left'} leftfull={false} />
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                        <div className="single-news wow fadeInUp" data-wow-delay=".2s" 
                        onClick={e => handleRedirectUrlClick(e, Links[99].url+'/veh')}
                        rel="noreferrer noopener">
                            <div className="image">
                                <img className='thumb' alt='img-consulta-deuda-vehiculo'
                                    src={Config[2].url + '/static/img/bt_vehiculo.png'} />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                        <div className="single-news wow fadeInUp" data-wow-delay=".2s" 
                        onClick={e => handleRedirectUrlClick(e, Links[50].url+'/inm')}
                        rel="noreferrer noopener">
                            <div className="image">
                                <img className='thumb' alt='img-consulta-deuda-inmueble'
                                    src={Config[2].url + '/static/img/bt_inmuebles.png'} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Home;