import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../style/input-file-drag.css";
import Texto from "../../data/es";
import PasosNavigationAutorizacion from "../utils/PasosNavigationAutorizacion";

const FormRequisitos = (props) => {
  const { dataInmueble, onSubmitForm, fetch, dataRequisitoDb } = props;
  const idForm = "formDataRequisitos";
  const [message, setMessage] = useState(undefined);
  const [nodeRequisitos, setNodeRequisitos] = useState([]);

  useEffect(() => {
    debugger;
    if (dataRequisitoDb) {
      loadRequisitoFromDb(dataRequisitoDb);
    } else
      loadRequisitos(
        dataInmueble.tipoTramite.codcat,
        dataInmueble.tipoTramite.codtiptramite
      );
    window.scrollTo(0, 0);
  }, []);

  const loadRequisitoFromDb = (dataRequisitoDb) => {
    const nodeReq = requisitosHtml(dataRequisitoDb);
    if (nodeReq && nodeReq.length > 0) {
      setTimeout(() => {
        for (let index = 1; index <= nodeReq.length; index++) {
          const dataRequisito = dataRequisitoDb[index - 1];
          const fileDb = new File(["World!"], dataRequisito.url, {
            type: "application/pdf",
            lastModified: new Date(),
          });
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(fileDb);

          const fileInput = document.querySelector(
            'input[name="saat_cat_req_autorization[' +
              dataRequisito.idCatRequisito +
              ']"]'
          );

          fileInput.files = dataTransfer.files;
          let eInput = new Event("change", { bubbles: true });
          fileInput.dispatchEvent(eInput);

          debugger;
          fileInput.closest(".row").firstChild.lastChild.innerHTML =
            dataRequisito.status;

          const linkPdf = document.getElementById("i-link-pdf");
          if (linkPdf) linkPdf.remove();
          else {
            fileInput.closest(".row").firstChild.appendChild(document.createElement("br"));
            const linkFileOpen = document.createElement("a");
            linkFileOpen.href = dataRequisito.url;
            linkFileOpen.id = "i-link-pdf-"+dataRequisito.idCatRequisito;
            linkFileOpen.target = "_blank";

            const iFileOpen = document.createElement("i");
            iFileOpen.classList.add("fas");
            iFileOpen.classList.add("fa-file-pdf");
            iFileOpen.classList.add("text-butterfly-bush");
            iFileOpen.classList.add("h4");
            
            linkFileOpen.appendChild(iFileOpen);
            fileInput.closest(".row").firstChild.appendChild(linkFileOpen);
          }
        }
      }, 600);
    }
    setNodeRequisitos(nodeReq);
  };

  const loadRequisitos = async (category, requisito) => {
    setNodeRequisitos([]);
    const respRequistos = await fetch.axiosAsyncGet(
      `api/saat/requisito/${category}/${requisito}`
    );
    if (respRequistos && respRequistos.status) {
      const nodeReq = requisitosHtml(respRequistos.data.SaatRequisito);
      setNodeRequisitos(nodeReq);
    }
  };

  const requisitosHtml = (respRequistos) => {
    let cont = 1;
    return respRequistos.map(
      ({ idCatRequisito, nombreRequisito, obligatorio, token }, index) => {
        return (
          <div className="row" key={index + "-" + idCatRequisito} index={index}>
            <div className="col-1 col-md-1 d-none d-sm-block ">
              <span
                className="rounded-circle float-end"
                style={{
                  background: "#ae1857",
                  width: "50px",
                  height: "50px",
                  textAlign: "center",
                  paddingTop: "2px",
                  fontSize: "2em",
                  color: "#ececec",
                }}
              >
                {" "}
                {cont++}{" "}
              </span>
            </div>

            <div
              className="col-12 col-md-11"
              id={"row-first-requisito-" + idCatRequisito}
            >
              <div
                className={
                  "row row-table-custom pt-2 pb-2  me-1 " +
                  (index % 2 === 0
                    ? " bg-picton-blue "
                    : " background-white ") +
                  " justify-content-center align-items-center requisitos-" +
                  idCatRequisito
                }
              >
                <div className="col-8 col-md-10">
                  <span className="text-justify"> {nombreRequisito} </span>
                  <br />
                  <strong>Documento: </strong>
                  <span className={`doc-name-${idCatRequisito}`}></span>
                  <br />
                  <strong>Estado: </strong>
                  <span className={`doc-state-${idCatRequisito}`}></span>
                </div>
                <div className="col-4 col-md-2">
                  <div className="form-group files mt-3 text-center">
                    <label tabIndex={idCatRequisito} className="form-control">
                      <input
                        type="file"
                        className="form-control"
                        multiple=""
                        id={`saat_cat_req_autorization_${idCatRequisito}`}
                        name={`saat_cat_req_autorization[${idCatRequisito}]`}
                        required={obligatorio === "1" ? true : false}
                        data-parsley-max-file-size="7100"
                        onChange={hanldeOnchangeInputFile}
                      />
                    </label>
                    <small className="fs-small8em text-maroon-flush">
                      7MB, Limite Máximo
                    </small>
                  </div>
                </div>
                {/*<input
                  type={"hidden"}
                  value={idCatRequisito}
                  name={`saat_cat_req_autorization_id[${idCatRequisito}]`}
              />*/}
                {token ? (
                  <input
                    type={"hidden"}
                    value={token}
                    name={`saat_cat_req_autorization_id[${idCatRequisito}]`}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        );
      }
    );
  };

  const submitFormToRequirement = (event) => {
    debugger;
    event.preventDefault();
    let inputsDocuments = document.getElementsByName("input-requisito[]");
    const inputsWithoutFiles = Array.from(inputsDocuments).filter((input) => {
      debugger;
      return input.files.length <= 0 && input.obligatorio;
    });

    if (inputsWithoutFiles.length === 0) {
      const data = {};
      onSubmitForm(event, data);
      setMessage(``);
    } else setMessage(Texto.requisitos_whit_document);
  };

  const hanldeOnchangeInputFile = (event) => {
    const inputFile = event.target;
    const divContainerFather = inputFile.closest(".row");

    let iconFile = inputFile.parentElement.parentElement.querySelector("i");
    if (iconFile) inputFile.parentElement.parentElement.removeChild(iconFile);

    debugger;
    if (inputFile.files.length > 0) {
      if (
        inputFile.files[0].name.includes("https") ||
        inputFile.files[0].name.includes("http")
      ) {
        let nameFile = inputFile.files[0].name.split("/");
        nameFile = nameFile[nameFile.length - 1];
        divContainerFather.firstChild.children[3].innerHTML = `${nameFile.substring(
          nameFile.length - 20,
          nameFile.length
        )}`;
      } else
        divContainerFather.firstChild.children[3].innerHTML = `${inputFile.files[0].name}`;

      divContainerFather.firstChild.lastChild.innerHTML = "Pendiente Revisión";

      const format = inputFile.files[0].type.split("/");
      if (/^(gif|jpe?g|png|pdf)$/i.test(format[1])) {
        const icono = document.createElement("i");
        if (format[1] === "pdf") {
          icono.className = "fas fa-file-pdf icon";
        }

        if (/^(gif|jpe?g|png)$/i.test(format[1]))
          icono.className = "fas fa-file-image icon";

        inputFile.parentElement.parentElement.appendChild(icono);

        window.parsley.addValidator("maxFileSize", {
          requirementType: "integer",
          validateString: function (value, maxSize, parsleyInstance) {
            const files = parsleyInstance.$element[0].files;
            return files.length !== 1 || files[0].size <= maxSize * 1024;
          },
          messages: {
            es: "El archivo no debe superar los %s Kb",
          },
        });

        if (
          dataRequisitoDb &&
          !(
            inputFile.files[0].name.includes("https") ||
            inputFile.files[0].name.includes("http")
          )
        ) {
          debugger;
          const parentFile =
            inputFile.parentElement.parentElement.parentElement.parentElement
              .parentElement;
          const id = parentFile.getAttribute("id").match(/(\d+)/g);
          window.create_input_hidden(
            "1",
            "input_force_update_" + id,
            parentFile.getAttribute("id")
          );
        }
      } else
        toast.warning("Formato no valido", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
    }
  };

  return (
    <>
      <PasosNavigationAutorizacion
        nro_inmueble={dataInmueble.numeroInmueble}
        paso1_active={true}
        paso2_active={true}
        paso3_active={true}
        paso4_active={false}
        paso5_active={false}
      />

      <form
        action=""
        className="contact__form needs-validation"
        name={idForm}
        id={idForm}
        method="post"
        noValidate
        onSubmit={submitFormToRequirement}
        style={{ width: "100%" }}
      >
        <div className="card widget widget-simple">
          <div className="card-body ">
            <div className="row ">
              <div className="col-12 col-md-8 widget-header mb-3">
                <h4 className="text-maroon-flush">
                  <i className="fas fa-bookmark me-2"></i> Requisitos
                </h4>
              </div>
            </div>

            <div className="row ">
              <div className="col-12 col-md-12 mb-3 text-justify">
                Adjuntar documentos (requisitos), en formato pdf o imagen.
              </div>
            </div>

            {message ? (
              <div className="row">
                <div className="col-12 text-start">
                  <div
                    className="alert alert-danger text-uppercase"
                    role="alert"
                  >
                    {message}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {nodeRequisitos}
          </div>
        </div>

        <div className="row">
          <div className={`hol col-12 text-end col-md-9 col-lg-9`}></div>

          <div className="col-12 col-md-3 col-lg-3 text-end">
            <div className=" search-btn button mb-3">
              <input
                type="submit"
                className="btn btn-full-screen"
                name="Siguiente"
                value="Siguiente"
              />
            </div>
          </div>
        </div>
      </form>

      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  );
};

export default FormRequisitos;
