import React, { useState,useEffect, useRef} from "react";
import PropTypes from "prop-types";
// import Links from "../../../data/link";
// import { Link } from "react-router-dom";
import Texto from "../../../data/es";
import Fetch from "../../../components/utils/Fetch";
import { toast } from "react-toastify";

const ResetPasswordMetodoFormulario = ({
	options,
	onSubmit,
	correoelectronico,
	urlLoading,		
	setDescriptionInicio,
	handleChangeVisibleOrData,
	dataFormReset
}) => {

	const fetch = new Fetch();
	fetch.setToast(toast);

	const idForm = "form-method-reset";
	const [selectedOption, setSelectedOption] = useState(dataFormReset.metodo);	
	const [loading, setLoading] = useState(false);		
	const isMounted = useRef(false);

	useEffect(() => {
		isMounted.current = true;
	
		return () => {
		  isMounted.current = false;
		};
	  }, []);

	const handleRadioChange = (optionValue) => {
		setSelectedOption(optionValue);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		window.jQuery("#" + idForm).parsley().validate();
		if (window.jQuery("#" + idForm).parsley().isValid() && isMounted.current) {
			
			if(selectedOption.indexOf('@') > 0){
				//email - enviar enlace 
				handleLinkResetEmail(selectedOption, e.target);
			}else{
				//celular - enviar codigo
				handleSendCodePhone(selectedOption,correoelectronico, e.target );
			}			
		}
	};

	// enviar correo electronico
	const handleLinkResetEmail= async (email, target)=>{
		setLoading(true);	

		try {			
			const form = new FormData();			
			form.append("usuario[email]", email);
			let dataResponse = await fetch.fetchPost(form, "api/password/reset/send-link-email", target);
			// console.log("handleLinkResetEmail++++++++++++++++++++++++++ ", dataResponse);
			if(dataResponse && dataResponse.status && isMounted){
				// console.log("--------------- ", email, dataResponse.data.token);	
				onSubmit(email, dataResponse.data.token);			
			}

		} catch (error) {
			toast.error(error.message);			
		}finally{
			setLoading(false);
		}
	}

	// enviar codigo
	const handleSendCodePhone = async(celular, email, target)=>{
		setLoading(true);
		try {			
			const form = new FormData();
			form.append("usuario[celular]", celular);
			form.append("usuario[email]", email);

			let dataResponse = await fetch.fetchPost(form, "api/password/reset/send-code", target);
			if(dataResponse && dataResponse.status){				
				!dataResponse.data.enviado ? toast.warning(dataResponse.message):toast.success(dataResponse.message);
				if(dataResponse.data.enviado && isMounted){
					onSubmit(celular, dataResponse.data.token);
				}
			}
		} catch (error) {
			toast.error(error.message);
			//setError(error.message);
			
		}finally{
			setLoading(false);
		}
	}

	// btn volver
	const handleEventClickVolver = () => {								
		setDescriptionInicio(Texto.reset_descripction_init);
		handleChangeVisibleOrData ('form_email', true,true); 
		handleChangeVisibleOrData ('form_method', false,true); 
	};

	

	return (
		<>
		{loading && (<div className="col-12 mb-1 mx-auto d-block text-center">
				<img
					className="rounded"
					alt="pulse-loading"
					src={urlLoading}
					width="40"
				/>{" "}
				<span className="ml-5 text-violeta">Procesando</span>
			</div>)}

			
			
		<form
			onSubmit={handleSubmit}
			noValidate
			name={idForm}
			id={idForm}
			method="post"
		>
			<div className="form-check pr-5">
				{options.map((option) => (
					<div key={option.value} className="form-check mb-4">
						<input
							type="radio"
							id={option.value}
							className="form-check-input parsley-validated"
							name="option"
							value={option.value}
							checked={selectedOption === option.value}
							onChange={() => handleRadioChange(option.value)}	
							required						
						/>

						<label htmlFor={option.value} className="text-justify ml-5">
							{option.label}
						</label>
					</div>
				))}
			</div>
			
			<div className="d-flex d-sm-flex flex-sm-row justify-content-between mt-3">
				<div>
					<button
						type="button"
						className="btn btn-fucsia"
						onClick={handleEventClickVolver}
					>
						Volver
					</button>
				</div>
				<div>
					<button type="submit" className="btn btn-violeta">
						Continuar
					</button>
				</div>
			</div>
		</form>
		</>
	);
};

ResetPasswordMetodoFormulario.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		})
	).isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default ResetPasswordMetodoFormulario;
