
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Texto from '../../data/es';
import Config from '../../data/config';
import TitlePage from '../utils/TitlePage';
import Links from '../../data/link';

const Publicidad = (props) => {

    const titlePage = Texto.guia

    const [showRequisitosPublicidad, setShowRequisitosPublicidad] = useState(true);

    const refRequisitosPublicidad = useRef();
    const refImgRequisitoPublicidad = useRef();

    const handleClickShowRequisitosPublicidad = (event) => {
        event.preventDefault();

        setShowRequisitosPublicidad(true)
        refRequisitosPublicidad.current.classList.add("active");
        refRequisitosPublicidad.current.classList.remove("active");
    }

    const handleOnchangeRequisitosPublicidad = (event) => {
        event.preventDefault();

        if (event.target.selectedOptions.length > 0) {
            const config = Config[2];
            const optionValue = event.target.selectedOptions[0];
            refImgRequisitoPublicidad.current.setAttribute("alt", 'img-requisito-publicidad-' + event.target.selectedOptions[0].value);
            refImgRequisitoPublicidad.current.setAttribute("width", '600');
            switch (optionValue.value) {
                case "CRAPE":
                    refImgRequisitoPublicidad.current.setAttribute("src", config.url + '/static/img/requisitos/publicidad/1_CRAPE.png');
                    break;
                case "EMPRESARIAL":
                    refImgRequisitoPublicidad.current.setAttribute("src", config.url + '/static/img/requisitos/publicidad/2_ELEMENTO_EMPRESARIAL.png');
                    break;
                case "ACTIVIDAD_ECONOMICA":
                    refImgRequisitoPublicidad.current.setAttribute("src", config.url + '/static/img/requisitos/publicidad/3_ACTIVIDAD_ECONOMICA.png');
                    break;
                case "EVENTUAL":
                    refImgRequisitoPublicidad.current.setAttribute("src", config.url + '/static/img/requisitos/publicidad/4_EVENTUAL.png');
                    break;
                case "BAJA":
                    refImgRequisitoPublicidad.current.setAttribute("src", config.url + '/static/img/requisitos/publicidad/8_BAJA_LETRERO_PUBLICITARIO.jpg');
                    break;
                /*case "RENOVACION_PUBLICIDAD_MOVIL":
                    refImgRequisitoPublicidad.current.setAttribute("src", config.url + '/static/img/requisitos/publicidad/6_RENOVACION_PUBLICIDAD_MOVIL.jpg');
                    break;s
                case "PUBLICIDAD_EVENTUAL":
                    refImgRequisitoPublicidad.current.setAttribute("src", config.url + '/static/img/requisitos/publicidad/7_PUBLICIDAD_EVENTUAL.jpg');
                    break;
                case "BAJA_LETRERO_PUBLICITARIO":
                    refImgRequisitoPublicidad.current.setAttribute("src", config.url + '/static/img/requisitos/publicidad/8_BAJA_LETRERO_PUBLICITARIO.jpg');
                    break;*/
                default:
                    refImgRequisitoPublicidad.current.setAttribute("width", '128');
                    refImgRequisitoPublicidad.current.setAttribute("src", config.url + '/static/img/preview.png');
                    break;
            }
        }
    }

    const [breadcrumbs, setBreadcrumbs] = useState([
        {
            title: Links[0].title,
            url: Links[0].url,
        },
        {
            title: Links[22].title,
            url: Links[22].url,
        },
        {
            title: Links[61].title,
            url: "#",
        }
    ]);

    return (

        <section className="items-grid section mt-5">
            <div className="container mt-1">
                <TitlePage titlePage={titlePage} breadcrumbs={breadcrumbs} position={'left'} leftfull={false} />

                <div className="nav-scroller bg-body shadow-sm bg-picton-blue mt-2 wow fadeInUp" data-wow-delay=".3s" style={{ marginTop: -20 }}>
                    <nav className="nav nav-underline" aria-label="Secondary navigation">
                        <Link to={""} className={`nav-link active`} title={Texto.requisitos} ref={refRequisitosPublicidad} onClick={handleClickShowRequisitosPublicidad}>
                            Requisitos
                        </Link>
                    </nav>
                </div>

                {
                    showRequisitosPublicidad ?
                        <>
                            <div className="row mt-3 wow fadeInUp" data-wow-delay=".3s" >
                                <div className="col-12 col-md-6 col-lg-4  mb-3">
                                    <label className="form-label">Requisitos Publicidads</label>
                                    <select className="form-select" aria-label="Requisitos Publicidads" onChange={handleOnchangeRequisitosPublicidad} >
                                        <option defaultValue value="">Seleccione Requisito Publicidad</option>
                                        <option value="CRAPE">CRAPE</option>
                                        <option value="EMPRESARIAL">Elemento de Publicidad Empresarial</option>
                                        <option value="ACTIVIDAD_ECONOMICA">Licencia de Publicidad (Actividad Económica)</option>
                                        <option value="EVENTUAL">Publicidad  Eventual</option>
                                        <option value="BAJA">Baja de licencia de Publicidad (O.M. 4073)</option>
                                        {/*<option value="NUEVO">Nuevos</option>
                                        <option value="RENOVACION">Renovación</option>
                                        <option value="NUEVAS_PANTALLAS">Nuevas Pantallas</option>
                                        <option value="RENOVACION_NUEVAS_PANTALLAS">Renovación Nuevas Pantallas</option>
                                        <option value="PUBLICIDAD_MOVIL">Publicidad Movil</option>
                                        <option value="RENOVACION_PUBLICIDAD_MOVIL">Renovación Publicidad Movil</option>
                                        <option value="PUBLICIDAD_EVENTUAL">Publicidad Eventual</option>
                                        <option value="BAJA_LETRERO_PUBLICITARIO">Baja de Letrero Publicitario</option>*/}
                                    </select>
                                </div>
                            </div>

                            <div className="row mt-3 wow fadeInUp" data-wow-delay=".3s">
                                <div className="col-12 text-center">
                                    <img className='rounded mx-auto d-block img-thumbnail' alt={'img-requisito-Publicidad-preview'} src={Config[2].url + '/static/img/preview.png'}
                                        width="128" ref={refImgRequisitoPublicidad} />
                                </div>
                            </div>
                        </>
                        : ""}
            </div>
        </section>
    )
}

export default Publicidad;