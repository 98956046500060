import React, { useState, useEffect } from "react";
import Constant from "../../data/constant";
import Links from "./../../data/link";
import Consulta from "./Consulta";
import HeaderTitlecard from "./_common/HeaderTitleCard";
//import Config from "./../../../data/config";

const StepFormCondictionProhibition = (props) => {
  const { nextClick, fetch, backClick, toast, auth, history, dataForm, categorySelected, stepNumber } = props;

  const consultaSQL = new Consulta();

  let rowsEventSelected = [];
  let categoriaSeleccionada = "";
  const [nodeCondition, setNodeCondition] = useState([]);
  const [nodeProhibition, setNodeProhibition] = useState([]);
  const [rowsSelected, setRowsSelected] = useState([]);

  if (!auth.loggedIn()) history.replace(Links[4].url);

  useEffect(() => {
    debugger; 
    if (dataForm.admision) 
    {
      loadCondicionesTecnicas(dataForm.admision.clasification.id);
      loadProhibiciones(dataForm.admision.clasification.id);
      // categoriaSeleccionada = dataForm.datosTecnicos?.advertising.categoryNombre;
    }    

    
  }, [dataForm.admision]);

  // load condiciones tecnicas
  const loadCondicionesTecnicas = async (clasificationId) =>{
    if(clasificationId > 0){
      const query = consultaSQL.queryRequirementSiteByClasification(clasificationId, "CONDITION");      
      const resDataCondition = await requestCondiction(query);
      const dataResponse = resDataCondition.requirementSites;
      if (dataResponse && dataResponse.result) {
        const nodeReq = conditionEventualHtml(dataResponse.data.requirementSite);
        setNodeCondition(nodeReq);
      } 
      // else              
      //   toast.error('No existen datos registrados de condiciones técnicas.');
     }
  }

  // load prohibiciones

  const loadProhibiciones = async (clasificationId)=> {
    if(clasificationId > 0){
      const query = consultaSQL.queryRequirementSiteByClasification(clasificationId, "PROHIBITION");      
      const resDataCondition = await requestCondiction(query);
      const dataResponse = resDataCondition.requirementSites;
      if (dataResponse && dataResponse.result) {
        const nodeReq = conditionEventualHtml(dataResponse.data.requirementSite);
        setNodeProhibition(nodeReq);
      } else
        toast.error('No existen datos registrados de prohibiciones.');
     }
  }


  const requestCondiction = async (query) => {
    debugger
    if (query) {
      const respCondition = await fetch.fetchPostGraphQl(query, "/graphql", null);
      debugger
      /*
      let nodeReq = undefined;
      if (respCondition && respCondition.requirementSitesForCategory && respCondition.requirementSitesForCategory.result)
        nodeReq = conditionEventualHtml(respCondition.requirementSitesForCategory.data.requirementSite);

      if (respCondition && respCondition.requirementSites && respCondition.requirementSites.result)
        nodeReq = conditionEventualHtml(respCondition.requirementSites.data.requirementSite);
      */
      if (respCondition)
        return respCondition;
      else
        toast.error('No existen datos registrados de condiciones técnicas.');
    }
  };

  const conditionEventualHtml = (respConditions) => {
    debugger
    return respConditions.map(
      ({ id, idFatherRequirementSite, name, code }, index) => {
        return (
          <div
            className={
              "row pointer row-table-custom pt-2 pb-2 ms-2 me-2 " +
              (index % 2 === 0 ? " bg-picton-blue " : " background-white ") +
              " justify-content-center align-items-center emplazamiento-" +
              id +
              " "
            }
            key={index}
            onClick={(e) =>
              handleClickRow(e, {
                selected: true,
                id,
                idFatherRequirementSite,
                name,
                code
              })
            }
          >
            <div className="col-11 col-md-11 text-justify">
              <span> {name}</span>
            </div>
            <div className="col-1 col-md-1 text-right">
              <i
                className="fas fa-toggle-off  fa-2x fa-icon-image"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        );
      }
    );
  }

  const handleClickRow = (event, pago) => {
    event.preventDefault();
    seleccionarRow(event, pago);
  };

  const seleccionarRow = (event, row) => {
    let nodeI = event.target.closest(".row").getElementsByTagName("i")[0];
    if (nodeI.classList.contains("fa-toggle-off")) {
      nodeI.classList.remove("fa-toggle-off");
      nodeI.classList.add("fa-toggle-on");
      rowsEventSelected = [...rowsEventSelected, row];
    } else {
      nodeI.classList.add("fa-toggle-off");
      nodeI.classList.remove("fa-toggle-on");
      rowsEventSelected = rowsEventSelected.filter(
        (item) => row.id !== item.id
      );
    }
    setRowsSelected(rowsEventSelected);
  };

  const nextForm = (event) => {
    debugger
    event.preventDefault();
    const totalCondition = nodeCondition.length;
    const totalProhibiciones = nodeProhibition.length;
    const total = totalCondition + totalProhibiciones;
    const dataResponse = {
      condition: rowsSelected.filter((row) => row.code === "CONDITION"),
      prohibition: rowsSelected.filter((row) => row.code === "PROHIBITION"),
    };

    console.log("______ condiciones y prohibiciones _______________", dataResponse);
    window.scrollTo(0, 0);

    if (rowsSelected.length >= 0 && total === rowsSelected.length) {
      nextClick(event, stepNumber, false, dataResponse)
    } else {
      toast.error(
        "Debe aceptar las condiciones técnicas y prohibiciones para poder continuar."
      );      
    }
  }

  return (
    <div className="main-wizard ">
      <HeaderTitlecard titulo={'Condiciones Técnicas y Prohibiciones de Emplazamiento'} descripcion={Constant[0].publicidad_step_new[3]} visibleRequired={true}/>

      <form
        className="contact__form"
        id={"formValideCondition"}
      >
        <div className="mt-4 mb-4">
          {nodeCondition}
          <hr/>
          {nodeProhibition}
        </div>

        <div className="buttons button_space justify-content-between">
          <a className="back_button btn btn-fucsia" onClick={backClick}>Volver</a>
          <button type="button" className="next_button btn btn-violeta" onClick={nextForm}>Continuar</button>
        </div>
      </form >
    </div>
  );
};

export default StepFormCondictionProhibition;
