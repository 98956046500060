import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";

registerLocale("es", es); // register it with the name you want

const DatePickerRangeAtm = (props) => {
  const { placeHolder, name, maxDate, minDate, mandatory, startDateSelected, endDateSelected, setTotalDias, validarRangoFechaElemento } = props;

  const [startDate, setStartDate] = useState(startDateSelected ? startDateSelected : null);
  const [endDate, setEndDate] = useState(endDateSelected ? endDateSelected : null);


  useEffect(() => {
    if (startDate && endDate && typeof validarRangoFechaElemento === 'function') {
      validarRangoFechaElemento();
    }
  }, [startDate, endDate]);


  const handleChangeDatePicker = (range) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
    calcularTotalDaysRange(startDate, endDate);
  };

  const calcularTotalDaysRange = (start, end) => {
    if (start && end) {
      const totalDay = window.moment(end).diff(window.moment(start), 'days') + 1;
      const totalMes = totalDay / 30;
      setTotalDias({ dias: totalDay, mes: totalMes.toFixed(2) });
    } else {
      setTotalDias({ dias: 0, mes: 0 });
    }
  };

  const formattedValue = () => {
    if (startDate && endDate) {
      return `${window.moment(startDate).format("DD/MM/YYYY")} - ${window.moment(endDate).format("DD/MM/YYYY")}`;
    } else if (startDate) {
      return `${window.moment(startDate).format("DD/MM/YYYY")} - `;
    }
    return "";
  };

  return <>
    <DatePicker
      className="form-control"
      selectsRange
      locale="es"
      value={formattedValue()}
      shouldCloseOnSelect={false}
      onChange={handleChangeDatePicker}
      startDate={startDate}
      endDate={endDate}
      maxDate={maxDate}
      minDate={minDate}      
      name={name}
      data-parsley-required={mandatory}
      required={mandatory}
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
      placeholderText={placeHolder}
      isClearable={true}
    />
  </>
};

export default DatePickerRangeAtm;
