import React from 'react';

//HOC
export const withStuffFunction = (Comp) => {
    const hocComponent = React.forwardRef((props, ref) => {
        return (
            <Comp
                ref={ref}
                addStuff={() => {}}
                {...props}
            />
        );
    });

    return hocComponent;
};