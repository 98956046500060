const defaultInmuebleCatastro = {
  codigoCatastral: "",
  nroInscripcion: 0,
  informesTecnico: [
    {
      fechaAprobacionPlano: "",
      nroResolucionMunicipal: "",
      fechaResolucionPlano: "",
      codigoCatastroAnterior: "0",
      superficiePredio: 0,
      informesTecnicoColindancia: [
        {
          descripcionColindancia: "",
          orientacion: "ESTE",
        },
        {
          descripcionColindancia: "",
          orientacion: "OESTE",
        },
        {
          descripcionColindancia: "",
          orientacion: "SUD",
        },
        {
          descripcionColindancia: "",
          orientacion: "NORTE",
        },
      ],
      informesTecnicosConstruccion: [
        {
          superficieConstruida: 0, //valido para ph
        },
      ],
    },
  ],
  informesLegal: [
    {
      nroInformeLegal: 0,
      gestioninformeLegal: 0,
      matricula: "",
      asiento: "",
      superficie: 0,
    },
  ],
};

export default defaultInmuebleCatastro;
