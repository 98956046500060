import React, { useState, useEffect } from "react";
import Texto from "../../../data/es";
import { Link } from "react-router-dom";

const ViewDataNatural = (props) => {
  const { dataNatural, dataPoder, dataApoderado, handleClickAddCopropietario,
    openModalSearchContribuyente, showButtonAdd, showTitle } =
    props;

  const [message, setMessage] = useState(undefined);

  useEffect(() => {
    loadDataForm(dataNatural);
    valideDataForm(dataNatural);
    window.scrollTo(0, 0);
  }, []);

  const loadDataForm = (comprador) => {
    debugger;

    document.getElementById("pCompradorTipoDocumento").innerHTML =
      comprador.tipoDocumento ? comprador.tipoDocumento : " -- ";

    const nroDocumento = comprador.numeroDocumento
      ? comprador.numeroDocumento : ""

    document.getElementById("pCompradorNumeroDocumento").innerHTML =
      nroDocumento
        ? nroDocumento + " " + (comprador.expedidoEn ? comprador.expedidoEn : "")
        : " -- ";
    document.getElementById("pCompradorPMC").innerHTML = comprador.pmc
      ? comprador.pmc
      : " -- ";
    document.getElementById("pCompradorName").innerHTML = comprador.nombre
      ? comprador.nombre
      : " -- ";
    document.getElementById("pCompradorFirstName").innerHTML =
      comprador.apellidoPaterno ? comprador.apellidoPaterno : " -- ";
    document.getElementById("pCompradorLastName").innerHTML =
      comprador.apellidoMaterno ? comprador.apellidoMaterno : " -- ";
    document.getElementById("pCompradorEstadoCivil").innerHTML =
      comprador.estadoCivil ? comprador.estadoCivil : " -- ";
    document.getElementById("pCompradorApellidoEsposo").innerHTML =
      comprador.apellidoCasada ? comprador.apellidoCasada : " -- ";
    document.getElementById("pCompradorGenero").innerHTML = comprador.genero
      ? comprador.genero
      : " -- ";
    document.getElementById("pCompradorFechaNacimiento").innerHTML =
      comprador.fechaNacimiento ? comprador.fechaNacimiento : " -- ";
    document.getElementById("pCompradorDirección").innerHTML =
      comprador.direccion ? comprador.direccion : " -- ";

    document.getElementById("pCompradorCelular").innerHTML = comprador.celular
      ? comprador.celular
      : " -- ";
  };

  const valideDataForm = (comprador) => {
    let hasError = false;
    for (var i in comprador) {
      const nodeHtml = document.getElementsByClassName("label_" + i);
      if (nodeHtml && nodeHtml.length > 0) {
        if (comprador[i]) {
          const compradorText = comprador[i].replace(/[^\w\s]/gi, "");
          if (!compradorText) {
            nodeHtml[0].classList.add("text-mandy");
            hasError = true;
          }
        } else {
          nodeHtml[0].classList.add("text-mandy");
          hasError = true;
        }
      }
    }

    if (hasError) {
      setMessage(
        "* El contribuyente debe tener el PMC actualizado. \n * Para tal efecto apersonarse a oficinas de la Dirección de Recaudaciones (GAMC)."
      );
    } else setMessage(undefined);
  };

  return (
    <>
      <div className="card widget widget-simple">
        <div className="card-body ">

          {
            showTitle ?
              <div className="row ">
                <div className="col-12 col-md-8 widget-header mb-3">
                  <h4 className="text-maroon-flush">
                    <i className="fas fa-bookmark me-2"></i>{" "}
                    {Texto.comprador_beneficiario}
                  </h4>
                </div>
              </div>
              : ""
          }

          <div className="widget-content">
            <div className="widget-body">
              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 mb-3">
                  <label
                    className="form-label fw-500 label_tipoDocumento"
                    style={{ marginBottom: 0 }}
                  >
                    Tipo Documento
                  </label>
                  <p id="pCompradorTipoDocumento">--</p>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-3">
                  <label
                    className="form-label fw-500 label_numeroDocumento"
                    style={{ marginBottom: 0 }}
                  >
                    Número Documento
                  </label>
                  <p id="pCompradorNumeroDocumento">--</p>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-3">
                  <label
                    className="form-label fw-500 label_pmc"
                    style={{ marginBottom: 0 }}
                  >
                    PMC
                  </label>
                  <p id="pCompradorPMC">--</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 mb-3">
                  <label
                    className="form-label fw-500 label_nombre"
                    style={{ marginBottom: 0 }}
                  >
                    Nombre
                  </label>
                  <p id="pCompradorName">--</p>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-3">
                  <label
                    className="form-label fw-500 label_apellidoPaterno"
                    style={{ marginBottom: 0 }}
                  >
                    Primer Apellido
                  </label>
                  <p id="pCompradorFirstName">--</p>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-3">
                  <label
                    className="form-label fw-500"
                    style={{ marginBottom: 0 }}
                  >
                    Segundo Apellido:
                  </label>
                  <p id="pCompradorLastName">--</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 mb-3">
                  <label
                    className="form-label fw-500 label_estadoCivil"
                    style={{ marginBottom: 0 }}
                  >
                    Estado Civil
                  </label>
                  <p id="pCompradorEstadoCivil">--</p>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-3">
                  <label
                    className="form-label fw-500"
                    style={{ marginBottom: 0 }}
                  >
                    Apellido de Casada
                  </label>
                  <p id="pCompradorApellidoEsposo">--</p>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-3">
                  <label
                    className="form-label fw-500 label_genero"
                    style={{ marginBottom: 0 }}
                  >
                    Género
                  </label>
                  <p id="pCompradorGenero">--</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 mb-3">
                  <label
                    className="form-label fw-500 label_fechaNacimiento"
                    style={{ marginBottom: 0 }}
                  >
                    Fecha Nacimiento
                  </label>
                  <p id="pCompradorFechaNacimiento">--</p>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mb-3">
                  <label
                    className="form-label fw-500"
                    style={{ marginBottom: 0 }}
                  >
                    Teléfono Móvil
                  </label>
                  <p id="pCompradorCelular">--</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-3">
                  <label
                    className="form-label fw-500 label_direccion"
                    style={{ marginBottom: 0 }}
                  >
                    Dirección
                  </label>
                  <p id="pCompradorDirección">--</p>
                </div>
              </div>
              <div className="row">
                {message ? (
                  <div className="col-12">
                    <div
                      className="alert alert-danger alert-dismissible fade show"
                      role="alert"
                    >
                      <strong>Atención!</strong> {message}
                      <Link
                        to="#"
                        title="Consulta Contribuyentes"
                        rel="noopener noreferrer"
                        onClick={openModalSearchContribuyente}
                      >
                        Consulta Contribuyentes
                      </Link>
                    </div>
                  </div>
                ) : (
                  showButtonAdd ? (<div className="col-12 text-end" >
                    <div className=" search-btn button mb-3">
                      <input
                        type="button"
                        className="btn bg-green-haze btn-full-screen"
                        name={"Agregar"}
                        value="Agregar"
                        onClick={handleClickAddCopropietario}
                      />
                    </div>
                  </div>) : ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default ViewDataNatural;
