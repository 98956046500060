import Texto from "../../data/es";
import Constant from "../../data/constant";
import { Link } from "react-router-dom";

export default class Columns {
  constructor(userAuth) {
    this.userAuth = userAuth;
  }

  columns(
    handleAprobarDatosClick,
    handleReaperturarClick,
    handlePreviewClick,
    //handleDigitalClickClick,
    handleDeleteClick,
    handleClickUpdateData
  ) {
    return [
      {
        name: Texto.publico,
        sortable: true,
        grow: 1,
        maxWidth: "130px",
        cell: (row) => (
          <div>
            <span>{ parseInt(row.publico, 10)  === 0 ? "NO" : "SI"}</span>
          </div>
        ),
      },
      {
        name: Texto.categoria,
        sortable: true,
        grow: 1,
        maxWidth: "150px",
        cell: (row) => (
          <div>
            <span>{row.category}</span>
          </div>
        ),
      },
      {
        name: Texto.tipo_procedimiento,
        sortable: true,
        maxWidth: "150px",
        cell: (row) => (
          <div>
            <span>
              {row.type_procedure.length > 19
                ? row.type_procedure.substring(0, 20) + "..."
                : row.type_procedure}
            </span>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        hide: "md",
      },
      {
        name: Texto.numero_orden,
        selector: "numero",
        sortable: true,
        maxWidth: "120px",
      },
      {
        name: Texto.nro_inmueble,
        selector: "numero_ruat",
        sortable: true,
        hide: "md",
        maxWidth: "120px",
      },
      {
        name: Texto.estado,
        center: true,
        sortable: true,
        maxWidth: "80px",
        minWidth: "50px",
        cell: (row) => (
          <div>
            {row.estado_code === Constant[0].estado.en_proceso ? (
              <span title={row.estado_code}>
                <i className="fa fa-cog" aria-hidden="true"></i>
              </span>
            ) : (
              ""
            )}
            {row.estado_code === Constant[0].estado.completado ? (
              <span title={row.estado_code}>
                <i className="far fa-file-alt" aria-hidden="true"></i>
              </span>
            ) : (
              ""
            )}
            {row.estado_code === Constant[0].estado.aprobado ? (
              <span title={row.estado_code}>
                <i className="fa fa-check" aria-hidden="true"></i>
              </span>
            ) : (
              ""
            )}
            {row.estado_code === Constant[0].estado.eliminado ? (
              <span title={row.estado_code}>
                <i className="fa fa-ban" aria-hidden="true"></i>
              </span>
            ) : (
              ""
            )}
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
      },
      {
        name: "Creado",
        selector: "created_at",
        sortable: true,
        hide: "md",
        maxWidth: "140px",
      },
      {
        name: "",
        sortable: true,
        cell: (row) => (
          <div className="text-sm-start text-md-end" style={{ width: "100px" }}>
            <span
              data-bs-toggle="dropdown"
              aria-expanded="false"
              className="pe-2 ps-2"
            >
              <i className="fas fa-ellipsis-v "></i>
            </span>
            <ul className="dropdown-menu">
              <li>
                {/*row.permissions ? (
                        row.permissions.includes(Constant[0].permission.update) &&
                        row.estado_code !== Constant[0].estado.aprobado ? (
                          <Link
                            to="#"
                            className="dropdown-item"
                            onClick={() => handleOpenEditClick(row)}
                            title={`Edición de la Autorización - ` + row.numero}
                          >
                            <i className="fas fa-pencil-alt me-2"></i> Editar
                          </Link>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )*/}
              </li>
              <li>
                {row.permissions ? (
                  row.estado_code === Constant[0].estado.completado &&
                  row.permissions.includes(Constant[0].permission.aprobar) ? (
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={() => handleAprobarDatosClick(row)}
                      title={`Aprobar los datos de la Autorización: ${row.numero}`}
                    >
                      <i
                        className="fa fa-unlock-alt me-2"
                        aria-hidden="true"
                      ></i>{" "}
                      Confirmar Datos
                    </Link>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </li>
              <li>
                {row.permissions ? (
                  row.estado_code === Constant[0].estado.aprobado &&
                  row.permissions.includes(
                    Constant[0].permission.reaperturar
                  ) ? (
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={() => handleReaperturarClick(row)}
                      title={`Reaperturar la Autorización: ${row.numero}`}
                    >
                      <i className="fa fa-lock me-2" aria-hidden="true"></i>{" "}
                      Desbloquear
                    </Link>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </li>
              <li>
                {row.estado_code === Constant[0].estado.aprobado ||
                row.estado_code === Constant[0].estado.completado ? (
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={(event) => handlePreviewClick(row, event)}
                    title={`Autorización número: ${row.numero}`}
                  >
                    <i className="far fa-file-pdf me-2" aria-hidden="true"></i>{" "}
                    Ver DDJJ
                  </Link>
                ) : (
                  ""
                )}
              </li>
              <li>
                {/* 
                this.userAuth.code === Constant[0].grupos.funcionario ||
                this.userAuth.code === Constant[0].grupos.administrador &&
                */}
                {
                  row.permissions &&
                  row.permissions.includes(Constant[0].permission.update) &&
                  (row.estado_code === Constant[0].estado.completado ||
                    row.estado_code === Constant[0].estado.en_proceso) ? (
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={(event) => handleClickUpdateData(row, event)}
                    title={`Actualizar/Modificar Datos`}
                  >
                    <i className="fas fa-pencil-alt me-2"></i> Modificar Datos
                  </Link>
                ) : (
                  ""
                )}
              </li>
              {/*<li>
                {
                  //row.permissions &&
                  //row.permissions.includes(Constant[0].permission.archivar) &&
                  row.estado_code === Constant[0].estado.aprobado ? (
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => handleDigitalClickClick(row)}
                    title={`Generar Fur de Pre-Liquidación`}
                  >
                    <i className="fas fa-archive me-2"></i> Archivar
                  </Link>
                ) : (
                  ""
                )}
                </li>*/}
              <li>
                {row.permissions ? (
                  row.permissions.includes(Constant[0].permission.update) &&
                  row.estado_code !== Constant[0].estado.aprobado &&
                  row.estado_code !== Constant[0].estado.eliminado ? ( //&&
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={() => handleDeleteClick(row)}
                      title={`Eliminar Autorización ${row.numero}`}
                    >
                      <i
                        className="far fa-trash-alt me-2"
                        aria-hidden="true"
                      ></i>{" "}
                      Eliminar
                    </Link>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </li>
            </ul>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];
  }
}
