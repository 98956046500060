import React, { Component } from 'react';

import Fetch from '../../components/utils/Fetch';
import Texto from '../../data/es';
import { Link } from 'react-router-dom';

import DataTable from 'react-data-table-component';
import styled from 'styled-components';  //styled for data table

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../style/parsley.css';
import CustomLoader from '../utils/SpinnerDataTable'

const columns = [
    {
        name: Texto.sub_alcaldia,
        selector: 'sub_alcaldia',
        sortable: true,
        grow: 1,
        maxWidth: '120px',
    },
    {
        name: Texto.distrito,
        selector: 'distrito',
        sortable: true,
        hide: 'md',
        maxWidth: '50px'
    },
    {
        name: Texto.sub_distrito,
        selector: 'sub_distrito',
        center: false,
        sortable: true,
        hide: 'md',
        maxWidth: '140px'
    },
    {
        name: Texto.tipo_direccion,
        selector: 'tipo_direccion',
        center: false,
        sortable: true,
        hide: 'sm',
        maxWidth: '60px'
    },
    {
        name: Texto.direccion,
        selector: 'direccion',
        sortable: true,
        center: false,
        maxWidth: '170px',
        cell: row => <div>
            {(new window.MobileDetect(window.navigator.userAgent)).mobile() ?
                row.direccion.length > 15 ? row.direccion.substring(0, 15) + '...' : row.direccion
                : row.direccion}
        </div>,
    },
    {
        name: '',
        sortable: true,
        cell: row => <div>
            <Link to={'#'} onClick={() => handleClickRow(row)} style={{ fontSize: '22px', marginRight: '10px' }} title={`Seleccionar fila `} >
                <i className="fa fa-plus" aria-hidden="true"></i>
            </Link>
        </div>,
        ignoreRowClick: false,
        allowOverflow: true,
        button: true,
    }
];

const SampleStyle = styled.div`
  padding: 10px;
  display: block;
  width: 100%;

  p {
    font-size: 12px;
    word-break: break-all;
  }
`;

const IntentExpandedComponent = ({ data }) => (
    <SampleStyle>
        <p><strong>{Texto.sub_alcaldia}: </strong>{data.sub_alcaldia}</p>
        <p><strong>{Texto.distrito}: </strong>{data.distrito}</p>
        <p><strong>{Texto.sub_distrito}: </strong>{data.sub_distrito}</p>
        <p><strong>{Texto.tipo_direccion}: </strong>{data.tipo_direccion}</p>
    </SampleStyle>
);

const handleClickRow = (row) => {

    if (_module === 'actividad_economica') {
        document.getElementsByName("domicilio_actividad_economica[direccion]")[0].value = row.tipo_direccion + ' ' + row.direccion
        document.getElementsByName("domicilio_actividad_economica[id_direccion]")[0].value = row.id_direccion
    }

    if (_module === 'prescripcion') {
        document.getElementsByName("inmueble_direccion[direccion]")[0].value = row.tipo_direccion + ' ' + row.direccion
        document.getElementsByName("inmueble_direccion[id_direccion]")[0].value = row.id_direccion
    }

    window.jQuery('#openModalSearch').modal('hide');
    return false
};

var _comuna = "";
var _distrito = "";
var _sub_distrito = "";
var _module = ""
class ModalSearchDireccion extends Component {

    constructor(props, context) {
        super(props, context);

        this.id_modal = "openModalSearch";
        this.fetch = new Fetch();
        this.fetch.setToast(toast);

        this.state = {
            optionsDistrito: "",
            optionsSubDistrito: "",
            optionsTipoDireccion: "",

            data: [],
            loading: false,
            totalRows: 0,
            perPage: 10,
            text_search: "",
        }

        _module = this.props.module
    }

    componentDidMount() {

        window.jQuery(".sc-kAzzGY").remove()  //pertenece al datatable
        window.jQuery(".cOaGrx").css("min-height", "0px")

        var self = this
        window.jQuery("#openModalSearch").on('show.bs.modal', function () {
            self.setState({
                data: [],
                totalRows: 0
            });
            document.getElementsByName("searchDireccion[direccion]")[0].value = ""
        });
    }

    componentDidUpdate() {
        if (this.props.open !== undefined && this.props.open === true) {
            window.jQuery('#' + this.id_modal).modal('show');
        }

        window.jQuery(".bTnnmE").css("height", "auto")
        if (this.props.module === 'actividad_economica') {
            _comuna = document.getElementsByName("actividad_economica[comuna]")[0].value
            _distrito = document.getElementsByName("actividad_economica[distrito]")[0].value
            _sub_distrito = document.getElementsByName("actividad_economica[sub_distrito]")[0].value
        }

        if (this.props.module === 'prescripcion') {
            _comuna = document.getElementsByName("inmueble[comuna]")[0].value
            _distrito = document.getElementsByName("inmueble[distrito]")[0].value
            _sub_distrito = document.getElementsByName("inmueble[sub_distrito]")[0].value
        }

        document.getElementById('title-modal-search-direccion').innerHTML = _comuna + " >> " + _distrito + " >> " + _sub_distrito
    }

    searchDireccion = async (event) => {
        let text = document.getElementById("searchDireccion[direccion]").value
        var self = this

        if (text.trim() !== "" && text.length >= 3) {
            const { perPage } = this.state;
            this.setState({ loading: true });
            const response = await this.fetch.axiosAsyncGet(`api/direccion/text/1/${perPage}/${text}/${_comuna}/${_distrito}/${_sub_distrito}`);
            if (response !== null && response.status === true) {
                self.setState({
                    data: response.Direccion,
                    totalRows: response.total,
                    loading: false,
                    text_search: text
                });
            }
        }
    }

    handlePageChange = async page => {
        const { perPage } = this.state;
        this.setState({ loading: true });

        const response = await this.fetch.axiosAsyncGet(`api/direccion/text/${page}/${perPage}/${this.state.text_search}/${_comuna}/${_distrito}/${_sub_distrito}`);
        this.setState({
            loading: false,
            data: response.Direccion,
        });
    };

    handlePerRowsChange = async (perPage, page) => {
        this.setState({ loading: true });

        const response = await this.fetch.axiosAsyncGet(`api/direccion/text/${page}/${perPage}/${this.state.text_search}/${_comuna}/${_distrito}/${_sub_distrito}`);
        this.setState({
            loading: false,
            data: response.Direccion,
            perPage,
        });
    };

    handleCloseOnClick = (event) => {
        event.preventDefault()
        document.getElementsByName("searchDireccion[direccion]")[0].value = ""
        this.setState({
            loading: false,
            data: [],
            totalRows: 0
        });
    }


    render() {
        const { loading, data, totalRows } = this.state
        return (

            <div className="modal fade" id={this.id_modal} tabIndex="-1" aria-labelledby="contactModalCenterTitle"
                aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="contactModalCenterTitle">Dirección de la Actividad Economica</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.handleCloseOnClick}></button>
                        </div>
                        <div className="modal-body">
                            <h6 style={{ marginTop: "-10px" }} id="title-modal-search-direccion" ></h6>
                            <div className="row mt-3">
                                <div className="col-12 col-md-12 col-lg-12 mb-3">
                                    <label htmlFor={'searchDireccion[direccion]'} className="form-label">Dirección Actividad Económica</label>
                                    <div className="input-group">
                                        <input autoFocus name={'searchDireccion[direccion]'} id="searchDireccion[direccion]" type="text" onChange={this.searchDireccion}
                                            className="form-control input-uppercase" placeholder="Dirección Actividad Económica" data-parsley-required="true"
                                            required pattern="[.,a-zA-Z0-9 À-ÿ\u00f1\u00d1]+" data-parsley-pattern="[.,a-zA-Z0-9 À-ÿ\u00f1\u00d1]+" />
                                        <div className="input-group-prepend">
                                            <button className="btn btn-outline-secondary" type="button" onClick={this.searchDireccion}><i className="fa fa-search" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <DataTable
                                    title={this.titlePage}
                                    columns={columns}
                                    data={data}
                                    progressPending={loading}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={this.handlePerRowsChange}
                                    onChangePage={this.handlePageChange}
                                    noDataComponent={Texto.there_are_no_records_to_display}
                                    highlightOnHover
                                    progressComponent={<CustomLoader />}
                                    expandableRowsComponent={<IntentExpandedComponent />}
                                    onRowClicked={handleClickRow}
                                    expandableRows
                                />

                            </div>

                        </div>
                        <div className="modal-footer text-end">
                            <div className="search-btn button ">
                                <button type="button" className="btn bg-casa-blanca" data-bs-dismiss="modal" onClick={this.handleCloseOnClick}>Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer enableMultiContainer containerId={'Z'}
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <ToastContainer />
            </div>
        );
    }
}

export default ModalSearchDireccion;
