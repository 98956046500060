import React, { } from 'react';
import Texto from '../../../data/es'

const DatosEstadoVehiculo = (props) => {

    const { dataObservaciones } = props

    let sub_cont = 0
    const table = dataObservaciones.map(({ dt, fr, to }) => {
        return <div className={"row "}
            key={sub_cont }  >
            <div className="col-12 ">
                - {dt}
            </div>
        </div>
    })

    return (
        <div className="card widget widget-simple w-100" >
            <div className="card-body ">
                <div className="row ">
                    <div className="col-12 widget-header">
                        <h4 className="">{Texto.estado_vehiculo}</h4>
                    </div>
                </div>

                <div className="widget-content">
                    <div className="widget-body">

                        <div className="row  " >
                            <div className="col-12 padding-left-right-0">
                                <p className="font-weight-bold">OBSERVACIONES:</p>
                            </div>
                        </div>

                        {table ? table : ""}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DatosEstadoVehiculo