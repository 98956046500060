import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const DatePickerAtm = (props) => {
  const { id, title, handleEventButton, placeHolder, regex, namePopOver } =
    props;

  const refInputPopOver = useRef();
  const [popOverBoostrap, setPopOverBoostrap] = useState(undefined);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const idCcontentPopOver = document.getElementById(
      `popover-content-${namePopOver}`
    );
    const options = {
      html: true,
      title: "Optional: Titulo por Defecto",
      placement: "top",
      content: idCcontentPopOver,
    };
    const popOverDoom = document.getElementById(id + "Link");
    const popOver = new window.bootstrap.Popover(popOverDoom, options);

    const popOverDomHtml = idCcontentPopOver.parentElement.parentElement;

    popOverDomHtml.addEventListener("shown.bs.popover", () => {
      const headerPopOver =
        idCcontentPopOver.parentElement.parentElement.getElementsByClassName(
          "popover-header"
        );

      const closeHeader =
        headerPopOver[0].parentElement.getElementsByClassName("fas fa-times");
      if (closeHeader && closeHeader.length === 0) {
        const ibutton = document.createElement("i");
        ibutton.setAttribute("class", "fas fa-times");
        ibutton.setAttribute("id", id + "CloseHeader");
        ibutton.setAttribute(
          "style",
          "position: absolute; right: 3%; top: 11%;"
        );
        ibutton.onclick = (e) => {
          e.target.parentElement.getElementsByTagName("input")[0].value = "";
          popOver.hide();
        };
        headerPopOver[0].parentElement.append(ibutton);
      }
      window.inputTextUpperCase();
    });

    setPopOverBoostrap(popOver);
  }, []);

  const handleEventInput = (event) => {
    const input = refInputPopOver.current;
    const value = input.value;
    let procesar = true;
    let messageWarning = "";

    if (!value) {
      procesar = false;
      messageWarning = "Campo obligatorio";
    }

    if (!value.match(regex)) {
      procesar = false;
      messageWarning = "Incorrecto, revisa lo ingresado";
    }

    debugger;
    if (procesar) {
      setMessage("");
      popOverBoostrap.hide();
      handleEventButton(event, value);
      input.value = "";
    } else setMessage(messageWarning);
  };

  return (
    <>
      <div hidden>
        <div
          data-name={`popover-content-${namePopOver}`}
          id={`popover-content-${namePopOver}`}
        >
          <div className="input-group">
            <input
              type="text"
              className="form-control form-control-sm input-uppercase"
              placeholder={placeHolder}
              name={id + "Input"}
              ref={refInputPopOver}
            />
            <div className="input-group-btn">
              <button
                className="btn bg-minsk"
                type="button"
                onClick={handleEventInput}
              >
                <i className="bi bi-search fas fa-save  text-white"></i>
              </button>
            </div>
          </div>
          <div className="text-maroon-flush">
            <small>{message}</small>
          </div>
        </div>
      </div>

      <Link
        to="#"
        id={id + "Link"}
        tabIndex="0"
        className="fs-1-1em"
        role="button"
        title={`${title}`}
        data-bs-toggle="popover"
      >
        Editar <i className="fas fa-edit" aria-hidden="true"></i>
      </Link>
    </>
  );
};

export default DatePickerAtm;
