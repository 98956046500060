import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fetch from '../utils/Fetch';
import Links from '../../data/link';
import AuthService from '../../components/Usuario/AuthService';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';  //styled for data table
import Texto from '../../data/es';
import { ToastContainer, toast } from 'react-toastify';
import '../style/parsley.css';

const columns = [
    {
        name: Texto.usuario,
        selector: 'username',
        sortable: true,
        maxWidth: '130px'
    },
    {
        name: Texto.description,
        center: false,
        sortable: true,
        maxWidth: '230px',
        hide: 'md',
        cell: row => <div>
            {row.description_intentos_fur}
        </div>
    },
    {
        name: Texto.solicitado,
        center: false,
        sortable: true,
        maxWidth: '50px',
        cell: row => <div>
            {(JSON.parse(row.data_new)).intentos_fur - (JSON.parse(row.data_old)).intentos_fur}
        </div>
    },
    {
        name: Texto.intentos_fur,
        center: false,
        sortable: true,
        maxWidth: '50px',
        cell: row => <div>
            {(JSON.parse(row.data_new)).intentos_fur}
        </div>
    },
    {
        name: Texto.creado,
        selector: 'created_at',
        sortable: true,
        hide: 'md',
        maxWidth: '150px',
    },
];

const SampleStyle = styled.div`
  padding: 10px;
  display: block;
  width: 100%;

  p {
    font-size: 12px;
    word-break: break-all;
  }
`;

const IntentExpandedComponent = ({ data }) => (
    <SampleStyle>
        <p><strong>{Texto.usuario}: </strong>{data.username}</p>
        <p><strong>{Texto.description}: </strong>{data.description_intentos_fur}</p>
    </SampleStyle>
);

var _token_user = ""
var _username = ""
var _modal_intentos = undefined
class ModalIntentos extends Component {

    constructor(props, context) {
        super(props, context);
        this.id_modal = "modalIntentAccountUser"
        this.title = Texto.increment_request_licence

        this.fetch = new Fetch();
        this.fetch.setToast(toast);
        _token_user = ""
        _username = ""

        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);

        this.Auth = new AuthService();

        _modal_intentos = this;
        this.state = {
            totalRows: 0,
            perPage: 10,
            optionsTipoDescripcionIntento: undefined
        };
    }

    componentDidUpdate() {
        var modal = document.getElementById("modalIntentAccountUser")
        if (modal.getElementsByClassName("bTnnmE").length > 0)
            modal.getElementsByClassName("bTnnmE")[0].setAttribute("style", "height: auto;");
    }

    show(token_usr, username) {

        window.jQuery("#" + this.id_modal).modal("show");
        _modal_intentos.setState({ loading: true });
        document.getElementById('formContadorIntent').reset();
        const response = this.fetch.fetchGet(`api/log/intent-by-token-user/1/${this.state.perPage}/${token_usr}`);
        response.then(res => {
            if (res !== undefined && res.status === true) {
                _modal_intentos.setState({
                    data: res.Log,
                    totalRows: res.total,
                    loading: false,
                });
            }
        })

        const tipo_descripcion_intento = this.fetch.fetchGet(`api/tipo-descripcion-intento/all`);
        tipo_descripcion_intento.then(res => {
            if (res !== undefined && res.status === true) {
                const listItems = res.TipoDescripcionIntento.map((item, index) => {
                    return <option key={index} value={item.id} >{item.name}</option>
                });

                _modal_intentos.setState({ optionsTipoDescripcionIntento: listItems });
            }
        })

        _token_user = token_usr
        _username = username
    }

    handleCancelClick(event) {
        document.getElementById("inputNumIntent").value = ''
        window.jQuery("#" + this.id_modal).modal("hide");
    }

    handleSubmitForm(event) {
        event.preventDefault()
        var target = event.target
        window.jQuery("#" + event.target.getAttribute('id')).parsley().validate();

        if (window.jQuery("#" + target.getAttribute('id')).parsley().isValid()) {
            const form = new FormData(event.target);
            form.append('usuario[token]', _token_user);

            var self = this
            this.fetch.fetchPost(form, `api/usuario/intent-increment`).then(res => {

                if (res !== undefined && res.status === true) {
                    const response = self.fetch.fetchGet(`api/log/intent-by-token-user/1/${self.state.perPage}/${_token_user}`);
                    response.then(res => {
                        if (res !== undefined && res.status === true) {
                            self.setState({
                                data: res.Log,
                                totalRows: res.total,
                                loading: false
                            });
                        }
                    })
                    toast.success(res.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    document.getElementById(target.getAttribute('id')).reset();
                }
            })
        } else {
            toast.warn(Texto.form_have_inputs_required, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    handlePerRowsChange = async (perPage, page) => {
        this.setState({ loading: true });
        const response = await this.fetch.fetchGet(`api/log/intent-by-token-user/${page}/${perPage}/${_token_user}`);
        this.setState({
            loading: false,
            data: response.Log,
            perPage,
        });
    };

    handlePageChange = async page => {
        const { perPage } = this.state;
        this.setState({ loading: true });
        const response = await this.fetch.fetchGet(`api/log/intent-by-token-user/${page}/${perPage}/${_token_user}`);

        this.setState({
            loading: false,
            data: response.Log,
        });
    };

    render() {
        const { loading, data, totalRows } = this.state
        return (
            <div className="modal fade" id={this.id_modal} tabIndex="-1" aria-labelledby="modalIntentFullLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="titlePdfFullLabel">{this.title}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            <form action="" className="contact__form needs-validation" name="formContadorIntent" id="formContadorIntent"
                                method="post" noValidate onSubmit={this.handleSubmitForm} style={{ width: '100%' }}>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-md-3 col-lg-3 mb-3">
                                        <div className="form-group">
                                            <label htmlFor="usuario[intentos_fur]">Nro</label>
                                            <input type="text" className="form-control" placeholder="Nro" aria-label="Nro" id="usuario[intentos_fur]" name="usuario[intentos_fur]"
                                                data-parsley-required="true" pattern="^[1-9][0-9]*$" data-parsley-pattern="^[1-9][0-9]*$" maxLength='2' required />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-md-9 col-lg-9 mb-3">
                                        <div className="form-group">
                                            <label htmlFor="usuario[id_tipo_description_intentos]">Descripción</label>
                                            <select className="form-control" name="usuario[id_tipo_description_intentos]" required data-parsley-required="true" >
                                                {this.state.optionsTipoDescripcionIntento}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3 mb-3">
                                    <div className="col-12 col-md-7 col-lg-7 ">
                                    </div>

                                    <div className="col-12 col-md-5 col-lg-5 mb-3 d-flex justify-content-between">
                                        <div className="search-btn button ">
                                            <input type="button" className="btn btn-full-screen" value="Cancelar" name="cancelar" data-bs-dismiss="modal" />
                                        </div>
                                        <div className="search-btn button ">
                                            <input type="submit" className="btn bg-maroon-flush btn-full-screen" id="btnModalSmallSubmit" name="enviar" value="Enviar" />
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div className="row">
                                <div className="col-12 " style={{ borderTop: '1px solid #e9ecef' }}>
                                    <h5 className="mt-3">{'Intentos para ' + _username}</h5>
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: '-60px' }}>
                                <div className="col-12 ">
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        progressPending={loading}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        onChangeRowsPerPage={this.handlePerRowsChange}
                                        onChangePage={this.handlePageChange}
                                        noDataComponent={Texto.there_are_no_records_to_display}
                                        expandableRowsComponent={<IntentExpandedComponent />}
                                        highlightOnHover
                                        expandableRows
                                    />
                                </div>
                            </div>
                        </div>

                        <ToastContainer enableMultiContainer containerId={'Z'}
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnVisibilityChange
                            draggable
                            pauseOnHover
                        />
                        <ToastContainer />
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalIntentos;