import React, { useState } from "react";
import { Link } from "react-router-dom";

const TableCopropietario = (props) => {
  const { dataCopropietario, deleteItem, editItem, showButtons } = props;
  const tableCopropietario = dataCopropietario.map(
    ({ apoderado, propietario, juridico }, index) => {
      const propietarioApoderado = apoderado || propietario;
      const {
        nombre,
        apellidoPaterno,
        apellidoMaterno,
        numeroDocumento,
        expedidoEn,
        pmc,
      } = propietarioApoderado;
      return (
        <div
          className={
            "row row-table-custom pt-3 ms-1 me-1 copropietario-" +
            numeroDocumento
          }
          key={pmc || numeroDocumento +"_"+index }
        >
          <div className="col-5 col-md-5 text-start">
            <p>
              {juridico
                ? juridico.razonSocial
                : `${nombre} ${apellidoPaterno} ${
                    apellidoMaterno ? apellidoMaterno : ""
                  }`}
            </p>
          </div>
          <div className="col-2 col-md-2 text-start">
            <p>{juridico ? juridico.pmc : pmc }</p>
          </div>
          <div className="col-4 col-md-4 text-center">
            {juridico
              ? `${juridico.numeroDocumento} ${juridico.tipoDocumentoCode}`
              : `${numeroDocumento} ${expedidoEn ? expedidoEn : ""}`}
          </div>
          <div className="col-1 col-md-1 text-center">
            {showButtons ? (
              <>
                <span
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className="pe-2 ps-2"
                >
                  <i className="fas fa-ellipsis-v "></i>
                </span>
                <ul className="dropdown-menu">
                  {/* <li>
                    <Link
                      to="#"
                      className="dropdown-item"
                      title={`Ver detalles del registro`}
                      onClick={(e) => editItem(e, numeroDocumento)}
                    >
                      <i className="fas fa-pencil-alt me-2"></i>
                      Editar
                    </Link>
                  </li>*/}
                  <li>
                    <Link
                      to="#"
                      className="dropdown-item"
                      title={`Eliminar registro `}
                      onClick={(e) => deleteItem(e, numeroDocumento, pmc)}
                    >
                      <i className="fas fa-trash-alt me-2"></i>
                      Borrar
                    </Link>
                  </li>
                </ul>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }
  );

  return (
    <>
      <div className="row ms-1 me-1 mt-4">
        <div className="col-5 col-md-5 pt-1 text-start">
          <p className="font-weight-bold">
            <strong>Nombre(s)</strong>
          </p>
        </div>
        <div className="col-2 col-md-2 pt-1 text-start">
          <p className="font-weight-bold">
            <strong>PMC</strong>
          </p>
        </div>
        <div className="col-4 col-md-4 pt-1 text-center">
          <p className="font-weight-bold ">
            <strong>Nro Documento</strong>
          </p>
        </div>

        <div className="col-1 col-md-1 pt-1 text-center"></div>
      </div>

      {tableCopropietario.length ? tableCopropietario : ""}
    </>
  );
};

export default TableCopropietario;
