import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Fetch from "../utils/Fetch";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Config from "../../data/config";
import Texto from "../../data/es";
import Constant from "../../data/constant";
import PasosNavigationCambioRadicatoria from "../utils/PasosNavigationCambioRadicatoria";

const FormDatosPoseedor = (props) => {
  const fetch = new Fetch();
  fetch.setToast(toast);

  const [optionsTipoDocumento, setOptionsTipoDocumento] = useState([]);
  const [optionsEstadoCivil, setOptionsEstadoCivil] = useState([]);
  const [optionsGenero, setOptionsGenero] = useState([]);
  const [optionsTiposDocumentosVehiculo, setOptionsTiposDocumentosVehiculo] =
    useState([]);

  const [showApellidoCasada, setShowApellidoCasada] = useState(false);
  const [startDatePersona, setStartDatePersona] = useState(undefined);
  const [startDateFechaPoder, setStartDateFechaPoder] = useState(undefined);
  const [ciExpedido, setCiExpedido] = useState("EX");
  const [isTipoDocumentoCi, setIsTipoDocumentoCi] = useState(false);

  const [showFechaPoder, setShowFechaPoder] = useState(false);
  const [textFechaPoder, setTextFechaPoder] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getTipoDocumentos();
    getGeneros();
    getEstadosCivil();
    getTiposDocumentosVehiculo();

    window.jQuery(".linkHelpFDAE").popover({
      title:
        '<h3 class="custom-title"><i class="fa fa-map-marker"></i> Dirección</h3>',
      content:
        "<p>Debe consignar obligatoriamente la dirección descriptiva del Poseedor (Avenida/Calle/Pasaje) y sus colindantes (Avenida/Calle/Pasaje).</p>",
      html: true,
    });

    window.jQuery(".linkHelpFDAdyaAE").popover({
      title:
        '<h3 class="custom-title"><i class="fa fa-map-marker"></i> Adyacentes</h3>',
      content:
        "<p>Debe consignar obligatoriamente las calles o avenidas Adyacentes a su domicilio.</p>",
      html: true,
    });

    window.inputTextUpperCase();
  }, []);

  const getTipoDocumentos = () => {
    const tipoDocRes = fetch.fetchGet(`api/tipo-documentos/cambio-radicatoria`);
    tipoDocRes.then((res) => {
      if (res && res.status) {
        if (res.status && res.TipoDocumento) {
          const listItems = res.TipoDocumento.map((item, index) => {
            return (
              <option key={index} value={item.id} code={item.code}>
                {item.name}
              </option>
            );
          });

          setOptionsTipoDocumento(listItems);
        }
      }
    });
  };

  const getGeneros = () => {
    const generoResp = fetch.fetchGet(`api/list/genero`);
    generoResp.then((res) => {
      if (res && res.status && res.genero) {
        const listGenero = res.genero.map((item, index) => {
          return (
            <option key={index} value={item.id} code={item.code}>
              {item.name}
            </option>
          );
        });
        setOptionsGenero(listGenero);
      }
    });
  };

  const getEstadosCivil = () => {
    const estadoCivilResp = fetch.fetchGet(`api/list/estado-civil`);
    estadoCivilResp.then((res) => {
      if (res && res.status && res.estadoCivil) {
        const listEC = res.estadoCivil.map((item, index) => {
          return (
            <option key={index} value={item.id} code={item.code}>
              {item.name}
            </option>
          );
        });
        setOptionsEstadoCivil(listEC);
      }
    });
  };

  const getTiposDocumentosVehiculo = () => {
    const tipoDocumentosVehiculo = fetch.fetchGet(
      `api/vehiculos/tipos-documentos`
    );
    tipoDocumentosVehiculo.then((res) => {
      if (res && res.status && res.VehiculoTipoDocumento) {
        const listEC = res.VehiculoTipoDocumento.map((item, index) => {
          return (
            <option key={index} value={item.id} code={item.code}>
              {item.name}
            </option>
          );
        });
        setOptionsTiposDocumentosVehiculo(listEC);
      }
    });
  };

  const handleTipoDocumentoOnChange = (event) => {
    event.preventDefault();
    debugger;
    if (event.target.selectedOptions.length > 0) {
      switch (event.target.selectedOptions[0].getAttribute("code")) {
        case "CI":
          setIsTipoDocumentoCi(true);
          setTimeout(() => {
            addEventDropdownMenu();
          }, 200);
          break;
        default:
          setIsTipoDocumentoCi(false);
          break;
      }
    }
  };

  const addEventDropdownMenu = () => {
    const dropdownMenuPerSol = window.jQuery(
      "#" + props.nameForm + "-dropdown-solicitante-tipo-doc"
    );
    if (dropdownMenuPerSol.length > 0) {
      let li = window.jQuery(dropdownMenuPerSol.find("li"));
      window.event_drop_down(li, function (code_ci_exp, target) {
        if (ciExpedido === "EX")
          document.getElementsByName(
            props.nameForm + "[expedido_en]"
          )[0].value = code_ci_exp;
      });
    }
  };

  const handleSearchByCiOnClick = (event) => {
    event.preventDefault();
    let ci = "";
    let input_search = "";
    if (event.target.tagName === "I") {
      ci = event.target.parentElement.parentElement.firstElementChild.value;
      input_search =
        event.target.parentElement.parentElement.firstElementChild.getAttribute(
          "name"
        );
    } else {
      ci = event.target.parentElement.firstElementChild.value;
      input_search =
        event.target.parentElement.firstElementChild.getAttribute("name");
    }

    if (ci !== "") {
      window
        .jQuery(
          "input[name*='persona']:not(input[name='persona[numero_documento]']):not(input[type=hidden])"
        )
        .val("");
      window
        .jQuery(
          "select[name*='persona']:not(select[name='persona[id_tipo_documento]'])"
        )
        .prop("selectedIndex", 0);
      getDataPersona(ci, props.nameForm, input_search, props.nameFormDomicilio);
    }
  };

  const getDataPersona = (ci, nameForm, input_search, nameFormDomicilio) => {
    const search_persona = fetch.fetchGet(
      `api/recaudaciones/persona-by-ci-no-auth/${ci}`
    );
    search_persona.then((dataJson) => {
      if (dataJson && dataJson.status && dataJson.data) {
        if (input_search.indexOf("persona") >= 0) {
          loadDataForm(
            dataJson.data.persona,
            dataJson.data.domicilio,
            dataJson.data.persona.recaudacion ? false : true,
            nameForm,
            nameFormDomicilio
          );
        }

        toast.success(dataJson.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    });
  };

  const handleEstadoCivilOnChange = (event) => {
    event.preventDefault();
    debugger;
    if (event.target.selectedOptions.length > 0) {
      const constant = Constant[0];
      switch (event.target.selectedOptions[0].value) {
        case constant.estado_civil.casado:
          setShowApellidoCasada(true);
          break;
        case constant.estado_civil.viudo:
          setShowApellidoCasada(true);
          break;
        default:
          setShowApellidoCasada(false);
          break;
      }
    }
  };

  const handleDatePickerPersonaChange = (date) => {
    setStartDatePersona(date);
  };

  const handleDatePickerFechaPoderChange = (date) => {
    setStartDateFechaPoder(date);
  };

  const handleNumeroOnchange = (event) => {
    if (event.target.checked) {
      document.getElementsByName(
        props.nameFormDomicilio + "[numero]"
      )[0].readOnly = false;
      document.getElementsByName(
        props.nameFormDomicilio + "[numero]"
      )[0].placeholder = Texto.numero;
      document
        .getElementsByName(props.nameFormDomicilio + "[numero]")[0]
        .setAttribute("data-parsley-required", true);
    } else {
      document.getElementsByName(
        props.nameFormDomicilio + "[numero]"
      )[0].readOnly = true;
      document.getElementsByName(
        props.nameFormDomicilio + "[numero]"
      )[0].value = "";
      document.getElementsByName(
        props.nameFormDomicilio + "[numero]"
      )[0].placeholder = "S/N";
      document
        .getElementsByName(props.nameFormDomicilio + "[numero]")[0]
        .setAttribute("data-parsley-required", false);
    }
  };

  const loadDataForm = (
    persona,
    domicilio,
    local,
    nameForm,
    nameFormDomicilio
  ) => {
    if (local) {
      let persona_db = undefined;
      if (persona.hasOwnProperty("persona")) {
        if (persona.persona) persona_db = persona.persona;
      } else persona_db = persona;

      if (persona_db) {
        window
          .jQuery("input[name='" + nameForm + "[nombre]']")
          .val(persona_db.nombre);
        window
          .jQuery("input[name='" + nameForm + "[apellido_paterno]']")
          .val(persona_db.apellido_paterno);
        window
          .jQuery("input[name='" + nameForm + "[apellido_materno]']")
          .val(persona_db.apellido_materno);

        if (Boolean(persona_db.apellido_casada)) {
          setShowApellidoCasada(true);
          setStartDatePersona(new Date(persona_db.fecha_nacimiento));
          setCiExpedido(persona_db.expedido_en);
          window
            .jQuery("select[name='" + nameForm + "[estado_civil]']")
            .val(Constant[0].estado_civil.casado)
            .trigger("change");
        } else {
          setStartDatePersona(new Date(persona_db.fecha_nacimiento));
          setCiExpedido(persona_db.expedido_en);
        }

        if (persona_db.genero)
          window
            .jQuery("select[name='" + nameForm + "[genero]']")
            .val(parseInt(persona_db.genero))
            .trigger("change");

        if (persona_db.estado_civil && !persona_db.apellido_casada)
          window
            .jQuery("select[name='" + nameForm + "[estado_civil]']")
            .val(parseInt(persona_db.estado_civil))
            .trigger("change");
      }

      if (domicilio) {
        window
          .jQuery("input[name='" + nameFormDomicilio + "[telefono]']")
          .val(domicilio.telefono);
        window
          .jQuery("input[name='" + nameFormDomicilio + "[celular]']")
          .val(domicilio.celular);
        window
          .jQuery("input[name='" + nameFormDomicilio + "[email]']")
          .val(domicilio.email);
        if (domicilio.email !== "") {
          window
            .jQuery("input[name='" + nameFormDomicilio + "[email]']")
            .focus();
          setTimeout(() => {
            var event = new Event("onblur", {
              bubbles: true,
              cancelable: true,
            });
            window
              .jQuery("input[name='" + nameFormDomicilio + "[email]']")[0]
              .dispatchEvent(event);
            handleEventBlurEmail(event);
          }, 200);
        }

        window
          .jQuery("input[name='" + nameFormDomicilio + "[direccion]']")
          .val(domicilio.direccion);
        window
          .jQuery("input[name='" + nameFormDomicilio + "[adyacente_entre]']")
          .val(domicilio.adyacente_entre);
        window
          .jQuery("input[name='" + nameFormDomicilio + "[adyacente_y]']")
          .val(domicilio.adyacente_y);
      }

      const dropdownMenuPerSol = window
        .jQuery("#" + nameForm + "-dropdown-solicitante-tipo-doc")
        .parent()
        .children(":first");

      if (persona.expedido_en !== "") {
        switch (persona.expedido_en) {
          case Constant[0].ci_expedido.cbba.new:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.cbba.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.cbba.new;
            break;
          case Constant[0].ci_expedido.lapaz.new:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.lapaz.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.lapaz.new;
            break;
          case Constant[0].ci_expedido.santacruz.new:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.santacruz.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.santacruz.new;
            break;
          case Constant[0].ci_expedido.oruro.new:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.oruro.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.oruro.new;
            break;
          case Constant[0].ci_expedido.potosi.new:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.potosi.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.potosi.new;
            break;
          case Constant[0].ci_expedido.pando.new:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.pando.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.pando.new;
            break;
          case Constant[0].ci_expedido.beni.new:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.beni.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.beni.new;
            break;
          case Constant[0].ci_expedido.tarija.new:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.tarija.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.tarija.new;
            break;
          case Constant[0].ci_expedido.sucre.new:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.sucre.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.sucre.new;
            break;
          case Constant[0].ci_expedido.sin_expedicion.new:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.sin_expedicion.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.sin_expedicion.new;
            break;
          default:
            if (Boolean(persona.expedido_en)) {
              dropdownMenuPerSol[0].nextElementSibling.innerText =
                persona.expedido_en;
              document.getElementsByName(nameForm + "[expedido_en]")[0].value =
                persona.expedido_en;
            } else dropdownMenuPerSol[0].nextElementSibling.innerText = "EX";
            break;
        }
      }
    } else {
      document.getElementsByName(nameForm + "[nombre]")[0].value =
        persona.nombres;
      document.getElementsByName(nameForm + "[apellido_paterno]")[0].value =
        persona.ap_paterno;
      document.getElementsByName(nameForm + "[apellido_materno]")[0].value =
        persona.ap_materno;

      if (Boolean(persona.ap_casada)) {
        setShowApellidoCasada(true);
        setCiExpedido(persona.lugar_ci);
        window
          .jQuery("select[name='" + nameForm + "[estado_civil]']")
          .val(Constant[0].estado_civil.casado)
          .trigger("change");
        document.getElementsByName(nameForm + "[apellido_casada]")[0].value =
          persona.ap_casada;
      } else {
        setShowApellidoCasada(false);
        setCiExpedido(persona.lugar_ci);
      }

      const dropdownMenuPerSol = window
        .jQuery("#" + nameForm + "-dropdown-solicitante-tipo-doc")
        .parent()
        .children(":first");
      if (persona.expedido_en !== "") {
        switch (persona.expedido_en) {
          case Constant[0].ci_expedido.cbba.old:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.cbba.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.cbba.new;
            break;
          case Constant[0].ci_expedido.lapaz.old:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.lapaz.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.lapaz.new;
            break;
          case Constant[0].ci_expedido.santacruz.old:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.santacruz.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.santacruz.new;
            break;
          case Constant[0].ci_expedido.oruro.old:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.oruro.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.oruro.new;
            break;
          case Constant[0].ci_expedido.potosi.old:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.potosi.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.potosi.new;
            break;
          case Constant[0].ci_expedido.pando.old:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.pando.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.pando.new;
            break;
          case Constant[0].ci_expedido.beni.old:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.beni.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.beni.new;
            break;
          case Constant[0].ci_expedido.tarija.old:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.tarija.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.tarija.new;
            break;
          case Constant[0].ci_expedido.sucre.old:
            dropdownMenuPerSol[0].nextElementSibling.innerText =
              Constant[0].ci_expedido.sucre.new;
            document.getElementsByName(nameForm + "[expedido_en]")[0].value =
              Constant[0].ci_expedido.sucre.new;
            break;
          default:
            if (Boolean(persona.expedido_en)) {
              dropdownMenuPerSol[0].nextElementSibling.innerText =
                persona.expedido_en;
              document.getElementsByName(nameForm + "[expedido_en]")[0].value =
                persona.expedido_en;
            } else dropdownMenuPerSol[0].nextElementSibling.innerText = "EX";
            break;
        }
      }
    }
  };

  const handleChangeDocumento = (event) => {
    event.preventDefault();
    if (event.currentTarget.selectedOptions[0].value !== "") {
      setShowFechaPoder(true);
      if (event.currentTarget.selectedOptions[0].value === "1")
        setTextFechaPoder("Fecha Minuta Compra Venta:");
      if (event.currentTarget.selectedOptions[0].value === "2")
        setTextFechaPoder("Fecha Documento Privado:");
      if (event.currentTarget.selectedOptions[0].value === "3")
        setTextFechaPoder("Declaratoria de Herederos:");
    } else setShowFechaPoder(false);
  };

  const handleEventBlurEmail = (event) => {
    event.preventDefault();
    const emailInput = event.target;
    if (emailInput.value !== "") {
      let email = emailInput.value;
      const form = new FormData();
      form.append("email", email);

      const validEmail = fetch.fetchPost(form, `api/validar-email`, undefined);
      validEmail.then((dataJson) => {
        if (dataJson && dataJson.status) {
          emailInput.nextSibling.classList.remove("bg-mandy");
          emailInput.nextSibling.firstElementChild.classList.remove("fa-times");
          emailInput.nextSibling.classList.add("bg-fountai-blue");
          emailInput.nextSibling.firstElementChild.classList.add("fa-check");
          setIsEmailValid(true);
          toast.success(dataJson.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          setIsEmailValid(false);
          emailInput.nextSibling.classList.add("bg-mandy");
          emailInput.nextSibling.firstElementChild.classList.add("fa-times");
          emailInput.nextSibling.classList.remove("bg-fountai-blue");
          emailInput.nextSibling.firstElementChild.classList.remove("fa-check");
        }
      });
    }
  };

  return (
    <div className="row">
      <PasosNavigationCambioRadicatoria
        titulo_paso1={"Datos Vehículo"}
        paso1_active={true}
        paso2_active={true}
        paso3_active={false}
      />
      <form
        action=""
        className="contact__form needs-validation"
        name="formDatosPoseedor"
        id="formDatosPoseedor"
        method="post"
        noValidate
        onSubmit={props.onSubmitForm}
        style={{ width: "100%" }}
      >
        <div className="card widget widget-simple w-100">
          <div className="card-body ">
            <div className="row ">
              <div className="col-12 widget-header">
                <h4 className="text-black">{Texto.datos_poseedor}</h4>
              </div>
            </div>

            <div className="widget-content">
              <div className="widget-body">
                <div className="row ">
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <label
                      htmlFor={props.nameForm + "[id_tipo_documento]"}
                      className="form-label"
                    >
                      Tipo Documento *
                    </label>
                    <select
                      className="form-select"
                      name={props.nameForm + "[id_tipo_documento]"}
                      onChange={handleTipoDocumentoOnChange}
                      data-parsley-required="true"
                      required
                    >
                      <option defaultValue value="">
                        Seleccione Tipo Documento
                      </option>
                      {optionsTipoDocumento}
                    </select>
                  </div>

                  {isTipoDocumentoCi ? (
                    <div className="col-12 col-md-4 col-lg-3 mb-3 padding-left-right-0">
                      <label
                        htmlFor={props.nameForm + "[numero_documento]"}
                        className="form-label"
                      >
                        Número de Documento *
                      </label>
                      <div className="input-group ">
                        <input
                          type="text"
                          className="form-control input-uppercase"
                          name={props.nameForm + "[numero_documento]"}
                          placeholder="Número Documento"
                          data-parsley-required="true"
                          required
                          pattern="[a-zA-Z0-9-]+"
                          data-parsley-pattern="[a-zA-Z0-9-]+"
                        />

                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                        >
                          EX
                        </button>
                        <input
                          type="hidden"
                          name={props.nameForm + "[expedido_en]"}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="visually-hidden">
                            Toggle Dropdown
                          </span>
                        </button>
                        <ul
                          className="dropdown-menu dropdown-menu-end"
                          id={props.nameForm + "-dropdown-solicitante-tipo-doc"}
                        >
                          <li>
                            <a className="dropdown-item" href="#" code="CB">
                              Cochabamba
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#" code="LP">
                              La Paz
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#" code="SC">
                              Santa Cruz
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#" code="OR">
                              Oruro
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#" code="CH">
                              Chuquisaca
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#" code="PO">
                              Potosi
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#" code="BN">
                              Beni
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#" code="PD">
                              Pando
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#" code="TJ">
                              Tarija
                            </a>
                          </li>
                          <li className="border-exception">
                            <a className="dropdown-item" href="#" code="SE">
                              Sin Expedido
                            </a>
                          </li>
                        </ul>
                        <button
                          className="btn btn-outline-secondary "
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Buscar por CI"
                          onClick={handleSearchByCiOnClick}
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12 col-md-4 col-lg-3 mb-3 padding-left-right-0">
                      <label
                        htmlFor={props.nameForm + "[numero_documento]"}
                        className="form-label"
                      >
                        Número de Documento *
                      </label>
                      <input
                        type="text"
                        className="form-control input-uppercase"
                        name={props.nameForm + "[numero_documento]"}
                        placeholder="Número Documento"
                        data-parsley-required="true"
                        required
                        pattern="[a-zA-Z0-9-]+"
                        data-parsley-pattern="[a-zA-Z0-9-]+"
                      />
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <label
                      htmlFor={props.nameForm + "[nombre]"}
                      className="form-label"
                    >
                      Nombre(s) *
                    </label>
                    <input
                      name={props.nameForm + "[nombre]"}
                      type="text"
                      className="form-control input-uppercase"
                      placeholder="Nombre"
                      required
                      data-parsley-required="true"
                      pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
                      data-parsley-pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
                    />
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <label
                      htmlFor={props.nameForm + "[apellido_paterno]"}
                      className="form-label"
                    >
                      Primer Apellido *
                    </label>
                    <input
                      name={props.nameForm + "[apellido_paterno]"}
                      type="text"
                      className="form-control input-uppercase"
                      placeholder="Apellido Paterno"
                      required
                      data-parsley-required="true"
                      pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
                      data-parsley-pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
                    />
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <label
                      htmlFor={props.nameForm + "[apellido_materno]"}
                      className="form-label"
                    >
                      Segundo Apellido
                    </label>
                    <input
                      name={props.nameForm + "[apellido_materno]"}
                      type="text"
                      className="form-control input-uppercase"
                      placeholder="Apellido Materno"
                      pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
                      data-parsley-pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card widget widget-simple w-100">
          <div className="card-body ">
            <div className="row ">
              <div className="col-12 widget-header">
                <h4 className="text-black">
                  {Texto.datos_complementarios_poseedor}
                </h4>
              </div>
            </div>
            <div className="widget-content">
              <div className="widget-body">
                <div className="row">
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <label
                      htmlFor={props.nameForm + "[fecha_nacimiento]"}
                      className="form-label"
                    >
                      F. Nacimiento <small>>18</small> *
                    </label>
                    <DatePicker
                      locale="es"
                      dateFormat={Config[4].format}
                      selected={startDatePersona}
                      onChange={handleDatePickerPersonaChange}
                      maxDate={Config[1].anio}
                      className="form-control"
                      name={props.nameForm + "[fecha_nacimiento]"}
                      data-parsley-required="true"
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      style={{ with: "100%" }}
                      required
                    />
                  </div>

                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <label
                      htmlFor={props.nameForm + "[genero]"}
                      className="form-label"
                    >
                      Género *
                    </label>
                    <select
                      className="form-select"
                      name={props.nameForm + "[genero]"}
                      required
                      data-parsley-required="true"
                    >
                      <option defaultValue value="">
                        Género
                      </option>
                      {optionsGenero}
                    </select>
                  </div>

                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <label
                      htmlFor={props.nameForm + "[estado_civil]"}
                      className="form-label"
                    >
                      Estado Civil *
                    </label>
                    <select
                      className="form-select"
                      name={props.nameForm + "[estado_civil]"}
                      required
                      onChange={handleEstadoCivilOnChange}
                    >
                      <option defaultValue value="">
                        Estado Civil
                      </option>
                      {optionsEstadoCivil}
                    </select>
                  </div>
                  {showApellidoCasada ? (
                    <div className="col-12 col-md-4 col-lg-3 mb-3">
                      <label
                        htmlFor={props.nameForm + "[apellido_casada]"}
                        className="form-label"
                      >
                        Apellido Casada:
                      </label>
                      <input
                        name={props.nameForm + "[apellido_casada]"}
                        type="text"
                        className="form-control input-uppercase"
                        placeholder="Apellido Casada"
                        pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
                        data-parsley-pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <label
                      htmlFor={props.nameFormDomicilio + "[telefono]"}
                      className="form-label"
                    >
                      Teléfono
                    </label>
                    <input
                      name={props.nameFormDomicilio + "[telefono]"}
                      type="text"
                      className="form-control input-uppercase"
                      placeholder="Teléfono"
                      pattern="[0-9]+"
                      data-parsley-pattern="[0-9]+"
                      data-parsley-maxlength="7"
                      maxLength="7"
                    />
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <label
                      htmlFor={props.nameFormDomicilio + "[celular]"}
                      className="form-label"
                    >
                      Celular *
                    </label>
                    <input
                      name={props.nameFormDomicilio + "[celular]"}
                      type="text"
                      className="form-control input-uppercase"
                      placeholder="Celular"
                      required
                      data-parsley-required="true"
                      pattern="(6|7)([0-9]){7}"
                      data-parsley-pattern="(6|7)([0-9]){7}"
                      data-parsley-maxlength="8"
                      maxLength="8"
                    />
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <label
                      htmlFor={props.nameFormDomicilio + "[numero]"}
                      className="form-label"
                    >
                      Número Casa
                    </label>
                    <div className="input-group mb-3">
                      <div className="input-group-text">
                        <input
                          type="checkbox"
                          id="checkNumero"
                          className="form-check-input"
                          onChange={handleNumeroOnchange}
                        />
                      </div>
                      <input
                        type="text"
                        className="form-control input-uppercase"
                        name={props.nameFormDomicilio + "[numero]"}
                        placeholder="S/N"
                        data-parsley-required="false"
                        pattern="[0-9]+"
                        data-parsley-pattern="[0-9]+"
                        data-parsley-maxlength="4"
                        maxLength="4"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-8 col-lg-3 mb-3">
                    <label
                      htmlFor={props.nameFormDomicilio + "[email]"}
                      className="form-label"
                    >
                      Correo Electronico *:
                    </label>
                    <div className="input-group ">
                      <input
                        name={props.nameFormDomicilio + "[email]"}
                        type="text"
                        className="form-control input-uppercase"
                        placeholder="Correo Electronico"
                        data-parsley-required="true"
                        pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                        data-parsley-pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                        onBlur={handleEventBlurEmail}
                      />
                      <span className="input-group-text bg-mandy text-white">
                        <i className="fas fa-times"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 mb-3">
                    <label
                      htmlFor={props.nameFormDomicilio + "[direccion]"}
                      className="form-label"
                    >
                      Dirección Descriptiva*
                    </label>
                    <button
                      type="button"
                      className="btn link-help linkHelpFDAE"
                      id="linkHelpFDAE"
                    >
                      <i
                        className="fa fa-question-circle"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <input
                      name={props.nameFormDomicilio + "[direccion]"}
                      type="text"
                      className="form-control input-uppercase"
                      placeholder="Dirección Descriptiva"
                      data-parsley-required="true"
                      required
                      pattern="[.,a-zA-Z'0-9 À-ÿ\u00f1\u00d1]+"
                      data-parsley-pattern="[.,a-zA-Z'0-9 À-ÿ\u00f1\u00d1]+"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <label
                      htmlFor={props.nameFormDomicilio + "[adyacente_entre]"}
                      className="form-label"
                    >
                      Calle/Avenida Adyacente *
                    </label>
                    <button
                      type="button"
                      className="btn link-help linkHelpFDAdyaAE"
                      id="linkHelpFDAdyaAE"
                    >
                      <i
                        className="fa fa-question-circle"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <div className="input-group ">
                      <div className="d-flex btn-full-screen  mb-3">
                        <div className="input-group-prepend">
                          <div className="input-group-text bg-white border-0">
                            <span>Entre</span>
                          </div>
                        </div>
                        <input
                          name={props.nameFormDomicilio + "[adyacente_entre]"}
                          type="text"
                          className="form-control input-uppercase"
                          placeholder=""
                          data-parsley-required="true"
                          required
                          pattern="[.,a-zA-Z0-9 À-ÿ\u00f1\u00d1]+"
                          data-parsley-pattern="[.,a-zA-Z0-9 À-ÿ\u00f1\u00d1]+"
                        />
                      </div>
                      <div className="d-flex btn-full-screen  mb-3">
                        <div className="input-group-prepend">
                          <div className="input-group-text bg-white border-0">
                            <span>Y</span>
                          </div>
                        </div>
                        <input
                          name={props.nameFormDomicilio + "[adyacente_y]"}
                          type="text"
                          className="form-control input-uppercase"
                          placeholder=""
                          data-parsley-required="true"
                          required
                          pattern="[.,a-zA-Z0-9 À-ÿ\u00f1\u00d1]+"
                          data-parsley-pattern="[.,a-zA-Z0-9 À-ÿ\u00f1\u00d1]+"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card widget widget-simple w-100">
          <div className="card-body ">
            <div className="row ">
              <div className="col-12 widget-header">
                <h4 className="text-black">{Texto.documentos_poseedor}</h4>
              </div>
            </div>

            <div className="widget-content">
              <div className="widget-body">
                <div className="row">
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <label
                      htmlFor={props.nameFormPoseedor + "[id_documento]"}
                      className="form-label"
                    >
                      Documentos{" "}
                    </label>
                    <select
                      className="form-select"
                      name={props.nameFormPoseedor + "[id_tipo_documento]"}
                      onChange={handleChangeDocumento}
                    >
                      <option defaultValue value="">
                        Seleccione Documento
                      </option>
                      {optionsTiposDocumentosVehiculo}
                    </select>
                  </div>

                  {showFechaPoder ? (
                    <div className="col-12 col-md-4 col-lg-3 mb-3">
                      <label
                        htmlFor={props.nameFormPoseedor + "[fecha_poder]"}
                        className="form-label"
                      >
                        {textFechaPoder} *
                      </label>
                      <DatePicker
                        locale="es"
                        dateFormat={Config[4].format}
                        selected={startDateFechaPoder}
                        onChange={handleDatePickerFechaPoderChange}
                        maxDate={Config[7].anio}
                        className="form-control"
                        name={props.nameFormPoseedor + "[fecha_poder]"}
                        data-parsley-required="true"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        style={{ with: "100%" }}
                        required
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          {isEmailValid ? (
            <div className="col-12 col-md-12 col-lg-12 text-end">
              <div className=" search-btn button ">
                <input
                  name="Siguiente"
                  type="submit"
                  className="btn btn-full-screen"
                  value={props.buttonName}
                />
              </div>
            </div>
          ) : (
            <div className="col-12 col-md-12 col-lg-12">
              <div className="alert alert-danger" role="alert">
                Verifica el email ingresado, no se ha podido verificar la
                existencia de la misma
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default FormDatosPoseedor;
