import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Texto from "../../data/es";
//import PasosNavigationVisacion from "../utils/PasosNavigationVisacionMinutas";
import PasosNavigationAutorizacion from "../utils/PasosNavigationAutorizacion";
import ViewDataJuridico from "../../components/component/Contribuyente/ViewDataJuridico";
import { Link } from "react-router-dom";

const FormOperador = (props) => {
  const { dataInmueble, onSubmitForm, fetch, dataOperadorDb } = props;
  const idForm = "formDataOperator";

  const [message, setMessage] = useState(undefined);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showPanelSearchResult, setShowPanelSearchResult] = useState(false);
  const [showModalSearch, setShowModalSearch] = useState(false);
  const [contribuyentesSearchList, setContribuyentesSearchList] = useState([]);
  const [modalSearchPmc, setModalSearchPmc] = useState(undefined);
  const [dataJuridico, setDataJuridico] = useState(false);

  const refButtonNextForm = useRef();
  const refInputSearchModal = useRef();
  const refInputSearchPmc = useRef();

  useEffect(() => {
    debugger;
    console.log("--------------", props);
    if (dataOperadorDb) {
      const arrayCopropietario = [];
      const pmcInput = refInputSearchPmc.current;
      pmcInput.value = dataOperadorDb.pmc;

      const eventBlur = new Event("blur");
      pmcInput.dispatchEvent(eventBlur);
    }
    window.scrollTo(0, 0);
  }, []);

  const handleOnFocusPmc = async (event) => {
    event.preventDefault();
    setDataJuridico(undefined);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") event.preventDefault();
  };

  const handleSearchByPmcOnClick = async (event) => {
    event.preventDefault();
    debugger;
    let input_search = event.target;
    if (input_search.value !== "" && input_search.value.length > 3) {
      const search_persona = fetch.fetchGet(
        `api-ruat/contribuyentes/pmc/${input_search.value}`
      );
      search_persona.then(async (dataJson) => {
        debugger;
        if (dataJson && dataJson.status) {
          let contribuyentes = dataJson.data.contribuyente;
          contribuyentes = contribuyentes.filter(
            (contribuyente) => contribuyente.contribuyenteActualizado === "1"
          );

          if (contribuyentes && contribuyentes.length === 0)
            setMessage(
              `No se ha localizado a ningún Contribuyente con el PMC ${input_search.value}`
            );

          if (contribuyentes && contribuyentes.length >= 3)
            setMessage(
              `Se ha detectado multiples contribuyentes con el PMC ${input_search.value}`
            );

          if (
            contribuyentes &&
            contribuyentes.length <= 2 &&
            contribuyentes.length !== 0
          ) {
            setMessage(undefined);
            contribuyentes = contribuyentes[0];

            if (contribuyentes.tipoContribuyente === "NATURAL")
              setMessage(
                `Verifique que los datos sean válidos para contribuyentes Juridicos`
              );

            if (contribuyentes.tipoContribuyente === "JURIDICO") {
              let representante = contribuyentes.apoderado
                ? contribuyentes.apoderado
                : undefined;

              const representateLegal = {
                tipoDocumento: representante.tipoDocumento,
                numeroDocumento: representante.numeroDocumento,
                nombre: representante.nombres,
                apellidoPaterno: representante.primerApellido,
                apellidoMaterno: representante.segundoApellido,
                apellidoCasada: representante.apellidoEsposo
                  ? representante.apellidoEsposo
                  : "",
                estadoCivil: representante.estadoCivil,
                genero: representante.genero,
                fechaNacimiento: representante.fechaNacimiento,
                expedidoEn: representante.expedido,
                tipoDocumentoCode: representante.tipoDocumento,
                pmc: 0,
                contribuyente: "JURIDICO",
                razonSocial: contribuyentes.juridico
                  ? contribuyentes.juridico.razonSocial
                  : undefined,
              };

              let propietarioJuridico = undefined;
              if (contribuyentes.juridico) {
                propietarioJuridico = contribuyentes.juridico;
                propietarioJuridico.nombreContribuyente =
                  contribuyentes.nombreContribuyente;
                propietarioJuridico.pmc = contribuyentes.pmc;
                propietarioJuridico.domicilioLegal =
                  contribuyentes.domicilioLegal;
                propietarioJuridico.tipoContribuyente =
                  contribuyentes.tipoContribuyente;
                propietarioJuridico.pmc = contribuyentes.pmc;
              }
              const infomacionAdicional = contribuyentes.informacionAdicional;
              const poder = contribuyentes.poder;

              setDataJuridico({
                dataJuridico: propietarioJuridico,
                dataApoderado: representateLegal,
                dataPoder: poder,
                dataInformationAditional: infomacionAdicional,
              });
            }

            //input_search.value = "";

            fetch.toast.success(dataJson.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        } else {
          setMessage(
            "No existe registro de Contribuyente asociado al PMC, a mayor información click en "
          );
        }
      });
    }
  };

  const submitFormToBuy = (event) => {
    debugger;
    if (dataJuridico) {
      const juridico = dataJuridico.dataJuridico;
      const apoderado = dataJuridico.dataApoderado;
      const poder = dataJuridico.dataPoder;
      const dataInformationAditional = dataJuridico.dataInformationAditional;

      const data = {
        juridico: juridico,
        apoderado: apoderado,
        poder: poder,
        dataInformationAditional: dataInformationAditional,
        contribuyente: "JURIDICO",
      };
      onSubmitForm(event, data);
    } else {
      event.preventDefault();
      toast.warn("Debe ingresar comprador(es)", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const openModalSearchContribuyente = (event) => {
    event.preventDefault();
    debugger;
    setShowModalSearch(true);
    setTimeout(() => {
      setContribuyentesSearchList([]);
      setShowPanelSearchResult(false);
      const modalSearchDom = document.getElementById(
        "modalSearchContribuyentesAutorization"
      );
      const modalSearch = new window.bootstrap.Modal(modalSearchDom, {
        keyboard: true,
        backdrop: "static",
        focus: true,
      });
      modalSearch.show();
      setModalSearchPmc(modalSearch);
    }, 500);
  };

  const handleSubmitSearchForm = (event) => {
    event.preventDefault();
    debugger;
    window.inputConvertUpperCaseForm(idForm);
    const form = event.target;
    const optionSelected =
      form.getElementsByTagName("select")[0].selectedOptions[0].value;
    const inputSearch = form.getElementsByTagName("input")[0].value;

    if (inputSearch.length > 0) {
      setLoadingSearch(true);
      setShowPanelSearchResult(false);

      fetch
        .fetchGet(
          `api-ruat/contribuyentes/search-nit-ci/${inputSearch}/${optionSelected.toUpperCase()}`
        )
        .then((res) => {
          debugger;
          if (res && res.status) {
            setShowPanelSearchResult(true);
            const contribuyentes = res.data.contribuyente;
            setContribuyentesSearchList(contribuyentes);
          } else {
            setContribuyentesSearchList([]);
          }
          setLoadingSearch(false);
          window.inputConvertUpperCaseForm("formSearchDataTable");
        });
    }
  };

  const handleEventSelectedPmc = (event, contribuyente) => {
    const inputSearch = refInputSearchPmc.current;
    inputSearch.focus();
    setTimeout(() => {
      inputSearch.value = contribuyente.pmc;
      const eventBlur = new Event("blur");
      inputSearch.dispatchEvent(eventBlur);
    }, 500);
    refInputSearchModal.current.value = "";
    modalSearchPmc.hide();
  };

  return (
    <>
      <PasosNavigationAutorizacion
        nro_inmueble={dataInmueble.numeroInmueble}
        paso1_active={true}
        paso2_active={true}
        paso3_active={false}
        paso4_active={false}
        paso5_active={false}
      />

      <form
        action=""
        className="contact__form needs-validation"
        name={idForm}
        id={idForm}
        method="post"
        noValidate
        onSubmit={submitFormToBuy}
        style={{ width: "100%" }}
      >
        <div className="card widget widget-simple">
          <div className="card-body ">
            <div className="row ">
              <div className="col-12 col-md-8 widget-header mb-3">
                <h4 className="text-maroon-flush">
                  <i className="fas fa-bookmark me-2"></i> Buscar Operador
                </h4>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <label htmlFor={"[pmc_search]"} className="form-label">
                  Padrón Municipal Contribuyente (PMC) *
                </label>
                <div className="input-group ">
                  <input
                    type="text"
                    className="form-control input-uppercase"
                    name={"[pmc_search]"}
                    placeholder="PMC"
                    id={"[pmc_search]"}
                    pattern="[a-zA-Z0-9]+"
                    data-parsley-pattern="[a-zA-Z0-9]+"
                    onBlur={handleSearchByPmcOnClick}
                    onFocus={handleOnFocusPmc}
                    onKeyDown={handleKeyDown}
                    ref={refInputSearchPmc}
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Buscar"
                  >
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-3 offset-md-4 offset-lg-4 text-end">
                <Link
                  to="#"
                  title="Buscar Contribuyente por NIT"
                  rel="noopener noreferrer"
                  className="text-decoration-underline"
                  onClick={openModalSearchContribuyente}
                >
                  No Recuerdo Mi PMC, Click Aquí
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {message ? (
            <div className="col-12">
              <div
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                <strong>Atención!</strong> {message}
                <Link
                  to="#"
                  title="Consulta Contribuyentes"
                  style={{ paddingLeft: "5px" }}
                  rel="noopener noreferrer"
                  onClick={openModalSearchContribuyente}
                >
                  Consulta Contribuyentes
                </Link>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        {dataJuridico ? (
          <ViewDataJuridico
            dataJuridico={dataJuridico.dataJuridico}
            dataApoderado={dataJuridico.dataApoderado}
            dataPoder={dataJuridico.dataPoder}
            dataInformationAditional={dataJuridico.dataInformationAditional}
            //handleClickAddCopropietario={handleClickAddCopropietario}
            showButtonAdd={false}
            isVisacion={false}
          />
        ) : (
          ""
        )}
        <div className="row">
          <div className={`hol col-12 text-end col-md-9 col-lg-9`}></div>
          {dataJuridico ? (
            <div className="col-12 col-md-3 col-lg-3 text-end">
              <div className=" search-btn button mb-3">
                <input
                  type="submit"
                  className="btn btn-full-screen"
                  ref={refButtonNextForm}
                  name="Siguiente"
                  value="Siguiente"
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </form>

      {showModalSearch ? (
        <div
          className="modal fade "
          id={"modalSearchContribuyentesAutorization"}
          tabIndex="-1"
          aria-labelledby="modalSearchContribuyentesAutorizationTitle"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
          style={{ overflowY: "auto" }}
        >
          <div className="modal-dialog  modal-dialog-centered modal-lg">
            <div className="modal-content ">
              <div className="modal-header text-butterfly-bush justify-content-center">
                <h4
                  className="modal-title "
                  id="modalSearchContribuyentesAutorizationTitle"
                  style={{ fontWeight: 600 }}
                >
                  Buscador de Contribuyentes
                </h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form
                  action=""
                  className="contact__form needs-validation center-login"
                  name="formSearchDataTable"
                  id="formSearchDataTable"
                  method="post"
                  noValidate
                  onSubmit={handleSubmitSearchForm}
                  style={{ width: "100%" }}
                >
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 ">
                      <div className="input-group mb-3">
                        <select
                          className="form-select "
                          name="type"
                          required
                          data-parsley-required="true"
                        >
                          <option key={0} value="NIT">
                            Número de Identificación Tributaria
                          </option>
                        </select>

                        <input
                          type="text"
                          name="search"
                          id="search"
                          className="form-control input-uppercase"
                          placeholder="*"
                          pattern="[0-9-A-Z]+"
                          data-parsley-pattern="[0-9-A-Z]+"
                          ref={refInputSearchModal}
                        />

                        <button
                          className="btn btn-outline-secondary"
                          type="submit"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Buscar"
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                {loadingSearch ? (
                  <div className="row">
                    <div className="col-12 mb-1">
                      <img
                        className="rounded mx-auto d-block"
                        alt="pulse-loading"
                        src={"/static/img/pulse_200.gif"}
                        width="70"
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {showPanelSearchResult ? (
                  <div className="card widget widget-simple">
                    <div className="card-body ">
                      <div className="row ">
                        <div className="col-12 col-md-12 widget-header mb-3">
                          <h4 className="text-maroon-flush">
                            <i className="fas fa-bookmark me-2"></i>{" "}
                            {Texto.seleccione_padron_actualizado}
                          </h4>
                        </div>
                        <div className="col-4 col-md-6 widget-header mb-3"></div>
                      </div>

                      <div className="widget-content">
                        <div className="widget-body">
                          <div className="row ">
                            {contribuyentesSearchList.map((contribuyente) => {
                              return (
                                <div
                                  key={contribuyente.pmc}
                                  className="col-sm-12 col-md-4 col-lg-4 text-butterfly-bush pointer"
                                  onClick={(e) =>
                                    handleEventSelectedPmc(e, contribuyente)
                                  }
                                >
                                  <p
                                    className="text-left h3 bg-gallery"
                                    style={{
                                      border: "1px solid rgb(174,24,87, .3)",
                                      borderRadius: 20,
                                      padding: 10,
                                      textAlign: "center",
                                    }}
                                  >
                                    <span className="fw-bolder">
                                      {contribuyente.pmc}
                                    </span>
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {!showPanelSearchResult
                  ? contribuyentesSearchList.length === 0 && refInputSearchModal
                    ? "No se ha encontrado ningún resultado"
                    : ""
                  : ""}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  );
};

export default FormOperador;
