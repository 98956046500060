import React, { Component } from 'react';

import Fetch from '../../components/utils/Fetch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var _fetch = undefined
class HeaderTotales extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            totalLicencias: 0,
        };

        _fetch = this.props.fetch;
    }

    async componentDidMount() {
        const response = await _fetch.axiosAsyncGet(`api/licencia-actividad-economica/get-all`);
        if (response !== null && response.status === true )
            this.setState({ totalLicencias: response.Total })
    }

    render() {
        return (
            <div className="row">
                <div className="col-12 col-sm-6 col-md-3">
                    <div className="info-box">
                        <span className="info-box-icon bg-info elevation-1"><i className="fa fa-address-card-o"></i></span>

                        <div className="info-box-content">
                            <span className="info-box-text">Licencias de Funcionamiento</span>
                            <span className="info-box-number">
                                {this.state.totalLicencias}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-md-3">
                    <div className="info-box mb-3">
                        <span className="info-box-icon bg-danger elevation-1"><i className="fa fa-users"></i></span>

                        <div className="info-box-content">
                            <span className="info-box-text">Visación Minutas</span>
                            <span className="info-box-number">0</span>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-md-3">
                    <div className="info-box mb-3">
                        <span className="info-box-icon bg-success elevation-1"><i className="fa fa-hourglass-half"></i></span>

                        <div className="info-box-content">
                            <span className="info-box-text">SAAT</span>
                            <span className="info-box-number">0</span>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-md-3">
                    <div className="info-box mb-3">
                        <span className="info-box-icon bg-warning elevation-1"><i className="fa fa-hourglass-o"></i></span>

                        <div className="info-box-content">
                            <span className="info-box-text">Certificaciones</span>
                            <span className="info-box-number">0</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeaderTotales;