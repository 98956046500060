import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Fetch from "../utils/Fetch";
import "react-toastify/dist/ReactToastify.css";

const ContribuyenteResult = ({ listResultado, imto }) => {
	debugger;

	const fetch = new Fetch();
	fetch.setToast(toast);

	var fechaActual = new Date();

	const [titleObject, setTitleObject] = useState("");
	const [titleCol, setTitleCol] = useState("");
	const [codeObjeto, setCodeObjeto] = useState("");
	const [data, setData] = useState([]);

	useEffect(() => {
		procesarItems();
	}, []);

	const procesarItems = () => {
		
		for (const [key, row] of Object.entries(listResultado)) {
			setTitleObject(textoConvertCamelcase(row.nombre));
			setTitleCol(row.columna);
			setCodeObjeto(row.codeObjeto);
			setData(row.data);
		}
	};

	const formatDateDMY = (fecha) => {
		return window.moment(fecha).format("DD/MM/YYYY");
	};

	const textoConvertCamelcase = (texto) => {
		return texto.length > 0 ? texto.toUpperCase() : "";
	};

	// ===================================================================================== //
	// ruat
	// ===================================================================================== //	
	
	const deudaBs2DA = (estado_2da, total_bs_actualizado) => {
		let resultado = "-";
		if (
			estado_2da == "SI" &&
			total_bs_actualizado >= 0 &&
			total_bs_actualizado != null
		) {
			resultado = total_bs_actualizado;
		}
		return resultado >= 0 ? parseInt(resultado) : resultado;
	};

	// Monto - precioActualRuat
	const precioActual2daRuat = (row) => {
		debugger;
		// let tipoImpuesto = row.codigo.toUpperCase();
		// let numero = numeroImpuesto(row.placa_poliza, row.inmueble, row.licencia);
		// let gestionConsulta = (row.gestion_masiva == null && tipoImpuesto === "IMTO")? 0 : row.gestion_masiva;
		let resultado = row.total_bs;
		let deudaBsHoy = row.deuda_hoy;
		if (row.estado_2da === "SI") {
			resultado = (deudaBsHoy == -1 || deudaBsHoy == -2 || deudaBsHoy == -3)? null:deudaBsHoy;		
		}
		
		return resultado === null? " - ":parseInt(resultado);
	};

	const mensajeRegistro = (total_bs_actualizado,deuda_hoy, estado_2da, observacion2da, simbolo_observacion) => {
		
		let resultado = "Con deuda";
		let resultado2da = simbolo_observacion == null? "": simbolo_observacion;
		let observacion = "";
		//let deudaBsHoy = deuda_hoy;

		if(estado_2da == "SI"){
			if (total_bs_actualizado != undefined && total_bs_actualizado == 0) {
				resultado = "Sin deuda";	
			}

			if(total_bs_actualizado == -1 && observacion2da =='SI'){
				observacion = "La poliza o placa no tiene registro de la deuda actual en el Ruat.";				
			}
			if(total_bs_actualizado == -2 && observacion2da =='SI'){
				observacion = "La poliza no tiene registro de la deuda actual en el Ruat.";				
			}	
			if(total_bs_actualizado == -3 && observacion2da =='SI'){
				observacion = "La poliza o placa no tiene registro de la deuda actual en el Ruat porque es IMTO.";				
			}		
			
			if(total_bs_actualizado == 0 && observacion2da =='SI'){
				resultado = "Con deuda";
				observacion = "Registrar base imponible.";				
			}				
		}	 
		
		return <span className="text-dark" tabindex="0" data-toggle="tooltip" data-placement="top" title={observacion}>{resultado} {resultado2da}</span> ;
	};

	const numeroImpuesto = (placa_poliza, inmueble, licencia) => {
		return placa_poliza !== null
			? placa_poliza
			: inmueble !== null
			? inmueble
			: licencia;
	};

	let totalData = data.length;
	// let deudaActualizada = 0;
	let datosContribuyente = data.map((dataRows) => (
		<tr>
			<td>{dataRows.numero_rd}</td>
			<td>
				{numeroImpuesto(
					dataRows.placa_poliza,
					dataRows.inmueble,
					dataRows.licencia
				)}
			</td>
			<td>
				{dataRows.gestion_masiva === null
					? formatDateDMY(dataRows.fecha_minuta)
					: dataRows.gestion_masiva}
			</td>
			<td>{parseInt(dataRows.total_bs)}</td>
			<td>
				{deudaBs2DA(dataRows.estado_2da, dataRows.total_bs_actualizado)}
				<br />
				<small>
					{deudaBs2DA(dataRows.estado_2da, dataRows.total_bs_actualizado) > 0
						? "(UFV: " +
						  dataRows.ufv_valor +
						  " " +
						  formatDateDMY(dataRows.ufv_fecha) +
						  ")"
						: ""}
				</small>
			</td>
			<td>
				<strong>{precioActual2daRuat(dataRows)}</strong>
			</td>
			<td>
				{mensajeRegistro(dataRows.total_bs_actualizado,dataRows.deuda_hoy, dataRows.estado_2da, dataRows.observacion2da, dataRows.simbolo_observacion)}
			</td>
		</tr>
	));

	// ===================================================================================== //
	if (totalData > 0) {
		return (
			<div className="row border border-2 rounded-3 mb-2 p-3">
				<h6 className="titulo_objeto mt-1 mb-1">
					{titleObject} {imto} 
					{/* {imto.length > 1? <small className="lead float-right">Impuesto Municipal a la Transferencia</small>:''} */}
				</h6>
				<div className="table-responsive-xs overflow-scroll">
					<table className="table table-xs">
						<thead>
							<tr>
								<th scope="col-xs-1 col-md-1">Nº RD</th>
								<th scope="col-xs-3 col-md-3">{titleCol}</th>
								<th scope="col-1">{imto == "" ? "Gestión" : "Fecha Minuta"}</th>
								<th scope="col-md-2 col-lg-2 col-xs-3 ">
									1ra. Publicación <br /> Deuda (Bs.)
								</th>
								<th scope="col-md-2 col-lg-2 col-xs-3 text-wrap">
									2da. Publicación <br /> Deuda (Bs.)
								</th>
								<th scope="col-xs-2 col-md-2">
									Deuda actualizada (Bs) <br /> al {formatDateDMY(fechaActual)}
								</th>
								<th scope="col-xs-2 col-md-2">Estado</th>
							</tr>
						</thead>
						<tbody>{datosContribuyente}</tbody>
					</table>
				</div>
				<ToastContainer
					enableMultiContainer
					containerId={"Z"}
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange
					draggable
					pauseOnHover
				/>
			</div>
		);
	} else {
		return null;
	}
};

export default ContribuyenteResult;
