import React, { useEffect } from "react";
import Texto from "../../data/es";
import Config from "../../data/config";
import Links from "../../data/link";
import TitlePage from "../utils/TitlePage";
import { Link } from "react-router-dom";

const Guia = (props) => {
  const titlePage = Texto.guia;
  const breadcrumbs = [
    {
      title: Links[0].title,
      url: Links[0].url,
    },
    {
      title: Links[22].title,
      url: Links[22].url,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="items-grid section mt-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={titlePage}
          breadcrumbs={breadcrumbs}
          position={"left"}
          leftfull={false}
        />

        <div className="d-flex align-content-around justify-content-center flex-wrap pt-5 pb-5">
          <div className="p-2 bd-highlight">
            <Link
              to={{
                pathname: Links[59].url,
              }}
              className="text-decoration-none w-100"
              title={"Vehículos"}
              rel="noopener noreferrer"
            >
              <div className="square">
                <div className="top bg-aero effect">
                  <img
                    className="mx-auto d-block img-noborder image"
                    alt="car"
                    width="108"
                    height="108"
                    src={Config[2].url + "/static/img/car.png"}
                  />
                  <div className="text">Vehículos</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Leer</div>
                </div>
              </div>
            </Link>
          </div>

          <div className="p-2 bd-highlight">
            <Link
              to={{
                pathname: Links[57].url,
              }}
              className="text-decoration-none w-100"
              title={"Inmuebles"}
              rel="noopener noreferrer"
            >
              <div className="square">
                <div className="top bg-aero effect">
                  <img
                    className="mx-auto d-block img-noborder image"
                    alt="inmueble"
                    width="108"
                    height="108"
                    src={Config[2].url + "/static/img/inmueble.png"}
                  />
                  <div className="text">Inmuebles</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Leer</div>
                </div>
              </div>
            </Link>
          </div>

          <div className="p-2 bd-highlight">
            <Link
              to={{
                pathname: Links[114].url,
              }}
              className="text-decoration-none w-100"
              title={"Patentes Municipales"}
              rel="noopener noreferrer"
            >
              <div className="square">
                <div className="top bg-aero effect">
                  <img
                    className="mx-auto d-block img-noborder image"
                    alt="img-patente"
                    width="108"
                    height="108"
                    src={
                      Config[2].url +
                      "/static/img/offcanvas/actividades_econominas.png"
                    }
                  />
                  <div className="text">Patentes</div>
                </div>
                <div className="bottom pt-4">
                  <div className="text bg-aero">Leer</div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Guia;