
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Texto from '../../data/es';
import Config from '../../data/config';
import TitlePage from '../utils/TitlePage';
import Links from '../../data/link';

const Inmuebles = (props) => {

    const titlePage = Texto.guia

    const [showRequisitosInmueble, setShowRequisitosInmueble] = useState(true);
    const [showVideoInmueble, setShowVideoInmueble] = useState(false);

    const refRequisitosInmueble = useRef();
    const refImgRequisitoInmueble = useRef();
    const refTutorial = useRef();

    const handleClickShowRequisitosInmueble = (event) => {
        event.preventDefault();

        setShowRequisitosInmueble(true)
        setShowVideoInmueble(false);
        refRequisitosInmueble.current.classList.add("active");
        refRequisitosInmueble.current.classList.remove("active");
    }

    const handleOnchangeRequisitosInmuebles = (event) => {
        event.preventDefault();

        if (event.target.selectedOptions.length > 0) {
            const config = Config[2];
            const optionValue = event.target.selectedOptions[0];
            refImgRequisitoInmueble.current.setAttribute("alt", 'img-requisito-inmueble-' + event.target.selectedOptions[0].value);
            refImgRequisitoInmueble.current.setAttribute("width", '600');
            switch (optionValue.value) {
                case "TRANSFERENCIA":
                    refImgRequisitoInmueble.current.setAttribute("src", config.url + '/static/img/requisitos/inmuebles/1_TRANSFERENCIA_INMUEBLE.jpg');
                    break;
                case "TRANSFERENCIA_ESPECIAL":
                    refImgRequisitoInmueble.current.setAttribute("src", config.url + '/static/img/requisitos/inmuebles/2_TRANSFERENCIA_INMUEBLE_ESPECIAL.jpg');
                    break;
                case "REGULARIZACION_ACCIONES_DERECHOS":
                    refImgRequisitoInmueble.current.setAttribute("src", config.url + '/static/img/requisitos/inmuebles/3_REGULARIZACION_ACCIONES_DERECHOS.jpg');
                    break;
                case "DESCUENTO_TERCERA_EDAD":
                    refImgRequisitoInmueble.current.setAttribute("src", config.url + '/static/img/requisitos/inmuebles/4_DESCUENTO_TERCERA_EDAD.jpg');
                    break;
                case "VISACION_MINUTAS":
                    refImgRequisitoInmueble.current.setAttribute("src", config.url + '/static/img/requisitos/inmuebles/5_VISACION_MINUTAS.jpg');
                    break;
                default:
                    refImgRequisitoInmueble.current.setAttribute("width", '128');
                    refImgRequisitoInmueble.current.setAttribute("src", config.url + '/static/img/preview.png');
                    break;
            }
        }
    }

    const handleClickShowVideo = (event) => {
        event.preventDefault();
        setShowRequisitosInmueble(false)
        setShowVideoInmueble(true);

        refRequisitosInmueble.current.classList.remove("active");
        refTutorial.current.classList.add("active");
    }

    const [breadcrumbs, setBreadcrumbs] = useState([
        {
            title: Links[0].title,
            url: Links[0].url,
        },
        {
            title: Links[22].title,
            url: Links[22].url,
        },
        {
            title: Links[57].title,
            url: "#",
        }
    ]);

    return (

        <section className="items-grid section mt-5">
            <div className="container mt-4">
                <TitlePage titlePage={titlePage} breadcrumbs={breadcrumbs} position={'left'} leftfull={false} />

                <div className="nav-scroller bg-body shadow-sm bg-picton-blue mt-2 wow fadeInUp" data-wow-delay=".3s" style={{ marginTop: -20 }}>
                    <nav className="nav nav-underline" aria-label="Secondary navigation">
                        <Link to={""} className={`nav-link active`} title={Texto.requisitos} ref={refRequisitosInmueble} onClick={handleClickShowRequisitosInmueble}>
                            Requisitos
                        </Link>
                        <Link to={""} className={`nav-link `} aria-current="page" title={Texto.tutorial} ref={refTutorial} onClick={handleClickShowVideo} >
                            Tutorial
                        </Link>
                    </nav>
                </div>

                {
                    showRequisitosInmueble ?
                        <>
                            <div className="row mt-3 wow fadeInUp" data-wow-delay=".3s" >
                                <div className="col-12 col-md-6 col-lg-4  mb-3">
                                    <label className="form-label">Requisitos Inmuebles</label>
                                    <select className="form-select" aria-label="Requisitos Inmuebles" onChange={handleOnchangeRequisitosInmuebles} >
                                        <option defaultValue value="">Seleccione Requisito Inmueble</option>
                                        <option value="TRANSFERENCIA">Transferencia</option>
                                        <option value="TRANSFERENCIA_ESPECIAL">Transferencia Especial</option>
                                        <option value="REGULARIZACION_ACCIONES_DERECHOS">Regularización Acciones y Derechos</option>
                                        <option value="DESCUENTO_TERCERA_EDAD">Descuento Tercera Edad</option>
                                        <option value="VISACION_MINUTAS">Visación Minutas</option>
                                    </select>
                                </div>
                            </div>

                            <div className="row mt-3 wow fadeInUp" data-wow-delay=".3s">
                                <div className="col-12 text-center">
                                    <img className='rounded mx-auto d-block img-thumbnail' alt={'img-requisito-inmueble-preview'} src={Config[2].url + '/static/img/preview.png'}
                                        width="128" ref={refImgRequisitoInmueble} />
                                </div>
                            </div>
                        </>
                        : ""}

{
                    showVideoInmueble ?
                        <div className="row wow fadeInUp" data-wow-delay=".3s">
                            <div className="col-12 col-md-4 mt-3">
                                <div className="recuros-block">
                                    <h6>Guía Pagos QR</h6>
                                </div>
                                <div className="embed-responsive embed-responsive-16by9 mt-2">
                                    <video width="370" height="260" controls>
                                        <source src={Config[2].url + '/static/video/inmuebles/pago_qr_inmueble.mp4'} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                        : ""
                }
            </div>
        </section>
    )
}

export default Inmuebles;