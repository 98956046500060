import React, { useEffect, useState, useRef } from "react";

import Links from "../../data/link";
// import { Link, useParams } from "react-router-dom";

import TitlePage from "../utils/TitlePage";
import Texto from "../../data/es";
import { toast } from "react-toastify";
import Fetch from "../utils/Fetch";
import Iframe from "react-iframe";
import "react-toastify/dist/ReactToastify.css";
import Config from "../../data/config";
import "./css/style.css";

const ConsultaInicio = (props) => {
	const fetch = new Fetch();
	fetch.setToast(toast);
	const titlePage = Texto.liquidaciones_pendientes;

	let params = new URLSearchParams(window.location.search);
	var paramLiquidacion = params.get("liquidacion");
	let codeObjetoValue = null;
	let tipoImpuestoValue = null;
	// =================================================================== //
	const [dataGestiones, setDataGestiones] = useState([]);
	const [dataPublicaciones, setDataPublicaciones] = useState([]);
	// const [dataVertical, setDataVertical] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isFrame, setIsFrame] = useState(false);
	const [urlPdf, setUrlPdf] = useState();
	const [totalDataGestion, setTotalDataGestion] = useState(0);
	const [totalDataPublicacion, setTotalDataPublicacion] = useState(0);
	const [codeObjeto, setCodeObjeto] = useState(null);
	const [tipoImpuesto, setTipoImpuesto] = useState(null);
	const [objetoMinuscula, setObjetoMinuscula] = useState(null);
	const [gestionActual, setGestionActual] = useState(null);
	const [codePublicacion, setCodePublicacion] = useState(null);
	// =================================================================== //
	useEffect(() => {
		loadDataGestion();
		window.scrollTo(0, 0);
	}, []);
	// =================================================== //
	// onclick gestiones con publicaciones
	// =================================================== //
	const loadDataGestion = async () => {
		debugger;
		setLoading(true);
		const responseGestion = await fetch.axiosAsyncGet(
			`api/nt-tributaria/reporte-gestiones/${paramLiquidacion}`
		);

		if (responseGestion && responseGestion.status) {
			setDataGestiones(responseGestion.data.gestiones);
			setTotalDataGestion(responseGestion.data.gestiones.length);
			let gestionInicial = parseInt(responseGestion.data.actual);
			loadDataPublicacion(gestionInicial);

			codeObjetoValue =
				responseGestion.data.objeto != null
					? responseGestion.data.objeto.toUpperCase()
					: null;
			tipoImpuestoValue =
				responseGestion.data.tipo != null
					? responseGestion.data.tipo.toUpperCase()
					: null;

			setGestionActual(gestionInicial);
			setCodeObjeto(codeObjetoValue);
			setTipoImpuesto(tipoImpuestoValue);
			setObjetoMinuscula(codeObjetoValue.toLowerCase());
			setLoading(false);
		}
	};

	// onclick select option (gestiones)
	const onClickBtnSelectGestion = (valueYear) => {
		debugger;

		setGestionActual(valueYear);
		loadDataPublicacion(valueYear);

		if (codePublicacion != null && codeObjetoValue != null) {
			previewPdf(valueYear, codePublicacion, codeObjeto, tipoImpuesto);
		}
	};

	// onclick select option (publicaciones)
	const onClickBtnSelectPublicacion = (valueCodePublicacion) => {
		debugger;
		// selectPublicacionRef.current = valueCodePublicacion;
		setCodePublicacion(valueCodePublicacion);
		

		if (gestionActual > 0 && valueCodePublicacion.length > 1) {
			//loadDataMenuVertical(gestionCurrent, valueCodePublicacion);
			previewPdf(gestionActual, valueCodePublicacion, codeObjeto, tipoImpuesto);
		} else {
			toast.error("Debe seleccionar una publicación  o  gestión válida.", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	};

	// data publication x gestion
	const loadDataPublicacion = async (gestion) => {
		debugger;
		let valueYear = parseInt(gestion);
		// console.log("gestion activa", valueYear, paramLiquidacion);
		if (valueYear > 0) {
			const response = await fetch.axiosAsyncGet(
				`api/nt-tributaria/reporte-publicaciones/${valueYear}/${paramLiquidacion}`
			);

			if (response && response.status) {
				
				setDataPublicaciones(response.data);
				setTotalDataPublicacion(response.data.length);
				var firstRow = response.data.slice(0, 1).shift();
				var keys = Object.keys(firstRow);
				var total = keys.length;

				if (total > 0) {
					let codePubli = firstRow["publi_code"];
					setCodePublicacion(codePubli);
					// loadDataMenuVertical(selectGestionRef.current, codePubli);
					 previewPdf(
					 	valueYear,
					 	codePubli,
					 	codeObjetoValue == null ? codeObjeto : codeObjetoValue,
					 	tipoImpuestoValue == null ? tipoImpuesto : tipoImpuestoValue
					);

					// selectPublicacionRef.current.addEventListener('change', (e) => console.log(e.target.value))
				}
			}
		}
	};

	// preview pdf

	const previewPdf = async (year, codePubli, codeObjetof, tipoImpuestof) => {
		debugger;
		setLoading(true);

		let totalCodePubli = codePubli != null ? codePubli.length : 0;

		if (totalCodePubli > 0) {
			setIsFrame(true);
			setTimeout(
				downloadPdf(year, codeObjetof, tipoImpuestof, codePubli),
				1000
			);
		}
	};

	// ================================================ //
	const downloadPdf = (year, objectTributary, typeImp, codePubli) => {
		let url = `${Config[0].url}api/nt-tributaria/reporte-pdf-masiva/${year}/${objectTributary}/${typeImp}/${codePubli}/preview`;
		setUrlPdf(url);
	};

	function nombreUrltipoImpuesto() {
		let resultado = "";

		switch (tipoImpuesto) {
			case "PUBLICIDAD":
				resultado = "Publicidad Externa";
				break;
			case "SITIOS":
				resultado = "Sitios y Mercados";
				break;
			case "ACTIVIDAD":
				resultado = "Actividad Económica";
				break;
			case "IMPBI":
			case "IMPVAT":
				resultado = "Impuesto Municipal";
				break;
			case "IMTO":
				resultado = "Impuesto Municipal Transferencia";
				break;
			default:
				resultado = "";
		}

		return resultado;
	}

	// const objetoMinuscula = () => {
	// 	return codeObjeto.toLowerCase();
	// };

	// =================================================================== //

	let breadcrumbs = [
		{
			title: Links[0].title,
			url: Links[0].url,
		},

		codeObjeto == "INMUEBLE"
			? {
					title: Links[111].title,
					url: Links[111].url,
			  }
			: codeObjeto == "VEHICULO"
			? {
					title: Links[112].title,
					url: Links[112].url,
			  }
			: {
					title: Links[113].title,
					url: Links[113].url,
			  },

		codeObjeto == "INMUEBLE"
			? {
					title: Links[122].title,
					url: Links[122].url,
			  }
			: codeObjeto == "VEHICULO"
			? {
					title: Links[123].title,
					url: Links[123].url,
			  }
			: {
					title: Links[124].title,
					url: Links[124].url,
			  },

		{
			title: nombreUrltipoImpuesto(),
			url: Links[116].url,
		},
	];

	// =================================================== //
	// return
	// =================================================== //

	return (
		<section className="items-grid section mt-5">
			<div className="container mt-4">
				<TitlePage
					titlePage={titlePage}
					breadcrumbs={breadcrumbs}
					position={"left"}
					leftfull={false}
				/>

				{dataGestiones.length > 0 ? (
					<section>
						<div className="row align-content-center align-items-center justify-content-center mb-2">
							<div className="col-lg-5 col-md-8 col-xs-12">
								<div className="text-center">
									<small className="form-text text-muted">
										Gestiones y Publicaciones
									</small>
								</div>
								<div className="input-group">
									<select
										defaultValue={gestionActual}
										disabled={loading}
										className="form-select form-select-sm"
										name="search[gestion]"
										id="search_gestion"
										required
										data-parsley-required="true"
										onChange={(event) =>
											onClickBtnSelectGestion(event.target.value)
										}
									>
										{dataGestiones.map((row) => (
											<option
												key={row.gestion_nombre}
												value={row.gestion_nombre}
											>
												{row.gestion_nombre}
											</option>
										))}
									</select>

									<select
										defaultValue={codePublicacion}
										disabled={loading}
										className="form-select form-select-sm"
										name="search[publicacion]"
										id="search_publicacion"
										required
										data-parsley-required="true"
										onChange={(event) =>
											onClickBtnSelectPublicacion(event.target.value)
										}
									>										
										{dataPublicaciones.map((row) => (
											<option
												key={row.publi_code}
												value={row.publi_code}
												selected={
													row.publi_code === codePublicacion ? true : false
												}
											>
												{row.publi_nombre}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>

						{gestionActual >= 2023  && !loading ? (
							<div className="row align-content-center align-items-center justify-content-center">
								<div className="col-xs-12 col-lg-9 col-md-9 mb-2">
									<a
										href={`${Links[119].url}/${objetoMinuscula}`}
										className="btn btn-outline-primary float-lg-end"
									>
										<i className="fa fa-search"></i> Búsqueda Personalizada
									</a>
								</div>
							</div>
						) : (
							""
						)}
					</section>
				) : (
					""
				)}

				{totalDataGestion > 0 && totalDataPublicacion > 0 ? (
					<div>
						<div
							className={
								loading ? "row mt-3 text-center" : "row mt-3 d-none text-center"
							}
						>
							<div className="col-12 justify-content-center align-content-center align-items-center">
								<img
									className="rounded mx-auto d-block"
									alt="pulse-loading"
									src={"/static/img/pulse_200.gif"}
									width={50}
								/>
								<small>Cargando los registros para generar el PDF. </small>
							</div>
						</div>
						{isFrame ? (
							<div className="d-flex flex-row vh-100 justify-content-center align-content-center align-items-center">
								<div className="col-md-9 col-lg-9 col-xs-12 border border-secondary">
									<div className="w-100">
										<Iframe
											id="iframePreviewMasivaPdf"
											src={urlPdf != null ? urlPdf + "#zoom=95" : ""}
											frameBorder="0"
											allow="allow-same-origin allow-scripts allow-popups allow-forms"
											allowFullScreen
											display="initial"
											className="embed-responsive-item vh-100"
											loading="pulse-loading"
											onLoad={() => setLoading(false)}
											
										></Iframe>
									</div>
								</div>
							</div>
						) : (
							""
						)}
					</div>
				) : (
					<div className="alert alert-warning">
						<strong>¡Mensaje!</strong>
						<p>
							No existen publicaciones de Liquidación por Determinación Mixta.
						</p>
					</div>
				)}
			</div>
		</section>
	);
};

export default ConsultaInicio;
