import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Texto from "../../../data/es";
import { toast } from "react-toastify";

import Temporizador from "./Temporizador";

const RegisterPhoneCodeFormulario = ({
	onSubmit,
	setTitlePage,
	setDescription,
	dataFormUser,
	fetchInit,
	urlImageLoading,
	setCurrentStep
}) => {
	const idForm = "form-phone-code";

	const fetch = fetchInit;
	const [error, setError] = useState("");
	const [codePhone, setCodePhone] = useState("");
	const [loading, setLoading] = useState(false);
	const [tiempoRestante, setTiempoRestante] = useState(600); // 10 minutos en segundos
	const [bloqueado, setBloqueado] = useState(false);
	const [minutoTemporizador, setMinutoTemporizador] = useState(60);

	useEffect(() => {
		// Evita actualizar el estado si el componente se desmonta
		let isMounted = true;

		return () => {
			isMounted = false;
		};
	}, [dataFormUser]);

	useEffect(() => {
		if (dataFormUser && dataFormUser.phone > 0) {
			handleVerificarRegistroConsecutivo(dataFormUser.phone);
		}
	}, []);

	// efect para tiempo restante
	useEffect(() => {
		let interval;

		if (bloqueado && tiempoRestante > 0) {
			// Configurar un intervalo que disminuye el tiempo restante cada segundo
			interval = setInterval(() => {
				setTiempoRestante((prevTiempo) => prevTiempo - 1);
			}, 1000);
		} else if (tiempoRestante === 0) {
			setBloqueado(false); // Desbloquear el botón cuando el tiempo se agota
		}

		// Limpiar el intervalo al desmontar el componente o si cambia el estado
		return () => clearInterval(interval);
	}, [bloqueado, tiempoRestante]);


	// btn volver
	const handleEventClickVolver = (e) => {
		if (!bloqueado) {
			setTitlePage(Texto.register_user_title_init);
			setDescription(Texto.register_user_description_init);
			setCurrentStep(1)
		}
	};

	// submit
	const handleFormSubmit = (e) => {
		setMinutoTemporizador(60);
		e.preventDefault();
		window.jQuery("#" + idForm).parsley().validate();
		setLoading(false);
		setError('');
		if (window.jQuery("#" + idForm).parsley().isValid()) {
			handleVerificarCodigo();
		}
	};

	//
	const handleVerificarCodigo = async () => {
		setLoading(true);
		try {
			if (dataFormUser?.phone) {
				const form = new FormData();
				form.append("registro[celular]", dataFormUser.phone);
				form.append("registro[codigo]", codePhone);
				const dataJson = await fetch.fetchPost(form, "api/usuario/registro/verificar-codigo");
				if (dataJson && dataJson.status) {
					fetch.toast.success(dataJson.message);
					onSubmit(codePhone);
				}
			}
		} catch (error) {
			toast.error("Error al realizar la verificación del número de celular. Vuelva a intentar.");
		}
		setLoading(false);

	}


	// onchange code phone
	const onchangeCodePhone = (e) => {
		setError('');
		setCodePhone(e.target.value)
	}

	//verificar registro consecutivo
	const handleVerificarRegistroConsecutivo = async (numeroCelular) => {
		debugger

		setBloqueado(false);
		if (numeroCelular > 0) {
			const form = new FormData();
			form.append("registro[celular]", numeroCelular);

			const dataJson = await fetch.fetchPost(form, "api/usuario/registro/consecutivo-celular");
			if (dataJson && dataJson.status) {
				debugger
				// fetch.toast.success(dataJson.message);
				setBloqueado(true);
				setTiempoRestante(600); // Reinicia el tiempo a 10 minutos (600 segundos)
				setMinutoTemporizador(5);
			}
		}
	}


	// Convertimos el tiempo restante a minutos y segundos
	const minutos = Math.floor(tiempoRestante / 60);
	const segundos = tiempoRestante % 60;

	/* ============================================================ */
	return (
		<form
			onSubmit={handleFormSubmit}
			noValidate
			name={idForm}
			id={idForm}
			method="post"
		>
			{loading && (<div className="col-12 mb-1 mx-auto d-block text-center">
				<img
					className="rounded"
					alt="pulse-loading"
					src={urlImageLoading}
					width="40"
				/>{" "}
				<span className="ml-5 text-violeta">Procesando</span>
			</div>)}

			{/* {bloqueado ? `Bloqueado por ${minutos}:${segundos < 10 ? `0${segundos}` : segundos}` : ""} */}

			<div className="form-group">
				<label htmlFor="codecelular">(*) Código de Verificación:</label>
				<input
					type="number"
					id="codecelular"
					name="codecelular"
					className={error ? 'form-control form-control-lg parsley-error' : 'form-control form-control-lg'}
					value={codePhone}
					onChange={(e) => onchangeCodePhone(e)}
					required
					data-parsley-required-message="El código de verificación es requerido"
					data-parsley-type="number"
					data-parsley-number-message="Ingrese solo ńumeros"
					data-parsley-pattern="^\d{5}$"
					data-parsley-pattern-message="El número debe tener 5 dígitos."
				/>
			</div>

			{error !== '' ? (
				<div className="parsley-errors-list filled">
					{error}
				</div>
			) : ''}

			{/* solicitar otro codigo */}

			{!bloqueado && dataFormUser?.phone > 0 &&
				<div className="text-justify col-lg-12 col-md-12 col-sm-12 mx-auto border-radius-035 bg-gray-5 mt-3 p-3">
					<Temporizador totalSegundos={minutoTemporizador} Texto={Texto} celular={dataFormUser.phone} fetch={fetch} toast={toast} handleVerificarRegistroConsecutivo={handleVerificarRegistroConsecutivo} />
				</div>
			}
			{bloqueado &&
				<div className="alert alert-danger mt-3" role="alert">
					<p>Intente nuevamente en <b>10</b> minutos, para acceder al código de verificación de número de celular.</p>
				</div>
			}

			<div className="d-flex d-sm-flex flex-sm-row justify-content-between mt-3">
				<div>
					<button
						type="button"
						className="btn btn-fucsia"
						onClick={handleEventClickVolver}
					>
						Volver
					</button>
				</div>
				<div>
					<button type="submit" className="btn btn-violeta">
						Continuar
					</button>
				</div>
			</div>
		</form>
	);
};

RegisterPhoneCodeFormulario.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	setTitlePage: PropTypes.func.isRequired,
	setDescription: PropTypes.func.isRequired,
	urlImageLoading: PropTypes.string.isRequired
};

export default RegisterPhoneCodeFormulario;
