import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useHistory } from "react-router-dom";
import FormAdministrativosLegales from "./FormAdministrativosLegales";
import FormMinuta from "./FormMinuta";
import FormSujetoPasivo from "./FormSujetoPasivo";
import FormComprador from "./FormComprador";
import FormVistaPrevia from "./FormVistaPrevia";

import Fetch from "../../components/utils/Fetch";
import AuthService from "../../components/Usuario/AuthService";

import Links from "../../data/link";
import TitlePage from "../../components/utils/TitlePage";
import Texto from "../../data/es";
import {
  formTerreno,
  formServiciosBasicos,
  formInmueble,
  formDireccion,
  formCompradores,
} from "./FormData";


const Edit = (props) => {
  const { form, token, numero } = useParams();

  const fetch = new Fetch();
  const auth = new AuthService();
  fetch.setToast(toast);
  const history = useHistory();

  const [visacionMinuta, setVisacionMinuta] = useState({});
  const [dataSeller, setDataSeller] = useState({});
  const [dataMinuta, setDataMinuta] = useState({});
  const [dataBuyed, setDataBuyed] = useState({});
  const [dataInmueble, setDataInmueble] = useState({});
  const [showAdministrativos, setShowAdministrativos] = useState(false);
  const [showSeller, setShowSeller] = useState(false);
  const [showBuyeds, setShowBuyeds] = useState(false);
  const [showMinuta, setShowMinuta] = useState(false);
  const [showDeclaracionJurada, setShowDeclaracionJurada] = useState(false);
  const [searchData, setSearchData] = useState(false);

  const callBack = window.getParameterByName("call");

  if (!auth.loggedIn()) history.replace(Links[4].url);

  useEffect(() => {    
    let numeroOrden = "";
    if (numero.includes("&")) {
      const arrayNumero = numero.split("&");
      numeroOrden = arrayNumero[0];
    } else numeroOrden = numero;
    loadDataVisacionMinutaInmueble(form, token, numeroOrden);
    loadDataSellers(form, token, numeroOrden);
    loadDataBuyed(form, token, numeroOrden);
    loadDataMinuta(form, token, numeroOrden);
    loadPreview(form, token, numeroOrden);
  }, []);

  const loadDataVisacionMinutaInmueble = async (form, token, numero) => {
    debugger;
    if (form === "inm") {
      setSearchData(true);
      await reloadDataContruccionesAndColindancias(token);
      const response = await fetch.fetchGet(
        `api/visacion-minutas/by-token-and-numero/${token}/${numero}`
      );
      if (response && response.status) {
        response.data.ruatLocal.tipoVisacionMinuta = response.data.TipoVisacionMinuta;
        setDataInmueble(response.data.ruatLocal);
        setVisacionMinuta(response.data.VisacionMinuta);
        setShowAdministrativos(true);
        setShowSeller(false);
        setShowBuyeds(false);
        setShowMinuta(false);
        setShowDeclaracionJurada(false);
        setSearchData(false);
      }
    }
  };

  const reloadDataContruccionesAndColindancias = async (token) => {
    const form = new FormData();
    form.append("vm_visacion_minuta[token]", token);
    await fetch.fetchPost(
      form,
      "api/visacion-minutas/reload-data-ruat-to-local",
      undefined
    );
  };

  const loadDataSellers = async (form, token, numero) => {
    debugger;
    if (form === "sellers") {
      setSearchData(true);
      await reloadDataPropietario(token);
      const response = await fetch.fetchGet(
        `api/visacion-minutas/by-token-and-numero/${token}/${numero}`
      );
      if (response && response.status) {
        setDataInmueble(response.data.ruatLocal);
        setVisacionMinuta(response.data.VisacionMinuta);
        setDataSeller(response.data.ruatLocal.propietario);
        setShowAdministrativos(false);
        setShowSeller(true);
        setShowBuyeds(false);
        setShowMinuta(false);
        setShowDeclaracionJurada(false);
        setSearchData(false);
      }
    }
  };

  const reloadDataPropietario = async (token) => {
    const form = new FormData();
    form.append("vm_visacion_minuta[token]", token);
    await fetch.fetchPost(
      form,
      "api/visacion-minutas/titular-propiedad/reload-data-ruat-to-local",
      undefined
    );
  };

  const loadDataMinuta = async (form, token, numero) => {
    if (form === "minuta") {
      setSearchData(true);
      const response = await fetch.fetchGet(
        `api/visacion-minutas/documentos-transferencia/by-token-and-numero/${token}/${numero}`
      );
      if (response && response.status) {
        const dataMinuta = response.data;
        const inmueble = {
          numeroInmueble: dataMinuta.Inmueble.numero_ruat,
          tipoVisacionMinuta: {
            showAmount: window.stringToBoolean(
              dataMinuta.TipoVisacionMinuta.show_amount
            ),
            code: dataMinuta.TipoVisacionMinuta.code,
            active: dataMinuta.TipoVisacionMinuta.active,
            name: dataMinuta.TipoVisacionMinuta.name,
          },
        };
        setDataInmueble(inmueble);
        setDataMinuta(dataMinuta.Minuta);
        setVisacionMinuta(dataMinuta.VisacionMinuta);
        setShowAdministrativos(false);
        setShowSeller(false);
        setShowBuyeds(false);
        setShowMinuta(true);
        setShowDeclaracionJurada(false);
        setSearchData(false);
      }
    }
  };

  const loadDataBuyed = async (form, token, numero) => {
    if (form === "buyeds") {
      setSearchData(true);
      const response = await fetch.fetchGet(
        `api/visacion-minutas/buyeds/by-token-and-numero/${token}/${numero}`
      );
      debugger;
      if (response && response.status) {
        const dataBuyed = response.data;
        const inmueble = {
          numeroInmueble: dataBuyed.Inmueble.numero_ruat,
          tipoVisacionMinuta: {
            showAmount: dataBuyed.TipoVisacionMinuta.show_amount,
            code: dataBuyed.TipoVisacionMinuta.code,
            active: dataBuyed.TipoVisacionMinuta.active,
            name: dataBuyed.TipoVisacionMinuta.name,
          },
        };
        setDataInmueble(inmueble);
        setVisacionMinuta(response.data.VisacionMinuta);
        setDataBuyed(dataBuyed.Comprador);
        setShowAdministrativos(false);
        setShowSeller(false);
        setShowBuyeds(true);
        setShowMinuta(false);
        setShowDeclaracionJurada(false);
        setSearchData(false);
      }
    }
  };

  const loadPreview = async (form, token, numero) => {
    if (form === "preview") {
      setSearchData(true);
      const response = await fetch.fetchGet(
        `api/visacion-minutas/by-token-and-numero/${token}/${numero}`
      );
      debugger;
      if (response && response.status) {
        const data = response.data;
        const inmuebleDb = data.Inmueble;
        const visacionMinutaDb = data.VisacionMinuta;
        const propiedadDb = data.Propiedad;

        const inmueble = {
          numeroInmueble: inmuebleDb.numero_ruat,
          codigoPropiedad: propiedadDb.codigo_propiedad,
        };

        const visacionMinuta = {
          token: visacionMinutaDb.token,
          numeroOrden: visacionMinutaDb.numero,
        };

        setDataInmueble(inmueble);
        setVisacionMinuta(visacionMinuta);
        setShowAdministrativos(false);
        setShowSeller(false);
        setShowBuyeds(false);
        setShowMinuta(false);
        setShowDeclaracionJurada(true);
        setSearchData(false);
      }
    }
  };

  const handleSubmitEditForm = (event, data) => {
    event.preventDefault();
    window
      .jQuery("#" + event.target.getAttribute("id"))
      .parsley()
      .validate();

    let formHtml = event.target; //event.target.getAttribute('id');
    const form = new FormData(event.target);
    debugger;
    if (
      window
        .jQuery("#" + formHtml.getAttribute("id"))
        .parsley()
        .isValid()
    ) {
      window.createBootbox("Esta Seguro de Continuar.", function (result) {
        if (result === true) {
          switch (formHtml.getAttribute("id")) {
            case "formDataInmueble": //primer formulario
              submitFormDataInmueble(form, event.target, data);
              break;
            case "formDataSell": //segundo formulario
              submitFormToSell(form, event.target, data);
              break;
            case "formDataBuy": //segundo formulario
              submitFormBuy(form, event.target, data);
              break;
            case "formDataMinuta": //tercer formulario
              submitFormDataMinuta(form, event.target, data);
              break;
            default:
              //self.submitFormDomicilioActEco(form, self, target)
              break;
          }
        }
      });
    } else {
      toast.warn("El formulario tiene valores obligatorios", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const submitFormDataInmueble = async (form, target, data) => {
    debugger;
    form.append("vm_visacion_minutas[token]", visacionMinuta.token);
    formInmueble(form, data);
    formDireccion(form, data);
    formTerreno(form, data);
    formServiciosBasicos(form, data);

    const response = await fetch.fetchPost(
      form,
      "api/visacion-minutas/update",
      target
    );
    if (response && response.status) {
      if (
        callBack &&
        callBack === "pvm" &&
        visacionMinuta.token &&
        visacionMinuta.numero
      )
        window.redirect(
          Links[91].url +
            "/preview/" +
            visacionMinuta.token +
            "/" +
            visacionMinuta.numero
        );
      else window.redirect(Links[88].url);

      toast.success(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const submitFormToSell = async (form, target, data) => {
    debugger
    if (
      callBack &&
      callBack === "pvm" &&
      visacionMinuta.token &&
      visacionMinuta.numero
    )
      window.redirect(
        Links[91].url +
          "/preview/" +
          visacionMinuta.token +
          "/" +
          visacionMinuta.numero
      );
    else window.redirect(Links[88].url);
  };

  const submitFormBuy = async (form, target, data) => {
    debugger;
    form.append("vm_visacion_minutas[token]", visacionMinuta.token);

    formCompradores(form, data);
    
    const compradoresRes = await fetch.fetchPost(
      form,
      "api/visacion-minutas/compradores/update",
      target
    );
    if (compradoresRes && compradoresRes.status) {
      if (
        callBack &&
        callBack === "pvm" &&
        visacionMinuta.token &&
        visacionMinuta.numero
      )
        window.redirect(
          Links[91].url +
            "/preview/" +
            visacionMinuta.token +
            "/" +
            visacionMinuta.numero
        );
      else window.redirect(Links[88].url);
      toast.success(compradoresRes.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const submitFormDataMinuta = async (form, target, data) => {
    debugger;
    form.append("vm_visacion_minutas[token]", visacionMinuta.token);
    //Number.isN(data.dataMinuta.amount) ? data.dataMinuta.amount : data.dataMinuta.amount.replace(',', '.')
    form.append(
      "vm_documentos_transferencia[amount]",
      data.dataMinuta.amount ? data.dataMinuta.amount.replace(",", ".") : 0
    );
    form.append("vm_documentos_transferencia[divisa]", data.dataMinuta.divisa);
    form.append("vm_documentos_transferencia[date]", data.dataMinuta.date);
    form.append("vm_documentos_transferencia[id_documentos_minuta]", data.dataMinuta.documentoMinuta);
    form.append("vm_documentos_transferencia[numero]", data.dataMinuta.numero);
    form.append("vm_documentos_transferencia[notario]", data.dataMinuta.notario);
    form.append("vm_documentos_transferencia[numero_notario]", data.dataMinuta.numeroNotario);

    const response = await fetch.fetchPost(
      form,
      "api/visacion-minutas/documentos-transferencia/update",
      target
    );
    if (response && response.status) {
      if (
        callBack &&
        callBack === "pvm" &&
        visacionMinuta.token &&
        visacionMinuta.numero
      )
        window.redirect(
          Links[91].url +
            "/preview/" +
            visacionMinuta.token +
            "/" +
            visacionMinuta.numero
        );
      else window.redirect(Links[88].url);
      toast.success(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const breadcrumbs = [
    {
      title: Links[0].title,
      url: Links[0].url,
    },
    {
      title: Links[88].title,
      url: Links[88].url,
    },
    {
      title: "Edición",
      url: "#",
    },
  ];

  return (
    <section className="items-grid section mt-5 mb-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={Texto.visacion_minutas}
          breadcrumbs={breadcrumbs}
          position={"left"}
        />

        {searchData ? (
          <div className="row">
            <div className="col-12 mb-1">
              <img
                className="rounded mx-auto d-block"
                alt="pulse-loading"
                src={"/static/img/pulse_200.gif"}
                width="70"
              />
            </div>
          </div>
        ) : (
          ""
        )}

        {showAdministrativos ? (
          <FormAdministrativosLegales
            dataInmueble={dataInmueble}
            visacionMinutaDb={visacionMinuta}
            fetch={fetch}
            onSubmitForm={handleSubmitEditForm}
          />
        ) : (
          ""
        )}

        {showSeller ? (
          <FormSujetoPasivo
            dataInmueble={dataInmueble}
            dataPropietario={dataSeller}
            fetch={fetch}
            onSubmitForm={handleSubmitEditForm}
          />
        ) : (
          ""
        )}

        {showBuyeds ? (
          <FormComprador
            dataInmueble={dataInmueble}
            dataCompradorDb={dataBuyed}
            nameForm={"persona"}
            fetch={fetch}
            onSubmitForm={handleSubmitEditForm}
          />
        ) : (
          ""
        )}

        {showMinuta ? (
          <FormMinuta
            dataInmueble={dataInmueble}
            dataPropietario={dataInmueble.propietario}
            dataMinutaDb={dataMinuta}
            nameForm={"persona"}
            fetch={fetch}
            onSubmitForm={handleSubmitEditForm}
          />
        ) : (
          ""
        )}

        {showDeclaracionJurada ? (
          <FormVistaPrevia
            dataInmueble={dataInmueble}
            dataPropietario={dataInmueble.propietario}
            visacionMinuta={visacionMinuta}
            fetch={fetch}
          />
        ) : (
          ""
        )}
      </div>

      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </section>
  );
};

export default Edit;
