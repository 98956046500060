import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fetch from "../../components/utils/Fetch";
import Carroucel from "../../data/Carroucel";

class HeaderCarroucel extends Component {
  constructor(props) {
    super(props);
    this.fetch = new Fetch();
    this.fetch.setToast(toast);
    this.list_carroucel = [];

    this.handleRedirectOpenPage = this.handleRedirectOpenPage.bind(this);

    this.state = {
      tipoCambioTazaInteres: 0,
      renderCarrousel: false,
      showMobile: false,
    };
    this.currentDate = new Date();
    this.fechaLiteral = window.fechaToLiteral(this.currentDate);

    this.fechaLiteralArray = this.fechaLiteral.split(" ");
    this.fechaLiteralString =
      this.fechaLiteralArray[0] +
      " " +
      this.fechaLiteralArray[1] +
      " " +
      this.fechaLiteralArray[2];
    this.dayLiteralString = window.dayToLiteral(this.currentDate);
  }

  async componentDidMount() {
    const cotizaciones = await this.fetch.axiosAsyncGet(
      `api/cobros/cotizaciones`
    );
    if (cotizaciones && cotizaciones.status) {
      if (Boolean(cotizaciones.Ufv)) {
        this.setState({ tipoCambioTazaInteres: cotizaciones.Ufv.cotizacion });
      }
    }

    if (this.list_carroucel.length <= 0) {
      this.list_carroucel = Carroucel.map((item) => {
        return (
          <div className={"carousel-item " + item.class_active} key={item.key}>
            <a
              href={item.href}
              title={item.title}
              className="item-title w-100"
              target={item.target}
            >
              <img
                className="img-fluid full-image"
                src={item.src}
                alt={item.alt}
                loading="eager"
                decoding="async"
                width="1600"
                height="600"
              />
            </a>
          </div>
        );
      });
      this.setState({ renderCarrousel: true });
    }

    if (window.outerWidth <= 600) {
      this.setState({ showMobile: true });
    }
  }

  handleRedirectOpenPage(event, url) {
    event.preventDefault();
    window.open(url, "_blank");
  }

  render = () => {
    const blockUFV = (
      <>
        <div className="cn-title">
          <div className="calendar mt-1">
            <i className="far fa-calendar-alt"></i>
          </div>
          <div className="ms-1">
            <div>
              <span className="text-date text-white text-uppercase">
                {this.dayLiteralString + " " + this.fechaLiteralString}
              </span>
            </div>
            <div>
              <span
                className="text-date text-white"
                style={{ fontSize: "1.3em" }}
              >
                {this.currentDate.getFullYear()}
              </span>
            </div>
          </div>
        </div>

        <div className="cn-title pt-3" style={{ borderTop: "2px solid #f18721" }}>
          <div className="calendar">
            <i className="fas fa-dollar-sign"></i>
          </div>
          <div className="ms-1" style={{ marginTop: -5 }}>
            <div>
              <span className="text-date text-white text-uppercase">UFV</span>
            </div>
            <div>
              <span
                className="text-date text-white"
                style={{ fontSize: "1em", paddingLeft: 3 }}
              >
                {this.state.tipoCambioTazaInteres}
              </span>
            </div>
          </div>
        </div>
      </>
    );
    /*
    const acordion = (
      <div className="cn-body">
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingCollapseOne">
              <button
                className="accordion-button collapsed accordion-custom-button text-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                <div className="avatar-btn">
                  <img
                    src={Config[2].url + "/static/img/offcanvas/atm_white.png"}
                    alt="atm icon blanco"
                    width="18"
                    height="18"
                  />
                </div>
                Resolución Técnica Administrativa
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingCollapseOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body accordion-body-custom ">
                Resolución Técnica Administrativa Nº 0001/2023,
                <small style={{ fontSize: ".9em" }}> 06-01-23 10:26:47</small>
                ...{" "}
                <a
                  href={"#"}
                  className=""
                  onClick={(e) =>
                    this.handleRedirectOpenPage(
                      e,
                      "https://atmapi.cochabamba.bo/restServerAtm/documents/preview/rta-0001-2023"
                    )
                  }
                  rel="noreferrer noopener"
                >
                  Continuar Leyendo
                </a>{" "}
                <br />
                Resolución Técnica Administrativa Nº 0097/2023,
                <small style={{ fontSize: ".9em" }}> 17-01-23 11:26:47</small>
                ...{" "}
                <a
                  href={"#"}
                  className=""
                  onClick={(e) =>
                    this.handleRedirectOpenPage(
                      e,
                      "https://atmapi.cochabamba.bo/restServerAtm/documents/preview/rta-0097-2023"
                    )
                  }
                  rel="noreferrer noopener"
                >
                  Continuar Leyendo
                </a>{" "}
                <br />
                Resolución Técnica Administrativa Nº 1406/2022,
                <small style={{ fontSize: ".9em" }}> 17-06-22 15:50:47</small>
                ...{" "}
                <a
                  href={"#"}
                  className=""
                  onClick={(e) =>
                    this.handleRedirectOpenPage(
                      e,
                      "https://atmapi.cochabamba.bo/restServerAtm/documents/preview/rta-1406-2022"
                    )
                  }
                  rel="noreferrer noopener"
                >
                  Continuar Leyendo
                </a>{" "}
                <br />
                Resolución Técnica Administrativa Nº 2302/2022,
                <small style={{ fontSize: ".9em" }}> 05-10-22 19:49:5</small>
                ...{" "}
                <a
                  href={"#"}
                  className=""
                  onClick={(e) =>
                    this.handleRedirectOpenPage(
                      e,
                      "https://atmapi.cochabamba.bo/restServerAtm/documents/preview/rta-2302-2022"
                    )
                  }
                  rel="noreferrer noopener"
                >
                  Continuar Leyendo
                </a>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingAHEThree">
              <button
                className="accordion-button collapsed accordion-custom-button text-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseAThree"
                aria-expanded="false"
                aria-controls="flush-collapseAThree"
              >
                <div className="avatar-btn">
                  <img
                    src={Config[2].url + "/static/img/offcanvas/atm_white.png"}
                    alt="atm icon blanco"
                    width="18"
                    height="18"
                  />
                </div>
                Ampliación Horas Extraordinarias
              </button>
            </h2>
            <div
              id="flush-collapseAThree"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingAHEThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body accordion-body-custom ">
                Resolución Técnica Administrativa Nº 2185/2021,
                <small style={{ fontSize: ".9em" }}> 19-08-21 14:26:06</small>
                ...{" "}
                <a
                  href={"#"}
                  className=""
                  onClick={(e) =>
                    this.handleRedirectOpenPage(
                      e,
                      "https://atmapi.cochabamba.bo/restServerAtm/legal-tributario/document/48"
                    )
                  }
                  rel="noreferrer noopener"
                >
                  Continuar Leyendo
                </a>
                <br />
                Resolución Técnica Administrativa Nº 878/2022,
                <small style={{ fontSize: ".9em" }}> 30-03-22 09:13:58</small>
                ...{" "}
                <a
                  href={"#"}
                  className=""
                  onClick={(e) =>
                    this.handleRedirectOpenPage(
                      e,
                      "https://atmapi.cochabamba.bo/restServerAtm/documents/preview/rta-878-2022"
                    )
                  }
                  rel="noreferrer noopener"
                >
                  Continuar Leyendo
                </a>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingSTFour">
              <button
                className="accordion-button collapsed accordion-custom-button text-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseAFour"
                aria-expanded="false"
                aria-controls="flush-collapseAFour"
              >
                <div className="avatar-btn">
                  <img
                    src={Config[2].url + "/static/img/offcanvas/atm_white.png"}
                    alt="atm icon blanco"
                    width="18"
                    height="18"
                  />
                </div>
                Simplificación de Trámites
              </button>
            </h2>
            <div
              id="flush-collapseAFour"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingSTFour"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body accordion-body-custom ">
                Resolución Técnica Administrativa Nº 3173/2021...{" "}
                <a
                  href={"#"}
                  className=""
                  onClick={(e) =>
                    this.handleRedirectOpenPage(
                      e,
                      "https://atmapi.cochabamba.bo/restServerAtm/documents/preview/rta-3173-2021"
                    )
                  }
                  rel="noreferrer noopener"
                >
                  Continuar Leyendo
                </a>
                Resolución Técnica Administrativa Nº 2801/2021...{" "}
                <a
                  href={"#"}
                  className=""
                  onClick={(e) =>
                    this.handleRedirectOpenPage(
                      e,
                      "https://atmapi.cochabamba.bo/restServerAtm/documents/preview/rta-2801-2021"
                    )
                  }
                  rel="noreferrer noopener"
                >
                  Continuar Leyendo
                </a>
                <br />
                D.M. Nº 272/2021, aprueba reglamento L.M. 1063/2021...{" "}
                <a
                  href={"#"}
                  className=""
                  onClick={(e) =>
                    this.handleRedirectOpenPage(
                      e,
                      "https://atmapi.cochabamba.bo/restServerAtm/documents/preview/dm-272-2021-aprueba-ley-munipal-1063-2021"
                    )
                  }
                  rel="noreferrer noopener"
                >
                  Continuar Leyendo
                </a>
                <br />
                Ley Municipal Nº 1063/2021...{" "}
                <a
                  href={"#"}
                  className=""
                  onClick={(e) =>
                    this.handleRedirectOpenPage(
                      e,
                      "https://atmapi.cochabamba.bo/restServerAtm/documents/preview/lm-1063-2021"
                    )
                  }
                  rel="noreferrer noopener"
                >
                  Continuar Leyendo
                </a>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingDPSFive">
              <button
                className="accordion-button collapsed accordion-custom-button text-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseAFive"
                aria-expanded="false"
                aria-controls="flush-collapseAFive"
              >
                <div className="avatar-btn">
                  <img
                    src={Config[2].url + "/static/img/offcanvas/atm_white.png"}
                    alt="atm icon blanco"
                    width="18"
                    height="18"
                  />
                </div>
                Dación en Pago
              </button>
            </h2>
            <div
              id="flush-collapseAFive"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingDPSFive"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body accordion-body-custom">
                <div className="button  text-center">
                  Ley Municipal de Reactivación Tributaria Municipal...{" "}
                  <a
                    href={"#"}
                    className=""
                    onClick={(e) =>
                      this.handleRedirectOpenPage(
                        e,
                        "https://atmapi.cochabamba.bo/restServerAtm/legal-tributario/document/49"
                      )
                    }
                    rel="noreferrer noopener"
                  >
                    Continuar Leyendo
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingPOSix">
              <button
                className="accordion-button collapsed accordion-custom-button text-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseASix"
                aria-expanded="false"
                aria-controls="flush-collapseASix"
              >
                <div className="avatar-btn">
                  <img
                    src={Config[2].url + "/static/img/offcanvas/atm_white.png"}
                    alt="atm icon blanco"
                    width="18"
                    height="18"
                  />
                </div>
                Pagos Online
              </button>
            </h2>
            <div
              id="flush-collapseASix"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingPOSix"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body accordion-body-custom">
                <div className="button  text-center">
                  Resolución Técnica Administrativa Nro. 2881/2021...{" "}
                  <a
                    href={"#"}
                    className=""
                    onClick={(e) =>
                      this.handleRedirectOpenPage(
                        e,
                        "https://atmapi.cochabamba.bo/restServerAtm/documents/preview/rta-2881-2021"
                      )
                    }
                    rel="noreferrer noopener"
                  >
                    Continuar Leyendo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
*/
    return (
      <section className="hero-area overlay">
        <div className="container-fluid">
          <div className="row  ">
            <div className="col-md-12 col-12 padding-left-0 padding-right-0">
              <div className="hero-text text-center">
                {this.state.renderCarrousel ? (
                  <div
                    id="carouselSimatTop"
                    className="carousel slide carousel-fade"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">{this.list_carroucel}</div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselSimatTop"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselSimatTop"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                ) : (
                  ""
                )}

                <div className="col-md-12 col-12 grid-noti">
                  <div className="container-noti">{blockUFV}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
}

export default HeaderCarroucel;