import React, { Component } from "react";

import Fetch from "../utils/Fetch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CharJs from "chart.js";

var _fetch = undefined;
var chartData = {};
var chartOptions = {};
var chart = undefined;
var anio_base = 2019;
var chartCanvas = undefined;
class GraficaLineal extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleAnioOnChange = this.handleAnioOnChange.bind(this);

    this.state = {
      titulo_grafica: "",
    };

    _fetch = this.props.fetch;

    let anios = [];
    for (let i = anio_base; i <= new Date().getFullYear(); i++) {
      anios[i] = anio_base + i;
    }

    this.listAnios = anios.map((item, index) => {
      return (
        <option key={index} value={index}>
          {index}
        </option>
      );
    });

    chartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
    };
  }

  async componentDidMount() {
    chartCanvas = window.jQuery("#salesChart").get(0).getContext("2d");
    await this.paintChart(anio_base);
  }

  async handleAnioOnChange(event) {
    event.preventDefault();
    chart.destroy();

    await this.paintChart(event.target.selectedOptions[0].value);
  }

  async paintChart(anio) {
    const response = await _fetch.axiosAsyncGet(
      `api/licencia-actividad-economica/get-all-by-month-year/${anio}`
    ); //${anio_base}
    let labels = [];
    let data = [];
    if (
      response !== null &&
      response.status === true &&
      response.DeclaracionJurada.length > 0
    ) {
      for (let i = 0; i < response.DeclaracionJurada.length; i++) {
        labels[i] = response.DeclaracionJurada[i].mes;
      }
      for (let i = 0; i < response.DeclaracionJurada.length; i++) {
        data[i] = response.DeclaracionJurada[i].total;
      }
      this.setState({
        titulo_grafica:
          labels[0] + " - " + labels[labels.length - 1] + ", " + anio,
      });
    } else {
      labels = [
        "ENERO",
        "FEBRERO",
        "MARZO",
        "ABRIL",
        "MAYO",
        "JUNIO",
        "JULIO",
        "AGOSTO",
        "SEPTIEMBRE",
        "OCTUBRE",
        "NOVIEMBRE",
        "DICIEMBRE",
      ];
      this.setState({
        titulo_grafica: "ENERO" + " - " + "DICIEMBRE" + ", " + anio,
      });
    }

    chartData = {
      labels: labels,
      datasets: [
        {
          label: "Licencias de Actividades Economicas",
          backgroundColor: "rgba(60,141,188,0.9)",
          borderColor: "rgba(60,141,188,0.8)",
          pointRadius: false,
          pointColor: "#3b8bba",
          pointStrokeColor: "rgba(60,141,188,1)",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "rgba(60,141,188,1)",
          data: data,
        },
        /*{
                    label: 'Licencias de Actividades Economicas',
                    backgroundColor: 'rgba(210, 214, 222, 1)',
                    borderColor: 'rgba(210, 214, 222, 1)',
                    pointRadius: false,
                    pointColor: 'rgba(210, 214, 222, 1)',
                    pointStrokeColor: '#c1c7d1',
                    pointHighlightFill: '#fff',
                    pointHighlightStroke: 'rgba(220,220,220,1)',
                    data: [65, 59, 80, 81, 56, 55, 40, 50, 60, 40, 80, 90]
                },*/
      ],
    };

    chart = new CharJs.Chart(chartCanvas, {
      type: "line",
      data: chartData,
      options: chartOptions,
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Reporte Mensual</h5>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <p className="text-center">
                    <strong>{this.state.titulo_grafica}</strong>

                    <select
                      className="form-select"
                      id="selectAnioGraphMap"
                      name="selectAnioGraphMap"
                      style={{
                        width: 150,
                        display: "inline-flex",
                        marginLeft: "15px",
                      }}
                      onChange={this.handleAnioOnChange}
                    >
                      {this.listAnios}
                    </select>
                  </p>

                  <div className="chart">
                    <canvas
                      id="salesChart"
                      height="180"
                      style={{ height: "180px" }}
                    ></canvas>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GraficaLineal;