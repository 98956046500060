import React from "react";
import PropTypes from "prop-types";
// import Links from "../../../data/link";
import { Link } from "react-router-dom";
import Texto from "../../../data/es";

const RegisterUserMessage = ({    
    email
}) => {

   const resetLocalStorage = (event) =>{
    
        const storedData = localStorage.getItem("dataForm");
        if (storedData) {
            localStorage.removeItem("dataForm");
        }
        
        const stepData = localStorage.getItem("currentStep");
        if(stepData){
            localStorage.removeItem("currentStep");
        }     
   }

    
   
    
    /* ============================================================ */
    return (
        <>
            <div className="row mt-3 p-2">
                <div className="text-center mb-3 col-sm-12 col-lg-12">
                    <button className="btn">
                        <i className='fa fa-check-circle fa-4x text-success'></i>
                    </button>
                </div>
                <div className="text-justify col-lg-12 col-md-12 col-sm-12 mx-auto border-radius-035 bg-gray-5 p-3">
                    {Texto.register_end_ok}
                    <br/>

                    <p className="text-center"><b>{email}</b></p>                   
                </div>
            </div>
            <div className="col-sm-12 col-lg-12 text-center mt-3">
				<div>				

                    <Link to={"/"} className="btn btn-violeta" onClick={resetLocalStorage}>
                    Finalizar
						</Link>
				</div>
			</div>
        </>

    );
};

RegisterUserMessage.propTypes = {    
    email: PropTypes.string.isRequired
};

export default RegisterUserMessage;
