import React, { useState, useEffect } from "react";
import Fetch from "../utils/Fetch";
import AuthService from "../Usuario/AuthService";
import Links from "../../data/link";

const ModalObservation = (props) => {
  const { toast, title, userAuth, Constant, autorization, clearObservation } =
    props;

  const fetch = new Fetch();
  fetch.setToast(toast);

  const auth = new AuthService();

  const idModal = "modalObservationAutorization";
  let modal = undefined;

  const [nodeObservation, setNodeObservation] = useState([]);

  useEffect(() => {
    if (!auth.loggedIn()) window.location.href = Links[4].url;
    openModalObservation(autorization.token);
    window.scrollTo(0, 0);
  }, []);

  const openModalObservation = (tokenAutorization) => {
    modal = new window.bootstrap.Modal(document.getElementById(idModal), {
      keyboard: true,
      backdrop: "static",
      focus: true,
    });
    modal.show();
    window.jQuery("#" + idModal).on("shown.bs.modal", function (e) {
      paintObservation(autorization);
    });
  };

  const paintObservation = (autorization) => {
    debugger;
    fetch
      .fetchGet(
        `api/saat-autorizacion/observation/by-token-autorization/${autorization.token}`
      )
      .then((dataJson) => {
        if (dataJson && dataJson.status) {
          debugger
          let cont = 0;
          const tableObservation = dataJson.data.Observation.map(
            ({ id, description, created_at }) => {
              cont++;
              return (
                <div
                  className={"row pointer row-table-custom pt-3 "}
                  code={id}
                  key={cont + "_" + id}
                >
                  <div className="col-10 col-xs-10 col-md-10 ">
                    <p className="fs-small9em">{description}</p>
                  </div>
                  <div className="col-2 col-xs-2 col-md-2 ">
                    <p className="fs-small9em">{window.moment(created_at).format("DD-MM-YYYY HH:mm:ss") }</p>
                  </div>
                </div>
              );
            }
          );

          setNodeObservation(tableObservation);
        }
      });
  };

  const handleCloseModal = (event) => {
    clearObservation();
  };

  return (
    <>
      <div
        className="modal fade"
        id={idModal}
        tabIndex="-1"
        aria-labelledby="movalPdfFullObservation"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" >
                {title}
              </h5>
              <button
                type="button"
                className="btn-close h6 me-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body me-3 ms-3">
              <div id="spinner-loading-modal-pdf" className="row d-none mt-3">
                <div className="col-12 mb-1">
                  <img
                    className="rounded mx-auto d-block"
                    alt="pulse-loading"
                    src={"/static/img/pulse_200.gif"}
                    width="70"
                  />
                </div>
              </div>

              <div className="row">
                <div className={"row pointer row-table-custom pt-3 "}>
                  <div className="col-10 col-xs-10 col-md-10 ">
                    <strong>Observación</strong>
                  </div>
                  <div className="col-2 col-xs-2 col-md-2 text-center">
                    <strong>Registrado</strong>
                  </div>
                </div>

                <div className="col-12 col-md-12 col-lg-12 embed-container overflow-visible">
                  {nodeObservation}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="col-12 search-btn button text-end">
                <button
                  type="button"
                  className="btn bg-maroon-flush"
                  data-bs-dismiss="modal"
                  onClick={handleCloseModal}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalObservation;
