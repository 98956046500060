import Config from '../../data/config';
import axios from 'axios';
import AuthService from '../../components/Usuario/AuthService';

export default class Fetch {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || Config[0].url // API server domain
        //this.domainAdvertising = "http://localhost:3030/graphql" // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff

        this.Auth = new AuthService();
        this.toast = null;
        this.port = 3000;
        this.isGraphQL = false;
        this.messageError = "No se pudo recuperar, problemas de conexión en la red"
    }

    setToast(toast) {
        this.toast = toast;
    }

    async axiosAsyncGet(url) {

        try {
            var self = this;
            const response = await axios.get(this.domain + `${url}?auth=${this.Auth.getToken()}`, {
            })
            const json = await response.data;
            if (json.status === true) {
                return json;
            } else {
                if (json.message)
                    self.toast.warning(json.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
            }
        } catch (error) {
            var message = error.message;

            if (Boolean(error.response) && Boolean(error.response.data))
                message += ", " + error.response.data.message

            this.toast.error(this.messageError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
        return null;
    }

    fetchGet(url) {

        //aqui ver cuales han de llevar el token
        const self = this;
        try {
            let code_sg = ""
            if (Boolean(this.Auth.getCodigoSoporteGerencial()))
                code_sg = "&code_sg=" + this.Auth.getCodigoSoporteGerencial() //para el soporte gerencial

            return this.fetch(`${this.domain}${url}?auth=${this.Auth.getToken()}${code_sg}`, {
                method: 'GET',
            }).then(res => {
                debugger
                if (res.status) {
                    return Promise.resolve(res);
                } else {
                    const message = this.message(res);
                    if (message)
                        self.toast.warning(message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });

                }
            }).catch(error => {
                const message = this.message(error)
                if (self.toast) {
                    self.toast.error(self.messageError, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else
                    console.error(message);
            });
        } catch (error) {
            const message = error.message;
            if (Boolean(error.response) && Boolean(error.response.data))
                message += ", " + error.response.data.message

            this.toast.error(this.messageError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }

        return null;
    }

    fetchGetExternal(url) {

        var self = this;
        try {
            return this.fetch(url, {
                method: 'GET',
            }).then(res => {
                return Promise.resolve(res);
            }).catch(error => {
                self.toast.error(JSON.stringify(error.message).replace('{', '').replace('}', ''), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
        } catch (error) {
            var message = error.message;
            if (Boolean(error.response) && Boolean(error.response.data))
                message += ", " + error.response.data.message

            this.toast.error(this.messageError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }

        return null;
    }

    /**
     * 
     * @param {*} form  object form
     * @param {*} form_javascript  form jjavascript
     * @param {*} url url request
     */
    fetchPost(form, url, formJavascript) {

        if (form.get('usuario[confirm_password]') !== null) {
            form.delete('usuario[confirm_password]');
        }

        form.set('auth', this.Auth.getToken());
        if (Boolean(this.Auth.getCodigoSoporteGerencial()))
            form.set('code_sg', this.Auth.getCodigoSoporteGerencial());      //para el soporte gerencial
        var self = this;    //aqui ver cuales han de llevar el token
        this.disableButton(formJavascript)

        //`${this.domain}` + url
        return this.fetch(`${this.domain}` + url, {
            method: 'POST',
            body: form
        }).then(res => {
            if (res.status === true) {
                self.enableButton(formJavascript)
                return Promise.resolve(res);
            } else {
                self.enableButton(formJavascript)
                const message = this.message(res);
                if (message)
                    self.toast.warning(message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
            }
        }).catch(error => {
            self.enableButton(formJavascript)
            //JSON.stringify(error.message).replace('{', '').replace('}', '')
            self.toast.error(self.messageError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
    }

    /**
     * 
     * @param {*} form  object form
     * @param {*} form_javascript  form jjavascript
     * @param {*} url url request
     */
    fetchPostGraphQl(query, url, formJavascript) {
        const headers = new Headers();
        headers.append("Authorization", "Bearer " + this.Auth.getToken());
        headers.append("Content-Type", "application/json");

        const graphql = JSON.stringify({
            query: query,
            variables: {}
        })
        const self = this;
        return this.fetch(`${Config[10].url}` + url, {
            method: 'POST',
            headers: headers,
            body: graphql,
            redirect: 'follow'
        }).then(res => {
            if (res.data) {
                self.enableButton(formJavascript)
                return Promise.resolve(res.data);
            } else {
                self.enableButton(formJavascript)
                const message = this.message(res);
                if (message)
                    self.toast.warning(message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
            }
        }).catch(error => {
            self.enableButton(formJavascript)
            self.toast.error(self.messageError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
    }

    // fet GrapgQl con variables o parametros
    fetchPostGraphQlWithParameters(query, variables, url, formJavascript) {

        const headers = new Headers();
        //headers.append("Authorization", "Bearer e0e190a7cb0d2ddc14c68bfbaee46765790a0b7d");
        headers.append("Content-Type", "application/json");

        const graphql = JSON.stringify({
            query: query,
            variables: variables
        })
        const self = this;
        return this.fetch(`${this.domainAdvertising}` + url, {
            method: 'POST',
            headers: headers,
            body: graphql,
            redirect: 'follow'
        }).then(res => {
            if (res.data) {
                self.enableButton(formJavascript)
                return Promise.resolve(res.data);
            } else {
                self.enableButton(formJavascript)
                const message = this.message(res);
                if (message)
                    self.toast.warning(message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
            }
        }).catch(error => {
            self.enableButton(formJavascript)
            self.toast.error(self.messageError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
    }

    /**
     * 
     * @param {*} form  object form
     * @param {*} form_javascript  form jjavascript
     * @param {*} url url request
     */
    fetchPostFormDataGraphQl(formdata, url, formJavascript) {
        const headers = new Headers();
        headers.append("Authorization", "Bearer " + this.Auth.getToken());
        //headers.append("Content-Type", "application/x-www-form-urlencoded");

        const self = this;
        return this.fetch(`${Config[10].url}` + url, {
            method: 'POST',
            headers: headers,
            body: formdata,
            //redirect: 'follow'
        }).then(res => {
            if (res.data || res.status) {
                self.enableButton(formJavascript)
                return Promise.resolve(res.data);
            } else {
                self.enableButton(formJavascript)
                const message = this.message(res);
                if (message)
                    self.toast.warning(message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
            }
        }).catch(error => {
            self.enableButton(formJavascript)
            self.toast.error(self.messageError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        });
    }

    fetch(url, options) {
        return fetch(url, {
            ...options
        }).then(this._checkStatus)
            .then(response => response.json());
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }

    jsonToString(json) {
        let message = JSON.stringify(json)
        return message.replace(/":"/, ':').replace(/{"/, '').replace(/"}/, '')
    }

    message(error) {

        var message = ""
        if (window.is_json(error) && error.hasOwnProperty('response')) {
            message = error.response.statusText;
        } else {
            if (window.is_json(error.message))
                message = this.jsonToString(error.message)
            else
                if (error.message.indexOf('Unexpected end of JSON') >= 0 || error.message.indexOf('Unexpected token') >= 0)
                    message = "500, Error Interno del Servidor. Vuelva a intentarlo de nuevo."
                else {
                    if (error instanceof String && error.indexOf('Unexpected end of JSON') >= 0) {
                        message = "500, Error Interno del Servidor. Vuelva a intentarlo de nuevo."
                    } else
                        message = error.message
                }
        }
        return message;
    }

    disableButton(form_javascript) {
        if (Boolean(form_javascript)) {
            let form = window.jQuery(form_javascript)
            if (form.length > 0)
                if (form.find("input[type=submit]").length > 0)
                    window.disableButton(form.find("input[type=submit]")[0])
        }
    }

    enableButton(form_javascript) {
        if (Boolean(form_javascript)) {
            let form = window.jQuery(form_javascript)
            if (form.length > 0)
                if (form.find("input[type=submit]").length > 0)
                    window.enableButton(form.find("input[type=submit]")[0])
        }
    }
}
