import React, { useEffect } from "react";

const Analytics = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    const header = document.getElementsByTagName("header");
    const footer = document.getElementsByTagName("footer");
    const newsletter = document.getElementsByClassName("newsletter ");

    if (header && header.length > 0) header[0].remove();
    if (newsletter && newsletter.length > 0) newsletter[0].remove();
    if (footer && footer.length > 0) footer[0].remove();
  }, []);

  return (
    <>
      $
      {
        "GooGhywoiu9839t543j0s7543uw1 – pls add {INSERT EMAIL ADDRESS} to GA account {INSERT G-B5JP18DTBK} with ‘Manage Users and Edit’ permissions – date {INSERT DATE}."
      }
    </>
  );
};

export default Analytics;
/*
"<script async src='https://www.googletagmanager.com/gtag/js?id=G-B5JP18DTBK'></script>" +
    "<script  type='text/javascript' async>" +
    "window.dataLayer = window.dataLayer || [];" +
    "function gtag() {" +
    "dataLayer.push(arguments);" +
    "}" +
    "gtag('js', new Date());" +
    "gtag('config', 'G-B5JP18DTBK');" +
    "</script>"
    */
