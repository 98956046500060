import React, { useEffect, useState } from "react";
import Fetch from "../utils/Fetch";
import Links from "../../data/link";
import TitlePage from "../utils/TitlePage";
import Texto from "../../data/es";
import DataTable from "react-data-table-component";
import ModalPdf from "../utils/ModalPdf";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalGacetaTributaria from "./ModalGacetaTributaria";
import ModalDocRelacionados from "./ModalDocRelacionados";
import Columns from "./Columns";
import AuthService from "../Usuario/AuthService";
import CustomLoader from "../utils/SpinnerDataTable";
import styled from "styled-components";
import Config from "../../data/config";
import Constant from "../../data/constant";

const breadcrumbs = [
  {
    title: Links[0].title,
    url: Links[0].url,
  },
  {
    title: Links[8].title,
    url: Links[8].url,
  },
  {
    title: Links[119].title,
    url: Links[119].url,
  },
];

const SampleStyle = styled.div`
  padding: 10px;
  display: block;
  width: 90%;
  p {
    font-size: 12px;
    word-break: break-all;
  }
`;

const Admin = (props) => {
  const titlePage = Texto.gaceta_tributaria;
  const fetch = new Fetch();
  fetch.setToast(toast);

  const modalPdf = new ModalPdf();
  modalPdf.setToast(toast);

  const auth = new AuthService();
  const userAuth = auth.getProfile();

  const columnsClass = new Columns(userAuth);

  const [showModalGT, setShowModalGT] = useState(false);
  const [showModalDocRel, setShowModalDocRel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [typeSearch, setTypeSearch] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [optionsGestion, setOptionsGestion] = useState(undefined);
  const [optionsTypes, setOptionsTypes] = useState(undefined);

  const typeGacetaTributaria = "ALL"; //LEYES

  useEffect(() => {
    if (auth.loggedIn()) {
      if (
        userAuth.code === Constant[0].grupos.funcionario ||
        userAuth.code === Constant[0].grupos.administrador
      ) {
        getDataGacetaTributaria();
        loadGestion();
        loadTypesGacetaTributaria();
        window.jQuery(".sc-kAzzGY").remove(); //pertenece al datatable
        document
          .getElementsByClassName("rdt_TableHeader")[0]
          .classList.add("d-none");
        window.scrollTo(0, 0);
      } else window.location.href = Links[4].url;
    } else window.location.href = Links[4].url;
  }, []);

  const loadGestion = () => {
    const date = new Date();
    fetch
      .axiosAsyncGet(`api/gestion/range-year/2010/${date.getFullYear()}`)
      .then((response) => {
        if (response && response.status) {
          const dataResp = response.data.gestion.map(
            ({ id, name, active }, index) => {
              return (
                <option key={index} value={id} name={name}>
                  {name}
                </option>
              );
            }
          );
          setOptionsGestion(dataResp);
        }
      });
  };

  const loadTypesGacetaTributaria = () => {
    fetch.axiosAsyncGet(`api/gaceta-tributaria/types/all`).then((response) => {
      if (response && response.status) {
        const dataResp = response.GTTypes.map(({ id, name }, index) => {
          return (
            <option key={index} value={id} name={name}>
              {name}
            </option>
          );
        });
        setOptionsTypes(dataResp);
      }
    });
  };

  const getDataGacetaTributaria = async () => {
    setLoading(true);
    const response = await searchData(
      currentPage,
      perPage,
      typeSearch,
      typeGacetaTributaria,
      "0",
      "0",
      "0"
    );
    if (response && response.status) {
      setData(response.data);
      setTotalRows(response.total);
      setLoading(false);

      document.getElementById("pPublicados").innerHTML =
        "Activos - " + response.activos;
      document.getElementById("pBloqueados").innerHTML =
        "Bloqueados - " + response.bloqueados;
      document.getElementById("pElininados").innerHTML =
        "Eliminados - " + response.cancelados;

      toast.success(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleRedirectUrlClick = (ev, url) => {
    window.location.href = url;
  };

  const openModalGacetaTributaria = (e, open) => {
    debugger;
    setSelectedRow(null);
    setShowModalGT(open);
    getDataGacetaTributaria();
  };

  const hanldeSearchGacetaTributaria = async (
    event,
    typeSearch,
    typeGacetaTributaria
  ) => {
    setLoading(true);
    debugger;
    const response = await searchData(
      currentPage,
      perPage,
      typeSearch,
      typeGacetaTributaria,
      "0",
      "0",
      "0"
    );
    if (response && response.status) {
      setData(response.data);
      setTotalRows(response.total);
      setLoading(false);
      setTypeSearch(typeSearch);
      toast.success(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handlePerRowsChange = async (perPage, page) => {
    setLoading(true);
    const response = await searchData(
      page,
      perPage,
      typeSearch,
      typeGacetaTributaria,
      "0",
      "0",
      "0"
    );
    const json = await response.data;
    setLoading(false);
    setData(json);
    setPerPage(perPage);
    setCurrentPage(page);
  };

  const handlePageChange = async (page) => {
    setLoading(true);
    const response = await searchData(
      page,
      perPage,
      typeSearch,
      typeGacetaTributaria,
      "0",
      "0",
      "0"
    );
    const json = response.data;
    setLoading(false);
    setData(json);
    setCurrentPage(page);
  };

  const searchData = async (
    page,
    perPage,
    typeSearch,
    typeGacetaTributaria,
    gestion,
    typeTextSearch,
    textSearch
  ) => {
    return await fetch.fetchGet(
      `api/gaceta-tributaria/data-admin-by-page/${page}/${perPage}/${typeSearch}/${typeGacetaTributaria}/${gestion}/${typeTextSearch}/${textSearch}`
    );
  };

  const handleDisplayClick = (row) => {
    window.createBootbox("Esta Seguro de Continuar.", function (result) {
      if (result) {
        actionRequestChangeState(row, "ACTIVO");
      }
    });
  };

  const handleCloseClick = (row) => {
    window.createBootbox("Esta Seguro de Continuar.", function (result) {
      if (result) {
        actionRequestChangeState(row, "BLOQUEADO");
      }
    });
  };

  const handleOpenDependencyClick = (row) => {};

  const handleDeleteClick = (row) => {
    window.createBootbox("Esta Seguro de Continuar.", function (result) {
      if (result) {
        actionRequestChangeState(row, "CANCELADO");
      }
    });
  };

  const handleRestoreClick = (row) => {
    debugger;
    window.createBootbox("Esta Seguro de Continuar.", function (result) {
      if (result) {
        actionRequestChangeState(row, "BLOQUEADO");
      }
    });
  };

  const handleEditarClick = (row) => {
    setSelectedRow(row);
    setShowModalGT(true);
  };

  const actionRequestChangeState = (row, estado) => {
    const form = new FormData();
    form.append("gt_gaceta_tributaria[id]", row.id_gaceta_tributaria);
    form.append("gt_gaceta_tributaria[code_estado]", estado);
    fetch
      .fetchPost(form, "api/gaceta-tributaria/change-state")
      .then((dataJson) => {
        if (dataJson && dataJson.status) {
          getDataGacetaTributaria();
          toast.success(dataJson.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };

  const handleOpenModalDocRelacinadosClick = (row, openModal) => {
    setShowModalDocRel(openModal);
    setSelectedRow(openModal ? row : undefined);
  };

  const openModalDoc = (e, code, number, type) => {
    e.preventDefault();
    debugger;
    const url = `${Config[0].url}api/gaceta-tributaria/documents/preview-download`;
    modalPdf.showPdf(
      `${url}/preview/${code}`,
      getTextByType(type, number),
      code,
      undefined,
      `${url}/download/${code}`
    );
  };

  const getTextByType = (type, number) =>
    type === "LEYES"
      ? "Ley Municipal Nro " + number
      : type === "DECRETOS"
      ? "Decreto Municipal Nro" + number
      : type === "RTA"
      ? "Resolución Técnica Administrativa Nro " + number
      : "";

  const IntentExpandedComponent = ({ data }) => {
    const codeNodeHtml = data.id + "-" + data.number;
    searchGacetaTributariaByFather(data, codeNodeHtml);
    return (
      <SampleStyle>
        <div className="row">
          <div className="col-12 col-md-9 mb-3">
            <span>
              <strong>{Texto.gaceta_tributaria.toUpperCase()} </strong>
            </span>
            <hr className="mt-0 " />
            <p>
              <strong>{Texto.numero}: </strong>
              {data.number}
            </p>
            <p>
              <strong>{Texto.titulo}: </strong>
              {data.title}
            </p>
            <p>
              <strong>{Texto.descripcion}: </strong>
              {data.description}
            </p>
            <p>
              <strong>{Texto.categoria}: </strong>
              {data.type_name}
            </p>
            <p>
              <strong>{Texto.creado}: </strong>
              {window.moment(data.created_at).format("DD-MM-YYYY HH:mm:ss")}
            </p>
            <p>
              <strong>{Texto.publicado}: </strong>
              {data.publication_at
                ? window
                    .moment(data.publication_at)
                    .format("DD-MM-YYYY HH:mm:ss")
                : ""}
            </p>
            <p>
              <strong>{Texto.usuario}: </strong>
              {data.username}
            </p>
            <p>
              <strong>{Texto.documento}: </strong>
              {data.document_code ? (
                <i
                  className="fas fa-file-download h5 pointer"
                  onClick={(e) =>
                    openModalDoc(
                      e,
                      data.document_code,
                      data.number,
                      data.type_code
                    )
                  }
                ></i>
              ) : (
                ""
              )}
            </p>
          </div>
          <div className="col-12 col-md-3">
            <span>
              <strong>{Texto.doc_relacionados.toUpperCase()} </strong>
            </span>
            <hr className="mt-0 " />
            <div id={codeNodeHtml}></div>
          </div>
        </div>
      </SampleStyle>
    );
  };

  const searchGacetaTributariaByFather = (gacetaTributaria, codeNodeHtml) => {
    if (gacetaTributaria.id_gaceta_tributaria) {
      const response = fetch.fetchGet(
        `api/gaceta-tributaria/search-by-id-father/${gacetaTributaria.id_gaceta_tributaria}`
      );
      response.then((respChild) => {
        if (
          respChild &&
          respChild.status &&
          respChild.data &&
          respChild.data.length
        ) {
          const child = respChild.data;
          const container = document.querySelector(
            '[id="' + codeNodeHtml + '"]'
          );
          container.innerHTML = "";

          for (let index = 0; index < child.length; index++) {
            const documentJson = JSON.parse(child[index].documents);
            const nodeI = document.createElement("i");
            nodeI.classList.add("fas");
            nodeI.classList.add("fa-file-download");
            nodeI.classList.add("h4");

            const link = document.createElement("a");
            link.appendChild(nodeI);
            link.title = documentJson.name;
            link.target = "_blank";
            link.classList.add("pe-3");
            link.href = `#`;

            link.onclick = (e) =>
              openModalDoc(
                e,
                documentJson.code,
                gacetaTributaria.number,
                gacetaTributaria.type_code
              );
            container.appendChild(link);
          }
        }
      });
    }
    return null;
  };

  const handleSubmitSearchForm = async (event) => {
    event.preventDefault();
    debugger;
    const typeSearch = 0; //para que busque en todos
    const typeGacetaTributariaSearch = document
      .getElementById("clasificadorSearch")
      .selectedOptions[0].getAttribute("name")
      .toUpperCase();

    const inputGestion = document
      .getElementById("selectGestion")
      .selectedOptions[0].getAttribute("name");
    const inputSearchNumeroOrDescription =
      document.getElementById("selectBuscarPor").selectedOptions[0].value;
    const inputTextoBuscar = document.getElementById("search").value;

    setLoading(true);
    const response = await searchData(
      currentPage,
      perPage,
      typeSearch,
      typeGacetaTributariaSearch,
      inputGestion,
      inputSearchNumeroOrDescription,
      inputTextoBuscar ? inputTextoBuscar : "0"
    );
    if (response && response.status) {
      debugger;
      setData(response.data);
      setTotalRows(response.total);
      setLoading(false);
      setTypeSearch(typeSearch);
      toast.success(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleOnchangeInputSearch = (event) => {
    const selectOptions = event.target.selectedOptions[0];
    const inputSerach = document.getElementById("search");
    if (selectOptions && selectOptions.value && selectOptions.value !== "0")
      inputSerach.removeAttribute("readOnly");
    else inputSerach.setAttribute("readOnly", true);
  };

  return (
    <section className="items-grid section mt-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={titlePage}
          breadcrumbs={breadcrumbs}
          position={"left"}
          leftfull={false}
        />

        <section className="panel-menu-info">
          <div className="panel-menu-info-content">
            <div className="row">
              <div className="col-4 col-md-2 col-lg-2">
                <div
                  className="single-contact-info pointer"
                  onClick={(e) => hanldeSearchGacetaTributaria(e, 3, "ALL")}
                >
                  <i className="fas fa-trash-alt" aria-hidden="true"></i>
                  <p id="pElininados">Eliminados - 0</p>
                </div>
              </div>
              <div className="col-4 col-md-2 col-lg-2">
                <div
                  className="single-contact-info pointer"
                  onClick={(e) => hanldeSearchGacetaTributaria(e, 2, "ALL")}
                >
                  <i className="fa fa-file-text-o" aria-hidden="true"></i>
                  <i className="fas fa-ban" aria-hidden="true"></i>
                  <p id="pBloqueados">Bloqueados - 0</p>
                </div>
              </div>

              <div className="col-4 col-md-2 col-lg-2">
                <div
                  className="single-contact-info pointer"
                  onClick={(e) => hanldeSearchGacetaTributaria(e, 1, "ALL")}
                >
                  <i className="fa fa-file-text-o" aria-hidden="true"></i>
                  <i className="fa fa-check" aria-hidden="true"></i>
                  <p id="pPublicados">Activos - 0</p>
                </div>
              </div>

              <div className="col-4 col-md-2 col-lg-2"></div>

              <div className="col-4 col-md-2 col-lg-2">
                <div
                  className="single-contact-info pointer"
                  onClick={(e) => handleRedirectUrlClick(e, Links[8].url)}
                >
                  <i className="far fa-user" aria-hidden="true"></i>
                  <p>Mi Cuenta</p>
                </div>
              </div>
              <div className="col-4 col-md-2 col-lg-2">
                <div
                  className="single-contact-info pointer"
                  onClick={(e) => openModalGacetaTributaria(e, true)}
                >
                  <i className="far fa-file" aria-hidden="true"></i>
                  <p>Nuevo Publicación</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="card widget widget-simple">
          <div className="card-body ">
            <div className="row ">
              <div className="col-12 col-md-6 widget-header mb-3">
                <h4 className="text-maroon-flush">
                  <i className="fas fa-bookmark me-2"></i> {Texto.search}
                </h4>
              </div>
              <div className="col-4 col-md-6 widget-header mb-3"></div>
            </div>

            <div className="widget-content">
              <div className="widget-body">
                <form
                  action=""
                  className="contact__form center-login"
                  name="formSearchDataTable"
                  id="formSearchDataTable"
                  method="post"
                  noValidate
                  onSubmit={handleSubmitSearchForm}
                >
                  <div className="row">
                    <div className="col-12 col-md-3 col-lg-3 ">
                      <label
                        htmlFor={"clasificadorSearch"}
                        className="form-label"
                      >
                        Clasificador
                      </label>
                      <select
                        className="form-select mb-3"
                        name="clasificadorSearch"
                        id="clasificadorSearch"
                        required
                        data-parsley-required="true"
                      >
                        {optionsTypes}
                      </select>
                    </div>
                    <div className="col-12 col-md-2 col-lg-2 ">
                      <label htmlFor={"selectBuscarPor"} className="form-label">
                        Buscar Por
                      </label>
                      <select
                        className="form-select mb-3"
                        name="selectBuscarPor"
                        id="selectBuscarPor"
                        required
                        data-parsley-required="true"
                        onChange={handleOnchangeInputSearch}
                      >
                        <option key={0} value="0">
                          Seleccione Criterio
                        </option>
                        <option key={1} value="NUMERO">
                          Número
                        </option>
                        <option key={2} value="CONTENIDO">
                          Título/Descripción
                        </option>
                      </select>
                    </div>
                    <div className="col-12 col-md-2 col-lg-2 ">
                      <label htmlFor={"selectGestion"} className="form-label">
                        Gestión
                      </label>
                      <select
                        className="form-select mb-3"
                        name="selectGestion"
                        id="selectGestion"
                        required
                        data-parsley-required="true"
                      >
                        <option key={0} value="0" name="0">
                          Gestión
                        </option>
                        {optionsGestion}
                      </select>
                    </div>

                    <div className="col-12 col-md-5 col-lg-5 ">
                      <label htmlFor={"search"} className="form-label">
                        Texto a buscar
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          name="search"
                          id="search"
                          className="form-control"
                          placeholder="*"
                          aria-label="search"
                          readOnly
                        />

                        <button
                          className="btn btn-outline-secondary"
                          type="submit"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Buscar"
                        >
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="card widget widget-simple">
          <div className="card-body ">
            <div className="widget-content">
              <div className="widget-body">
                <DataTable
                  columns={columnsClass.columns(
                    handleDisplayClick,
                    handleCloseClick,
                    handleOpenDependencyClick,
                    handleDeleteClick,
                    handleRestoreClick,
                    handleOpenModalDocRelacinadosClick,
                    handleEditarClick
                  )}
                  data={data}
                  progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  highlightOnHover
                  noDataComponent={Texto.there_are_no_records_to_display}
                  progressComponent={<CustomLoader />}
                  expandableRowsComponent={<IntentExpandedComponent />}
                  expandableRows
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModalGT ? (
        <ModalGacetaTributaria
          toast={toast}
          openModalGacetaTributaria={openModalGacetaTributaria}
          gacetaTributariaDb={selectedRow}
        />
      ) : (
        ""
      )}

      {showModalDocRel ? (
        <ModalDocRelacionados
          toast={toast}
          gacetaTributaria={selectedRow}
          openModalDocRelacionado={handleOpenModalDocRelacinadosClick}
        />
      ) : (
        ""
      )}

      <ModalPdf />
      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </section>
  );
};

export default Admin;
