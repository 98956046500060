import React, { useEffect, useState, useRef } from 'react';

import Fetch from '../../utils/Fetch';
//import AuthService from '../../Usuario/AuthService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Texto from '../../../data/es'

import FormVehiculoServicioPublico from './FormVehiculoServicioPublico'
import DatosIdentificacionVehiculo from './DatosIdentificacionVehiculo'
import DatosTecnicosVehiculo from './DatosTecnicosVehiculo'
import DatosDeudaVehiculo from './DatosDeudaVehiculo'
import DatosEstadoVehiculo from './DatosEstadoVehiculo'

const Form = (props) => {

    const fetch = new Fetch();
    fetch.setToast(toast);

    const { servicioPublicoDb, vehiculoRuatDb } = props

    //const refCheckBoxPlacaOrPoliza = useRef()
    const refInputPlacaOrPoliza = useRef()

    const [isNew, setIsNew] = useState(true)
    const [showForm, setShowForm] = useState(false)
    const [showMessage, setShowMessage] = useState("")
    const [placa, setPlaca] = useState(false)

    const [dataVehiculo, setDataVehiculo] = useState(undefined)
    const [dataDeudas, setDataDeudas] = useState([])
    const [dataDeudasSolicitadasCeritificacion, setDataDeudasSolicitadasCeritificacion] = useState([])
    const [dataObservaciones, setDataObservaciones] = useState([])
    const [dataObservado, setDataObservado] = useState(true)

    /*const vehiculoGamc = {
        lineaServicioPublico: {
            id: 1,
            name: "prueba"
        }
    }*/

    useEffect(() => {
        window.jQuery(function () { window.jQuery('.toogle-input').bootstrapToggle() });  //input[type=checkbox]

        /*let checkPolizaOrPlaca = refCheckBoxPlacaOrPoliza.current
        if (checkPolizaOrPlaca) {
            let events = window.jQuery._data(checkPolizaOrPlaca, "events");
            if (events === undefined || events.change.length === 0) {
                window.jQuery(checkPolizaOrPlaca).change(function (event) {
                    let inputPlacaOrPoliza = refInputPlacaOrPoliza.current
                    if (window.jQuery(event.target).prop('checked') === true)
                        inputPlacaOrPoliza.setAttribute("placeHolder", "Nro. Placa")
                    else
                        inputPlacaOrPoliza.setAttribute("placeHolder", "Nro. Poliza")
                })
            }
        }*/

        if (servicioPublicoDb && vehiculoRuatDb) {
            setDataVehiculo(vehiculoRuatDb.dv)
            setDataDeudas(vehiculoRuatDb.de)
            setDataObservaciones(vehiculoRuatDb.lo)
            setDataObservado(vehiculoRuatDb.observado)
            setShowForm(true)
            setIsNew(false)
            setShowMessage("")
            setPlaca(vehiculoRuatDb.dv.pl)
        }

    }, []);

    /*const handleKeyEnterSearchByPlacaOrPoliza = (event) => {
        if (event.charCode === 13) {
            event.preventDefault()
            let placaOrPolica = event.target.value
            if (placaOrPolica !== "" && placaOrPolica.length <= 8) {
                searchVehiculo(placaOrPolica)
            }
        }
    }

    const handleSearchByPlacaOrPoliza = (event) => {
        event.preventDefault()
        let placaOrPolica = event.target.closest('.input-group').firstChild.value
        if (placaOrPolica !== "" && placaOrPolica.length <= 8) {
            searchVehiculo(placaOrPolica)
        }
    }*/

    /*const searchVehiculo = (placaOrPolica) => {
        fetch.fetchGet('api-ruat/vehiculos/consulta-datos-basic/' + placaOrPolica).then(dataJson => {
            if (dataJson && dataJson.status === true && dataJson.data) {
                if (dataJson.data.vehiculo) {
                    setDataVehiculo(dataJson.data.vehiculo.dv)
                    setDataDeudas(dataJson.data.vehiculo.de)
                    setDataObservaciones(dataJson.data.vehiculo.lo)
                    setDataObservado(dataJson.data.observado)
                    setShowForm(true)
                    setIsNew(true)
                    setShowMessage("")

                    setPlaca(placaOrPolica)

                    toast.success(dataJson.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else {
                    setShowForm(false)
                    setShowMessage(dataJson.message)
                    toast.warn(dataJson.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            } else {
                setShowForm(false)
            }
        })
    }*/

    const onSubmitForm = (event) => {
        event.preventDefault()

        let deudas = dataDeudas.map(deuda => {
            deuda.solicitado = 0
            for (let index = 0; index < dataDeudasSolicitadasCeritificacion.length; index++) {
                if (parseInt(deuda.ge) === parseInt(dataDeudasSolicitadasCeritificacion[index].ge) &&
                    deuda.mt === dataDeudasSolicitadasCeritificacion[index].mt) {
                    deuda.solicitado = 1
                    break
                }
            }
            return deuda
        })
        props.onSubmitForm(event, { dataVehiculo, dataDeudas: deudas, dataObservaciones, dataObservado })
    }

    const addDeudaToCertificacion = (event, deuda) => {
        event.preventDefault()
        let deudas = [...dataDeudasSolicitadasCeritificacion, deuda]
        setDataDeudasSolicitadasCeritificacion(deudas)
    }

    const removeDeudaToCertificacion = (event, deuda) => {
        event.preventDefault()
        let deudas = dataDeudasSolicitadasCeritificacion.filter(deudaOld => deudaOld.ge !== deuda.ge)
        setDataDeudasSolicitadasCeritificacion(deudas)
    }

    return (
        <>
            <form className="contact__form" style={{ margin: "0.5rem" }} name={'formServicioPublico'} id={'formServicioPublico'}
                method="post" noValidate onSubmit={onSubmitForm}>

                {/* ? <div className="card widget widget-simple " >
                    <div className="card-body ">
                        <div className="row ">
                            <div className="col-12 widget-header">
                                <h4 className="">{Texto.busqueda_vehiculo}</h4>
                            </div>
                        </div>

                        <div className="widget-content">
                            <div className="widget-body">
                                <div className="row">
                                    <div className="col-12 col-md-4 col-lg-4 form-group">
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 form-group">
                                        <div className="input-group mb-3">
                                            <input name="certificado_servicio_publico[numero]" type="text" className="form-control" placeholder="Nro. Placa *" ref={refInputPlacaOrPoliza}
                                                data-parsley-required="true" onKeyPress={handleKeyEnterSearchByPlacaOrPoliza} />
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button" onClick={handleSearchByPlacaOrPoliza}><i className="fa fa-search" aria-hidden="true" ></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 form-group"></div>
                                </div>
                            </div>
                        </div>
                    </div>
    </div> : */}

                {showMessage !== "" ?
                    <div className="row ">
                        <div className="col-12 ">
                            <div className="alert alert-warning alert-dismissible fade show h5" role="alert">
                                <strong>Alerta!</strong> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {showMessage}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    : ""}

                {
                    showForm ?
                        <>
                            <DatosIdentificacionVehiculo dataVehiculo={dataVehiculo} />
                            <DatosTecnicosVehiculo dataVehiculo={dataVehiculo} />
                        </>
                        : ""
                }

                {
                    showForm && dataDeudas.length > 0 ?
                        <DatosDeudaVehiculo dataDeudas={dataDeudas} nameFormGestionDeuda="tabla" addDeuda={addDeudaToCertificacion}
                            removeDeuda={removeDeudaToCertificacion} activeEvent={true} />
                        : ""
                }

                {
                    showForm && dataObservaciones.length > 0 ?
                        <DatosEstadoVehiculo dataObservaciones={dataObservaciones} observacion={dataObservado} />
                        : ""
                }

                {
                    showForm ?
                        <div className="card widget widget-simple" >
                            <div className="card-body ">
                                <div className="row ">
                                    <div className="col-12 widget-header">
                                        <h4 className="">{Texto.datos_propietario_tercero_responsable_poseedor}</h4>
                                    </div>
                                </div>

                                <div className="widget-content">
                                    <div className="widget-body">
                                        {
                                            isNew ?
                                                <FormVehiculoServicioPublico fetch={fetch} placa={placa} />
                                                : <FormVehiculoServicioPublico fetch={fetch} placa={placa}
                                                    servicioPublicoDb={servicioPublicoDb} vehiculoRuatDb={vehiculoRuatDb} />
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        : ""
                }

                {
                    showForm ?
                        <div className="row">
                            <div className="col-0 col-md-4"></div>
                            <div className="col-12 col-md-4 col-lg-4 form-group text-center"></div>
                            <div className="col-0 col-md-4">
                                <input type="submit" className="button-style " value={'Generar Solicitud CTSP'} />
                            </div>
                        </div>
                        : ""
                }
            </form>

            <ToastContainer enableMultiContainer containerId={'Z'}
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
            <ToastContainer />
        </>
    )
}

export default Form